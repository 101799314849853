import type { FC } from 'react';
import { Box } from '@material-ui/core';
import Logo from './Logo';

const SlashScreen: FC = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: '#ededed',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000,
    }}
  >
    <div style={{ backgroundColor: '#0001', padding: 12, borderRadius: 12 }}>
      <Logo size={3} />
    </div>
  </Box>
);

export default SlashScreen;
