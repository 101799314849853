import { gql, useMutation } from '@apollo/client';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import React from 'react';
import { client } from '../sdk/client';
import {
  AuthUserFragment,
  LoginDocument,
  PractitionerCreateInput,
  RegisterDocument,
  ViewerDocument,
} from '../sdk/types';
interface SignInProps {
  email: string;
  password: string;
}

export type RegisterInput = {
  email: string;
  password: string;
  profile: PractitionerCreateInput;
};

interface AuthContextProps {
  isPending: boolean;
  login: (args: SignInProps) => Promise<AuthUserFragment | null>;
  register: (args: RegisterInput) => Promise<AuthUserFragment>;
  logout: () => void;
  isAuthenticated: boolean;
  user: AuthUserFragment;
  org: AuthUserFragment['organizations'][0];
}

const authContext = React.createContext<AuthContextProps>({} as AuthContextProps);

export const useAuth = () => {
  return React.useContext(authContext);
};

const useProvideAuth = () => {
  const [user, setUser] = React.useState<AuthUserFragment | null>(null);
  const [isPending, setIsPending] = React.useState<boolean>(true);
  const [doLogin] = useMutation(LoginDocument);

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const jwt: JwtPayload = jwtDecode(token);
      if (jwt.sub)
        viewer(jwt.sub)
          .then((user) => {
            setUser(user);
            console.log('Got User', user);
            setIsPending(false);
          })
          .catch(() => {
            // TODO: Reenable
            // localStorage.removeItem('token');
            setIsPending(false);
          });
      else setIsPending(false);
    } else {
      setIsPending(false);
    }
  }, []);

  const login = ({ email, password }: SignInProps) => {
    return doLogin({ variables: { email, password } })
      .then(async ({ data }) => {
        const login = data?.login;
        if (login) {
          localStorage.setItem('token', login.token);

          setUser(login.user);

          return login.user;
        }

        return null;
      })
      .catch(() => {
        return null;
      });
  };

  const register = async (variables: RegisterInput) => {
    return client
      .mutate({
        mutation: RegisterDocument,
        variables,
      })
      .then((res) => {
        const register = res.data?.register;
        if (register) {
          localStorage.setItem('token', register.token);
          setUser(register.user);
          return register.user;
        } else {
          throw res.errors ?? 'Unknown create account error';
        }
      });
  };

  const logout = (): void => {
    // TODO: Add invalidate token on the back-end logic
    setUser(null);
    localStorage.removeItem('token');
  };

  let org: AuthUserFragment['organizations'][0] | undefined;

  return {
    isPending,
    login,
    logout,
    user: user!,
    org: org!,
    register,
    isAuthenticated: !isPending && !!user,
  };
};

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const NAME_COMMON_FRAGMENT = gql`
  fragment NameCommon on HumanName {
    id
    use
    text
    family
    given
    prefix
    suffix
  }
`;

export const ContactPointCommon = gql`
  fragment ContactPointCommon on ContactPoint {
    id
    system
    value
    use
    rank
    period {
      start
      end
    }
  }
`;

export const PRACTITIONER_COMMON = gql`
  fragment PractitionerCommon on Practitioner {
    id
    active
    resourceType
    gender
    implicitRules
    birthDate
    name {
      ...NameCommon
    }
    photo {
      id
      url
    }
  }
  ${NAME_COMMON_FRAGMENT}
`;

export const CODE_COMMON = gql`
  fragment CodeCommon on CodeableConcept {
    id
    text
    coding {
      id
      userSelected
      system
      version
      code
      display
    }
  }
`;

export const AuthUser = gql`
  fragment AuthUser on User {
    id
    email
    appRole
    profile {
      ...PractitionerCommon
    }
    organizations {
      id
      active
      name
      alias
    }
  }
  ${PRACTITIONER_COMMON}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VIEWER = gql`
  query Viewer($id: ID!) {
    users(where: { id: $id }) {
      ...AuthUser
    }
  }
  ${AuthUser}
`;

export const viewer = (id: string): Promise<AuthUserFragment | null> => {
  return client
    .query({
      query: ViewerDocument,
      variables: { id },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        console.error(errors);
      }
      return data?.users[0] ?? null;
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ...AuthUser
      }
    }
  }
  ${AuthUser}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REGISTER = gql`
  mutation Register($email: String!, $password: String!, $profile: PractitionerCreateInput!) {
    register(email: $email, password: $password, profile: $profile) {
      token
      user {
        ...AuthUser
      }
    }
  }
  ${AuthUser}
`;

export default useAuth;
