import { Box } from '@material-ui/core';
import { SubmitButton, TextField } from '@medorrohealth/mui-formik';
import { Form, Formik } from 'formik';
import type { FC } from 'react';
import * as Yup from 'yup';
import { GeneralFormError } from '../../../forms/general_form_error';
import useAuth from '../../../hooks/useAuth';

const LoginJWT: FC = () => {
  // const mounted = useMounted();
  const { login } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }): Promise<void> => {
        console.log('Login on submit', values);
        try {
          const result = await login(values);
          console.log('Login result');
          if (result) {
            setStatus({ success: true });
            setSubmitting(false);
          } else {
            setStatus({ success: false, error: 'Invalid email or password' });
            setSubmitting(false);
          }
        } catch (err) {
          console.log('Set Form Error');
          console.error(err);
          setStatus({ success: false, error: 'Invalid email or password' });
          setSubmitting(false);
        }
      }}
    >
      <Form>
        <TextField
          autoFocus
          fullWidth
          label="Email Address"
          margin="normal"
          name="email"
          type="email"
          variant="outlined"
        />
        <TextField fullWidth label="Password" margin="normal" name="password" type="password" variant="outlined" />
        <GeneralFormError />
        <Box sx={{ mt: 2 }}>
          <SubmitButton color="primary" fullWidth size="large" variant="contained">
            Log In
          </SubmitButton>
        </Box>
      </Form>
    </Formik>
  );
};

export default LoginJWT;
