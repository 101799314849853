import { Box, FormHelperText } from '@material-ui/core';
import { useFormikContext } from 'formik';

export function GeneralFormError() {
  const form = useFormikContext();
  if (!form.status?.error) return null;
  return (
    <Box sx={{ mt: 3 }}>
      <FormHelperText error>{form.status.error}</FormHelperText>
    </Box>
  );
}
