import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A stream of bytes */
  Base64Binary: any;
  /** A URI that is a reference to a canonical URL on a FHIR resource */
  Canonical: any;
  /** A string which has at least one character and no leading or trailing whitespace and where there is no whitespace other than single spaces in the contents */
  Code: any;
  Date: string;
  DateTime: string;
  /** A rational number with implicit precision */
  Decimal: any;
  /** An instant in time - known at least to the second */
  Instant: any;
  /** A string that may contain Github Flavored Markdown syntax for optional processing by a mark down presentation engine */
  Markdown: any;
  Number: any;
  /** An integer with a value that is positive (e.g. >0) */
  PositiveInt: any;
  /** Int | Float | String | Boolean | ID | DateTime */
  Scalar: any;
  /** A time during the day, with no date specified */
  Time: any;
  /** An integer with a value that is not negative (e.g. >= 0) */
  UnsignedInt: any;
  /** String of characters used to identify a name or a resource */
  Uri: any;
  /** A URI that is a literal reference */
  Url: any;
  /** xhtml - escaped html (see specfication) */
  Xhtml: any;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type Account = {
  __typename?: 'Account';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a Account resource */
  resourceType: AccountResourceType;
  /** Indicates whether the account is presently used/usable or not. */
  status?: Maybe<AccountStatus>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** Name used for the account when displaying it to humans in reports, etc. */
  name?: Maybe<Scalars['String']>;
  /** Provides additional information about what the account tracks and how it is used. */
  description?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _status?: Maybe<Element>;
  type?: Maybe<CodeableConcept>;
  _name?: Maybe<Element>;
  subject: Array<Reference>;
  servicePeriod?: Maybe<Period>;
  coverage: Array<AccountCoverage>;
  owner?: Maybe<Reference>;
  _description?: Maybe<Element>;
  guarantor: Array<AccountGuarantor>;
  partOf?: Maybe<Reference>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type Account_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type Account_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type Account_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type Account_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountSubjectArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountServicePeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountCoverageArgs = {
  where?: Maybe<AccountCoverageWhere>;
  options?: Maybe<AccountCoverageOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountOwnerArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type Account_DescriptionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountGuarantorArgs = {
  where?: Maybe<AccountGuarantorWhere>;
  options?: Maybe<AccountGuarantorOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountPartOfArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type AccountConnectFieldInput = {
  where?: Maybe<AccountWhere>;
  connect?: Maybe<AccountConnectInput>;
};

export type AccountConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  _name?: Maybe<ElementConnectFieldInput>;
  subject?: Maybe<Array<ReferenceConnectFieldInput>>;
  servicePeriod?: Maybe<PeriodConnectFieldInput>;
  coverage?: Maybe<Array<AccountCoverageConnectFieldInput>>;
  owner?: Maybe<ReferenceConnectFieldInput>;
  _description?: Maybe<ElementConnectFieldInput>;
  guarantor?: Maybe<Array<AccountGuarantorConnectFieldInput>>;
  partOf?: Maybe<ReferenceConnectFieldInput>;
};

export type AccountContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type AccountContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type AccountContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type AccountContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type AccountContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type AccountContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type AccountContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type AccountContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type AccountContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type AccountContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type AccountContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type AccountContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type AccountContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type AccountContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type AccountContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type AccountContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type AccountContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type AccountContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type AccountContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type AccountContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type AccountContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type AccountContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type AccountContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type AccountContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type AccountContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type AccountContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type AccountContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type AccountContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type AccountContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type AccountContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type AccountContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type AccountContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type AccountContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type AccountContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type AccountContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type AccountContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type AccountContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type AccountContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type AccountContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type AccountContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type AccountContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type AccountContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type AccountContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type AccountContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type AccountContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type AccountContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type AccountContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type AccountContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type AccountContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type AccountContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type AccountContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type AccountContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type AccountContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type AccountContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type AccountContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type AccountContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type AccountContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type AccountContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type AccountContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type AccountContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type AccountContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type AccountContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type AccountContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountCoverage = {
  __typename?: 'AccountCoverage';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The priority of the coverage in the context of this account. */
  priority?: Maybe<Scalars['PositiveInt']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  coverage: Reference;
  _priority?: Maybe<Element>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountCoverageExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountCoverageModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountCoverageCoverageArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountCoverage_PriorityArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type AccountCoverageConnectFieldInput = {
  where?: Maybe<AccountCoverageWhere>;
  connect?: Maybe<AccountCoverageConnectInput>;
};

export type AccountCoverageConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  coverage?: Maybe<ReferenceConnectFieldInput>;
  _priority?: Maybe<ElementConnectFieldInput>;
};

export type AccountCoverageCoverageDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AccountCoverageCoverageFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type AccountCoverageCoverageUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type AccountCoverageCreateInput = {
  priority?: Maybe<Scalars['PositiveInt']>;
  extension?: Maybe<AccountCoverageExtensionFieldInput>;
  modifierExtension?: Maybe<AccountCoverageModifierextensionFieldInput>;
  coverage?: Maybe<AccountCoverageCoverageFieldInput>;
  _priority?: Maybe<AccountCoverage_PriorityFieldInput>;
};

export type AccountCoverageDeleteFieldInput = {
  where?: Maybe<AccountCoverageWhere>;
  delete?: Maybe<AccountCoverageDeleteInput>;
};

export type AccountCoverageDeleteInput = {
  extension?: Maybe<Array<AccountCoverageExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<AccountCoverageModifierextensionDeleteFieldInput>>;
  coverage?: Maybe<AccountCoverageCoverageDeleteFieldInput>;
  _priority?: Maybe<AccountCoverage_PriorityDeleteFieldInput>;
};

export type AccountCoverageDisconnectFieldInput = {
  where?: Maybe<AccountCoverageWhere>;
  disconnect?: Maybe<AccountCoverageDisconnectInput>;
};

export type AccountCoverageDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  coverage?: Maybe<ReferenceDisconnectFieldInput>;
  _priority?: Maybe<ElementDisconnectFieldInput>;
};

export type AccountCoverageExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AccountCoverageExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AccountCoverageExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AccountCoverageFieldInput = {
  create?: Maybe<Array<AccountCoverageCreateInput>>;
  connect?: Maybe<Array<AccountCoverageConnectFieldInput>>;
};

export type AccountCoverageModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AccountCoverageModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AccountCoverageModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AccountCoverageOptions = {
  /** Specify one or more AccountCoverageSort objects to sort AccountCoverages by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AccountCoverageSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AccountCoverageRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  coverage?: Maybe<ReferenceCreateInput>;
  _priority?: Maybe<ElementCreateInput>;
};

/** Fields to sort AccountCoverages by. The order in which sorts are applied is not guaranteed when specifying many fields in one AccountCoverageSort object. */
export type AccountCoverageSort = {
  id?: Maybe<SortDirection>;
  priority?: Maybe<SortDirection>;
};

export type AccountCoverageUpdateFieldInput = {
  where?: Maybe<AccountCoverageWhere>;
  update?: Maybe<AccountCoverageUpdateInput>;
  connect?: Maybe<Array<AccountCoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountCoverageDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCoverageCreateInput>>;
  delete?: Maybe<Array<AccountCoverageDeleteFieldInput>>;
};

export type AccountCoverageUpdateInput = {
  priority?: Maybe<Scalars['PositiveInt']>;
  extension?: Maybe<Array<AccountCoverageExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<AccountCoverageModifierextensionUpdateFieldInput>>;
  coverage?: Maybe<AccountCoverageCoverageUpdateFieldInput>;
  _priority?: Maybe<AccountCoverage_PriorityUpdateFieldInput>;
};

export type AccountCoverageWhere = {
  OR?: Maybe<Array<AccountCoverageWhere>>;
  AND?: Maybe<Array<AccountCoverageWhere>>;
  priority?: Maybe<Scalars['PositiveInt']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  coverage?: Maybe<ReferenceWhere>;
  coverage_NOT?: Maybe<ReferenceWhere>;
  coverage_IN?: Maybe<Array<ReferenceWhere>>;
  coverage_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _priority?: Maybe<ElementWhere>;
  _priority_NOT?: Maybe<ElementWhere>;
  _priority_IN?: Maybe<Array<ElementWhere>>;
  _priority_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type AccountCoverage_PriorityDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AccountCoverage_PriorityFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AccountCoverage_PriorityUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AccountCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  resourceType: AccountResourceType;
  status?: Maybe<AccountStatus>;
  meta?: Maybe<AccountMetaFieldInput>;
  _implicitRules?: Maybe<Account_ImplicitrulesFieldInput>;
  _language?: Maybe<Account_LanguageFieldInput>;
  text?: Maybe<AccountTextFieldInput>;
  contained_Account?: Maybe<AccountContainedAccountFieldInput>;
  contained_Appointment?: Maybe<AccountContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<AccountContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<AccountContainedCoverageFieldInput>;
  contained_Device?: Maybe<AccountContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<AccountContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<AccountContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<AccountContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<AccountContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<AccountContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<AccountContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<AccountContainedLocationFieldInput>;
  contained_Organization?: Maybe<AccountContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<AccountContainedPatientFieldInput>;
  contained_Person?: Maybe<AccountContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<AccountContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<AccountContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<AccountContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<AccountContainedScheduleFieldInput>;
  contained_Slot?: Maybe<AccountContainedSlotFieldInput>;
  contained_Substance?: Maybe<AccountContainedSubstanceFieldInput>;
  extension?: Maybe<AccountExtensionFieldInput>;
  modifierExtension?: Maybe<AccountModifierextensionFieldInput>;
  identifier?: Maybe<AccountIdentifierFieldInput>;
  _status?: Maybe<Account_StatusFieldInput>;
  type?: Maybe<AccountTypeFieldInput>;
  _name?: Maybe<Account_NameFieldInput>;
  subject?: Maybe<AccountSubjectFieldInput>;
  servicePeriod?: Maybe<AccountServiceperiodFieldInput>;
  coverage?: Maybe<AccountCoverageFieldInput>;
  owner?: Maybe<AccountOwnerFieldInput>;
  _description?: Maybe<Account_DescriptionFieldInput>;
  guarantor?: Maybe<AccountGuarantorFieldInput>;
  partOf?: Maybe<AccountPartofFieldInput>;
};

export type AccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type AccountDeleteInput = {
  meta?: Maybe<AccountMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Account_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Account_LanguageDeleteFieldInput>;
  text?: Maybe<AccountTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<AccountContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<AccountContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AccountContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<AccountContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<AccountContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<AccountContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<AccountContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<AccountContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<AccountContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<AccountContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<AccountContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<AccountContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<AccountContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<AccountContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<AccountContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<AccountContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<AccountContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<AccountContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<AccountContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<AccountContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<AccountContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<AccountExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<AccountModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<AccountIdentifierDeleteFieldInput>>;
  _status?: Maybe<Account_StatusDeleteFieldInput>;
  type?: Maybe<AccountTypeDeleteFieldInput>;
  _name?: Maybe<Account_NameDeleteFieldInput>;
  subject?: Maybe<Array<AccountSubjectDeleteFieldInput>>;
  servicePeriod?: Maybe<AccountServiceperiodDeleteFieldInput>;
  coverage?: Maybe<Array<AccountCoverageDeleteFieldInput>>;
  owner?: Maybe<AccountOwnerDeleteFieldInput>;
  _description?: Maybe<Account_DescriptionDeleteFieldInput>;
  guarantor?: Maybe<Array<AccountGuarantorDeleteFieldInput>>;
  partOf?: Maybe<AccountPartofDeleteFieldInput>;
};

export type AccountDisconnectFieldInput = {
  where?: Maybe<AccountWhere>;
  disconnect?: Maybe<AccountDisconnectInput>;
};

export type AccountDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  subject?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  servicePeriod?: Maybe<PeriodDisconnectFieldInput>;
  coverage?: Maybe<Array<AccountCoverageDisconnectFieldInput>>;
  owner?: Maybe<ReferenceDisconnectFieldInput>;
  _description?: Maybe<ElementDisconnectFieldInput>;
  guarantor?: Maybe<Array<AccountGuarantorDisconnectFieldInput>>;
  partOf?: Maybe<ReferenceDisconnectFieldInput>;
};

export type AccountExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AccountExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AccountExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountGuarantor = {
  __typename?: 'AccountGuarantor';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** A guarantor may be placed on credit hold or otherwise have their role temporarily suspended. */
  onHold?: Maybe<Scalars['Boolean']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  party: Reference;
  _onHold?: Maybe<Element>;
  period?: Maybe<Period>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountGuarantorExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountGuarantorModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountGuarantorPartyArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountGuarantor_OnHoldArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
export type AccountGuarantorPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type AccountGuarantorConnectFieldInput = {
  where?: Maybe<AccountGuarantorWhere>;
  connect?: Maybe<AccountGuarantorConnectInput>;
};

export type AccountGuarantorConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  party?: Maybe<ReferenceConnectFieldInput>;
  _onHold?: Maybe<ElementConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type AccountGuarantorCreateInput = {
  onHold?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<AccountGuarantorExtensionFieldInput>;
  modifierExtension?: Maybe<AccountGuarantorModifierextensionFieldInput>;
  party?: Maybe<AccountGuarantorPartyFieldInput>;
  _onHold?: Maybe<AccountGuarantor_OnholdFieldInput>;
  period?: Maybe<AccountGuarantorPeriodFieldInput>;
};

export type AccountGuarantorDeleteFieldInput = {
  where?: Maybe<AccountGuarantorWhere>;
  delete?: Maybe<AccountGuarantorDeleteInput>;
};

export type AccountGuarantorDeleteInput = {
  extension?: Maybe<Array<AccountGuarantorExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<AccountGuarantorModifierextensionDeleteFieldInput>>;
  party?: Maybe<AccountGuarantorPartyDeleteFieldInput>;
  _onHold?: Maybe<AccountGuarantor_OnholdDeleteFieldInput>;
  period?: Maybe<AccountGuarantorPeriodDeleteFieldInput>;
};

export type AccountGuarantorDisconnectFieldInput = {
  where?: Maybe<AccountGuarantorWhere>;
  disconnect?: Maybe<AccountGuarantorDisconnectInput>;
};

export type AccountGuarantorDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  party?: Maybe<ReferenceDisconnectFieldInput>;
  _onHold?: Maybe<ElementDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type AccountGuarantorExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AccountGuarantorExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AccountGuarantorExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AccountGuarantorFieldInput = {
  create?: Maybe<Array<AccountGuarantorCreateInput>>;
  connect?: Maybe<Array<AccountGuarantorConnectFieldInput>>;
};

export type AccountGuarantorModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AccountGuarantorModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AccountGuarantorModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AccountGuarantorOptions = {
  /** Specify one or more AccountGuarantorSort objects to sort AccountGuarantors by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AccountGuarantorSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AccountGuarantorPartyDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AccountGuarantorPartyFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type AccountGuarantorPartyUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type AccountGuarantorPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type AccountGuarantorPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type AccountGuarantorPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type AccountGuarantorRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  party?: Maybe<ReferenceCreateInput>;
  _onHold?: Maybe<ElementCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

/** Fields to sort AccountGuarantors by. The order in which sorts are applied is not guaranteed when specifying many fields in one AccountGuarantorSort object. */
export type AccountGuarantorSort = {
  id?: Maybe<SortDirection>;
  onHold?: Maybe<SortDirection>;
};

export type AccountGuarantorUpdateFieldInput = {
  where?: Maybe<AccountGuarantorWhere>;
  update?: Maybe<AccountGuarantorUpdateInput>;
  connect?: Maybe<Array<AccountGuarantorConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountGuarantorDisconnectFieldInput>>;
  create?: Maybe<Array<AccountGuarantorCreateInput>>;
  delete?: Maybe<Array<AccountGuarantorDeleteFieldInput>>;
};

export type AccountGuarantorUpdateInput = {
  onHold?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Array<AccountGuarantorExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<AccountGuarantorModifierextensionUpdateFieldInput>>;
  party?: Maybe<AccountGuarantorPartyUpdateFieldInput>;
  _onHold?: Maybe<AccountGuarantor_OnholdUpdateFieldInput>;
  period?: Maybe<AccountGuarantorPeriodUpdateFieldInput>;
};

export type AccountGuarantorWhere = {
  OR?: Maybe<Array<AccountGuarantorWhere>>;
  AND?: Maybe<Array<AccountGuarantorWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  onHold?: Maybe<Scalars['Boolean']>;
  onHold_NOT?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  party?: Maybe<ReferenceWhere>;
  party_NOT?: Maybe<ReferenceWhere>;
  party_IN?: Maybe<Array<ReferenceWhere>>;
  party_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _onHold?: Maybe<ElementWhere>;
  _onHold_NOT?: Maybe<ElementWhere>;
  _onHold_IN?: Maybe<Array<ElementWhere>>;
  _onHold_NOT_IN?: Maybe<Array<ElementWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type AccountGuarantor_OnholdDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AccountGuarantor_OnholdFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AccountGuarantor_OnholdUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AccountIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type AccountIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type AccountIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type AccountMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type AccountMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type AccountMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type AccountModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AccountModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AccountModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AccountOptions = {
  /** Specify one or more AccountSort objects to sort Accounts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AccountSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AccountOwnerDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AccountOwnerFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type AccountOwnerUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type AccountPartofDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AccountPartofFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type AccountPartofUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type AccountRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  type?: Maybe<CodeableConceptCreateInput>;
  _name?: Maybe<ElementCreateInput>;
  subject?: Maybe<Array<ReferenceCreateInput>>;
  servicePeriod?: Maybe<PeriodCreateInput>;
  coverage?: Maybe<Array<AccountCoverageCreateInput>>;
  owner?: Maybe<ReferenceCreateInput>;
  _description?: Maybe<ElementCreateInput>;
  guarantor?: Maybe<Array<AccountGuarantorCreateInput>>;
  partOf?: Maybe<ReferenceCreateInput>;
};

export enum AccountResourceType {
  Account = 'Account',
}

export type AccountServiceperiodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type AccountServiceperiodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type AccountServiceperiodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

/** Fields to sort Accounts by. The order in which sorts are applied is not guaranteed when specifying many fields in one AccountSort object. */
export type AccountSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
};

/** Indicates whether the account is presently used/usable or not. */
export enum AccountStatus {
  Active = 'active',
  Inactive = 'inactive',
  EnteredInError = 'enteredInError',
  OnHold = 'onHold',
  Unknown = 'unknown',
}

export type AccountSubjectDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AccountSubjectFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type AccountSubjectUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type AccountTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type AccountTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type AccountTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type AccountTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type AccountTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type AccountTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type AccountUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  resourceType?: Maybe<AccountResourceType>;
  status?: Maybe<AccountStatus>;
  meta?: Maybe<AccountMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Account_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Account_LanguageUpdateFieldInput>;
  text?: Maybe<AccountTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<AccountContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<AccountContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AccountContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<AccountContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<AccountContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<AccountContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<AccountContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<AccountContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<AccountContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<AccountContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<AccountContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<AccountContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<AccountContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<AccountContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<AccountContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<AccountContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<AccountContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<AccountContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<AccountContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<AccountContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<AccountContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<AccountExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<AccountModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<AccountIdentifierUpdateFieldInput>>;
  _status?: Maybe<Account_StatusUpdateFieldInput>;
  type?: Maybe<AccountTypeUpdateFieldInput>;
  _name?: Maybe<Account_NameUpdateFieldInput>;
  subject?: Maybe<Array<AccountSubjectUpdateFieldInput>>;
  servicePeriod?: Maybe<AccountServiceperiodUpdateFieldInput>;
  coverage?: Maybe<Array<AccountCoverageUpdateFieldInput>>;
  owner?: Maybe<AccountOwnerUpdateFieldInput>;
  _description?: Maybe<Account_DescriptionUpdateFieldInput>;
  guarantor?: Maybe<Array<AccountGuarantorUpdateFieldInput>>;
  partOf?: Maybe<AccountPartofUpdateFieldInput>;
};

export type AccountWhere = {
  OR?: Maybe<Array<AccountWhere>>;
  AND?: Maybe<Array<AccountWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<AccountResourceType>;
  resourceType_NOT?: Maybe<AccountResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<AccountResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<AccountResourceType>>>;
  status?: Maybe<AccountStatus>;
  status_NOT?: Maybe<AccountStatus>;
  status_IN?: Maybe<Array<Maybe<AccountStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<AccountStatus>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  subject?: Maybe<ReferenceWhere>;
  subject_NOT?: Maybe<ReferenceWhere>;
  servicePeriod?: Maybe<PeriodWhere>;
  servicePeriod_NOT?: Maybe<PeriodWhere>;
  servicePeriod_IN?: Maybe<Array<PeriodWhere>>;
  servicePeriod_NOT_IN?: Maybe<Array<PeriodWhere>>;
  coverage?: Maybe<AccountCoverageWhere>;
  coverage_NOT?: Maybe<AccountCoverageWhere>;
  owner?: Maybe<ReferenceWhere>;
  owner_NOT?: Maybe<ReferenceWhere>;
  owner_IN?: Maybe<Array<ReferenceWhere>>;
  owner_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _description?: Maybe<ElementWhere>;
  _description_NOT?: Maybe<ElementWhere>;
  _description_IN?: Maybe<Array<ElementWhere>>;
  _description_NOT_IN?: Maybe<Array<ElementWhere>>;
  guarantor?: Maybe<AccountGuarantorWhere>;
  guarantor_NOT?: Maybe<AccountGuarantorWhere>;
  partOf?: Maybe<ReferenceWhere>;
  partOf_NOT?: Maybe<ReferenceWhere>;
  partOf_IN?: Maybe<Array<ReferenceWhere>>;
  partOf_NOT_IN?: Maybe<Array<ReferenceWhere>>;
};

export type Account_DescriptionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Account_DescriptionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Account_DescriptionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Account_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Account_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Account_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Account_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Account_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Account_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Account_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Account_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Account_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Account_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Account_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Account_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address = {
  __typename?: 'Address';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The purpose of this address. */
  use?: Maybe<AddressUse>;
  /** Distinguishes between physical addresses (those you can visit) and mailing addresses (e.g. PO Boxes and care-of addresses). Most addresses are both. */
  type?: Maybe<AddressType>;
  /** Specifies the entire address as it should be displayed e.g. on a postal label. This may be provided instead of or as well as the specific parts. */
  text?: Maybe<Scalars['String']>;
  /** This component contains the house number, apartment number, street name, street direction,  P.O. Box number, delivery hints, and similar address information. */
  line: Array<Scalars['String']>;
  /** The name of the city, town, suburb, village or other community or delivery center. */
  city?: Maybe<Scalars['String']>;
  /** The name of the administrative area (county). */
  district?: Maybe<Scalars['String']>;
  /** Sub-unit of a country with limited sovereignty in a federally organized country. A code may be used if codes are in common use (e.g. US 2 letter state codes). */
  state?: Maybe<Scalars['String']>;
  /** A postal code designating a region defined by the postal service. */
  postalCode?: Maybe<Scalars['String']>;
  /** Country - a nation as commonly understood or generally accepted. */
  country?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  _use?: Maybe<Element>;
  _type?: Maybe<Element>;
  _text?: Maybe<Element>;
  _line: Array<Element>;
  _city?: Maybe<Element>;
  _district?: Maybe<Element>;
  _state?: Maybe<Element>;
  _postalCode?: Maybe<Element>;
  _country?: Maybe<Element>;
  period?: Maybe<Period>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type AddressExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address_UseArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address_TextArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address_LineArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address_CityArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address_DistrictArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address_StateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address_PostalCodeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type Address_CountryArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats).  This data type may be used to convey addresses for use in delivering mail as well as for visiting locations which might not be valid for mail delivery.  There are a variety of postal address formats defined around the world. */
export type AddressPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type AddressConnectFieldInput = {
  where?: Maybe<AddressWhere>;
  connect?: Maybe<AddressConnectInput>;
};

export type AddressConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _use?: Maybe<ElementConnectFieldInput>;
  _type?: Maybe<ElementConnectFieldInput>;
  _text?: Maybe<ElementConnectFieldInput>;
  _line?: Maybe<Array<ElementConnectFieldInput>>;
  _city?: Maybe<ElementConnectFieldInput>;
  _district?: Maybe<ElementConnectFieldInput>;
  _state?: Maybe<ElementConnectFieldInput>;
  _postalCode?: Maybe<ElementConnectFieldInput>;
  _country?: Maybe<ElementConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type AddressCreateInput = {
  text?: Maybe<Scalars['String']>;
  line: Array<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  use?: Maybe<AddressUse>;
  type?: Maybe<AddressType>;
  extension?: Maybe<AddressExtensionFieldInput>;
  _use?: Maybe<Address_UseFieldInput>;
  _type?: Maybe<Address_TypeFieldInput>;
  _text?: Maybe<Address_TextFieldInput>;
  _line?: Maybe<Address_LineFieldInput>;
  _city?: Maybe<Address_CityFieldInput>;
  _district?: Maybe<Address_DistrictFieldInput>;
  _state?: Maybe<Address_StateFieldInput>;
  _postalCode?: Maybe<Address_PostalcodeFieldInput>;
  _country?: Maybe<Address_CountryFieldInput>;
  period?: Maybe<AddressPeriodFieldInput>;
};

export type AddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type AddressDeleteInput = {
  extension?: Maybe<Array<AddressExtensionDeleteFieldInput>>;
  _use?: Maybe<Address_UseDeleteFieldInput>;
  _type?: Maybe<Address_TypeDeleteFieldInput>;
  _text?: Maybe<Address_TextDeleteFieldInput>;
  _line?: Maybe<Array<Address_LineDeleteFieldInput>>;
  _city?: Maybe<Address_CityDeleteFieldInput>;
  _district?: Maybe<Address_DistrictDeleteFieldInput>;
  _state?: Maybe<Address_StateDeleteFieldInput>;
  _postalCode?: Maybe<Address_PostalcodeDeleteFieldInput>;
  _country?: Maybe<Address_CountryDeleteFieldInput>;
  period?: Maybe<AddressPeriodDeleteFieldInput>;
};

export type AddressDisconnectFieldInput = {
  where?: Maybe<AddressWhere>;
  disconnect?: Maybe<AddressDisconnectInput>;
};

export type AddressDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _use?: Maybe<ElementDisconnectFieldInput>;
  _type?: Maybe<ElementDisconnectFieldInput>;
  _text?: Maybe<ElementDisconnectFieldInput>;
  _line?: Maybe<Array<ElementDisconnectFieldInput>>;
  _city?: Maybe<ElementDisconnectFieldInput>;
  _district?: Maybe<ElementDisconnectFieldInput>;
  _state?: Maybe<ElementDisconnectFieldInput>;
  _postalCode?: Maybe<ElementDisconnectFieldInput>;
  _country?: Maybe<ElementDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type AddressExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AddressExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AddressExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AddressOptions = {
  /** Specify one or more AddressSort objects to sort Addresses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AddressSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AddressPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type AddressPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type AddressPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type AddressRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _use?: Maybe<ElementCreateInput>;
  _type?: Maybe<ElementCreateInput>;
  _text?: Maybe<ElementCreateInput>;
  _line?: Maybe<Array<ElementCreateInput>>;
  _city?: Maybe<ElementCreateInput>;
  _district?: Maybe<ElementCreateInput>;
  _state?: Maybe<ElementCreateInput>;
  _postalCode?: Maybe<ElementCreateInput>;
  _country?: Maybe<ElementCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

/** Fields to sort Addresses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AddressSort object. */
export type AddressSort = {
  id?: Maybe<SortDirection>;
  use?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  text?: Maybe<SortDirection>;
  city?: Maybe<SortDirection>;
  district?: Maybe<SortDirection>;
  state?: Maybe<SortDirection>;
  postalCode?: Maybe<SortDirection>;
  country?: Maybe<SortDirection>;
};

/** Distinguishes between physical addresses (those you can visit) and mailing addresses (e.g. PO Boxes and care-of addresses). Most addresses are both. */
export enum AddressType {
  Postal = 'postal',
  Physical = 'physical',
  Both = 'both',
}

export type AddressUpdateInput = {
  text?: Maybe<Scalars['String']>;
  line?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  use?: Maybe<AddressUse>;
  type?: Maybe<AddressType>;
  extension?: Maybe<Array<AddressExtensionUpdateFieldInput>>;
  _use?: Maybe<Address_UseUpdateFieldInput>;
  _type?: Maybe<Address_TypeUpdateFieldInput>;
  _text?: Maybe<Address_TextUpdateFieldInput>;
  _line?: Maybe<Array<Address_LineUpdateFieldInput>>;
  _city?: Maybe<Address_CityUpdateFieldInput>;
  _district?: Maybe<Address_DistrictUpdateFieldInput>;
  _state?: Maybe<Address_StateUpdateFieldInput>;
  _postalCode?: Maybe<Address_PostalcodeUpdateFieldInput>;
  _country?: Maybe<Address_CountryUpdateFieldInput>;
  period?: Maybe<AddressPeriodUpdateFieldInput>;
};

/** The purpose of this address. */
export enum AddressUse {
  Home = 'home',
  Work = 'work',
  Temp = 'temp',
  Old = 'old',
  Billing = 'billing',
}

export type AddressWhere = {
  OR?: Maybe<Array<AddressWhere>>;
  AND?: Maybe<Array<AddressWhere>>;
  text?: Maybe<Scalars['String']>;
  line?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  use?: Maybe<AddressUse>;
  use_NOT?: Maybe<AddressUse>;
  use_IN?: Maybe<Array<Maybe<AddressUse>>>;
  use_NOT_IN?: Maybe<Array<Maybe<AddressUse>>>;
  type?: Maybe<AddressType>;
  type_NOT?: Maybe<AddressType>;
  type_IN?: Maybe<Array<Maybe<AddressType>>>;
  type_NOT_IN?: Maybe<Array<Maybe<AddressType>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _use?: Maybe<ElementWhere>;
  _use_NOT?: Maybe<ElementWhere>;
  _use_IN?: Maybe<Array<ElementWhere>>;
  _use_NOT_IN?: Maybe<Array<ElementWhere>>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
  _text?: Maybe<ElementWhere>;
  _text_NOT?: Maybe<ElementWhere>;
  _text_IN?: Maybe<Array<ElementWhere>>;
  _text_NOT_IN?: Maybe<Array<ElementWhere>>;
  _line?: Maybe<ElementWhere>;
  _line_NOT?: Maybe<ElementWhere>;
  _city?: Maybe<ElementWhere>;
  _city_NOT?: Maybe<ElementWhere>;
  _city_IN?: Maybe<Array<ElementWhere>>;
  _city_NOT_IN?: Maybe<Array<ElementWhere>>;
  _district?: Maybe<ElementWhere>;
  _district_NOT?: Maybe<ElementWhere>;
  _district_IN?: Maybe<Array<ElementWhere>>;
  _district_NOT_IN?: Maybe<Array<ElementWhere>>;
  _state?: Maybe<ElementWhere>;
  _state_NOT?: Maybe<ElementWhere>;
  _state_IN?: Maybe<Array<ElementWhere>>;
  _state_NOT_IN?: Maybe<Array<ElementWhere>>;
  _postalCode?: Maybe<ElementWhere>;
  _postalCode_NOT?: Maybe<ElementWhere>;
  _postalCode_IN?: Maybe<Array<ElementWhere>>;
  _postalCode_NOT_IN?: Maybe<Array<ElementWhere>>;
  _country?: Maybe<ElementWhere>;
  _country_NOT?: Maybe<ElementWhere>;
  _country_IN?: Maybe<Array<ElementWhere>>;
  _country_NOT_IN?: Maybe<Array<ElementWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type Address_CityDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Address_CityFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Address_CityUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Address_CountryDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Address_CountryFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Address_CountryUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Address_DistrictDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Address_DistrictFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Address_DistrictUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Address_LineDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Address_LineFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type Address_LineUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type Address_PostalcodeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Address_PostalcodeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Address_PostalcodeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Address_StateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Address_StateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Address_StateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Address_TextDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Address_TextFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Address_TextUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Address_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Address_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Address_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Address_UseDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Address_UseFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Address_UseUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A duration of time during which an organism (or a process) has existed. */
export type Age = {
  __typename?: 'Age';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
  comparator?: Maybe<AgeComparator>;
  /** The value of the measured amount. The value includes an implicit precision in the presentation of the value. */
  value?: Maybe<Scalars['Decimal']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['String']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['Uri']>;
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['Code']>;
  extension: Array<Extension>;
  _value?: Maybe<Element>;
  _comparator?: Maybe<Element>;
  _unit?: Maybe<Element>;
  _system?: Maybe<Element>;
  _code?: Maybe<Element>;
};

/** A duration of time during which an organism (or a process) has existed. */
export type AgeExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A duration of time during which an organism (or a process) has existed. */
export type Age_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A duration of time during which an organism (or a process) has existed. */
export type Age_ComparatorArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A duration of time during which an organism (or a process) has existed. */
export type Age_UnitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A duration of time during which an organism (or a process) has existed. */
export type Age_SystemArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A duration of time during which an organism (or a process) has existed. */
export type Age_CodeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
export enum AgeComparator {
  Lt = 'lt',
  Lte = 'lte',
  Gte = 'gte',
  Gt = 'gt',
}

export type AgeConnectFieldInput = {
  where?: Maybe<AgeWhere>;
  connect?: Maybe<AgeConnectInput>;
};

export type AgeConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _value?: Maybe<ElementConnectFieldInput>;
  _comparator?: Maybe<ElementConnectFieldInput>;
  _unit?: Maybe<ElementConnectFieldInput>;
  _system?: Maybe<ElementConnectFieldInput>;
  _code?: Maybe<ElementConnectFieldInput>;
};

export type AgeCreateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<AgeComparator>;
  extension?: Maybe<AgeExtensionFieldInput>;
  _value?: Maybe<Age_ValueFieldInput>;
  _comparator?: Maybe<Age_ComparatorFieldInput>;
  _unit?: Maybe<Age_UnitFieldInput>;
  _system?: Maybe<Age_SystemFieldInput>;
  _code?: Maybe<Age_CodeFieldInput>;
};

export type AgeDeleteFieldInput = {
  where?: Maybe<AgeWhere>;
  delete?: Maybe<AgeDeleteInput>;
};

export type AgeDeleteInput = {
  extension?: Maybe<Array<AgeExtensionDeleteFieldInput>>;
  _value?: Maybe<Age_ValueDeleteFieldInput>;
  _comparator?: Maybe<Age_ComparatorDeleteFieldInput>;
  _unit?: Maybe<Age_UnitDeleteFieldInput>;
  _system?: Maybe<Age_SystemDeleteFieldInput>;
  _code?: Maybe<Age_CodeDeleteFieldInput>;
};

export type AgeDisconnectFieldInput = {
  where?: Maybe<AgeWhere>;
  disconnect?: Maybe<AgeDisconnectInput>;
};

export type AgeDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _value?: Maybe<ElementDisconnectFieldInput>;
  _comparator?: Maybe<ElementDisconnectFieldInput>;
  _unit?: Maybe<ElementDisconnectFieldInput>;
  _system?: Maybe<ElementDisconnectFieldInput>;
  _code?: Maybe<ElementDisconnectFieldInput>;
};

export type AgeExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AgeExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AgeExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AgeOptions = {
  /** Specify one or more AgeSort objects to sort Ages by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AgeSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AgeRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _value?: Maybe<ElementCreateInput>;
  _comparator?: Maybe<ElementCreateInput>;
  _unit?: Maybe<ElementCreateInput>;
  _system?: Maybe<ElementCreateInput>;
  _code?: Maybe<ElementCreateInput>;
};

/** Fields to sort Ages by. The order in which sorts are applied is not guaranteed when specifying many fields in one AgeSort object. */
export type AgeSort = {
  id?: Maybe<SortDirection>;
  comparator?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  unit?: Maybe<SortDirection>;
  system?: Maybe<SortDirection>;
  code?: Maybe<SortDirection>;
};

export type AgeUpdateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<AgeComparator>;
  extension?: Maybe<Array<AgeExtensionUpdateFieldInput>>;
  _value?: Maybe<Age_ValueUpdateFieldInput>;
  _comparator?: Maybe<Age_ComparatorUpdateFieldInput>;
  _unit?: Maybe<Age_UnitUpdateFieldInput>;
  _system?: Maybe<Age_SystemUpdateFieldInput>;
  _code?: Maybe<Age_CodeUpdateFieldInput>;
};

export type AgeWhere = {
  OR?: Maybe<Array<AgeWhere>>;
  AND?: Maybe<Array<AgeWhere>>;
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  comparator?: Maybe<AgeComparator>;
  comparator_NOT?: Maybe<AgeComparator>;
  comparator_IN?: Maybe<Array<Maybe<AgeComparator>>>;
  comparator_NOT_IN?: Maybe<Array<Maybe<AgeComparator>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
  _comparator?: Maybe<ElementWhere>;
  _comparator_NOT?: Maybe<ElementWhere>;
  _comparator_IN?: Maybe<Array<ElementWhere>>;
  _comparator_NOT_IN?: Maybe<Array<ElementWhere>>;
  _unit?: Maybe<ElementWhere>;
  _unit_NOT?: Maybe<ElementWhere>;
  _unit_IN?: Maybe<Array<ElementWhere>>;
  _unit_NOT_IN?: Maybe<Array<ElementWhere>>;
  _system?: Maybe<ElementWhere>;
  _system_NOT?: Maybe<ElementWhere>;
  _system_IN?: Maybe<Array<ElementWhere>>;
  _system_NOT_IN?: Maybe<Array<ElementWhere>>;
  _code?: Maybe<ElementWhere>;
  _code_NOT?: Maybe<ElementWhere>;
  _code_IN?: Maybe<Array<ElementWhere>>;
  _code_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Age_CodeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Age_CodeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Age_CodeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Age_ComparatorDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Age_ComparatorFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Age_ComparatorUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Age_SystemDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Age_SystemFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Age_SystemUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Age_UnitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Age_UnitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Age_UnitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Age_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Age_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Age_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A  text note which also  contains information about who made the statement and when. */
export type Annotation = {
  __typename?: 'Annotation';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The individual responsible for making the annotation. */
  authorString?: Maybe<Scalars['String']>;
  /** Indicates when this particular annotation was made. */
  time?: Maybe<Scalars['DateTime']>;
  /** The text of the annotation in markdown format. */
  text?: Maybe<Scalars['Markdown']>;
  extension: Array<Extension>;
  authorReference?: Maybe<Reference>;
  _authorString?: Maybe<Element>;
  _time?: Maybe<Element>;
  _text?: Maybe<Element>;
};

/** A  text note which also  contains information about who made the statement and when. */
export type AnnotationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A  text note which also  contains information about who made the statement and when. */
export type AnnotationAuthorReferenceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A  text note which also  contains information about who made the statement and when. */
export type Annotation_AuthorStringArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A  text note which also  contains information about who made the statement and when. */
export type Annotation_TimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A  text note which also  contains information about who made the statement and when. */
export type Annotation_TextArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type AnnotationAuthorreferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AnnotationAuthorreferenceFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type AnnotationAuthorreferenceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type AnnotationConnectFieldInput = {
  where?: Maybe<AnnotationWhere>;
  connect?: Maybe<AnnotationConnectInput>;
};

export type AnnotationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  authorReference?: Maybe<ReferenceConnectFieldInput>;
  _authorString?: Maybe<ElementConnectFieldInput>;
  _time?: Maybe<ElementConnectFieldInput>;
  _text?: Maybe<ElementConnectFieldInput>;
};

export type AnnotationCreateInput = {
  authorString?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['Markdown']>;
  extension?: Maybe<AnnotationExtensionFieldInput>;
  authorReference?: Maybe<AnnotationAuthorreferenceFieldInput>;
  _authorString?: Maybe<Annotation_AuthorstringFieldInput>;
  _time?: Maybe<Annotation_TimeFieldInput>;
  _text?: Maybe<Annotation_TextFieldInput>;
};

export type AnnotationDeleteFieldInput = {
  where?: Maybe<AnnotationWhere>;
  delete?: Maybe<AnnotationDeleteInput>;
};

export type AnnotationDeleteInput = {
  extension?: Maybe<Array<AnnotationExtensionDeleteFieldInput>>;
  authorReference?: Maybe<AnnotationAuthorreferenceDeleteFieldInput>;
  _authorString?: Maybe<Annotation_AuthorstringDeleteFieldInput>;
  _time?: Maybe<Annotation_TimeDeleteFieldInput>;
  _text?: Maybe<Annotation_TextDeleteFieldInput>;
};

export type AnnotationDisconnectFieldInput = {
  where?: Maybe<AnnotationWhere>;
  disconnect?: Maybe<AnnotationDisconnectInput>;
};

export type AnnotationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  authorReference?: Maybe<ReferenceDisconnectFieldInput>;
  _authorString?: Maybe<ElementDisconnectFieldInput>;
  _time?: Maybe<ElementDisconnectFieldInput>;
  _text?: Maybe<ElementDisconnectFieldInput>;
};

export type AnnotationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AnnotationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AnnotationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AnnotationOptions = {
  /** Specify one or more AnnotationSort objects to sort Annotations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AnnotationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AnnotationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  authorReference?: Maybe<ReferenceCreateInput>;
  _authorString?: Maybe<ElementCreateInput>;
  _time?: Maybe<ElementCreateInput>;
  _text?: Maybe<ElementCreateInput>;
};

/** Fields to sort Annotations by. The order in which sorts are applied is not guaranteed when specifying many fields in one AnnotationSort object. */
export type AnnotationSort = {
  id?: Maybe<SortDirection>;
  authorString?: Maybe<SortDirection>;
  time?: Maybe<SortDirection>;
  text?: Maybe<SortDirection>;
};

export type AnnotationUpdateInput = {
  authorString?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['Markdown']>;
  extension?: Maybe<Array<AnnotationExtensionUpdateFieldInput>>;
  authorReference?: Maybe<AnnotationAuthorreferenceUpdateFieldInput>;
  _authorString?: Maybe<Annotation_AuthorstringUpdateFieldInput>;
  _time?: Maybe<Annotation_TimeUpdateFieldInput>;
  _text?: Maybe<Annotation_TextUpdateFieldInput>;
};

export type AnnotationWhere = {
  OR?: Maybe<Array<AnnotationWhere>>;
  AND?: Maybe<Array<AnnotationWhere>>;
  authorString?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['Markdown']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  authorReference?: Maybe<ReferenceWhere>;
  authorReference_NOT?: Maybe<ReferenceWhere>;
  authorReference_IN?: Maybe<Array<ReferenceWhere>>;
  authorReference_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _authorString?: Maybe<ElementWhere>;
  _authorString_NOT?: Maybe<ElementWhere>;
  _authorString_IN?: Maybe<Array<ElementWhere>>;
  _authorString_NOT_IN?: Maybe<Array<ElementWhere>>;
  _time?: Maybe<ElementWhere>;
  _time_NOT?: Maybe<ElementWhere>;
  _time_IN?: Maybe<Array<ElementWhere>>;
  _time_NOT_IN?: Maybe<Array<ElementWhere>>;
  _text?: Maybe<ElementWhere>;
  _text_NOT?: Maybe<ElementWhere>;
  _text_IN?: Maybe<Array<ElementWhere>>;
  _text_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Annotation_AuthorstringDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Annotation_AuthorstringFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Annotation_AuthorstringUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Annotation_TextDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Annotation_TextFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Annotation_TextUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Annotation_TimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Annotation_TimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Annotation_TimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export enum AppRole {
  User = 'user',
  Admin = 'admin',
}

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment = {
  __typename?: 'Appointment';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a Appointment resource */
  resourceType: AppointmentResourceType;
  /** The overall status of the Appointment. Each of the participants has their own participation status which indicates their involvement in the process, however this status indicates the shared status. */
  status?: Maybe<AppointmentStatus>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** The priority of the appointment. Can be used to make informed decisions if needing to re-prioritize appointments. (The iCal Standard specifies 0 as undefined, 1 as highest, 9 as lowest priority). */
  priority?: Maybe<Scalars['UnsignedInt']>;
  /** The brief description of the appointment as would be shown on a subject line in a meeting request, or appointment list. Detailed or expanded information should be put in the comment field. */
  description?: Maybe<Scalars['String']>;
  /** Date/Time that the appointment is to take place. */
  start?: Maybe<Scalars['Instant']>;
  /** Date/Time that the appointment is to conclude. */
  end?: Maybe<Scalars['Instant']>;
  /** Number of minutes that the appointment is to take. This can be less than the duration between the start and end times.  For example, where the actual time of appointment is only an estimate or if a 30 minute appointment is being requested, but any time would work.  Also, if there is, for example, a planned 15 minute break in the middle of a long appointment, the duration may be 15 minutes less than the difference between the start and end. */
  minutesDuration?: Maybe<Scalars['PositiveInt']>;
  /** The date that this appointment was initially created. This could be different to the meta.lastModified value on the initial entry, as this could have been before the resource was created on the FHIR server, and should remain unchanged over the lifespan of the appointment. */
  created?: Maybe<Scalars['DateTime']>;
  /** Additional comments about the appointment. */
  comment?: Maybe<Scalars['String']>;
  /** While Appointment.comment contains information for internal use, Appointment.patientInstructions is used to capture patient facing information about the Appointment (e.g. please bring your referral or fast from 8pm night before). */
  patientInstruction?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _status?: Maybe<Element>;
  cancelationReason?: Maybe<CodeableConcept>;
  serviceCategory: Array<CodeableConcept>;
  serviceType: Array<CodeableConcept>;
  specialty: Array<CodeableConcept>;
  appointmentType?: Maybe<CodeableConcept>;
  reasonCode: Array<CodeableConcept>;
  reasonReference: Array<Reference>;
  _priority?: Maybe<Element>;
  _description?: Maybe<Element>;
  supportingInformation: Array<Reference>;
  _start?: Maybe<Element>;
  _end?: Maybe<Element>;
  _minutesDuration?: Maybe<Element>;
  slot: Array<Reference>;
  _created?: Maybe<Element>;
  _comment?: Maybe<Element>;
  _patientInstruction?: Maybe<Element>;
  basedOn: Array<Reference>;
  participant: Array<AppointmentParticipant>;
  requestedPeriod: Array<Period>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentCancelationReasonArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentServiceCategoryArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentServiceTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentSpecialtyArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentAppointmentTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentReasonCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentReasonReferenceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_PriorityArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_DescriptionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentSupportingInformationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_StartArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_EndArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_MinutesDurationArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentSlotArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_CreatedArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_CommentArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type Appointment_PatientInstructionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentBasedOnArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentParticipantArgs = {
  where?: Maybe<AppointmentParticipantWhere>;
  options?: Maybe<AppointmentParticipantOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentRequestedPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type AppointmentAppointmenttypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type AppointmentAppointmenttypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type AppointmentAppointmenttypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type AppointmentBasedonDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AppointmentBasedonFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type AppointmentBasedonUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type AppointmentCancelationreasonDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type AppointmentCancelationreasonFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type AppointmentCancelationreasonUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type AppointmentConnectFieldInput = {
  where?: Maybe<AppointmentWhere>;
  connect?: Maybe<AppointmentConnectInput>;
};

export type AppointmentConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  cancelationReason?: Maybe<CodeableConceptConnectFieldInput>;
  serviceCategory?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  serviceType?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  appointmentType?: Maybe<CodeableConceptConnectFieldInput>;
  reasonCode?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  reasonReference?: Maybe<Array<ReferenceConnectFieldInput>>;
  _priority?: Maybe<ElementConnectFieldInput>;
  _description?: Maybe<ElementConnectFieldInput>;
  supportingInformation?: Maybe<Array<ReferenceConnectFieldInput>>;
  _start?: Maybe<ElementConnectFieldInput>;
  _end?: Maybe<ElementConnectFieldInput>;
  _minutesDuration?: Maybe<ElementConnectFieldInput>;
  slot?: Maybe<Array<ReferenceConnectFieldInput>>;
  _created?: Maybe<ElementConnectFieldInput>;
  _comment?: Maybe<ElementConnectFieldInput>;
  _patientInstruction?: Maybe<ElementConnectFieldInput>;
  basedOn?: Maybe<Array<ReferenceConnectFieldInput>>;
  participant?: Maybe<Array<AppointmentParticipantConnectFieldInput>>;
  requestedPeriod?: Maybe<Array<PeriodConnectFieldInput>>;
};

export type AppointmentContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type AppointmentContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type AppointmentContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type AppointmentContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type AppointmentContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type AppointmentContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type AppointmentContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type AppointmentContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type AppointmentContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type AppointmentContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type AppointmentContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type AppointmentContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type AppointmentContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type AppointmentContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type AppointmentContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type AppointmentContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type AppointmentContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type AppointmentContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type AppointmentContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type AppointmentContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type AppointmentContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type AppointmentContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type AppointmentContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type AppointmentContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type AppointmentContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type AppointmentContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type AppointmentContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type AppointmentContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type AppointmentContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type AppointmentContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type AppointmentContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type AppointmentContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type AppointmentContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type AppointmentContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type AppointmentContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type AppointmentContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type AppointmentContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type AppointmentContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type AppointmentContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type AppointmentContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type AppointmentContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type AppointmentContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type AppointmentContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type AppointmentContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type AppointmentContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type AppointmentContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type AppointmentContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type AppointmentContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type AppointmentContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type AppointmentContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type AppointmentContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type AppointmentContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type AppointmentContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type AppointmentContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type AppointmentContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type AppointmentContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type AppointmentContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type AppointmentContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type AppointmentContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type AppointmentContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type AppointmentContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type AppointmentContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type AppointmentContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type AppointmentCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  priority?: Maybe<Scalars['UnsignedInt']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Instant']>;
  end?: Maybe<Scalars['Instant']>;
  minutesDuration?: Maybe<Scalars['PositiveInt']>;
  created?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  patientInstruction?: Maybe<Scalars['String']>;
  resourceType: AppointmentResourceType;
  status?: Maybe<AppointmentStatus>;
  meta?: Maybe<AppointmentMetaFieldInput>;
  _implicitRules?: Maybe<Appointment_ImplicitrulesFieldInput>;
  _language?: Maybe<Appointment_LanguageFieldInput>;
  text?: Maybe<AppointmentTextFieldInput>;
  contained_Account?: Maybe<AppointmentContainedAccountFieldInput>;
  contained_Appointment?: Maybe<AppointmentContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<AppointmentContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<AppointmentContainedCoverageFieldInput>;
  contained_Device?: Maybe<AppointmentContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<AppointmentContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<AppointmentContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<AppointmentContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<AppointmentContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<AppointmentContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<AppointmentContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<AppointmentContainedLocationFieldInput>;
  contained_Organization?: Maybe<AppointmentContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<AppointmentContainedPatientFieldInput>;
  contained_Person?: Maybe<AppointmentContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<AppointmentContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<AppointmentContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<AppointmentContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<AppointmentContainedScheduleFieldInput>;
  contained_Slot?: Maybe<AppointmentContainedSlotFieldInput>;
  contained_Substance?: Maybe<AppointmentContainedSubstanceFieldInput>;
  extension?: Maybe<AppointmentExtensionFieldInput>;
  modifierExtension?: Maybe<AppointmentModifierextensionFieldInput>;
  identifier?: Maybe<AppointmentIdentifierFieldInput>;
  _status?: Maybe<Appointment_StatusFieldInput>;
  cancelationReason?: Maybe<AppointmentCancelationreasonFieldInput>;
  serviceCategory?: Maybe<AppointmentServicecategoryFieldInput>;
  serviceType?: Maybe<AppointmentServicetypeFieldInput>;
  specialty?: Maybe<AppointmentSpecialtyFieldInput>;
  appointmentType?: Maybe<AppointmentAppointmenttypeFieldInput>;
  reasonCode?: Maybe<AppointmentReasoncodeFieldInput>;
  reasonReference?: Maybe<AppointmentReasonreferenceFieldInput>;
  _priority?: Maybe<Appointment_PriorityFieldInput>;
  _description?: Maybe<Appointment_DescriptionFieldInput>;
  supportingInformation?: Maybe<AppointmentSupportinginformationFieldInput>;
  _start?: Maybe<Appointment_StartFieldInput>;
  _end?: Maybe<Appointment_EndFieldInput>;
  _minutesDuration?: Maybe<Appointment_MinutesdurationFieldInput>;
  slot?: Maybe<AppointmentSlotFieldInput>;
  _created?: Maybe<Appointment_CreatedFieldInput>;
  _comment?: Maybe<Appointment_CommentFieldInput>;
  _patientInstruction?: Maybe<Appointment_PatientinstructionFieldInput>;
  basedOn?: Maybe<AppointmentBasedonFieldInput>;
  participant?: Maybe<AppointmentParticipantFieldInput>;
  requestedPeriod?: Maybe<AppointmentRequestedperiodFieldInput>;
};

export type AppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type AppointmentDeleteInput = {
  meta?: Maybe<AppointmentMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Appointment_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Appointment_LanguageDeleteFieldInput>;
  text?: Maybe<AppointmentTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<AppointmentContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<AppointmentContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<AppointmentContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<AppointmentContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<AppointmentContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<AppointmentContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<AppointmentContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<AppointmentContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<AppointmentContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<AppointmentContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<AppointmentContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<AppointmentContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<AppointmentContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<AppointmentContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<AppointmentContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<AppointmentContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<AppointmentContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<AppointmentContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<AppointmentContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<AppointmentExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<AppointmentModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<AppointmentIdentifierDeleteFieldInput>>;
  _status?: Maybe<Appointment_StatusDeleteFieldInput>;
  cancelationReason?: Maybe<AppointmentCancelationreasonDeleteFieldInput>;
  serviceCategory?: Maybe<Array<AppointmentServicecategoryDeleteFieldInput>>;
  serviceType?: Maybe<Array<AppointmentServicetypeDeleteFieldInput>>;
  specialty?: Maybe<Array<AppointmentSpecialtyDeleteFieldInput>>;
  appointmentType?: Maybe<AppointmentAppointmenttypeDeleteFieldInput>;
  reasonCode?: Maybe<Array<AppointmentReasoncodeDeleteFieldInput>>;
  reasonReference?: Maybe<Array<AppointmentReasonreferenceDeleteFieldInput>>;
  _priority?: Maybe<Appointment_PriorityDeleteFieldInput>;
  _description?: Maybe<Appointment_DescriptionDeleteFieldInput>;
  supportingInformation?: Maybe<Array<AppointmentSupportinginformationDeleteFieldInput>>;
  _start?: Maybe<Appointment_StartDeleteFieldInput>;
  _end?: Maybe<Appointment_EndDeleteFieldInput>;
  _minutesDuration?: Maybe<Appointment_MinutesdurationDeleteFieldInput>;
  slot?: Maybe<Array<AppointmentSlotDeleteFieldInput>>;
  _created?: Maybe<Appointment_CreatedDeleteFieldInput>;
  _comment?: Maybe<Appointment_CommentDeleteFieldInput>;
  _patientInstruction?: Maybe<Appointment_PatientinstructionDeleteFieldInput>;
  basedOn?: Maybe<Array<AppointmentBasedonDeleteFieldInput>>;
  participant?: Maybe<Array<AppointmentParticipantDeleteFieldInput>>;
  requestedPeriod?: Maybe<Array<AppointmentRequestedperiodDeleteFieldInput>>;
};

export type AppointmentDisconnectFieldInput = {
  where?: Maybe<AppointmentWhere>;
  disconnect?: Maybe<AppointmentDisconnectInput>;
};

export type AppointmentDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  cancelationReason?: Maybe<CodeableConceptDisconnectFieldInput>;
  serviceCategory?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  serviceType?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  appointmentType?: Maybe<CodeableConceptDisconnectFieldInput>;
  reasonCode?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  reasonReference?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  _priority?: Maybe<ElementDisconnectFieldInput>;
  _description?: Maybe<ElementDisconnectFieldInput>;
  supportingInformation?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  _start?: Maybe<ElementDisconnectFieldInput>;
  _end?: Maybe<ElementDisconnectFieldInput>;
  _minutesDuration?: Maybe<ElementDisconnectFieldInput>;
  slot?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  _created?: Maybe<ElementDisconnectFieldInput>;
  _comment?: Maybe<ElementDisconnectFieldInput>;
  _patientInstruction?: Maybe<ElementDisconnectFieldInput>;
  basedOn?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  participant?: Maybe<Array<AppointmentParticipantDisconnectFieldInput>>;
  requestedPeriod?: Maybe<Array<PeriodDisconnectFieldInput>>;
};

export type AppointmentExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AppointmentExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AppointmentExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AppointmentIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type AppointmentIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type AppointmentIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type AppointmentMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type AppointmentMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type AppointmentMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type AppointmentModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AppointmentModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AppointmentModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AppointmentOptions = {
  /** Specify one or more AppointmentSort objects to sort Appointments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AppointmentSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentParticipant = {
  __typename?: 'AppointmentParticipant';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Whether this participant is required to be present at the meeting. This covers a use-case where two doctors need to meet to discuss the results for a specific patient, and the patient is not required to be present. */
  required?: Maybe<AppointmentParticipantRequired>;
  /** Participation status of the actor. */
  status?: Maybe<AppointmentParticipantStatus>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type: Array<CodeableConcept>;
  actor?: Maybe<Reference>;
  _required?: Maybe<Element>;
  _status?: Maybe<Element>;
  period?: Maybe<Period>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentParticipantExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentParticipantModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentParticipantTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentParticipantActorArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentParticipant_RequiredArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentParticipant_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
export type AppointmentParticipantPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type AppointmentParticipantActorDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AppointmentParticipantActorFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type AppointmentParticipantActorUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type AppointmentParticipantConnectFieldInput = {
  where?: Maybe<AppointmentParticipantWhere>;
  connect?: Maybe<AppointmentParticipantConnectInput>;
};

export type AppointmentParticipantConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  actor?: Maybe<ReferenceConnectFieldInput>;
  _required?: Maybe<ElementConnectFieldInput>;
  _status?: Maybe<ElementConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type AppointmentParticipantCreateInput = {
  required?: Maybe<AppointmentParticipantRequired>;
  status?: Maybe<AppointmentParticipantStatus>;
  extension?: Maybe<AppointmentParticipantExtensionFieldInput>;
  modifierExtension?: Maybe<AppointmentParticipantModifierextensionFieldInput>;
  type?: Maybe<AppointmentParticipantTypeFieldInput>;
  actor?: Maybe<AppointmentParticipantActorFieldInput>;
  _required?: Maybe<AppointmentParticipant_RequiredFieldInput>;
  _status?: Maybe<AppointmentParticipant_StatusFieldInput>;
  period?: Maybe<AppointmentParticipantPeriodFieldInput>;
};

export type AppointmentParticipantDeleteFieldInput = {
  where?: Maybe<AppointmentParticipantWhere>;
  delete?: Maybe<AppointmentParticipantDeleteInput>;
};

export type AppointmentParticipantDeleteInput = {
  extension?: Maybe<Array<AppointmentParticipantExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<AppointmentParticipantModifierextensionDeleteFieldInput>>;
  type?: Maybe<Array<AppointmentParticipantTypeDeleteFieldInput>>;
  actor?: Maybe<AppointmentParticipantActorDeleteFieldInput>;
  _required?: Maybe<AppointmentParticipant_RequiredDeleteFieldInput>;
  _status?: Maybe<AppointmentParticipant_StatusDeleteFieldInput>;
  period?: Maybe<AppointmentParticipantPeriodDeleteFieldInput>;
};

export type AppointmentParticipantDisconnectFieldInput = {
  where?: Maybe<AppointmentParticipantWhere>;
  disconnect?: Maybe<AppointmentParticipantDisconnectInput>;
};

export type AppointmentParticipantDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  actor?: Maybe<ReferenceDisconnectFieldInput>;
  _required?: Maybe<ElementDisconnectFieldInput>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type AppointmentParticipantExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AppointmentParticipantExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AppointmentParticipantExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AppointmentParticipantFieldInput = {
  create?: Maybe<Array<AppointmentParticipantCreateInput>>;
  connect?: Maybe<Array<AppointmentParticipantConnectFieldInput>>;
};

export type AppointmentParticipantModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AppointmentParticipantModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AppointmentParticipantModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AppointmentParticipantOptions = {
  /** Specify one or more AppointmentParticipantSort objects to sort AppointmentParticipants by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AppointmentParticipantSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AppointmentParticipantPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type AppointmentParticipantPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type AppointmentParticipantPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type AppointmentParticipantRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<Array<CodeableConceptCreateInput>>;
  actor?: Maybe<ReferenceCreateInput>;
  _required?: Maybe<ElementCreateInput>;
  _status?: Maybe<ElementCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

/** Whether this participant is required to be present at the meeting. This covers a use-case where two doctors need to meet to discuss the results for a specific patient, and the patient is not required to be present. */
export enum AppointmentParticipantRequired {
  Required = 'required',
  Optional = 'optional',
  InformationOnly = 'informationOnly',
}

/** Fields to sort AppointmentParticipants by. The order in which sorts are applied is not guaranteed when specifying many fields in one AppointmentParticipantSort object. */
export type AppointmentParticipantSort = {
  id?: Maybe<SortDirection>;
  required?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
};

/** Participation status of the actor. */
export enum AppointmentParticipantStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Tentative = 'tentative',
  NeedsAction = 'needsAction',
}

export type AppointmentParticipantTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type AppointmentParticipantTypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type AppointmentParticipantTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type AppointmentParticipantUpdateFieldInput = {
  where?: Maybe<AppointmentParticipantWhere>;
  update?: Maybe<AppointmentParticipantUpdateInput>;
  connect?: Maybe<Array<AppointmentParticipantConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentParticipantDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentParticipantCreateInput>>;
  delete?: Maybe<Array<AppointmentParticipantDeleteFieldInput>>;
};

export type AppointmentParticipantUpdateInput = {
  required?: Maybe<AppointmentParticipantRequired>;
  status?: Maybe<AppointmentParticipantStatus>;
  extension?: Maybe<Array<AppointmentParticipantExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<AppointmentParticipantModifierextensionUpdateFieldInput>>;
  type?: Maybe<Array<AppointmentParticipantTypeUpdateFieldInput>>;
  actor?: Maybe<AppointmentParticipantActorUpdateFieldInput>;
  _required?: Maybe<AppointmentParticipant_RequiredUpdateFieldInput>;
  _status?: Maybe<AppointmentParticipant_StatusUpdateFieldInput>;
  period?: Maybe<AppointmentParticipantPeriodUpdateFieldInput>;
};

export type AppointmentParticipantWhere = {
  OR?: Maybe<Array<AppointmentParticipantWhere>>;
  AND?: Maybe<Array<AppointmentParticipantWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  required?: Maybe<AppointmentParticipantRequired>;
  required_NOT?: Maybe<AppointmentParticipantRequired>;
  required_IN?: Maybe<Array<Maybe<AppointmentParticipantRequired>>>;
  required_NOT_IN?: Maybe<Array<Maybe<AppointmentParticipantRequired>>>;
  status?: Maybe<AppointmentParticipantStatus>;
  status_NOT?: Maybe<AppointmentParticipantStatus>;
  status_IN?: Maybe<Array<Maybe<AppointmentParticipantStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<AppointmentParticipantStatus>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  actor?: Maybe<ReferenceWhere>;
  actor_NOT?: Maybe<ReferenceWhere>;
  actor_IN?: Maybe<Array<ReferenceWhere>>;
  actor_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _required?: Maybe<ElementWhere>;
  _required_NOT?: Maybe<ElementWhere>;
  _required_IN?: Maybe<Array<ElementWhere>>;
  _required_NOT_IN?: Maybe<Array<ElementWhere>>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type AppointmentParticipant_RequiredDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AppointmentParticipant_RequiredFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AppointmentParticipant_RequiredUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AppointmentParticipant_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AppointmentParticipant_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AppointmentParticipant_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AppointmentReasoncodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type AppointmentReasoncodeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type AppointmentReasoncodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type AppointmentReasonreferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AppointmentReasonreferenceFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type AppointmentReasonreferenceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type AppointmentRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  cancelationReason?: Maybe<CodeableConceptCreateInput>;
  serviceCategory?: Maybe<Array<CodeableConceptCreateInput>>;
  serviceType?: Maybe<Array<CodeableConceptCreateInput>>;
  specialty?: Maybe<Array<CodeableConceptCreateInput>>;
  appointmentType?: Maybe<CodeableConceptCreateInput>;
  reasonCode?: Maybe<Array<CodeableConceptCreateInput>>;
  reasonReference?: Maybe<Array<ReferenceCreateInput>>;
  _priority?: Maybe<ElementCreateInput>;
  _description?: Maybe<ElementCreateInput>;
  supportingInformation?: Maybe<Array<ReferenceCreateInput>>;
  _start?: Maybe<ElementCreateInput>;
  _end?: Maybe<ElementCreateInput>;
  _minutesDuration?: Maybe<ElementCreateInput>;
  slot?: Maybe<Array<ReferenceCreateInput>>;
  _created?: Maybe<ElementCreateInput>;
  _comment?: Maybe<ElementCreateInput>;
  _patientInstruction?: Maybe<ElementCreateInput>;
  basedOn?: Maybe<Array<ReferenceCreateInput>>;
  participant?: Maybe<Array<AppointmentParticipantCreateInput>>;
  requestedPeriod?: Maybe<Array<PeriodCreateInput>>;
};

export type AppointmentRequestedperiodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type AppointmentRequestedperiodFieldInput = {
  create?: Maybe<Array<PeriodCreateInput>>;
  connect?: Maybe<Array<PeriodConnectFieldInput>>;
};

export type AppointmentRequestedperiodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<Array<PeriodConnectFieldInput>>;
  disconnect?: Maybe<Array<PeriodDisconnectFieldInput>>;
  create?: Maybe<Array<PeriodCreateInput>>;
  delete?: Maybe<Array<PeriodDeleteFieldInput>>;
};

export enum AppointmentResourceType {
  Appointment = 'Appointment',
}

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponse = {
  __typename?: 'AppointmentResponse';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a AppointmentResponse resource */
  resourceType: AppointmentResponseResourceType;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** Date/Time that the appointment is to take place, or requested new start time. */
  start?: Maybe<Scalars['Instant']>;
  /** This may be either the same as the appointment request to confirm the details of the appointment, or alternately a new time to request a re-negotiation of the end time. */
  end?: Maybe<Scalars['Instant']>;
  /** Participation status of the participant. When the status is declined or tentative if the start/end times are different to the appointment, then these times should be interpreted as a requested time change. When the status is accepted, the times can either be the time of the appointment (as a confirmation of the time) or can be empty. */
  participantStatus?: Maybe<Scalars['Code']>;
  /** Additional comments about the appointment. */
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  appointment: Reference;
  _start?: Maybe<Element>;
  _end?: Maybe<Element>;
  participantType: Array<CodeableConcept>;
  actor?: Maybe<Reference>;
  _participantStatus?: Maybe<Element>;
  _comment?: Maybe<Element>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponseMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponse_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponse_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponseTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponseContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponseExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponseModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponseIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponseAppointmentArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponse_StartArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponse_EndArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponseParticipantTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponseActorArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponse_ParticipantStatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
export type AppointmentResponse_CommentArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type AppointmentResponseActorDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AppointmentResponseActorFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type AppointmentResponseActorUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type AppointmentResponseAppointmentDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AppointmentResponseAppointmentFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type AppointmentResponseAppointmentUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type AppointmentResponseConnectFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  connect?: Maybe<AppointmentResponseConnectInput>;
};

export type AppointmentResponseConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  appointment?: Maybe<ReferenceConnectFieldInput>;
  _start?: Maybe<ElementConnectFieldInput>;
  _end?: Maybe<ElementConnectFieldInput>;
  participantType?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  actor?: Maybe<ReferenceConnectFieldInput>;
  _participantStatus?: Maybe<ElementConnectFieldInput>;
  _comment?: Maybe<ElementConnectFieldInput>;
};

export type AppointmentResponseContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type AppointmentResponseContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type AppointmentResponseContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type AppointmentResponseContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type AppointmentResponseContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type AppointmentResponseContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type AppointmentResponseContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type AppointmentResponseContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type AppointmentResponseContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type AppointmentResponseContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type AppointmentResponseContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type AppointmentResponseContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type AppointmentResponseContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type AppointmentResponseContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type AppointmentResponseContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type AppointmentResponseContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type AppointmentResponseContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type AppointmentResponseContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type AppointmentResponseContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type AppointmentResponseContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type AppointmentResponseContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type AppointmentResponseContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type AppointmentResponseContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type AppointmentResponseContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type AppointmentResponseContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type AppointmentResponseContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type AppointmentResponseContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type AppointmentResponseContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type AppointmentResponseContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type AppointmentResponseContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type AppointmentResponseContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type AppointmentResponseContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type AppointmentResponseContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type AppointmentResponseContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type AppointmentResponseContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type AppointmentResponseContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type AppointmentResponseContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type AppointmentResponseContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type AppointmentResponseContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type AppointmentResponseContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type AppointmentResponseContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type AppointmentResponseContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type AppointmentResponseContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type AppointmentResponseContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type AppointmentResponseContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type AppointmentResponseContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type AppointmentResponseContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type AppointmentResponseContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type AppointmentResponseContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type AppointmentResponseContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type AppointmentResponseContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type AppointmentResponseContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type AppointmentResponseContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type AppointmentResponseContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type AppointmentResponseContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type AppointmentResponseContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type AppointmentResponseContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type AppointmentResponseContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type AppointmentResponseContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type AppointmentResponseContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type AppointmentResponseContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type AppointmentResponseContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type AppointmentResponseContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type AppointmentResponseCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  start?: Maybe<Scalars['Instant']>;
  end?: Maybe<Scalars['Instant']>;
  participantStatus?: Maybe<Scalars['Code']>;
  comment?: Maybe<Scalars['String']>;
  resourceType: AppointmentResponseResourceType;
  meta?: Maybe<AppointmentResponseMetaFieldInput>;
  _implicitRules?: Maybe<AppointmentResponse_ImplicitrulesFieldInput>;
  _language?: Maybe<AppointmentResponse_LanguageFieldInput>;
  text?: Maybe<AppointmentResponseTextFieldInput>;
  contained_Account?: Maybe<AppointmentResponseContainedAccountFieldInput>;
  contained_Appointment?: Maybe<AppointmentResponseContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<AppointmentResponseContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<AppointmentResponseContainedCoverageFieldInput>;
  contained_Device?: Maybe<AppointmentResponseContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<AppointmentResponseContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<AppointmentResponseContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<AppointmentResponseContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<AppointmentResponseContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<AppointmentResponseContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<AppointmentResponseContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<AppointmentResponseContainedLocationFieldInput>;
  contained_Organization?: Maybe<AppointmentResponseContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<AppointmentResponseContainedPatientFieldInput>;
  contained_Person?: Maybe<AppointmentResponseContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<AppointmentResponseContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<AppointmentResponseContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<AppointmentResponseContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<AppointmentResponseContainedScheduleFieldInput>;
  contained_Slot?: Maybe<AppointmentResponseContainedSlotFieldInput>;
  contained_Substance?: Maybe<AppointmentResponseContainedSubstanceFieldInput>;
  extension?: Maybe<AppointmentResponseExtensionFieldInput>;
  modifierExtension?: Maybe<AppointmentResponseModifierextensionFieldInput>;
  identifier?: Maybe<AppointmentResponseIdentifierFieldInput>;
  appointment?: Maybe<AppointmentResponseAppointmentFieldInput>;
  _start?: Maybe<AppointmentResponse_StartFieldInput>;
  _end?: Maybe<AppointmentResponse_EndFieldInput>;
  participantType?: Maybe<AppointmentResponseParticipanttypeFieldInput>;
  actor?: Maybe<AppointmentResponseActorFieldInput>;
  _participantStatus?: Maybe<AppointmentResponse_ParticipantstatusFieldInput>;
  _comment?: Maybe<AppointmentResponse_CommentFieldInput>;
};

export type AppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type AppointmentResponseDeleteInput = {
  meta?: Maybe<AppointmentResponseMetaDeleteFieldInput>;
  _implicitRules?: Maybe<AppointmentResponse_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<AppointmentResponse_LanguageDeleteFieldInput>;
  text?: Maybe<AppointmentResponseTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<AppointmentResponseContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentResponseContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<AppointmentResponseContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<AppointmentResponseContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<AppointmentResponseContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<AppointmentResponseContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<AppointmentResponseContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<AppointmentResponseContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<AppointmentResponseContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<AppointmentResponseContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<AppointmentResponseContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<AppointmentResponseContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<AppointmentResponseContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<AppointmentResponseContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<AppointmentResponseContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<AppointmentResponseContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<AppointmentResponseContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<AppointmentResponseContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<AppointmentResponseContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<AppointmentResponseContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<AppointmentResponseExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<AppointmentResponseModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<AppointmentResponseIdentifierDeleteFieldInput>>;
  appointment?: Maybe<AppointmentResponseAppointmentDeleteFieldInput>;
  _start?: Maybe<AppointmentResponse_StartDeleteFieldInput>;
  _end?: Maybe<AppointmentResponse_EndDeleteFieldInput>;
  participantType?: Maybe<Array<AppointmentResponseParticipanttypeDeleteFieldInput>>;
  actor?: Maybe<AppointmentResponseActorDeleteFieldInput>;
  _participantStatus?: Maybe<AppointmentResponse_ParticipantstatusDeleteFieldInput>;
  _comment?: Maybe<AppointmentResponse_CommentDeleteFieldInput>;
};

export type AppointmentResponseDisconnectFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  disconnect?: Maybe<AppointmentResponseDisconnectInput>;
};

export type AppointmentResponseDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  appointment?: Maybe<ReferenceDisconnectFieldInput>;
  _start?: Maybe<ElementDisconnectFieldInput>;
  _end?: Maybe<ElementDisconnectFieldInput>;
  participantType?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  actor?: Maybe<ReferenceDisconnectFieldInput>;
  _participantStatus?: Maybe<ElementDisconnectFieldInput>;
  _comment?: Maybe<ElementDisconnectFieldInput>;
};

export type AppointmentResponseExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AppointmentResponseExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AppointmentResponseExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AppointmentResponseIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type AppointmentResponseIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type AppointmentResponseIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type AppointmentResponseMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type AppointmentResponseMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type AppointmentResponseMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type AppointmentResponseModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AppointmentResponseModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AppointmentResponseModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AppointmentResponseOptions = {
  /** Specify one or more AppointmentResponseSort objects to sort AppointmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AppointmentResponseSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AppointmentResponseParticipanttypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type AppointmentResponseParticipanttypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type AppointmentResponseParticipanttypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type AppointmentResponseRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  appointment?: Maybe<ReferenceCreateInput>;
  _start?: Maybe<ElementCreateInput>;
  _end?: Maybe<ElementCreateInput>;
  participantType?: Maybe<Array<CodeableConceptCreateInput>>;
  actor?: Maybe<ReferenceCreateInput>;
  _participantStatus?: Maybe<ElementCreateInput>;
  _comment?: Maybe<ElementCreateInput>;
};

export enum AppointmentResponseResourceType {
  AppointmentResponse = 'AppointmentResponse',
}

/** Fields to sort AppointmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AppointmentResponseSort object. */
export type AppointmentResponseSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  start?: Maybe<SortDirection>;
  end?: Maybe<SortDirection>;
  participantStatus?: Maybe<SortDirection>;
  comment?: Maybe<SortDirection>;
};

export type AppointmentResponseTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type AppointmentResponseTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type AppointmentResponseTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type AppointmentResponseUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  start?: Maybe<Scalars['Instant']>;
  end?: Maybe<Scalars['Instant']>;
  participantStatus?: Maybe<Scalars['Code']>;
  comment?: Maybe<Scalars['String']>;
  resourceType?: Maybe<AppointmentResponseResourceType>;
  meta?: Maybe<AppointmentResponseMetaUpdateFieldInput>;
  _implicitRules?: Maybe<AppointmentResponse_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<AppointmentResponse_LanguageUpdateFieldInput>;
  text?: Maybe<AppointmentResponseTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<AppointmentResponseContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentResponseContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<AppointmentResponseContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<AppointmentResponseContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<AppointmentResponseContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<AppointmentResponseContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<AppointmentResponseContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<AppointmentResponseContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<AppointmentResponseContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<AppointmentResponseContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<AppointmentResponseContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<AppointmentResponseContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<AppointmentResponseContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<AppointmentResponseContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<AppointmentResponseContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<AppointmentResponseContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<AppointmentResponseContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<AppointmentResponseContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<AppointmentResponseContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<AppointmentResponseContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<AppointmentResponseExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<AppointmentResponseModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<AppointmentResponseIdentifierUpdateFieldInput>>;
  appointment?: Maybe<AppointmentResponseAppointmentUpdateFieldInput>;
  _start?: Maybe<AppointmentResponse_StartUpdateFieldInput>;
  _end?: Maybe<AppointmentResponse_EndUpdateFieldInput>;
  participantType?: Maybe<Array<AppointmentResponseParticipanttypeUpdateFieldInput>>;
  actor?: Maybe<AppointmentResponseActorUpdateFieldInput>;
  _participantStatus?: Maybe<AppointmentResponse_ParticipantstatusUpdateFieldInput>;
  _comment?: Maybe<AppointmentResponse_CommentUpdateFieldInput>;
};

export type AppointmentResponseWhere = {
  OR?: Maybe<Array<AppointmentResponseWhere>>;
  AND?: Maybe<Array<AppointmentResponseWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  start?: Maybe<Scalars['Instant']>;
  end?: Maybe<Scalars['Instant']>;
  participantStatus?: Maybe<Scalars['Code']>;
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<AppointmentResponseResourceType>;
  resourceType_NOT?: Maybe<AppointmentResponseResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<AppointmentResponseResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<AppointmentResponseResourceType>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  appointment?: Maybe<ReferenceWhere>;
  appointment_NOT?: Maybe<ReferenceWhere>;
  appointment_IN?: Maybe<Array<ReferenceWhere>>;
  appointment_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _start?: Maybe<ElementWhere>;
  _start_NOT?: Maybe<ElementWhere>;
  _start_IN?: Maybe<Array<ElementWhere>>;
  _start_NOT_IN?: Maybe<Array<ElementWhere>>;
  _end?: Maybe<ElementWhere>;
  _end_NOT?: Maybe<ElementWhere>;
  _end_IN?: Maybe<Array<ElementWhere>>;
  _end_NOT_IN?: Maybe<Array<ElementWhere>>;
  participantType?: Maybe<CodeableConceptWhere>;
  participantType_NOT?: Maybe<CodeableConceptWhere>;
  actor?: Maybe<ReferenceWhere>;
  actor_NOT?: Maybe<ReferenceWhere>;
  actor_IN?: Maybe<Array<ReferenceWhere>>;
  actor_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _participantStatus?: Maybe<ElementWhere>;
  _participantStatus_NOT?: Maybe<ElementWhere>;
  _participantStatus_IN?: Maybe<Array<ElementWhere>>;
  _participantStatus_NOT_IN?: Maybe<Array<ElementWhere>>;
  _comment?: Maybe<ElementWhere>;
  _comment_NOT?: Maybe<ElementWhere>;
  _comment_IN?: Maybe<Array<ElementWhere>>;
  _comment_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type AppointmentResponse_CommentDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AppointmentResponse_CommentFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AppointmentResponse_CommentUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AppointmentResponse_EndDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AppointmentResponse_EndFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AppointmentResponse_EndUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AppointmentResponse_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AppointmentResponse_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AppointmentResponse_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AppointmentResponse_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AppointmentResponse_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AppointmentResponse_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AppointmentResponse_ParticipantstatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AppointmentResponse_ParticipantstatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AppointmentResponse_ParticipantstatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AppointmentResponse_StartDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type AppointmentResponse_StartFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type AppointmentResponse_StartUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type AppointmentServicecategoryDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type AppointmentServicecategoryFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type AppointmentServicecategoryUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type AppointmentServicetypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type AppointmentServicetypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type AppointmentServicetypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type AppointmentSlotDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AppointmentSlotFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type AppointmentSlotUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

/** Fields to sort Appointments by. The order in which sorts are applied is not guaranteed when specifying many fields in one AppointmentSort object. */
export type AppointmentSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  priority?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
  start?: Maybe<SortDirection>;
  end?: Maybe<SortDirection>;
  minutesDuration?: Maybe<SortDirection>;
  created?: Maybe<SortDirection>;
  comment?: Maybe<SortDirection>;
  patientInstruction?: Maybe<SortDirection>;
};

export type AppointmentSpecialtyDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type AppointmentSpecialtyFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type AppointmentSpecialtyUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

/** The overall status of the Appointment. Each of the participants has their own participation status which indicates their involvement in the process, however this status indicates the shared status. */
export enum AppointmentStatus {
  Proposed = 'proposed',
  Pending = 'pending',
  Booked = 'booked',
  Arrived = 'arrived',
  Fulfilled = 'fulfilled',
  Cancelled = 'cancelled',
  Noshow = 'noshow',
  EnteredInError = 'enteredInError',
  CheckedIn = 'checkedIn',
  Waitlist = 'waitlist',
}

export type AppointmentSupportinginformationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type AppointmentSupportinginformationFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type AppointmentSupportinginformationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type AppointmentTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type AppointmentTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type AppointmentTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type AppointmentUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  priority?: Maybe<Scalars['UnsignedInt']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Instant']>;
  end?: Maybe<Scalars['Instant']>;
  minutesDuration?: Maybe<Scalars['PositiveInt']>;
  created?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  patientInstruction?: Maybe<Scalars['String']>;
  resourceType?: Maybe<AppointmentResourceType>;
  status?: Maybe<AppointmentStatus>;
  meta?: Maybe<AppointmentMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Appointment_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Appointment_LanguageUpdateFieldInput>;
  text?: Maybe<AppointmentTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<AppointmentContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<AppointmentContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<AppointmentContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<AppointmentContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<AppointmentContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<AppointmentContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<AppointmentContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<AppointmentContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<AppointmentContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<AppointmentContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<AppointmentContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<AppointmentContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<AppointmentContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<AppointmentContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<AppointmentContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<AppointmentContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<AppointmentContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<AppointmentContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<AppointmentContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<AppointmentExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<AppointmentModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<AppointmentIdentifierUpdateFieldInput>>;
  _status?: Maybe<Appointment_StatusUpdateFieldInput>;
  cancelationReason?: Maybe<AppointmentCancelationreasonUpdateFieldInput>;
  serviceCategory?: Maybe<Array<AppointmentServicecategoryUpdateFieldInput>>;
  serviceType?: Maybe<Array<AppointmentServicetypeUpdateFieldInput>>;
  specialty?: Maybe<Array<AppointmentSpecialtyUpdateFieldInput>>;
  appointmentType?: Maybe<AppointmentAppointmenttypeUpdateFieldInput>;
  reasonCode?: Maybe<Array<AppointmentReasoncodeUpdateFieldInput>>;
  reasonReference?: Maybe<Array<AppointmentReasonreferenceUpdateFieldInput>>;
  _priority?: Maybe<Appointment_PriorityUpdateFieldInput>;
  _description?: Maybe<Appointment_DescriptionUpdateFieldInput>;
  supportingInformation?: Maybe<Array<AppointmentSupportinginformationUpdateFieldInput>>;
  _start?: Maybe<Appointment_StartUpdateFieldInput>;
  _end?: Maybe<Appointment_EndUpdateFieldInput>;
  _minutesDuration?: Maybe<Appointment_MinutesdurationUpdateFieldInput>;
  slot?: Maybe<Array<AppointmentSlotUpdateFieldInput>>;
  _created?: Maybe<Appointment_CreatedUpdateFieldInput>;
  _comment?: Maybe<Appointment_CommentUpdateFieldInput>;
  _patientInstruction?: Maybe<Appointment_PatientinstructionUpdateFieldInput>;
  basedOn?: Maybe<Array<AppointmentBasedonUpdateFieldInput>>;
  participant?: Maybe<Array<AppointmentParticipantUpdateFieldInput>>;
  requestedPeriod?: Maybe<Array<AppointmentRequestedperiodUpdateFieldInput>>;
};

export type AppointmentWhere = {
  OR?: Maybe<Array<AppointmentWhere>>;
  AND?: Maybe<Array<AppointmentWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  priority?: Maybe<Scalars['UnsignedInt']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Instant']>;
  end?: Maybe<Scalars['Instant']>;
  minutesDuration?: Maybe<Scalars['PositiveInt']>;
  created?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  patientInstruction?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<AppointmentResourceType>;
  resourceType_NOT?: Maybe<AppointmentResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<AppointmentResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<AppointmentResourceType>>>;
  status?: Maybe<AppointmentStatus>;
  status_NOT?: Maybe<AppointmentStatus>;
  status_IN?: Maybe<Array<Maybe<AppointmentStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<AppointmentStatus>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  cancelationReason?: Maybe<CodeableConceptWhere>;
  cancelationReason_NOT?: Maybe<CodeableConceptWhere>;
  cancelationReason_IN?: Maybe<Array<CodeableConceptWhere>>;
  cancelationReason_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  serviceCategory?: Maybe<CodeableConceptWhere>;
  serviceCategory_NOT?: Maybe<CodeableConceptWhere>;
  serviceType?: Maybe<CodeableConceptWhere>;
  serviceType_NOT?: Maybe<CodeableConceptWhere>;
  specialty?: Maybe<CodeableConceptWhere>;
  specialty_NOT?: Maybe<CodeableConceptWhere>;
  appointmentType?: Maybe<CodeableConceptWhere>;
  appointmentType_NOT?: Maybe<CodeableConceptWhere>;
  appointmentType_IN?: Maybe<Array<CodeableConceptWhere>>;
  appointmentType_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  reasonCode?: Maybe<CodeableConceptWhere>;
  reasonCode_NOT?: Maybe<CodeableConceptWhere>;
  reasonReference?: Maybe<ReferenceWhere>;
  reasonReference_NOT?: Maybe<ReferenceWhere>;
  _priority?: Maybe<ElementWhere>;
  _priority_NOT?: Maybe<ElementWhere>;
  _priority_IN?: Maybe<Array<ElementWhere>>;
  _priority_NOT_IN?: Maybe<Array<ElementWhere>>;
  _description?: Maybe<ElementWhere>;
  _description_NOT?: Maybe<ElementWhere>;
  _description_IN?: Maybe<Array<ElementWhere>>;
  _description_NOT_IN?: Maybe<Array<ElementWhere>>;
  supportingInformation?: Maybe<ReferenceWhere>;
  supportingInformation_NOT?: Maybe<ReferenceWhere>;
  _start?: Maybe<ElementWhere>;
  _start_NOT?: Maybe<ElementWhere>;
  _start_IN?: Maybe<Array<ElementWhere>>;
  _start_NOT_IN?: Maybe<Array<ElementWhere>>;
  _end?: Maybe<ElementWhere>;
  _end_NOT?: Maybe<ElementWhere>;
  _end_IN?: Maybe<Array<ElementWhere>>;
  _end_NOT_IN?: Maybe<Array<ElementWhere>>;
  _minutesDuration?: Maybe<ElementWhere>;
  _minutesDuration_NOT?: Maybe<ElementWhere>;
  _minutesDuration_IN?: Maybe<Array<ElementWhere>>;
  _minutesDuration_NOT_IN?: Maybe<Array<ElementWhere>>;
  slot?: Maybe<ReferenceWhere>;
  slot_NOT?: Maybe<ReferenceWhere>;
  _created?: Maybe<ElementWhere>;
  _created_NOT?: Maybe<ElementWhere>;
  _created_IN?: Maybe<Array<ElementWhere>>;
  _created_NOT_IN?: Maybe<Array<ElementWhere>>;
  _comment?: Maybe<ElementWhere>;
  _comment_NOT?: Maybe<ElementWhere>;
  _comment_IN?: Maybe<Array<ElementWhere>>;
  _comment_NOT_IN?: Maybe<Array<ElementWhere>>;
  _patientInstruction?: Maybe<ElementWhere>;
  _patientInstruction_NOT?: Maybe<ElementWhere>;
  _patientInstruction_IN?: Maybe<Array<ElementWhere>>;
  _patientInstruction_NOT_IN?: Maybe<Array<ElementWhere>>;
  basedOn?: Maybe<ReferenceWhere>;
  basedOn_NOT?: Maybe<ReferenceWhere>;
  participant?: Maybe<AppointmentParticipantWhere>;
  participant_NOT?: Maybe<AppointmentParticipantWhere>;
  requestedPeriod?: Maybe<PeriodWhere>;
  requestedPeriod_NOT?: Maybe<PeriodWhere>;
};

export type Appointment_CommentDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_CommentFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_CommentUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_CreatedDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_CreatedFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_CreatedUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_DescriptionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_DescriptionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_DescriptionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_EndDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_EndFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_EndUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_MinutesdurationDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_MinutesdurationFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_MinutesdurationUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_PatientinstructionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_PatientinstructionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_PatientinstructionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_PriorityDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_PriorityFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_PriorityUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_StartDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_StartFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_StartUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Appointment_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Appointment_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Appointment_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** For referring to data content defined in other formats. */
export type Attachment = {
  __typename?: 'Attachment';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Identifies the type of the data in the attachment and allows a method to be chosen to interpret or render the data. Includes mime type parameters such as charset where appropriate. */
  contentType?: Maybe<Scalars['Code']>;
  /** The human language of the content. The value can be any valid value according to BCP 47. */
  language?: Maybe<Scalars['Code']>;
  /** The actual data of the attachment - a sequence of bytes, base64 encoded. */
  data?: Maybe<Scalars['Base64Binary']>;
  /** A location where the data can be accessed. */
  url?: Maybe<Scalars['Url']>;
  /** The number of bytes of data that make up this attachment (before base64 encoding, if that is done). */
  size?: Maybe<Scalars['UnsignedInt']>;
  /** The calculated hash of the data using SHA-1. Represented using base64. */
  hash?: Maybe<Scalars['Base64Binary']>;
  /** A label or set of text to display in place of the data. */
  title?: Maybe<Scalars['String']>;
  /** The date that the attachment was first created. */
  creation?: Maybe<Scalars['DateTime']>;
  extension: Array<Extension>;
  _contentType?: Maybe<Element>;
  _language?: Maybe<Element>;
  _data?: Maybe<Element>;
  _url?: Maybe<Element>;
  _size?: Maybe<Element>;
  _hash?: Maybe<Element>;
  _title?: Maybe<Element>;
  _creation?: Maybe<Element>;
};

/** For referring to data content defined in other formats. */
export type AttachmentExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** For referring to data content defined in other formats. */
export type Attachment_ContentTypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** For referring to data content defined in other formats. */
export type Attachment_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** For referring to data content defined in other formats. */
export type Attachment_DataArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** For referring to data content defined in other formats. */
export type Attachment_UrlArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** For referring to data content defined in other formats. */
export type Attachment_SizeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** For referring to data content defined in other formats. */
export type Attachment_HashArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** For referring to data content defined in other formats. */
export type Attachment_TitleArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** For referring to data content defined in other formats. */
export type Attachment_CreationArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type AttachmentConnectFieldInput = {
  where?: Maybe<AttachmentWhere>;
  connect?: Maybe<AttachmentConnectInput>;
};

export type AttachmentConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _contentType?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  _data?: Maybe<ElementConnectFieldInput>;
  _url?: Maybe<ElementConnectFieldInput>;
  _size?: Maybe<ElementConnectFieldInput>;
  _hash?: Maybe<ElementConnectFieldInput>;
  _title?: Maybe<ElementConnectFieldInput>;
  _creation?: Maybe<ElementConnectFieldInput>;
};

export type AttachmentCreateInput = {
  contentType?: Maybe<Scalars['Code']>;
  language?: Maybe<Scalars['Code']>;
  data?: Maybe<Scalars['Base64Binary']>;
  url?: Maybe<Scalars['Url']>;
  size?: Maybe<Scalars['UnsignedInt']>;
  hash?: Maybe<Scalars['Base64Binary']>;
  title?: Maybe<Scalars['String']>;
  creation?: Maybe<Scalars['DateTime']>;
  extension?: Maybe<AttachmentExtensionFieldInput>;
  _contentType?: Maybe<Attachment_ContenttypeFieldInput>;
  _language?: Maybe<Attachment_LanguageFieldInput>;
  _data?: Maybe<Attachment_DataFieldInput>;
  _url?: Maybe<Attachment_UrlFieldInput>;
  _size?: Maybe<Attachment_SizeFieldInput>;
  _hash?: Maybe<Attachment_HashFieldInput>;
  _title?: Maybe<Attachment_TitleFieldInput>;
  _creation?: Maybe<Attachment_CreationFieldInput>;
};

export type AttachmentDeleteFieldInput = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type AttachmentDeleteInput = {
  extension?: Maybe<Array<AttachmentExtensionDeleteFieldInput>>;
  _contentType?: Maybe<Attachment_ContenttypeDeleteFieldInput>;
  _language?: Maybe<Attachment_LanguageDeleteFieldInput>;
  _data?: Maybe<Attachment_DataDeleteFieldInput>;
  _url?: Maybe<Attachment_UrlDeleteFieldInput>;
  _size?: Maybe<Attachment_SizeDeleteFieldInput>;
  _hash?: Maybe<Attachment_HashDeleteFieldInput>;
  _title?: Maybe<Attachment_TitleDeleteFieldInput>;
  _creation?: Maybe<Attachment_CreationDeleteFieldInput>;
};

export type AttachmentDisconnectFieldInput = {
  where?: Maybe<AttachmentWhere>;
  disconnect?: Maybe<AttachmentDisconnectInput>;
};

export type AttachmentDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _contentType?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  _data?: Maybe<ElementDisconnectFieldInput>;
  _url?: Maybe<ElementDisconnectFieldInput>;
  _size?: Maybe<ElementDisconnectFieldInput>;
  _hash?: Maybe<ElementDisconnectFieldInput>;
  _title?: Maybe<ElementDisconnectFieldInput>;
  _creation?: Maybe<ElementDisconnectFieldInput>;
};

export type AttachmentExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type AttachmentExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type AttachmentExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type AttachmentOptions = {
  /** Specify one or more AttachmentSort objects to sort Attachments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<AttachmentSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AttachmentRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _contentType?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  _data?: Maybe<ElementCreateInput>;
  _url?: Maybe<ElementCreateInput>;
  _size?: Maybe<ElementCreateInput>;
  _hash?: Maybe<ElementCreateInput>;
  _title?: Maybe<ElementCreateInput>;
  _creation?: Maybe<ElementCreateInput>;
};

/** Fields to sort Attachments by. The order in which sorts are applied is not guaranteed when specifying many fields in one AttachmentSort object. */
export type AttachmentSort = {
  id?: Maybe<SortDirection>;
  contentType?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  data?: Maybe<SortDirection>;
  url?: Maybe<SortDirection>;
  size?: Maybe<SortDirection>;
  hash?: Maybe<SortDirection>;
  title?: Maybe<SortDirection>;
  creation?: Maybe<SortDirection>;
};

export type AttachmentUpdateInput = {
  contentType?: Maybe<Scalars['Code']>;
  language?: Maybe<Scalars['Code']>;
  data?: Maybe<Scalars['Base64Binary']>;
  url?: Maybe<Scalars['Url']>;
  size?: Maybe<Scalars['UnsignedInt']>;
  hash?: Maybe<Scalars['Base64Binary']>;
  title?: Maybe<Scalars['String']>;
  creation?: Maybe<Scalars['DateTime']>;
  extension?: Maybe<Array<AttachmentExtensionUpdateFieldInput>>;
  _contentType?: Maybe<Attachment_ContenttypeUpdateFieldInput>;
  _language?: Maybe<Attachment_LanguageUpdateFieldInput>;
  _data?: Maybe<Attachment_DataUpdateFieldInput>;
  _url?: Maybe<Attachment_UrlUpdateFieldInput>;
  _size?: Maybe<Attachment_SizeUpdateFieldInput>;
  _hash?: Maybe<Attachment_HashUpdateFieldInput>;
  _title?: Maybe<Attachment_TitleUpdateFieldInput>;
  _creation?: Maybe<Attachment_CreationUpdateFieldInput>;
};

export type AttachmentWhere = {
  OR?: Maybe<Array<AttachmentWhere>>;
  AND?: Maybe<Array<AttachmentWhere>>;
  contentType?: Maybe<Scalars['Code']>;
  language?: Maybe<Scalars['Code']>;
  data?: Maybe<Scalars['Base64Binary']>;
  url?: Maybe<Scalars['Url']>;
  size?: Maybe<Scalars['UnsignedInt']>;
  hash?: Maybe<Scalars['Base64Binary']>;
  title?: Maybe<Scalars['String']>;
  creation?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _contentType?: Maybe<ElementWhere>;
  _contentType_NOT?: Maybe<ElementWhere>;
  _contentType_IN?: Maybe<Array<ElementWhere>>;
  _contentType_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  _data?: Maybe<ElementWhere>;
  _data_NOT?: Maybe<ElementWhere>;
  _data_IN?: Maybe<Array<ElementWhere>>;
  _data_NOT_IN?: Maybe<Array<ElementWhere>>;
  _url?: Maybe<ElementWhere>;
  _url_NOT?: Maybe<ElementWhere>;
  _url_IN?: Maybe<Array<ElementWhere>>;
  _url_NOT_IN?: Maybe<Array<ElementWhere>>;
  _size?: Maybe<ElementWhere>;
  _size_NOT?: Maybe<ElementWhere>;
  _size_IN?: Maybe<Array<ElementWhere>>;
  _size_NOT_IN?: Maybe<Array<ElementWhere>>;
  _hash?: Maybe<ElementWhere>;
  _hash_NOT?: Maybe<ElementWhere>;
  _hash_IN?: Maybe<Array<ElementWhere>>;
  _hash_NOT_IN?: Maybe<Array<ElementWhere>>;
  _title?: Maybe<ElementWhere>;
  _title_NOT?: Maybe<ElementWhere>;
  _title_IN?: Maybe<Array<ElementWhere>>;
  _title_NOT_IN?: Maybe<Array<ElementWhere>>;
  _creation?: Maybe<ElementWhere>;
  _creation_NOT?: Maybe<ElementWhere>;
  _creation_IN?: Maybe<Array<ElementWhere>>;
  _creation_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Attachment_ContenttypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Attachment_ContenttypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Attachment_ContenttypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Attachment_CreationDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Attachment_CreationFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Attachment_CreationUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Attachment_DataDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Attachment_DataFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Attachment_DataUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Attachment_HashDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Attachment_HashFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Attachment_HashUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Attachment_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Attachment_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Attachment_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Attachment_SizeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Attachment_SizeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Attachment_SizeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Attachment_TitleDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Attachment_TitleFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Attachment_TitleUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Attachment_UrlDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Attachment_UrlFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Attachment_UrlUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A concept that may be defined by a formal reference to a terminology or ontology or may be provided by text. */
export type CodeableConcept = {
  __typename?: 'CodeableConcept';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** A human language representation of the concept as seen/selected/uttered by the user who entered the data and/or which represents the intended meaning of the user. */
  text?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  coding: Array<Coding>;
  _text?: Maybe<Element>;
};

/** A concept that may be defined by a formal reference to a terminology or ontology or may be provided by text. */
export type CodeableConceptExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A concept that may be defined by a formal reference to a terminology or ontology or may be provided by text. */
export type CodeableConceptCodingArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

/** A concept that may be defined by a formal reference to a terminology or ontology or may be provided by text. */
export type CodeableConcept_TextArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type CodeableConceptCodingDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type CodeableConceptCodingFieldInput = {
  create?: Maybe<Array<CodingCreateInput>>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
};

export type CodeableConceptCodingUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
  disconnect?: Maybe<Array<CodingDisconnectFieldInput>>;
  create?: Maybe<Array<CodingCreateInput>>;
  delete?: Maybe<Array<CodingDeleteFieldInput>>;
};

export type CodeableConceptConnectFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  connect?: Maybe<CodeableConceptConnectInput>;
};

export type CodeableConceptConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  coding?: Maybe<Array<CodingConnectFieldInput>>;
  _text?: Maybe<ElementConnectFieldInput>;
};

export type CodeableConceptCreateInput = {
  text?: Maybe<Scalars['String']>;
  extension?: Maybe<CodeableConceptExtensionFieldInput>;
  coding?: Maybe<CodeableConceptCodingFieldInput>;
  _text?: Maybe<CodeableConcept_TextFieldInput>;
};

export type CodeableConceptDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type CodeableConceptDeleteInput = {
  extension?: Maybe<Array<CodeableConceptExtensionDeleteFieldInput>>;
  coding?: Maybe<Array<CodeableConceptCodingDeleteFieldInput>>;
  _text?: Maybe<CodeableConcept_TextDeleteFieldInput>;
};

export type CodeableConceptDisconnectFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  disconnect?: Maybe<CodeableConceptDisconnectInput>;
};

export type CodeableConceptDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  coding?: Maybe<Array<CodingDisconnectFieldInput>>;
  _text?: Maybe<ElementDisconnectFieldInput>;
};

export type CodeableConceptExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CodeableConceptExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CodeableConceptExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CodeableConceptOptions = {
  /** Specify one or more CodeableConceptSort objects to sort CodeableConcepts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<CodeableConceptSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type CodeableConceptRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  coding?: Maybe<Array<CodingCreateInput>>;
  _text?: Maybe<ElementCreateInput>;
};

/** Fields to sort CodeableConcepts by. The order in which sorts are applied is not guaranteed when specifying many fields in one CodeableConceptSort object. */
export type CodeableConceptSort = {
  id?: Maybe<SortDirection>;
  text?: Maybe<SortDirection>;
};

export type CodeableConceptUpdateInput = {
  text?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<CodeableConceptExtensionUpdateFieldInput>>;
  coding?: Maybe<Array<CodeableConceptCodingUpdateFieldInput>>;
  _text?: Maybe<CodeableConcept_TextUpdateFieldInput>;
};

export type CodeableConceptWhere = {
  OR?: Maybe<Array<CodeableConceptWhere>>;
  AND?: Maybe<Array<CodeableConceptWhere>>;
  text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  coding?: Maybe<CodingWhere>;
  coding_NOT?: Maybe<CodingWhere>;
  _text?: Maybe<ElementWhere>;
  _text_NOT?: Maybe<ElementWhere>;
  _text_IN?: Maybe<Array<ElementWhere>>;
  _text_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type CodeableConcept_TextDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type CodeableConcept_TextFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type CodeableConcept_TextUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A reference to a code defined by a terminology system. */
export type Coding = {
  __typename?: 'Coding';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Indicates that this coding was chosen by a user directly - e.g. off a pick list of available items (codes or displays). */
  userSelected?: Maybe<Scalars['Boolean']>;
  /** The identification of the code system that defines the meaning of the symbol in the code. */
  system?: Maybe<Scalars['Uri']>;
  /** The version of the code system which was used when choosing this code. Note that a well-maintained code system does not need the version reported, because the meaning of codes is consistent across versions. However this cannot consistently be assured, and when the meaning is not guaranteed to be consistent, the version SHOULD be exchanged. */
  version?: Maybe<Scalars['String']>;
  /** A symbol in syntax defined by the system. The symbol may be a predefined code or an expression in a syntax defined by the coding system (e.g. post-coordination). */
  code?: Maybe<Scalars['Code']>;
  /** A representation of the meaning of the code in the system, following the rules of the system. */
  display?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  _system?: Maybe<Element>;
  _version?: Maybe<Element>;
  _code?: Maybe<Element>;
  _display?: Maybe<Element>;
  _userSelected?: Maybe<Element>;
};

/** A reference to a code defined by a terminology system. */
export type CodingExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A reference to a code defined by a terminology system. */
export type Coding_SystemArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reference to a code defined by a terminology system. */
export type Coding_VersionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reference to a code defined by a terminology system. */
export type Coding_CodeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reference to a code defined by a terminology system. */
export type Coding_DisplayArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reference to a code defined by a terminology system. */
export type Coding_UserSelectedArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type CodingConnectFieldInput = {
  where?: Maybe<CodingWhere>;
  connect?: Maybe<CodingConnectInput>;
};

export type CodingConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _system?: Maybe<ElementConnectFieldInput>;
  _version?: Maybe<ElementConnectFieldInput>;
  _code?: Maybe<ElementConnectFieldInput>;
  _display?: Maybe<ElementConnectFieldInput>;
  _userSelected?: Maybe<ElementConnectFieldInput>;
};

export type CodingCreateInput = {
  userSelected?: Maybe<Scalars['Boolean']>;
  system?: Maybe<Scalars['Uri']>;
  version?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Code']>;
  display?: Maybe<Scalars['String']>;
  extension?: Maybe<CodingExtensionFieldInput>;
  _system?: Maybe<Coding_SystemFieldInput>;
  _version?: Maybe<Coding_VersionFieldInput>;
  _code?: Maybe<Coding_CodeFieldInput>;
  _display?: Maybe<Coding_DisplayFieldInput>;
  _userSelected?: Maybe<Coding_UserselectedFieldInput>;
};

export type CodingDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type CodingDeleteInput = {
  extension?: Maybe<Array<CodingExtensionDeleteFieldInput>>;
  _system?: Maybe<Coding_SystemDeleteFieldInput>;
  _version?: Maybe<Coding_VersionDeleteFieldInput>;
  _code?: Maybe<Coding_CodeDeleteFieldInput>;
  _display?: Maybe<Coding_DisplayDeleteFieldInput>;
  _userSelected?: Maybe<Coding_UserselectedDeleteFieldInput>;
};

export type CodingDisconnectFieldInput = {
  where?: Maybe<CodingWhere>;
  disconnect?: Maybe<CodingDisconnectInput>;
};

export type CodingDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _system?: Maybe<ElementDisconnectFieldInput>;
  _version?: Maybe<ElementDisconnectFieldInput>;
  _code?: Maybe<ElementDisconnectFieldInput>;
  _display?: Maybe<ElementDisconnectFieldInput>;
  _userSelected?: Maybe<ElementDisconnectFieldInput>;
};

export type CodingExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CodingExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CodingExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CodingOptions = {
  /** Specify one or more CodingSort objects to sort Codings by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<CodingSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type CodingRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _system?: Maybe<ElementCreateInput>;
  _version?: Maybe<ElementCreateInput>;
  _code?: Maybe<ElementCreateInput>;
  _display?: Maybe<ElementCreateInput>;
  _userSelected?: Maybe<ElementCreateInput>;
};

/** Fields to sort Codings by. The order in which sorts are applied is not guaranteed when specifying many fields in one CodingSort object. */
export type CodingSort = {
  id?: Maybe<SortDirection>;
  userSelected?: Maybe<SortDirection>;
  system?: Maybe<SortDirection>;
  version?: Maybe<SortDirection>;
  code?: Maybe<SortDirection>;
  display?: Maybe<SortDirection>;
};

export type CodingUpdateInput = {
  userSelected?: Maybe<Scalars['Boolean']>;
  system?: Maybe<Scalars['Uri']>;
  version?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Code']>;
  display?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<CodingExtensionUpdateFieldInput>>;
  _system?: Maybe<Coding_SystemUpdateFieldInput>;
  _version?: Maybe<Coding_VersionUpdateFieldInput>;
  _code?: Maybe<Coding_CodeUpdateFieldInput>;
  _display?: Maybe<Coding_DisplayUpdateFieldInput>;
  _userSelected?: Maybe<Coding_UserselectedUpdateFieldInput>;
};

export type CodingWhere = {
  OR?: Maybe<Array<CodingWhere>>;
  AND?: Maybe<Array<CodingWhere>>;
  system?: Maybe<Scalars['Uri']>;
  version?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Code']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  userSelected?: Maybe<Scalars['Boolean']>;
  userSelected_NOT?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _system?: Maybe<ElementWhere>;
  _system_NOT?: Maybe<ElementWhere>;
  _system_IN?: Maybe<Array<ElementWhere>>;
  _system_NOT_IN?: Maybe<Array<ElementWhere>>;
  _version?: Maybe<ElementWhere>;
  _version_NOT?: Maybe<ElementWhere>;
  _version_IN?: Maybe<Array<ElementWhere>>;
  _version_NOT_IN?: Maybe<Array<ElementWhere>>;
  _code?: Maybe<ElementWhere>;
  _code_NOT?: Maybe<ElementWhere>;
  _code_IN?: Maybe<Array<ElementWhere>>;
  _code_NOT_IN?: Maybe<Array<ElementWhere>>;
  _display?: Maybe<ElementWhere>;
  _display_NOT?: Maybe<ElementWhere>;
  _display_IN?: Maybe<Array<ElementWhere>>;
  _display_NOT_IN?: Maybe<Array<ElementWhere>>;
  _userSelected?: Maybe<ElementWhere>;
  _userSelected_NOT?: Maybe<ElementWhere>;
  _userSelected_IN?: Maybe<Array<ElementWhere>>;
  _userSelected_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Coding_CodeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coding_CodeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coding_CodeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coding_DisplayDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coding_DisplayFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coding_DisplayUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coding_SystemDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coding_SystemFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coding_SystemUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coding_UserselectedDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coding_UserselectedFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coding_UserselectedUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coding_VersionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coding_VersionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coding_VersionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Specifies contact information for a person or organization. */
export type ContactDetail = {
  __typename?: 'ContactDetail';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The name of an individual to contact. */
  name?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  _name?: Maybe<Element>;
  telecom: Array<ContactPoint>;
};

/** Specifies contact information for a person or organization. */
export type ContactDetailExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Specifies contact information for a person or organization. */
export type ContactDetail_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies contact information for a person or organization. */
export type ContactDetailTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

export type ContactDetailConnectFieldInput = {
  where?: Maybe<ContactDetailWhere>;
  connect?: Maybe<ContactDetailConnectInput>;
};

export type ContactDetailConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _name?: Maybe<ElementConnectFieldInput>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type ContactDetailCreateInput = {
  name?: Maybe<Scalars['String']>;
  extension?: Maybe<ContactDetailExtensionFieldInput>;
  _name?: Maybe<ContactDetail_NameFieldInput>;
  telecom?: Maybe<ContactDetailTelecomFieldInput>;
};

export type ContactDetailDeleteFieldInput = {
  where?: Maybe<ContactDetailWhere>;
  delete?: Maybe<ContactDetailDeleteInput>;
};

export type ContactDetailDeleteInput = {
  extension?: Maybe<Array<ContactDetailExtensionDeleteFieldInput>>;
  _name?: Maybe<ContactDetail_NameDeleteFieldInput>;
  telecom?: Maybe<Array<ContactDetailTelecomDeleteFieldInput>>;
};

export type ContactDetailDisconnectFieldInput = {
  where?: Maybe<ContactDetailWhere>;
  disconnect?: Maybe<ContactDetailDisconnectInput>;
};

export type ContactDetailDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
};

export type ContactDetailExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ContactDetailExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ContactDetailExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ContactDetailOptions = {
  /** Specify one or more ContactDetailSort objects to sort ContactDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ContactDetailSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ContactDetailRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _name?: Maybe<ElementCreateInput>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
};

/** Fields to sort ContactDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one ContactDetailSort object. */
export type ContactDetailSort = {
  id?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
};

export type ContactDetailTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type ContactDetailTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type ContactDetailTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type ContactDetailUpdateInput = {
  name?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<ContactDetailExtensionUpdateFieldInput>>;
  _name?: Maybe<ContactDetail_NameUpdateFieldInput>;
  telecom?: Maybe<Array<ContactDetailTelecomUpdateFieldInput>>;
};

export type ContactDetailWhere = {
  OR?: Maybe<Array<ContactDetailWhere>>;
  AND?: Maybe<Array<ContactDetailWhere>>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
};

export type ContactDetail_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ContactDetail_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ContactDetail_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Details for all kinds of technology mediated contact points for a person or organization, including telephone, email, etc. */
export type ContactPoint = {
  __typename?: 'ContactPoint';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Telecommunications form for contact point - what communications system is required to make use of the contact. */
  system?: Maybe<ContactPointSystem>;
  /** Identifies the purpose for the contact point. */
  use?: Maybe<ContactPointUse>;
  /** The actual contact point details, in a form that is meaningful to the designated communication system (i.e. phone number or email address). */
  value?: Maybe<Scalars['String']>;
  /** Specifies a preferred order in which to use a set of contacts. ContactPoints with lower rank values are more preferred than those with higher rank values. */
  rank?: Maybe<Scalars['PositiveInt']>;
  extension: Array<Extension>;
  _system?: Maybe<Element>;
  _value?: Maybe<Element>;
  _use?: Maybe<Element>;
  _rank?: Maybe<Element>;
  period?: Maybe<Period>;
};

/** Details for all kinds of technology mediated contact points for a person or organization, including telephone, email, etc. */
export type ContactPointExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Details for all kinds of technology mediated contact points for a person or organization, including telephone, email, etc. */
export type ContactPoint_SystemArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details for all kinds of technology mediated contact points for a person or organization, including telephone, email, etc. */
export type ContactPoint_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details for all kinds of technology mediated contact points for a person or organization, including telephone, email, etc. */
export type ContactPoint_UseArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details for all kinds of technology mediated contact points for a person or organization, including telephone, email, etc. */
export type ContactPoint_RankArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details for all kinds of technology mediated contact points for a person or organization, including telephone, email, etc. */
export type ContactPointPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type ContactPointConnectFieldInput = {
  where?: Maybe<ContactPointWhere>;
  connect?: Maybe<ContactPointConnectInput>;
};

export type ContactPointConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _system?: Maybe<ElementConnectFieldInput>;
  _value?: Maybe<ElementConnectFieldInput>;
  _use?: Maybe<ElementConnectFieldInput>;
  _rank?: Maybe<ElementConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type ContactPointCreateInput = {
  value?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['PositiveInt']>;
  system?: Maybe<ContactPointSystem>;
  use?: Maybe<ContactPointUse>;
  extension?: Maybe<ContactPointExtensionFieldInput>;
  _system?: Maybe<ContactPoint_SystemFieldInput>;
  _value?: Maybe<ContactPoint_ValueFieldInput>;
  _use?: Maybe<ContactPoint_UseFieldInput>;
  _rank?: Maybe<ContactPoint_RankFieldInput>;
  period?: Maybe<ContactPointPeriodFieldInput>;
};

export type ContactPointDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type ContactPointDeleteInput = {
  extension?: Maybe<Array<ContactPointExtensionDeleteFieldInput>>;
  _system?: Maybe<ContactPoint_SystemDeleteFieldInput>;
  _value?: Maybe<ContactPoint_ValueDeleteFieldInput>;
  _use?: Maybe<ContactPoint_UseDeleteFieldInput>;
  _rank?: Maybe<ContactPoint_RankDeleteFieldInput>;
  period?: Maybe<ContactPointPeriodDeleteFieldInput>;
};

export type ContactPointDisconnectFieldInput = {
  where?: Maybe<ContactPointWhere>;
  disconnect?: Maybe<ContactPointDisconnectInput>;
};

export type ContactPointDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _system?: Maybe<ElementDisconnectFieldInput>;
  _value?: Maybe<ElementDisconnectFieldInput>;
  _use?: Maybe<ElementDisconnectFieldInput>;
  _rank?: Maybe<ElementDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type ContactPointExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ContactPointExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ContactPointExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ContactPointOptions = {
  /** Specify one or more ContactPointSort objects to sort ContactPoints by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ContactPointSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ContactPointPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type ContactPointPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type ContactPointPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type ContactPointRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _system?: Maybe<ElementCreateInput>;
  _value?: Maybe<ElementCreateInput>;
  _use?: Maybe<ElementCreateInput>;
  _rank?: Maybe<ElementCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

/** Fields to sort ContactPoints by. The order in which sorts are applied is not guaranteed when specifying many fields in one ContactPointSort object. */
export type ContactPointSort = {
  id?: Maybe<SortDirection>;
  system?: Maybe<SortDirection>;
  use?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  rank?: Maybe<SortDirection>;
};

/** Telecommunications form for contact point - what communications system is required to make use of the contact. */
export enum ContactPointSystem {
  Phone = 'phone',
  Fax = 'fax',
  Email = 'email',
  Pager = 'pager',
  Url = 'url',
  Sms = 'sms',
  Other = 'other',
}

export type ContactPointUpdateInput = {
  value?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['PositiveInt']>;
  system?: Maybe<ContactPointSystem>;
  use?: Maybe<ContactPointUse>;
  extension?: Maybe<Array<ContactPointExtensionUpdateFieldInput>>;
  _system?: Maybe<ContactPoint_SystemUpdateFieldInput>;
  _value?: Maybe<ContactPoint_ValueUpdateFieldInput>;
  _use?: Maybe<ContactPoint_UseUpdateFieldInput>;
  _rank?: Maybe<ContactPoint_RankUpdateFieldInput>;
  period?: Maybe<ContactPointPeriodUpdateFieldInput>;
};

/** Identifies the purpose for the contact point. */
export enum ContactPointUse {
  Home = 'home',
  Work = 'work',
  Temp = 'temp',
  Old = 'old',
  Mobile = 'mobile',
}

export type ContactPointWhere = {
  OR?: Maybe<Array<ContactPointWhere>>;
  AND?: Maybe<Array<ContactPointWhere>>;
  value?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['PositiveInt']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  system?: Maybe<ContactPointSystem>;
  system_NOT?: Maybe<ContactPointSystem>;
  system_IN?: Maybe<Array<Maybe<ContactPointSystem>>>;
  system_NOT_IN?: Maybe<Array<Maybe<ContactPointSystem>>>;
  use?: Maybe<ContactPointUse>;
  use_NOT?: Maybe<ContactPointUse>;
  use_IN?: Maybe<Array<Maybe<ContactPointUse>>>;
  use_NOT_IN?: Maybe<Array<Maybe<ContactPointUse>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _system?: Maybe<ElementWhere>;
  _system_NOT?: Maybe<ElementWhere>;
  _system_IN?: Maybe<Array<ElementWhere>>;
  _system_NOT_IN?: Maybe<Array<ElementWhere>>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
  _use?: Maybe<ElementWhere>;
  _use_NOT?: Maybe<ElementWhere>;
  _use_IN?: Maybe<Array<ElementWhere>>;
  _use_NOT_IN?: Maybe<Array<ElementWhere>>;
  _rank?: Maybe<ElementWhere>;
  _rank_NOT?: Maybe<ElementWhere>;
  _rank_IN?: Maybe<Array<ElementWhere>>;
  _rank_NOT_IN?: Maybe<Array<ElementWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type ContactPoint_RankDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ContactPoint_RankFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ContactPoint_RankUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type ContactPoint_SystemDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ContactPoint_SystemFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ContactPoint_SystemUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type ContactPoint_UseDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ContactPoint_UseFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ContactPoint_UseUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type ContactPoint_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ContactPoint_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ContactPoint_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A contributor to the content of a knowledge asset, including authors, editors, reviewers, and endorsers. */
export type Contributor = {
  __typename?: 'Contributor';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The type of contributor. */
  type?: Maybe<ContributorType>;
  /** The name of the individual or organization responsible for the contribution. */
  name?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  _type?: Maybe<Element>;
  _name?: Maybe<Element>;
  contact: Array<ContactDetail>;
};

/** A contributor to the content of a knowledge asset, including authors, editors, reviewers, and endorsers. */
export type ContributorExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A contributor to the content of a knowledge asset, including authors, editors, reviewers, and endorsers. */
export type Contributor_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A contributor to the content of a knowledge asset, including authors, editors, reviewers, and endorsers. */
export type Contributor_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A contributor to the content of a knowledge asset, including authors, editors, reviewers, and endorsers. */
export type ContributorContactArgs = {
  where?: Maybe<ContactDetailWhere>;
  options?: Maybe<ContactDetailOptions>;
};

export type ContributorConnectFieldInput = {
  where?: Maybe<ContributorWhere>;
  connect?: Maybe<ContributorConnectInput>;
};

export type ContributorConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _type?: Maybe<ElementConnectFieldInput>;
  _name?: Maybe<ElementConnectFieldInput>;
  contact?: Maybe<Array<ContactDetailConnectFieldInput>>;
};

export type ContributorContactDeleteFieldInput = {
  where?: Maybe<ContactDetailWhere>;
  delete?: Maybe<ContactDetailDeleteInput>;
};

export type ContributorContactFieldInput = {
  create?: Maybe<Array<ContactDetailCreateInput>>;
  connect?: Maybe<Array<ContactDetailConnectFieldInput>>;
};

export type ContributorContactUpdateFieldInput = {
  where?: Maybe<ContactDetailWhere>;
  update?: Maybe<ContactDetailUpdateInput>;
  connect?: Maybe<Array<ContactDetailConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactDetailDisconnectFieldInput>>;
  create?: Maybe<Array<ContactDetailCreateInput>>;
  delete?: Maybe<Array<ContactDetailDeleteFieldInput>>;
};

export type ContributorCreateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ContributorType>;
  extension?: Maybe<ContributorExtensionFieldInput>;
  _type?: Maybe<Contributor_TypeFieldInput>;
  _name?: Maybe<Contributor_NameFieldInput>;
  contact?: Maybe<ContributorContactFieldInput>;
};

export type ContributorDeleteFieldInput = {
  where?: Maybe<ContributorWhere>;
  delete?: Maybe<ContributorDeleteInput>;
};

export type ContributorDeleteInput = {
  extension?: Maybe<Array<ContributorExtensionDeleteFieldInput>>;
  _type?: Maybe<Contributor_TypeDeleteFieldInput>;
  _name?: Maybe<Contributor_NameDeleteFieldInput>;
  contact?: Maybe<Array<ContributorContactDeleteFieldInput>>;
};

export type ContributorDisconnectFieldInput = {
  where?: Maybe<ContributorWhere>;
  disconnect?: Maybe<ContributorDisconnectInput>;
};

export type ContributorDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _type?: Maybe<ElementDisconnectFieldInput>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  contact?: Maybe<Array<ContactDetailDisconnectFieldInput>>;
};

export type ContributorExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ContributorExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ContributorExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ContributorOptions = {
  /** Specify one or more ContributorSort objects to sort Contributors by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ContributorSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ContributorRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _type?: Maybe<ElementCreateInput>;
  _name?: Maybe<ElementCreateInput>;
  contact?: Maybe<Array<ContactDetailCreateInput>>;
};

/** Fields to sort Contributors by. The order in which sorts are applied is not guaranteed when specifying many fields in one ContributorSort object. */
export type ContributorSort = {
  id?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
};

/** The type of contributor. */
export enum ContributorType {
  Author = 'author',
  Editor = 'editor',
  Reviewer = 'reviewer',
  Endorser = 'endorser',
}

export type ContributorUpdateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ContributorType>;
  extension?: Maybe<Array<ContributorExtensionUpdateFieldInput>>;
  _type?: Maybe<Contributor_TypeUpdateFieldInput>;
  _name?: Maybe<Contributor_NameUpdateFieldInput>;
  contact?: Maybe<Array<ContributorContactUpdateFieldInput>>;
};

export type ContributorWhere = {
  OR?: Maybe<Array<ContributorWhere>>;
  AND?: Maybe<Array<ContributorWhere>>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  type?: Maybe<ContributorType>;
  type_NOT?: Maybe<ContributorType>;
  type_IN?: Maybe<Array<Maybe<ContributorType>>>;
  type_NOT_IN?: Maybe<Array<Maybe<ContributorType>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  contact?: Maybe<ContactDetailWhere>;
  contact_NOT?: Maybe<ContactDetailWhere>;
};

export type Contributor_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Contributor_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Contributor_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Contributor_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Contributor_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Contributor_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Count = {
  __typename?: 'Count';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
  comparator?: Maybe<CountComparator>;
  /** The value of the measured amount. The value includes an implicit precision in the presentation of the value. */
  value?: Maybe<Scalars['Decimal']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['String']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['Uri']>;
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['Code']>;
  extension: Array<Extension>;
  _value?: Maybe<Element>;
  _comparator?: Maybe<Element>;
  _unit?: Maybe<Element>;
  _system?: Maybe<Element>;
  _code?: Maybe<Element>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type CountExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Count_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Count_ComparatorArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Count_UnitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Count_SystemArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Count_CodeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
export enum CountComparator {
  Lt = 'lt',
  Lte = 'lte',
  Gte = 'gte',
  Gt = 'gt',
}

export type CountConnectFieldInput = {
  where?: Maybe<CountWhere>;
  connect?: Maybe<CountConnectInput>;
};

export type CountConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _value?: Maybe<ElementConnectFieldInput>;
  _comparator?: Maybe<ElementConnectFieldInput>;
  _unit?: Maybe<ElementConnectFieldInput>;
  _system?: Maybe<ElementConnectFieldInput>;
  _code?: Maybe<ElementConnectFieldInput>;
};

export type CountCreateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<CountComparator>;
  extension?: Maybe<CountExtensionFieldInput>;
  _value?: Maybe<Count_ValueFieldInput>;
  _comparator?: Maybe<Count_ComparatorFieldInput>;
  _unit?: Maybe<Count_UnitFieldInput>;
  _system?: Maybe<Count_SystemFieldInput>;
  _code?: Maybe<Count_CodeFieldInput>;
};

export type CountDeleteFieldInput = {
  where?: Maybe<CountWhere>;
  delete?: Maybe<CountDeleteInput>;
};

export type CountDeleteInput = {
  extension?: Maybe<Array<CountExtensionDeleteFieldInput>>;
  _value?: Maybe<Count_ValueDeleteFieldInput>;
  _comparator?: Maybe<Count_ComparatorDeleteFieldInput>;
  _unit?: Maybe<Count_UnitDeleteFieldInput>;
  _system?: Maybe<Count_SystemDeleteFieldInput>;
  _code?: Maybe<Count_CodeDeleteFieldInput>;
};

export type CountDisconnectFieldInput = {
  where?: Maybe<CountWhere>;
  disconnect?: Maybe<CountDisconnectInput>;
};

export type CountDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _value?: Maybe<ElementDisconnectFieldInput>;
  _comparator?: Maybe<ElementDisconnectFieldInput>;
  _unit?: Maybe<ElementDisconnectFieldInput>;
  _system?: Maybe<ElementDisconnectFieldInput>;
  _code?: Maybe<ElementDisconnectFieldInput>;
};

export type CountExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CountExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CountExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CountOptions = {
  /** Specify one or more CountSort objects to sort Counts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<CountSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type CountRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _value?: Maybe<ElementCreateInput>;
  _comparator?: Maybe<ElementCreateInput>;
  _unit?: Maybe<ElementCreateInput>;
  _system?: Maybe<ElementCreateInput>;
  _code?: Maybe<ElementCreateInput>;
};

/** Fields to sort Counts by. The order in which sorts are applied is not guaranteed when specifying many fields in one CountSort object. */
export type CountSort = {
  id?: Maybe<SortDirection>;
  comparator?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  unit?: Maybe<SortDirection>;
  system?: Maybe<SortDirection>;
  code?: Maybe<SortDirection>;
};

export type CountUpdateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<CountComparator>;
  extension?: Maybe<Array<CountExtensionUpdateFieldInput>>;
  _value?: Maybe<Count_ValueUpdateFieldInput>;
  _comparator?: Maybe<Count_ComparatorUpdateFieldInput>;
  _unit?: Maybe<Count_UnitUpdateFieldInput>;
  _system?: Maybe<Count_SystemUpdateFieldInput>;
  _code?: Maybe<Count_CodeUpdateFieldInput>;
};

export type CountWhere = {
  OR?: Maybe<Array<CountWhere>>;
  AND?: Maybe<Array<CountWhere>>;
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  comparator?: Maybe<CountComparator>;
  comparator_NOT?: Maybe<CountComparator>;
  comparator_IN?: Maybe<Array<Maybe<CountComparator>>>;
  comparator_NOT_IN?: Maybe<Array<Maybe<CountComparator>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
  _comparator?: Maybe<ElementWhere>;
  _comparator_NOT?: Maybe<ElementWhere>;
  _comparator_IN?: Maybe<Array<ElementWhere>>;
  _comparator_NOT_IN?: Maybe<Array<ElementWhere>>;
  _unit?: Maybe<ElementWhere>;
  _unit_NOT?: Maybe<ElementWhere>;
  _unit_IN?: Maybe<Array<ElementWhere>>;
  _unit_NOT_IN?: Maybe<Array<ElementWhere>>;
  _system?: Maybe<ElementWhere>;
  _system_NOT?: Maybe<ElementWhere>;
  _system_IN?: Maybe<Array<ElementWhere>>;
  _system_NOT_IN?: Maybe<Array<ElementWhere>>;
  _code?: Maybe<ElementWhere>;
  _code_NOT?: Maybe<ElementWhere>;
  _code_IN?: Maybe<Array<ElementWhere>>;
  _code_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Count_CodeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Count_CodeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Count_CodeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Count_ComparatorDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Count_ComparatorFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Count_ComparatorUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Count_SystemDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Count_SystemFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Count_SystemUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Count_UnitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Count_UnitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Count_UnitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Count_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Count_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Count_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type Coverage = {
  __typename?: 'Coverage';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** When 'subrogation=true' this insurance instance has been included not for adjudication but to provide insurers with the details to recover costs. */
  subrogation?: Maybe<Scalars['Boolean']>;
  /** This is a Coverage resource */
  resourceType: CoverageResourceType;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** The status of the resource instance. */
  status?: Maybe<Scalars['Code']>;
  /** The insurer assigned ID for the Subscriber. */
  subscriberId?: Maybe<Scalars['String']>;
  /** A unique identifier for a dependent under the coverage. */
  dependent?: Maybe<Scalars['String']>;
  /** The order of applicability of this coverage relative to other coverages which are currently in force. Note, there may be gaps in the numbering and this does not imply primary, secondary etc. as the specific positioning of coverages depends upon the episode of care. */
  order?: Maybe<Scalars['PositiveInt']>;
  /** The insurer-specific identifier for the insurer-defined network of providers to which the beneficiary may seek treatment which will be covered at the 'in-network' rate, otherwise 'out of network' terms and conditions apply. */
  network?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _status?: Maybe<Element>;
  type?: Maybe<CodeableConcept>;
  policyHolder?: Maybe<Reference>;
  subscriber?: Maybe<Reference>;
  _subscriberId?: Maybe<Element>;
  beneficiary: Reference;
  _dependent?: Maybe<Element>;
  relationship?: Maybe<CodeableConcept>;
  period?: Maybe<Period>;
  payor: Array<Reference>;
  class: Array<CoverageClass>;
  _order?: Maybe<Element>;
  _network?: Maybe<Element>;
  costToBeneficiary: Array<CoverageCostToBeneficiary>;
  _subrogation?: Maybe<Element>;
  contract: Array<Reference>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type Coverage_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type Coverage_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type Coverage_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoveragePolicyHolderArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageSubscriberArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type Coverage_SubscriberIdArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageBeneficiaryArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type Coverage_DependentArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageRelationshipArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoveragePeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoveragePayorArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageClassArgs = {
  where?: Maybe<CoverageClassWhere>;
  options?: Maybe<CoverageClassOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type Coverage_OrderArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type Coverage_NetworkArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageCostToBeneficiaryArgs = {
  where?: Maybe<CoverageCostToBeneficiaryWhere>;
  options?: Maybe<CoverageCostToBeneficiaryOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type Coverage_SubrogationArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageContractArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type CoverageBeneficiaryDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type CoverageBeneficiaryFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type CoverageBeneficiaryUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageClass = {
  __typename?: 'CoverageClass';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The alphanumeric string value associated with the insurer issued label. */
  value?: Maybe<Scalars['String']>;
  /** A short description for the class. */
  name?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type: CodeableConcept;
  _value?: Maybe<Element>;
  _name?: Maybe<Element>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageClassExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageClassModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageClassTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageClass_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageClass_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type CoverageClassConnectFieldInput = {
  where?: Maybe<CoverageClassWhere>;
  connect?: Maybe<CoverageClassConnectInput>;
};

export type CoverageClassConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  _value?: Maybe<ElementConnectFieldInput>;
  _name?: Maybe<ElementConnectFieldInput>;
};

export type CoverageClassCreateInput = {
  value?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  extension?: Maybe<CoverageClassExtensionFieldInput>;
  modifierExtension?: Maybe<CoverageClassModifierextensionFieldInput>;
  type?: Maybe<CoverageClassTypeFieldInput>;
  _value?: Maybe<CoverageClass_ValueFieldInput>;
  _name?: Maybe<CoverageClass_NameFieldInput>;
};

export type CoverageClassDeleteFieldInput = {
  where?: Maybe<CoverageClassWhere>;
  delete?: Maybe<CoverageClassDeleteInput>;
};

export type CoverageClassDeleteInput = {
  extension?: Maybe<Array<CoverageClassExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<CoverageClassModifierextensionDeleteFieldInput>>;
  type?: Maybe<CoverageClassTypeDeleteFieldInput>;
  _value?: Maybe<CoverageClass_ValueDeleteFieldInput>;
  _name?: Maybe<CoverageClass_NameDeleteFieldInput>;
};

export type CoverageClassDisconnectFieldInput = {
  where?: Maybe<CoverageClassWhere>;
  disconnect?: Maybe<CoverageClassDisconnectInput>;
};

export type CoverageClassDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  _value?: Maybe<ElementDisconnectFieldInput>;
  _name?: Maybe<ElementDisconnectFieldInput>;
};

export type CoverageClassExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CoverageClassExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CoverageClassExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CoverageClassFieldInput = {
  create?: Maybe<Array<CoverageClassCreateInput>>;
  connect?: Maybe<Array<CoverageClassConnectFieldInput>>;
};

export type CoverageClassModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CoverageClassModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CoverageClassModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CoverageClassOptions = {
  /** Specify one or more CoverageClassSort objects to sort CoverageClasses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<CoverageClassSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type CoverageClassRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<CodeableConceptCreateInput>;
  _value?: Maybe<ElementCreateInput>;
  _name?: Maybe<ElementCreateInput>;
};

/** Fields to sort CoverageClasses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CoverageClassSort object. */
export type CoverageClassSort = {
  id?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
};

export type CoverageClassTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type CoverageClassTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type CoverageClassTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type CoverageClassUpdateFieldInput = {
  where?: Maybe<CoverageClassWhere>;
  update?: Maybe<CoverageClassUpdateInput>;
  connect?: Maybe<Array<CoverageClassConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageClassDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageClassCreateInput>>;
  delete?: Maybe<Array<CoverageClassDeleteFieldInput>>;
};

export type CoverageClassUpdateInput = {
  value?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<CoverageClassExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<CoverageClassModifierextensionUpdateFieldInput>>;
  type?: Maybe<CoverageClassTypeUpdateFieldInput>;
  _value?: Maybe<CoverageClass_ValueUpdateFieldInput>;
  _name?: Maybe<CoverageClass_NameUpdateFieldInput>;
};

export type CoverageClassWhere = {
  OR?: Maybe<Array<CoverageClassWhere>>;
  AND?: Maybe<Array<CoverageClassWhere>>;
  value?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type CoverageClass_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type CoverageClass_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type CoverageClass_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type CoverageClass_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type CoverageClass_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type CoverageClass_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type CoverageConnectFieldInput = {
  where?: Maybe<CoverageWhere>;
  connect?: Maybe<CoverageConnectInput>;
};

export type CoverageConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  policyHolder?: Maybe<ReferenceConnectFieldInput>;
  subscriber?: Maybe<ReferenceConnectFieldInput>;
  _subscriberId?: Maybe<ElementConnectFieldInput>;
  beneficiary?: Maybe<ReferenceConnectFieldInput>;
  _dependent?: Maybe<ElementConnectFieldInput>;
  relationship?: Maybe<CodeableConceptConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
  payor?: Maybe<Array<ReferenceConnectFieldInput>>;
  class?: Maybe<Array<CoverageClassConnectFieldInput>>;
  _order?: Maybe<ElementConnectFieldInput>;
  _network?: Maybe<ElementConnectFieldInput>;
  costToBeneficiary?: Maybe<Array<CoverageCostToBeneficiaryConnectFieldInput>>;
  _subrogation?: Maybe<ElementConnectFieldInput>;
  contract?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type CoverageContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type CoverageContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type CoverageContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type CoverageContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type CoverageContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type CoverageContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type CoverageContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type CoverageContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type CoverageContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type CoverageContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type CoverageContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type CoverageContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type CoverageContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type CoverageContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type CoverageContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type CoverageContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type CoverageContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type CoverageContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type CoverageContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type CoverageContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type CoverageContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type CoverageContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type CoverageContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type CoverageContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type CoverageContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type CoverageContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type CoverageContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type CoverageContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type CoverageContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type CoverageContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type CoverageContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type CoverageContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type CoverageContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type CoverageContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type CoverageContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type CoverageContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type CoverageContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type CoverageContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type CoverageContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type CoverageContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type CoverageContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type CoverageContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type CoverageContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type CoverageContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type CoverageContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type CoverageContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type CoverageContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type CoverageContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type CoverageContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type CoverageContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type CoverageContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type CoverageContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type CoverageContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type CoverageContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type CoverageContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type CoverageContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type CoverageContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type CoverageContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type CoverageContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type CoverageContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type CoverageContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type CoverageContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type CoverageContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type CoverageContractDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type CoverageContractFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type CoverageContractUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageCostToBeneficiary = {
  __typename?: 'CoverageCostToBeneficiary';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type?: Maybe<CodeableConcept>;
  valueQuantity?: Maybe<Quantity>;
  valueMoney?: Maybe<Money>;
  exception: Array<CoverageException>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageCostToBeneficiaryExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageCostToBeneficiaryModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageCostToBeneficiaryTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageCostToBeneficiaryValueQuantityArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageCostToBeneficiaryValueMoneyArgs = {
  where?: Maybe<MoneyWhere>;
  options?: Maybe<MoneyOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageCostToBeneficiaryExceptionArgs = {
  where?: Maybe<CoverageExceptionWhere>;
  options?: Maybe<CoverageExceptionOptions>;
};

export type CoverageCostToBeneficiaryConnectFieldInput = {
  where?: Maybe<CoverageCostToBeneficiaryWhere>;
  connect?: Maybe<CoverageCostToBeneficiaryConnectInput>;
};

export type CoverageCostToBeneficiaryConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  valueQuantity?: Maybe<QuantityConnectFieldInput>;
  valueMoney?: Maybe<MoneyConnectFieldInput>;
  exception?: Maybe<Array<CoverageExceptionConnectFieldInput>>;
};

export type CoverageCostToBeneficiaryCreateInput = {
  extension?: Maybe<CoverageCostToBeneficiaryExtensionFieldInput>;
  modifierExtension?: Maybe<CoverageCostToBeneficiaryModifierextensionFieldInput>;
  type?: Maybe<CoverageCostToBeneficiaryTypeFieldInput>;
  valueQuantity?: Maybe<CoverageCostToBeneficiaryValuequantityFieldInput>;
  valueMoney?: Maybe<CoverageCostToBeneficiaryValuemoneyFieldInput>;
  exception?: Maybe<CoverageCostToBeneficiaryExceptionFieldInput>;
};

export type CoverageCostToBeneficiaryDeleteFieldInput = {
  where?: Maybe<CoverageCostToBeneficiaryWhere>;
  delete?: Maybe<CoverageCostToBeneficiaryDeleteInput>;
};

export type CoverageCostToBeneficiaryDeleteInput = {
  extension?: Maybe<Array<CoverageCostToBeneficiaryExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<CoverageCostToBeneficiaryModifierextensionDeleteFieldInput>>;
  type?: Maybe<CoverageCostToBeneficiaryTypeDeleteFieldInput>;
  valueQuantity?: Maybe<CoverageCostToBeneficiaryValuequantityDeleteFieldInput>;
  valueMoney?: Maybe<CoverageCostToBeneficiaryValuemoneyDeleteFieldInput>;
  exception?: Maybe<Array<CoverageCostToBeneficiaryExceptionDeleteFieldInput>>;
};

export type CoverageCostToBeneficiaryDisconnectFieldInput = {
  where?: Maybe<CoverageCostToBeneficiaryWhere>;
  disconnect?: Maybe<CoverageCostToBeneficiaryDisconnectInput>;
};

export type CoverageCostToBeneficiaryDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  valueQuantity?: Maybe<QuantityDisconnectFieldInput>;
  valueMoney?: Maybe<MoneyDisconnectFieldInput>;
  exception?: Maybe<Array<CoverageExceptionDisconnectFieldInput>>;
};

export type CoverageCostToBeneficiaryExceptionDeleteFieldInput = {
  where?: Maybe<CoverageExceptionWhere>;
  delete?: Maybe<CoverageExceptionDeleteInput>;
};

export type CoverageCostToBeneficiaryExceptionFieldInput = {
  create?: Maybe<Array<CoverageExceptionCreateInput>>;
  connect?: Maybe<Array<CoverageExceptionConnectFieldInput>>;
};

export type CoverageCostToBeneficiaryExceptionUpdateFieldInput = {
  where?: Maybe<CoverageExceptionWhere>;
  update?: Maybe<CoverageExceptionUpdateInput>;
  connect?: Maybe<Array<CoverageExceptionConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageExceptionDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageExceptionCreateInput>>;
  delete?: Maybe<Array<CoverageExceptionDeleteFieldInput>>;
};

export type CoverageCostToBeneficiaryExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CoverageCostToBeneficiaryExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CoverageCostToBeneficiaryExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CoverageCostToBeneficiaryModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CoverageCostToBeneficiaryModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CoverageCostToBeneficiaryModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CoverageCostToBeneficiaryOptions = {
  /** Specify one or more CoverageCostToBeneficiarySort objects to sort CoverageCostToBeneficiaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<CoverageCostToBeneficiarySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type CoverageCostToBeneficiaryRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<CodeableConceptCreateInput>;
  valueQuantity?: Maybe<QuantityCreateInput>;
  valueMoney?: Maybe<MoneyCreateInput>;
  exception?: Maybe<Array<CoverageExceptionCreateInput>>;
};

/** Fields to sort CoverageCostToBeneficiaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one CoverageCostToBeneficiarySort object. */
export type CoverageCostToBeneficiarySort = {
  id?: Maybe<SortDirection>;
};

export type CoverageCostToBeneficiaryTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type CoverageCostToBeneficiaryTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type CoverageCostToBeneficiaryTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type CoverageCostToBeneficiaryUpdateInput = {
  extension?: Maybe<Array<CoverageCostToBeneficiaryExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<CoverageCostToBeneficiaryModifierextensionUpdateFieldInput>>;
  type?: Maybe<CoverageCostToBeneficiaryTypeUpdateFieldInput>;
  valueQuantity?: Maybe<CoverageCostToBeneficiaryValuequantityUpdateFieldInput>;
  valueMoney?: Maybe<CoverageCostToBeneficiaryValuemoneyUpdateFieldInput>;
  exception?: Maybe<Array<CoverageCostToBeneficiaryExceptionUpdateFieldInput>>;
};

export type CoverageCostToBeneficiaryValuemoneyDeleteFieldInput = {
  where?: Maybe<MoneyWhere>;
  delete?: Maybe<MoneyDeleteInput>;
};

export type CoverageCostToBeneficiaryValuemoneyFieldInput = {
  create?: Maybe<MoneyCreateInput>;
  connect?: Maybe<MoneyConnectFieldInput>;
};

export type CoverageCostToBeneficiaryValuemoneyUpdateFieldInput = {
  where?: Maybe<MoneyWhere>;
  update?: Maybe<MoneyUpdateInput>;
  connect?: Maybe<MoneyConnectFieldInput>;
  disconnect?: Maybe<MoneyDisconnectFieldInput>;
  create?: Maybe<MoneyCreateInput>;
  delete?: Maybe<MoneyDeleteFieldInput>;
};

export type CoverageCostToBeneficiaryValuequantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type CoverageCostToBeneficiaryValuequantityFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type CoverageCostToBeneficiaryValuequantityUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type CoverageCostToBeneficiaryWhere = {
  OR?: Maybe<Array<CoverageCostToBeneficiaryWhere>>;
  AND?: Maybe<Array<CoverageCostToBeneficiaryWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  valueQuantity?: Maybe<QuantityWhere>;
  valueQuantity_NOT?: Maybe<QuantityWhere>;
  valueQuantity_IN?: Maybe<Array<QuantityWhere>>;
  valueQuantity_NOT_IN?: Maybe<Array<QuantityWhere>>;
  valueMoney?: Maybe<MoneyWhere>;
  valueMoney_NOT?: Maybe<MoneyWhere>;
  valueMoney_IN?: Maybe<Array<MoneyWhere>>;
  valueMoney_NOT_IN?: Maybe<Array<MoneyWhere>>;
  exception?: Maybe<CoverageExceptionWhere>;
  exception_NOT?: Maybe<CoverageExceptionWhere>;
};

export type CoverageCosttobeneficiaryDeleteFieldInput = {
  where?: Maybe<CoverageCostToBeneficiaryWhere>;
  delete?: Maybe<CoverageCostToBeneficiaryDeleteInput>;
};

export type CoverageCosttobeneficiaryFieldInput = {
  create?: Maybe<Array<CoverageCostToBeneficiaryCreateInput>>;
  connect?: Maybe<Array<CoverageCostToBeneficiaryConnectFieldInput>>;
};

export type CoverageCosttobeneficiaryUpdateFieldInput = {
  where?: Maybe<CoverageCostToBeneficiaryWhere>;
  update?: Maybe<CoverageCostToBeneficiaryUpdateInput>;
  connect?: Maybe<Array<CoverageCostToBeneficiaryConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageCostToBeneficiaryDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCostToBeneficiaryCreateInput>>;
  delete?: Maybe<Array<CoverageCostToBeneficiaryDeleteFieldInput>>;
};

export type CoverageCreateInput = {
  subrogation?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  status?: Maybe<Scalars['Code']>;
  subscriberId?: Maybe<Scalars['String']>;
  dependent?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['PositiveInt']>;
  network?: Maybe<Scalars['String']>;
  resourceType: CoverageResourceType;
  meta?: Maybe<CoverageMetaFieldInput>;
  _implicitRules?: Maybe<Coverage_ImplicitrulesFieldInput>;
  _language?: Maybe<Coverage_LanguageFieldInput>;
  text?: Maybe<CoverageTextFieldInput>;
  contained_Account?: Maybe<CoverageContainedAccountFieldInput>;
  contained_Appointment?: Maybe<CoverageContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<CoverageContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<CoverageContainedCoverageFieldInput>;
  contained_Device?: Maybe<CoverageContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<CoverageContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<CoverageContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<CoverageContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<CoverageContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<CoverageContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<CoverageContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<CoverageContainedLocationFieldInput>;
  contained_Organization?: Maybe<CoverageContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<CoverageContainedPatientFieldInput>;
  contained_Person?: Maybe<CoverageContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<CoverageContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<CoverageContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<CoverageContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<CoverageContainedScheduleFieldInput>;
  contained_Slot?: Maybe<CoverageContainedSlotFieldInput>;
  contained_Substance?: Maybe<CoverageContainedSubstanceFieldInput>;
  extension?: Maybe<CoverageExtensionFieldInput>;
  modifierExtension?: Maybe<CoverageModifierextensionFieldInput>;
  identifier?: Maybe<CoverageIdentifierFieldInput>;
  _status?: Maybe<Coverage_StatusFieldInput>;
  type?: Maybe<CoverageTypeFieldInput>;
  policyHolder?: Maybe<CoveragePolicyholderFieldInput>;
  subscriber?: Maybe<CoverageSubscriberFieldInput>;
  _subscriberId?: Maybe<Coverage_SubscriberidFieldInput>;
  beneficiary?: Maybe<CoverageBeneficiaryFieldInput>;
  _dependent?: Maybe<Coverage_DependentFieldInput>;
  relationship?: Maybe<CoverageRelationshipFieldInput>;
  period?: Maybe<CoveragePeriodFieldInput>;
  payor?: Maybe<CoveragePayorFieldInput>;
  class?: Maybe<CoverageClassFieldInput>;
  _order?: Maybe<Coverage_OrderFieldInput>;
  _network?: Maybe<Coverage_NetworkFieldInput>;
  costToBeneficiary?: Maybe<CoverageCosttobeneficiaryFieldInput>;
  _subrogation?: Maybe<Coverage_SubrogationFieldInput>;
  contract?: Maybe<CoverageContractFieldInput>;
};

export type CoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type CoverageDeleteInput = {
  meta?: Maybe<CoverageMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Coverage_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Coverage_LanguageDeleteFieldInput>;
  text?: Maybe<CoverageTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<CoverageContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<CoverageContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<CoverageContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<CoverageContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<CoverageContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<CoverageContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<CoverageContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<CoverageContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<CoverageContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<CoverageContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<CoverageContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<CoverageContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<CoverageContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<CoverageContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<CoverageContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<CoverageContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<CoverageContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<CoverageContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<CoverageContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<CoverageContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<CoverageExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<CoverageModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<CoverageIdentifierDeleteFieldInput>>;
  _status?: Maybe<Coverage_StatusDeleteFieldInput>;
  type?: Maybe<CoverageTypeDeleteFieldInput>;
  policyHolder?: Maybe<CoveragePolicyholderDeleteFieldInput>;
  subscriber?: Maybe<CoverageSubscriberDeleteFieldInput>;
  _subscriberId?: Maybe<Coverage_SubscriberidDeleteFieldInput>;
  beneficiary?: Maybe<CoverageBeneficiaryDeleteFieldInput>;
  _dependent?: Maybe<Coverage_DependentDeleteFieldInput>;
  relationship?: Maybe<CoverageRelationshipDeleteFieldInput>;
  period?: Maybe<CoveragePeriodDeleteFieldInput>;
  payor?: Maybe<Array<CoveragePayorDeleteFieldInput>>;
  class?: Maybe<Array<CoverageClassDeleteFieldInput>>;
  _order?: Maybe<Coverage_OrderDeleteFieldInput>;
  _network?: Maybe<Coverage_NetworkDeleteFieldInput>;
  costToBeneficiary?: Maybe<Array<CoverageCosttobeneficiaryDeleteFieldInput>>;
  _subrogation?: Maybe<Coverage_SubrogationDeleteFieldInput>;
  contract?: Maybe<Array<CoverageContractDeleteFieldInput>>;
};

export type CoverageDisconnectFieldInput = {
  where?: Maybe<CoverageWhere>;
  disconnect?: Maybe<CoverageDisconnectInput>;
};

export type CoverageDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  policyHolder?: Maybe<ReferenceDisconnectFieldInput>;
  subscriber?: Maybe<ReferenceDisconnectFieldInput>;
  _subscriberId?: Maybe<ElementDisconnectFieldInput>;
  beneficiary?: Maybe<ReferenceDisconnectFieldInput>;
  _dependent?: Maybe<ElementDisconnectFieldInput>;
  relationship?: Maybe<CodeableConceptDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
  payor?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  class?: Maybe<Array<CoverageClassDisconnectFieldInput>>;
  _order?: Maybe<ElementDisconnectFieldInput>;
  _network?: Maybe<ElementDisconnectFieldInput>;
  costToBeneficiary?: Maybe<Array<CoverageCostToBeneficiaryDisconnectFieldInput>>;
  _subrogation?: Maybe<ElementDisconnectFieldInput>;
  contract?: Maybe<Array<ReferenceDisconnectFieldInput>>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageException = {
  __typename?: 'CoverageException';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type: CodeableConcept;
  period?: Maybe<Period>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageExceptionExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageExceptionModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageExceptionTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Financial instrument which may be used to reimburse or pay for health care products and services. Includes both insurance and self-payment. */
export type CoverageExceptionPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type CoverageExceptionConnectFieldInput = {
  where?: Maybe<CoverageExceptionWhere>;
  connect?: Maybe<CoverageExceptionConnectInput>;
};

export type CoverageExceptionConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type CoverageExceptionCreateInput = {
  extension?: Maybe<CoverageExceptionExtensionFieldInput>;
  modifierExtension?: Maybe<CoverageExceptionModifierextensionFieldInput>;
  type?: Maybe<CoverageExceptionTypeFieldInput>;
  period?: Maybe<CoverageExceptionPeriodFieldInput>;
};

export type CoverageExceptionDeleteFieldInput = {
  where?: Maybe<CoverageExceptionWhere>;
  delete?: Maybe<CoverageExceptionDeleteInput>;
};

export type CoverageExceptionDeleteInput = {
  extension?: Maybe<Array<CoverageExceptionExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<CoverageExceptionModifierextensionDeleteFieldInput>>;
  type?: Maybe<CoverageExceptionTypeDeleteFieldInput>;
  period?: Maybe<CoverageExceptionPeriodDeleteFieldInput>;
};

export type CoverageExceptionDisconnectFieldInput = {
  where?: Maybe<CoverageExceptionWhere>;
  disconnect?: Maybe<CoverageExceptionDisconnectInput>;
};

export type CoverageExceptionDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type CoverageExceptionExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CoverageExceptionExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CoverageExceptionExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CoverageExceptionModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CoverageExceptionModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CoverageExceptionModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CoverageExceptionOptions = {
  /** Specify one or more CoverageExceptionSort objects to sort CoverageExceptions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<CoverageExceptionSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type CoverageExceptionPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type CoverageExceptionPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type CoverageExceptionPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type CoverageExceptionRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<CodeableConceptCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

/** Fields to sort CoverageExceptions by. The order in which sorts are applied is not guaranteed when specifying many fields in one CoverageExceptionSort object. */
export type CoverageExceptionSort = {
  id?: Maybe<SortDirection>;
};

export type CoverageExceptionTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type CoverageExceptionTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type CoverageExceptionTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type CoverageExceptionUpdateInput = {
  extension?: Maybe<Array<CoverageExceptionExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<CoverageExceptionModifierextensionUpdateFieldInput>>;
  type?: Maybe<CoverageExceptionTypeUpdateFieldInput>;
  period?: Maybe<CoverageExceptionPeriodUpdateFieldInput>;
};

export type CoverageExceptionWhere = {
  OR?: Maybe<Array<CoverageExceptionWhere>>;
  AND?: Maybe<Array<CoverageExceptionWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type CoverageExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CoverageExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CoverageExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CoverageIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type CoverageIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type CoverageIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type CoverageMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type CoverageMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type CoverageMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type CoverageModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type CoverageModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type CoverageModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type CoverageOptions = {
  /** Specify one or more CoverageSort objects to sort Coverages by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<CoverageSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type CoveragePayorDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type CoveragePayorFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type CoveragePayorUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type CoveragePeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type CoveragePeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type CoveragePeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type CoveragePolicyholderDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type CoveragePolicyholderFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type CoveragePolicyholderUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type CoverageRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  type?: Maybe<CodeableConceptCreateInput>;
  policyHolder?: Maybe<ReferenceCreateInput>;
  subscriber?: Maybe<ReferenceCreateInput>;
  _subscriberId?: Maybe<ElementCreateInput>;
  beneficiary?: Maybe<ReferenceCreateInput>;
  _dependent?: Maybe<ElementCreateInput>;
  relationship?: Maybe<CodeableConceptCreateInput>;
  period?: Maybe<PeriodCreateInput>;
  payor?: Maybe<Array<ReferenceCreateInput>>;
  class?: Maybe<Array<CoverageClassCreateInput>>;
  _order?: Maybe<ElementCreateInput>;
  _network?: Maybe<ElementCreateInput>;
  costToBeneficiary?: Maybe<Array<CoverageCostToBeneficiaryCreateInput>>;
  _subrogation?: Maybe<ElementCreateInput>;
  contract?: Maybe<Array<ReferenceCreateInput>>;
};

export type CoverageRelationshipDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type CoverageRelationshipFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type CoverageRelationshipUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export enum CoverageResourceType {
  Coverage = 'Coverage',
}

/** Fields to sort Coverages by. The order in which sorts are applied is not guaranteed when specifying many fields in one CoverageSort object. */
export type CoverageSort = {
  id?: Maybe<SortDirection>;
  subrogation?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  subscriberId?: Maybe<SortDirection>;
  dependent?: Maybe<SortDirection>;
  order?: Maybe<SortDirection>;
  network?: Maybe<SortDirection>;
};

export type CoverageSubscriberDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type CoverageSubscriberFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type CoverageSubscriberUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type CoverageTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type CoverageTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type CoverageTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type CoverageTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type CoverageTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type CoverageTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type CoverageUpdateInput = {
  subrogation?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  status?: Maybe<Scalars['Code']>;
  subscriberId?: Maybe<Scalars['String']>;
  dependent?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['PositiveInt']>;
  network?: Maybe<Scalars['String']>;
  resourceType?: Maybe<CoverageResourceType>;
  meta?: Maybe<CoverageMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Coverage_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Coverage_LanguageUpdateFieldInput>;
  text?: Maybe<CoverageTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<CoverageContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<CoverageContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<CoverageContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<CoverageContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<CoverageContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<CoverageContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<CoverageContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<CoverageContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<CoverageContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<CoverageContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<CoverageContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<CoverageContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<CoverageContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<CoverageContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<CoverageContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<CoverageContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<CoverageContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<CoverageContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<CoverageContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<CoverageContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<CoverageExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<CoverageModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<CoverageIdentifierUpdateFieldInput>>;
  _status?: Maybe<Coverage_StatusUpdateFieldInput>;
  type?: Maybe<CoverageTypeUpdateFieldInput>;
  policyHolder?: Maybe<CoveragePolicyholderUpdateFieldInput>;
  subscriber?: Maybe<CoverageSubscriberUpdateFieldInput>;
  _subscriberId?: Maybe<Coverage_SubscriberidUpdateFieldInput>;
  beneficiary?: Maybe<CoverageBeneficiaryUpdateFieldInput>;
  _dependent?: Maybe<Coverage_DependentUpdateFieldInput>;
  relationship?: Maybe<CoverageRelationshipUpdateFieldInput>;
  period?: Maybe<CoveragePeriodUpdateFieldInput>;
  payor?: Maybe<Array<CoveragePayorUpdateFieldInput>>;
  class?: Maybe<Array<CoverageClassUpdateFieldInput>>;
  _order?: Maybe<Coverage_OrderUpdateFieldInput>;
  _network?: Maybe<Coverage_NetworkUpdateFieldInput>;
  costToBeneficiary?: Maybe<Array<CoverageCosttobeneficiaryUpdateFieldInput>>;
  _subrogation?: Maybe<Coverage_SubrogationUpdateFieldInput>;
  contract?: Maybe<Array<CoverageContractUpdateFieldInput>>;
};

export type CoverageWhere = {
  OR?: Maybe<Array<CoverageWhere>>;
  AND?: Maybe<Array<CoverageWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  status?: Maybe<Scalars['Code']>;
  subscriberId?: Maybe<Scalars['String']>;
  dependent?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['PositiveInt']>;
  network?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  subrogation?: Maybe<Scalars['Boolean']>;
  subrogation_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<CoverageResourceType>;
  resourceType_NOT?: Maybe<CoverageResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<CoverageResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<CoverageResourceType>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  policyHolder?: Maybe<ReferenceWhere>;
  policyHolder_NOT?: Maybe<ReferenceWhere>;
  policyHolder_IN?: Maybe<Array<ReferenceWhere>>;
  policyHolder_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  subscriber?: Maybe<ReferenceWhere>;
  subscriber_NOT?: Maybe<ReferenceWhere>;
  subscriber_IN?: Maybe<Array<ReferenceWhere>>;
  subscriber_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _subscriberId?: Maybe<ElementWhere>;
  _subscriberId_NOT?: Maybe<ElementWhere>;
  _subscriberId_IN?: Maybe<Array<ElementWhere>>;
  _subscriberId_NOT_IN?: Maybe<Array<ElementWhere>>;
  beneficiary?: Maybe<ReferenceWhere>;
  beneficiary_NOT?: Maybe<ReferenceWhere>;
  beneficiary_IN?: Maybe<Array<ReferenceWhere>>;
  beneficiary_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _dependent?: Maybe<ElementWhere>;
  _dependent_NOT?: Maybe<ElementWhere>;
  _dependent_IN?: Maybe<Array<ElementWhere>>;
  _dependent_NOT_IN?: Maybe<Array<ElementWhere>>;
  relationship?: Maybe<CodeableConceptWhere>;
  relationship_NOT?: Maybe<CodeableConceptWhere>;
  relationship_IN?: Maybe<Array<CodeableConceptWhere>>;
  relationship_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
  payor?: Maybe<ReferenceWhere>;
  payor_NOT?: Maybe<ReferenceWhere>;
  class?: Maybe<CoverageClassWhere>;
  class_NOT?: Maybe<CoverageClassWhere>;
  _order?: Maybe<ElementWhere>;
  _order_NOT?: Maybe<ElementWhere>;
  _order_IN?: Maybe<Array<ElementWhere>>;
  _order_NOT_IN?: Maybe<Array<ElementWhere>>;
  _network?: Maybe<ElementWhere>;
  _network_NOT?: Maybe<ElementWhere>;
  _network_IN?: Maybe<Array<ElementWhere>>;
  _network_NOT_IN?: Maybe<Array<ElementWhere>>;
  costToBeneficiary?: Maybe<CoverageCostToBeneficiaryWhere>;
  costToBeneficiary_NOT?: Maybe<CoverageCostToBeneficiaryWhere>;
  _subrogation?: Maybe<ElementWhere>;
  _subrogation_NOT?: Maybe<ElementWhere>;
  _subrogation_IN?: Maybe<Array<ElementWhere>>;
  _subrogation_NOT_IN?: Maybe<Array<ElementWhere>>;
  contract?: Maybe<ReferenceWhere>;
  contract_NOT?: Maybe<ReferenceWhere>;
};

export type Coverage_DependentDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coverage_DependentFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coverage_DependentUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coverage_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coverage_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coverage_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coverage_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coverage_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coverage_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coverage_NetworkDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coverage_NetworkFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coverage_NetworkUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coverage_OrderDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coverage_OrderFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coverage_OrderUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coverage_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coverage_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coverage_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coverage_SubrogationDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coverage_SubrogationFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coverage_SubrogationUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Coverage_SubscriberidDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Coverage_SubscriberidFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Coverage_SubscriberidUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type CreateAccountCoveragesMutationResponse = {
  __typename?: 'CreateAccountCoveragesMutationResponse';
  accountCoverages: Array<AccountCoverage>;
};

export type CreateAccountGuarantorsMutationResponse = {
  __typename?: 'CreateAccountGuarantorsMutationResponse';
  accountGuarantors: Array<AccountGuarantor>;
};

export type CreateAccountsMutationResponse = {
  __typename?: 'CreateAccountsMutationResponse';
  accounts: Array<Account>;
};

export type CreateAddressesMutationResponse = {
  __typename?: 'CreateAddressesMutationResponse';
  addresses: Array<Address>;
};

export type CreateAgesMutationResponse = {
  __typename?: 'CreateAgesMutationResponse';
  ages: Array<Age>;
};

export type CreateAnnotationsMutationResponse = {
  __typename?: 'CreateAnnotationsMutationResponse';
  annotations: Array<Annotation>;
};

export type CreateAppointmentParticipantsMutationResponse = {
  __typename?: 'CreateAppointmentParticipantsMutationResponse';
  appointmentParticipants: Array<AppointmentParticipant>;
};

export type CreateAppointmentResponsesMutationResponse = {
  __typename?: 'CreateAppointmentResponsesMutationResponse';
  appointmentResponses: Array<AppointmentResponse>;
};

export type CreateAppointmentsMutationResponse = {
  __typename?: 'CreateAppointmentsMutationResponse';
  appointments: Array<Appointment>;
};

export type CreateAttachmentsMutationResponse = {
  __typename?: 'CreateAttachmentsMutationResponse';
  attachments: Array<Attachment>;
};

export type CreateCodeableConceptsMutationResponse = {
  __typename?: 'CreateCodeableConceptsMutationResponse';
  codeableConcepts: Array<CodeableConcept>;
};

export type CreateCodingsMutationResponse = {
  __typename?: 'CreateCodingsMutationResponse';
  codings: Array<Coding>;
};

export type CreateContactDetailsMutationResponse = {
  __typename?: 'CreateContactDetailsMutationResponse';
  contactDetails: Array<ContactDetail>;
};

export type CreateContactPointsMutationResponse = {
  __typename?: 'CreateContactPointsMutationResponse';
  contactPoints: Array<ContactPoint>;
};

export type CreateContributorsMutationResponse = {
  __typename?: 'CreateContributorsMutationResponse';
  contributors: Array<Contributor>;
};

export type CreateCountsMutationResponse = {
  __typename?: 'CreateCountsMutationResponse';
  counts: Array<Count>;
};

export type CreateCoverageClassesMutationResponse = {
  __typename?: 'CreateCoverageClassesMutationResponse';
  coverageClasses: Array<CoverageClass>;
};

export type CreateCoverageCostToBeneficiariesMutationResponse = {
  __typename?: 'CreateCoverageCostToBeneficiariesMutationResponse';
  coverageCostToBeneficiaries: Array<CoverageCostToBeneficiary>;
};

export type CreateCoverageExceptionsMutationResponse = {
  __typename?: 'CreateCoverageExceptionsMutationResponse';
  coverageExceptions: Array<CoverageException>;
};

export type CreateCoveragesMutationResponse = {
  __typename?: 'CreateCoveragesMutationResponse';
  coverages: Array<Coverage>;
};

export type CreateDataRequirementCodeFiltersMutationResponse = {
  __typename?: 'CreateDataRequirementCodeFiltersMutationResponse';
  dataRequirementCodeFilters: Array<DataRequirementCodeFilter>;
};

export type CreateDataRequirementCustomSortsMutationResponse = {
  __typename?: 'CreateDataRequirementCustomSortsMutationResponse';
  dataRequirementCustomSorts: Array<DataRequirementCustomSort>;
};

export type CreateDataRequirementDateFiltersMutationResponse = {
  __typename?: 'CreateDataRequirementDateFiltersMutationResponse';
  dataRequirementDateFilters: Array<DataRequirementDateFilter>;
};

export type CreateDataRequirementsMutationResponse = {
  __typename?: 'CreateDataRequirementsMutationResponse';
  dataRequirements: Array<DataRequirement>;
};

export type CreateDeviceDefinitionCapabilitiesMutationResponse = {
  __typename?: 'CreateDeviceDefinitionCapabilitiesMutationResponse';
  deviceDefinitionCapabilities: Array<DeviceDefinitionCapability>;
};

export type CreateDeviceDefinitionDeviceNamesMutationResponse = {
  __typename?: 'CreateDeviceDefinitionDeviceNamesMutationResponse';
  deviceDefinitionDeviceNames: Array<DeviceDefinitionDeviceName>;
};

export type CreateDeviceDefinitionMaterialsMutationResponse = {
  __typename?: 'CreateDeviceDefinitionMaterialsMutationResponse';
  deviceDefinitionMaterials: Array<DeviceDefinitionMaterial>;
};

export type CreateDeviceDefinitionPropertiesMutationResponse = {
  __typename?: 'CreateDeviceDefinitionPropertiesMutationResponse';
  deviceDefinitionProperties: Array<DeviceDefinitionProperty>;
};

export type CreateDeviceDefinitionSpecializationsMutationResponse = {
  __typename?: 'CreateDeviceDefinitionSpecializationsMutationResponse';
  deviceDefinitionSpecializations: Array<DeviceDefinitionSpecialization>;
};

export type CreateDeviceDefinitionUdiDeviceIdentifiersMutationResponse = {
  __typename?: 'CreateDeviceDefinitionUdiDeviceIdentifiersMutationResponse';
  deviceDefinitionUdiDeviceIdentifiers: Array<DeviceDefinitionUdiDeviceIdentifier>;
};

export type CreateDeviceDefinitionsMutationResponse = {
  __typename?: 'CreateDeviceDefinitionsMutationResponse';
  deviceDefinitions: Array<DeviceDefinition>;
};

export type CreateDeviceDeviceNamesMutationResponse = {
  __typename?: 'CreateDeviceDeviceNamesMutationResponse';
  deviceDeviceNames: Array<DeviceDeviceName>;
};

export type CreateDeviceMetricCalibrationsMutationResponse = {
  __typename?: 'CreateDeviceMetricCalibrationsMutationResponse';
  deviceMetricCalibrations: Array<DeviceMetricCalibration>;
};

export type CreateDeviceMetricsMutationResponse = {
  __typename?: 'CreateDeviceMetricsMutationResponse';
  deviceMetrics: Array<DeviceMetric>;
};

export type CreateDevicePropertiesMutationResponse = {
  __typename?: 'CreateDevicePropertiesMutationResponse';
  deviceProperties: Array<DeviceProperty>;
};

export type CreateDeviceSpecializationsMutationResponse = {
  __typename?: 'CreateDeviceSpecializationsMutationResponse';
  deviceSpecializations: Array<DeviceSpecialization>;
};

export type CreateDeviceUdiCarriersMutationResponse = {
  __typename?: 'CreateDeviceUdiCarriersMutationResponse';
  deviceUdiCarriers: Array<DeviceUdiCarrier>;
};

export type CreateDeviceVersionsMutationResponse = {
  __typename?: 'CreateDeviceVersionsMutationResponse';
  deviceVersions: Array<DeviceVersion>;
};

export type CreateDevicesMutationResponse = {
  __typename?: 'CreateDevicesMutationResponse';
  devices: Array<Device>;
};

export type CreateDistancesMutationResponse = {
  __typename?: 'CreateDistancesMutationResponse';
  distances: Array<Distance>;
};

export type CreateDosageDoseAndRatesMutationResponse = {
  __typename?: 'CreateDosageDoseAndRatesMutationResponse';
  dosageDoseAndRates: Array<DosageDoseAndRate>;
};

export type CreateDosagesMutationResponse = {
  __typename?: 'CreateDosagesMutationResponse';
  dosages: Array<Dosage>;
};

export type CreateDurationsMutationResponse = {
  __typename?: 'CreateDurationsMutationResponse';
  durations: Array<Duration>;
};

export type CreateElementsMutationResponse = {
  __typename?: 'CreateElementsMutationResponse';
  elements: Array<Element>;
};

export type CreateEncounterClassHistoriesMutationResponse = {
  __typename?: 'CreateEncounterClassHistoriesMutationResponse';
  encounterClassHistories: Array<EncounterClassHistory>;
};

export type CreateEncounterDiagnosesMutationResponse = {
  __typename?: 'CreateEncounterDiagnosesMutationResponse';
  encounterDiagnoses: Array<EncounterDiagnosis>;
};

export type CreateEncounterHospitalizationsMutationResponse = {
  __typename?: 'CreateEncounterHospitalizationsMutationResponse';
  encounterHospitalizations: Array<EncounterHospitalization>;
};

export type CreateEncounterLocationsMutationResponse = {
  __typename?: 'CreateEncounterLocationsMutationResponse';
  encounterLocations: Array<EncounterLocation>;
};

export type CreateEncounterParticipantsMutationResponse = {
  __typename?: 'CreateEncounterParticipantsMutationResponse';
  encounterParticipants: Array<EncounterParticipant>;
};

export type CreateEncounterStatusHistoriesMutationResponse = {
  __typename?: 'CreateEncounterStatusHistoriesMutationResponse';
  encounterStatusHistories: Array<EncounterStatusHistory>;
};

export type CreateEncountersMutationResponse = {
  __typename?: 'CreateEncountersMutationResponse';
  encounters: Array<Encounter>;
};

export type CreateEndpointsMutationResponse = {
  __typename?: 'CreateEndpointsMutationResponse';
  endpoints: Array<Endpoint>;
};

export type CreateEpisodeOfCareDiagnosesMutationResponse = {
  __typename?: 'CreateEpisodeOfCareDiagnosesMutationResponse';
  episodeOfCareDiagnoses: Array<EpisodeOfCareDiagnosis>;
};

export type CreateEpisodeOfCareStatusHistoriesMutationResponse = {
  __typename?: 'CreateEpisodeOfCareStatusHistoriesMutationResponse';
  episodeOfCareStatusHistories: Array<EpisodeOfCareStatusHistory>;
};

export type CreateEpisodeOfCaresMutationResponse = {
  __typename?: 'CreateEpisodeOfCaresMutationResponse';
  episodeOfCares: Array<EpisodeOfCare>;
};

export type CreateExpressionsMutationResponse = {
  __typename?: 'CreateExpressionsMutationResponse';
  expressions: Array<Expression>;
};

export type CreateExtensionsMutationResponse = {
  __typename?: 'CreateExtensionsMutationResponse';
  extensions: Array<Extension>;
};

export type CreateHealthcareServiceAvailableTimesMutationResponse = {
  __typename?: 'CreateHealthcareServiceAvailableTimesMutationResponse';
  healthcareServiceAvailableTimes: Array<HealthcareServiceAvailableTime>;
};

export type CreateHealthcareServiceEligibilitiesMutationResponse = {
  __typename?: 'CreateHealthcareServiceEligibilitiesMutationResponse';
  healthcareServiceEligibilities: Array<HealthcareServiceEligibility>;
};

export type CreateHealthcareServiceNotAvailablesMutationResponse = {
  __typename?: 'CreateHealthcareServiceNotAvailablesMutationResponse';
  healthcareServiceNotAvailables: Array<HealthcareServiceNotAvailable>;
};

export type CreateHealthcareServicesMutationResponse = {
  __typename?: 'CreateHealthcareServicesMutationResponse';
  healthcareServices: Array<HealthcareService>;
};

export type CreateHumanNamesMutationResponse = {
  __typename?: 'CreateHumanNamesMutationResponse';
  humanNames: Array<HumanName>;
};

export type CreateIdentifiersMutationResponse = {
  __typename?: 'CreateIdentifiersMutationResponse';
  identifiers: Array<Identifier>;
};

export type CreateLocationHoursOfOperationsMutationResponse = {
  __typename?: 'CreateLocationHoursOfOperationsMutationResponse';
  locationHoursOfOperations: Array<LocationHoursOfOperation>;
};

export type CreateLocationPositionsMutationResponse = {
  __typename?: 'CreateLocationPositionsMutationResponse';
  locationPositions: Array<LocationPosition>;
};

export type CreateLocationsMutationResponse = {
  __typename?: 'CreateLocationsMutationResponse';
  locations: Array<Location>;
};

export type CreateMetasMutationResponse = {
  __typename?: 'CreateMetasMutationResponse';
  metas: Array<Meta>;
};

export type CreateMoniesMutationResponse = {
  __typename?: 'CreateMoniesMutationResponse';
  monies: Array<Money>;
};

export type CreateNarrativesMutationResponse = {
  __typename?: 'CreateNarrativesMutationResponse';
  narratives: Array<Narrative>;
};

export type CreateOrganizationContactsMutationResponse = {
  __typename?: 'CreateOrganizationContactsMutationResponse';
  organizationContacts: Array<OrganizationContact>;
};

export type CreateOrganizationsMutationResponse = {
  __typename?: 'CreateOrganizationsMutationResponse';
  organizations: Array<Organization>;
};

export type CreateParameterDefinitionsMutationResponse = {
  __typename?: 'CreateParameterDefinitionsMutationResponse';
  parameterDefinitions: Array<ParameterDefinition>;
};

export type CreatePatientCommunicationsMutationResponse = {
  __typename?: 'CreatePatientCommunicationsMutationResponse';
  patientCommunications: Array<PatientCommunication>;
};

export type CreatePatientContactsMutationResponse = {
  __typename?: 'CreatePatientContactsMutationResponse';
  patientContacts: Array<PatientContact>;
};

export type CreatePatientLinksMutationResponse = {
  __typename?: 'CreatePatientLinksMutationResponse';
  patientLinks: Array<PatientLink>;
};

export type CreatePatientsMutationResponse = {
  __typename?: 'CreatePatientsMutationResponse';
  patients: Array<Patient>;
};

export type CreatePeopleMutationResponse = {
  __typename?: 'CreatePeopleMutationResponse';
  people: Array<Person>;
};

export type CreatePeriodsMutationResponse = {
  __typename?: 'CreatePeriodsMutationResponse';
  periods: Array<Period>;
};

export type CreatePersonLinksMutationResponse = {
  __typename?: 'CreatePersonLinksMutationResponse';
  personLinks: Array<PersonLink>;
};

export type CreatePractitionerQualificationsMutationResponse = {
  __typename?: 'CreatePractitionerQualificationsMutationResponse';
  practitionerQualifications: Array<PractitionerQualification>;
};

export type CreatePractitionerRoleAvailableTimesMutationResponse = {
  __typename?: 'CreatePractitionerRoleAvailableTimesMutationResponse';
  practitionerRoleAvailableTimes: Array<PractitionerRoleAvailableTime>;
};

export type CreatePractitionerRoleNotAvailablesMutationResponse = {
  __typename?: 'CreatePractitionerRoleNotAvailablesMutationResponse';
  practitionerRoleNotAvailables: Array<PractitionerRoleNotAvailable>;
};

export type CreatePractitionerRolesMutationResponse = {
  __typename?: 'CreatePractitionerRolesMutationResponse';
  practitionerRoles: Array<PractitionerRole>;
};

export type CreatePractitionersMutationResponse = {
  __typename?: 'CreatePractitionersMutationResponse';
  practitioners: Array<Practitioner>;
};

export type CreateProdCharacteristicsMutationResponse = {
  __typename?: 'CreateProdCharacteristicsMutationResponse';
  prodCharacteristics: Array<ProdCharacteristic>;
};

export type CreateProductShelfLivesMutationResponse = {
  __typename?: 'CreateProductShelfLivesMutationResponse';
  productShelfLives: Array<ProductShelfLife>;
};

export type CreateQuantitiesMutationResponse = {
  __typename?: 'CreateQuantitiesMutationResponse';
  quantities: Array<Quantity>;
};

export type CreateRangesMutationResponse = {
  __typename?: 'CreateRangesMutationResponse';
  ranges: Array<Range>;
};

export type CreateRatiosMutationResponse = {
  __typename?: 'CreateRatiosMutationResponse';
  ratios: Array<Ratio>;
};

export type CreateReferencesMutationResponse = {
  __typename?: 'CreateReferencesMutationResponse';
  references: Array<Reference>;
};

export type CreateRelatedArtifactsMutationResponse = {
  __typename?: 'CreateRelatedArtifactsMutationResponse';
  relatedArtifacts: Array<RelatedArtifact>;
};

export type CreateRelatedPeopleMutationResponse = {
  __typename?: 'CreateRelatedPeopleMutationResponse';
  relatedPeople: Array<RelatedPerson>;
};

export type CreateRelatedPersonCommunicationsMutationResponse = {
  __typename?: 'CreateRelatedPersonCommunicationsMutationResponse';
  relatedPersonCommunications: Array<RelatedPersonCommunication>;
};

export type CreateSampledDataMutationResponse = {
  __typename?: 'CreateSampledDataMutationResponse';
  sampledData: Array<SampledData>;
};

export type CreateSchedulesMutationResponse = {
  __typename?: 'CreateSchedulesMutationResponse';
  schedules: Array<Schedule>;
};

export type CreateSignaturesMutationResponse = {
  __typename?: 'CreateSignaturesMutationResponse';
  signatures: Array<Signature>;
};

export type CreateSlotsMutationResponse = {
  __typename?: 'CreateSlotsMutationResponse';
  slots: Array<Slot>;
};

export type CreateSubstanceIngredientsMutationResponse = {
  __typename?: 'CreateSubstanceIngredientsMutationResponse';
  substanceIngredients: Array<SubstanceIngredient>;
};

export type CreateSubstanceInstancesMutationResponse = {
  __typename?: 'CreateSubstanceInstancesMutationResponse';
  substanceInstances: Array<SubstanceInstance>;
};

export type CreateSubstancesMutationResponse = {
  __typename?: 'CreateSubstancesMutationResponse';
  substances: Array<Substance>;
};

export type CreateTimingRepeatsMutationResponse = {
  __typename?: 'CreateTimingRepeatsMutationResponse';
  timingRepeats: Array<TimingRepeat>;
};

export type CreateTimingsMutationResponse = {
  __typename?: 'CreateTimingsMutationResponse';
  timings: Array<Timing>;
};

export type CreateTriggerDefinitionsMutationResponse = {
  __typename?: 'CreateTriggerDefinitionsMutationResponse';
  triggerDefinitions: Array<TriggerDefinition>;
};

export type CreateUsageContextsMutationResponse = {
  __typename?: 'CreateUsageContextsMutationResponse';
  usageContexts: Array<UsageContext>;
};

export type CreateUsersMutationResponse = {
  __typename?: 'CreateUsersMutationResponse';
  users: Array<User>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirement = {
  __typename?: 'DataRequirement';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The type of the required data, specified as the type name of a resource. For profiles, this value is set to the type of the base resource of the profile. */
  type?: Maybe<Scalars['Code']>;
  /** The profile of the required data, specified as the uri of the profile definition. */
  profile: Array<Scalars['Canonical']>;
  /**
   * Indicates that specific elements of the type are referenced by the knowledge module and must be supported by the consumer in order to obtain an effective evaluation. This does not mean that a value is required for this element, only that the consuming system must understand the element and be able to provide values for it if they are available.
   *
   * The value of mustSupport SHALL be a FHIRPath resolveable on the type of the DataRequirement. The path SHALL consist only of identifiers, constant indexers, and .resolve() (see the [Simple FHIRPath Profile](fhirpath.html#simple) for full details).
   */
  mustSupport: Array<Scalars['String']>;
  /** Specifies a maximum number of results that are required (uses the _count search parameter). */
  limit?: Maybe<Scalars['PositiveInt']>;
  extension: Array<Extension>;
  _type?: Maybe<Element>;
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  subjectReference?: Maybe<Reference>;
  _mustSupport: Array<Element>;
  codeFilter: Array<DataRequirementCodeFilter>;
  dateFilter: Array<DataRequirementDateFilter>;
  _limit?: Maybe<Element>;
  sort: Array<DataRequirementCustomSort>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirement_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementSubjectCodeableConceptArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementSubjectReferenceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirement_MustSupportArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCodeFilterArgs = {
  where?: Maybe<DataRequirementCodeFilterWhere>;
  options?: Maybe<DataRequirementCodeFilterOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementDateFilterArgs = {
  where?: Maybe<DataRequirementDateFilterWhere>;
  options?: Maybe<DataRequirementDateFilterOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirement_LimitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementSortArgs = {
  where?: Maybe<DataRequirementCustomSortWhere>;
  options?: Maybe<DataRequirementCustomSortOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCodeFilter = {
  __typename?: 'DataRequirementCodeFilter';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The code-valued attribute of the filter. The specified path SHALL be a FHIRPath resolveable on the specified type of the DataRequirement, and SHALL consist only of identifiers, constant indexers, and .resolve(). The path is allowed to contain qualifiers (.) to traverse sub-elements, as well as indexers ([x]) to traverse multiple-cardinality sub-elements (see the [Simple FHIRPath Profile](fhirpath.html#simple) for full details). Note that the index must be an integer constant. The path must resolve to an element of type code, Coding, or CodeableConcept. */
  path?: Maybe<Scalars['String']>;
  /** A token parameter that refers to a search parameter defined on the specified type of the DataRequirement, and which searches on elements of type code, Coding, or CodeableConcept. */
  searchParam?: Maybe<Scalars['String']>;
  /** The valueset for the code filter. The valueSet and code elements are additive. If valueSet is specified, the filter will return only those data items for which the value of the code-valued element specified in the path is a member of the specified valueset. */
  valueSet?: Maybe<Scalars['Canonical']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _path?: Maybe<Element>;
  _searchParam?: Maybe<Element>;
  code: Array<Coding>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCodeFilterExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCodeFilterModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCodeFilter_PathArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCodeFilter_SearchParamArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCodeFilterCodeArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

export type DataRequirementCodeFilterCodeDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type DataRequirementCodeFilterCodeFieldInput = {
  create?: Maybe<Array<CodingCreateInput>>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
};

export type DataRequirementCodeFilterCodeUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
  disconnect?: Maybe<Array<CodingDisconnectFieldInput>>;
  create?: Maybe<Array<CodingCreateInput>>;
  delete?: Maybe<Array<CodingDeleteFieldInput>>;
};

export type DataRequirementCodeFilterConnectFieldInput = {
  where?: Maybe<DataRequirementCodeFilterWhere>;
  connect?: Maybe<DataRequirementCodeFilterConnectInput>;
};

export type DataRequirementCodeFilterConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _path?: Maybe<ElementConnectFieldInput>;
  _searchParam?: Maybe<ElementConnectFieldInput>;
  code?: Maybe<Array<CodingConnectFieldInput>>;
};

export type DataRequirementCodeFilterCreateInput = {
  path?: Maybe<Scalars['String']>;
  searchParam?: Maybe<Scalars['String']>;
  valueSet?: Maybe<Scalars['Canonical']>;
  extension?: Maybe<DataRequirementCodeFilterExtensionFieldInput>;
  modifierExtension?: Maybe<DataRequirementCodeFilterModifierextensionFieldInput>;
  _path?: Maybe<DataRequirementCodeFilter_PathFieldInput>;
  _searchParam?: Maybe<DataRequirementCodeFilter_SearchparamFieldInput>;
  code?: Maybe<DataRequirementCodeFilterCodeFieldInput>;
};

export type DataRequirementCodeFilterDeleteFieldInput = {
  where?: Maybe<DataRequirementCodeFilterWhere>;
  delete?: Maybe<DataRequirementCodeFilterDeleteInput>;
};

export type DataRequirementCodeFilterDeleteInput = {
  extension?: Maybe<Array<DataRequirementCodeFilterExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DataRequirementCodeFilterModifierextensionDeleteFieldInput>>;
  _path?: Maybe<DataRequirementCodeFilter_PathDeleteFieldInput>;
  _searchParam?: Maybe<DataRequirementCodeFilter_SearchparamDeleteFieldInput>;
  code?: Maybe<Array<DataRequirementCodeFilterCodeDeleteFieldInput>>;
};

export type DataRequirementCodeFilterDisconnectFieldInput = {
  where?: Maybe<DataRequirementCodeFilterWhere>;
  disconnect?: Maybe<DataRequirementCodeFilterDisconnectInput>;
};

export type DataRequirementCodeFilterDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _path?: Maybe<ElementDisconnectFieldInput>;
  _searchParam?: Maybe<ElementDisconnectFieldInput>;
  code?: Maybe<Array<CodingDisconnectFieldInput>>;
};

export type DataRequirementCodeFilterExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DataRequirementCodeFilterExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DataRequirementCodeFilterExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DataRequirementCodeFilterModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DataRequirementCodeFilterModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DataRequirementCodeFilterModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DataRequirementCodeFilterOptions = {
  /** Specify one or more DataRequirementCodeFilterSort objects to sort DataRequirementCodeFilters by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DataRequirementCodeFilterSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DataRequirementCodeFilterRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _path?: Maybe<ElementCreateInput>;
  _searchParam?: Maybe<ElementCreateInput>;
  code?: Maybe<Array<CodingCreateInput>>;
};

/** Fields to sort DataRequirementCodeFilters by. The order in which sorts are applied is not guaranteed when specifying many fields in one DataRequirementCodeFilterSort object. */
export type DataRequirementCodeFilterSort = {
  id?: Maybe<SortDirection>;
  path?: Maybe<SortDirection>;
  searchParam?: Maybe<SortDirection>;
  valueSet?: Maybe<SortDirection>;
};

export type DataRequirementCodeFilterUpdateInput = {
  path?: Maybe<Scalars['String']>;
  searchParam?: Maybe<Scalars['String']>;
  valueSet?: Maybe<Scalars['Canonical']>;
  extension?: Maybe<Array<DataRequirementCodeFilterExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DataRequirementCodeFilterModifierextensionUpdateFieldInput>>;
  _path?: Maybe<DataRequirementCodeFilter_PathUpdateFieldInput>;
  _searchParam?: Maybe<DataRequirementCodeFilter_SearchparamUpdateFieldInput>;
  code?: Maybe<Array<DataRequirementCodeFilterCodeUpdateFieldInput>>;
};

export type DataRequirementCodeFilterWhere = {
  OR?: Maybe<Array<DataRequirementCodeFilterWhere>>;
  AND?: Maybe<Array<DataRequirementCodeFilterWhere>>;
  path?: Maybe<Scalars['String']>;
  searchParam?: Maybe<Scalars['String']>;
  valueSet?: Maybe<Scalars['Canonical']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _path?: Maybe<ElementWhere>;
  _path_NOT?: Maybe<ElementWhere>;
  _path_IN?: Maybe<Array<ElementWhere>>;
  _path_NOT_IN?: Maybe<Array<ElementWhere>>;
  _searchParam?: Maybe<ElementWhere>;
  _searchParam_NOT?: Maybe<ElementWhere>;
  _searchParam_IN?: Maybe<Array<ElementWhere>>;
  _searchParam_NOT_IN?: Maybe<Array<ElementWhere>>;
  code?: Maybe<CodingWhere>;
  code_NOT?: Maybe<CodingWhere>;
};

export type DataRequirementCodeFilter_PathDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirementCodeFilter_PathFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirementCodeFilter_PathUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DataRequirementCodeFilter_SearchparamDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirementCodeFilter_SearchparamFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirementCodeFilter_SearchparamUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DataRequirementCodefilterDeleteFieldInput = {
  where?: Maybe<DataRequirementCodeFilterWhere>;
  delete?: Maybe<DataRequirementCodeFilterDeleteInput>;
};

export type DataRequirementCodefilterFieldInput = {
  create?: Maybe<Array<DataRequirementCodeFilterCreateInput>>;
  connect?: Maybe<Array<DataRequirementCodeFilterConnectFieldInput>>;
};

export type DataRequirementCodefilterUpdateFieldInput = {
  where?: Maybe<DataRequirementCodeFilterWhere>;
  update?: Maybe<DataRequirementCodeFilterUpdateInput>;
  connect?: Maybe<Array<DataRequirementCodeFilterConnectFieldInput>>;
  disconnect?: Maybe<Array<DataRequirementCodeFilterDisconnectFieldInput>>;
  create?: Maybe<Array<DataRequirementCodeFilterCreateInput>>;
  delete?: Maybe<Array<DataRequirementCodeFilterDeleteFieldInput>>;
};

export type DataRequirementConnectFieldInput = {
  where?: Maybe<DataRequirementWhere>;
  connect?: Maybe<DataRequirementConnectInput>;
};

export type DataRequirementConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _type?: Maybe<ElementConnectFieldInput>;
  subjectCodeableConcept?: Maybe<CodeableConceptConnectFieldInput>;
  subjectReference?: Maybe<ReferenceConnectFieldInput>;
  _mustSupport?: Maybe<Array<ElementConnectFieldInput>>;
  codeFilter?: Maybe<Array<DataRequirementCodeFilterConnectFieldInput>>;
  dateFilter?: Maybe<Array<DataRequirementDateFilterConnectFieldInput>>;
  _limit?: Maybe<ElementConnectFieldInput>;
  sort?: Maybe<Array<DataRequirementCustomSortConnectFieldInput>>;
};

export type DataRequirementCreateInput = {
  type?: Maybe<Scalars['Code']>;
  profile: Array<Scalars['Canonical']>;
  mustSupport: Array<Scalars['String']>;
  limit?: Maybe<Scalars['PositiveInt']>;
  extension?: Maybe<DataRequirementExtensionFieldInput>;
  _type?: Maybe<DataRequirement_TypeFieldInput>;
  subjectCodeableConcept?: Maybe<DataRequirementSubjectcodeableconceptFieldInput>;
  subjectReference?: Maybe<DataRequirementSubjectreferenceFieldInput>;
  _mustSupport?: Maybe<DataRequirement_MustsupportFieldInput>;
  codeFilter?: Maybe<DataRequirementCodefilterFieldInput>;
  dateFilter?: Maybe<DataRequirementDatefilterFieldInput>;
  _limit?: Maybe<DataRequirement_LimitFieldInput>;
  sort?: Maybe<DataRequirementSortFieldInput>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCustomSort = {
  __typename?: 'DataRequirementCustomSort';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The direction of the sort, ascending or descending. */
  direction?: Maybe<DataRequirementSortDirection>;
  /** The attribute of the sort. The specified path must be resolvable from the type of the required data. The path is allowed to contain qualifiers (.) to traverse sub-elements, as well as indexers ([x]) to traverse multiple-cardinality sub-elements. Note that the index must be an integer constant. */
  path?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _path?: Maybe<Element>;
  _direction?: Maybe<Element>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCustomSortExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCustomSortModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCustomSort_PathArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementCustomSort_DirectionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DataRequirementCustomSortConnectFieldInput = {
  where?: Maybe<DataRequirementCustomSortWhere>;
  connect?: Maybe<DataRequirementCustomSortConnectInput>;
};

export type DataRequirementCustomSortConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _path?: Maybe<ElementConnectFieldInput>;
  _direction?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirementCustomSortCreateInput = {
  path?: Maybe<Scalars['String']>;
  direction?: Maybe<DataRequirementSortDirection>;
  extension?: Maybe<DataRequirementCustomSortExtensionFieldInput>;
  modifierExtension?: Maybe<DataRequirementCustomSortModifierextensionFieldInput>;
  _path?: Maybe<DataRequirementCustomSort_PathFieldInput>;
  _direction?: Maybe<DataRequirementCustomSort_DirectionFieldInput>;
};

export type DataRequirementCustomSortDeleteFieldInput = {
  where?: Maybe<DataRequirementCustomSortWhere>;
  delete?: Maybe<DataRequirementCustomSortDeleteInput>;
};

export type DataRequirementCustomSortDeleteInput = {
  extension?: Maybe<Array<DataRequirementCustomSortExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DataRequirementCustomSortModifierextensionDeleteFieldInput>>;
  _path?: Maybe<DataRequirementCustomSort_PathDeleteFieldInput>;
  _direction?: Maybe<DataRequirementCustomSort_DirectionDeleteFieldInput>;
};

export type DataRequirementCustomSortDisconnectFieldInput = {
  where?: Maybe<DataRequirementCustomSortWhere>;
  disconnect?: Maybe<DataRequirementCustomSortDisconnectInput>;
};

export type DataRequirementCustomSortDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _path?: Maybe<ElementDisconnectFieldInput>;
  _direction?: Maybe<ElementDisconnectFieldInput>;
};

export type DataRequirementCustomSortExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DataRequirementCustomSortExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DataRequirementCustomSortExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DataRequirementCustomSortModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DataRequirementCustomSortModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DataRequirementCustomSortModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DataRequirementCustomSortOptions = {
  /** Specify one or more DataRequirementCustomSortSort objects to sort DataRequirementCustomSorts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DataRequirementCustomSortSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DataRequirementCustomSortRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _path?: Maybe<ElementCreateInput>;
  _direction?: Maybe<ElementCreateInput>;
};

/** Fields to sort DataRequirementCustomSorts by. The order in which sorts are applied is not guaranteed when specifying many fields in one DataRequirementCustomSortSort object. */
export type DataRequirementCustomSortSort = {
  id?: Maybe<SortDirection>;
  direction?: Maybe<SortDirection>;
  path?: Maybe<SortDirection>;
};

export type DataRequirementCustomSortUpdateInput = {
  path?: Maybe<Scalars['String']>;
  direction?: Maybe<DataRequirementSortDirection>;
  extension?: Maybe<Array<DataRequirementCustomSortExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DataRequirementCustomSortModifierextensionUpdateFieldInput>>;
  _path?: Maybe<DataRequirementCustomSort_PathUpdateFieldInput>;
  _direction?: Maybe<DataRequirementCustomSort_DirectionUpdateFieldInput>;
};

export type DataRequirementCustomSortWhere = {
  OR?: Maybe<Array<DataRequirementCustomSortWhere>>;
  AND?: Maybe<Array<DataRequirementCustomSortWhere>>;
  path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  direction?: Maybe<DataRequirementSortDirection>;
  direction_NOT?: Maybe<DataRequirementSortDirection>;
  direction_IN?: Maybe<Array<Maybe<DataRequirementSortDirection>>>;
  direction_NOT_IN?: Maybe<Array<Maybe<DataRequirementSortDirection>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _path?: Maybe<ElementWhere>;
  _path_NOT?: Maybe<ElementWhere>;
  _path_IN?: Maybe<Array<ElementWhere>>;
  _path_NOT_IN?: Maybe<Array<ElementWhere>>;
  _direction?: Maybe<ElementWhere>;
  _direction_NOT?: Maybe<ElementWhere>;
  _direction_IN?: Maybe<Array<ElementWhere>>;
  _direction_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DataRequirementCustomSort_DirectionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirementCustomSort_DirectionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirementCustomSort_DirectionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DataRequirementCustomSort_PathDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirementCustomSort_PathFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirementCustomSort_PathUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementDateFilter = {
  __typename?: 'DataRequirementDateFilter';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The date-valued attribute of the filter. The specified path SHALL be a FHIRPath resolveable on the specified type of the DataRequirement, and SHALL consist only of identifiers, constant indexers, and .resolve(). The path is allowed to contain qualifiers (.) to traverse sub-elements, as well as indexers ([x]) to traverse multiple-cardinality sub-elements (see the [Simple FHIRPath Profile](fhirpath.html#simple) for full details). Note that the index must be an integer constant. The path must resolve to an element of type date, dateTime, Period, Schedule, or Timing. */
  path?: Maybe<Scalars['String']>;
  /** A date parameter that refers to a search parameter defined on the specified type of the DataRequirement, and which searches on elements of type date, dateTime, Period, Schedule, or Timing. */
  searchParam?: Maybe<Scalars['String']>;
  /** The value of the filter. If period is specified, the filter will return only those data items that fall within the bounds determined by the Period, inclusive of the period boundaries. If dateTime is specified, the filter will return only those data items that are equal to the specified dateTime. If a Duration is specified, the filter will return only those data items that fall within Duration before now. */
  valueDateTime?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _path?: Maybe<Element>;
  _searchParam?: Maybe<Element>;
  _valueDateTime?: Maybe<Element>;
  valuePeriod?: Maybe<Period>;
  valueDuration?: Maybe<Duration>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementDateFilterExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementDateFilterModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementDateFilter_PathArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementDateFilter_SearchParamArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementDateFilter_ValueDateTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementDateFilterValuePeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** Describes a required data item for evaluation in terms of the type of data, and optional code or date-based filters of the data. */
export type DataRequirementDateFilterValueDurationArgs = {
  where?: Maybe<DurationWhere>;
  options?: Maybe<DurationOptions>;
};

export type DataRequirementDateFilterConnectFieldInput = {
  where?: Maybe<DataRequirementDateFilterWhere>;
  connect?: Maybe<DataRequirementDateFilterConnectInput>;
};

export type DataRequirementDateFilterConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _path?: Maybe<ElementConnectFieldInput>;
  _searchParam?: Maybe<ElementConnectFieldInput>;
  _valueDateTime?: Maybe<ElementConnectFieldInput>;
  valuePeriod?: Maybe<PeriodConnectFieldInput>;
  valueDuration?: Maybe<DurationConnectFieldInput>;
};

export type DataRequirementDateFilterCreateInput = {
  path?: Maybe<Scalars['String']>;
  searchParam?: Maybe<Scalars['String']>;
  valueDateTime?: Maybe<Scalars['String']>;
  extension?: Maybe<DataRequirementDateFilterExtensionFieldInput>;
  modifierExtension?: Maybe<DataRequirementDateFilterModifierextensionFieldInput>;
  _path?: Maybe<DataRequirementDateFilter_PathFieldInput>;
  _searchParam?: Maybe<DataRequirementDateFilter_SearchparamFieldInput>;
  _valueDateTime?: Maybe<DataRequirementDateFilter_ValuedatetimeFieldInput>;
  valuePeriod?: Maybe<DataRequirementDateFilterValueperiodFieldInput>;
  valueDuration?: Maybe<DataRequirementDateFilterValuedurationFieldInput>;
};

export type DataRequirementDateFilterDeleteFieldInput = {
  where?: Maybe<DataRequirementDateFilterWhere>;
  delete?: Maybe<DataRequirementDateFilterDeleteInput>;
};

export type DataRequirementDateFilterDeleteInput = {
  extension?: Maybe<Array<DataRequirementDateFilterExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DataRequirementDateFilterModifierextensionDeleteFieldInput>>;
  _path?: Maybe<DataRequirementDateFilter_PathDeleteFieldInput>;
  _searchParam?: Maybe<DataRequirementDateFilter_SearchparamDeleteFieldInput>;
  _valueDateTime?: Maybe<DataRequirementDateFilter_ValuedatetimeDeleteFieldInput>;
  valuePeriod?: Maybe<DataRequirementDateFilterValueperiodDeleteFieldInput>;
  valueDuration?: Maybe<DataRequirementDateFilterValuedurationDeleteFieldInput>;
};

export type DataRequirementDateFilterDisconnectFieldInput = {
  where?: Maybe<DataRequirementDateFilterWhere>;
  disconnect?: Maybe<DataRequirementDateFilterDisconnectInput>;
};

export type DataRequirementDateFilterDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _path?: Maybe<ElementDisconnectFieldInput>;
  _searchParam?: Maybe<ElementDisconnectFieldInput>;
  _valueDateTime?: Maybe<ElementDisconnectFieldInput>;
  valuePeriod?: Maybe<PeriodDisconnectFieldInput>;
  valueDuration?: Maybe<DurationDisconnectFieldInput>;
};

export type DataRequirementDateFilterExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DataRequirementDateFilterExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DataRequirementDateFilterExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DataRequirementDateFilterModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DataRequirementDateFilterModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DataRequirementDateFilterModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DataRequirementDateFilterOptions = {
  /** Specify one or more DataRequirementDateFilterSort objects to sort DataRequirementDateFilters by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DataRequirementDateFilterSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DataRequirementDateFilterRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _path?: Maybe<ElementCreateInput>;
  _searchParam?: Maybe<ElementCreateInput>;
  _valueDateTime?: Maybe<ElementCreateInput>;
  valuePeriod?: Maybe<PeriodCreateInput>;
  valueDuration?: Maybe<DurationCreateInput>;
};

/** Fields to sort DataRequirementDateFilters by. The order in which sorts are applied is not guaranteed when specifying many fields in one DataRequirementDateFilterSort object. */
export type DataRequirementDateFilterSort = {
  id?: Maybe<SortDirection>;
  path?: Maybe<SortDirection>;
  searchParam?: Maybe<SortDirection>;
  valueDateTime?: Maybe<SortDirection>;
};

export type DataRequirementDateFilterUpdateInput = {
  path?: Maybe<Scalars['String']>;
  searchParam?: Maybe<Scalars['String']>;
  valueDateTime?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<DataRequirementDateFilterExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DataRequirementDateFilterModifierextensionUpdateFieldInput>>;
  _path?: Maybe<DataRequirementDateFilter_PathUpdateFieldInput>;
  _searchParam?: Maybe<DataRequirementDateFilter_SearchparamUpdateFieldInput>;
  _valueDateTime?: Maybe<DataRequirementDateFilter_ValuedatetimeUpdateFieldInput>;
  valuePeriod?: Maybe<DataRequirementDateFilterValueperiodUpdateFieldInput>;
  valueDuration?: Maybe<DataRequirementDateFilterValuedurationUpdateFieldInput>;
};

export type DataRequirementDateFilterValuedurationDeleteFieldInput = {
  where?: Maybe<DurationWhere>;
  delete?: Maybe<DurationDeleteInput>;
};

export type DataRequirementDateFilterValuedurationFieldInput = {
  create?: Maybe<DurationCreateInput>;
  connect?: Maybe<DurationConnectFieldInput>;
};

export type DataRequirementDateFilterValuedurationUpdateFieldInput = {
  where?: Maybe<DurationWhere>;
  update?: Maybe<DurationUpdateInput>;
  connect?: Maybe<DurationConnectFieldInput>;
  disconnect?: Maybe<DurationDisconnectFieldInput>;
  create?: Maybe<DurationCreateInput>;
  delete?: Maybe<DurationDeleteFieldInput>;
};

export type DataRequirementDateFilterValueperiodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type DataRequirementDateFilterValueperiodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type DataRequirementDateFilterValueperiodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type DataRequirementDateFilterWhere = {
  OR?: Maybe<Array<DataRequirementDateFilterWhere>>;
  AND?: Maybe<Array<DataRequirementDateFilterWhere>>;
  path?: Maybe<Scalars['String']>;
  searchParam?: Maybe<Scalars['String']>;
  valueDateTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _path?: Maybe<ElementWhere>;
  _path_NOT?: Maybe<ElementWhere>;
  _path_IN?: Maybe<Array<ElementWhere>>;
  _path_NOT_IN?: Maybe<Array<ElementWhere>>;
  _searchParam?: Maybe<ElementWhere>;
  _searchParam_NOT?: Maybe<ElementWhere>;
  _searchParam_IN?: Maybe<Array<ElementWhere>>;
  _searchParam_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueDateTime?: Maybe<ElementWhere>;
  _valueDateTime_NOT?: Maybe<ElementWhere>;
  _valueDateTime_IN?: Maybe<Array<ElementWhere>>;
  _valueDateTime_NOT_IN?: Maybe<Array<ElementWhere>>;
  valuePeriod?: Maybe<PeriodWhere>;
  valuePeriod_NOT?: Maybe<PeriodWhere>;
  valuePeriod_IN?: Maybe<Array<PeriodWhere>>;
  valuePeriod_NOT_IN?: Maybe<Array<PeriodWhere>>;
  valueDuration?: Maybe<DurationWhere>;
  valueDuration_NOT?: Maybe<DurationWhere>;
  valueDuration_IN?: Maybe<Array<DurationWhere>>;
  valueDuration_NOT_IN?: Maybe<Array<DurationWhere>>;
};

export type DataRequirementDateFilter_PathDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirementDateFilter_PathFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirementDateFilter_PathUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DataRequirementDateFilter_SearchparamDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirementDateFilter_SearchparamFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirementDateFilter_SearchparamUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DataRequirementDateFilter_ValuedatetimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirementDateFilter_ValuedatetimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirementDateFilter_ValuedatetimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DataRequirementDatefilterDeleteFieldInput = {
  where?: Maybe<DataRequirementDateFilterWhere>;
  delete?: Maybe<DataRequirementDateFilterDeleteInput>;
};

export type DataRequirementDatefilterFieldInput = {
  create?: Maybe<Array<DataRequirementDateFilterCreateInput>>;
  connect?: Maybe<Array<DataRequirementDateFilterConnectFieldInput>>;
};

export type DataRequirementDatefilterUpdateFieldInput = {
  where?: Maybe<DataRequirementDateFilterWhere>;
  update?: Maybe<DataRequirementDateFilterUpdateInput>;
  connect?: Maybe<Array<DataRequirementDateFilterConnectFieldInput>>;
  disconnect?: Maybe<Array<DataRequirementDateFilterDisconnectFieldInput>>;
  create?: Maybe<Array<DataRequirementDateFilterCreateInput>>;
  delete?: Maybe<Array<DataRequirementDateFilterDeleteFieldInput>>;
};

export type DataRequirementDeleteFieldInput = {
  where?: Maybe<DataRequirementWhere>;
  delete?: Maybe<DataRequirementDeleteInput>;
};

export type DataRequirementDeleteInput = {
  extension?: Maybe<Array<DataRequirementExtensionDeleteFieldInput>>;
  _type?: Maybe<DataRequirement_TypeDeleteFieldInput>;
  subjectCodeableConcept?: Maybe<DataRequirementSubjectcodeableconceptDeleteFieldInput>;
  subjectReference?: Maybe<DataRequirementSubjectreferenceDeleteFieldInput>;
  _mustSupport?: Maybe<Array<DataRequirement_MustsupportDeleteFieldInput>>;
  codeFilter?: Maybe<Array<DataRequirementCodefilterDeleteFieldInput>>;
  dateFilter?: Maybe<Array<DataRequirementDatefilterDeleteFieldInput>>;
  _limit?: Maybe<DataRequirement_LimitDeleteFieldInput>;
  sort?: Maybe<Array<DataRequirementSortDeleteFieldInput>>;
};

export type DataRequirementDisconnectFieldInput = {
  where?: Maybe<DataRequirementWhere>;
  disconnect?: Maybe<DataRequirementDisconnectInput>;
};

export type DataRequirementDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _type?: Maybe<ElementDisconnectFieldInput>;
  subjectCodeableConcept?: Maybe<CodeableConceptDisconnectFieldInput>;
  subjectReference?: Maybe<ReferenceDisconnectFieldInput>;
  _mustSupport?: Maybe<Array<ElementDisconnectFieldInput>>;
  codeFilter?: Maybe<Array<DataRequirementCodeFilterDisconnectFieldInput>>;
  dateFilter?: Maybe<Array<DataRequirementDateFilterDisconnectFieldInput>>;
  _limit?: Maybe<ElementDisconnectFieldInput>;
  sort?: Maybe<Array<DataRequirementCustomSortDisconnectFieldInput>>;
};

export type DataRequirementExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DataRequirementExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DataRequirementExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DataRequirementOptions = {
  /** Specify one or more DataRequirementSort objects to sort DataRequirements by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DataRequirementSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DataRequirementRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _type?: Maybe<ElementCreateInput>;
  subjectCodeableConcept?: Maybe<CodeableConceptCreateInput>;
  subjectReference?: Maybe<ReferenceCreateInput>;
  _mustSupport?: Maybe<Array<ElementCreateInput>>;
  codeFilter?: Maybe<Array<DataRequirementCodeFilterCreateInput>>;
  dateFilter?: Maybe<Array<DataRequirementDateFilterCreateInput>>;
  _limit?: Maybe<ElementCreateInput>;
  sort?: Maybe<Array<DataRequirementCustomSortCreateInput>>;
};

/** Fields to sort DataRequirements by. The order in which sorts are applied is not guaranteed when specifying many fields in one DataRequirementSort object. */
export type DataRequirementSort = {
  id?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  limit?: Maybe<SortDirection>;
};

export type DataRequirementSortDeleteFieldInput = {
  where?: Maybe<DataRequirementCustomSortWhere>;
  delete?: Maybe<DataRequirementCustomSortDeleteInput>;
};

/** The direction of the sort, ascending or descending. */
export enum DataRequirementSortDirection {
  Ascending = 'ascending',
  Descending = 'descending',
}

export type DataRequirementSortFieldInput = {
  create?: Maybe<Array<DataRequirementCustomSortCreateInput>>;
  connect?: Maybe<Array<DataRequirementCustomSortConnectFieldInput>>;
};

export type DataRequirementSortUpdateFieldInput = {
  where?: Maybe<DataRequirementCustomSortWhere>;
  update?: Maybe<DataRequirementCustomSortUpdateInput>;
  connect?: Maybe<Array<DataRequirementCustomSortConnectFieldInput>>;
  disconnect?: Maybe<Array<DataRequirementCustomSortDisconnectFieldInput>>;
  create?: Maybe<Array<DataRequirementCustomSortCreateInput>>;
  delete?: Maybe<Array<DataRequirementCustomSortDeleteFieldInput>>;
};

export type DataRequirementSubjectcodeableconceptDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DataRequirementSubjectcodeableconceptFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DataRequirementSubjectcodeableconceptUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DataRequirementSubjectreferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DataRequirementSubjectreferenceFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DataRequirementSubjectreferenceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type DataRequirementUpdateInput = {
  type?: Maybe<Scalars['Code']>;
  profile?: Maybe<Array<Scalars['Canonical']>>;
  mustSupport?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['PositiveInt']>;
  extension?: Maybe<Array<DataRequirementExtensionUpdateFieldInput>>;
  _type?: Maybe<DataRequirement_TypeUpdateFieldInput>;
  subjectCodeableConcept?: Maybe<DataRequirementSubjectcodeableconceptUpdateFieldInput>;
  subjectReference?: Maybe<DataRequirementSubjectreferenceUpdateFieldInput>;
  _mustSupport?: Maybe<Array<DataRequirement_MustsupportUpdateFieldInput>>;
  codeFilter?: Maybe<Array<DataRequirementCodefilterUpdateFieldInput>>;
  dateFilter?: Maybe<Array<DataRequirementDatefilterUpdateFieldInput>>;
  _limit?: Maybe<DataRequirement_LimitUpdateFieldInput>;
  sort?: Maybe<Array<DataRequirementSortUpdateFieldInput>>;
};

export type DataRequirementWhere = {
  OR?: Maybe<Array<DataRequirementWhere>>;
  AND?: Maybe<Array<DataRequirementWhere>>;
  type?: Maybe<Scalars['Code']>;
  profile?: Maybe<Array<Maybe<Scalars['Canonical']>>>;
  mustSupport?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['PositiveInt']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
  subjectCodeableConcept?: Maybe<CodeableConceptWhere>;
  subjectCodeableConcept_NOT?: Maybe<CodeableConceptWhere>;
  subjectCodeableConcept_IN?: Maybe<Array<CodeableConceptWhere>>;
  subjectCodeableConcept_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  subjectReference?: Maybe<ReferenceWhere>;
  subjectReference_NOT?: Maybe<ReferenceWhere>;
  subjectReference_IN?: Maybe<Array<ReferenceWhere>>;
  subjectReference_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _mustSupport?: Maybe<ElementWhere>;
  _mustSupport_NOT?: Maybe<ElementWhere>;
  codeFilter?: Maybe<DataRequirementCodeFilterWhere>;
  codeFilter_NOT?: Maybe<DataRequirementCodeFilterWhere>;
  dateFilter?: Maybe<DataRequirementDateFilterWhere>;
  dateFilter_NOT?: Maybe<DataRequirementDateFilterWhere>;
  _limit?: Maybe<ElementWhere>;
  _limit_NOT?: Maybe<ElementWhere>;
  _limit_IN?: Maybe<Array<ElementWhere>>;
  _limit_NOT_IN?: Maybe<Array<ElementWhere>>;
  sort?: Maybe<DataRequirementCustomSortWhere>;
  sort_NOT?: Maybe<DataRequirementCustomSortWhere>;
};

export type DataRequirement_LimitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirement_LimitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirement_LimitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DataRequirement_MustsupportDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirement_MustsupportFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type DataRequirement_MustsupportUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type DataRequirement_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DataRequirement_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DataRequirement_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeleteInfo = {
  __typename?: 'DeleteInfo';
  nodesDeleted: Scalars['Int'];
  relationshipsDeleted: Scalars['Int'];
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device = {
  __typename?: 'Device';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a Device resource */
  resourceType: DeviceResourceType;
  /** Status of the Device availability. */
  status?: Maybe<DeviceStatus>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** The distinct identification string as required by regulation for a human cell, tissue, or cellular and tissue-based product. */
  distinctIdentifier?: Maybe<Scalars['String']>;
  /** A name of the manufacturer. */
  manufacturer?: Maybe<Scalars['String']>;
  /** The date and time when the device was manufactured. */
  manufactureDate?: Maybe<Scalars['DateTime']>;
  /** The date and time beyond which this device is no longer valid or should not be used (if applicable). */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** Lot number assigned by the manufacturer. */
  lotNumber?: Maybe<Scalars['String']>;
  /** The serial number assigned by the organization when the device was manufactured. */
  serialNumber?: Maybe<Scalars['String']>;
  /** The model number for the device. */
  modelNumber?: Maybe<Scalars['String']>;
  /** The part number of the device. */
  partNumber?: Maybe<Scalars['String']>;
  /** A network address on which the device may be contacted directly. */
  url?: Maybe<Scalars['Uri']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  definition?: Maybe<Reference>;
  udiCarrier: Array<DeviceUdiCarrier>;
  _status?: Maybe<Element>;
  statusReason: Array<CodeableConcept>;
  _distinctIdentifier?: Maybe<Element>;
  _manufacturer?: Maybe<Element>;
  _manufactureDate?: Maybe<Element>;
  _expirationDate?: Maybe<Element>;
  _lotNumber?: Maybe<Element>;
  _serialNumber?: Maybe<Element>;
  deviceName: Array<DeviceDeviceName>;
  _modelNumber?: Maybe<Element>;
  _partNumber?: Maybe<Element>;
  type?: Maybe<CodeableConcept>;
  specialization: Array<DeviceSpecialization>;
  version: Array<DeviceVersion>;
  property: Array<DeviceProperty>;
  patient?: Maybe<Reference>;
  owner?: Maybe<Reference>;
  contact: Array<ContactPoint>;
  location?: Maybe<Reference>;
  _url?: Maybe<Element>;
  note: Array<Annotation>;
  safety: Array<CodeableConcept>;
  parent?: Maybe<Reference>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceDefinitionArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrierArgs = {
  where?: Maybe<DeviceUdiCarrierWhere>;
  options?: Maybe<DeviceUdiCarrierOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceStatusReasonArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_DistinctIdentifierArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_ManufacturerArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_ManufactureDateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_ExpirationDateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_LotNumberArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_SerialNumberArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceDeviceNameArgs = {
  where?: Maybe<DeviceDeviceNameWhere>;
  options?: Maybe<DeviceDeviceNameOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_ModelNumberArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_PartNumberArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceSpecializationArgs = {
  where?: Maybe<DeviceSpecializationWhere>;
  options?: Maybe<DeviceSpecializationOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceVersionArgs = {
  where?: Maybe<DeviceVersionWhere>;
  options?: Maybe<DeviceVersionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DevicePropertyArgs = {
  where?: Maybe<DevicePropertyWhere>;
  options?: Maybe<DevicePropertyOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DevicePatientArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceOwnerArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceContactArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceLocationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type Device_UrlArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceNoteArgs = {
  where?: Maybe<AnnotationWhere>;
  options?: Maybe<AnnotationOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceSafetyArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceParentArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type DeviceConnectFieldInput = {
  where?: Maybe<DeviceWhere>;
  connect?: Maybe<DeviceConnectInput>;
};

export type DeviceConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  definition?: Maybe<ReferenceConnectFieldInput>;
  udiCarrier?: Maybe<Array<DeviceUdiCarrierConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  statusReason?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  _distinctIdentifier?: Maybe<ElementConnectFieldInput>;
  _manufacturer?: Maybe<ElementConnectFieldInput>;
  _manufactureDate?: Maybe<ElementConnectFieldInput>;
  _expirationDate?: Maybe<ElementConnectFieldInput>;
  _lotNumber?: Maybe<ElementConnectFieldInput>;
  _serialNumber?: Maybe<ElementConnectFieldInput>;
  deviceName?: Maybe<Array<DeviceDeviceNameConnectFieldInput>>;
  _modelNumber?: Maybe<ElementConnectFieldInput>;
  _partNumber?: Maybe<ElementConnectFieldInput>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  specialization?: Maybe<Array<DeviceSpecializationConnectFieldInput>>;
  version?: Maybe<Array<DeviceVersionConnectFieldInput>>;
  property?: Maybe<Array<DevicePropertyConnectFieldInput>>;
  patient?: Maybe<ReferenceConnectFieldInput>;
  owner?: Maybe<ReferenceConnectFieldInput>;
  contact?: Maybe<Array<ContactPointConnectFieldInput>>;
  location?: Maybe<ReferenceConnectFieldInput>;
  _url?: Maybe<ElementConnectFieldInput>;
  note?: Maybe<Array<AnnotationConnectFieldInput>>;
  safety?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  parent?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceContactDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type DeviceContactFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type DeviceContactUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type DeviceContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type DeviceContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type DeviceContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type DeviceContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type DeviceContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type DeviceContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type DeviceContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type DeviceContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type DeviceContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type DeviceContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type DeviceContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type DeviceContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type DeviceContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type DeviceContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type DeviceContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type DeviceContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type DeviceContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type DeviceContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type DeviceContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type DeviceContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type DeviceContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type DeviceContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type DeviceContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type DeviceContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type DeviceContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type DeviceContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type DeviceContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type DeviceContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type DeviceContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type DeviceContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type DeviceContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type DeviceContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type DeviceContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type DeviceContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type DeviceContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type DeviceContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type DeviceContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type DeviceContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type DeviceContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type DeviceContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type DeviceContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type DeviceContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type DeviceContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type DeviceContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type DeviceContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type DeviceContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type DeviceContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type DeviceContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type DeviceContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type DeviceContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type DeviceContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type DeviceContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type DeviceContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type DeviceContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type DeviceContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type DeviceContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type DeviceContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type DeviceContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type DeviceContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type DeviceContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type DeviceContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type DeviceContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type DeviceContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type DeviceCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  distinctIdentifier?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufactureDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  lotNumber?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['Uri']>;
  resourceType: DeviceResourceType;
  status?: Maybe<DeviceStatus>;
  meta?: Maybe<DeviceMetaFieldInput>;
  _implicitRules?: Maybe<Device_ImplicitrulesFieldInput>;
  _language?: Maybe<Device_LanguageFieldInput>;
  text?: Maybe<DeviceTextFieldInput>;
  contained_Account?: Maybe<DeviceContainedAccountFieldInput>;
  contained_Appointment?: Maybe<DeviceContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<DeviceContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<DeviceContainedCoverageFieldInput>;
  contained_Device?: Maybe<DeviceContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<DeviceContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<DeviceContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<DeviceContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<DeviceContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<DeviceContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<DeviceContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<DeviceContainedLocationFieldInput>;
  contained_Organization?: Maybe<DeviceContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<DeviceContainedPatientFieldInput>;
  contained_Person?: Maybe<DeviceContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<DeviceContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<DeviceContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<DeviceContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<DeviceContainedScheduleFieldInput>;
  contained_Slot?: Maybe<DeviceContainedSlotFieldInput>;
  contained_Substance?: Maybe<DeviceContainedSubstanceFieldInput>;
  extension?: Maybe<DeviceExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceModifierextensionFieldInput>;
  identifier?: Maybe<DeviceIdentifierFieldInput>;
  definition?: Maybe<DeviceDefinitionFieldInput>;
  udiCarrier?: Maybe<DeviceUdicarrierFieldInput>;
  _status?: Maybe<Device_StatusFieldInput>;
  statusReason?: Maybe<DeviceStatusreasonFieldInput>;
  _distinctIdentifier?: Maybe<Device_DistinctidentifierFieldInput>;
  _manufacturer?: Maybe<Device_ManufacturerFieldInput>;
  _manufactureDate?: Maybe<Device_ManufacturedateFieldInput>;
  _expirationDate?: Maybe<Device_ExpirationdateFieldInput>;
  _lotNumber?: Maybe<Device_LotnumberFieldInput>;
  _serialNumber?: Maybe<Device_SerialnumberFieldInput>;
  deviceName?: Maybe<DeviceDevicenameFieldInput>;
  _modelNumber?: Maybe<Device_ModelnumberFieldInput>;
  _partNumber?: Maybe<Device_PartnumberFieldInput>;
  type?: Maybe<DeviceTypeFieldInput>;
  specialization?: Maybe<DeviceSpecializationFieldInput>;
  version?: Maybe<DeviceVersionFieldInput>;
  property?: Maybe<DevicePropertyFieldInput>;
  patient?: Maybe<DevicePatientFieldInput>;
  owner?: Maybe<DeviceOwnerFieldInput>;
  contact?: Maybe<DeviceContactFieldInput>;
  location?: Maybe<DeviceLocationFieldInput>;
  _url?: Maybe<Device_UrlFieldInput>;
  note?: Maybe<DeviceNoteFieldInput>;
  safety?: Maybe<DeviceSafetyFieldInput>;
  parent?: Maybe<DeviceParentFieldInput>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinition = {
  __typename?: 'DeviceDefinition';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a DeviceDefinition resource */
  resourceType: DeviceDefinitionResourceType;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** A name of the manufacturer. */
  manufacturerString?: Maybe<Scalars['String']>;
  /** The model number for the device. */
  modelNumber?: Maybe<Scalars['String']>;
  /** The available versions of the device, e.g., software versions. */
  version: Array<Scalars['String']>;
  /** A network address on which the device may be contacted directly. */
  url?: Maybe<Scalars['Uri']>;
  /** Access to on-line information about the device. */
  onlineInformation?: Maybe<Scalars['Uri']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  udiDeviceIdentifier: Array<DeviceDefinitionUdiDeviceIdentifier>;
  _manufacturerString?: Maybe<Element>;
  manufacturerReference?: Maybe<Reference>;
  deviceName: Array<DeviceDefinitionDeviceName>;
  _modelNumber?: Maybe<Element>;
  type?: Maybe<CodeableConcept>;
  specialization: Array<DeviceDefinitionSpecialization>;
  _version: Array<Element>;
  safety: Array<CodeableConcept>;
  shelfLifeStorage: Array<ProductShelfLife>;
  physicalCharacteristics?: Maybe<ProdCharacteristic>;
  languageCode: Array<CodeableConcept>;
  capability: Array<DeviceDefinitionCapability>;
  property: Array<DeviceDefinitionProperty>;
  owner?: Maybe<Reference>;
  contact: Array<ContactPoint>;
  _url?: Maybe<Element>;
  _onlineInformation?: Maybe<Element>;
  note: Array<Annotation>;
  quantity?: Maybe<Quantity>;
  parentDevice?: Maybe<Reference>;
  material: Array<DeviceDefinitionMaterial>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinition_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinition_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionUdiDeviceIdentifierArgs = {
  where?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  options?: Maybe<DeviceDefinitionUdiDeviceIdentifierOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinition_ManufacturerStringArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionManufacturerReferenceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionDeviceNameArgs = {
  where?: Maybe<DeviceDefinitionDeviceNameWhere>;
  options?: Maybe<DeviceDefinitionDeviceNameOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinition_ModelNumberArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionSpecializationArgs = {
  where?: Maybe<DeviceDefinitionSpecializationWhere>;
  options?: Maybe<DeviceDefinitionSpecializationOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinition_VersionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionSafetyArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionShelfLifeStorageArgs = {
  where?: Maybe<ProductShelfLifeWhere>;
  options?: Maybe<ProductShelfLifeOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionPhysicalCharacteristicsArgs = {
  where?: Maybe<ProdCharacteristicWhere>;
  options?: Maybe<ProdCharacteristicOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionLanguageCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionCapabilityArgs = {
  where?: Maybe<DeviceDefinitionCapabilityWhere>;
  options?: Maybe<DeviceDefinitionCapabilityOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionPropertyArgs = {
  where?: Maybe<DeviceDefinitionPropertyWhere>;
  options?: Maybe<DeviceDefinitionPropertyOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionOwnerArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionContactArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinition_UrlArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinition_OnlineInformationArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionNoteArgs = {
  where?: Maybe<AnnotationWhere>;
  options?: Maybe<AnnotationOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionQuantityArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionParentDeviceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionMaterialArgs = {
  where?: Maybe<DeviceDefinitionMaterialWhere>;
  options?: Maybe<DeviceDefinitionMaterialOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionCapability = {
  __typename?: 'DeviceDefinitionCapability';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type: CodeableConcept;
  description: Array<CodeableConcept>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionCapabilityExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionCapabilityModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionCapabilityTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionCapabilityDescriptionArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

export type DeviceDefinitionCapabilityConnectFieldInput = {
  where?: Maybe<DeviceDefinitionCapabilityWhere>;
  connect?: Maybe<DeviceDefinitionCapabilityConnectInput>;
};

export type DeviceDefinitionCapabilityConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  description?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DeviceDefinitionCapabilityCreateInput = {
  extension?: Maybe<DeviceDefinitionCapabilityExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceDefinitionCapabilityModifierextensionFieldInput>;
  type?: Maybe<DeviceDefinitionCapabilityTypeFieldInput>;
  description?: Maybe<DeviceDefinitionCapabilityDescriptionFieldInput>;
};

export type DeviceDefinitionCapabilityDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionCapabilityWhere>;
  delete?: Maybe<DeviceDefinitionCapabilityDeleteInput>;
};

export type DeviceDefinitionCapabilityDeleteInput = {
  extension?: Maybe<Array<DeviceDefinitionCapabilityExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionCapabilityModifierextensionDeleteFieldInput>>;
  type?: Maybe<DeviceDefinitionCapabilityTypeDeleteFieldInput>;
  description?: Maybe<Array<DeviceDefinitionCapabilityDescriptionDeleteFieldInput>>;
};

export type DeviceDefinitionCapabilityDescriptionDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceDefinitionCapabilityDescriptionFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DeviceDefinitionCapabilityDescriptionUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type DeviceDefinitionCapabilityDisconnectFieldInput = {
  where?: Maybe<DeviceDefinitionCapabilityWhere>;
  disconnect?: Maybe<DeviceDefinitionCapabilityDisconnectInput>;
};

export type DeviceDefinitionCapabilityDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  description?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
};

export type DeviceDefinitionCapabilityExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionCapabilityExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionCapabilityExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionCapabilityFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCapabilityCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionCapabilityConnectFieldInput>>;
};

export type DeviceDefinitionCapabilityModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionCapabilityModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionCapabilityModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionCapabilityOptions = {
  /** Specify one or more DeviceDefinitionCapabilitySort objects to sort DeviceDefinitionCapabilities by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceDefinitionCapabilitySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceDefinitionCapabilityRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<CodeableConceptCreateInput>;
  description?: Maybe<Array<CodeableConceptCreateInput>>;
};

/** Fields to sort DeviceDefinitionCapabilities by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceDefinitionCapabilitySort object. */
export type DeviceDefinitionCapabilitySort = {
  id?: Maybe<SortDirection>;
};

export type DeviceDefinitionCapabilityTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceDefinitionCapabilityTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DeviceDefinitionCapabilityTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DeviceDefinitionCapabilityUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionCapabilityWhere>;
  update?: Maybe<DeviceDefinitionCapabilityUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionCapabilityConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionCapabilityDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCapabilityCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionCapabilityDeleteFieldInput>>;
};

export type DeviceDefinitionCapabilityUpdateInput = {
  extension?: Maybe<Array<DeviceDefinitionCapabilityExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionCapabilityModifierextensionUpdateFieldInput>>;
  type?: Maybe<DeviceDefinitionCapabilityTypeUpdateFieldInput>;
  description?: Maybe<Array<DeviceDefinitionCapabilityDescriptionUpdateFieldInput>>;
};

export type DeviceDefinitionCapabilityWhere = {
  OR?: Maybe<Array<DeviceDefinitionCapabilityWhere>>;
  AND?: Maybe<Array<DeviceDefinitionCapabilityWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  description?: Maybe<CodeableConceptWhere>;
  description_NOT?: Maybe<CodeableConceptWhere>;
};

export type DeviceDefinitionConnectFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  connect?: Maybe<DeviceDefinitionConnectInput>;
};

export type DeviceDefinitionConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  udiDeviceIdentifier?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierConnectFieldInput>>;
  _manufacturerString?: Maybe<ElementConnectFieldInput>;
  manufacturerReference?: Maybe<ReferenceConnectFieldInput>;
  deviceName?: Maybe<Array<DeviceDefinitionDeviceNameConnectFieldInput>>;
  _modelNumber?: Maybe<ElementConnectFieldInput>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  specialization?: Maybe<Array<DeviceDefinitionSpecializationConnectFieldInput>>;
  _version?: Maybe<Array<ElementConnectFieldInput>>;
  safety?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  shelfLifeStorage?: Maybe<Array<ProductShelfLifeConnectFieldInput>>;
  physicalCharacteristics?: Maybe<ProdCharacteristicConnectFieldInput>;
  languageCode?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  capability?: Maybe<Array<DeviceDefinitionCapabilityConnectFieldInput>>;
  property?: Maybe<Array<DeviceDefinitionPropertyConnectFieldInput>>;
  owner?: Maybe<ReferenceConnectFieldInput>;
  contact?: Maybe<Array<ContactPointConnectFieldInput>>;
  _url?: Maybe<ElementConnectFieldInput>;
  _onlineInformation?: Maybe<ElementConnectFieldInput>;
  note?: Maybe<Array<AnnotationConnectFieldInput>>;
  quantity?: Maybe<QuantityConnectFieldInput>;
  parentDevice?: Maybe<ReferenceConnectFieldInput>;
  material?: Maybe<Array<DeviceDefinitionMaterialConnectFieldInput>>;
};

export type DeviceDefinitionContactDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type DeviceDefinitionContactFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type DeviceDefinitionContactUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type DeviceDefinitionContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type DeviceDefinitionContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type DeviceDefinitionContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type DeviceDefinitionContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type DeviceDefinitionContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type DeviceDefinitionContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type DeviceDefinitionContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type DeviceDefinitionContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type DeviceDefinitionContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type DeviceDefinitionContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type DeviceDefinitionContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type DeviceDefinitionContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type DeviceDefinitionContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type DeviceDefinitionContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type DeviceDefinitionContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type DeviceDefinitionContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type DeviceDefinitionContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type DeviceDefinitionContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type DeviceDefinitionContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type DeviceDefinitionContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type DeviceDefinitionContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type DeviceDefinitionContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type DeviceDefinitionContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type DeviceDefinitionContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type DeviceDefinitionContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type DeviceDefinitionContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type DeviceDefinitionContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type DeviceDefinitionContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type DeviceDefinitionContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type DeviceDefinitionContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type DeviceDefinitionContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type DeviceDefinitionContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type DeviceDefinitionContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type DeviceDefinitionContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type DeviceDefinitionContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type DeviceDefinitionContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type DeviceDefinitionContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type DeviceDefinitionContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type DeviceDefinitionContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type DeviceDefinitionContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type DeviceDefinitionContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type DeviceDefinitionContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type DeviceDefinitionContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type DeviceDefinitionContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type DeviceDefinitionContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type DeviceDefinitionContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type DeviceDefinitionContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type DeviceDefinitionContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type DeviceDefinitionContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type DeviceDefinitionContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type DeviceDefinitionContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type DeviceDefinitionContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type DeviceDefinitionContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type DeviceDefinitionContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type DeviceDefinitionContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type DeviceDefinitionContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type DeviceDefinitionContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type DeviceDefinitionContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type DeviceDefinitionContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type DeviceDefinitionContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type DeviceDefinitionContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type DeviceDefinitionContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type DeviceDefinitionContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type DeviceDefinitionCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  manufacturerString?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  version: Array<Scalars['String']>;
  url?: Maybe<Scalars['Uri']>;
  onlineInformation?: Maybe<Scalars['Uri']>;
  resourceType: DeviceDefinitionResourceType;
  meta?: Maybe<DeviceDefinitionMetaFieldInput>;
  _implicitRules?: Maybe<DeviceDefinition_ImplicitrulesFieldInput>;
  _language?: Maybe<DeviceDefinition_LanguageFieldInput>;
  text?: Maybe<DeviceDefinitionTextFieldInput>;
  contained_Account?: Maybe<DeviceDefinitionContainedAccountFieldInput>;
  contained_Appointment?: Maybe<DeviceDefinitionContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<DeviceDefinitionContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<DeviceDefinitionContainedCoverageFieldInput>;
  contained_Device?: Maybe<DeviceDefinitionContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<DeviceDefinitionContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<DeviceDefinitionContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<DeviceDefinitionContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<DeviceDefinitionContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<DeviceDefinitionContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<DeviceDefinitionContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<DeviceDefinitionContainedLocationFieldInput>;
  contained_Organization?: Maybe<DeviceDefinitionContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<DeviceDefinitionContainedPatientFieldInput>;
  contained_Person?: Maybe<DeviceDefinitionContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<DeviceDefinitionContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<DeviceDefinitionContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<DeviceDefinitionContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<DeviceDefinitionContainedScheduleFieldInput>;
  contained_Slot?: Maybe<DeviceDefinitionContainedSlotFieldInput>;
  contained_Substance?: Maybe<DeviceDefinitionContainedSubstanceFieldInput>;
  extension?: Maybe<DeviceDefinitionExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceDefinitionModifierextensionFieldInput>;
  identifier?: Maybe<DeviceDefinitionIdentifierFieldInput>;
  udiDeviceIdentifier?: Maybe<DeviceDefinitionUdideviceidentifierFieldInput>;
  _manufacturerString?: Maybe<DeviceDefinition_ManufacturerstringFieldInput>;
  manufacturerReference?: Maybe<DeviceDefinitionManufacturerreferenceFieldInput>;
  deviceName?: Maybe<DeviceDefinitionDevicenameFieldInput>;
  _modelNumber?: Maybe<DeviceDefinition_ModelnumberFieldInput>;
  type?: Maybe<DeviceDefinitionTypeFieldInput>;
  specialization?: Maybe<DeviceDefinitionSpecializationFieldInput>;
  _version?: Maybe<DeviceDefinition_VersionFieldInput>;
  safety?: Maybe<DeviceDefinitionSafetyFieldInput>;
  shelfLifeStorage?: Maybe<DeviceDefinitionShelflifestorageFieldInput>;
  physicalCharacteristics?: Maybe<DeviceDefinitionPhysicalcharacteristicsFieldInput>;
  languageCode?: Maybe<DeviceDefinitionLanguagecodeFieldInput>;
  capability?: Maybe<DeviceDefinitionCapabilityFieldInput>;
  property?: Maybe<DeviceDefinitionPropertyFieldInput>;
  owner?: Maybe<DeviceDefinitionOwnerFieldInput>;
  contact?: Maybe<DeviceDefinitionContactFieldInput>;
  _url?: Maybe<DeviceDefinition_UrlFieldInput>;
  _onlineInformation?: Maybe<DeviceDefinition_OnlineinformationFieldInput>;
  note?: Maybe<DeviceDefinitionNoteFieldInput>;
  quantity?: Maybe<DeviceDefinitionQuantityFieldInput>;
  parentDevice?: Maybe<DeviceDefinitionParentdeviceFieldInput>;
  material?: Maybe<DeviceDefinitionMaterialFieldInput>;
};

export type DeviceDefinitionDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DeviceDefinitionDeleteInput = {
  meta?: Maybe<DeviceDefinitionMetaDeleteFieldInput>;
  _implicitRules?: Maybe<DeviceDefinition_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<DeviceDefinition_LanguageDeleteFieldInput>;
  text?: Maybe<DeviceDefinitionTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<DeviceDefinitionContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<DeviceDefinitionContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<DeviceDefinitionContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<DeviceDefinitionContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDefinitionContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceDefinitionContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<DeviceDefinitionContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<DeviceDefinitionContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<DeviceDefinitionContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<DeviceDefinitionContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<DeviceDefinitionContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<DeviceDefinitionContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<DeviceDefinitionContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<DeviceDefinitionContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<DeviceDefinitionContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<DeviceDefinitionContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<DeviceDefinitionContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<DeviceDefinitionContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<DeviceDefinitionContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<DeviceDefinitionContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<DeviceDefinitionExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<DeviceDefinitionIdentifierDeleteFieldInput>>;
  udiDeviceIdentifier?: Maybe<Array<DeviceDefinitionUdideviceidentifierDeleteFieldInput>>;
  _manufacturerString?: Maybe<DeviceDefinition_ManufacturerstringDeleteFieldInput>;
  manufacturerReference?: Maybe<DeviceDefinitionManufacturerreferenceDeleteFieldInput>;
  deviceName?: Maybe<Array<DeviceDefinitionDevicenameDeleteFieldInput>>;
  _modelNumber?: Maybe<DeviceDefinition_ModelnumberDeleteFieldInput>;
  type?: Maybe<DeviceDefinitionTypeDeleteFieldInput>;
  specialization?: Maybe<Array<DeviceDefinitionSpecializationDeleteFieldInput>>;
  _version?: Maybe<Array<DeviceDefinition_VersionDeleteFieldInput>>;
  safety?: Maybe<Array<DeviceDefinitionSafetyDeleteFieldInput>>;
  shelfLifeStorage?: Maybe<Array<DeviceDefinitionShelflifestorageDeleteFieldInput>>;
  physicalCharacteristics?: Maybe<DeviceDefinitionPhysicalcharacteristicsDeleteFieldInput>;
  languageCode?: Maybe<Array<DeviceDefinitionLanguagecodeDeleteFieldInput>>;
  capability?: Maybe<Array<DeviceDefinitionCapabilityDeleteFieldInput>>;
  property?: Maybe<Array<DeviceDefinitionPropertyDeleteFieldInput>>;
  owner?: Maybe<DeviceDefinitionOwnerDeleteFieldInput>;
  contact?: Maybe<Array<DeviceDefinitionContactDeleteFieldInput>>;
  _url?: Maybe<DeviceDefinition_UrlDeleteFieldInput>;
  _onlineInformation?: Maybe<DeviceDefinition_OnlineinformationDeleteFieldInput>;
  note?: Maybe<Array<DeviceDefinitionNoteDeleteFieldInput>>;
  quantity?: Maybe<DeviceDefinitionQuantityDeleteFieldInput>;
  parentDevice?: Maybe<DeviceDefinitionParentdeviceDeleteFieldInput>;
  material?: Maybe<Array<DeviceDefinitionMaterialDeleteFieldInput>>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionDeviceName = {
  __typename?: 'DeviceDefinitionDeviceName';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /**
   * The type of deviceName.
   * UDILabelName | UserFriendlyName | PatientReportedName | ManufactureDeviceName | ModelName.
   */
  type?: Maybe<DeviceDefinitionDeviceNameType>;
  /** The name of the device. */
  name?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _name?: Maybe<Element>;
  _type?: Maybe<Element>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionDeviceNameExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionDeviceNameModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionDeviceName_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionDeviceName_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DeviceDefinitionDeviceNameConnectFieldInput = {
  where?: Maybe<DeviceDefinitionDeviceNameWhere>;
  connect?: Maybe<DeviceDefinitionDeviceNameConnectInput>;
};

export type DeviceDefinitionDeviceNameConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _name?: Maybe<ElementConnectFieldInput>;
  _type?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionDeviceNameCreateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceDefinitionDeviceNameType>;
  extension?: Maybe<DeviceDefinitionDeviceNameExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceDefinitionDeviceNameModifierextensionFieldInput>;
  _name?: Maybe<DeviceDefinitionDeviceName_NameFieldInput>;
  _type?: Maybe<DeviceDefinitionDeviceName_TypeFieldInput>;
};

export type DeviceDefinitionDeviceNameDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionDeviceNameWhere>;
  delete?: Maybe<DeviceDefinitionDeviceNameDeleteInput>;
};

export type DeviceDefinitionDeviceNameDeleteInput = {
  extension?: Maybe<Array<DeviceDefinitionDeviceNameExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionDeviceNameModifierextensionDeleteFieldInput>>;
  _name?: Maybe<DeviceDefinitionDeviceName_NameDeleteFieldInput>;
  _type?: Maybe<DeviceDefinitionDeviceName_TypeDeleteFieldInput>;
};

export type DeviceDefinitionDeviceNameDisconnectFieldInput = {
  where?: Maybe<DeviceDefinitionDeviceNameWhere>;
  disconnect?: Maybe<DeviceDefinitionDeviceNameDisconnectInput>;
};

export type DeviceDefinitionDeviceNameDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  _type?: Maybe<ElementDisconnectFieldInput>;
};

export type DeviceDefinitionDeviceNameExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionDeviceNameExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionDeviceNameExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionDeviceNameModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionDeviceNameModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionDeviceNameModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionDeviceNameOptions = {
  /** Specify one or more DeviceDefinitionDeviceNameSort objects to sort DeviceDefinitionDeviceNames by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceDefinitionDeviceNameSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceDefinitionDeviceNameRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _name?: Maybe<ElementCreateInput>;
  _type?: Maybe<ElementCreateInput>;
};

/** Fields to sort DeviceDefinitionDeviceNames by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceDefinitionDeviceNameSort object. */
export type DeviceDefinitionDeviceNameSort = {
  id?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
};

/**
 * The type of deviceName.
 * UDILabelName | UserFriendlyName | PatientReportedName | ManufactureDeviceName | ModelName.
 */
export enum DeviceDefinitionDeviceNameType {
  UdiLabelName = 'udiLabelName',
  UserFriendlyName = 'userFriendlyName',
  PatientReportedName = 'patientReportedName',
  ManufacturerName = 'manufacturerName',
  ModelName = 'modelName',
  Other = 'other',
}

export type DeviceDefinitionDeviceNameUpdateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceDefinitionDeviceNameType>;
  extension?: Maybe<Array<DeviceDefinitionDeviceNameExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionDeviceNameModifierextensionUpdateFieldInput>>;
  _name?: Maybe<DeviceDefinitionDeviceName_NameUpdateFieldInput>;
  _type?: Maybe<DeviceDefinitionDeviceName_TypeUpdateFieldInput>;
};

export type DeviceDefinitionDeviceNameWhere = {
  OR?: Maybe<Array<DeviceDefinitionDeviceNameWhere>>;
  AND?: Maybe<Array<DeviceDefinitionDeviceNameWhere>>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  type?: Maybe<DeviceDefinitionDeviceNameType>;
  type_NOT?: Maybe<DeviceDefinitionDeviceNameType>;
  type_IN?: Maybe<Array<Maybe<DeviceDefinitionDeviceNameType>>>;
  type_NOT_IN?: Maybe<Array<Maybe<DeviceDefinitionDeviceNameType>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DeviceDefinitionDeviceName_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinitionDeviceName_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionDeviceName_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinitionDeviceName_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinitionDeviceName_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionDeviceName_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinitionDevicenameDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionDeviceNameWhere>;
  delete?: Maybe<DeviceDefinitionDeviceNameDeleteInput>;
};

export type DeviceDefinitionDevicenameFieldInput = {
  create?: Maybe<Array<DeviceDefinitionDeviceNameCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionDeviceNameConnectFieldInput>>;
};

export type DeviceDefinitionDevicenameUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionDeviceNameWhere>;
  update?: Maybe<DeviceDefinitionDeviceNameUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionDeviceNameConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDeviceNameDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionDeviceNameCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeviceNameDeleteFieldInput>>;
};

export type DeviceDefinitionDisconnectFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  disconnect?: Maybe<DeviceDefinitionDisconnectInput>;
};

export type DeviceDefinitionDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  udiDeviceIdentifier?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierDisconnectFieldInput>>;
  _manufacturerString?: Maybe<ElementDisconnectFieldInput>;
  manufacturerReference?: Maybe<ReferenceDisconnectFieldInput>;
  deviceName?: Maybe<Array<DeviceDefinitionDeviceNameDisconnectFieldInput>>;
  _modelNumber?: Maybe<ElementDisconnectFieldInput>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  specialization?: Maybe<Array<DeviceDefinitionSpecializationDisconnectFieldInput>>;
  _version?: Maybe<Array<ElementDisconnectFieldInput>>;
  safety?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  shelfLifeStorage?: Maybe<Array<ProductShelfLifeDisconnectFieldInput>>;
  physicalCharacteristics?: Maybe<ProdCharacteristicDisconnectFieldInput>;
  languageCode?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  capability?: Maybe<Array<DeviceDefinitionCapabilityDisconnectFieldInput>>;
  property?: Maybe<Array<DeviceDefinitionPropertyDisconnectFieldInput>>;
  owner?: Maybe<ReferenceDisconnectFieldInput>;
  contact?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  _url?: Maybe<ElementDisconnectFieldInput>;
  _onlineInformation?: Maybe<ElementDisconnectFieldInput>;
  note?: Maybe<Array<AnnotationDisconnectFieldInput>>;
  quantity?: Maybe<QuantityDisconnectFieldInput>;
  parentDevice?: Maybe<ReferenceDisconnectFieldInput>;
  material?: Maybe<Array<DeviceDefinitionMaterialDisconnectFieldInput>>;
};

export type DeviceDefinitionExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceDefinitionIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type DeviceDefinitionIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type DeviceDefinitionIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type DeviceDefinitionLanguagecodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceDefinitionLanguagecodeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DeviceDefinitionLanguagecodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type DeviceDefinitionManufacturerreferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DeviceDefinitionManufacturerreferenceFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceDefinitionManufacturerreferenceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionMaterial = {
  __typename?: 'DeviceDefinitionMaterial';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Indicates an alternative material of the device. */
  alternate?: Maybe<Scalars['Boolean']>;
  /** Whether the substance is a known or suspected allergen. */
  allergenicIndicator?: Maybe<Scalars['Boolean']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  substance: CodeableConcept;
  _alternate?: Maybe<Element>;
  _allergenicIndicator?: Maybe<Element>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionMaterialExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionMaterialModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionMaterialSubstanceArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionMaterial_AlternateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionMaterial_AllergenicIndicatorArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DeviceDefinitionMaterialConnectFieldInput = {
  where?: Maybe<DeviceDefinitionMaterialWhere>;
  connect?: Maybe<DeviceDefinitionMaterialConnectInput>;
};

export type DeviceDefinitionMaterialConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  substance?: Maybe<CodeableConceptConnectFieldInput>;
  _alternate?: Maybe<ElementConnectFieldInput>;
  _allergenicIndicator?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionMaterialCreateInput = {
  alternate?: Maybe<Scalars['Boolean']>;
  allergenicIndicator?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<DeviceDefinitionMaterialExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceDefinitionMaterialModifierextensionFieldInput>;
  substance?: Maybe<DeviceDefinitionMaterialSubstanceFieldInput>;
  _alternate?: Maybe<DeviceDefinitionMaterial_AlternateFieldInput>;
  _allergenicIndicator?: Maybe<DeviceDefinitionMaterial_AllergenicindicatorFieldInput>;
};

export type DeviceDefinitionMaterialDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionMaterialWhere>;
  delete?: Maybe<DeviceDefinitionMaterialDeleteInput>;
};

export type DeviceDefinitionMaterialDeleteInput = {
  extension?: Maybe<Array<DeviceDefinitionMaterialExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionMaterialModifierextensionDeleteFieldInput>>;
  substance?: Maybe<DeviceDefinitionMaterialSubstanceDeleteFieldInput>;
  _alternate?: Maybe<DeviceDefinitionMaterial_AlternateDeleteFieldInput>;
  _allergenicIndicator?: Maybe<DeviceDefinitionMaterial_AllergenicindicatorDeleteFieldInput>;
};

export type DeviceDefinitionMaterialDisconnectFieldInput = {
  where?: Maybe<DeviceDefinitionMaterialWhere>;
  disconnect?: Maybe<DeviceDefinitionMaterialDisconnectInput>;
};

export type DeviceDefinitionMaterialDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  substance?: Maybe<CodeableConceptDisconnectFieldInput>;
  _alternate?: Maybe<ElementDisconnectFieldInput>;
  _allergenicIndicator?: Maybe<ElementDisconnectFieldInput>;
};

export type DeviceDefinitionMaterialExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionMaterialExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionMaterialExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionMaterialFieldInput = {
  create?: Maybe<Array<DeviceDefinitionMaterialCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionMaterialConnectFieldInput>>;
};

export type DeviceDefinitionMaterialModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionMaterialModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionMaterialModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionMaterialOptions = {
  /** Specify one or more DeviceDefinitionMaterialSort objects to sort DeviceDefinitionMaterials by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceDefinitionMaterialSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceDefinitionMaterialRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  substance?: Maybe<CodeableConceptCreateInput>;
  _alternate?: Maybe<ElementCreateInput>;
  _allergenicIndicator?: Maybe<ElementCreateInput>;
};

/** Fields to sort DeviceDefinitionMaterials by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceDefinitionMaterialSort object. */
export type DeviceDefinitionMaterialSort = {
  id?: Maybe<SortDirection>;
  alternate?: Maybe<SortDirection>;
  allergenicIndicator?: Maybe<SortDirection>;
};

export type DeviceDefinitionMaterialSubstanceDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceDefinitionMaterialSubstanceFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DeviceDefinitionMaterialSubstanceUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DeviceDefinitionMaterialUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionMaterialWhere>;
  update?: Maybe<DeviceDefinitionMaterialUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionMaterialConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionMaterialDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionMaterialCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionMaterialDeleteFieldInput>>;
};

export type DeviceDefinitionMaterialUpdateInput = {
  alternate?: Maybe<Scalars['Boolean']>;
  allergenicIndicator?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Array<DeviceDefinitionMaterialExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionMaterialModifierextensionUpdateFieldInput>>;
  substance?: Maybe<DeviceDefinitionMaterialSubstanceUpdateFieldInput>;
  _alternate?: Maybe<DeviceDefinitionMaterial_AlternateUpdateFieldInput>;
  _allergenicIndicator?: Maybe<DeviceDefinitionMaterial_AllergenicindicatorUpdateFieldInput>;
};

export type DeviceDefinitionMaterialWhere = {
  OR?: Maybe<Array<DeviceDefinitionMaterialWhere>>;
  AND?: Maybe<Array<DeviceDefinitionMaterialWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  alternate?: Maybe<Scalars['Boolean']>;
  alternate_NOT?: Maybe<Scalars['Boolean']>;
  allergenicIndicator?: Maybe<Scalars['Boolean']>;
  allergenicIndicator_NOT?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  substance?: Maybe<CodeableConceptWhere>;
  substance_NOT?: Maybe<CodeableConceptWhere>;
  substance_IN?: Maybe<Array<CodeableConceptWhere>>;
  substance_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _alternate?: Maybe<ElementWhere>;
  _alternate_NOT?: Maybe<ElementWhere>;
  _alternate_IN?: Maybe<Array<ElementWhere>>;
  _alternate_NOT_IN?: Maybe<Array<ElementWhere>>;
  _allergenicIndicator?: Maybe<ElementWhere>;
  _allergenicIndicator_NOT?: Maybe<ElementWhere>;
  _allergenicIndicator_IN?: Maybe<Array<ElementWhere>>;
  _allergenicIndicator_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DeviceDefinitionMaterial_AllergenicindicatorDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinitionMaterial_AllergenicindicatorFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionMaterial_AllergenicindicatorUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinitionMaterial_AlternateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinitionMaterial_AlternateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionMaterial_AlternateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinitionMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type DeviceDefinitionMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type DeviceDefinitionMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type DeviceDefinitionModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionNoteDeleteFieldInput = {
  where?: Maybe<AnnotationWhere>;
  delete?: Maybe<AnnotationDeleteInput>;
};

export type DeviceDefinitionNoteFieldInput = {
  create?: Maybe<Array<AnnotationCreateInput>>;
  connect?: Maybe<Array<AnnotationConnectFieldInput>>;
};

export type DeviceDefinitionNoteUpdateFieldInput = {
  where?: Maybe<AnnotationWhere>;
  update?: Maybe<AnnotationUpdateInput>;
  connect?: Maybe<Array<AnnotationConnectFieldInput>>;
  disconnect?: Maybe<Array<AnnotationDisconnectFieldInput>>;
  create?: Maybe<Array<AnnotationCreateInput>>;
  delete?: Maybe<Array<AnnotationDeleteFieldInput>>;
};

export type DeviceDefinitionOptions = {
  /** Specify one or more DeviceDefinitionSort objects to sort DeviceDefinitions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceDefinitionSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceDefinitionOwnerDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DeviceDefinitionOwnerFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceDefinitionOwnerUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type DeviceDefinitionParentdeviceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DeviceDefinitionParentdeviceFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceDefinitionParentdeviceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type DeviceDefinitionPhysicalcharacteristicsDeleteFieldInput = {
  where?: Maybe<ProdCharacteristicWhere>;
  delete?: Maybe<ProdCharacteristicDeleteInput>;
};

export type DeviceDefinitionPhysicalcharacteristicsFieldInput = {
  create?: Maybe<ProdCharacteristicCreateInput>;
  connect?: Maybe<ProdCharacteristicConnectFieldInput>;
};

export type DeviceDefinitionPhysicalcharacteristicsUpdateFieldInput = {
  where?: Maybe<ProdCharacteristicWhere>;
  update?: Maybe<ProdCharacteristicUpdateInput>;
  connect?: Maybe<ProdCharacteristicConnectFieldInput>;
  disconnect?: Maybe<ProdCharacteristicDisconnectFieldInput>;
  create?: Maybe<ProdCharacteristicCreateInput>;
  delete?: Maybe<ProdCharacteristicDeleteFieldInput>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionProperty = {
  __typename?: 'DeviceDefinitionProperty';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type: CodeableConcept;
  valueQuantity: Array<Quantity>;
  valueCode: Array<CodeableConcept>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionPropertyExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionPropertyModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionPropertyTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionPropertyValueQuantityArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionPropertyValueCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

export type DeviceDefinitionPropertyConnectFieldInput = {
  where?: Maybe<DeviceDefinitionPropertyWhere>;
  connect?: Maybe<DeviceDefinitionPropertyConnectInput>;
};

export type DeviceDefinitionPropertyConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  valueQuantity?: Maybe<Array<QuantityConnectFieldInput>>;
  valueCode?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DeviceDefinitionPropertyCreateInput = {
  extension?: Maybe<DeviceDefinitionPropertyExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceDefinitionPropertyModifierextensionFieldInput>;
  type?: Maybe<DeviceDefinitionPropertyTypeFieldInput>;
  valueQuantity?: Maybe<DeviceDefinitionPropertyValuequantityFieldInput>;
  valueCode?: Maybe<DeviceDefinitionPropertyValuecodeFieldInput>;
};

export type DeviceDefinitionPropertyDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionPropertyWhere>;
  delete?: Maybe<DeviceDefinitionPropertyDeleteInput>;
};

export type DeviceDefinitionPropertyDeleteInput = {
  extension?: Maybe<Array<DeviceDefinitionPropertyExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionPropertyModifierextensionDeleteFieldInput>>;
  type?: Maybe<DeviceDefinitionPropertyTypeDeleteFieldInput>;
  valueQuantity?: Maybe<Array<DeviceDefinitionPropertyValuequantityDeleteFieldInput>>;
  valueCode?: Maybe<Array<DeviceDefinitionPropertyValuecodeDeleteFieldInput>>;
};

export type DeviceDefinitionPropertyDisconnectFieldInput = {
  where?: Maybe<DeviceDefinitionPropertyWhere>;
  disconnect?: Maybe<DeviceDefinitionPropertyDisconnectInput>;
};

export type DeviceDefinitionPropertyDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  valueQuantity?: Maybe<Array<QuantityDisconnectFieldInput>>;
  valueCode?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
};

export type DeviceDefinitionPropertyExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionPropertyExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionPropertyExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionPropertyFieldInput = {
  create?: Maybe<Array<DeviceDefinitionPropertyCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionPropertyConnectFieldInput>>;
};

export type DeviceDefinitionPropertyModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionPropertyModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionPropertyModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionPropertyOptions = {
  /** Specify one or more DeviceDefinitionPropertySort objects to sort DeviceDefinitionProperties by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceDefinitionPropertySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceDefinitionPropertyRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<CodeableConceptCreateInput>;
  valueQuantity?: Maybe<Array<QuantityCreateInput>>;
  valueCode?: Maybe<Array<CodeableConceptCreateInput>>;
};

/** Fields to sort DeviceDefinitionProperties by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceDefinitionPropertySort object. */
export type DeviceDefinitionPropertySort = {
  id?: Maybe<SortDirection>;
};

export type DeviceDefinitionPropertyTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceDefinitionPropertyTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DeviceDefinitionPropertyTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DeviceDefinitionPropertyUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionPropertyWhere>;
  update?: Maybe<DeviceDefinitionPropertyUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionPropertyConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionPropertyDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionPropertyCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionPropertyDeleteFieldInput>>;
};

export type DeviceDefinitionPropertyUpdateInput = {
  extension?: Maybe<Array<DeviceDefinitionPropertyExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionPropertyModifierextensionUpdateFieldInput>>;
  type?: Maybe<DeviceDefinitionPropertyTypeUpdateFieldInput>;
  valueQuantity?: Maybe<Array<DeviceDefinitionPropertyValuequantityUpdateFieldInput>>;
  valueCode?: Maybe<Array<DeviceDefinitionPropertyValuecodeUpdateFieldInput>>;
};

export type DeviceDefinitionPropertyValuecodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceDefinitionPropertyValuecodeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DeviceDefinitionPropertyValuecodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type DeviceDefinitionPropertyValuequantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type DeviceDefinitionPropertyValuequantityFieldInput = {
  create?: Maybe<Array<QuantityCreateInput>>;
  connect?: Maybe<Array<QuantityConnectFieldInput>>;
};

export type DeviceDefinitionPropertyValuequantityUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<Array<QuantityConnectFieldInput>>;
  disconnect?: Maybe<Array<QuantityDisconnectFieldInput>>;
  create?: Maybe<Array<QuantityCreateInput>>;
  delete?: Maybe<Array<QuantityDeleteFieldInput>>;
};

export type DeviceDefinitionPropertyWhere = {
  OR?: Maybe<Array<DeviceDefinitionPropertyWhere>>;
  AND?: Maybe<Array<DeviceDefinitionPropertyWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  valueQuantity?: Maybe<QuantityWhere>;
  valueQuantity_NOT?: Maybe<QuantityWhere>;
  valueCode?: Maybe<CodeableConceptWhere>;
  valueCode_NOT?: Maybe<CodeableConceptWhere>;
};

export type DeviceDefinitionQuantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type DeviceDefinitionQuantityFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type DeviceDefinitionQuantityUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type DeviceDefinitionRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  udiDeviceIdentifier?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierCreateInput>>;
  _manufacturerString?: Maybe<ElementCreateInput>;
  manufacturerReference?: Maybe<ReferenceCreateInput>;
  deviceName?: Maybe<Array<DeviceDefinitionDeviceNameCreateInput>>;
  _modelNumber?: Maybe<ElementCreateInput>;
  type?: Maybe<CodeableConceptCreateInput>;
  specialization?: Maybe<Array<DeviceDefinitionSpecializationCreateInput>>;
  _version?: Maybe<Array<ElementCreateInput>>;
  safety?: Maybe<Array<CodeableConceptCreateInput>>;
  shelfLifeStorage?: Maybe<Array<ProductShelfLifeCreateInput>>;
  physicalCharacteristics?: Maybe<ProdCharacteristicCreateInput>;
  languageCode?: Maybe<Array<CodeableConceptCreateInput>>;
  capability?: Maybe<Array<DeviceDefinitionCapabilityCreateInput>>;
  property?: Maybe<Array<DeviceDefinitionPropertyCreateInput>>;
  owner?: Maybe<ReferenceCreateInput>;
  contact?: Maybe<Array<ContactPointCreateInput>>;
  _url?: Maybe<ElementCreateInput>;
  _onlineInformation?: Maybe<ElementCreateInput>;
  note?: Maybe<Array<AnnotationCreateInput>>;
  quantity?: Maybe<QuantityCreateInput>;
  parentDevice?: Maybe<ReferenceCreateInput>;
  material?: Maybe<Array<DeviceDefinitionMaterialCreateInput>>;
};

export enum DeviceDefinitionResourceType {
  DeviceDefinition = 'DeviceDefinition',
}

export type DeviceDefinitionSafetyDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceDefinitionSafetyFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DeviceDefinitionSafetyUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type DeviceDefinitionShelflifestorageDeleteFieldInput = {
  where?: Maybe<ProductShelfLifeWhere>;
  delete?: Maybe<ProductShelfLifeDeleteInput>;
};

export type DeviceDefinitionShelflifestorageFieldInput = {
  create?: Maybe<Array<ProductShelfLifeCreateInput>>;
  connect?: Maybe<Array<ProductShelfLifeConnectFieldInput>>;
};

export type DeviceDefinitionShelflifestorageUpdateFieldInput = {
  where?: Maybe<ProductShelfLifeWhere>;
  update?: Maybe<ProductShelfLifeUpdateInput>;
  connect?: Maybe<Array<ProductShelfLifeConnectFieldInput>>;
  disconnect?: Maybe<Array<ProductShelfLifeDisconnectFieldInput>>;
  create?: Maybe<Array<ProductShelfLifeCreateInput>>;
  delete?: Maybe<Array<ProductShelfLifeDeleteFieldInput>>;
};

/** Fields to sort DeviceDefinitions by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceDefinitionSort object. */
export type DeviceDefinitionSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  manufacturerString?: Maybe<SortDirection>;
  modelNumber?: Maybe<SortDirection>;
  url?: Maybe<SortDirection>;
  onlineInformation?: Maybe<SortDirection>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionSpecialization = {
  __typename?: 'DeviceDefinitionSpecialization';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The standard that is used to operate and communicate. */
  systemType?: Maybe<Scalars['String']>;
  /** The version of the standard that is used to operate and communicate. */
  version?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _systemType?: Maybe<Element>;
  _version?: Maybe<Element>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionSpecializationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionSpecializationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionSpecialization_SystemTypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionSpecialization_VersionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DeviceDefinitionSpecializationConnectFieldInput = {
  where?: Maybe<DeviceDefinitionSpecializationWhere>;
  connect?: Maybe<DeviceDefinitionSpecializationConnectInput>;
};

export type DeviceDefinitionSpecializationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _systemType?: Maybe<ElementConnectFieldInput>;
  _version?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionSpecializationCreateInput = {
  systemType?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  extension?: Maybe<DeviceDefinitionSpecializationExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceDefinitionSpecializationModifierextensionFieldInput>;
  _systemType?: Maybe<DeviceDefinitionSpecialization_SystemtypeFieldInput>;
  _version?: Maybe<DeviceDefinitionSpecialization_VersionFieldInput>;
};

export type DeviceDefinitionSpecializationDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionSpecializationWhere>;
  delete?: Maybe<DeviceDefinitionSpecializationDeleteInput>;
};

export type DeviceDefinitionSpecializationDeleteInput = {
  extension?: Maybe<Array<DeviceDefinitionSpecializationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionSpecializationModifierextensionDeleteFieldInput>>;
  _systemType?: Maybe<DeviceDefinitionSpecialization_SystemtypeDeleteFieldInput>;
  _version?: Maybe<DeviceDefinitionSpecialization_VersionDeleteFieldInput>;
};

export type DeviceDefinitionSpecializationDisconnectFieldInput = {
  where?: Maybe<DeviceDefinitionSpecializationWhere>;
  disconnect?: Maybe<DeviceDefinitionSpecializationDisconnectInput>;
};

export type DeviceDefinitionSpecializationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _systemType?: Maybe<ElementDisconnectFieldInput>;
  _version?: Maybe<ElementDisconnectFieldInput>;
};

export type DeviceDefinitionSpecializationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionSpecializationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionSpecializationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionSpecializationFieldInput = {
  create?: Maybe<Array<DeviceDefinitionSpecializationCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionSpecializationConnectFieldInput>>;
};

export type DeviceDefinitionSpecializationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionSpecializationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionSpecializationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionSpecializationOptions = {
  /** Specify one or more DeviceDefinitionSpecializationSort objects to sort DeviceDefinitionSpecializations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceDefinitionSpecializationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceDefinitionSpecializationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _systemType?: Maybe<ElementCreateInput>;
  _version?: Maybe<ElementCreateInput>;
};

/** Fields to sort DeviceDefinitionSpecializations by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceDefinitionSpecializationSort object. */
export type DeviceDefinitionSpecializationSort = {
  id?: Maybe<SortDirection>;
  systemType?: Maybe<SortDirection>;
  version?: Maybe<SortDirection>;
};

export type DeviceDefinitionSpecializationUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionSpecializationWhere>;
  update?: Maybe<DeviceDefinitionSpecializationUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionSpecializationConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionSpecializationDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionSpecializationCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionSpecializationDeleteFieldInput>>;
};

export type DeviceDefinitionSpecializationUpdateInput = {
  systemType?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<DeviceDefinitionSpecializationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionSpecializationModifierextensionUpdateFieldInput>>;
  _systemType?: Maybe<DeviceDefinitionSpecialization_SystemtypeUpdateFieldInput>;
  _version?: Maybe<DeviceDefinitionSpecialization_VersionUpdateFieldInput>;
};

export type DeviceDefinitionSpecializationWhere = {
  OR?: Maybe<Array<DeviceDefinitionSpecializationWhere>>;
  AND?: Maybe<Array<DeviceDefinitionSpecializationWhere>>;
  systemType?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _systemType?: Maybe<ElementWhere>;
  _systemType_NOT?: Maybe<ElementWhere>;
  _systemType_IN?: Maybe<Array<ElementWhere>>;
  _systemType_NOT_IN?: Maybe<Array<ElementWhere>>;
  _version?: Maybe<ElementWhere>;
  _version_NOT?: Maybe<ElementWhere>;
  _version_IN?: Maybe<Array<ElementWhere>>;
  _version_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DeviceDefinitionSpecialization_SystemtypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinitionSpecialization_SystemtypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionSpecialization_SystemtypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinitionSpecialization_VersionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinitionSpecialization_VersionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionSpecialization_VersionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinitionTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type DeviceDefinitionTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type DeviceDefinitionTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type DeviceDefinitionTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceDefinitionTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DeviceDefinitionTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionUdiDeviceIdentifier = {
  __typename?: 'DeviceDefinitionUdiDeviceIdentifier';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The identifier that is to be associated with every Device that references this DeviceDefintiion for the issuer and jurisdication porvided in the DeviceDefinition.udiDeviceIdentifier. */
  deviceIdentifier?: Maybe<Scalars['String']>;
  /** The organization that assigns the identifier algorithm. */
  issuer?: Maybe<Scalars['Uri']>;
  /** The jurisdiction to which the deviceIdentifier applies. */
  jurisdiction?: Maybe<Scalars['Uri']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _deviceIdentifier?: Maybe<Element>;
  _issuer?: Maybe<Element>;
  _jurisdiction?: Maybe<Element>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionUdiDeviceIdentifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionUdiDeviceIdentifierModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionUdiDeviceIdentifier_DeviceIdentifierArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionUdiDeviceIdentifier_IssuerArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
export type DeviceDefinitionUdiDeviceIdentifier_JurisdictionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DeviceDefinitionUdiDeviceIdentifierConnectFieldInput = {
  where?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  connect?: Maybe<DeviceDefinitionUdiDeviceIdentifierConnectInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _deviceIdentifier?: Maybe<ElementConnectFieldInput>;
  _issuer?: Maybe<ElementConnectFieldInput>;
  _jurisdiction?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierCreateInput = {
  deviceIdentifier?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['Uri']>;
  jurisdiction?: Maybe<Scalars['Uri']>;
  extension?: Maybe<DeviceDefinitionUdiDeviceIdentifierExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceDefinitionUdiDeviceIdentifierModifierextensionFieldInput>;
  _deviceIdentifier?: Maybe<DeviceDefinitionUdiDeviceIdentifier_DeviceidentifierFieldInput>;
  _issuer?: Maybe<DeviceDefinitionUdiDeviceIdentifier_IssuerFieldInput>;
  _jurisdiction?: Maybe<DeviceDefinitionUdiDeviceIdentifier_JurisdictionFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  delete?: Maybe<DeviceDefinitionUdiDeviceIdentifierDeleteInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierDeleteInput = {
  extension?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierModifierextensionDeleteFieldInput>>;
  _deviceIdentifier?: Maybe<DeviceDefinitionUdiDeviceIdentifier_DeviceidentifierDeleteFieldInput>;
  _issuer?: Maybe<DeviceDefinitionUdiDeviceIdentifier_IssuerDeleteFieldInput>;
  _jurisdiction?: Maybe<DeviceDefinitionUdiDeviceIdentifier_JurisdictionDeleteFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierDisconnectFieldInput = {
  where?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  disconnect?: Maybe<DeviceDefinitionUdiDeviceIdentifierDisconnectInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _deviceIdentifier?: Maybe<ElementDisconnectFieldInput>;
  _issuer?: Maybe<ElementDisconnectFieldInput>;
  _jurisdiction?: Maybe<ElementDisconnectFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionUdiDeviceIdentifierExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionUdiDeviceIdentifierModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDefinitionUdiDeviceIdentifierModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDefinitionUdiDeviceIdentifierOptions = {
  /** Specify one or more DeviceDefinitionUdiDeviceIdentifierSort objects to sort DeviceDefinitionUdiDeviceIdentifiers by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceDefinitionUdiDeviceIdentifierSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceDefinitionUdiDeviceIdentifierRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _deviceIdentifier?: Maybe<ElementCreateInput>;
  _issuer?: Maybe<ElementCreateInput>;
  _jurisdiction?: Maybe<ElementCreateInput>;
};

/** Fields to sort DeviceDefinitionUdiDeviceIdentifiers by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceDefinitionUdiDeviceIdentifierSort object. */
export type DeviceDefinitionUdiDeviceIdentifierSort = {
  id?: Maybe<SortDirection>;
  deviceIdentifier?: Maybe<SortDirection>;
  issuer?: Maybe<SortDirection>;
  jurisdiction?: Maybe<SortDirection>;
};

export type DeviceDefinitionUdiDeviceIdentifierUpdateInput = {
  deviceIdentifier?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['Uri']>;
  jurisdiction?: Maybe<Scalars['Uri']>;
  extension?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierModifierextensionUpdateFieldInput>>;
  _deviceIdentifier?: Maybe<DeviceDefinitionUdiDeviceIdentifier_DeviceidentifierUpdateFieldInput>;
  _issuer?: Maybe<DeviceDefinitionUdiDeviceIdentifier_IssuerUpdateFieldInput>;
  _jurisdiction?: Maybe<DeviceDefinitionUdiDeviceIdentifier_JurisdictionUpdateFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifierWhere = {
  OR?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierWhere>>;
  AND?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierWhere>>;
  deviceIdentifier?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['Uri']>;
  jurisdiction?: Maybe<Scalars['Uri']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _deviceIdentifier?: Maybe<ElementWhere>;
  _deviceIdentifier_NOT?: Maybe<ElementWhere>;
  _deviceIdentifier_IN?: Maybe<Array<ElementWhere>>;
  _deviceIdentifier_NOT_IN?: Maybe<Array<ElementWhere>>;
  _issuer?: Maybe<ElementWhere>;
  _issuer_NOT?: Maybe<ElementWhere>;
  _issuer_IN?: Maybe<Array<ElementWhere>>;
  _issuer_NOT_IN?: Maybe<Array<ElementWhere>>;
  _jurisdiction?: Maybe<ElementWhere>;
  _jurisdiction_NOT?: Maybe<ElementWhere>;
  _jurisdiction_IN?: Maybe<Array<ElementWhere>>;
  _jurisdiction_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DeviceDefinitionUdiDeviceIdentifier_DeviceidentifierDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinitionUdiDeviceIdentifier_DeviceidentifierFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifier_DeviceidentifierUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifier_IssuerDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinitionUdiDeviceIdentifier_IssuerFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifier_IssuerUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifier_JurisdictionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinitionUdiDeviceIdentifier_JurisdictionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinitionUdiDeviceIdentifier_JurisdictionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinitionUdideviceidentifierDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  delete?: Maybe<DeviceDefinitionUdiDeviceIdentifierDeleteInput>;
};

export type DeviceDefinitionUdideviceidentifierFieldInput = {
  create?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierConnectFieldInput>>;
};

export type DeviceDefinitionUdideviceidentifierUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  update?: Maybe<DeviceDefinitionUdiDeviceIdentifierUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionUdiDeviceIdentifierDeleteFieldInput>>;
};

export type DeviceDefinitionUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type DeviceDefinitionUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  manufacturerString?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  version?: Maybe<Array<Scalars['String']>>;
  url?: Maybe<Scalars['Uri']>;
  onlineInformation?: Maybe<Scalars['Uri']>;
  resourceType?: Maybe<DeviceDefinitionResourceType>;
  meta?: Maybe<DeviceDefinitionMetaUpdateFieldInput>;
  _implicitRules?: Maybe<DeviceDefinition_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<DeviceDefinition_LanguageUpdateFieldInput>;
  text?: Maybe<DeviceDefinitionTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<DeviceDefinitionContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<DeviceDefinitionContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<DeviceDefinitionContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<DeviceDefinitionContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDefinitionContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceDefinitionContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<DeviceDefinitionContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<DeviceDefinitionContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<DeviceDefinitionContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<DeviceDefinitionContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<DeviceDefinitionContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<DeviceDefinitionContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<DeviceDefinitionContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<DeviceDefinitionContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<DeviceDefinitionContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<DeviceDefinitionContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<DeviceDefinitionContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<DeviceDefinitionContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<DeviceDefinitionContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<DeviceDefinitionContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<DeviceDefinitionExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDefinitionModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<DeviceDefinitionIdentifierUpdateFieldInput>>;
  udiDeviceIdentifier?: Maybe<Array<DeviceDefinitionUdideviceidentifierUpdateFieldInput>>;
  _manufacturerString?: Maybe<DeviceDefinition_ManufacturerstringUpdateFieldInput>;
  manufacturerReference?: Maybe<DeviceDefinitionManufacturerreferenceUpdateFieldInput>;
  deviceName?: Maybe<Array<DeviceDefinitionDevicenameUpdateFieldInput>>;
  _modelNumber?: Maybe<DeviceDefinition_ModelnumberUpdateFieldInput>;
  type?: Maybe<DeviceDefinitionTypeUpdateFieldInput>;
  specialization?: Maybe<Array<DeviceDefinitionSpecializationUpdateFieldInput>>;
  _version?: Maybe<Array<DeviceDefinition_VersionUpdateFieldInput>>;
  safety?: Maybe<Array<DeviceDefinitionSafetyUpdateFieldInput>>;
  shelfLifeStorage?: Maybe<Array<DeviceDefinitionShelflifestorageUpdateFieldInput>>;
  physicalCharacteristics?: Maybe<DeviceDefinitionPhysicalcharacteristicsUpdateFieldInput>;
  languageCode?: Maybe<Array<DeviceDefinitionLanguagecodeUpdateFieldInput>>;
  capability?: Maybe<Array<DeviceDefinitionCapabilityUpdateFieldInput>>;
  property?: Maybe<Array<DeviceDefinitionPropertyUpdateFieldInput>>;
  owner?: Maybe<DeviceDefinitionOwnerUpdateFieldInput>;
  contact?: Maybe<Array<DeviceDefinitionContactUpdateFieldInput>>;
  _url?: Maybe<DeviceDefinition_UrlUpdateFieldInput>;
  _onlineInformation?: Maybe<DeviceDefinition_OnlineinformationUpdateFieldInput>;
  note?: Maybe<Array<DeviceDefinitionNoteUpdateFieldInput>>;
  quantity?: Maybe<DeviceDefinitionQuantityUpdateFieldInput>;
  parentDevice?: Maybe<DeviceDefinitionParentdeviceUpdateFieldInput>;
  material?: Maybe<Array<DeviceDefinitionMaterialUpdateFieldInput>>;
};

export type DeviceDefinitionWhere = {
  OR?: Maybe<Array<DeviceDefinitionWhere>>;
  AND?: Maybe<Array<DeviceDefinitionWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  manufacturerString?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  version?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['Uri']>;
  onlineInformation?: Maybe<Scalars['Uri']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<DeviceDefinitionResourceType>;
  resourceType_NOT?: Maybe<DeviceDefinitionResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<DeviceDefinitionResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<DeviceDefinitionResourceType>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  udiDeviceIdentifier?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  udiDeviceIdentifier_NOT?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  _manufacturerString?: Maybe<ElementWhere>;
  _manufacturerString_NOT?: Maybe<ElementWhere>;
  _manufacturerString_IN?: Maybe<Array<ElementWhere>>;
  _manufacturerString_NOT_IN?: Maybe<Array<ElementWhere>>;
  manufacturerReference?: Maybe<ReferenceWhere>;
  manufacturerReference_NOT?: Maybe<ReferenceWhere>;
  manufacturerReference_IN?: Maybe<Array<ReferenceWhere>>;
  manufacturerReference_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  deviceName?: Maybe<DeviceDefinitionDeviceNameWhere>;
  deviceName_NOT?: Maybe<DeviceDefinitionDeviceNameWhere>;
  _modelNumber?: Maybe<ElementWhere>;
  _modelNumber_NOT?: Maybe<ElementWhere>;
  _modelNumber_IN?: Maybe<Array<ElementWhere>>;
  _modelNumber_NOT_IN?: Maybe<Array<ElementWhere>>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  specialization?: Maybe<DeviceDefinitionSpecializationWhere>;
  specialization_NOT?: Maybe<DeviceDefinitionSpecializationWhere>;
  _version?: Maybe<ElementWhere>;
  _version_NOT?: Maybe<ElementWhere>;
  safety?: Maybe<CodeableConceptWhere>;
  safety_NOT?: Maybe<CodeableConceptWhere>;
  shelfLifeStorage?: Maybe<ProductShelfLifeWhere>;
  shelfLifeStorage_NOT?: Maybe<ProductShelfLifeWhere>;
  physicalCharacteristics?: Maybe<ProdCharacteristicWhere>;
  physicalCharacteristics_NOT?: Maybe<ProdCharacteristicWhere>;
  physicalCharacteristics_IN?: Maybe<Array<ProdCharacteristicWhere>>;
  physicalCharacteristics_NOT_IN?: Maybe<Array<ProdCharacteristicWhere>>;
  languageCode?: Maybe<CodeableConceptWhere>;
  languageCode_NOT?: Maybe<CodeableConceptWhere>;
  capability?: Maybe<DeviceDefinitionCapabilityWhere>;
  capability_NOT?: Maybe<DeviceDefinitionCapabilityWhere>;
  property?: Maybe<DeviceDefinitionPropertyWhere>;
  property_NOT?: Maybe<DeviceDefinitionPropertyWhere>;
  owner?: Maybe<ReferenceWhere>;
  owner_NOT?: Maybe<ReferenceWhere>;
  owner_IN?: Maybe<Array<ReferenceWhere>>;
  owner_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  contact?: Maybe<ContactPointWhere>;
  contact_NOT?: Maybe<ContactPointWhere>;
  _url?: Maybe<ElementWhere>;
  _url_NOT?: Maybe<ElementWhere>;
  _url_IN?: Maybe<Array<ElementWhere>>;
  _url_NOT_IN?: Maybe<Array<ElementWhere>>;
  _onlineInformation?: Maybe<ElementWhere>;
  _onlineInformation_NOT?: Maybe<ElementWhere>;
  _onlineInformation_IN?: Maybe<Array<ElementWhere>>;
  _onlineInformation_NOT_IN?: Maybe<Array<ElementWhere>>;
  note?: Maybe<AnnotationWhere>;
  note_NOT?: Maybe<AnnotationWhere>;
  quantity?: Maybe<QuantityWhere>;
  quantity_NOT?: Maybe<QuantityWhere>;
  quantity_IN?: Maybe<Array<QuantityWhere>>;
  quantity_NOT_IN?: Maybe<Array<QuantityWhere>>;
  parentDevice?: Maybe<ReferenceWhere>;
  parentDevice_NOT?: Maybe<ReferenceWhere>;
  parentDevice_IN?: Maybe<Array<ReferenceWhere>>;
  parentDevice_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  material?: Maybe<DeviceDefinitionMaterialWhere>;
  material_NOT?: Maybe<DeviceDefinitionMaterialWhere>;
};

export type DeviceDefinition_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinition_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinition_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinition_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinition_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinition_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinition_ManufacturerstringDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinition_ManufacturerstringFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinition_ManufacturerstringUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinition_ModelnumberDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinition_ModelnumberFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinition_ModelnumberUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinition_OnlineinformationDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinition_OnlineinformationFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinition_OnlineinformationUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinition_UrlDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinition_UrlFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDefinition_UrlUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDefinition_VersionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDefinition_VersionFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type DeviceDefinition_VersionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type DeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type DeviceDeleteInput = {
  meta?: Maybe<DeviceMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Device_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Device_LanguageDeleteFieldInput>;
  text?: Maybe<DeviceTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<DeviceContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<DeviceContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<DeviceContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<DeviceContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<DeviceContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<DeviceContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<DeviceContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<DeviceContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<DeviceContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<DeviceContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<DeviceContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<DeviceContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<DeviceContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<DeviceContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<DeviceContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<DeviceContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<DeviceContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<DeviceContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<DeviceContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<DeviceExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<DeviceIdentifierDeleteFieldInput>>;
  definition?: Maybe<DeviceDefinitionDeleteFieldInput>;
  udiCarrier?: Maybe<Array<DeviceUdicarrierDeleteFieldInput>>;
  _status?: Maybe<Device_StatusDeleteFieldInput>;
  statusReason?: Maybe<Array<DeviceStatusreasonDeleteFieldInput>>;
  _distinctIdentifier?: Maybe<Device_DistinctidentifierDeleteFieldInput>;
  _manufacturer?: Maybe<Device_ManufacturerDeleteFieldInput>;
  _manufactureDate?: Maybe<Device_ManufacturedateDeleteFieldInput>;
  _expirationDate?: Maybe<Device_ExpirationdateDeleteFieldInput>;
  _lotNumber?: Maybe<Device_LotnumberDeleteFieldInput>;
  _serialNumber?: Maybe<Device_SerialnumberDeleteFieldInput>;
  deviceName?: Maybe<Array<DeviceDevicenameDeleteFieldInput>>;
  _modelNumber?: Maybe<Device_ModelnumberDeleteFieldInput>;
  _partNumber?: Maybe<Device_PartnumberDeleteFieldInput>;
  type?: Maybe<DeviceTypeDeleteFieldInput>;
  specialization?: Maybe<Array<DeviceSpecializationDeleteFieldInput>>;
  version?: Maybe<Array<DeviceVersionDeleteFieldInput>>;
  property?: Maybe<Array<DevicePropertyDeleteFieldInput>>;
  patient?: Maybe<DevicePatientDeleteFieldInput>;
  owner?: Maybe<DeviceOwnerDeleteFieldInput>;
  contact?: Maybe<Array<DeviceContactDeleteFieldInput>>;
  location?: Maybe<DeviceLocationDeleteFieldInput>;
  _url?: Maybe<Device_UrlDeleteFieldInput>;
  note?: Maybe<Array<DeviceNoteDeleteFieldInput>>;
  safety?: Maybe<Array<DeviceSafetyDeleteFieldInput>>;
  parent?: Maybe<DeviceParentDeleteFieldInput>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceDeviceName = {
  __typename?: 'DeviceDeviceName';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /**
   * The type of deviceName.
   * UDILabelName | UserFriendlyName | PatientReportedName | ManufactureDeviceName | ModelName.
   */
  type?: Maybe<DeviceDeviceNameType>;
  /** The name of the device. */
  name?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _name?: Maybe<Element>;
  _type?: Maybe<Element>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceDeviceNameExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceDeviceNameModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceDeviceName_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceDeviceName_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DeviceDeviceNameConnectFieldInput = {
  where?: Maybe<DeviceDeviceNameWhere>;
  connect?: Maybe<DeviceDeviceNameConnectInput>;
};

export type DeviceDeviceNameConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _name?: Maybe<ElementConnectFieldInput>;
  _type?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDeviceNameCreateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceDeviceNameType>;
  extension?: Maybe<DeviceDeviceNameExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceDeviceNameModifierextensionFieldInput>;
  _name?: Maybe<DeviceDeviceName_NameFieldInput>;
  _type?: Maybe<DeviceDeviceName_TypeFieldInput>;
};

export type DeviceDeviceNameDeleteFieldInput = {
  where?: Maybe<DeviceDeviceNameWhere>;
  delete?: Maybe<DeviceDeviceNameDeleteInput>;
};

export type DeviceDeviceNameDeleteInput = {
  extension?: Maybe<Array<DeviceDeviceNameExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDeviceNameModifierextensionDeleteFieldInput>>;
  _name?: Maybe<DeviceDeviceName_NameDeleteFieldInput>;
  _type?: Maybe<DeviceDeviceName_TypeDeleteFieldInput>;
};

export type DeviceDeviceNameDisconnectFieldInput = {
  where?: Maybe<DeviceDeviceNameWhere>;
  disconnect?: Maybe<DeviceDeviceNameDisconnectInput>;
};

export type DeviceDeviceNameDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  _type?: Maybe<ElementDisconnectFieldInput>;
};

export type DeviceDeviceNameExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDeviceNameExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDeviceNameExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDeviceNameModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceDeviceNameModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceDeviceNameModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceDeviceNameOptions = {
  /** Specify one or more DeviceDeviceNameSort objects to sort DeviceDeviceNames by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceDeviceNameSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceDeviceNameRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _name?: Maybe<ElementCreateInput>;
  _type?: Maybe<ElementCreateInput>;
};

/** Fields to sort DeviceDeviceNames by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceDeviceNameSort object. */
export type DeviceDeviceNameSort = {
  id?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
};

/**
 * The type of deviceName.
 * UDILabelName | UserFriendlyName | PatientReportedName | ManufactureDeviceName | ModelName.
 */
export enum DeviceDeviceNameType {
  UdiLabelName = 'udiLabelName',
  UserFriendlyName = 'userFriendlyName',
  PatientReportedName = 'patientReportedName',
  ManufacturerName = 'manufacturerName',
  ModelName = 'modelName',
  Other = 'other',
}

export type DeviceDeviceNameUpdateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceDeviceNameType>;
  extension?: Maybe<Array<DeviceDeviceNameExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceDeviceNameModifierextensionUpdateFieldInput>>;
  _name?: Maybe<DeviceDeviceName_NameUpdateFieldInput>;
  _type?: Maybe<DeviceDeviceName_TypeUpdateFieldInput>;
};

export type DeviceDeviceNameWhere = {
  OR?: Maybe<Array<DeviceDeviceNameWhere>>;
  AND?: Maybe<Array<DeviceDeviceNameWhere>>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  type?: Maybe<DeviceDeviceNameType>;
  type_NOT?: Maybe<DeviceDeviceNameType>;
  type_IN?: Maybe<Array<Maybe<DeviceDeviceNameType>>>;
  type_NOT_IN?: Maybe<Array<Maybe<DeviceDeviceNameType>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DeviceDeviceName_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDeviceName_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDeviceName_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDeviceName_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceDeviceName_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceDeviceName_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceDevicenameDeleteFieldInput = {
  where?: Maybe<DeviceDeviceNameWhere>;
  delete?: Maybe<DeviceDeviceNameDeleteInput>;
};

export type DeviceDevicenameFieldInput = {
  create?: Maybe<Array<DeviceDeviceNameCreateInput>>;
  connect?: Maybe<Array<DeviceDeviceNameConnectFieldInput>>;
};

export type DeviceDevicenameUpdateFieldInput = {
  where?: Maybe<DeviceDeviceNameWhere>;
  update?: Maybe<DeviceDeviceNameUpdateInput>;
  connect?: Maybe<Array<DeviceDeviceNameConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDeviceNameDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDeviceNameCreateInput>>;
  delete?: Maybe<Array<DeviceDeviceNameDeleteFieldInput>>;
};

export type DeviceDisconnectFieldInput = {
  where?: Maybe<DeviceWhere>;
  disconnect?: Maybe<DeviceDisconnectInput>;
};

export type DeviceDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  definition?: Maybe<ReferenceDisconnectFieldInput>;
  udiCarrier?: Maybe<Array<DeviceUdiCarrierDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  statusReason?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  _distinctIdentifier?: Maybe<ElementDisconnectFieldInput>;
  _manufacturer?: Maybe<ElementDisconnectFieldInput>;
  _manufactureDate?: Maybe<ElementDisconnectFieldInput>;
  _expirationDate?: Maybe<ElementDisconnectFieldInput>;
  _lotNumber?: Maybe<ElementDisconnectFieldInput>;
  _serialNumber?: Maybe<ElementDisconnectFieldInput>;
  deviceName?: Maybe<Array<DeviceDeviceNameDisconnectFieldInput>>;
  _modelNumber?: Maybe<ElementDisconnectFieldInput>;
  _partNumber?: Maybe<ElementDisconnectFieldInput>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  specialization?: Maybe<Array<DeviceSpecializationDisconnectFieldInput>>;
  version?: Maybe<Array<DeviceVersionDisconnectFieldInput>>;
  property?: Maybe<Array<DevicePropertyDisconnectFieldInput>>;
  patient?: Maybe<ReferenceDisconnectFieldInput>;
  owner?: Maybe<ReferenceDisconnectFieldInput>;
  contact?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  location?: Maybe<ReferenceDisconnectFieldInput>;
  _url?: Maybe<ElementDisconnectFieldInput>;
  note?: Maybe<Array<AnnotationDisconnectFieldInput>>;
  safety?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  parent?: Maybe<ReferenceDisconnectFieldInput>;
};

export type DeviceExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type DeviceIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type DeviceIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type DeviceLocationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DeviceLocationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceLocationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type DeviceMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type DeviceMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type DeviceMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetric = {
  __typename?: 'DeviceMetric';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a DeviceMetric resource */
  resourceType: DeviceMetricResourceType;
  /** Indicates current operational state of the device. For example: On, Off, Standby, etc. */
  operationalStatus?: Maybe<DeviceMetricOperationalStatus>;
  /** Describes the color representation for the metric. This is often used to aid clinicians to track and identify parameter types by color. In practice, consider a Patient Monitor that has ECG/HR and Pleth for example; the parameters are displayed in different characteristic colors, such as HR-blue, BP-green, and PR and SpO2- magenta. */
  color?: Maybe<DeviceMetricColor>;
  /** Indicates the category of the observation generation process. A DeviceMetric can be for example a setting, measurement, or calculation. */
  category?: Maybe<DeviceMetricCategory>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  type: CodeableConcept;
  unit?: Maybe<CodeableConcept>;
  source?: Maybe<Reference>;
  parent?: Maybe<Reference>;
  _operationalStatus?: Maybe<Element>;
  _color?: Maybe<Element>;
  _category?: Maybe<Element>;
  measurementPeriod?: Maybe<Timing>;
  calibration: Array<DeviceMetricCalibration>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetric_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetric_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricUnitArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricSourceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricParentArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetric_OperationalStatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetric_ColorArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetric_CategoryArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricMeasurementPeriodArgs = {
  where?: Maybe<TimingWhere>;
  options?: Maybe<TimingOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricCalibrationArgs = {
  where?: Maybe<DeviceMetricCalibrationWhere>;
  options?: Maybe<DeviceMetricCalibrationOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricCalibration = {
  __typename?: 'DeviceMetricCalibration';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Describes the type of the calibration method. */
  type?: Maybe<DeviceMetricCalibrationType>;
  /** Describes the state of the calibration. */
  state?: Maybe<DeviceMetricCalibrationState>;
  /** Describes the time last calibration has been performed. */
  time?: Maybe<Scalars['Instant']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _type?: Maybe<Element>;
  _state?: Maybe<Element>;
  _time?: Maybe<Element>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricCalibrationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricCalibrationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricCalibration_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricCalibration_StateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Describes a measurement, calculation or setting capability of a medical device. */
export type DeviceMetricCalibration_TimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DeviceMetricCalibrationConnectFieldInput = {
  where?: Maybe<DeviceMetricCalibrationWhere>;
  connect?: Maybe<DeviceMetricCalibrationConnectInput>;
};

export type DeviceMetricCalibrationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _type?: Maybe<ElementConnectFieldInput>;
  _state?: Maybe<ElementConnectFieldInput>;
  _time?: Maybe<ElementConnectFieldInput>;
};

export type DeviceMetricCalibrationCreateInput = {
  time?: Maybe<Scalars['Instant']>;
  type?: Maybe<DeviceMetricCalibrationType>;
  state?: Maybe<DeviceMetricCalibrationState>;
  extension?: Maybe<DeviceMetricCalibrationExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceMetricCalibrationModifierextensionFieldInput>;
  _type?: Maybe<DeviceMetricCalibration_TypeFieldInput>;
  _state?: Maybe<DeviceMetricCalibration_StateFieldInput>;
  _time?: Maybe<DeviceMetricCalibration_TimeFieldInput>;
};

export type DeviceMetricCalibrationDeleteFieldInput = {
  where?: Maybe<DeviceMetricCalibrationWhere>;
  delete?: Maybe<DeviceMetricCalibrationDeleteInput>;
};

export type DeviceMetricCalibrationDeleteInput = {
  extension?: Maybe<Array<DeviceMetricCalibrationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceMetricCalibrationModifierextensionDeleteFieldInput>>;
  _type?: Maybe<DeviceMetricCalibration_TypeDeleteFieldInput>;
  _state?: Maybe<DeviceMetricCalibration_StateDeleteFieldInput>;
  _time?: Maybe<DeviceMetricCalibration_TimeDeleteFieldInput>;
};

export type DeviceMetricCalibrationDisconnectFieldInput = {
  where?: Maybe<DeviceMetricCalibrationWhere>;
  disconnect?: Maybe<DeviceMetricCalibrationDisconnectInput>;
};

export type DeviceMetricCalibrationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _type?: Maybe<ElementDisconnectFieldInput>;
  _state?: Maybe<ElementDisconnectFieldInput>;
  _time?: Maybe<ElementDisconnectFieldInput>;
};

export type DeviceMetricCalibrationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceMetricCalibrationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceMetricCalibrationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceMetricCalibrationFieldInput = {
  create?: Maybe<Array<DeviceMetricCalibrationCreateInput>>;
  connect?: Maybe<Array<DeviceMetricCalibrationConnectFieldInput>>;
};

export type DeviceMetricCalibrationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceMetricCalibrationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceMetricCalibrationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceMetricCalibrationOptions = {
  /** Specify one or more DeviceMetricCalibrationSort objects to sort DeviceMetricCalibrations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceMetricCalibrationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceMetricCalibrationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _type?: Maybe<ElementCreateInput>;
  _state?: Maybe<ElementCreateInput>;
  _time?: Maybe<ElementCreateInput>;
};

/** Fields to sort DeviceMetricCalibrations by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceMetricCalibrationSort object. */
export type DeviceMetricCalibrationSort = {
  id?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  state?: Maybe<SortDirection>;
  time?: Maybe<SortDirection>;
};

/** Describes the state of the calibration. */
export enum DeviceMetricCalibrationState {
  NotCalibrated = 'notCalibrated',
  CalibrationRequired = 'calibrationRequired',
  Calibrated = 'calibrated',
  Unspecified = 'unspecified',
}

/** Describes the type of the calibration method. */
export enum DeviceMetricCalibrationType {
  Unspecified = 'unspecified',
  Offset = 'offset',
  Gain = 'gain',
  TwoPoint = 'twoPoint',
}

export type DeviceMetricCalibrationUpdateFieldInput = {
  where?: Maybe<DeviceMetricCalibrationWhere>;
  update?: Maybe<DeviceMetricCalibrationUpdateInput>;
  connect?: Maybe<Array<DeviceMetricCalibrationConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricCalibrationDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCalibrationCreateInput>>;
  delete?: Maybe<Array<DeviceMetricCalibrationDeleteFieldInput>>;
};

export type DeviceMetricCalibrationUpdateInput = {
  time?: Maybe<Scalars['Instant']>;
  type?: Maybe<DeviceMetricCalibrationType>;
  state?: Maybe<DeviceMetricCalibrationState>;
  extension?: Maybe<Array<DeviceMetricCalibrationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceMetricCalibrationModifierextensionUpdateFieldInput>>;
  _type?: Maybe<DeviceMetricCalibration_TypeUpdateFieldInput>;
  _state?: Maybe<DeviceMetricCalibration_StateUpdateFieldInput>;
  _time?: Maybe<DeviceMetricCalibration_TimeUpdateFieldInput>;
};

export type DeviceMetricCalibrationWhere = {
  OR?: Maybe<Array<DeviceMetricCalibrationWhere>>;
  AND?: Maybe<Array<DeviceMetricCalibrationWhere>>;
  time?: Maybe<Scalars['Instant']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  type?: Maybe<DeviceMetricCalibrationType>;
  type_NOT?: Maybe<DeviceMetricCalibrationType>;
  type_IN?: Maybe<Array<Maybe<DeviceMetricCalibrationType>>>;
  type_NOT_IN?: Maybe<Array<Maybe<DeviceMetricCalibrationType>>>;
  state?: Maybe<DeviceMetricCalibrationState>;
  state_NOT?: Maybe<DeviceMetricCalibrationState>;
  state_IN?: Maybe<Array<Maybe<DeviceMetricCalibrationState>>>;
  state_NOT_IN?: Maybe<Array<Maybe<DeviceMetricCalibrationState>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
  _state?: Maybe<ElementWhere>;
  _state_NOT?: Maybe<ElementWhere>;
  _state_IN?: Maybe<Array<ElementWhere>>;
  _state_NOT_IN?: Maybe<Array<ElementWhere>>;
  _time?: Maybe<ElementWhere>;
  _time_NOT?: Maybe<ElementWhere>;
  _time_IN?: Maybe<Array<ElementWhere>>;
  _time_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DeviceMetricCalibration_StateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceMetricCalibration_StateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceMetricCalibration_StateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceMetricCalibration_TimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceMetricCalibration_TimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceMetricCalibration_TimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceMetricCalibration_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceMetricCalibration_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceMetricCalibration_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Indicates the category of the observation generation process. A DeviceMetric can be for example a setting, measurement, or calculation. */
export enum DeviceMetricCategory {
  Measurement = 'measurement',
  Setting = 'setting',
  Calculation = 'calculation',
  Unspecified = 'unspecified',
}

/** Describes the color representation for the metric. This is often used to aid clinicians to track and identify parameter types by color. In practice, consider a Patient Monitor that has ECG/HR and Pleth for example; the parameters are displayed in different characteristic colors, such as HR-blue, BP-green, and PR and SpO2- magenta. */
export enum DeviceMetricColor {
  Black = 'black',
  Red = 'red',
  Green = 'green',
  Yellow = 'yellow',
  Blue = 'blue',
  Magenta = 'magenta',
  Cyan = 'cyan',
  White = 'white',
}

export type DeviceMetricConnectFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  connect?: Maybe<DeviceMetricConnectInput>;
};

export type DeviceMetricConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  unit?: Maybe<CodeableConceptConnectFieldInput>;
  source?: Maybe<ReferenceConnectFieldInput>;
  parent?: Maybe<ReferenceConnectFieldInput>;
  _operationalStatus?: Maybe<ElementConnectFieldInput>;
  _color?: Maybe<ElementConnectFieldInput>;
  _category?: Maybe<ElementConnectFieldInput>;
  measurementPeriod?: Maybe<TimingConnectFieldInput>;
  calibration?: Maybe<Array<DeviceMetricCalibrationConnectFieldInput>>;
};

export type DeviceMetricContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type DeviceMetricContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type DeviceMetricContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type DeviceMetricContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type DeviceMetricContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type DeviceMetricContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type DeviceMetricContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type DeviceMetricContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type DeviceMetricContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type DeviceMetricContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type DeviceMetricContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type DeviceMetricContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type DeviceMetricContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type DeviceMetricContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type DeviceMetricContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type DeviceMetricContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type DeviceMetricContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type DeviceMetricContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type DeviceMetricContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type DeviceMetricContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type DeviceMetricContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type DeviceMetricContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type DeviceMetricContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type DeviceMetricContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type DeviceMetricContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type DeviceMetricContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type DeviceMetricContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type DeviceMetricContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type DeviceMetricContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type DeviceMetricContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type DeviceMetricContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type DeviceMetricContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type DeviceMetricContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type DeviceMetricContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type DeviceMetricContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type DeviceMetricContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type DeviceMetricContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type DeviceMetricContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type DeviceMetricContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type DeviceMetricContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type DeviceMetricContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type DeviceMetricContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type DeviceMetricContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type DeviceMetricContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type DeviceMetricContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type DeviceMetricContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type DeviceMetricContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type DeviceMetricContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type DeviceMetricContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type DeviceMetricContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type DeviceMetricContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type DeviceMetricContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type DeviceMetricContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type DeviceMetricContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type DeviceMetricContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type DeviceMetricContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type DeviceMetricContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type DeviceMetricContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type DeviceMetricContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type DeviceMetricContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type DeviceMetricContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type DeviceMetricContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type DeviceMetricContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type DeviceMetricCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  resourceType: DeviceMetricResourceType;
  operationalStatus?: Maybe<DeviceMetricOperationalStatus>;
  color?: Maybe<DeviceMetricColor>;
  category?: Maybe<DeviceMetricCategory>;
  meta?: Maybe<DeviceMetricMetaFieldInput>;
  _implicitRules?: Maybe<DeviceMetric_ImplicitrulesFieldInput>;
  _language?: Maybe<DeviceMetric_LanguageFieldInput>;
  text?: Maybe<DeviceMetricTextFieldInput>;
  contained_Account?: Maybe<DeviceMetricContainedAccountFieldInput>;
  contained_Appointment?: Maybe<DeviceMetricContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<DeviceMetricContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<DeviceMetricContainedCoverageFieldInput>;
  contained_Device?: Maybe<DeviceMetricContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<DeviceMetricContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<DeviceMetricContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<DeviceMetricContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<DeviceMetricContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<DeviceMetricContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<DeviceMetricContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<DeviceMetricContainedLocationFieldInput>;
  contained_Organization?: Maybe<DeviceMetricContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<DeviceMetricContainedPatientFieldInput>;
  contained_Person?: Maybe<DeviceMetricContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<DeviceMetricContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<DeviceMetricContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<DeviceMetricContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<DeviceMetricContainedScheduleFieldInput>;
  contained_Slot?: Maybe<DeviceMetricContainedSlotFieldInput>;
  contained_Substance?: Maybe<DeviceMetricContainedSubstanceFieldInput>;
  extension?: Maybe<DeviceMetricExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceMetricModifierextensionFieldInput>;
  identifier?: Maybe<DeviceMetricIdentifierFieldInput>;
  type?: Maybe<DeviceMetricTypeFieldInput>;
  unit?: Maybe<DeviceMetricUnitFieldInput>;
  source?: Maybe<DeviceMetricSourceFieldInput>;
  parent?: Maybe<DeviceMetricParentFieldInput>;
  _operationalStatus?: Maybe<DeviceMetric_OperationalstatusFieldInput>;
  _color?: Maybe<DeviceMetric_ColorFieldInput>;
  _category?: Maybe<DeviceMetric_CategoryFieldInput>;
  measurementPeriod?: Maybe<DeviceMetricMeasurementperiodFieldInput>;
  calibration?: Maybe<DeviceMetricCalibrationFieldInput>;
};

export type DeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type DeviceMetricDeleteInput = {
  meta?: Maybe<DeviceMetricMetaDeleteFieldInput>;
  _implicitRules?: Maybe<DeviceMetric_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<DeviceMetric_LanguageDeleteFieldInput>;
  text?: Maybe<DeviceMetricTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<DeviceMetricContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<DeviceMetricContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<DeviceMetricContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<DeviceMetricContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<DeviceMetricContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceMetricContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<DeviceMetricContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<DeviceMetricContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<DeviceMetricContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<DeviceMetricContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<DeviceMetricContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<DeviceMetricContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<DeviceMetricContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<DeviceMetricContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<DeviceMetricContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<DeviceMetricContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<DeviceMetricContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<DeviceMetricContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<DeviceMetricContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<DeviceMetricContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<DeviceMetricExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceMetricModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<DeviceMetricIdentifierDeleteFieldInput>>;
  type?: Maybe<DeviceMetricTypeDeleteFieldInput>;
  unit?: Maybe<DeviceMetricUnitDeleteFieldInput>;
  source?: Maybe<DeviceMetricSourceDeleteFieldInput>;
  parent?: Maybe<DeviceMetricParentDeleteFieldInput>;
  _operationalStatus?: Maybe<DeviceMetric_OperationalstatusDeleteFieldInput>;
  _color?: Maybe<DeviceMetric_ColorDeleteFieldInput>;
  _category?: Maybe<DeviceMetric_CategoryDeleteFieldInput>;
  measurementPeriod?: Maybe<DeviceMetricMeasurementperiodDeleteFieldInput>;
  calibration?: Maybe<Array<DeviceMetricCalibrationDeleteFieldInput>>;
};

export type DeviceMetricDisconnectFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  disconnect?: Maybe<DeviceMetricDisconnectInput>;
};

export type DeviceMetricDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  unit?: Maybe<CodeableConceptDisconnectFieldInput>;
  source?: Maybe<ReferenceDisconnectFieldInput>;
  parent?: Maybe<ReferenceDisconnectFieldInput>;
  _operationalStatus?: Maybe<ElementDisconnectFieldInput>;
  _color?: Maybe<ElementDisconnectFieldInput>;
  _category?: Maybe<ElementDisconnectFieldInput>;
  measurementPeriod?: Maybe<TimingDisconnectFieldInput>;
  calibration?: Maybe<Array<DeviceMetricCalibrationDisconnectFieldInput>>;
};

export type DeviceMetricExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceMetricExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceMetricExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceMetricIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type DeviceMetricIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type DeviceMetricIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type DeviceMetricMeasurementperiodDeleteFieldInput = {
  where?: Maybe<TimingWhere>;
  delete?: Maybe<TimingDeleteInput>;
};

export type DeviceMetricMeasurementperiodFieldInput = {
  create?: Maybe<TimingCreateInput>;
  connect?: Maybe<TimingConnectFieldInput>;
};

export type DeviceMetricMeasurementperiodUpdateFieldInput = {
  where?: Maybe<TimingWhere>;
  update?: Maybe<TimingUpdateInput>;
  connect?: Maybe<TimingConnectFieldInput>;
  disconnect?: Maybe<TimingDisconnectFieldInput>;
  create?: Maybe<TimingCreateInput>;
  delete?: Maybe<TimingDeleteFieldInput>;
};

export type DeviceMetricMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type DeviceMetricMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type DeviceMetricMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type DeviceMetricModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceMetricModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceMetricModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** Indicates current operational state of the device. For example: On, Off, Standby, etc. */
export enum DeviceMetricOperationalStatus {
  On = 'on',
  Off = 'off',
  Standby = 'standby',
  EnteredInError = 'enteredInError',
}

export type DeviceMetricOptions = {
  /** Specify one or more DeviceMetricSort objects to sort DeviceMetrics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceMetricSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceMetricParentDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DeviceMetricParentFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceMetricParentUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type DeviceMetricRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  type?: Maybe<CodeableConceptCreateInput>;
  unit?: Maybe<CodeableConceptCreateInput>;
  source?: Maybe<ReferenceCreateInput>;
  parent?: Maybe<ReferenceCreateInput>;
  _operationalStatus?: Maybe<ElementCreateInput>;
  _color?: Maybe<ElementCreateInput>;
  _category?: Maybe<ElementCreateInput>;
  measurementPeriod?: Maybe<TimingCreateInput>;
  calibration?: Maybe<Array<DeviceMetricCalibrationCreateInput>>;
};

export enum DeviceMetricResourceType {
  DeviceMetric = 'DeviceMetric',
}

/** Fields to sort DeviceMetrics by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceMetricSort object. */
export type DeviceMetricSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  operationalStatus?: Maybe<SortDirection>;
  color?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
};

export type DeviceMetricSourceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DeviceMetricSourceFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceMetricSourceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type DeviceMetricTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type DeviceMetricTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type DeviceMetricTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type DeviceMetricTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceMetricTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DeviceMetricTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DeviceMetricUnitDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceMetricUnitFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DeviceMetricUnitUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DeviceMetricUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  resourceType?: Maybe<DeviceMetricResourceType>;
  operationalStatus?: Maybe<DeviceMetricOperationalStatus>;
  color?: Maybe<DeviceMetricColor>;
  category?: Maybe<DeviceMetricCategory>;
  meta?: Maybe<DeviceMetricMetaUpdateFieldInput>;
  _implicitRules?: Maybe<DeviceMetric_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<DeviceMetric_LanguageUpdateFieldInput>;
  text?: Maybe<DeviceMetricTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<DeviceMetricContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<DeviceMetricContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<DeviceMetricContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<DeviceMetricContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<DeviceMetricContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceMetricContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<DeviceMetricContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<DeviceMetricContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<DeviceMetricContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<DeviceMetricContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<DeviceMetricContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<DeviceMetricContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<DeviceMetricContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<DeviceMetricContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<DeviceMetricContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<DeviceMetricContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<DeviceMetricContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<DeviceMetricContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<DeviceMetricContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<DeviceMetricContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<DeviceMetricExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceMetricModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<DeviceMetricIdentifierUpdateFieldInput>>;
  type?: Maybe<DeviceMetricTypeUpdateFieldInput>;
  unit?: Maybe<DeviceMetricUnitUpdateFieldInput>;
  source?: Maybe<DeviceMetricSourceUpdateFieldInput>;
  parent?: Maybe<DeviceMetricParentUpdateFieldInput>;
  _operationalStatus?: Maybe<DeviceMetric_OperationalstatusUpdateFieldInput>;
  _color?: Maybe<DeviceMetric_ColorUpdateFieldInput>;
  _category?: Maybe<DeviceMetric_CategoryUpdateFieldInput>;
  measurementPeriod?: Maybe<DeviceMetricMeasurementperiodUpdateFieldInput>;
  calibration?: Maybe<Array<DeviceMetricCalibrationUpdateFieldInput>>;
};

export type DeviceMetricWhere = {
  OR?: Maybe<Array<DeviceMetricWhere>>;
  AND?: Maybe<Array<DeviceMetricWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<DeviceMetricResourceType>;
  resourceType_NOT?: Maybe<DeviceMetricResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<DeviceMetricResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<DeviceMetricResourceType>>>;
  operationalStatus?: Maybe<DeviceMetricOperationalStatus>;
  operationalStatus_NOT?: Maybe<DeviceMetricOperationalStatus>;
  operationalStatus_IN?: Maybe<Array<Maybe<DeviceMetricOperationalStatus>>>;
  operationalStatus_NOT_IN?: Maybe<Array<Maybe<DeviceMetricOperationalStatus>>>;
  color?: Maybe<DeviceMetricColor>;
  color_NOT?: Maybe<DeviceMetricColor>;
  color_IN?: Maybe<Array<Maybe<DeviceMetricColor>>>;
  color_NOT_IN?: Maybe<Array<Maybe<DeviceMetricColor>>>;
  category?: Maybe<DeviceMetricCategory>;
  category_NOT?: Maybe<DeviceMetricCategory>;
  category_IN?: Maybe<Array<Maybe<DeviceMetricCategory>>>;
  category_NOT_IN?: Maybe<Array<Maybe<DeviceMetricCategory>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  unit?: Maybe<CodeableConceptWhere>;
  unit_NOT?: Maybe<CodeableConceptWhere>;
  unit_IN?: Maybe<Array<CodeableConceptWhere>>;
  unit_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  source?: Maybe<ReferenceWhere>;
  source_NOT?: Maybe<ReferenceWhere>;
  source_IN?: Maybe<Array<ReferenceWhere>>;
  source_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  parent?: Maybe<ReferenceWhere>;
  parent_NOT?: Maybe<ReferenceWhere>;
  parent_IN?: Maybe<Array<ReferenceWhere>>;
  parent_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _operationalStatus?: Maybe<ElementWhere>;
  _operationalStatus_NOT?: Maybe<ElementWhere>;
  _operationalStatus_IN?: Maybe<Array<ElementWhere>>;
  _operationalStatus_NOT_IN?: Maybe<Array<ElementWhere>>;
  _color?: Maybe<ElementWhere>;
  _color_NOT?: Maybe<ElementWhere>;
  _color_IN?: Maybe<Array<ElementWhere>>;
  _color_NOT_IN?: Maybe<Array<ElementWhere>>;
  _category?: Maybe<ElementWhere>;
  _category_NOT?: Maybe<ElementWhere>;
  _category_IN?: Maybe<Array<ElementWhere>>;
  _category_NOT_IN?: Maybe<Array<ElementWhere>>;
  measurementPeriod?: Maybe<TimingWhere>;
  measurementPeriod_NOT?: Maybe<TimingWhere>;
  measurementPeriod_IN?: Maybe<Array<TimingWhere>>;
  measurementPeriod_NOT_IN?: Maybe<Array<TimingWhere>>;
  calibration?: Maybe<DeviceMetricCalibrationWhere>;
  calibration_NOT?: Maybe<DeviceMetricCalibrationWhere>;
};

export type DeviceMetric_CategoryDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceMetric_CategoryFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceMetric_CategoryUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceMetric_ColorDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceMetric_ColorFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceMetric_ColorUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceMetric_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceMetric_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceMetric_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceMetric_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceMetric_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceMetric_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceMetric_OperationalstatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceMetric_OperationalstatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceMetric_OperationalstatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceNoteDeleteFieldInput = {
  where?: Maybe<AnnotationWhere>;
  delete?: Maybe<AnnotationDeleteInput>;
};

export type DeviceNoteFieldInput = {
  create?: Maybe<Array<AnnotationCreateInput>>;
  connect?: Maybe<Array<AnnotationConnectFieldInput>>;
};

export type DeviceNoteUpdateFieldInput = {
  where?: Maybe<AnnotationWhere>;
  update?: Maybe<AnnotationUpdateInput>;
  connect?: Maybe<Array<AnnotationConnectFieldInput>>;
  disconnect?: Maybe<Array<AnnotationDisconnectFieldInput>>;
  create?: Maybe<Array<AnnotationCreateInput>>;
  delete?: Maybe<Array<AnnotationDeleteFieldInput>>;
};

export type DeviceOptions = {
  /** Specify one or more DeviceSort objects to sort Devices by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceOwnerDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DeviceOwnerFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceOwnerUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type DeviceParentDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DeviceParentFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DeviceParentUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type DevicePatientDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type DevicePatientFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type DevicePatientUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceProperty = {
  __typename?: 'DeviceProperty';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type: CodeableConcept;
  valueQuantity: Array<Quantity>;
  valueCode: Array<CodeableConcept>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DevicePropertyExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DevicePropertyModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DevicePropertyTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DevicePropertyValueQuantityArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DevicePropertyValueCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

export type DevicePropertyConnectFieldInput = {
  where?: Maybe<DevicePropertyWhere>;
  connect?: Maybe<DevicePropertyConnectInput>;
};

export type DevicePropertyConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  valueQuantity?: Maybe<Array<QuantityConnectFieldInput>>;
  valueCode?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DevicePropertyCreateInput = {
  extension?: Maybe<DevicePropertyExtensionFieldInput>;
  modifierExtension?: Maybe<DevicePropertyModifierextensionFieldInput>;
  type?: Maybe<DevicePropertyTypeFieldInput>;
  valueQuantity?: Maybe<DevicePropertyValuequantityFieldInput>;
  valueCode?: Maybe<DevicePropertyValuecodeFieldInput>;
};

export type DevicePropertyDeleteFieldInput = {
  where?: Maybe<DevicePropertyWhere>;
  delete?: Maybe<DevicePropertyDeleteInput>;
};

export type DevicePropertyDeleteInput = {
  extension?: Maybe<Array<DevicePropertyExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DevicePropertyModifierextensionDeleteFieldInput>>;
  type?: Maybe<DevicePropertyTypeDeleteFieldInput>;
  valueQuantity?: Maybe<Array<DevicePropertyValuequantityDeleteFieldInput>>;
  valueCode?: Maybe<Array<DevicePropertyValuecodeDeleteFieldInput>>;
};

export type DevicePropertyDisconnectFieldInput = {
  where?: Maybe<DevicePropertyWhere>;
  disconnect?: Maybe<DevicePropertyDisconnectInput>;
};

export type DevicePropertyDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  valueQuantity?: Maybe<Array<QuantityDisconnectFieldInput>>;
  valueCode?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
};

export type DevicePropertyExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DevicePropertyExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DevicePropertyExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DevicePropertyFieldInput = {
  create?: Maybe<Array<DevicePropertyCreateInput>>;
  connect?: Maybe<Array<DevicePropertyConnectFieldInput>>;
};

export type DevicePropertyModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DevicePropertyModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DevicePropertyModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DevicePropertyOptions = {
  /** Specify one or more DevicePropertySort objects to sort DeviceProperties by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DevicePropertySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DevicePropertyRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<CodeableConceptCreateInput>;
  valueQuantity?: Maybe<Array<QuantityCreateInput>>;
  valueCode?: Maybe<Array<CodeableConceptCreateInput>>;
};

/** Fields to sort DeviceProperties by. The order in which sorts are applied is not guaranteed when specifying many fields in one DevicePropertySort object. */
export type DevicePropertySort = {
  id?: Maybe<SortDirection>;
};

export type DevicePropertyTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DevicePropertyTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DevicePropertyTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DevicePropertyUpdateFieldInput = {
  where?: Maybe<DevicePropertyWhere>;
  update?: Maybe<DevicePropertyUpdateInput>;
  connect?: Maybe<Array<DevicePropertyConnectFieldInput>>;
  disconnect?: Maybe<Array<DevicePropertyDisconnectFieldInput>>;
  create?: Maybe<Array<DevicePropertyCreateInput>>;
  delete?: Maybe<Array<DevicePropertyDeleteFieldInput>>;
};

export type DevicePropertyUpdateInput = {
  extension?: Maybe<Array<DevicePropertyExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DevicePropertyModifierextensionUpdateFieldInput>>;
  type?: Maybe<DevicePropertyTypeUpdateFieldInput>;
  valueQuantity?: Maybe<Array<DevicePropertyValuequantityUpdateFieldInput>>;
  valueCode?: Maybe<Array<DevicePropertyValuecodeUpdateFieldInput>>;
};

export type DevicePropertyValuecodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DevicePropertyValuecodeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DevicePropertyValuecodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type DevicePropertyValuequantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type DevicePropertyValuequantityFieldInput = {
  create?: Maybe<Array<QuantityCreateInput>>;
  connect?: Maybe<Array<QuantityConnectFieldInput>>;
};

export type DevicePropertyValuequantityUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<Array<QuantityConnectFieldInput>>;
  disconnect?: Maybe<Array<QuantityDisconnectFieldInput>>;
  create?: Maybe<Array<QuantityCreateInput>>;
  delete?: Maybe<Array<QuantityDeleteFieldInput>>;
};

export type DevicePropertyWhere = {
  OR?: Maybe<Array<DevicePropertyWhere>>;
  AND?: Maybe<Array<DevicePropertyWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  valueQuantity?: Maybe<QuantityWhere>;
  valueQuantity_NOT?: Maybe<QuantityWhere>;
  valueCode?: Maybe<CodeableConceptWhere>;
  valueCode_NOT?: Maybe<CodeableConceptWhere>;
};

export type DeviceRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  definition?: Maybe<ReferenceCreateInput>;
  udiCarrier?: Maybe<Array<DeviceUdiCarrierCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  statusReason?: Maybe<Array<CodeableConceptCreateInput>>;
  _distinctIdentifier?: Maybe<ElementCreateInput>;
  _manufacturer?: Maybe<ElementCreateInput>;
  _manufactureDate?: Maybe<ElementCreateInput>;
  _expirationDate?: Maybe<ElementCreateInput>;
  _lotNumber?: Maybe<ElementCreateInput>;
  _serialNumber?: Maybe<ElementCreateInput>;
  deviceName?: Maybe<Array<DeviceDeviceNameCreateInput>>;
  _modelNumber?: Maybe<ElementCreateInput>;
  _partNumber?: Maybe<ElementCreateInput>;
  type?: Maybe<CodeableConceptCreateInput>;
  specialization?: Maybe<Array<DeviceSpecializationCreateInput>>;
  version?: Maybe<Array<DeviceVersionCreateInput>>;
  property?: Maybe<Array<DevicePropertyCreateInput>>;
  patient?: Maybe<ReferenceCreateInput>;
  owner?: Maybe<ReferenceCreateInput>;
  contact?: Maybe<Array<ContactPointCreateInput>>;
  location?: Maybe<ReferenceCreateInput>;
  _url?: Maybe<ElementCreateInput>;
  note?: Maybe<Array<AnnotationCreateInput>>;
  safety?: Maybe<Array<CodeableConceptCreateInput>>;
  parent?: Maybe<ReferenceCreateInput>;
};

export enum DeviceResourceType {
  Device = 'Device',
}

export type DeviceSafetyDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceSafetyFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DeviceSafetyUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

/** Fields to sort Devices by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceSort object. */
export type DeviceSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  distinctIdentifier?: Maybe<SortDirection>;
  manufacturer?: Maybe<SortDirection>;
  manufactureDate?: Maybe<SortDirection>;
  expirationDate?: Maybe<SortDirection>;
  lotNumber?: Maybe<SortDirection>;
  serialNumber?: Maybe<SortDirection>;
  modelNumber?: Maybe<SortDirection>;
  partNumber?: Maybe<SortDirection>;
  url?: Maybe<SortDirection>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceSpecialization = {
  __typename?: 'DeviceSpecialization';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The version of the standard that is used to operate and communicate. */
  version?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  systemType: CodeableConcept;
  _version?: Maybe<Element>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceSpecializationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceSpecializationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceSpecializationSystemTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceSpecialization_VersionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DeviceSpecializationConnectFieldInput = {
  where?: Maybe<DeviceSpecializationWhere>;
  connect?: Maybe<DeviceSpecializationConnectInput>;
};

export type DeviceSpecializationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  systemType?: Maybe<CodeableConceptConnectFieldInput>;
  _version?: Maybe<ElementConnectFieldInput>;
};

export type DeviceSpecializationCreateInput = {
  version?: Maybe<Scalars['String']>;
  extension?: Maybe<DeviceSpecializationExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceSpecializationModifierextensionFieldInput>;
  systemType?: Maybe<DeviceSpecializationSystemtypeFieldInput>;
  _version?: Maybe<DeviceSpecialization_VersionFieldInput>;
};

export type DeviceSpecializationDeleteFieldInput = {
  where?: Maybe<DeviceSpecializationWhere>;
  delete?: Maybe<DeviceSpecializationDeleteInput>;
};

export type DeviceSpecializationDeleteInput = {
  extension?: Maybe<Array<DeviceSpecializationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceSpecializationModifierextensionDeleteFieldInput>>;
  systemType?: Maybe<DeviceSpecializationSystemtypeDeleteFieldInput>;
  _version?: Maybe<DeviceSpecialization_VersionDeleteFieldInput>;
};

export type DeviceSpecializationDisconnectFieldInput = {
  where?: Maybe<DeviceSpecializationWhere>;
  disconnect?: Maybe<DeviceSpecializationDisconnectInput>;
};

export type DeviceSpecializationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  systemType?: Maybe<CodeableConceptDisconnectFieldInput>;
  _version?: Maybe<ElementDisconnectFieldInput>;
};

export type DeviceSpecializationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceSpecializationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceSpecializationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceSpecializationFieldInput = {
  create?: Maybe<Array<DeviceSpecializationCreateInput>>;
  connect?: Maybe<Array<DeviceSpecializationConnectFieldInput>>;
};

export type DeviceSpecializationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceSpecializationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceSpecializationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceSpecializationOptions = {
  /** Specify one or more DeviceSpecializationSort objects to sort DeviceSpecializations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceSpecializationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceSpecializationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  systemType?: Maybe<CodeableConceptCreateInput>;
  _version?: Maybe<ElementCreateInput>;
};

/** Fields to sort DeviceSpecializations by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceSpecializationSort object. */
export type DeviceSpecializationSort = {
  id?: Maybe<SortDirection>;
  version?: Maybe<SortDirection>;
};

export type DeviceSpecializationSystemtypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceSpecializationSystemtypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DeviceSpecializationSystemtypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DeviceSpecializationUpdateFieldInput = {
  where?: Maybe<DeviceSpecializationWhere>;
  update?: Maybe<DeviceSpecializationUpdateInput>;
  connect?: Maybe<Array<DeviceSpecializationConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceSpecializationDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceSpecializationCreateInput>>;
  delete?: Maybe<Array<DeviceSpecializationDeleteFieldInput>>;
};

export type DeviceSpecializationUpdateInput = {
  version?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<DeviceSpecializationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceSpecializationModifierextensionUpdateFieldInput>>;
  systemType?: Maybe<DeviceSpecializationSystemtypeUpdateFieldInput>;
  _version?: Maybe<DeviceSpecialization_VersionUpdateFieldInput>;
};

export type DeviceSpecializationWhere = {
  OR?: Maybe<Array<DeviceSpecializationWhere>>;
  AND?: Maybe<Array<DeviceSpecializationWhere>>;
  version?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  systemType?: Maybe<CodeableConceptWhere>;
  systemType_NOT?: Maybe<CodeableConceptWhere>;
  systemType_IN?: Maybe<Array<CodeableConceptWhere>>;
  systemType_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _version?: Maybe<ElementWhere>;
  _version_NOT?: Maybe<ElementWhere>;
  _version_IN?: Maybe<Array<ElementWhere>>;
  _version_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DeviceSpecialization_VersionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceSpecialization_VersionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceSpecialization_VersionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Status of the Device availability. */
export enum DeviceStatus {
  Active = 'active',
  Inactive = 'inactive',
  EnteredInError = 'enteredInError',
  Unknown = 'unknown',
}

export type DeviceStatusreasonDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceStatusreasonFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DeviceStatusreasonUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type DeviceTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type DeviceTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type DeviceTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type DeviceTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DeviceTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrier = {
  __typename?: 'DeviceUdiCarrier';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** A coded entry to indicate how the data was entered. */
  entryType?: Maybe<DeviceUdiCarrierEntryType>;
  /** The device identifier (DI) is a mandatory, fixed portion of a UDI that identifies the labeler and the specific version or model of a device. */
  deviceIdentifier?: Maybe<Scalars['String']>;
  /**
   * Organization that is charged with issuing UDIs for devices.  For example, the US FDA issuers include :
   * 1) GS1:
   * http://hl7.org/fhir/NamingSystem/gs1-di,
   * 2) HIBCC:
   * http://hl7.org/fhir/NamingSystem/hibcc-dI,
   * 3) ICCBBA for blood containers:
   * http://hl7.org/fhir/NamingSystem/iccbba-blood-di,
   * 4) ICCBA for other devices:
   * http://hl7.org/fhir/NamingSystem/iccbba-other-di.
   */
  issuer?: Maybe<Scalars['Uri']>;
  /** The identity of the authoritative source for UDI generation within a  jurisdiction.  All UDIs are globally unique within a single namespace with the appropriate repository uri as the system.  For example,  UDIs of devices managed in the U.S. by the FDA, the value is  http://hl7.org/fhir/NamingSystem/fda-udi. */
  jurisdiction?: Maybe<Scalars['Uri']>;
  /** The full UDI carrier of the Automatic Identification and Data Capture (AIDC) technology representation of the barcode string as printed on the packaging of the device - e.g., a barcode or RFID.   Because of limitations on character sets in XML and the need to round-trip JSON data through XML, AIDC Formats *SHALL* be base64 encoded. */
  carrierAIDC?: Maybe<Scalars['Base64Binary']>;
  /** The full UDI carrier as the human readable form (HRF) representation of the barcode string as printed on the packaging of the device. */
  carrierHRF?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _deviceIdentifier?: Maybe<Element>;
  _issuer?: Maybe<Element>;
  _jurisdiction?: Maybe<Element>;
  _carrierAIDC?: Maybe<Element>;
  _carrierHRF?: Maybe<Element>;
  _entryType?: Maybe<Element>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrierModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrier_DeviceIdentifierArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrier_IssuerArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrier_JurisdictionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrier_CarrierAidcArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrier_CarrierHrfArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceUdiCarrier_EntryTypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DeviceUdiCarrierConnectFieldInput = {
  where?: Maybe<DeviceUdiCarrierWhere>;
  connect?: Maybe<DeviceUdiCarrierConnectInput>;
};

export type DeviceUdiCarrierConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _deviceIdentifier?: Maybe<ElementConnectFieldInput>;
  _issuer?: Maybe<ElementConnectFieldInput>;
  _jurisdiction?: Maybe<ElementConnectFieldInput>;
  _carrierAIDC?: Maybe<ElementConnectFieldInput>;
  _carrierHRF?: Maybe<ElementConnectFieldInput>;
  _entryType?: Maybe<ElementConnectFieldInput>;
};

export type DeviceUdiCarrierCreateInput = {
  deviceIdentifier?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['Uri']>;
  jurisdiction?: Maybe<Scalars['Uri']>;
  carrierAIDC?: Maybe<Scalars['Base64Binary']>;
  carrierHRF?: Maybe<Scalars['String']>;
  entryType?: Maybe<DeviceUdiCarrierEntryType>;
  extension?: Maybe<DeviceUdiCarrierExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceUdiCarrierModifierextensionFieldInput>;
  _deviceIdentifier?: Maybe<DeviceUdiCarrier_DeviceidentifierFieldInput>;
  _issuer?: Maybe<DeviceUdiCarrier_IssuerFieldInput>;
  _jurisdiction?: Maybe<DeviceUdiCarrier_JurisdictionFieldInput>;
  _carrierAIDC?: Maybe<DeviceUdiCarrier_CarrieraidcFieldInput>;
  _carrierHRF?: Maybe<DeviceUdiCarrier_CarrierhrfFieldInput>;
  _entryType?: Maybe<DeviceUdiCarrier_EntrytypeFieldInput>;
};

export type DeviceUdiCarrierDeleteFieldInput = {
  where?: Maybe<DeviceUdiCarrierWhere>;
  delete?: Maybe<DeviceUdiCarrierDeleteInput>;
};

export type DeviceUdiCarrierDeleteInput = {
  extension?: Maybe<Array<DeviceUdiCarrierExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceUdiCarrierModifierextensionDeleteFieldInput>>;
  _deviceIdentifier?: Maybe<DeviceUdiCarrier_DeviceidentifierDeleteFieldInput>;
  _issuer?: Maybe<DeviceUdiCarrier_IssuerDeleteFieldInput>;
  _jurisdiction?: Maybe<DeviceUdiCarrier_JurisdictionDeleteFieldInput>;
  _carrierAIDC?: Maybe<DeviceUdiCarrier_CarrieraidcDeleteFieldInput>;
  _carrierHRF?: Maybe<DeviceUdiCarrier_CarrierhrfDeleteFieldInput>;
  _entryType?: Maybe<DeviceUdiCarrier_EntrytypeDeleteFieldInput>;
};

export type DeviceUdiCarrierDisconnectFieldInput = {
  where?: Maybe<DeviceUdiCarrierWhere>;
  disconnect?: Maybe<DeviceUdiCarrierDisconnectInput>;
};

export type DeviceUdiCarrierDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _deviceIdentifier?: Maybe<ElementDisconnectFieldInput>;
  _issuer?: Maybe<ElementDisconnectFieldInput>;
  _jurisdiction?: Maybe<ElementDisconnectFieldInput>;
  _carrierAIDC?: Maybe<ElementDisconnectFieldInput>;
  _carrierHRF?: Maybe<ElementDisconnectFieldInput>;
  _entryType?: Maybe<ElementDisconnectFieldInput>;
};

/** A coded entry to indicate how the data was entered. */
export enum DeviceUdiCarrierEntryType {
  Barcode = 'barcode',
  Rfid = 'rfid',
  Manual = 'manual',
  Card = 'card',
  SelfReported = 'selfReported',
  Unknown = 'unknown',
}

export type DeviceUdiCarrierExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceUdiCarrierExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceUdiCarrierExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceUdiCarrierModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceUdiCarrierModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceUdiCarrierModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceUdiCarrierOptions = {
  /** Specify one or more DeviceUdiCarrierSort objects to sort DeviceUdiCarriers by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceUdiCarrierSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceUdiCarrierRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _deviceIdentifier?: Maybe<ElementCreateInput>;
  _issuer?: Maybe<ElementCreateInput>;
  _jurisdiction?: Maybe<ElementCreateInput>;
  _carrierAIDC?: Maybe<ElementCreateInput>;
  _carrierHRF?: Maybe<ElementCreateInput>;
  _entryType?: Maybe<ElementCreateInput>;
};

/** Fields to sort DeviceUdiCarriers by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceUdiCarrierSort object. */
export type DeviceUdiCarrierSort = {
  id?: Maybe<SortDirection>;
  entryType?: Maybe<SortDirection>;
  deviceIdentifier?: Maybe<SortDirection>;
  issuer?: Maybe<SortDirection>;
  jurisdiction?: Maybe<SortDirection>;
  carrierAIDC?: Maybe<SortDirection>;
  carrierHRF?: Maybe<SortDirection>;
};

export type DeviceUdiCarrierUpdateInput = {
  deviceIdentifier?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['Uri']>;
  jurisdiction?: Maybe<Scalars['Uri']>;
  carrierAIDC?: Maybe<Scalars['Base64Binary']>;
  carrierHRF?: Maybe<Scalars['String']>;
  entryType?: Maybe<DeviceUdiCarrierEntryType>;
  extension?: Maybe<Array<DeviceUdiCarrierExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceUdiCarrierModifierextensionUpdateFieldInput>>;
  _deviceIdentifier?: Maybe<DeviceUdiCarrier_DeviceidentifierUpdateFieldInput>;
  _issuer?: Maybe<DeviceUdiCarrier_IssuerUpdateFieldInput>;
  _jurisdiction?: Maybe<DeviceUdiCarrier_JurisdictionUpdateFieldInput>;
  _carrierAIDC?: Maybe<DeviceUdiCarrier_CarrieraidcUpdateFieldInput>;
  _carrierHRF?: Maybe<DeviceUdiCarrier_CarrierhrfUpdateFieldInput>;
  _entryType?: Maybe<DeviceUdiCarrier_EntrytypeUpdateFieldInput>;
};

export type DeviceUdiCarrierWhere = {
  OR?: Maybe<Array<DeviceUdiCarrierWhere>>;
  AND?: Maybe<Array<DeviceUdiCarrierWhere>>;
  deviceIdentifier?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['Uri']>;
  jurisdiction?: Maybe<Scalars['Uri']>;
  carrierAIDC?: Maybe<Scalars['Base64Binary']>;
  carrierHRF?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  entryType?: Maybe<DeviceUdiCarrierEntryType>;
  entryType_NOT?: Maybe<DeviceUdiCarrierEntryType>;
  entryType_IN?: Maybe<Array<Maybe<DeviceUdiCarrierEntryType>>>;
  entryType_NOT_IN?: Maybe<Array<Maybe<DeviceUdiCarrierEntryType>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _deviceIdentifier?: Maybe<ElementWhere>;
  _deviceIdentifier_NOT?: Maybe<ElementWhere>;
  _deviceIdentifier_IN?: Maybe<Array<ElementWhere>>;
  _deviceIdentifier_NOT_IN?: Maybe<Array<ElementWhere>>;
  _issuer?: Maybe<ElementWhere>;
  _issuer_NOT?: Maybe<ElementWhere>;
  _issuer_IN?: Maybe<Array<ElementWhere>>;
  _issuer_NOT_IN?: Maybe<Array<ElementWhere>>;
  _jurisdiction?: Maybe<ElementWhere>;
  _jurisdiction_NOT?: Maybe<ElementWhere>;
  _jurisdiction_IN?: Maybe<Array<ElementWhere>>;
  _jurisdiction_NOT_IN?: Maybe<Array<ElementWhere>>;
  _carrierAIDC?: Maybe<ElementWhere>;
  _carrierAIDC_NOT?: Maybe<ElementWhere>;
  _carrierAIDC_IN?: Maybe<Array<ElementWhere>>;
  _carrierAIDC_NOT_IN?: Maybe<Array<ElementWhere>>;
  _carrierHRF?: Maybe<ElementWhere>;
  _carrierHRF_NOT?: Maybe<ElementWhere>;
  _carrierHRF_IN?: Maybe<Array<ElementWhere>>;
  _carrierHRF_NOT_IN?: Maybe<Array<ElementWhere>>;
  _entryType?: Maybe<ElementWhere>;
  _entryType_NOT?: Maybe<ElementWhere>;
  _entryType_IN?: Maybe<Array<ElementWhere>>;
  _entryType_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DeviceUdiCarrier_CarrieraidcDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceUdiCarrier_CarrieraidcFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceUdiCarrier_CarrieraidcUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceUdiCarrier_CarrierhrfDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceUdiCarrier_CarrierhrfFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceUdiCarrier_CarrierhrfUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceUdiCarrier_DeviceidentifierDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceUdiCarrier_DeviceidentifierFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceUdiCarrier_DeviceidentifierUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceUdiCarrier_EntrytypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceUdiCarrier_EntrytypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceUdiCarrier_EntrytypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceUdiCarrier_IssuerDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceUdiCarrier_IssuerFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceUdiCarrier_IssuerUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceUdiCarrier_JurisdictionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceUdiCarrier_JurisdictionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceUdiCarrier_JurisdictionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceUdicarrierDeleteFieldInput = {
  where?: Maybe<DeviceUdiCarrierWhere>;
  delete?: Maybe<DeviceUdiCarrierDeleteInput>;
};

export type DeviceUdicarrierFieldInput = {
  create?: Maybe<Array<DeviceUdiCarrierCreateInput>>;
  connect?: Maybe<Array<DeviceUdiCarrierConnectFieldInput>>;
};

export type DeviceUdicarrierUpdateFieldInput = {
  where?: Maybe<DeviceUdiCarrierWhere>;
  update?: Maybe<DeviceUdiCarrierUpdateInput>;
  connect?: Maybe<Array<DeviceUdiCarrierConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceUdiCarrierDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceUdiCarrierCreateInput>>;
  delete?: Maybe<Array<DeviceUdiCarrierDeleteFieldInput>>;
};

export type DeviceUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  distinctIdentifier?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufactureDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  lotNumber?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['Uri']>;
  resourceType?: Maybe<DeviceResourceType>;
  status?: Maybe<DeviceStatus>;
  meta?: Maybe<DeviceMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Device_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Device_LanguageUpdateFieldInput>;
  text?: Maybe<DeviceTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<DeviceContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<DeviceContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<DeviceContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<DeviceContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<DeviceContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<DeviceContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<DeviceContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<DeviceContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<DeviceContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<DeviceContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<DeviceContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<DeviceContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<DeviceContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<DeviceContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<DeviceContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<DeviceContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<DeviceContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<DeviceContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<DeviceContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<DeviceExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<DeviceIdentifierUpdateFieldInput>>;
  definition?: Maybe<DeviceDefinitionUpdateFieldInput>;
  udiCarrier?: Maybe<Array<DeviceUdicarrierUpdateFieldInput>>;
  _status?: Maybe<Device_StatusUpdateFieldInput>;
  statusReason?: Maybe<Array<DeviceStatusreasonUpdateFieldInput>>;
  _distinctIdentifier?: Maybe<Device_DistinctidentifierUpdateFieldInput>;
  _manufacturer?: Maybe<Device_ManufacturerUpdateFieldInput>;
  _manufactureDate?: Maybe<Device_ManufacturedateUpdateFieldInput>;
  _expirationDate?: Maybe<Device_ExpirationdateUpdateFieldInput>;
  _lotNumber?: Maybe<Device_LotnumberUpdateFieldInput>;
  _serialNumber?: Maybe<Device_SerialnumberUpdateFieldInput>;
  deviceName?: Maybe<Array<DeviceDevicenameUpdateFieldInput>>;
  _modelNumber?: Maybe<Device_ModelnumberUpdateFieldInput>;
  _partNumber?: Maybe<Device_PartnumberUpdateFieldInput>;
  type?: Maybe<DeviceTypeUpdateFieldInput>;
  specialization?: Maybe<Array<DeviceSpecializationUpdateFieldInput>>;
  version?: Maybe<Array<DeviceVersionUpdateFieldInput>>;
  property?: Maybe<Array<DevicePropertyUpdateFieldInput>>;
  patient?: Maybe<DevicePatientUpdateFieldInput>;
  owner?: Maybe<DeviceOwnerUpdateFieldInput>;
  contact?: Maybe<Array<DeviceContactUpdateFieldInput>>;
  location?: Maybe<DeviceLocationUpdateFieldInput>;
  _url?: Maybe<Device_UrlUpdateFieldInput>;
  note?: Maybe<Array<DeviceNoteUpdateFieldInput>>;
  safety?: Maybe<Array<DeviceSafetyUpdateFieldInput>>;
  parent?: Maybe<DeviceParentUpdateFieldInput>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceVersion = {
  __typename?: 'DeviceVersion';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The version text. */
  value?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type?: Maybe<CodeableConcept>;
  component?: Maybe<Identifier>;
  _value?: Maybe<Element>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceVersionExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceVersionModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceVersionTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceVersionComponentArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A type of a manufactured item that is used in the provision of healthcare without being substantially changed through that activity. The device may be a medical or non-medical device. */
export type DeviceVersion_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type DeviceVersionComponentDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type DeviceVersionComponentFieldInput = {
  create?: Maybe<IdentifierCreateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
};

export type DeviceVersionComponentUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
  disconnect?: Maybe<IdentifierDisconnectFieldInput>;
  create?: Maybe<IdentifierCreateInput>;
  delete?: Maybe<IdentifierDeleteFieldInput>;
};

export type DeviceVersionConnectFieldInput = {
  where?: Maybe<DeviceVersionWhere>;
  connect?: Maybe<DeviceVersionConnectInput>;
};

export type DeviceVersionConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  component?: Maybe<IdentifierConnectFieldInput>;
  _value?: Maybe<ElementConnectFieldInput>;
};

export type DeviceVersionCreateInput = {
  value?: Maybe<Scalars['String']>;
  extension?: Maybe<DeviceVersionExtensionFieldInput>;
  modifierExtension?: Maybe<DeviceVersionModifierextensionFieldInput>;
  type?: Maybe<DeviceVersionTypeFieldInput>;
  component?: Maybe<DeviceVersionComponentFieldInput>;
  _value?: Maybe<DeviceVersion_ValueFieldInput>;
};

export type DeviceVersionDeleteFieldInput = {
  where?: Maybe<DeviceVersionWhere>;
  delete?: Maybe<DeviceVersionDeleteInput>;
};

export type DeviceVersionDeleteInput = {
  extension?: Maybe<Array<DeviceVersionExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceVersionModifierextensionDeleteFieldInput>>;
  type?: Maybe<DeviceVersionTypeDeleteFieldInput>;
  component?: Maybe<DeviceVersionComponentDeleteFieldInput>;
  _value?: Maybe<DeviceVersion_ValueDeleteFieldInput>;
};

export type DeviceVersionDisconnectFieldInput = {
  where?: Maybe<DeviceVersionWhere>;
  disconnect?: Maybe<DeviceVersionDisconnectInput>;
};

export type DeviceVersionDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  component?: Maybe<IdentifierDisconnectFieldInput>;
  _value?: Maybe<ElementDisconnectFieldInput>;
};

export type DeviceVersionExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceVersionExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceVersionExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceVersionFieldInput = {
  create?: Maybe<Array<DeviceVersionCreateInput>>;
  connect?: Maybe<Array<DeviceVersionConnectFieldInput>>;
};

export type DeviceVersionModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DeviceVersionModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DeviceVersionModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DeviceVersionOptions = {
  /** Specify one or more DeviceVersionSort objects to sort DeviceVersions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DeviceVersionSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DeviceVersionRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<CodeableConceptCreateInput>;
  component?: Maybe<IdentifierCreateInput>;
  _value?: Maybe<ElementCreateInput>;
};

/** Fields to sort DeviceVersions by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceVersionSort object. */
export type DeviceVersionSort = {
  id?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
};

export type DeviceVersionTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DeviceVersionTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DeviceVersionTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DeviceVersionUpdateFieldInput = {
  where?: Maybe<DeviceVersionWhere>;
  update?: Maybe<DeviceVersionUpdateInput>;
  connect?: Maybe<Array<DeviceVersionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceVersionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceVersionCreateInput>>;
  delete?: Maybe<Array<DeviceVersionDeleteFieldInput>>;
};

export type DeviceVersionUpdateInput = {
  value?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<DeviceVersionExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DeviceVersionModifierextensionUpdateFieldInput>>;
  type?: Maybe<DeviceVersionTypeUpdateFieldInput>;
  component?: Maybe<DeviceVersionComponentUpdateFieldInput>;
  _value?: Maybe<DeviceVersion_ValueUpdateFieldInput>;
};

export type DeviceVersionWhere = {
  OR?: Maybe<Array<DeviceVersionWhere>>;
  AND?: Maybe<Array<DeviceVersionWhere>>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  component?: Maybe<IdentifierWhere>;
  component_NOT?: Maybe<IdentifierWhere>;
  component_IN?: Maybe<Array<IdentifierWhere>>;
  component_NOT_IN?: Maybe<Array<IdentifierWhere>>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type DeviceVersion_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type DeviceVersion_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type DeviceVersion_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type DeviceWhere = {
  OR?: Maybe<Array<DeviceWhere>>;
  AND?: Maybe<Array<DeviceWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  distinctIdentifier?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufactureDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  lotNumber?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['Uri']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<DeviceResourceType>;
  resourceType_NOT?: Maybe<DeviceResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<DeviceResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<DeviceResourceType>>>;
  status?: Maybe<DeviceStatus>;
  status_NOT?: Maybe<DeviceStatus>;
  status_IN?: Maybe<Array<Maybe<DeviceStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<DeviceStatus>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  definition?: Maybe<ReferenceWhere>;
  definition_NOT?: Maybe<ReferenceWhere>;
  definition_IN?: Maybe<Array<ReferenceWhere>>;
  definition_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  udiCarrier?: Maybe<DeviceUdiCarrierWhere>;
  udiCarrier_NOT?: Maybe<DeviceUdiCarrierWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  statusReason?: Maybe<CodeableConceptWhere>;
  statusReason_NOT?: Maybe<CodeableConceptWhere>;
  _distinctIdentifier?: Maybe<ElementWhere>;
  _distinctIdentifier_NOT?: Maybe<ElementWhere>;
  _distinctIdentifier_IN?: Maybe<Array<ElementWhere>>;
  _distinctIdentifier_NOT_IN?: Maybe<Array<ElementWhere>>;
  _manufacturer?: Maybe<ElementWhere>;
  _manufacturer_NOT?: Maybe<ElementWhere>;
  _manufacturer_IN?: Maybe<Array<ElementWhere>>;
  _manufacturer_NOT_IN?: Maybe<Array<ElementWhere>>;
  _manufactureDate?: Maybe<ElementWhere>;
  _manufactureDate_NOT?: Maybe<ElementWhere>;
  _manufactureDate_IN?: Maybe<Array<ElementWhere>>;
  _manufactureDate_NOT_IN?: Maybe<Array<ElementWhere>>;
  _expirationDate?: Maybe<ElementWhere>;
  _expirationDate_NOT?: Maybe<ElementWhere>;
  _expirationDate_IN?: Maybe<Array<ElementWhere>>;
  _expirationDate_NOT_IN?: Maybe<Array<ElementWhere>>;
  _lotNumber?: Maybe<ElementWhere>;
  _lotNumber_NOT?: Maybe<ElementWhere>;
  _lotNumber_IN?: Maybe<Array<ElementWhere>>;
  _lotNumber_NOT_IN?: Maybe<Array<ElementWhere>>;
  _serialNumber?: Maybe<ElementWhere>;
  _serialNumber_NOT?: Maybe<ElementWhere>;
  _serialNumber_IN?: Maybe<Array<ElementWhere>>;
  _serialNumber_NOT_IN?: Maybe<Array<ElementWhere>>;
  deviceName?: Maybe<DeviceDeviceNameWhere>;
  deviceName_NOT?: Maybe<DeviceDeviceNameWhere>;
  _modelNumber?: Maybe<ElementWhere>;
  _modelNumber_NOT?: Maybe<ElementWhere>;
  _modelNumber_IN?: Maybe<Array<ElementWhere>>;
  _modelNumber_NOT_IN?: Maybe<Array<ElementWhere>>;
  _partNumber?: Maybe<ElementWhere>;
  _partNumber_NOT?: Maybe<ElementWhere>;
  _partNumber_IN?: Maybe<Array<ElementWhere>>;
  _partNumber_NOT_IN?: Maybe<Array<ElementWhere>>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  specialization?: Maybe<DeviceSpecializationWhere>;
  specialization_NOT?: Maybe<DeviceSpecializationWhere>;
  version?: Maybe<DeviceVersionWhere>;
  version_NOT?: Maybe<DeviceVersionWhere>;
  property?: Maybe<DevicePropertyWhere>;
  property_NOT?: Maybe<DevicePropertyWhere>;
  patient?: Maybe<ReferenceWhere>;
  patient_NOT?: Maybe<ReferenceWhere>;
  patient_IN?: Maybe<Array<ReferenceWhere>>;
  patient_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  owner?: Maybe<ReferenceWhere>;
  owner_NOT?: Maybe<ReferenceWhere>;
  owner_IN?: Maybe<Array<ReferenceWhere>>;
  owner_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  contact?: Maybe<ContactPointWhere>;
  contact_NOT?: Maybe<ContactPointWhere>;
  location?: Maybe<ReferenceWhere>;
  location_NOT?: Maybe<ReferenceWhere>;
  location_IN?: Maybe<Array<ReferenceWhere>>;
  location_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _url?: Maybe<ElementWhere>;
  _url_NOT?: Maybe<ElementWhere>;
  _url_IN?: Maybe<Array<ElementWhere>>;
  _url_NOT_IN?: Maybe<Array<ElementWhere>>;
  note?: Maybe<AnnotationWhere>;
  note_NOT?: Maybe<AnnotationWhere>;
  safety?: Maybe<CodeableConceptWhere>;
  safety_NOT?: Maybe<CodeableConceptWhere>;
  parent?: Maybe<ReferenceWhere>;
  parent_NOT?: Maybe<ReferenceWhere>;
  parent_IN?: Maybe<Array<ReferenceWhere>>;
  parent_NOT_IN?: Maybe<Array<ReferenceWhere>>;
};

export type Device_DistinctidentifierDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_DistinctidentifierFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_DistinctidentifierUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_ExpirationdateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_ExpirationdateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_ExpirationdateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_LotnumberDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_LotnumberFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_LotnumberUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_ManufacturedateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_ManufacturedateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_ManufacturedateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_ManufacturerDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_ManufacturerFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_ManufacturerUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_ModelnumberDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_ModelnumberFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_ModelnumberUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_PartnumberDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_PartnumberFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_PartnumberUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_SerialnumberDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_SerialnumberFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_SerialnumberUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Device_UrlDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Device_UrlFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Device_UrlUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A length - a value with a unit that is a physical distance. */
export type Distance = {
  __typename?: 'Distance';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
  comparator?: Maybe<DistanceComparator>;
  /** The value of the measured amount. The value includes an implicit precision in the presentation of the value. */
  value?: Maybe<Scalars['Decimal']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['String']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['Uri']>;
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['Code']>;
  extension: Array<Extension>;
  _value?: Maybe<Element>;
  _comparator?: Maybe<Element>;
  _unit?: Maybe<Element>;
  _system?: Maybe<Element>;
  _code?: Maybe<Element>;
};

/** A length - a value with a unit that is a physical distance. */
export type DistanceExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A length - a value with a unit that is a physical distance. */
export type Distance_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A length - a value with a unit that is a physical distance. */
export type Distance_ComparatorArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A length - a value with a unit that is a physical distance. */
export type Distance_UnitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A length - a value with a unit that is a physical distance. */
export type Distance_SystemArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A length - a value with a unit that is a physical distance. */
export type Distance_CodeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
export enum DistanceComparator {
  Lt = 'lt',
  Lte = 'lte',
  Gte = 'gte',
  Gt = 'gt',
}

export type DistanceConnectFieldInput = {
  where?: Maybe<DistanceWhere>;
  connect?: Maybe<DistanceConnectInput>;
};

export type DistanceConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _value?: Maybe<ElementConnectFieldInput>;
  _comparator?: Maybe<ElementConnectFieldInput>;
  _unit?: Maybe<ElementConnectFieldInput>;
  _system?: Maybe<ElementConnectFieldInput>;
  _code?: Maybe<ElementConnectFieldInput>;
};

export type DistanceCreateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<DistanceComparator>;
  extension?: Maybe<DistanceExtensionFieldInput>;
  _value?: Maybe<Distance_ValueFieldInput>;
  _comparator?: Maybe<Distance_ComparatorFieldInput>;
  _unit?: Maybe<Distance_UnitFieldInput>;
  _system?: Maybe<Distance_SystemFieldInput>;
  _code?: Maybe<Distance_CodeFieldInput>;
};

export type DistanceDeleteFieldInput = {
  where?: Maybe<DistanceWhere>;
  delete?: Maybe<DistanceDeleteInput>;
};

export type DistanceDeleteInput = {
  extension?: Maybe<Array<DistanceExtensionDeleteFieldInput>>;
  _value?: Maybe<Distance_ValueDeleteFieldInput>;
  _comparator?: Maybe<Distance_ComparatorDeleteFieldInput>;
  _unit?: Maybe<Distance_UnitDeleteFieldInput>;
  _system?: Maybe<Distance_SystemDeleteFieldInput>;
  _code?: Maybe<Distance_CodeDeleteFieldInput>;
};

export type DistanceDisconnectFieldInput = {
  where?: Maybe<DistanceWhere>;
  disconnect?: Maybe<DistanceDisconnectInput>;
};

export type DistanceDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _value?: Maybe<ElementDisconnectFieldInput>;
  _comparator?: Maybe<ElementDisconnectFieldInput>;
  _unit?: Maybe<ElementDisconnectFieldInput>;
  _system?: Maybe<ElementDisconnectFieldInput>;
  _code?: Maybe<ElementDisconnectFieldInput>;
};

export type DistanceExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DistanceExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DistanceExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DistanceOptions = {
  /** Specify one or more DistanceSort objects to sort Distances by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DistanceSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DistanceRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _value?: Maybe<ElementCreateInput>;
  _comparator?: Maybe<ElementCreateInput>;
  _unit?: Maybe<ElementCreateInput>;
  _system?: Maybe<ElementCreateInput>;
  _code?: Maybe<ElementCreateInput>;
};

/** Fields to sort Distances by. The order in which sorts are applied is not guaranteed when specifying many fields in one DistanceSort object. */
export type DistanceSort = {
  id?: Maybe<SortDirection>;
  comparator?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  unit?: Maybe<SortDirection>;
  system?: Maybe<SortDirection>;
  code?: Maybe<SortDirection>;
};

export type DistanceUpdateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<DistanceComparator>;
  extension?: Maybe<Array<DistanceExtensionUpdateFieldInput>>;
  _value?: Maybe<Distance_ValueUpdateFieldInput>;
  _comparator?: Maybe<Distance_ComparatorUpdateFieldInput>;
  _unit?: Maybe<Distance_UnitUpdateFieldInput>;
  _system?: Maybe<Distance_SystemUpdateFieldInput>;
  _code?: Maybe<Distance_CodeUpdateFieldInput>;
};

export type DistanceWhere = {
  OR?: Maybe<Array<DistanceWhere>>;
  AND?: Maybe<Array<DistanceWhere>>;
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  comparator?: Maybe<DistanceComparator>;
  comparator_NOT?: Maybe<DistanceComparator>;
  comparator_IN?: Maybe<Array<Maybe<DistanceComparator>>>;
  comparator_NOT_IN?: Maybe<Array<Maybe<DistanceComparator>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
  _comparator?: Maybe<ElementWhere>;
  _comparator_NOT?: Maybe<ElementWhere>;
  _comparator_IN?: Maybe<Array<ElementWhere>>;
  _comparator_NOT_IN?: Maybe<Array<ElementWhere>>;
  _unit?: Maybe<ElementWhere>;
  _unit_NOT?: Maybe<ElementWhere>;
  _unit_IN?: Maybe<Array<ElementWhere>>;
  _unit_NOT_IN?: Maybe<Array<ElementWhere>>;
  _system?: Maybe<ElementWhere>;
  _system_NOT?: Maybe<ElementWhere>;
  _system_IN?: Maybe<Array<ElementWhere>>;
  _system_NOT_IN?: Maybe<Array<ElementWhere>>;
  _code?: Maybe<ElementWhere>;
  _code_NOT?: Maybe<ElementWhere>;
  _code_IN?: Maybe<Array<ElementWhere>>;
  _code_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Distance_CodeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Distance_CodeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Distance_CodeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Distance_ComparatorDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Distance_ComparatorFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Distance_ComparatorUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Distance_SystemDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Distance_SystemFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Distance_SystemUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Distance_UnitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Distance_UnitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Distance_UnitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Distance_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Distance_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Distance_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type Dosage = {
  __typename?: 'Dosage';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Indicates the order in which the dosage instructions should be applied or interpreted. */
  sequence?: Maybe<Scalars['Int']>;
  /** Indicates whether the Medication is only taken when needed within a specific dosing schedule (Boolean option), or it indicates the precondition for taking the Medication (CodeableConcept). */
  asNeededBoolean?: Maybe<Scalars['Boolean']>;
  /** Free text dosage instructions e.g. SIG. */
  text?: Maybe<Scalars['String']>;
  /** Instructions in terms that are understood by the patient or consumer. */
  patientInstruction?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _sequence?: Maybe<Element>;
  _text?: Maybe<Element>;
  additionalInstruction: Array<CodeableConcept>;
  _patientInstruction?: Maybe<Element>;
  timing?: Maybe<Timing>;
  _asNeededBoolean?: Maybe<Element>;
  asNeededCodeableConcept?: Maybe<CodeableConcept>;
  site?: Maybe<CodeableConcept>;
  route?: Maybe<CodeableConcept>;
  method?: Maybe<CodeableConcept>;
  doseAndRate: Array<DosageDoseAndRate>;
  maxDosePerPeriod?: Maybe<Ratio>;
  maxDosePerAdministration?: Maybe<Quantity>;
  maxDosePerLifetime?: Maybe<Quantity>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type Dosage_SequenceArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type Dosage_TextArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageAdditionalInstructionArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type Dosage_PatientInstructionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageTimingArgs = {
  where?: Maybe<TimingWhere>;
  options?: Maybe<TimingOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type Dosage_AsNeededBooleanArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageAsNeededCodeableConceptArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageSiteArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageRouteArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageMethodArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRateArgs = {
  where?: Maybe<DosageDoseAndRateWhere>;
  options?: Maybe<DosageDoseAndRateOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageMaxDosePerPeriodArgs = {
  where?: Maybe<RatioWhere>;
  options?: Maybe<RatioOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageMaxDosePerAdministrationArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageMaxDosePerLifetimeArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

export type DosageAdditionalinstructionDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DosageAdditionalinstructionFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type DosageAdditionalinstructionUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type DosageAsneededcodeableconceptDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DosageAsneededcodeableconceptFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DosageAsneededcodeableconceptUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DosageConnectFieldInput = {
  where?: Maybe<DosageWhere>;
  connect?: Maybe<DosageConnectInput>;
};

export type DosageConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _sequence?: Maybe<ElementConnectFieldInput>;
  _text?: Maybe<ElementConnectFieldInput>;
  additionalInstruction?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  _patientInstruction?: Maybe<ElementConnectFieldInput>;
  timing?: Maybe<TimingConnectFieldInput>;
  _asNeededBoolean?: Maybe<ElementConnectFieldInput>;
  asNeededCodeableConcept?: Maybe<CodeableConceptConnectFieldInput>;
  site?: Maybe<CodeableConceptConnectFieldInput>;
  route?: Maybe<CodeableConceptConnectFieldInput>;
  method?: Maybe<CodeableConceptConnectFieldInput>;
  doseAndRate?: Maybe<Array<DosageDoseAndRateConnectFieldInput>>;
  maxDosePerPeriod?: Maybe<RatioConnectFieldInput>;
  maxDosePerAdministration?: Maybe<QuantityConnectFieldInput>;
  maxDosePerLifetime?: Maybe<QuantityConnectFieldInput>;
};

export type DosageCreateInput = {
  sequence?: Maybe<Scalars['Int']>;
  asNeededBoolean?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  patientInstruction?: Maybe<Scalars['String']>;
  extension?: Maybe<DosageExtensionFieldInput>;
  modifierExtension?: Maybe<DosageModifierextensionFieldInput>;
  _sequence?: Maybe<Dosage_SequenceFieldInput>;
  _text?: Maybe<Dosage_TextFieldInput>;
  additionalInstruction?: Maybe<DosageAdditionalinstructionFieldInput>;
  _patientInstruction?: Maybe<Dosage_PatientinstructionFieldInput>;
  timing?: Maybe<DosageTimingFieldInput>;
  _asNeededBoolean?: Maybe<Dosage_AsneededbooleanFieldInput>;
  asNeededCodeableConcept?: Maybe<DosageAsneededcodeableconceptFieldInput>;
  site?: Maybe<DosageSiteFieldInput>;
  route?: Maybe<DosageRouteFieldInput>;
  method?: Maybe<DosageMethodFieldInput>;
  doseAndRate?: Maybe<DosageDoseandrateFieldInput>;
  maxDosePerPeriod?: Maybe<DosageMaxdoseperperiodFieldInput>;
  maxDosePerAdministration?: Maybe<DosageMaxdoseperadministrationFieldInput>;
  maxDosePerLifetime?: Maybe<DosageMaxdoseperlifetimeFieldInput>;
};

export type DosageDeleteFieldInput = {
  where?: Maybe<DosageWhere>;
  delete?: Maybe<DosageDeleteInput>;
};

export type DosageDeleteInput = {
  extension?: Maybe<Array<DosageExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DosageModifierextensionDeleteFieldInput>>;
  _sequence?: Maybe<Dosage_SequenceDeleteFieldInput>;
  _text?: Maybe<Dosage_TextDeleteFieldInput>;
  additionalInstruction?: Maybe<Array<DosageAdditionalinstructionDeleteFieldInput>>;
  _patientInstruction?: Maybe<Dosage_PatientinstructionDeleteFieldInput>;
  timing?: Maybe<DosageTimingDeleteFieldInput>;
  _asNeededBoolean?: Maybe<Dosage_AsneededbooleanDeleteFieldInput>;
  asNeededCodeableConcept?: Maybe<DosageAsneededcodeableconceptDeleteFieldInput>;
  site?: Maybe<DosageSiteDeleteFieldInput>;
  route?: Maybe<DosageRouteDeleteFieldInput>;
  method?: Maybe<DosageMethodDeleteFieldInput>;
  doseAndRate?: Maybe<Array<DosageDoseandrateDeleteFieldInput>>;
  maxDosePerPeriod?: Maybe<DosageMaxdoseperperiodDeleteFieldInput>;
  maxDosePerAdministration?: Maybe<DosageMaxdoseperadministrationDeleteFieldInput>;
  maxDosePerLifetime?: Maybe<DosageMaxdoseperlifetimeDeleteFieldInput>;
};

export type DosageDisconnectFieldInput = {
  where?: Maybe<DosageWhere>;
  disconnect?: Maybe<DosageDisconnectInput>;
};

export type DosageDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _sequence?: Maybe<ElementDisconnectFieldInput>;
  _text?: Maybe<ElementDisconnectFieldInput>;
  additionalInstruction?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  _patientInstruction?: Maybe<ElementDisconnectFieldInput>;
  timing?: Maybe<TimingDisconnectFieldInput>;
  _asNeededBoolean?: Maybe<ElementDisconnectFieldInput>;
  asNeededCodeableConcept?: Maybe<CodeableConceptDisconnectFieldInput>;
  site?: Maybe<CodeableConceptDisconnectFieldInput>;
  route?: Maybe<CodeableConceptDisconnectFieldInput>;
  method?: Maybe<CodeableConceptDisconnectFieldInput>;
  doseAndRate?: Maybe<Array<DosageDoseAndRateDisconnectFieldInput>>;
  maxDosePerPeriod?: Maybe<RatioDisconnectFieldInput>;
  maxDosePerAdministration?: Maybe<QuantityDisconnectFieldInput>;
  maxDosePerLifetime?: Maybe<QuantityDisconnectFieldInput>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRate = {
  __typename?: 'DosageDoseAndRate';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type?: Maybe<CodeableConcept>;
  doseRange?: Maybe<Range>;
  doseQuantity?: Maybe<Quantity>;
  rateRatio?: Maybe<Ratio>;
  rateRange?: Maybe<Range>;
  rateQuantity?: Maybe<Quantity>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRateExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRateModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRateTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRateDoseRangeArgs = {
  where?: Maybe<RangeWhere>;
  options?: Maybe<RangeOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRateDoseQuantityArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRateRateRatioArgs = {
  where?: Maybe<RatioWhere>;
  options?: Maybe<RatioOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRateRateRangeArgs = {
  where?: Maybe<RangeWhere>;
  options?: Maybe<RangeOptions>;
};

/** Indicates how the medication is/was taken or should be taken by the patient. */
export type DosageDoseAndRateRateQuantityArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

export type DosageDoseAndRateConnectFieldInput = {
  where?: Maybe<DosageDoseAndRateWhere>;
  connect?: Maybe<DosageDoseAndRateConnectInput>;
};

export type DosageDoseAndRateConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  doseRange?: Maybe<RangeConnectFieldInput>;
  doseQuantity?: Maybe<QuantityConnectFieldInput>;
  rateRatio?: Maybe<RatioConnectFieldInput>;
  rateRange?: Maybe<RangeConnectFieldInput>;
  rateQuantity?: Maybe<QuantityConnectFieldInput>;
};

export type DosageDoseAndRateCreateInput = {
  extension?: Maybe<DosageDoseAndRateExtensionFieldInput>;
  modifierExtension?: Maybe<DosageDoseAndRateModifierextensionFieldInput>;
  type?: Maybe<DosageDoseAndRateTypeFieldInput>;
  doseRange?: Maybe<DosageDoseAndRateDoserangeFieldInput>;
  doseQuantity?: Maybe<DosageDoseAndRateDosequantityFieldInput>;
  rateRatio?: Maybe<DosageDoseAndRateRateratioFieldInput>;
  rateRange?: Maybe<DosageDoseAndRateRaterangeFieldInput>;
  rateQuantity?: Maybe<DosageDoseAndRateRatequantityFieldInput>;
};

export type DosageDoseAndRateDeleteFieldInput = {
  where?: Maybe<DosageDoseAndRateWhere>;
  delete?: Maybe<DosageDoseAndRateDeleteInput>;
};

export type DosageDoseAndRateDeleteInput = {
  extension?: Maybe<Array<DosageDoseAndRateExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<DosageDoseAndRateModifierextensionDeleteFieldInput>>;
  type?: Maybe<DosageDoseAndRateTypeDeleteFieldInput>;
  doseRange?: Maybe<DosageDoseAndRateDoserangeDeleteFieldInput>;
  doseQuantity?: Maybe<DosageDoseAndRateDosequantityDeleteFieldInput>;
  rateRatio?: Maybe<DosageDoseAndRateRateratioDeleteFieldInput>;
  rateRange?: Maybe<DosageDoseAndRateRaterangeDeleteFieldInput>;
  rateQuantity?: Maybe<DosageDoseAndRateRatequantityDeleteFieldInput>;
};

export type DosageDoseAndRateDisconnectFieldInput = {
  where?: Maybe<DosageDoseAndRateWhere>;
  disconnect?: Maybe<DosageDoseAndRateDisconnectInput>;
};

export type DosageDoseAndRateDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  doseRange?: Maybe<RangeDisconnectFieldInput>;
  doseQuantity?: Maybe<QuantityDisconnectFieldInput>;
  rateRatio?: Maybe<RatioDisconnectFieldInput>;
  rateRange?: Maybe<RangeDisconnectFieldInput>;
  rateQuantity?: Maybe<QuantityDisconnectFieldInput>;
};

export type DosageDoseAndRateDosequantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type DosageDoseAndRateDosequantityFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type DosageDoseAndRateDosequantityUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type DosageDoseAndRateDoserangeDeleteFieldInput = {
  where?: Maybe<RangeWhere>;
  delete?: Maybe<RangeDeleteInput>;
};

export type DosageDoseAndRateDoserangeFieldInput = {
  create?: Maybe<RangeCreateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
};

export type DosageDoseAndRateDoserangeUpdateFieldInput = {
  where?: Maybe<RangeWhere>;
  update?: Maybe<RangeUpdateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
  disconnect?: Maybe<RangeDisconnectFieldInput>;
  create?: Maybe<RangeCreateInput>;
  delete?: Maybe<RangeDeleteFieldInput>;
};

export type DosageDoseAndRateExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DosageDoseAndRateExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DosageDoseAndRateExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DosageDoseAndRateModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DosageDoseAndRateModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DosageDoseAndRateModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DosageDoseAndRateOptions = {
  /** Specify one or more DosageDoseAndRateSort objects to sort DosageDoseAndRates by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DosageDoseAndRateSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DosageDoseAndRateRatequantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type DosageDoseAndRateRatequantityFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type DosageDoseAndRateRatequantityUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type DosageDoseAndRateRaterangeDeleteFieldInput = {
  where?: Maybe<RangeWhere>;
  delete?: Maybe<RangeDeleteInput>;
};

export type DosageDoseAndRateRaterangeFieldInput = {
  create?: Maybe<RangeCreateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
};

export type DosageDoseAndRateRaterangeUpdateFieldInput = {
  where?: Maybe<RangeWhere>;
  update?: Maybe<RangeUpdateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
  disconnect?: Maybe<RangeDisconnectFieldInput>;
  create?: Maybe<RangeCreateInput>;
  delete?: Maybe<RangeDeleteFieldInput>;
};

export type DosageDoseAndRateRateratioDeleteFieldInput = {
  where?: Maybe<RatioWhere>;
  delete?: Maybe<RatioDeleteInput>;
};

export type DosageDoseAndRateRateratioFieldInput = {
  create?: Maybe<RatioCreateInput>;
  connect?: Maybe<RatioConnectFieldInput>;
};

export type DosageDoseAndRateRateratioUpdateFieldInput = {
  where?: Maybe<RatioWhere>;
  update?: Maybe<RatioUpdateInput>;
  connect?: Maybe<RatioConnectFieldInput>;
  disconnect?: Maybe<RatioDisconnectFieldInput>;
  create?: Maybe<RatioCreateInput>;
  delete?: Maybe<RatioDeleteFieldInput>;
};

export type DosageDoseAndRateRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<CodeableConceptCreateInput>;
  doseRange?: Maybe<RangeCreateInput>;
  doseQuantity?: Maybe<QuantityCreateInput>;
  rateRatio?: Maybe<RatioCreateInput>;
  rateRange?: Maybe<RangeCreateInput>;
  rateQuantity?: Maybe<QuantityCreateInput>;
};

/** Fields to sort DosageDoseAndRates by. The order in which sorts are applied is not guaranteed when specifying many fields in one DosageDoseAndRateSort object. */
export type DosageDoseAndRateSort = {
  id?: Maybe<SortDirection>;
};

export type DosageDoseAndRateTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DosageDoseAndRateTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DosageDoseAndRateTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DosageDoseAndRateUpdateInput = {
  extension?: Maybe<Array<DosageDoseAndRateExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DosageDoseAndRateModifierextensionUpdateFieldInput>>;
  type?: Maybe<DosageDoseAndRateTypeUpdateFieldInput>;
  doseRange?: Maybe<DosageDoseAndRateDoserangeUpdateFieldInput>;
  doseQuantity?: Maybe<DosageDoseAndRateDosequantityUpdateFieldInput>;
  rateRatio?: Maybe<DosageDoseAndRateRateratioUpdateFieldInput>;
  rateRange?: Maybe<DosageDoseAndRateRaterangeUpdateFieldInput>;
  rateQuantity?: Maybe<DosageDoseAndRateRatequantityUpdateFieldInput>;
};

export type DosageDoseAndRateWhere = {
  OR?: Maybe<Array<DosageDoseAndRateWhere>>;
  AND?: Maybe<Array<DosageDoseAndRateWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  doseRange?: Maybe<RangeWhere>;
  doseRange_NOT?: Maybe<RangeWhere>;
  doseRange_IN?: Maybe<Array<RangeWhere>>;
  doseRange_NOT_IN?: Maybe<Array<RangeWhere>>;
  doseQuantity?: Maybe<QuantityWhere>;
  doseQuantity_NOT?: Maybe<QuantityWhere>;
  doseQuantity_IN?: Maybe<Array<QuantityWhere>>;
  doseQuantity_NOT_IN?: Maybe<Array<QuantityWhere>>;
  rateRatio?: Maybe<RatioWhere>;
  rateRatio_NOT?: Maybe<RatioWhere>;
  rateRatio_IN?: Maybe<Array<RatioWhere>>;
  rateRatio_NOT_IN?: Maybe<Array<RatioWhere>>;
  rateRange?: Maybe<RangeWhere>;
  rateRange_NOT?: Maybe<RangeWhere>;
  rateRange_IN?: Maybe<Array<RangeWhere>>;
  rateRange_NOT_IN?: Maybe<Array<RangeWhere>>;
  rateQuantity?: Maybe<QuantityWhere>;
  rateQuantity_NOT?: Maybe<QuantityWhere>;
  rateQuantity_IN?: Maybe<Array<QuantityWhere>>;
  rateQuantity_NOT_IN?: Maybe<Array<QuantityWhere>>;
};

export type DosageDoseandrateDeleteFieldInput = {
  where?: Maybe<DosageDoseAndRateWhere>;
  delete?: Maybe<DosageDoseAndRateDeleteInput>;
};

export type DosageDoseandrateFieldInput = {
  create?: Maybe<Array<DosageDoseAndRateCreateInput>>;
  connect?: Maybe<Array<DosageDoseAndRateConnectFieldInput>>;
};

export type DosageDoseandrateUpdateFieldInput = {
  where?: Maybe<DosageDoseAndRateWhere>;
  update?: Maybe<DosageDoseAndRateUpdateInput>;
  connect?: Maybe<Array<DosageDoseAndRateConnectFieldInput>>;
  disconnect?: Maybe<Array<DosageDoseAndRateDisconnectFieldInput>>;
  create?: Maybe<Array<DosageDoseAndRateCreateInput>>;
  delete?: Maybe<Array<DosageDoseAndRateDeleteFieldInput>>;
};

export type DosageExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DosageExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DosageExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DosageMaxdoseperadministrationDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type DosageMaxdoseperadministrationFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type DosageMaxdoseperadministrationUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type DosageMaxdoseperlifetimeDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type DosageMaxdoseperlifetimeFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type DosageMaxdoseperlifetimeUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type DosageMaxdoseperperiodDeleteFieldInput = {
  where?: Maybe<RatioWhere>;
  delete?: Maybe<RatioDeleteInput>;
};

export type DosageMaxdoseperperiodFieldInput = {
  create?: Maybe<RatioCreateInput>;
  connect?: Maybe<RatioConnectFieldInput>;
};

export type DosageMaxdoseperperiodUpdateFieldInput = {
  where?: Maybe<RatioWhere>;
  update?: Maybe<RatioUpdateInput>;
  connect?: Maybe<RatioConnectFieldInput>;
  disconnect?: Maybe<RatioDisconnectFieldInput>;
  create?: Maybe<RatioCreateInput>;
  delete?: Maybe<RatioDeleteFieldInput>;
};

export type DosageMethodDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DosageMethodFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DosageMethodUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DosageModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DosageModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DosageModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DosageOptions = {
  /** Specify one or more DosageSort objects to sort Dosages by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DosageSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DosageRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _sequence?: Maybe<ElementCreateInput>;
  _text?: Maybe<ElementCreateInput>;
  additionalInstruction?: Maybe<Array<CodeableConceptCreateInput>>;
  _patientInstruction?: Maybe<ElementCreateInput>;
  timing?: Maybe<TimingCreateInput>;
  _asNeededBoolean?: Maybe<ElementCreateInput>;
  asNeededCodeableConcept?: Maybe<CodeableConceptCreateInput>;
  site?: Maybe<CodeableConceptCreateInput>;
  route?: Maybe<CodeableConceptCreateInput>;
  method?: Maybe<CodeableConceptCreateInput>;
  doseAndRate?: Maybe<Array<DosageDoseAndRateCreateInput>>;
  maxDosePerPeriod?: Maybe<RatioCreateInput>;
  maxDosePerAdministration?: Maybe<QuantityCreateInput>;
  maxDosePerLifetime?: Maybe<QuantityCreateInput>;
};

export type DosageRouteDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DosageRouteFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DosageRouteUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type DosageSiteDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type DosageSiteFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type DosageSiteUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

/** Fields to sort Dosages by. The order in which sorts are applied is not guaranteed when specifying many fields in one DosageSort object. */
export type DosageSort = {
  id?: Maybe<SortDirection>;
  sequence?: Maybe<SortDirection>;
  asNeededBoolean?: Maybe<SortDirection>;
  text?: Maybe<SortDirection>;
  patientInstruction?: Maybe<SortDirection>;
};

export type DosageTimingDeleteFieldInput = {
  where?: Maybe<TimingWhere>;
  delete?: Maybe<TimingDeleteInput>;
};

export type DosageTimingFieldInput = {
  create?: Maybe<TimingCreateInput>;
  connect?: Maybe<TimingConnectFieldInput>;
};

export type DosageTimingUpdateFieldInput = {
  where?: Maybe<TimingWhere>;
  update?: Maybe<TimingUpdateInput>;
  connect?: Maybe<TimingConnectFieldInput>;
  disconnect?: Maybe<TimingDisconnectFieldInput>;
  create?: Maybe<TimingCreateInput>;
  delete?: Maybe<TimingDeleteFieldInput>;
};

export type DosageUpdateInput = {
  sequence?: Maybe<Scalars['Int']>;
  asNeededBoolean?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  patientInstruction?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<DosageExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<DosageModifierextensionUpdateFieldInput>>;
  _sequence?: Maybe<Dosage_SequenceUpdateFieldInput>;
  _text?: Maybe<Dosage_TextUpdateFieldInput>;
  additionalInstruction?: Maybe<Array<DosageAdditionalinstructionUpdateFieldInput>>;
  _patientInstruction?: Maybe<Dosage_PatientinstructionUpdateFieldInput>;
  timing?: Maybe<DosageTimingUpdateFieldInput>;
  _asNeededBoolean?: Maybe<Dosage_AsneededbooleanUpdateFieldInput>;
  asNeededCodeableConcept?: Maybe<DosageAsneededcodeableconceptUpdateFieldInput>;
  site?: Maybe<DosageSiteUpdateFieldInput>;
  route?: Maybe<DosageRouteUpdateFieldInput>;
  method?: Maybe<DosageMethodUpdateFieldInput>;
  doseAndRate?: Maybe<Array<DosageDoseandrateUpdateFieldInput>>;
  maxDosePerPeriod?: Maybe<DosageMaxdoseperperiodUpdateFieldInput>;
  maxDosePerAdministration?: Maybe<DosageMaxdoseperadministrationUpdateFieldInput>;
  maxDosePerLifetime?: Maybe<DosageMaxdoseperlifetimeUpdateFieldInput>;
};

export type DosageWhere = {
  OR?: Maybe<Array<DosageWhere>>;
  AND?: Maybe<Array<DosageWhere>>;
  text?: Maybe<Scalars['String']>;
  patientInstruction?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  sequence?: Maybe<Scalars['Int']>;
  sequence_NOT?: Maybe<Scalars['Int']>;
  sequence_IN?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sequence_NOT_IN?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sequence_LT?: Maybe<Scalars['Int']>;
  sequence_LTE?: Maybe<Scalars['Int']>;
  sequence_GT?: Maybe<Scalars['Int']>;
  sequence_GTE?: Maybe<Scalars['Int']>;
  asNeededBoolean?: Maybe<Scalars['Boolean']>;
  asNeededBoolean_NOT?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _sequence?: Maybe<ElementWhere>;
  _sequence_NOT?: Maybe<ElementWhere>;
  _sequence_IN?: Maybe<Array<ElementWhere>>;
  _sequence_NOT_IN?: Maybe<Array<ElementWhere>>;
  _text?: Maybe<ElementWhere>;
  _text_NOT?: Maybe<ElementWhere>;
  _text_IN?: Maybe<Array<ElementWhere>>;
  _text_NOT_IN?: Maybe<Array<ElementWhere>>;
  additionalInstruction?: Maybe<CodeableConceptWhere>;
  additionalInstruction_NOT?: Maybe<CodeableConceptWhere>;
  _patientInstruction?: Maybe<ElementWhere>;
  _patientInstruction_NOT?: Maybe<ElementWhere>;
  _patientInstruction_IN?: Maybe<Array<ElementWhere>>;
  _patientInstruction_NOT_IN?: Maybe<Array<ElementWhere>>;
  timing?: Maybe<TimingWhere>;
  timing_NOT?: Maybe<TimingWhere>;
  timing_IN?: Maybe<Array<TimingWhere>>;
  timing_NOT_IN?: Maybe<Array<TimingWhere>>;
  _asNeededBoolean?: Maybe<ElementWhere>;
  _asNeededBoolean_NOT?: Maybe<ElementWhere>;
  _asNeededBoolean_IN?: Maybe<Array<ElementWhere>>;
  _asNeededBoolean_NOT_IN?: Maybe<Array<ElementWhere>>;
  asNeededCodeableConcept?: Maybe<CodeableConceptWhere>;
  asNeededCodeableConcept_NOT?: Maybe<CodeableConceptWhere>;
  asNeededCodeableConcept_IN?: Maybe<Array<CodeableConceptWhere>>;
  asNeededCodeableConcept_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  site?: Maybe<CodeableConceptWhere>;
  site_NOT?: Maybe<CodeableConceptWhere>;
  site_IN?: Maybe<Array<CodeableConceptWhere>>;
  site_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  route?: Maybe<CodeableConceptWhere>;
  route_NOT?: Maybe<CodeableConceptWhere>;
  route_IN?: Maybe<Array<CodeableConceptWhere>>;
  route_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  method?: Maybe<CodeableConceptWhere>;
  method_NOT?: Maybe<CodeableConceptWhere>;
  method_IN?: Maybe<Array<CodeableConceptWhere>>;
  method_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  doseAndRate?: Maybe<DosageDoseAndRateWhere>;
  doseAndRate_NOT?: Maybe<DosageDoseAndRateWhere>;
  maxDosePerPeriod?: Maybe<RatioWhere>;
  maxDosePerPeriod_NOT?: Maybe<RatioWhere>;
  maxDosePerPeriod_IN?: Maybe<Array<RatioWhere>>;
  maxDosePerPeriod_NOT_IN?: Maybe<Array<RatioWhere>>;
  maxDosePerAdministration?: Maybe<QuantityWhere>;
  maxDosePerAdministration_NOT?: Maybe<QuantityWhere>;
  maxDosePerAdministration_IN?: Maybe<Array<QuantityWhere>>;
  maxDosePerAdministration_NOT_IN?: Maybe<Array<QuantityWhere>>;
  maxDosePerLifetime?: Maybe<QuantityWhere>;
  maxDosePerLifetime_NOT?: Maybe<QuantityWhere>;
  maxDosePerLifetime_IN?: Maybe<Array<QuantityWhere>>;
  maxDosePerLifetime_NOT_IN?: Maybe<Array<QuantityWhere>>;
};

export type Dosage_AsneededbooleanDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Dosage_AsneededbooleanFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Dosage_AsneededbooleanUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Dosage_PatientinstructionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Dosage_PatientinstructionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Dosage_PatientinstructionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Dosage_SequenceDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Dosage_SequenceFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Dosage_SequenceUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Dosage_TextDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Dosage_TextFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Dosage_TextUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A length of time. */
export type Duration = {
  __typename?: 'Duration';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
  comparator?: Maybe<DurationComparator>;
  /** The value of the measured amount. The value includes an implicit precision in the presentation of the value. */
  value?: Maybe<Scalars['Decimal']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['String']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['Uri']>;
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['Code']>;
  extension: Array<Extension>;
  _value?: Maybe<Element>;
  _comparator?: Maybe<Element>;
  _unit?: Maybe<Element>;
  _system?: Maybe<Element>;
  _code?: Maybe<Element>;
};

/** A length of time. */
export type DurationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A length of time. */
export type Duration_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A length of time. */
export type Duration_ComparatorArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A length of time. */
export type Duration_UnitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A length of time. */
export type Duration_SystemArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A length of time. */
export type Duration_CodeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
export enum DurationComparator {
  Lt = 'lt',
  Lte = 'lte',
  Gte = 'gte',
  Gt = 'gt',
}

export type DurationConnectFieldInput = {
  where?: Maybe<DurationWhere>;
  connect?: Maybe<DurationConnectInput>;
};

export type DurationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _value?: Maybe<ElementConnectFieldInput>;
  _comparator?: Maybe<ElementConnectFieldInput>;
  _unit?: Maybe<ElementConnectFieldInput>;
  _system?: Maybe<ElementConnectFieldInput>;
  _code?: Maybe<ElementConnectFieldInput>;
};

export type DurationCreateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<DurationComparator>;
  extension?: Maybe<DurationExtensionFieldInput>;
  _value?: Maybe<Duration_ValueFieldInput>;
  _comparator?: Maybe<Duration_ComparatorFieldInput>;
  _unit?: Maybe<Duration_UnitFieldInput>;
  _system?: Maybe<Duration_SystemFieldInput>;
  _code?: Maybe<Duration_CodeFieldInput>;
};

export type DurationDeleteFieldInput = {
  where?: Maybe<DurationWhere>;
  delete?: Maybe<DurationDeleteInput>;
};

export type DurationDeleteInput = {
  extension?: Maybe<Array<DurationExtensionDeleteFieldInput>>;
  _value?: Maybe<Duration_ValueDeleteFieldInput>;
  _comparator?: Maybe<Duration_ComparatorDeleteFieldInput>;
  _unit?: Maybe<Duration_UnitDeleteFieldInput>;
  _system?: Maybe<Duration_SystemDeleteFieldInput>;
  _code?: Maybe<Duration_CodeDeleteFieldInput>;
};

export type DurationDisconnectFieldInput = {
  where?: Maybe<DurationWhere>;
  disconnect?: Maybe<DurationDisconnectInput>;
};

export type DurationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _value?: Maybe<ElementDisconnectFieldInput>;
  _comparator?: Maybe<ElementDisconnectFieldInput>;
  _unit?: Maybe<ElementDisconnectFieldInput>;
  _system?: Maybe<ElementDisconnectFieldInput>;
  _code?: Maybe<ElementDisconnectFieldInput>;
};

export type DurationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type DurationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type DurationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type DurationOptions = {
  /** Specify one or more DurationSort objects to sort Durations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<DurationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type DurationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _value?: Maybe<ElementCreateInput>;
  _comparator?: Maybe<ElementCreateInput>;
  _unit?: Maybe<ElementCreateInput>;
  _system?: Maybe<ElementCreateInput>;
  _code?: Maybe<ElementCreateInput>;
};

/** Fields to sort Durations by. The order in which sorts are applied is not guaranteed when specifying many fields in one DurationSort object. */
export type DurationSort = {
  id?: Maybe<SortDirection>;
  comparator?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  unit?: Maybe<SortDirection>;
  system?: Maybe<SortDirection>;
  code?: Maybe<SortDirection>;
};

export type DurationUpdateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<DurationComparator>;
  extension?: Maybe<Array<DurationExtensionUpdateFieldInput>>;
  _value?: Maybe<Duration_ValueUpdateFieldInput>;
  _comparator?: Maybe<Duration_ComparatorUpdateFieldInput>;
  _unit?: Maybe<Duration_UnitUpdateFieldInput>;
  _system?: Maybe<Duration_SystemUpdateFieldInput>;
  _code?: Maybe<Duration_CodeUpdateFieldInput>;
};

export type DurationWhere = {
  OR?: Maybe<Array<DurationWhere>>;
  AND?: Maybe<Array<DurationWhere>>;
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  comparator?: Maybe<DurationComparator>;
  comparator_NOT?: Maybe<DurationComparator>;
  comparator_IN?: Maybe<Array<Maybe<DurationComparator>>>;
  comparator_NOT_IN?: Maybe<Array<Maybe<DurationComparator>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
  _comparator?: Maybe<ElementWhere>;
  _comparator_NOT?: Maybe<ElementWhere>;
  _comparator_IN?: Maybe<Array<ElementWhere>>;
  _comparator_NOT_IN?: Maybe<Array<ElementWhere>>;
  _unit?: Maybe<ElementWhere>;
  _unit_NOT?: Maybe<ElementWhere>;
  _unit_IN?: Maybe<Array<ElementWhere>>;
  _unit_NOT_IN?: Maybe<Array<ElementWhere>>;
  _system?: Maybe<ElementWhere>;
  _system_NOT?: Maybe<ElementWhere>;
  _system_IN?: Maybe<Array<ElementWhere>>;
  _system_NOT_IN?: Maybe<Array<ElementWhere>>;
  _code?: Maybe<ElementWhere>;
  _code_NOT?: Maybe<ElementWhere>;
  _code_IN?: Maybe<Array<ElementWhere>>;
  _code_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Duration_CodeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Duration_CodeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Duration_CodeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Duration_ComparatorDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Duration_ComparatorFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Duration_ComparatorUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Duration_SystemDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Duration_SystemFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Duration_SystemUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Duration_UnitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Duration_UnitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Duration_UnitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Duration_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Duration_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Duration_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Base definition for all elements in a resource. */
export type Element = {
  __typename?: 'Element';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
};

/** Base definition for all elements in a resource. */
export type ElementExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

export type ElementConnectFieldInput = {
  where?: Maybe<ElementWhere>;
  connect?: Maybe<ElementConnectInput>;
};

export type ElementConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ElementCreateInput = {
  extension?: Maybe<ElementExtensionFieldInput>;
};

export type ElementDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ElementDeleteInput = {
  extension?: Maybe<Array<ElementExtensionDeleteFieldInput>>;
};

export type ElementDisconnectFieldInput = {
  where?: Maybe<ElementWhere>;
  disconnect?: Maybe<ElementDisconnectInput>;
};

export type ElementDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
};

export type ElementExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ElementExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ElementExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ElementOptions = {
  /** Specify one or more ElementSort objects to sort Elements by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ElementSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ElementRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
};

/** Fields to sort Elements by. The order in which sorts are applied is not guaranteed when specifying many fields in one ElementSort object. */
export type ElementSort = {
  id?: Maybe<SortDirection>;
};

export type ElementUpdateInput = {
  extension?: Maybe<Array<ElementExtensionUpdateFieldInput>>;
};

export type ElementWhere = {
  OR?: Maybe<Array<ElementWhere>>;
  AND?: Maybe<Array<ElementWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type Encounter = {
  __typename?: 'Encounter';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a Encounter resource */
  resourceType: EncounterResourceType;
  /** planned | arrived | triaged | in-progress | onleave | finished | cancelled +. */
  status?: Maybe<EncounterStatus>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _status?: Maybe<Element>;
  statusHistory: Array<EncounterStatusHistory>;
  class: Coding;
  classHistory: Array<EncounterClassHistory>;
  type: Array<CodeableConcept>;
  serviceType?: Maybe<CodeableConcept>;
  priority?: Maybe<CodeableConcept>;
  subject?: Maybe<Reference>;
  episodeOfCare: Array<Reference>;
  basedOn: Array<Reference>;
  participant: Array<EncounterParticipant>;
  appointment: Array<Reference>;
  period?: Maybe<Period>;
  length?: Maybe<Duration>;
  reasonCode: Array<CodeableConcept>;
  reasonReference: Array<Reference>;
  diagnosis: Array<EncounterDiagnosis>;
  account: Array<Reference>;
  hospitalization?: Maybe<EncounterHospitalization>;
  location: Array<EncounterLocation>;
  serviceProvider?: Maybe<Reference>;
  partOf?: Maybe<Reference>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type Encounter_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type Encounter_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type Encounter_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterStatusHistoryArgs = {
  where?: Maybe<EncounterStatusHistoryWhere>;
  options?: Maybe<EncounterStatusHistoryOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterClassArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterClassHistoryArgs = {
  where?: Maybe<EncounterClassHistoryWhere>;
  options?: Maybe<EncounterClassHistoryOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterServiceTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterPriorityArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterSubjectArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterEpisodeOfCareArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterBasedOnArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterParticipantArgs = {
  where?: Maybe<EncounterParticipantWhere>;
  options?: Maybe<EncounterParticipantOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterAppointmentArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterLengthArgs = {
  where?: Maybe<DurationWhere>;
  options?: Maybe<DurationOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterReasonCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterReasonReferenceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterDiagnosisArgs = {
  where?: Maybe<EncounterDiagnosisWhere>;
  options?: Maybe<EncounterDiagnosisOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterAccountArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationArgs = {
  where?: Maybe<EncounterHospitalizationWhere>;
  options?: Maybe<EncounterHospitalizationOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterLocationArgs = {
  where?: Maybe<EncounterLocationWhere>;
  options?: Maybe<EncounterLocationOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterServiceProviderArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterPartOfArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type EncounterAccountDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterAccountFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type EncounterAccountUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type EncounterAppointmentDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterAppointmentFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type EncounterAppointmentUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type EncounterBasedonDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterBasedonFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type EncounterBasedonUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type EncounterClassDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type EncounterClassFieldInput = {
  create?: Maybe<CodingCreateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterClassHistory = {
  __typename?: 'EncounterClassHistory';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  class: Coding;
  period: Period;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterClassHistoryExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterClassHistoryModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterClassHistoryClassArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterClassHistoryPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type EncounterClassHistoryClassDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type EncounterClassHistoryClassFieldInput = {
  create?: Maybe<CodingCreateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
};

export type EncounterClassHistoryClassUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
  disconnect?: Maybe<CodingDisconnectFieldInput>;
  create?: Maybe<CodingCreateInput>;
  delete?: Maybe<CodingDeleteFieldInput>;
};

export type EncounterClassHistoryConnectFieldInput = {
  where?: Maybe<EncounterClassHistoryWhere>;
  connect?: Maybe<EncounterClassHistoryConnectInput>;
};

export type EncounterClassHistoryConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  class?: Maybe<CodingConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type EncounterClassHistoryCreateInput = {
  extension?: Maybe<EncounterClassHistoryExtensionFieldInput>;
  modifierExtension?: Maybe<EncounterClassHistoryModifierextensionFieldInput>;
  class?: Maybe<EncounterClassHistoryClassFieldInput>;
  period?: Maybe<EncounterClassHistoryPeriodFieldInput>;
};

export type EncounterClassHistoryDeleteFieldInput = {
  where?: Maybe<EncounterClassHistoryWhere>;
  delete?: Maybe<EncounterClassHistoryDeleteInput>;
};

export type EncounterClassHistoryDeleteInput = {
  extension?: Maybe<Array<EncounterClassHistoryExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterClassHistoryModifierextensionDeleteFieldInput>>;
  class?: Maybe<EncounterClassHistoryClassDeleteFieldInput>;
  period?: Maybe<EncounterClassHistoryPeriodDeleteFieldInput>;
};

export type EncounterClassHistoryDisconnectFieldInput = {
  where?: Maybe<EncounterClassHistoryWhere>;
  disconnect?: Maybe<EncounterClassHistoryDisconnectInput>;
};

export type EncounterClassHistoryDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  class?: Maybe<CodingDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type EncounterClassHistoryExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterClassHistoryExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterClassHistoryExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterClassHistoryModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterClassHistoryModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterClassHistoryModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterClassHistoryOptions = {
  /** Specify one or more EncounterClassHistorySort objects to sort EncounterClassHistories by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EncounterClassHistorySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EncounterClassHistoryPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type EncounterClassHistoryPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type EncounterClassHistoryPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type EncounterClassHistoryRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  class?: Maybe<CodingCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

/** Fields to sort EncounterClassHistories by. The order in which sorts are applied is not guaranteed when specifying many fields in one EncounterClassHistorySort object. */
export type EncounterClassHistorySort = {
  id?: Maybe<SortDirection>;
};

export type EncounterClassHistoryUpdateInput = {
  extension?: Maybe<Array<EncounterClassHistoryExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterClassHistoryModifierextensionUpdateFieldInput>>;
  class?: Maybe<EncounterClassHistoryClassUpdateFieldInput>;
  period?: Maybe<EncounterClassHistoryPeriodUpdateFieldInput>;
};

export type EncounterClassHistoryWhere = {
  OR?: Maybe<Array<EncounterClassHistoryWhere>>;
  AND?: Maybe<Array<EncounterClassHistoryWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  class?: Maybe<CodingWhere>;
  class_NOT?: Maybe<CodingWhere>;
  class_IN?: Maybe<Array<CodingWhere>>;
  class_NOT_IN?: Maybe<Array<CodingWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type EncounterClassUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
  disconnect?: Maybe<CodingDisconnectFieldInput>;
  create?: Maybe<CodingCreateInput>;
  delete?: Maybe<CodingDeleteFieldInput>;
};

export type EncounterClasshistoryDeleteFieldInput = {
  where?: Maybe<EncounterClassHistoryWhere>;
  delete?: Maybe<EncounterClassHistoryDeleteInput>;
};

export type EncounterClasshistoryFieldInput = {
  create?: Maybe<Array<EncounterClassHistoryCreateInput>>;
  connect?: Maybe<Array<EncounterClassHistoryConnectFieldInput>>;
};

export type EncounterClasshistoryUpdateFieldInput = {
  where?: Maybe<EncounterClassHistoryWhere>;
  update?: Maybe<EncounterClassHistoryUpdateInput>;
  connect?: Maybe<Array<EncounterClassHistoryConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterClassHistoryDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterClassHistoryCreateInput>>;
  delete?: Maybe<Array<EncounterClassHistoryDeleteFieldInput>>;
};

export type EncounterConnectFieldInput = {
  where?: Maybe<EncounterWhere>;
  connect?: Maybe<EncounterConnectInput>;
};

export type EncounterConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  statusHistory?: Maybe<Array<EncounterStatusHistoryConnectFieldInput>>;
  class?: Maybe<CodingConnectFieldInput>;
  classHistory?: Maybe<Array<EncounterClassHistoryConnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  serviceType?: Maybe<CodeableConceptConnectFieldInput>;
  priority?: Maybe<CodeableConceptConnectFieldInput>;
  subject?: Maybe<ReferenceConnectFieldInput>;
  episodeOfCare?: Maybe<Array<ReferenceConnectFieldInput>>;
  basedOn?: Maybe<Array<ReferenceConnectFieldInput>>;
  participant?: Maybe<Array<EncounterParticipantConnectFieldInput>>;
  appointment?: Maybe<Array<ReferenceConnectFieldInput>>;
  period?: Maybe<PeriodConnectFieldInput>;
  length?: Maybe<DurationConnectFieldInput>;
  reasonCode?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  reasonReference?: Maybe<Array<ReferenceConnectFieldInput>>;
  diagnosis?: Maybe<Array<EncounterDiagnosisConnectFieldInput>>;
  account?: Maybe<Array<ReferenceConnectFieldInput>>;
  hospitalization?: Maybe<EncounterHospitalizationConnectFieldInput>;
  location?: Maybe<Array<EncounterLocationConnectFieldInput>>;
  serviceProvider?: Maybe<ReferenceConnectFieldInput>;
  partOf?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type EncounterContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type EncounterContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type EncounterContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type EncounterContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type EncounterContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type EncounterContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type EncounterContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type EncounterContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type EncounterContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type EncounterContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type EncounterContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type EncounterContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type EncounterContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type EncounterContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type EncounterContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type EncounterContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type EncounterContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type EncounterContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type EncounterContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type EncounterContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type EncounterContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type EncounterContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type EncounterContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type EncounterContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type EncounterContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type EncounterContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type EncounterContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type EncounterContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type EncounterContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type EncounterContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type EncounterContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type EncounterContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type EncounterContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type EncounterContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type EncounterContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type EncounterContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type EncounterContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type EncounterContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type EncounterContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type EncounterContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type EncounterContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type EncounterContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type EncounterContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type EncounterContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type EncounterContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type EncounterContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type EncounterContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type EncounterContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type EncounterContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type EncounterContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type EncounterContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type EncounterContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type EncounterContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type EncounterContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type EncounterContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type EncounterContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type EncounterContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type EncounterContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type EncounterContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type EncounterContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type EncounterContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type EncounterContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type EncounterCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  resourceType: EncounterResourceType;
  status?: Maybe<EncounterStatus>;
  meta?: Maybe<EncounterMetaFieldInput>;
  _implicitRules?: Maybe<Encounter_ImplicitrulesFieldInput>;
  _language?: Maybe<Encounter_LanguageFieldInput>;
  text?: Maybe<EncounterTextFieldInput>;
  contained_Account?: Maybe<EncounterContainedAccountFieldInput>;
  contained_Appointment?: Maybe<EncounterContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<EncounterContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<EncounterContainedCoverageFieldInput>;
  contained_Device?: Maybe<EncounterContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<EncounterContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<EncounterContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<EncounterContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<EncounterContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<EncounterContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<EncounterContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<EncounterContainedLocationFieldInput>;
  contained_Organization?: Maybe<EncounterContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<EncounterContainedPatientFieldInput>;
  contained_Person?: Maybe<EncounterContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<EncounterContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<EncounterContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<EncounterContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<EncounterContainedScheduleFieldInput>;
  contained_Slot?: Maybe<EncounterContainedSlotFieldInput>;
  contained_Substance?: Maybe<EncounterContainedSubstanceFieldInput>;
  extension?: Maybe<EncounterExtensionFieldInput>;
  modifierExtension?: Maybe<EncounterModifierextensionFieldInput>;
  identifier?: Maybe<EncounterIdentifierFieldInput>;
  _status?: Maybe<Encounter_StatusFieldInput>;
  statusHistory?: Maybe<EncounterStatushistoryFieldInput>;
  class?: Maybe<EncounterClassFieldInput>;
  classHistory?: Maybe<EncounterClasshistoryFieldInput>;
  type?: Maybe<EncounterTypeFieldInput>;
  serviceType?: Maybe<EncounterServicetypeFieldInput>;
  priority?: Maybe<EncounterPriorityFieldInput>;
  subject?: Maybe<EncounterSubjectFieldInput>;
  episodeOfCare?: Maybe<EncounterEpisodeofcareFieldInput>;
  basedOn?: Maybe<EncounterBasedonFieldInput>;
  participant?: Maybe<EncounterParticipantFieldInput>;
  appointment?: Maybe<EncounterAppointmentFieldInput>;
  period?: Maybe<EncounterPeriodFieldInput>;
  length?: Maybe<EncounterLengthFieldInput>;
  reasonCode?: Maybe<EncounterReasoncodeFieldInput>;
  reasonReference?: Maybe<EncounterReasonreferenceFieldInput>;
  diagnosis?: Maybe<EncounterDiagnosisFieldInput>;
  account?: Maybe<EncounterAccountFieldInput>;
  hospitalization?: Maybe<EncounterHospitalizationFieldInput>;
  location?: Maybe<EncounterLocationFieldInput>;
  serviceProvider?: Maybe<EncounterServiceproviderFieldInput>;
  partOf?: Maybe<EncounterPartofFieldInput>;
};

export type EncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type EncounterDeleteInput = {
  meta?: Maybe<EncounterMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Encounter_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Encounter_LanguageDeleteFieldInput>;
  text?: Maybe<EncounterTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<EncounterContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<EncounterContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<EncounterContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<EncounterContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<EncounterContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<EncounterContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<EncounterContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<EncounterContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EncounterContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<EncounterContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<EncounterContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<EncounterContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<EncounterContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<EncounterContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<EncounterContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<EncounterContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<EncounterContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<EncounterContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<EncounterContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<EncounterContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<EncounterExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<EncounterIdentifierDeleteFieldInput>>;
  _status?: Maybe<Encounter_StatusDeleteFieldInput>;
  statusHistory?: Maybe<Array<EncounterStatushistoryDeleteFieldInput>>;
  class?: Maybe<EncounterClassDeleteFieldInput>;
  classHistory?: Maybe<Array<EncounterClasshistoryDeleteFieldInput>>;
  type?: Maybe<Array<EncounterTypeDeleteFieldInput>>;
  serviceType?: Maybe<EncounterServicetypeDeleteFieldInput>;
  priority?: Maybe<EncounterPriorityDeleteFieldInput>;
  subject?: Maybe<EncounterSubjectDeleteFieldInput>;
  episodeOfCare?: Maybe<Array<EncounterEpisodeofcareDeleteFieldInput>>;
  basedOn?: Maybe<Array<EncounterBasedonDeleteFieldInput>>;
  participant?: Maybe<Array<EncounterParticipantDeleteFieldInput>>;
  appointment?: Maybe<Array<EncounterAppointmentDeleteFieldInput>>;
  period?: Maybe<EncounterPeriodDeleteFieldInput>;
  length?: Maybe<EncounterLengthDeleteFieldInput>;
  reasonCode?: Maybe<Array<EncounterReasoncodeDeleteFieldInput>>;
  reasonReference?: Maybe<Array<EncounterReasonreferenceDeleteFieldInput>>;
  diagnosis?: Maybe<Array<EncounterDiagnosisDeleteFieldInput>>;
  account?: Maybe<Array<EncounterAccountDeleteFieldInput>>;
  hospitalization?: Maybe<EncounterHospitalizationDeleteFieldInput>;
  location?: Maybe<Array<EncounterLocationDeleteFieldInput>>;
  serviceProvider?: Maybe<EncounterServiceproviderDeleteFieldInput>;
  partOf?: Maybe<EncounterPartofDeleteFieldInput>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterDiagnosis = {
  __typename?: 'EncounterDiagnosis';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Ranking of the diagnosis (for each role type). */
  rank?: Maybe<Scalars['PositiveInt']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  condition: Reference;
  use?: Maybe<CodeableConcept>;
  _rank?: Maybe<Element>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterDiagnosisExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterDiagnosisModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterDiagnosisConditionArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterDiagnosisUseArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterDiagnosis_RankArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type EncounterDiagnosisConditionDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterDiagnosisConditionFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterDiagnosisConditionUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EncounterDiagnosisConnectFieldInput = {
  where?: Maybe<EncounterDiagnosisWhere>;
  connect?: Maybe<EncounterDiagnosisConnectInput>;
};

export type EncounterDiagnosisConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  condition?: Maybe<ReferenceConnectFieldInput>;
  use?: Maybe<CodeableConceptConnectFieldInput>;
  _rank?: Maybe<ElementConnectFieldInput>;
};

export type EncounterDiagnosisCreateInput = {
  rank?: Maybe<Scalars['PositiveInt']>;
  extension?: Maybe<EncounterDiagnosisExtensionFieldInput>;
  modifierExtension?: Maybe<EncounterDiagnosisModifierextensionFieldInput>;
  condition?: Maybe<EncounterDiagnosisConditionFieldInput>;
  use?: Maybe<EncounterDiagnosisUseFieldInput>;
  _rank?: Maybe<EncounterDiagnosis_RankFieldInput>;
};

export type EncounterDiagnosisDeleteFieldInput = {
  where?: Maybe<EncounterDiagnosisWhere>;
  delete?: Maybe<EncounterDiagnosisDeleteInput>;
};

export type EncounterDiagnosisDeleteInput = {
  extension?: Maybe<Array<EncounterDiagnosisExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterDiagnosisModifierextensionDeleteFieldInput>>;
  condition?: Maybe<EncounterDiagnosisConditionDeleteFieldInput>;
  use?: Maybe<EncounterDiagnosisUseDeleteFieldInput>;
  _rank?: Maybe<EncounterDiagnosis_RankDeleteFieldInput>;
};

export type EncounterDiagnosisDisconnectFieldInput = {
  where?: Maybe<EncounterDiagnosisWhere>;
  disconnect?: Maybe<EncounterDiagnosisDisconnectInput>;
};

export type EncounterDiagnosisDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  condition?: Maybe<ReferenceDisconnectFieldInput>;
  use?: Maybe<CodeableConceptDisconnectFieldInput>;
  _rank?: Maybe<ElementDisconnectFieldInput>;
};

export type EncounterDiagnosisExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterDiagnosisExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterDiagnosisExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterDiagnosisFieldInput = {
  create?: Maybe<Array<EncounterDiagnosisCreateInput>>;
  connect?: Maybe<Array<EncounterDiagnosisConnectFieldInput>>;
};

export type EncounterDiagnosisModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterDiagnosisModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterDiagnosisModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterDiagnosisOptions = {
  /** Specify one or more EncounterDiagnosisSort objects to sort EncounterDiagnoses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EncounterDiagnosisSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EncounterDiagnosisRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  condition?: Maybe<ReferenceCreateInput>;
  use?: Maybe<CodeableConceptCreateInput>;
  _rank?: Maybe<ElementCreateInput>;
};

/** Fields to sort EncounterDiagnoses by. The order in which sorts are applied is not guaranteed when specifying many fields in one EncounterDiagnosisSort object. */
export type EncounterDiagnosisSort = {
  id?: Maybe<SortDirection>;
  rank?: Maybe<SortDirection>;
};

export type EncounterDiagnosisUpdateFieldInput = {
  where?: Maybe<EncounterDiagnosisWhere>;
  update?: Maybe<EncounterDiagnosisUpdateInput>;
  connect?: Maybe<Array<EncounterDiagnosisConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDiagnosisDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterDiagnosisCreateInput>>;
  delete?: Maybe<Array<EncounterDiagnosisDeleteFieldInput>>;
};

export type EncounterDiagnosisUpdateInput = {
  rank?: Maybe<Scalars['PositiveInt']>;
  extension?: Maybe<Array<EncounterDiagnosisExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterDiagnosisModifierextensionUpdateFieldInput>>;
  condition?: Maybe<EncounterDiagnosisConditionUpdateFieldInput>;
  use?: Maybe<EncounterDiagnosisUseUpdateFieldInput>;
  _rank?: Maybe<EncounterDiagnosis_RankUpdateFieldInput>;
};

export type EncounterDiagnosisUseDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterDiagnosisUseFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type EncounterDiagnosisUseUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type EncounterDiagnosisWhere = {
  OR?: Maybe<Array<EncounterDiagnosisWhere>>;
  AND?: Maybe<Array<EncounterDiagnosisWhere>>;
  rank?: Maybe<Scalars['PositiveInt']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  condition?: Maybe<ReferenceWhere>;
  condition_NOT?: Maybe<ReferenceWhere>;
  condition_IN?: Maybe<Array<ReferenceWhere>>;
  condition_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  use?: Maybe<CodeableConceptWhere>;
  use_NOT?: Maybe<CodeableConceptWhere>;
  use_IN?: Maybe<Array<CodeableConceptWhere>>;
  use_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _rank?: Maybe<ElementWhere>;
  _rank_NOT?: Maybe<ElementWhere>;
  _rank_IN?: Maybe<Array<ElementWhere>>;
  _rank_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type EncounterDiagnosis_RankDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type EncounterDiagnosis_RankFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type EncounterDiagnosis_RankUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type EncounterDisconnectFieldInput = {
  where?: Maybe<EncounterWhere>;
  disconnect?: Maybe<EncounterDisconnectInput>;
};

export type EncounterDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  statusHistory?: Maybe<Array<EncounterStatusHistoryDisconnectFieldInput>>;
  class?: Maybe<CodingDisconnectFieldInput>;
  classHistory?: Maybe<Array<EncounterClassHistoryDisconnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  serviceType?: Maybe<CodeableConceptDisconnectFieldInput>;
  priority?: Maybe<CodeableConceptDisconnectFieldInput>;
  subject?: Maybe<ReferenceDisconnectFieldInput>;
  episodeOfCare?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  basedOn?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  participant?: Maybe<Array<EncounterParticipantDisconnectFieldInput>>;
  appointment?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  period?: Maybe<PeriodDisconnectFieldInput>;
  length?: Maybe<DurationDisconnectFieldInput>;
  reasonCode?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  reasonReference?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  diagnosis?: Maybe<Array<EncounterDiagnosisDisconnectFieldInput>>;
  account?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  hospitalization?: Maybe<EncounterHospitalizationDisconnectFieldInput>;
  location?: Maybe<Array<EncounterLocationDisconnectFieldInput>>;
  serviceProvider?: Maybe<ReferenceDisconnectFieldInput>;
  partOf?: Maybe<ReferenceDisconnectFieldInput>;
};

export type EncounterEpisodeofcareDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterEpisodeofcareFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type EncounterEpisodeofcareUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type EncounterExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalization = {
  __typename?: 'EncounterHospitalization';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  preAdmissionIdentifier?: Maybe<Identifier>;
  origin?: Maybe<Reference>;
  admitSource?: Maybe<CodeableConcept>;
  reAdmission?: Maybe<CodeableConcept>;
  dietPreference: Array<CodeableConcept>;
  specialCourtesy: Array<CodeableConcept>;
  specialArrangement: Array<CodeableConcept>;
  destination?: Maybe<Reference>;
  dischargeDisposition?: Maybe<CodeableConcept>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationPreAdmissionIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationOriginArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationAdmitSourceArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationReAdmissionArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationDietPreferenceArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationSpecialCourtesyArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationSpecialArrangementArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationDestinationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterHospitalizationDischargeDispositionArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

export type EncounterHospitalizationAdmitsourceDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterHospitalizationAdmitsourceFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type EncounterHospitalizationAdmitsourceUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type EncounterHospitalizationConnectFieldInput = {
  where?: Maybe<EncounterHospitalizationWhere>;
  connect?: Maybe<EncounterHospitalizationConnectInput>;
};

export type EncounterHospitalizationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  preAdmissionIdentifier?: Maybe<IdentifierConnectFieldInput>;
  origin?: Maybe<ReferenceConnectFieldInput>;
  admitSource?: Maybe<CodeableConceptConnectFieldInput>;
  reAdmission?: Maybe<CodeableConceptConnectFieldInput>;
  dietPreference?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  specialCourtesy?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  specialArrangement?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  destination?: Maybe<ReferenceConnectFieldInput>;
  dischargeDisposition?: Maybe<CodeableConceptConnectFieldInput>;
};

export type EncounterHospitalizationCreateInput = {
  extension?: Maybe<EncounterHospitalizationExtensionFieldInput>;
  modifierExtension?: Maybe<EncounterHospitalizationModifierextensionFieldInput>;
  preAdmissionIdentifier?: Maybe<EncounterHospitalizationPreadmissionidentifierFieldInput>;
  origin?: Maybe<EncounterHospitalizationOriginFieldInput>;
  admitSource?: Maybe<EncounterHospitalizationAdmitsourceFieldInput>;
  reAdmission?: Maybe<EncounterHospitalizationReadmissionFieldInput>;
  dietPreference?: Maybe<EncounterHospitalizationDietpreferenceFieldInput>;
  specialCourtesy?: Maybe<EncounterHospitalizationSpecialcourtesyFieldInput>;
  specialArrangement?: Maybe<EncounterHospitalizationSpecialarrangementFieldInput>;
  destination?: Maybe<EncounterHospitalizationDestinationFieldInput>;
  dischargeDisposition?: Maybe<EncounterHospitalizationDischargedispositionFieldInput>;
};

export type EncounterHospitalizationDeleteFieldInput = {
  where?: Maybe<EncounterHospitalizationWhere>;
  delete?: Maybe<EncounterHospitalizationDeleteInput>;
};

export type EncounterHospitalizationDeleteInput = {
  extension?: Maybe<Array<EncounterHospitalizationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterHospitalizationModifierextensionDeleteFieldInput>>;
  preAdmissionIdentifier?: Maybe<EncounterHospitalizationPreadmissionidentifierDeleteFieldInput>;
  origin?: Maybe<EncounterHospitalizationOriginDeleteFieldInput>;
  admitSource?: Maybe<EncounterHospitalizationAdmitsourceDeleteFieldInput>;
  reAdmission?: Maybe<EncounterHospitalizationReadmissionDeleteFieldInput>;
  dietPreference?: Maybe<Array<EncounterHospitalizationDietpreferenceDeleteFieldInput>>;
  specialCourtesy?: Maybe<Array<EncounterHospitalizationSpecialcourtesyDeleteFieldInput>>;
  specialArrangement?: Maybe<Array<EncounterHospitalizationSpecialarrangementDeleteFieldInput>>;
  destination?: Maybe<EncounterHospitalizationDestinationDeleteFieldInput>;
  dischargeDisposition?: Maybe<EncounterHospitalizationDischargedispositionDeleteFieldInput>;
};

export type EncounterHospitalizationDestinationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterHospitalizationDestinationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterHospitalizationDestinationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EncounterHospitalizationDietpreferenceDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterHospitalizationDietpreferenceFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type EncounterHospitalizationDietpreferenceUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type EncounterHospitalizationDischargedispositionDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterHospitalizationDischargedispositionFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type EncounterHospitalizationDischargedispositionUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type EncounterHospitalizationDisconnectFieldInput = {
  where?: Maybe<EncounterHospitalizationWhere>;
  disconnect?: Maybe<EncounterHospitalizationDisconnectInput>;
};

export type EncounterHospitalizationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  preAdmissionIdentifier?: Maybe<IdentifierDisconnectFieldInput>;
  origin?: Maybe<ReferenceDisconnectFieldInput>;
  admitSource?: Maybe<CodeableConceptDisconnectFieldInput>;
  reAdmission?: Maybe<CodeableConceptDisconnectFieldInput>;
  dietPreference?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  specialCourtesy?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  specialArrangement?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  destination?: Maybe<ReferenceDisconnectFieldInput>;
  dischargeDisposition?: Maybe<CodeableConceptDisconnectFieldInput>;
};

export type EncounterHospitalizationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterHospitalizationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterHospitalizationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterHospitalizationFieldInput = {
  create?: Maybe<EncounterHospitalizationCreateInput>;
  connect?: Maybe<EncounterHospitalizationConnectFieldInput>;
};

export type EncounterHospitalizationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterHospitalizationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterHospitalizationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterHospitalizationOptions = {
  /** Specify one or more EncounterHospitalizationSort objects to sort EncounterHospitalizations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EncounterHospitalizationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EncounterHospitalizationOriginDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterHospitalizationOriginFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterHospitalizationOriginUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EncounterHospitalizationPreadmissionidentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type EncounterHospitalizationPreadmissionidentifierFieldInput = {
  create?: Maybe<IdentifierCreateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
};

export type EncounterHospitalizationPreadmissionidentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
  disconnect?: Maybe<IdentifierDisconnectFieldInput>;
  create?: Maybe<IdentifierCreateInput>;
  delete?: Maybe<IdentifierDeleteFieldInput>;
};

export type EncounterHospitalizationReadmissionDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterHospitalizationReadmissionFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type EncounterHospitalizationReadmissionUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type EncounterHospitalizationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  preAdmissionIdentifier?: Maybe<IdentifierCreateInput>;
  origin?: Maybe<ReferenceCreateInput>;
  admitSource?: Maybe<CodeableConceptCreateInput>;
  reAdmission?: Maybe<CodeableConceptCreateInput>;
  dietPreference?: Maybe<Array<CodeableConceptCreateInput>>;
  specialCourtesy?: Maybe<Array<CodeableConceptCreateInput>>;
  specialArrangement?: Maybe<Array<CodeableConceptCreateInput>>;
  destination?: Maybe<ReferenceCreateInput>;
  dischargeDisposition?: Maybe<CodeableConceptCreateInput>;
};

/** Fields to sort EncounterHospitalizations by. The order in which sorts are applied is not guaranteed when specifying many fields in one EncounterHospitalizationSort object. */
export type EncounterHospitalizationSort = {
  id?: Maybe<SortDirection>;
};

export type EncounterHospitalizationSpecialarrangementDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterHospitalizationSpecialarrangementFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type EncounterHospitalizationSpecialarrangementUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type EncounterHospitalizationSpecialcourtesyDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterHospitalizationSpecialcourtesyFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type EncounterHospitalizationSpecialcourtesyUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type EncounterHospitalizationUpdateFieldInput = {
  where?: Maybe<EncounterHospitalizationWhere>;
  update?: Maybe<EncounterHospitalizationUpdateInput>;
  connect?: Maybe<EncounterHospitalizationConnectFieldInput>;
  disconnect?: Maybe<EncounterHospitalizationDisconnectFieldInput>;
  create?: Maybe<EncounterHospitalizationCreateInput>;
  delete?: Maybe<EncounterHospitalizationDeleteFieldInput>;
};

export type EncounterHospitalizationUpdateInput = {
  extension?: Maybe<Array<EncounterHospitalizationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterHospitalizationModifierextensionUpdateFieldInput>>;
  preAdmissionIdentifier?: Maybe<EncounterHospitalizationPreadmissionidentifierUpdateFieldInput>;
  origin?: Maybe<EncounterHospitalizationOriginUpdateFieldInput>;
  admitSource?: Maybe<EncounterHospitalizationAdmitsourceUpdateFieldInput>;
  reAdmission?: Maybe<EncounterHospitalizationReadmissionUpdateFieldInput>;
  dietPreference?: Maybe<Array<EncounterHospitalizationDietpreferenceUpdateFieldInput>>;
  specialCourtesy?: Maybe<Array<EncounterHospitalizationSpecialcourtesyUpdateFieldInput>>;
  specialArrangement?: Maybe<Array<EncounterHospitalizationSpecialarrangementUpdateFieldInput>>;
  destination?: Maybe<EncounterHospitalizationDestinationUpdateFieldInput>;
  dischargeDisposition?: Maybe<EncounterHospitalizationDischargedispositionUpdateFieldInput>;
};

export type EncounterHospitalizationWhere = {
  OR?: Maybe<Array<EncounterHospitalizationWhere>>;
  AND?: Maybe<Array<EncounterHospitalizationWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  preAdmissionIdentifier?: Maybe<IdentifierWhere>;
  preAdmissionIdentifier_NOT?: Maybe<IdentifierWhere>;
  preAdmissionIdentifier_IN?: Maybe<Array<IdentifierWhere>>;
  preAdmissionIdentifier_NOT_IN?: Maybe<Array<IdentifierWhere>>;
  origin?: Maybe<ReferenceWhere>;
  origin_NOT?: Maybe<ReferenceWhere>;
  origin_IN?: Maybe<Array<ReferenceWhere>>;
  origin_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  admitSource?: Maybe<CodeableConceptWhere>;
  admitSource_NOT?: Maybe<CodeableConceptWhere>;
  admitSource_IN?: Maybe<Array<CodeableConceptWhere>>;
  admitSource_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  reAdmission?: Maybe<CodeableConceptWhere>;
  reAdmission_NOT?: Maybe<CodeableConceptWhere>;
  reAdmission_IN?: Maybe<Array<CodeableConceptWhere>>;
  reAdmission_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  dietPreference?: Maybe<CodeableConceptWhere>;
  dietPreference_NOT?: Maybe<CodeableConceptWhere>;
  specialCourtesy?: Maybe<CodeableConceptWhere>;
  specialCourtesy_NOT?: Maybe<CodeableConceptWhere>;
  specialArrangement?: Maybe<CodeableConceptWhere>;
  specialArrangement_NOT?: Maybe<CodeableConceptWhere>;
  destination?: Maybe<ReferenceWhere>;
  destination_NOT?: Maybe<ReferenceWhere>;
  destination_IN?: Maybe<Array<ReferenceWhere>>;
  destination_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  dischargeDisposition?: Maybe<CodeableConceptWhere>;
  dischargeDisposition_NOT?: Maybe<CodeableConceptWhere>;
  dischargeDisposition_IN?: Maybe<Array<CodeableConceptWhere>>;
  dischargeDisposition_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
};

export type EncounterIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type EncounterIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type EncounterIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type EncounterLengthDeleteFieldInput = {
  where?: Maybe<DurationWhere>;
  delete?: Maybe<DurationDeleteInput>;
};

export type EncounterLengthFieldInput = {
  create?: Maybe<DurationCreateInput>;
  connect?: Maybe<DurationConnectFieldInput>;
};

export type EncounterLengthUpdateFieldInput = {
  where?: Maybe<DurationWhere>;
  update?: Maybe<DurationUpdateInput>;
  connect?: Maybe<DurationConnectFieldInput>;
  disconnect?: Maybe<DurationDisconnectFieldInput>;
  create?: Maybe<DurationCreateInput>;
  delete?: Maybe<DurationDeleteFieldInput>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterLocation = {
  __typename?: 'EncounterLocation';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The status of the participants' presence at the specified location during the period specified. If the participant is no longer at the location, then the period will have an end date/time. */
  status?: Maybe<EncounterLocationStatus>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  location: Reference;
  _status?: Maybe<Element>;
  physicalType?: Maybe<CodeableConcept>;
  period?: Maybe<Period>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterLocationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterLocationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterLocationLocationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterLocation_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterLocationPhysicalTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterLocationPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type EncounterLocationConnectFieldInput = {
  where?: Maybe<EncounterLocationWhere>;
  connect?: Maybe<EncounterLocationConnectInput>;
};

export type EncounterLocationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  location?: Maybe<ReferenceConnectFieldInput>;
  _status?: Maybe<ElementConnectFieldInput>;
  physicalType?: Maybe<CodeableConceptConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type EncounterLocationCreateInput = {
  status?: Maybe<EncounterLocationStatus>;
  extension?: Maybe<EncounterLocationExtensionFieldInput>;
  modifierExtension?: Maybe<EncounterLocationModifierextensionFieldInput>;
  location?: Maybe<EncounterLocationLocationFieldInput>;
  _status?: Maybe<EncounterLocation_StatusFieldInput>;
  physicalType?: Maybe<EncounterLocationPhysicaltypeFieldInput>;
  period?: Maybe<EncounterLocationPeriodFieldInput>;
};

export type EncounterLocationDeleteFieldInput = {
  where?: Maybe<EncounterLocationWhere>;
  delete?: Maybe<EncounterLocationDeleteInput>;
};

export type EncounterLocationDeleteInput = {
  extension?: Maybe<Array<EncounterLocationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterLocationModifierextensionDeleteFieldInput>>;
  location?: Maybe<EncounterLocationLocationDeleteFieldInput>;
  _status?: Maybe<EncounterLocation_StatusDeleteFieldInput>;
  physicalType?: Maybe<EncounterLocationPhysicaltypeDeleteFieldInput>;
  period?: Maybe<EncounterLocationPeriodDeleteFieldInput>;
};

export type EncounterLocationDisconnectFieldInput = {
  where?: Maybe<EncounterLocationWhere>;
  disconnect?: Maybe<EncounterLocationDisconnectInput>;
};

export type EncounterLocationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  location?: Maybe<ReferenceDisconnectFieldInput>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  physicalType?: Maybe<CodeableConceptDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type EncounterLocationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterLocationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterLocationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterLocationFieldInput = {
  create?: Maybe<Array<EncounterLocationCreateInput>>;
  connect?: Maybe<Array<EncounterLocationConnectFieldInput>>;
};

export type EncounterLocationLocationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterLocationLocationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterLocationLocationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EncounterLocationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterLocationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterLocationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterLocationOptions = {
  /** Specify one or more EncounterLocationSort objects to sort EncounterLocations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EncounterLocationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EncounterLocationPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type EncounterLocationPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type EncounterLocationPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type EncounterLocationPhysicaltypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterLocationPhysicaltypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type EncounterLocationPhysicaltypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type EncounterLocationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  location?: Maybe<ReferenceCreateInput>;
  _status?: Maybe<ElementCreateInput>;
  physicalType?: Maybe<CodeableConceptCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

/** Fields to sort EncounterLocations by. The order in which sorts are applied is not guaranteed when specifying many fields in one EncounterLocationSort object. */
export type EncounterLocationSort = {
  id?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
};

/** The status of the participants' presence at the specified location during the period specified. If the participant is no longer at the location, then the period will have an end date/time. */
export enum EncounterLocationStatus {
  Planned = 'planned',
  Active = 'active',
  Reserved = 'reserved',
  Completed = 'completed',
}

export type EncounterLocationUpdateFieldInput = {
  where?: Maybe<EncounterLocationWhere>;
  update?: Maybe<EncounterLocationUpdateInput>;
  connect?: Maybe<Array<EncounterLocationConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterLocationDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterLocationCreateInput>>;
  delete?: Maybe<Array<EncounterLocationDeleteFieldInput>>;
};

export type EncounterLocationUpdateInput = {
  status?: Maybe<EncounterLocationStatus>;
  extension?: Maybe<Array<EncounterLocationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterLocationModifierextensionUpdateFieldInput>>;
  location?: Maybe<EncounterLocationLocationUpdateFieldInput>;
  _status?: Maybe<EncounterLocation_StatusUpdateFieldInput>;
  physicalType?: Maybe<EncounterLocationPhysicaltypeUpdateFieldInput>;
  period?: Maybe<EncounterLocationPeriodUpdateFieldInput>;
};

export type EncounterLocationWhere = {
  OR?: Maybe<Array<EncounterLocationWhere>>;
  AND?: Maybe<Array<EncounterLocationWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  status?: Maybe<EncounterLocationStatus>;
  status_NOT?: Maybe<EncounterLocationStatus>;
  status_IN?: Maybe<Array<Maybe<EncounterLocationStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<EncounterLocationStatus>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  location?: Maybe<ReferenceWhere>;
  location_NOT?: Maybe<ReferenceWhere>;
  location_IN?: Maybe<Array<ReferenceWhere>>;
  location_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  physicalType?: Maybe<CodeableConceptWhere>;
  physicalType_NOT?: Maybe<CodeableConceptWhere>;
  physicalType_IN?: Maybe<Array<CodeableConceptWhere>>;
  physicalType_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type EncounterLocation_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type EncounterLocation_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type EncounterLocation_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type EncounterMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type EncounterMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type EncounterMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type EncounterModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterOptions = {
  /** Specify one or more EncounterSort objects to sort Encounters by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EncounterSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterParticipant = {
  __typename?: 'EncounterParticipant';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  type: Array<CodeableConcept>;
  period?: Maybe<Period>;
  individual?: Maybe<Reference>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterParticipantExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterParticipantModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterParticipantTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterParticipantPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterParticipantIndividualArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type EncounterParticipantConnectFieldInput = {
  where?: Maybe<EncounterParticipantWhere>;
  connect?: Maybe<EncounterParticipantConnectInput>;
};

export type EncounterParticipantConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  period?: Maybe<PeriodConnectFieldInput>;
  individual?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterParticipantCreateInput = {
  extension?: Maybe<EncounterParticipantExtensionFieldInput>;
  modifierExtension?: Maybe<EncounterParticipantModifierextensionFieldInput>;
  type?: Maybe<EncounterParticipantTypeFieldInput>;
  period?: Maybe<EncounterParticipantPeriodFieldInput>;
  individual?: Maybe<EncounterParticipantIndividualFieldInput>;
};

export type EncounterParticipantDeleteFieldInput = {
  where?: Maybe<EncounterParticipantWhere>;
  delete?: Maybe<EncounterParticipantDeleteInput>;
};

export type EncounterParticipantDeleteInput = {
  extension?: Maybe<Array<EncounterParticipantExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterParticipantModifierextensionDeleteFieldInput>>;
  type?: Maybe<Array<EncounterParticipantTypeDeleteFieldInput>>;
  period?: Maybe<EncounterParticipantPeriodDeleteFieldInput>;
  individual?: Maybe<EncounterParticipantIndividualDeleteFieldInput>;
};

export type EncounterParticipantDisconnectFieldInput = {
  where?: Maybe<EncounterParticipantWhere>;
  disconnect?: Maybe<EncounterParticipantDisconnectInput>;
};

export type EncounterParticipantDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  period?: Maybe<PeriodDisconnectFieldInput>;
  individual?: Maybe<ReferenceDisconnectFieldInput>;
};

export type EncounterParticipantExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterParticipantExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterParticipantExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterParticipantFieldInput = {
  create?: Maybe<Array<EncounterParticipantCreateInput>>;
  connect?: Maybe<Array<EncounterParticipantConnectFieldInput>>;
};

export type EncounterParticipantIndividualDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterParticipantIndividualFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterParticipantIndividualUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EncounterParticipantModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterParticipantModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterParticipantModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterParticipantOptions = {
  /** Specify one or more EncounterParticipantSort objects to sort EncounterParticipants by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EncounterParticipantSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EncounterParticipantPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type EncounterParticipantPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type EncounterParticipantPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type EncounterParticipantRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<Array<CodeableConceptCreateInput>>;
  period?: Maybe<PeriodCreateInput>;
  individual?: Maybe<ReferenceCreateInput>;
};

/** Fields to sort EncounterParticipants by. The order in which sorts are applied is not guaranteed when specifying many fields in one EncounterParticipantSort object. */
export type EncounterParticipantSort = {
  id?: Maybe<SortDirection>;
};

export type EncounterParticipantTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterParticipantTypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type EncounterParticipantTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type EncounterParticipantUpdateFieldInput = {
  where?: Maybe<EncounterParticipantWhere>;
  update?: Maybe<EncounterParticipantUpdateInput>;
  connect?: Maybe<Array<EncounterParticipantConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterParticipantDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterParticipantCreateInput>>;
  delete?: Maybe<Array<EncounterParticipantDeleteFieldInput>>;
};

export type EncounterParticipantUpdateInput = {
  extension?: Maybe<Array<EncounterParticipantExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterParticipantModifierextensionUpdateFieldInput>>;
  type?: Maybe<Array<EncounterParticipantTypeUpdateFieldInput>>;
  period?: Maybe<EncounterParticipantPeriodUpdateFieldInput>;
  individual?: Maybe<EncounterParticipantIndividualUpdateFieldInput>;
};

export type EncounterParticipantWhere = {
  OR?: Maybe<Array<EncounterParticipantWhere>>;
  AND?: Maybe<Array<EncounterParticipantWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
  individual?: Maybe<ReferenceWhere>;
  individual_NOT?: Maybe<ReferenceWhere>;
  individual_IN?: Maybe<Array<ReferenceWhere>>;
  individual_NOT_IN?: Maybe<Array<ReferenceWhere>>;
};

export type EncounterPartofDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterPartofFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterPartofUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EncounterPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type EncounterPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type EncounterPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type EncounterPriorityDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterPriorityFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type EncounterPriorityUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type EncounterReasoncodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterReasoncodeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type EncounterReasoncodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type EncounterReasonreferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterReasonreferenceFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type EncounterReasonreferenceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type EncounterRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  statusHistory?: Maybe<Array<EncounterStatusHistoryCreateInput>>;
  class?: Maybe<CodingCreateInput>;
  classHistory?: Maybe<Array<EncounterClassHistoryCreateInput>>;
  type?: Maybe<Array<CodeableConceptCreateInput>>;
  serviceType?: Maybe<CodeableConceptCreateInput>;
  priority?: Maybe<CodeableConceptCreateInput>;
  subject?: Maybe<ReferenceCreateInput>;
  episodeOfCare?: Maybe<Array<ReferenceCreateInput>>;
  basedOn?: Maybe<Array<ReferenceCreateInput>>;
  participant?: Maybe<Array<EncounterParticipantCreateInput>>;
  appointment?: Maybe<Array<ReferenceCreateInput>>;
  period?: Maybe<PeriodCreateInput>;
  length?: Maybe<DurationCreateInput>;
  reasonCode?: Maybe<Array<CodeableConceptCreateInput>>;
  reasonReference?: Maybe<Array<ReferenceCreateInput>>;
  diagnosis?: Maybe<Array<EncounterDiagnosisCreateInput>>;
  account?: Maybe<Array<ReferenceCreateInput>>;
  hospitalization?: Maybe<EncounterHospitalizationCreateInput>;
  location?: Maybe<Array<EncounterLocationCreateInput>>;
  serviceProvider?: Maybe<ReferenceCreateInput>;
  partOf?: Maybe<ReferenceCreateInput>;
};

export enum EncounterResourceType {
  Encounter = 'Encounter',
}

export type EncounterServiceproviderDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterServiceproviderFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterServiceproviderUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EncounterServicetypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterServicetypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type EncounterServicetypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

/** Fields to sort Encounters by. The order in which sorts are applied is not guaranteed when specifying many fields in one EncounterSort object. */
export type EncounterSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
};

/** planned | arrived | triaged | in-progress | onleave | finished | cancelled +. */
export enum EncounterStatus {
  Planned = 'planned',
  Arrived = 'arrived',
  Triaged = 'triaged',
  InProgress = 'inProgress',
  Onleave = 'onleave',
  Finished = 'finished',
  Cancelled = 'cancelled',
  EnteredInError = 'enteredInError',
  Unknown = 'unknown',
}

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterStatusHistory = {
  __typename?: 'EncounterStatusHistory';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** planned | arrived | triaged | in-progress | onleave | finished | cancelled +. */
  status?: Maybe<EncounterStatusHistoryStatus>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _status?: Maybe<Element>;
  period: Period;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterStatusHistoryExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterStatusHistoryModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterStatusHistory_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
export type EncounterStatusHistoryPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type EncounterStatusHistoryConnectFieldInput = {
  where?: Maybe<EncounterStatusHistoryWhere>;
  connect?: Maybe<EncounterStatusHistoryConnectInput>;
};

export type EncounterStatusHistoryConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type EncounterStatusHistoryCreateInput = {
  status?: Maybe<EncounterStatusHistoryStatus>;
  extension?: Maybe<EncounterStatusHistoryExtensionFieldInput>;
  modifierExtension?: Maybe<EncounterStatusHistoryModifierextensionFieldInput>;
  _status?: Maybe<EncounterStatusHistory_StatusFieldInput>;
  period?: Maybe<EncounterStatusHistoryPeriodFieldInput>;
};

export type EncounterStatusHistoryDeleteFieldInput = {
  where?: Maybe<EncounterStatusHistoryWhere>;
  delete?: Maybe<EncounterStatusHistoryDeleteInput>;
};

export type EncounterStatusHistoryDeleteInput = {
  extension?: Maybe<Array<EncounterStatusHistoryExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterStatusHistoryModifierextensionDeleteFieldInput>>;
  _status?: Maybe<EncounterStatusHistory_StatusDeleteFieldInput>;
  period?: Maybe<EncounterStatusHistoryPeriodDeleteFieldInput>;
};

export type EncounterStatusHistoryDisconnectFieldInput = {
  where?: Maybe<EncounterStatusHistoryWhere>;
  disconnect?: Maybe<EncounterStatusHistoryDisconnectInput>;
};

export type EncounterStatusHistoryDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type EncounterStatusHistoryExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterStatusHistoryExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterStatusHistoryExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterStatusHistoryModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EncounterStatusHistoryModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EncounterStatusHistoryModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EncounterStatusHistoryOptions = {
  /** Specify one or more EncounterStatusHistorySort objects to sort EncounterStatusHistories by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EncounterStatusHistorySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EncounterStatusHistoryPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type EncounterStatusHistoryPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type EncounterStatusHistoryPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type EncounterStatusHistoryRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

/** Fields to sort EncounterStatusHistories by. The order in which sorts are applied is not guaranteed when specifying many fields in one EncounterStatusHistorySort object. */
export type EncounterStatusHistorySort = {
  id?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
};

/** planned | arrived | triaged | in-progress | onleave | finished | cancelled +. */
export enum EncounterStatusHistoryStatus {
  Planned = 'planned',
  Arrived = 'arrived',
  Triaged = 'triaged',
  InProgress = 'inProgress',
  Onleave = 'onleave',
  Finished = 'finished',
  Cancelled = 'cancelled',
  EnteredInError = 'enteredInError',
  Unknown = 'unknown',
}

export type EncounterStatusHistoryUpdateInput = {
  status?: Maybe<EncounterStatusHistoryStatus>;
  extension?: Maybe<Array<EncounterStatusHistoryExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterStatusHistoryModifierextensionUpdateFieldInput>>;
  _status?: Maybe<EncounterStatusHistory_StatusUpdateFieldInput>;
  period?: Maybe<EncounterStatusHistoryPeriodUpdateFieldInput>;
};

export type EncounterStatusHistoryWhere = {
  OR?: Maybe<Array<EncounterStatusHistoryWhere>>;
  AND?: Maybe<Array<EncounterStatusHistoryWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  status?: Maybe<EncounterStatusHistoryStatus>;
  status_NOT?: Maybe<EncounterStatusHistoryStatus>;
  status_IN?: Maybe<Array<Maybe<EncounterStatusHistoryStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<EncounterStatusHistoryStatus>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type EncounterStatusHistory_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type EncounterStatusHistory_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type EncounterStatusHistory_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type EncounterStatushistoryDeleteFieldInput = {
  where?: Maybe<EncounterStatusHistoryWhere>;
  delete?: Maybe<EncounterStatusHistoryDeleteInput>;
};

export type EncounterStatushistoryFieldInput = {
  create?: Maybe<Array<EncounterStatusHistoryCreateInput>>;
  connect?: Maybe<Array<EncounterStatusHistoryConnectFieldInput>>;
};

export type EncounterStatushistoryUpdateFieldInput = {
  where?: Maybe<EncounterStatusHistoryWhere>;
  update?: Maybe<EncounterStatusHistoryUpdateInput>;
  connect?: Maybe<Array<EncounterStatusHistoryConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterStatusHistoryDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterStatusHistoryCreateInput>>;
  delete?: Maybe<Array<EncounterStatusHistoryDeleteFieldInput>>;
};

export type EncounterSubjectDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EncounterSubjectFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EncounterSubjectUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EncounterTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type EncounterTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type EncounterTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type EncounterTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EncounterTypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type EncounterTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type EncounterUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  resourceType?: Maybe<EncounterResourceType>;
  status?: Maybe<EncounterStatus>;
  meta?: Maybe<EncounterMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Encounter_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Encounter_LanguageUpdateFieldInput>;
  text?: Maybe<EncounterTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<EncounterContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<EncounterContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<EncounterContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<EncounterContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<EncounterContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<EncounterContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<EncounterContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<EncounterContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EncounterContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<EncounterContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<EncounterContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<EncounterContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<EncounterContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<EncounterContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<EncounterContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<EncounterContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<EncounterContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<EncounterContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<EncounterContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<EncounterContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<EncounterExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EncounterModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<EncounterIdentifierUpdateFieldInput>>;
  _status?: Maybe<Encounter_StatusUpdateFieldInput>;
  statusHistory?: Maybe<Array<EncounterStatushistoryUpdateFieldInput>>;
  class?: Maybe<EncounterClassUpdateFieldInput>;
  classHistory?: Maybe<Array<EncounterClasshistoryUpdateFieldInput>>;
  type?: Maybe<Array<EncounterTypeUpdateFieldInput>>;
  serviceType?: Maybe<EncounterServicetypeUpdateFieldInput>;
  priority?: Maybe<EncounterPriorityUpdateFieldInput>;
  subject?: Maybe<EncounterSubjectUpdateFieldInput>;
  episodeOfCare?: Maybe<Array<EncounterEpisodeofcareUpdateFieldInput>>;
  basedOn?: Maybe<Array<EncounterBasedonUpdateFieldInput>>;
  participant?: Maybe<Array<EncounterParticipantUpdateFieldInput>>;
  appointment?: Maybe<Array<EncounterAppointmentUpdateFieldInput>>;
  period?: Maybe<EncounterPeriodUpdateFieldInput>;
  length?: Maybe<EncounterLengthUpdateFieldInput>;
  reasonCode?: Maybe<Array<EncounterReasoncodeUpdateFieldInput>>;
  reasonReference?: Maybe<Array<EncounterReasonreferenceUpdateFieldInput>>;
  diagnosis?: Maybe<Array<EncounterDiagnosisUpdateFieldInput>>;
  account?: Maybe<Array<EncounterAccountUpdateFieldInput>>;
  hospitalization?: Maybe<EncounterHospitalizationUpdateFieldInput>;
  location?: Maybe<Array<EncounterLocationUpdateFieldInput>>;
  serviceProvider?: Maybe<EncounterServiceproviderUpdateFieldInput>;
  partOf?: Maybe<EncounterPartofUpdateFieldInput>;
};

export type EncounterWhere = {
  OR?: Maybe<Array<EncounterWhere>>;
  AND?: Maybe<Array<EncounterWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<EncounterResourceType>;
  resourceType_NOT?: Maybe<EncounterResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<EncounterResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<EncounterResourceType>>>;
  status?: Maybe<EncounterStatus>;
  status_NOT?: Maybe<EncounterStatus>;
  status_IN?: Maybe<Array<Maybe<EncounterStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<EncounterStatus>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  statusHistory?: Maybe<EncounterStatusHistoryWhere>;
  statusHistory_NOT?: Maybe<EncounterStatusHistoryWhere>;
  class?: Maybe<CodingWhere>;
  class_NOT?: Maybe<CodingWhere>;
  class_IN?: Maybe<Array<CodingWhere>>;
  class_NOT_IN?: Maybe<Array<CodingWhere>>;
  classHistory?: Maybe<EncounterClassHistoryWhere>;
  classHistory_NOT?: Maybe<EncounterClassHistoryWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  serviceType?: Maybe<CodeableConceptWhere>;
  serviceType_NOT?: Maybe<CodeableConceptWhere>;
  serviceType_IN?: Maybe<Array<CodeableConceptWhere>>;
  serviceType_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  priority?: Maybe<CodeableConceptWhere>;
  priority_NOT?: Maybe<CodeableConceptWhere>;
  priority_IN?: Maybe<Array<CodeableConceptWhere>>;
  priority_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  subject?: Maybe<ReferenceWhere>;
  subject_NOT?: Maybe<ReferenceWhere>;
  subject_IN?: Maybe<Array<ReferenceWhere>>;
  subject_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  episodeOfCare?: Maybe<ReferenceWhere>;
  episodeOfCare_NOT?: Maybe<ReferenceWhere>;
  basedOn?: Maybe<ReferenceWhere>;
  basedOn_NOT?: Maybe<ReferenceWhere>;
  participant?: Maybe<EncounterParticipantWhere>;
  participant_NOT?: Maybe<EncounterParticipantWhere>;
  appointment?: Maybe<ReferenceWhere>;
  appointment_NOT?: Maybe<ReferenceWhere>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
  length?: Maybe<DurationWhere>;
  length_NOT?: Maybe<DurationWhere>;
  length_IN?: Maybe<Array<DurationWhere>>;
  length_NOT_IN?: Maybe<Array<DurationWhere>>;
  reasonCode?: Maybe<CodeableConceptWhere>;
  reasonCode_NOT?: Maybe<CodeableConceptWhere>;
  reasonReference?: Maybe<ReferenceWhere>;
  reasonReference_NOT?: Maybe<ReferenceWhere>;
  diagnosis?: Maybe<EncounterDiagnosisWhere>;
  diagnosis_NOT?: Maybe<EncounterDiagnosisWhere>;
  account?: Maybe<ReferenceWhere>;
  account_NOT?: Maybe<ReferenceWhere>;
  hospitalization?: Maybe<EncounterHospitalizationWhere>;
  hospitalization_NOT?: Maybe<EncounterHospitalizationWhere>;
  hospitalization_IN?: Maybe<Array<EncounterHospitalizationWhere>>;
  hospitalization_NOT_IN?: Maybe<Array<EncounterHospitalizationWhere>>;
  location?: Maybe<EncounterLocationWhere>;
  location_NOT?: Maybe<EncounterLocationWhere>;
  serviceProvider?: Maybe<ReferenceWhere>;
  serviceProvider_NOT?: Maybe<ReferenceWhere>;
  serviceProvider_IN?: Maybe<Array<ReferenceWhere>>;
  serviceProvider_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  partOf?: Maybe<ReferenceWhere>;
  partOf_NOT?: Maybe<ReferenceWhere>;
  partOf_IN?: Maybe<Array<ReferenceWhere>>;
  partOf_NOT_IN?: Maybe<Array<ReferenceWhere>>;
};

export type Encounter_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Encounter_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Encounter_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Encounter_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Encounter_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Encounter_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Encounter_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Encounter_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Encounter_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type Endpoint = {
  __typename?: 'Endpoint';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a Endpoint resource */
  resourceType: EndpointResourceType;
  /** active | suspended | error | off | test. */
  status?: Maybe<EndpointStatus>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** A friendly name that this endpoint can be referred to with. */
  name?: Maybe<Scalars['String']>;
  /** The mime type to send the payload in - e.g. application/fhir+xml, application/fhir+json. If the mime type is not specified, then the sender could send any content (including no content depending on the connectionType). */
  payloadMimeType: Array<Scalars['Code']>;
  /** The uri that describes the actual end-point to connect to. */
  address?: Maybe<Scalars['Url']>;
  /** Additional headers / information to send as part of the notification. */
  header: Array<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _status?: Maybe<Element>;
  connectionType: Coding;
  _name?: Maybe<Element>;
  managingOrganization?: Maybe<Reference>;
  contact: Array<ContactPoint>;
  period?: Maybe<Period>;
  payloadType: Array<CodeableConcept>;
  _payloadMimeType: Array<Element>;
  _address?: Maybe<Element>;
  _header: Array<Element>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type Endpoint_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type Endpoint_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type Endpoint_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointConnectionTypeArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type Endpoint_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointManagingOrganizationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointContactArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type EndpointPayloadTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type Endpoint_PayloadMimeTypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type Endpoint_AddressArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
export type Endpoint_HeaderArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type EndpointConnectFieldInput = {
  where?: Maybe<EndpointWhere>;
  connect?: Maybe<EndpointConnectInput>;
};

export type EndpointConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  connectionType?: Maybe<CodingConnectFieldInput>;
  _name?: Maybe<ElementConnectFieldInput>;
  managingOrganization?: Maybe<ReferenceConnectFieldInput>;
  contact?: Maybe<Array<ContactPointConnectFieldInput>>;
  period?: Maybe<PeriodConnectFieldInput>;
  payloadType?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  _payloadMimeType?: Maybe<Array<ElementConnectFieldInput>>;
  _address?: Maybe<ElementConnectFieldInput>;
  _header?: Maybe<Array<ElementConnectFieldInput>>;
};

export type EndpointConnectiontypeDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type EndpointConnectiontypeFieldInput = {
  create?: Maybe<CodingCreateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
};

export type EndpointConnectiontypeUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
  disconnect?: Maybe<CodingDisconnectFieldInput>;
  create?: Maybe<CodingCreateInput>;
  delete?: Maybe<CodingDeleteFieldInput>;
};

export type EndpointContactDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type EndpointContactFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type EndpointContactUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type EndpointContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type EndpointContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type EndpointContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type EndpointContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type EndpointContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type EndpointContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type EndpointContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type EndpointContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type EndpointContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type EndpointContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type EndpointContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type EndpointContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type EndpointContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type EndpointContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type EndpointContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type EndpointContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type EndpointContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type EndpointContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type EndpointContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type EndpointContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type EndpointContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type EndpointContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type EndpointContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type EndpointContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type EndpointContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type EndpointContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type EndpointContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type EndpointContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type EndpointContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type EndpointContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type EndpointContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type EndpointContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type EndpointContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type EndpointContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type EndpointContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type EndpointContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type EndpointContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type EndpointContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type EndpointContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type EndpointContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type EndpointContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type EndpointContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type EndpointContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type EndpointContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type EndpointContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type EndpointContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type EndpointContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type EndpointContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type EndpointContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type EndpointContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type EndpointContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type EndpointContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type EndpointContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type EndpointContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type EndpointContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type EndpointContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type EndpointContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type EndpointContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type EndpointContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type EndpointContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type EndpointContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type EndpointContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type EndpointContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type EndpointCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  payloadMimeType: Array<Scalars['Code']>;
  address?: Maybe<Scalars['Url']>;
  header: Array<Scalars['String']>;
  resourceType: EndpointResourceType;
  status?: Maybe<EndpointStatus>;
  meta?: Maybe<EndpointMetaFieldInput>;
  _implicitRules?: Maybe<Endpoint_ImplicitrulesFieldInput>;
  _language?: Maybe<Endpoint_LanguageFieldInput>;
  text?: Maybe<EndpointTextFieldInput>;
  contained_Account?: Maybe<EndpointContainedAccountFieldInput>;
  contained_Appointment?: Maybe<EndpointContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<EndpointContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<EndpointContainedCoverageFieldInput>;
  contained_Device?: Maybe<EndpointContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<EndpointContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<EndpointContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<EndpointContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<EndpointContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<EndpointContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<EndpointContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<EndpointContainedLocationFieldInput>;
  contained_Organization?: Maybe<EndpointContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<EndpointContainedPatientFieldInput>;
  contained_Person?: Maybe<EndpointContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<EndpointContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<EndpointContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<EndpointContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<EndpointContainedScheduleFieldInput>;
  contained_Slot?: Maybe<EndpointContainedSlotFieldInput>;
  contained_Substance?: Maybe<EndpointContainedSubstanceFieldInput>;
  extension?: Maybe<EndpointExtensionFieldInput>;
  modifierExtension?: Maybe<EndpointModifierextensionFieldInput>;
  identifier?: Maybe<EndpointIdentifierFieldInput>;
  _status?: Maybe<Endpoint_StatusFieldInput>;
  connectionType?: Maybe<EndpointConnectiontypeFieldInput>;
  _name?: Maybe<Endpoint_NameFieldInput>;
  managingOrganization?: Maybe<EndpointManagingorganizationFieldInput>;
  contact?: Maybe<EndpointContactFieldInput>;
  period?: Maybe<EndpointPeriodFieldInput>;
  payloadType?: Maybe<EndpointPayloadtypeFieldInput>;
  _payloadMimeType?: Maybe<Endpoint_PayloadmimetypeFieldInput>;
  _address?: Maybe<Endpoint_AddressFieldInput>;
  _header?: Maybe<Endpoint_HeaderFieldInput>;
};

export type EndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type EndpointDeleteInput = {
  meta?: Maybe<EndpointMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Endpoint_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Endpoint_LanguageDeleteFieldInput>;
  text?: Maybe<EndpointTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<EndpointContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<EndpointContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<EndpointContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<EndpointContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<EndpointContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<EndpointContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<EndpointContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<EndpointContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EndpointContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<EndpointContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<EndpointContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<EndpointContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<EndpointContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<EndpointContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<EndpointContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<EndpointContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<EndpointContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<EndpointContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<EndpointContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<EndpointContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<EndpointExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EndpointModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<EndpointIdentifierDeleteFieldInput>>;
  _status?: Maybe<Endpoint_StatusDeleteFieldInput>;
  connectionType?: Maybe<EndpointConnectiontypeDeleteFieldInput>;
  _name?: Maybe<Endpoint_NameDeleteFieldInput>;
  managingOrganization?: Maybe<EndpointManagingorganizationDeleteFieldInput>;
  contact?: Maybe<Array<EndpointContactDeleteFieldInput>>;
  period?: Maybe<EndpointPeriodDeleteFieldInput>;
  payloadType?: Maybe<Array<EndpointPayloadtypeDeleteFieldInput>>;
  _payloadMimeType?: Maybe<Array<Endpoint_PayloadmimetypeDeleteFieldInput>>;
  _address?: Maybe<Endpoint_AddressDeleteFieldInput>;
  _header?: Maybe<Array<Endpoint_HeaderDeleteFieldInput>>;
};

export type EndpointDisconnectFieldInput = {
  where?: Maybe<EndpointWhere>;
  disconnect?: Maybe<EndpointDisconnectInput>;
};

export type EndpointDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  connectionType?: Maybe<CodingDisconnectFieldInput>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  managingOrganization?: Maybe<ReferenceDisconnectFieldInput>;
  contact?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  period?: Maybe<PeriodDisconnectFieldInput>;
  payloadType?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  _payloadMimeType?: Maybe<Array<ElementDisconnectFieldInput>>;
  _address?: Maybe<ElementDisconnectFieldInput>;
  _header?: Maybe<Array<ElementDisconnectFieldInput>>;
};

export type EndpointExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EndpointExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EndpointExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EndpointIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type EndpointIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type EndpointIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type EndpointManagingorganizationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EndpointManagingorganizationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EndpointManagingorganizationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EndpointMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type EndpointMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type EndpointMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type EndpointModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EndpointModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EndpointModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EndpointOptions = {
  /** Specify one or more EndpointSort objects to sort Endpoints by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EndpointSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EndpointPayloadtypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EndpointPayloadtypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type EndpointPayloadtypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type EndpointPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type EndpointPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type EndpointPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type EndpointRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  connectionType?: Maybe<CodingCreateInput>;
  _name?: Maybe<ElementCreateInput>;
  managingOrganization?: Maybe<ReferenceCreateInput>;
  contact?: Maybe<Array<ContactPointCreateInput>>;
  period?: Maybe<PeriodCreateInput>;
  payloadType?: Maybe<Array<CodeableConceptCreateInput>>;
  _payloadMimeType?: Maybe<Array<ElementCreateInput>>;
  _address?: Maybe<ElementCreateInput>;
  _header?: Maybe<Array<ElementCreateInput>>;
};

export enum EndpointResourceType {
  Endpoint = 'Endpoint',
}

/** Fields to sort Endpoints by. The order in which sorts are applied is not guaranteed when specifying many fields in one EndpointSort object. */
export type EndpointSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  address?: Maybe<SortDirection>;
};

/** active | suspended | error | off | test. */
export enum EndpointStatus {
  Active = 'active',
  Suspended = 'suspended',
  Error = 'error',
  Off = 'off',
  EnteredInError = 'enteredInError',
  Test = 'test',
}

export type EndpointTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type EndpointTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type EndpointTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type EndpointUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  payloadMimeType?: Maybe<Array<Scalars['Code']>>;
  address?: Maybe<Scalars['Url']>;
  header?: Maybe<Array<Scalars['String']>>;
  resourceType?: Maybe<EndpointResourceType>;
  status?: Maybe<EndpointStatus>;
  meta?: Maybe<EndpointMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Endpoint_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Endpoint_LanguageUpdateFieldInput>;
  text?: Maybe<EndpointTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<EndpointContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<EndpointContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<EndpointContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<EndpointContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<EndpointContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<EndpointContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<EndpointContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<EndpointContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EndpointContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<EndpointContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<EndpointContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<EndpointContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<EndpointContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<EndpointContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<EndpointContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<EndpointContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<EndpointContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<EndpointContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<EndpointContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<EndpointContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<EndpointExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EndpointModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<EndpointIdentifierUpdateFieldInput>>;
  _status?: Maybe<Endpoint_StatusUpdateFieldInput>;
  connectionType?: Maybe<EndpointConnectiontypeUpdateFieldInput>;
  _name?: Maybe<Endpoint_NameUpdateFieldInput>;
  managingOrganization?: Maybe<EndpointManagingorganizationUpdateFieldInput>;
  contact?: Maybe<Array<EndpointContactUpdateFieldInput>>;
  period?: Maybe<EndpointPeriodUpdateFieldInput>;
  payloadType?: Maybe<Array<EndpointPayloadtypeUpdateFieldInput>>;
  _payloadMimeType?: Maybe<Array<Endpoint_PayloadmimetypeUpdateFieldInput>>;
  _address?: Maybe<Endpoint_AddressUpdateFieldInput>;
  _header?: Maybe<Array<Endpoint_HeaderUpdateFieldInput>>;
};

export type EndpointWhere = {
  OR?: Maybe<Array<EndpointWhere>>;
  AND?: Maybe<Array<EndpointWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  payloadMimeType?: Maybe<Array<Maybe<Scalars['Code']>>>;
  address?: Maybe<Scalars['Url']>;
  header?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<EndpointResourceType>;
  resourceType_NOT?: Maybe<EndpointResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<EndpointResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<EndpointResourceType>>>;
  status?: Maybe<EndpointStatus>;
  status_NOT?: Maybe<EndpointStatus>;
  status_IN?: Maybe<Array<Maybe<EndpointStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<EndpointStatus>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  connectionType?: Maybe<CodingWhere>;
  connectionType_NOT?: Maybe<CodingWhere>;
  connectionType_IN?: Maybe<Array<CodingWhere>>;
  connectionType_NOT_IN?: Maybe<Array<CodingWhere>>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  managingOrganization?: Maybe<ReferenceWhere>;
  managingOrganization_NOT?: Maybe<ReferenceWhere>;
  managingOrganization_IN?: Maybe<Array<ReferenceWhere>>;
  managingOrganization_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  contact?: Maybe<ContactPointWhere>;
  contact_NOT?: Maybe<ContactPointWhere>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
  payloadType?: Maybe<CodeableConceptWhere>;
  payloadType_NOT?: Maybe<CodeableConceptWhere>;
  _payloadMimeType?: Maybe<ElementWhere>;
  _payloadMimeType_NOT?: Maybe<ElementWhere>;
  _address?: Maybe<ElementWhere>;
  _address_NOT?: Maybe<ElementWhere>;
  _address_IN?: Maybe<Array<ElementWhere>>;
  _address_NOT_IN?: Maybe<Array<ElementWhere>>;
  _header?: Maybe<ElementWhere>;
  _header_NOT?: Maybe<ElementWhere>;
};

export type Endpoint_AddressDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Endpoint_AddressFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Endpoint_AddressUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Endpoint_HeaderDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Endpoint_HeaderFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type Endpoint_HeaderUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type Endpoint_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Endpoint_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Endpoint_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Endpoint_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Endpoint_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Endpoint_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Endpoint_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Endpoint_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Endpoint_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Endpoint_PayloadmimetypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Endpoint_PayloadmimetypeFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type Endpoint_PayloadmimetypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type Endpoint_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Endpoint_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Endpoint_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCare = {
  __typename?: 'EpisodeOfCare';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a EpisodeOfCare resource */
  resourceType: EpisodeOfCareResourceType;
  /** planned | waitlist | active | onhold | finished | cancelled. */
  status?: Maybe<EpisodeOfCareStatus>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _status?: Maybe<Element>;
  statusHistory: Array<EpisodeOfCareStatusHistory>;
  type: Array<CodeableConcept>;
  diagnosis: Array<EpisodeOfCareDiagnosis>;
  patient: Reference;
  managingOrganization?: Maybe<Reference>;
  period?: Maybe<Period>;
  referralRequest: Array<Reference>;
  careManager?: Maybe<Reference>;
  team: Array<Reference>;
  account: Array<Reference>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCare_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCare_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCare_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareStatusHistoryArgs = {
  where?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  options?: Maybe<EpisodeOfCareStatusHistoryOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareDiagnosisArgs = {
  where?: Maybe<EpisodeOfCareDiagnosisWhere>;
  options?: Maybe<EpisodeOfCareDiagnosisOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCarePatientArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareManagingOrganizationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCarePeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareReferralRequestArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareCareManagerArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareTeamArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareAccountArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type EpisodeOfCareAccountDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EpisodeOfCareAccountFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type EpisodeOfCareAccountUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type EpisodeOfCareCaremanagerDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EpisodeOfCareCaremanagerFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EpisodeOfCareCaremanagerUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EpisodeOfCareConnectFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  connect?: Maybe<EpisodeOfCareConnectInput>;
};

export type EpisodeOfCareConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  statusHistory?: Maybe<Array<EpisodeOfCareStatusHistoryConnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  diagnosis?: Maybe<Array<EpisodeOfCareDiagnosisConnectFieldInput>>;
  patient?: Maybe<ReferenceConnectFieldInput>;
  managingOrganization?: Maybe<ReferenceConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
  referralRequest?: Maybe<Array<ReferenceConnectFieldInput>>;
  careManager?: Maybe<ReferenceConnectFieldInput>;
  team?: Maybe<Array<ReferenceConnectFieldInput>>;
  account?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type EpisodeOfCareContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type EpisodeOfCareContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type EpisodeOfCareContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type EpisodeOfCareContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type EpisodeOfCareContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type EpisodeOfCareContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type EpisodeOfCareContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type EpisodeOfCareContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type EpisodeOfCareContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type EpisodeOfCareContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type EpisodeOfCareContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type EpisodeOfCareContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type EpisodeOfCareContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type EpisodeOfCareContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type EpisodeOfCareContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type EpisodeOfCareContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type EpisodeOfCareContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type EpisodeOfCareContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type EpisodeOfCareContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type EpisodeOfCareContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type EpisodeOfCareContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type EpisodeOfCareContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type EpisodeOfCareContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type EpisodeOfCareContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type EpisodeOfCareContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type EpisodeOfCareContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type EpisodeOfCareContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type EpisodeOfCareContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type EpisodeOfCareContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type EpisodeOfCareContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type EpisodeOfCareContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type EpisodeOfCareContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type EpisodeOfCareContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type EpisodeOfCareContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type EpisodeOfCareContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type EpisodeOfCareContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type EpisodeOfCareContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type EpisodeOfCareContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type EpisodeOfCareContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type EpisodeOfCareContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type EpisodeOfCareContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type EpisodeOfCareContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type EpisodeOfCareContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type EpisodeOfCareContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type EpisodeOfCareContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type EpisodeOfCareContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type EpisodeOfCareContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type EpisodeOfCareContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type EpisodeOfCareContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type EpisodeOfCareContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type EpisodeOfCareContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type EpisodeOfCareContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type EpisodeOfCareContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type EpisodeOfCareContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type EpisodeOfCareContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type EpisodeOfCareContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type EpisodeOfCareContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type EpisodeOfCareContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type EpisodeOfCareContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type EpisodeOfCareContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type EpisodeOfCareContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type EpisodeOfCareContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type EpisodeOfCareContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type EpisodeOfCareCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  resourceType: EpisodeOfCareResourceType;
  status?: Maybe<EpisodeOfCareStatus>;
  meta?: Maybe<EpisodeOfCareMetaFieldInput>;
  _implicitRules?: Maybe<EpisodeOfCare_ImplicitrulesFieldInput>;
  _language?: Maybe<EpisodeOfCare_LanguageFieldInput>;
  text?: Maybe<EpisodeOfCareTextFieldInput>;
  contained_Account?: Maybe<EpisodeOfCareContainedAccountFieldInput>;
  contained_Appointment?: Maybe<EpisodeOfCareContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<EpisodeOfCareContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<EpisodeOfCareContainedCoverageFieldInput>;
  contained_Device?: Maybe<EpisodeOfCareContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<EpisodeOfCareContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<EpisodeOfCareContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<EpisodeOfCareContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<EpisodeOfCareContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<EpisodeOfCareContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<EpisodeOfCareContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<EpisodeOfCareContainedLocationFieldInput>;
  contained_Organization?: Maybe<EpisodeOfCareContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<EpisodeOfCareContainedPatientFieldInput>;
  contained_Person?: Maybe<EpisodeOfCareContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<EpisodeOfCareContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<EpisodeOfCareContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<EpisodeOfCareContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<EpisodeOfCareContainedScheduleFieldInput>;
  contained_Slot?: Maybe<EpisodeOfCareContainedSlotFieldInput>;
  contained_Substance?: Maybe<EpisodeOfCareContainedSubstanceFieldInput>;
  extension?: Maybe<EpisodeOfCareExtensionFieldInput>;
  modifierExtension?: Maybe<EpisodeOfCareModifierextensionFieldInput>;
  identifier?: Maybe<EpisodeOfCareIdentifierFieldInput>;
  _status?: Maybe<EpisodeOfCare_StatusFieldInput>;
  statusHistory?: Maybe<EpisodeOfCareStatushistoryFieldInput>;
  type?: Maybe<EpisodeOfCareTypeFieldInput>;
  diagnosis?: Maybe<EpisodeOfCareDiagnosisFieldInput>;
  patient?: Maybe<EpisodeOfCarePatientFieldInput>;
  managingOrganization?: Maybe<EpisodeOfCareManagingorganizationFieldInput>;
  period?: Maybe<EpisodeOfCarePeriodFieldInput>;
  referralRequest?: Maybe<EpisodeOfCareReferralrequestFieldInput>;
  careManager?: Maybe<EpisodeOfCareCaremanagerFieldInput>;
  team?: Maybe<EpisodeOfCareTeamFieldInput>;
  account?: Maybe<EpisodeOfCareAccountFieldInput>;
};

export type EpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type EpisodeOfCareDeleteInput = {
  meta?: Maybe<EpisodeOfCareMetaDeleteFieldInput>;
  _implicitRules?: Maybe<EpisodeOfCare_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<EpisodeOfCare_LanguageDeleteFieldInput>;
  text?: Maybe<EpisodeOfCareTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<EpisodeOfCareContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<EpisodeOfCareContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<EpisodeOfCareContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<EpisodeOfCareContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<EpisodeOfCareContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<EpisodeOfCareContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<EpisodeOfCareContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<EpisodeOfCareContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<EpisodeOfCareContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<EpisodeOfCareContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<EpisodeOfCareContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<EpisodeOfCareContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<EpisodeOfCareContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<EpisodeOfCareContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<EpisodeOfCareContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<EpisodeOfCareContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<EpisodeOfCareContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<EpisodeOfCareContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<EpisodeOfCareContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<EpisodeOfCareContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<EpisodeOfCareExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EpisodeOfCareModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<EpisodeOfCareIdentifierDeleteFieldInput>>;
  _status?: Maybe<EpisodeOfCare_StatusDeleteFieldInput>;
  statusHistory?: Maybe<Array<EpisodeOfCareStatushistoryDeleteFieldInput>>;
  type?: Maybe<Array<EpisodeOfCareTypeDeleteFieldInput>>;
  diagnosis?: Maybe<Array<EpisodeOfCareDiagnosisDeleteFieldInput>>;
  patient?: Maybe<EpisodeOfCarePatientDeleteFieldInput>;
  managingOrganization?: Maybe<EpisodeOfCareManagingorganizationDeleteFieldInput>;
  period?: Maybe<EpisodeOfCarePeriodDeleteFieldInput>;
  referralRequest?: Maybe<Array<EpisodeOfCareReferralrequestDeleteFieldInput>>;
  careManager?: Maybe<EpisodeOfCareCaremanagerDeleteFieldInput>;
  team?: Maybe<Array<EpisodeOfCareTeamDeleteFieldInput>>;
  account?: Maybe<Array<EpisodeOfCareAccountDeleteFieldInput>>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareDiagnosis = {
  __typename?: 'EpisodeOfCareDiagnosis';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Ranking of the diagnosis (for each role type). */
  rank?: Maybe<Scalars['PositiveInt']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  condition: Reference;
  role?: Maybe<CodeableConcept>;
  _rank?: Maybe<Element>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareDiagnosisExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareDiagnosisModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareDiagnosisConditionArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareDiagnosisRoleArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareDiagnosis_RankArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type EpisodeOfCareDiagnosisConditionDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EpisodeOfCareDiagnosisConditionFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EpisodeOfCareDiagnosisConditionUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EpisodeOfCareDiagnosisConnectFieldInput = {
  where?: Maybe<EpisodeOfCareDiagnosisWhere>;
  connect?: Maybe<EpisodeOfCareDiagnosisConnectInput>;
};

export type EpisodeOfCareDiagnosisConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  condition?: Maybe<ReferenceConnectFieldInput>;
  role?: Maybe<CodeableConceptConnectFieldInput>;
  _rank?: Maybe<ElementConnectFieldInput>;
};

export type EpisodeOfCareDiagnosisCreateInput = {
  rank?: Maybe<Scalars['PositiveInt']>;
  extension?: Maybe<EpisodeOfCareDiagnosisExtensionFieldInput>;
  modifierExtension?: Maybe<EpisodeOfCareDiagnosisModifierextensionFieldInput>;
  condition?: Maybe<EpisodeOfCareDiagnosisConditionFieldInput>;
  role?: Maybe<EpisodeOfCareDiagnosisRoleFieldInput>;
  _rank?: Maybe<EpisodeOfCareDiagnosis_RankFieldInput>;
};

export type EpisodeOfCareDiagnosisDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareDiagnosisWhere>;
  delete?: Maybe<EpisodeOfCareDiagnosisDeleteInput>;
};

export type EpisodeOfCareDiagnosisDeleteInput = {
  extension?: Maybe<Array<EpisodeOfCareDiagnosisExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EpisodeOfCareDiagnosisModifierextensionDeleteFieldInput>>;
  condition?: Maybe<EpisodeOfCareDiagnosisConditionDeleteFieldInput>;
  role?: Maybe<EpisodeOfCareDiagnosisRoleDeleteFieldInput>;
  _rank?: Maybe<EpisodeOfCareDiagnosis_RankDeleteFieldInput>;
};

export type EpisodeOfCareDiagnosisDisconnectFieldInput = {
  where?: Maybe<EpisodeOfCareDiagnosisWhere>;
  disconnect?: Maybe<EpisodeOfCareDiagnosisDisconnectInput>;
};

export type EpisodeOfCareDiagnosisDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  condition?: Maybe<ReferenceDisconnectFieldInput>;
  role?: Maybe<CodeableConceptDisconnectFieldInput>;
  _rank?: Maybe<ElementDisconnectFieldInput>;
};

export type EpisodeOfCareDiagnosisExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EpisodeOfCareDiagnosisExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EpisodeOfCareDiagnosisExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EpisodeOfCareDiagnosisFieldInput = {
  create?: Maybe<Array<EpisodeOfCareDiagnosisCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareDiagnosisConnectFieldInput>>;
};

export type EpisodeOfCareDiagnosisModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EpisodeOfCareDiagnosisModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EpisodeOfCareDiagnosisModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EpisodeOfCareDiagnosisOptions = {
  /** Specify one or more EpisodeOfCareDiagnosisSort objects to sort EpisodeOfCareDiagnoses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EpisodeOfCareDiagnosisSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EpisodeOfCareDiagnosisRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  condition?: Maybe<ReferenceCreateInput>;
  role?: Maybe<CodeableConceptCreateInput>;
  _rank?: Maybe<ElementCreateInput>;
};

export type EpisodeOfCareDiagnosisRoleDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EpisodeOfCareDiagnosisRoleFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type EpisodeOfCareDiagnosisRoleUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

/** Fields to sort EpisodeOfCareDiagnoses by. The order in which sorts are applied is not guaranteed when specifying many fields in one EpisodeOfCareDiagnosisSort object. */
export type EpisodeOfCareDiagnosisSort = {
  id?: Maybe<SortDirection>;
  rank?: Maybe<SortDirection>;
};

export type EpisodeOfCareDiagnosisUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareDiagnosisWhere>;
  update?: Maybe<EpisodeOfCareDiagnosisUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareDiagnosisConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDiagnosisDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareDiagnosisCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDiagnosisDeleteFieldInput>>;
};

export type EpisodeOfCareDiagnosisUpdateInput = {
  rank?: Maybe<Scalars['PositiveInt']>;
  extension?: Maybe<Array<EpisodeOfCareDiagnosisExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EpisodeOfCareDiagnosisModifierextensionUpdateFieldInput>>;
  condition?: Maybe<EpisodeOfCareDiagnosisConditionUpdateFieldInput>;
  role?: Maybe<EpisodeOfCareDiagnosisRoleUpdateFieldInput>;
  _rank?: Maybe<EpisodeOfCareDiagnosis_RankUpdateFieldInput>;
};

export type EpisodeOfCareDiagnosisWhere = {
  OR?: Maybe<Array<EpisodeOfCareDiagnosisWhere>>;
  AND?: Maybe<Array<EpisodeOfCareDiagnosisWhere>>;
  rank?: Maybe<Scalars['PositiveInt']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  condition?: Maybe<ReferenceWhere>;
  condition_NOT?: Maybe<ReferenceWhere>;
  condition_IN?: Maybe<Array<ReferenceWhere>>;
  condition_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  role?: Maybe<CodeableConceptWhere>;
  role_NOT?: Maybe<CodeableConceptWhere>;
  role_IN?: Maybe<Array<CodeableConceptWhere>>;
  role_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _rank?: Maybe<ElementWhere>;
  _rank_NOT?: Maybe<ElementWhere>;
  _rank_IN?: Maybe<Array<ElementWhere>>;
  _rank_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type EpisodeOfCareDiagnosis_RankDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type EpisodeOfCareDiagnosis_RankFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type EpisodeOfCareDiagnosis_RankUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type EpisodeOfCareDisconnectFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  disconnect?: Maybe<EpisodeOfCareDisconnectInput>;
};

export type EpisodeOfCareDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  statusHistory?: Maybe<Array<EpisodeOfCareStatusHistoryDisconnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  diagnosis?: Maybe<Array<EpisodeOfCareDiagnosisDisconnectFieldInput>>;
  patient?: Maybe<ReferenceDisconnectFieldInput>;
  managingOrganization?: Maybe<ReferenceDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
  referralRequest?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  careManager?: Maybe<ReferenceDisconnectFieldInput>;
  team?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  account?: Maybe<Array<ReferenceDisconnectFieldInput>>;
};

export type EpisodeOfCareExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EpisodeOfCareExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EpisodeOfCareExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EpisodeOfCareIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type EpisodeOfCareIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type EpisodeOfCareIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type EpisodeOfCareManagingorganizationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EpisodeOfCareManagingorganizationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EpisodeOfCareManagingorganizationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EpisodeOfCareMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type EpisodeOfCareMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type EpisodeOfCareMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type EpisodeOfCareModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EpisodeOfCareModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EpisodeOfCareModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EpisodeOfCareOptions = {
  /** Specify one or more EpisodeOfCareSort objects to sort EpisodeOfCares by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EpisodeOfCareSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EpisodeOfCarePatientDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EpisodeOfCarePatientFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type EpisodeOfCarePatientUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type EpisodeOfCarePeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type EpisodeOfCarePeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type EpisodeOfCarePeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type EpisodeOfCareReferralrequestDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EpisodeOfCareReferralrequestFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type EpisodeOfCareReferralrequestUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type EpisodeOfCareRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  statusHistory?: Maybe<Array<EpisodeOfCareStatusHistoryCreateInput>>;
  type?: Maybe<Array<CodeableConceptCreateInput>>;
  diagnosis?: Maybe<Array<EpisodeOfCareDiagnosisCreateInput>>;
  patient?: Maybe<ReferenceCreateInput>;
  managingOrganization?: Maybe<ReferenceCreateInput>;
  period?: Maybe<PeriodCreateInput>;
  referralRequest?: Maybe<Array<ReferenceCreateInput>>;
  careManager?: Maybe<ReferenceCreateInput>;
  team?: Maybe<Array<ReferenceCreateInput>>;
  account?: Maybe<Array<ReferenceCreateInput>>;
};

export enum EpisodeOfCareResourceType {
  EpisodeOfCare = 'EpisodeOfCare',
}

/** Fields to sort EpisodeOfCares by. The order in which sorts are applied is not guaranteed when specifying many fields in one EpisodeOfCareSort object. */
export type EpisodeOfCareSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
};

/** planned | waitlist | active | onhold | finished | cancelled. */
export enum EpisodeOfCareStatus {
  Planned = 'planned',
  Waitlist = 'waitlist',
  Active = 'active',
  Onhold = 'onhold',
  Finished = 'finished',
  Cancelled = 'cancelled',
  EnteredInError = 'enteredInError',
}

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareStatusHistory = {
  __typename?: 'EpisodeOfCareStatusHistory';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** planned | waitlist | active | onhold | finished | cancelled. */
  status?: Maybe<EpisodeOfCareStatusHistoryStatus>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _status?: Maybe<Element>;
  period: Period;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareStatusHistoryExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareStatusHistoryModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareStatusHistory_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
export type EpisodeOfCareStatusHistoryPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type EpisodeOfCareStatusHistoryConnectFieldInput = {
  where?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  connect?: Maybe<EpisodeOfCareStatusHistoryConnectInput>;
};

export type EpisodeOfCareStatusHistoryConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type EpisodeOfCareStatusHistoryCreateInput = {
  status?: Maybe<EpisodeOfCareStatusHistoryStatus>;
  extension?: Maybe<EpisodeOfCareStatusHistoryExtensionFieldInput>;
  modifierExtension?: Maybe<EpisodeOfCareStatusHistoryModifierextensionFieldInput>;
  _status?: Maybe<EpisodeOfCareStatusHistory_StatusFieldInput>;
  period?: Maybe<EpisodeOfCareStatusHistoryPeriodFieldInput>;
};

export type EpisodeOfCareStatusHistoryDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  delete?: Maybe<EpisodeOfCareStatusHistoryDeleteInput>;
};

export type EpisodeOfCareStatusHistoryDeleteInput = {
  extension?: Maybe<Array<EpisodeOfCareStatusHistoryExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<EpisodeOfCareStatusHistoryModifierextensionDeleteFieldInput>>;
  _status?: Maybe<EpisodeOfCareStatusHistory_StatusDeleteFieldInput>;
  period?: Maybe<EpisodeOfCareStatusHistoryPeriodDeleteFieldInput>;
};

export type EpisodeOfCareStatusHistoryDisconnectFieldInput = {
  where?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  disconnect?: Maybe<EpisodeOfCareStatusHistoryDisconnectInput>;
};

export type EpisodeOfCareStatusHistoryDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type EpisodeOfCareStatusHistoryExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EpisodeOfCareStatusHistoryExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EpisodeOfCareStatusHistoryExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EpisodeOfCareStatusHistoryModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type EpisodeOfCareStatusHistoryModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type EpisodeOfCareStatusHistoryModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type EpisodeOfCareStatusHistoryOptions = {
  /** Specify one or more EpisodeOfCareStatusHistorySort objects to sort EpisodeOfCareStatusHistories by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<EpisodeOfCareStatusHistorySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EpisodeOfCareStatusHistoryPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type EpisodeOfCareStatusHistoryPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type EpisodeOfCareStatusHistoryPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type EpisodeOfCareStatusHistoryRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

/** Fields to sort EpisodeOfCareStatusHistories by. The order in which sorts are applied is not guaranteed when specifying many fields in one EpisodeOfCareStatusHistorySort object. */
export type EpisodeOfCareStatusHistorySort = {
  id?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
};

/** planned | waitlist | active | onhold | finished | cancelled. */
export enum EpisodeOfCareStatusHistoryStatus {
  Planned = 'planned',
  Waitlist = 'waitlist',
  Active = 'active',
  Onhold = 'onhold',
  Finished = 'finished',
  Cancelled = 'cancelled',
  EnteredInError = 'enteredInError',
}

export type EpisodeOfCareStatusHistoryUpdateInput = {
  status?: Maybe<EpisodeOfCareStatusHistoryStatus>;
  extension?: Maybe<Array<EpisodeOfCareStatusHistoryExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EpisodeOfCareStatusHistoryModifierextensionUpdateFieldInput>>;
  _status?: Maybe<EpisodeOfCareStatusHistory_StatusUpdateFieldInput>;
  period?: Maybe<EpisodeOfCareStatusHistoryPeriodUpdateFieldInput>;
};

export type EpisodeOfCareStatusHistoryWhere = {
  OR?: Maybe<Array<EpisodeOfCareStatusHistoryWhere>>;
  AND?: Maybe<Array<EpisodeOfCareStatusHistoryWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  status?: Maybe<EpisodeOfCareStatusHistoryStatus>;
  status_NOT?: Maybe<EpisodeOfCareStatusHistoryStatus>;
  status_IN?: Maybe<Array<Maybe<EpisodeOfCareStatusHistoryStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<EpisodeOfCareStatusHistoryStatus>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type EpisodeOfCareStatusHistory_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type EpisodeOfCareStatusHistory_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type EpisodeOfCareStatusHistory_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type EpisodeOfCareStatushistoryDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  delete?: Maybe<EpisodeOfCareStatusHistoryDeleteInput>;
};

export type EpisodeOfCareStatushistoryFieldInput = {
  create?: Maybe<Array<EpisodeOfCareStatusHistoryCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareStatusHistoryConnectFieldInput>>;
};

export type EpisodeOfCareStatushistoryUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  update?: Maybe<EpisodeOfCareStatusHistoryUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareStatusHistoryConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareStatusHistoryDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareStatusHistoryCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareStatusHistoryDeleteFieldInput>>;
};

export type EpisodeOfCareTeamDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type EpisodeOfCareTeamFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type EpisodeOfCareTeamUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type EpisodeOfCareTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type EpisodeOfCareTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type EpisodeOfCareTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type EpisodeOfCareTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type EpisodeOfCareTypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type EpisodeOfCareTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type EpisodeOfCareUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  resourceType?: Maybe<EpisodeOfCareResourceType>;
  status?: Maybe<EpisodeOfCareStatus>;
  meta?: Maybe<EpisodeOfCareMetaUpdateFieldInput>;
  _implicitRules?: Maybe<EpisodeOfCare_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<EpisodeOfCare_LanguageUpdateFieldInput>;
  text?: Maybe<EpisodeOfCareTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<EpisodeOfCareContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<EpisodeOfCareContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<EpisodeOfCareContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<EpisodeOfCareContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<EpisodeOfCareContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<EpisodeOfCareContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<EpisodeOfCareContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<EpisodeOfCareContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<EpisodeOfCareContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<EpisodeOfCareContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<EpisodeOfCareContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<EpisodeOfCareContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<EpisodeOfCareContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<EpisodeOfCareContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<EpisodeOfCareContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<EpisodeOfCareContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<EpisodeOfCareContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<EpisodeOfCareContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<EpisodeOfCareContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<EpisodeOfCareContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<EpisodeOfCareExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<EpisodeOfCareModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<EpisodeOfCareIdentifierUpdateFieldInput>>;
  _status?: Maybe<EpisodeOfCare_StatusUpdateFieldInput>;
  statusHistory?: Maybe<Array<EpisodeOfCareStatushistoryUpdateFieldInput>>;
  type?: Maybe<Array<EpisodeOfCareTypeUpdateFieldInput>>;
  diagnosis?: Maybe<Array<EpisodeOfCareDiagnosisUpdateFieldInput>>;
  patient?: Maybe<EpisodeOfCarePatientUpdateFieldInput>;
  managingOrganization?: Maybe<EpisodeOfCareManagingorganizationUpdateFieldInput>;
  period?: Maybe<EpisodeOfCarePeriodUpdateFieldInput>;
  referralRequest?: Maybe<Array<EpisodeOfCareReferralrequestUpdateFieldInput>>;
  careManager?: Maybe<EpisodeOfCareCaremanagerUpdateFieldInput>;
  team?: Maybe<Array<EpisodeOfCareTeamUpdateFieldInput>>;
  account?: Maybe<Array<EpisodeOfCareAccountUpdateFieldInput>>;
};

export type EpisodeOfCareWhere = {
  OR?: Maybe<Array<EpisodeOfCareWhere>>;
  AND?: Maybe<Array<EpisodeOfCareWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<EpisodeOfCareResourceType>;
  resourceType_NOT?: Maybe<EpisodeOfCareResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<EpisodeOfCareResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<EpisodeOfCareResourceType>>>;
  status?: Maybe<EpisodeOfCareStatus>;
  status_NOT?: Maybe<EpisodeOfCareStatus>;
  status_IN?: Maybe<Array<Maybe<EpisodeOfCareStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<EpisodeOfCareStatus>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  statusHistory?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  statusHistory_NOT?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  diagnosis?: Maybe<EpisodeOfCareDiagnosisWhere>;
  diagnosis_NOT?: Maybe<EpisodeOfCareDiagnosisWhere>;
  patient?: Maybe<ReferenceWhere>;
  patient_NOT?: Maybe<ReferenceWhere>;
  patient_IN?: Maybe<Array<ReferenceWhere>>;
  patient_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  managingOrganization?: Maybe<ReferenceWhere>;
  managingOrganization_NOT?: Maybe<ReferenceWhere>;
  managingOrganization_IN?: Maybe<Array<ReferenceWhere>>;
  managingOrganization_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
  referralRequest?: Maybe<ReferenceWhere>;
  referralRequest_NOT?: Maybe<ReferenceWhere>;
  careManager?: Maybe<ReferenceWhere>;
  careManager_NOT?: Maybe<ReferenceWhere>;
  careManager_IN?: Maybe<Array<ReferenceWhere>>;
  careManager_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  team?: Maybe<ReferenceWhere>;
  team_NOT?: Maybe<ReferenceWhere>;
  account?: Maybe<ReferenceWhere>;
  account_NOT?: Maybe<ReferenceWhere>;
};

export type EpisodeOfCare_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type EpisodeOfCare_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type EpisodeOfCare_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type EpisodeOfCare_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type EpisodeOfCare_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type EpisodeOfCare_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type EpisodeOfCare_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type EpisodeOfCare_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type EpisodeOfCare_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export enum ExcludeOperation {
  Create = 'CREATE',
  Read = 'READ',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

/** A expression that is evaluated in a specified context and returns a value. The context of use of the expression must specify the context in which the expression is evaluated, and how the result of the expression is used. */
export type Expression = {
  __typename?: 'Expression';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** A short name assigned to the expression to allow for multiple reuse of the expression in the context where it is defined. */
  name?: Maybe<Scalars['ID']>;
  /** The media type of the language for the expression. */
  language?: Maybe<ExpressionLanguage>;
  /** A brief, natural language description of the condition that effectively communicates the intended semantics. */
  description?: Maybe<Scalars['String']>;
  /** An expression in the specified language that returns a value. */
  expression?: Maybe<Scalars['String']>;
  /** A URI that defines where the expression is found. */
  reference?: Maybe<Scalars['Uri']>;
  extension: Array<Extension>;
  _description?: Maybe<Element>;
  _name?: Maybe<Element>;
  _language?: Maybe<Element>;
  _expression?: Maybe<Element>;
  _reference?: Maybe<Element>;
};

/** A expression that is evaluated in a specified context and returns a value. The context of use of the expression must specify the context in which the expression is evaluated, and how the result of the expression is used. */
export type ExpressionExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A expression that is evaluated in a specified context and returns a value. The context of use of the expression must specify the context in which the expression is evaluated, and how the result of the expression is used. */
export type Expression_DescriptionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A expression that is evaluated in a specified context and returns a value. The context of use of the expression must specify the context in which the expression is evaluated, and how the result of the expression is used. */
export type Expression_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A expression that is evaluated in a specified context and returns a value. The context of use of the expression must specify the context in which the expression is evaluated, and how the result of the expression is used. */
export type Expression_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A expression that is evaluated in a specified context and returns a value. The context of use of the expression must specify the context in which the expression is evaluated, and how the result of the expression is used. */
export type Expression_ExpressionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A expression that is evaluated in a specified context and returns a value. The context of use of the expression must specify the context in which the expression is evaluated, and how the result of the expression is used. */
export type Expression_ReferenceArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type ExpressionConnectFieldInput = {
  where?: Maybe<ExpressionWhere>;
  connect?: Maybe<ExpressionConnectInput>;
};

export type ExpressionConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _description?: Maybe<ElementConnectFieldInput>;
  _name?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  _expression?: Maybe<ElementConnectFieldInput>;
  _reference?: Maybe<ElementConnectFieldInput>;
};

export type ExpressionCreateInput = {
  name?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['Uri']>;
  language?: Maybe<ExpressionLanguage>;
  extension?: Maybe<ExpressionExtensionFieldInput>;
  _description?: Maybe<Expression_DescriptionFieldInput>;
  _name?: Maybe<Expression_NameFieldInput>;
  _language?: Maybe<Expression_LanguageFieldInput>;
  _expression?: Maybe<Expression_ExpressionFieldInput>;
  _reference?: Maybe<Expression_ReferenceFieldInput>;
};

export type ExpressionDeleteFieldInput = {
  where?: Maybe<ExpressionWhere>;
  delete?: Maybe<ExpressionDeleteInput>;
};

export type ExpressionDeleteInput = {
  extension?: Maybe<Array<ExpressionExtensionDeleteFieldInput>>;
  _description?: Maybe<Expression_DescriptionDeleteFieldInput>;
  _name?: Maybe<Expression_NameDeleteFieldInput>;
  _language?: Maybe<Expression_LanguageDeleteFieldInput>;
  _expression?: Maybe<Expression_ExpressionDeleteFieldInput>;
  _reference?: Maybe<Expression_ReferenceDeleteFieldInput>;
};

export type ExpressionDisconnectFieldInput = {
  where?: Maybe<ExpressionWhere>;
  disconnect?: Maybe<ExpressionDisconnectInput>;
};

export type ExpressionDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _description?: Maybe<ElementDisconnectFieldInput>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  _expression?: Maybe<ElementDisconnectFieldInput>;
  _reference?: Maybe<ElementDisconnectFieldInput>;
};

export type ExpressionExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ExpressionExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ExpressionExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** The media type of the language for the expression. */
export enum ExpressionLanguage {
  TextCql = 'textCql',
  TextFhirpath = 'textFhirpath',
  ApplicationXFhirQuery = 'applicationXFhirQuery',
}

export type ExpressionOptions = {
  /** Specify one or more ExpressionSort objects to sort Expressions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ExpressionSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ExpressionRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _description?: Maybe<ElementCreateInput>;
  _name?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  _expression?: Maybe<ElementCreateInput>;
  _reference?: Maybe<ElementCreateInput>;
};

/** Fields to sort Expressions by. The order in which sorts are applied is not guaranteed when specifying many fields in one ExpressionSort object. */
export type ExpressionSort = {
  id?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
  expression?: Maybe<SortDirection>;
  reference?: Maybe<SortDirection>;
};

export type ExpressionUpdateInput = {
  name?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['Uri']>;
  language?: Maybe<ExpressionLanguage>;
  extension?: Maybe<Array<ExpressionExtensionUpdateFieldInput>>;
  _description?: Maybe<Expression_DescriptionUpdateFieldInput>;
  _name?: Maybe<Expression_NameUpdateFieldInput>;
  _language?: Maybe<Expression_LanguageUpdateFieldInput>;
  _expression?: Maybe<Expression_ExpressionUpdateFieldInput>;
  _reference?: Maybe<Expression_ReferenceUpdateFieldInput>;
};

export type ExpressionWhere = {
  OR?: Maybe<Array<ExpressionWhere>>;
  AND?: Maybe<Array<ExpressionWhere>>;
  description?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['Uri']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['ID']>;
  name_NOT?: Maybe<Scalars['ID']>;
  name_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_CONTAINS?: Maybe<Scalars['ID']>;
  name_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  name_STARTS_WITH?: Maybe<Scalars['ID']>;
  name_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  name_ENDS_WITH?: Maybe<Scalars['ID']>;
  name_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  language?: Maybe<ExpressionLanguage>;
  language_NOT?: Maybe<ExpressionLanguage>;
  language_IN?: Maybe<Array<Maybe<ExpressionLanguage>>>;
  language_NOT_IN?: Maybe<Array<Maybe<ExpressionLanguage>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _description?: Maybe<ElementWhere>;
  _description_NOT?: Maybe<ElementWhere>;
  _description_IN?: Maybe<Array<ElementWhere>>;
  _description_NOT_IN?: Maybe<Array<ElementWhere>>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  _expression?: Maybe<ElementWhere>;
  _expression_NOT?: Maybe<ElementWhere>;
  _expression_IN?: Maybe<Array<ElementWhere>>;
  _expression_NOT_IN?: Maybe<Array<ElementWhere>>;
  _reference?: Maybe<ElementWhere>;
  _reference_NOT?: Maybe<ElementWhere>;
  _reference_IN?: Maybe<Array<ElementWhere>>;
  _reference_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Expression_DescriptionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Expression_DescriptionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Expression_DescriptionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Expression_ExpressionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Expression_ExpressionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Expression_ExpressionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Expression_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Expression_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Expression_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Expression_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Expression_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Expression_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Expression_ReferenceDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Expression_ReferenceFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Expression_ReferenceUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Optional Extension Element - found in all resources. */
export type Extension = {
  __typename?: 'Extension';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueBoolean?: Maybe<Scalars['Boolean']>;
  /** Source of the definition for the extension code - a logical name or a URL. */
  url?: Maybe<Scalars['Uri']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueBase64Binary?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueCanonical?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueCode?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueDate?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueDateTime?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueDecimal?: Maybe<Scalars['Number']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueId?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueInstant?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueInteger?: Maybe<Scalars['Number']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueMarkdown?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueOid?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valuePositiveInt?: Maybe<Scalars['Number']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueString?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueTime?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueUnsignedInt?: Maybe<Scalars['Number']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueUri?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueUrl?: Maybe<Scalars['String']>;
  /** Value of extension - must be one of a constrained set of the data types (see [Extensibility](extensibility.html) for a list). */
  valueUuid?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  _url?: Maybe<Element>;
  _valueBase64Binary?: Maybe<Element>;
  _valueBoolean?: Maybe<Element>;
  _valueCanonical?: Maybe<Element>;
  _valueCode?: Maybe<Element>;
  _valueDate?: Maybe<Element>;
  _valueDateTime?: Maybe<Element>;
  _valueDecimal?: Maybe<Element>;
  _valueId?: Maybe<Element>;
  _valueInstant?: Maybe<Element>;
  _valueInteger?: Maybe<Element>;
  _valueMarkdown?: Maybe<Element>;
  _valueOid?: Maybe<Element>;
  _valuePositiveInt?: Maybe<Element>;
  _valueString?: Maybe<Element>;
  _valueTime?: Maybe<Element>;
  _valueUnsignedInt?: Maybe<Element>;
  _valueUri?: Maybe<Element>;
  _valueUrl?: Maybe<Element>;
  _valueUuid?: Maybe<Element>;
  valueAddress?: Maybe<Address>;
  valueAge?: Maybe<Age>;
  valueAnnotation?: Maybe<Annotation>;
  valueAttachment?: Maybe<Attachment>;
  valueCodeableConcept?: Maybe<CodeableConcept>;
  valueCoding?: Maybe<Coding>;
  valueContactPoint?: Maybe<ContactPoint>;
  valueCount?: Maybe<Count>;
  valueDistance?: Maybe<Distance>;
  valueDuration?: Maybe<Duration>;
  valueHumanName?: Maybe<HumanName>;
  valueIdentifier?: Maybe<Identifier>;
  valueMoney?: Maybe<Money>;
  valuePeriod?: Maybe<Period>;
  valueQuantity?: Maybe<Quantity>;
  valueRange?: Maybe<Range>;
  valueRatio?: Maybe<Ratio>;
  valueReference?: Maybe<Reference>;
  valueSampledData?: Maybe<SampledData>;
  valueSignature?: Maybe<Signature>;
  valueTiming?: Maybe<Timing>;
  valueContactDetail?: Maybe<ContactDetail>;
  valueContributor?: Maybe<Contributor>;
  valueDataRequirement?: Maybe<DataRequirement>;
  valueExpression?: Maybe<Expression>;
  valueParameterDefinition?: Maybe<ParameterDefinition>;
  valueRelatedArtifact?: Maybe<RelatedArtifact>;
  valueTriggerDefinition?: Maybe<TriggerDefinition>;
  valueUsageContext?: Maybe<UsageContext>;
  valueDosage?: Maybe<Dosage>;
  valueMeta?: Maybe<Meta>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_UrlArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueBase64BinaryArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueBooleanArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueCanonicalArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueCodeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueDateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueDateTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueDecimalArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueIdArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueInstantArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueIntegerArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueMarkdownArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueOidArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValuePositiveIntArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueStringArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueUnsignedIntArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueUriArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueUrlArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type Extension_ValueUuidArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueAddressArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueAgeArgs = {
  where?: Maybe<AgeWhere>;
  options?: Maybe<AgeOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueAnnotationArgs = {
  where?: Maybe<AnnotationWhere>;
  options?: Maybe<AnnotationOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueAttachmentArgs = {
  where?: Maybe<AttachmentWhere>;
  options?: Maybe<AttachmentOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueCodeableConceptArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueCodingArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueContactPointArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueCountArgs = {
  where?: Maybe<CountWhere>;
  options?: Maybe<CountOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueDistanceArgs = {
  where?: Maybe<DistanceWhere>;
  options?: Maybe<DistanceOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueDurationArgs = {
  where?: Maybe<DurationWhere>;
  options?: Maybe<DurationOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueHumanNameArgs = {
  where?: Maybe<HumanNameWhere>;
  options?: Maybe<HumanNameOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueMoneyArgs = {
  where?: Maybe<MoneyWhere>;
  options?: Maybe<MoneyOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValuePeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueQuantityArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueRangeArgs = {
  where?: Maybe<RangeWhere>;
  options?: Maybe<RangeOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueRatioArgs = {
  where?: Maybe<RatioWhere>;
  options?: Maybe<RatioOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueReferenceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueSampledDataArgs = {
  where?: Maybe<SampledDataWhere>;
  options?: Maybe<SampledDataOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueSignatureArgs = {
  where?: Maybe<SignatureWhere>;
  options?: Maybe<SignatureOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueTimingArgs = {
  where?: Maybe<TimingWhere>;
  options?: Maybe<TimingOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueContactDetailArgs = {
  where?: Maybe<ContactDetailWhere>;
  options?: Maybe<ContactDetailOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueContributorArgs = {
  where?: Maybe<ContributorWhere>;
  options?: Maybe<ContributorOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueDataRequirementArgs = {
  where?: Maybe<DataRequirementWhere>;
  options?: Maybe<DataRequirementOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueExpressionArgs = {
  where?: Maybe<ExpressionWhere>;
  options?: Maybe<ExpressionOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueParameterDefinitionArgs = {
  where?: Maybe<ParameterDefinitionWhere>;
  options?: Maybe<ParameterDefinitionOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueRelatedArtifactArgs = {
  where?: Maybe<RelatedArtifactWhere>;
  options?: Maybe<RelatedArtifactOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueTriggerDefinitionArgs = {
  where?: Maybe<TriggerDefinitionWhere>;
  options?: Maybe<TriggerDefinitionOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueUsageContextArgs = {
  where?: Maybe<UsageContextWhere>;
  options?: Maybe<UsageContextOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueDosageArgs = {
  where?: Maybe<DosageWhere>;
  options?: Maybe<DosageOptions>;
};

/** Optional Extension Element - found in all resources. */
export type ExtensionValueMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

export type ExtensionConnectFieldInput = {
  where?: Maybe<ExtensionWhere>;
  connect?: Maybe<ExtensionConnectInput>;
};

export type ExtensionConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _url?: Maybe<ElementConnectFieldInput>;
  _valueBase64Binary?: Maybe<ElementConnectFieldInput>;
  _valueBoolean?: Maybe<ElementConnectFieldInput>;
  _valueCanonical?: Maybe<ElementConnectFieldInput>;
  _valueCode?: Maybe<ElementConnectFieldInput>;
  _valueDate?: Maybe<ElementConnectFieldInput>;
  _valueDateTime?: Maybe<ElementConnectFieldInput>;
  _valueDecimal?: Maybe<ElementConnectFieldInput>;
  _valueId?: Maybe<ElementConnectFieldInput>;
  _valueInstant?: Maybe<ElementConnectFieldInput>;
  _valueInteger?: Maybe<ElementConnectFieldInput>;
  _valueMarkdown?: Maybe<ElementConnectFieldInput>;
  _valueOid?: Maybe<ElementConnectFieldInput>;
  _valuePositiveInt?: Maybe<ElementConnectFieldInput>;
  _valueString?: Maybe<ElementConnectFieldInput>;
  _valueTime?: Maybe<ElementConnectFieldInput>;
  _valueUnsignedInt?: Maybe<ElementConnectFieldInput>;
  _valueUri?: Maybe<ElementConnectFieldInput>;
  _valueUrl?: Maybe<ElementConnectFieldInput>;
  _valueUuid?: Maybe<ElementConnectFieldInput>;
  valueAddress?: Maybe<AddressConnectFieldInput>;
  valueAge?: Maybe<AgeConnectFieldInput>;
  valueAnnotation?: Maybe<AnnotationConnectFieldInput>;
  valueAttachment?: Maybe<AttachmentConnectFieldInput>;
  valueCodeableConcept?: Maybe<CodeableConceptConnectFieldInput>;
  valueCoding?: Maybe<CodingConnectFieldInput>;
  valueContactPoint?: Maybe<ContactPointConnectFieldInput>;
  valueCount?: Maybe<CountConnectFieldInput>;
  valueDistance?: Maybe<DistanceConnectFieldInput>;
  valueDuration?: Maybe<DurationConnectFieldInput>;
  valueHumanName?: Maybe<HumanNameConnectFieldInput>;
  valueIdentifier?: Maybe<IdentifierConnectFieldInput>;
  valueMoney?: Maybe<MoneyConnectFieldInput>;
  valuePeriod?: Maybe<PeriodConnectFieldInput>;
  valueQuantity?: Maybe<QuantityConnectFieldInput>;
  valueRange?: Maybe<RangeConnectFieldInput>;
  valueRatio?: Maybe<RatioConnectFieldInput>;
  valueReference?: Maybe<ReferenceConnectFieldInput>;
  valueSampledData?: Maybe<SampledDataConnectFieldInput>;
  valueSignature?: Maybe<SignatureConnectFieldInput>;
  valueTiming?: Maybe<TimingConnectFieldInput>;
  valueContactDetail?: Maybe<ContactDetailConnectFieldInput>;
  valueContributor?: Maybe<ContributorConnectFieldInput>;
  valueDataRequirement?: Maybe<DataRequirementConnectFieldInput>;
  valueExpression?: Maybe<ExpressionConnectFieldInput>;
  valueParameterDefinition?: Maybe<ParameterDefinitionConnectFieldInput>;
  valueRelatedArtifact?: Maybe<RelatedArtifactConnectFieldInput>;
  valueTriggerDefinition?: Maybe<TriggerDefinitionConnectFieldInput>;
  valueUsageContext?: Maybe<UsageContextConnectFieldInput>;
  valueDosage?: Maybe<DosageConnectFieldInput>;
  valueMeta?: Maybe<MetaConnectFieldInput>;
};

export type ExtensionCreateInput = {
  valueBoolean?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['Uri']>;
  valueBase64Binary?: Maybe<Scalars['String']>;
  valueCanonical?: Maybe<Scalars['String']>;
  valueCode?: Maybe<Scalars['String']>;
  valueDate?: Maybe<Scalars['String']>;
  valueDateTime?: Maybe<Scalars['String']>;
  valueDecimal?: Maybe<Scalars['Number']>;
  valueId?: Maybe<Scalars['String']>;
  valueInstant?: Maybe<Scalars['String']>;
  valueInteger?: Maybe<Scalars['Number']>;
  valueMarkdown?: Maybe<Scalars['String']>;
  valueOid?: Maybe<Scalars['String']>;
  valuePositiveInt?: Maybe<Scalars['Number']>;
  valueString?: Maybe<Scalars['String']>;
  valueTime?: Maybe<Scalars['String']>;
  valueUnsignedInt?: Maybe<Scalars['Number']>;
  valueUri?: Maybe<Scalars['String']>;
  valueUrl?: Maybe<Scalars['String']>;
  valueUuid?: Maybe<Scalars['String']>;
  extension?: Maybe<ExtensionExtensionFieldInput>;
  _url?: Maybe<Extension_UrlFieldInput>;
  _valueBase64Binary?: Maybe<Extension_Valuebase64binaryFieldInput>;
  _valueBoolean?: Maybe<Extension_ValuebooleanFieldInput>;
  _valueCanonical?: Maybe<Extension_ValuecanonicalFieldInput>;
  _valueCode?: Maybe<Extension_ValuecodeFieldInput>;
  _valueDate?: Maybe<Extension_ValuedateFieldInput>;
  _valueDateTime?: Maybe<Extension_ValuedatetimeFieldInput>;
  _valueDecimal?: Maybe<Extension_ValuedecimalFieldInput>;
  _valueId?: Maybe<Extension_ValueidFieldInput>;
  _valueInstant?: Maybe<Extension_ValueinstantFieldInput>;
  _valueInteger?: Maybe<Extension_ValueintegerFieldInput>;
  _valueMarkdown?: Maybe<Extension_ValuemarkdownFieldInput>;
  _valueOid?: Maybe<Extension_ValueoidFieldInput>;
  _valuePositiveInt?: Maybe<Extension_ValuepositiveintFieldInput>;
  _valueString?: Maybe<Extension_ValuestringFieldInput>;
  _valueTime?: Maybe<Extension_ValuetimeFieldInput>;
  _valueUnsignedInt?: Maybe<Extension_ValueunsignedintFieldInput>;
  _valueUri?: Maybe<Extension_ValueuriFieldInput>;
  _valueUrl?: Maybe<Extension_ValueurlFieldInput>;
  _valueUuid?: Maybe<Extension_ValueuuidFieldInput>;
  valueAddress?: Maybe<ExtensionValueaddressFieldInput>;
  valueAge?: Maybe<ExtensionValueageFieldInput>;
  valueAnnotation?: Maybe<ExtensionValueannotationFieldInput>;
  valueAttachment?: Maybe<ExtensionValueattachmentFieldInput>;
  valueCodeableConcept?: Maybe<ExtensionValuecodeableconceptFieldInput>;
  valueCoding?: Maybe<ExtensionValuecodingFieldInput>;
  valueContactPoint?: Maybe<ExtensionValuecontactpointFieldInput>;
  valueCount?: Maybe<ExtensionValuecountFieldInput>;
  valueDistance?: Maybe<ExtensionValuedistanceFieldInput>;
  valueDuration?: Maybe<ExtensionValuedurationFieldInput>;
  valueHumanName?: Maybe<ExtensionValuehumannameFieldInput>;
  valueIdentifier?: Maybe<ExtensionValueidentifierFieldInput>;
  valueMoney?: Maybe<ExtensionValuemoneyFieldInput>;
  valuePeriod?: Maybe<ExtensionValueperiodFieldInput>;
  valueQuantity?: Maybe<ExtensionValuequantityFieldInput>;
  valueRange?: Maybe<ExtensionValuerangeFieldInput>;
  valueRatio?: Maybe<ExtensionValueratioFieldInput>;
  valueReference?: Maybe<ExtensionValuereferenceFieldInput>;
  valueSampledData?: Maybe<ExtensionValuesampleddataFieldInput>;
  valueSignature?: Maybe<ExtensionValuesignatureFieldInput>;
  valueTiming?: Maybe<ExtensionValuetimingFieldInput>;
  valueContactDetail?: Maybe<ExtensionValuecontactdetailFieldInput>;
  valueContributor?: Maybe<ExtensionValuecontributorFieldInput>;
  valueDataRequirement?: Maybe<ExtensionValuedatarequirementFieldInput>;
  valueExpression?: Maybe<ExtensionValueexpressionFieldInput>;
  valueParameterDefinition?: Maybe<ExtensionValueparameterdefinitionFieldInput>;
  valueRelatedArtifact?: Maybe<ExtensionValuerelatedartifactFieldInput>;
  valueTriggerDefinition?: Maybe<ExtensionValuetriggerdefinitionFieldInput>;
  valueUsageContext?: Maybe<ExtensionValueusagecontextFieldInput>;
  valueDosage?: Maybe<ExtensionValuedosageFieldInput>;
  valueMeta?: Maybe<ExtensionValuemetaFieldInput>;
};

export type ExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ExtensionDeleteInput = {
  extension?: Maybe<Array<ExtensionExtensionDeleteFieldInput>>;
  _url?: Maybe<Extension_UrlDeleteFieldInput>;
  _valueBase64Binary?: Maybe<Extension_Valuebase64binaryDeleteFieldInput>;
  _valueBoolean?: Maybe<Extension_ValuebooleanDeleteFieldInput>;
  _valueCanonical?: Maybe<Extension_ValuecanonicalDeleteFieldInput>;
  _valueCode?: Maybe<Extension_ValuecodeDeleteFieldInput>;
  _valueDate?: Maybe<Extension_ValuedateDeleteFieldInput>;
  _valueDateTime?: Maybe<Extension_ValuedatetimeDeleteFieldInput>;
  _valueDecimal?: Maybe<Extension_ValuedecimalDeleteFieldInput>;
  _valueId?: Maybe<Extension_ValueidDeleteFieldInput>;
  _valueInstant?: Maybe<Extension_ValueinstantDeleteFieldInput>;
  _valueInteger?: Maybe<Extension_ValueintegerDeleteFieldInput>;
  _valueMarkdown?: Maybe<Extension_ValuemarkdownDeleteFieldInput>;
  _valueOid?: Maybe<Extension_ValueoidDeleteFieldInput>;
  _valuePositiveInt?: Maybe<Extension_ValuepositiveintDeleteFieldInput>;
  _valueString?: Maybe<Extension_ValuestringDeleteFieldInput>;
  _valueTime?: Maybe<Extension_ValuetimeDeleteFieldInput>;
  _valueUnsignedInt?: Maybe<Extension_ValueunsignedintDeleteFieldInput>;
  _valueUri?: Maybe<Extension_ValueuriDeleteFieldInput>;
  _valueUrl?: Maybe<Extension_ValueurlDeleteFieldInput>;
  _valueUuid?: Maybe<Extension_ValueuuidDeleteFieldInput>;
  valueAddress?: Maybe<ExtensionValueaddressDeleteFieldInput>;
  valueAge?: Maybe<ExtensionValueageDeleteFieldInput>;
  valueAnnotation?: Maybe<ExtensionValueannotationDeleteFieldInput>;
  valueAttachment?: Maybe<ExtensionValueattachmentDeleteFieldInput>;
  valueCodeableConcept?: Maybe<ExtensionValuecodeableconceptDeleteFieldInput>;
  valueCoding?: Maybe<ExtensionValuecodingDeleteFieldInput>;
  valueContactPoint?: Maybe<ExtensionValuecontactpointDeleteFieldInput>;
  valueCount?: Maybe<ExtensionValuecountDeleteFieldInput>;
  valueDistance?: Maybe<ExtensionValuedistanceDeleteFieldInput>;
  valueDuration?: Maybe<ExtensionValuedurationDeleteFieldInput>;
  valueHumanName?: Maybe<ExtensionValuehumannameDeleteFieldInput>;
  valueIdentifier?: Maybe<ExtensionValueidentifierDeleteFieldInput>;
  valueMoney?: Maybe<ExtensionValuemoneyDeleteFieldInput>;
  valuePeriod?: Maybe<ExtensionValueperiodDeleteFieldInput>;
  valueQuantity?: Maybe<ExtensionValuequantityDeleteFieldInput>;
  valueRange?: Maybe<ExtensionValuerangeDeleteFieldInput>;
  valueRatio?: Maybe<ExtensionValueratioDeleteFieldInput>;
  valueReference?: Maybe<ExtensionValuereferenceDeleteFieldInput>;
  valueSampledData?: Maybe<ExtensionValuesampleddataDeleteFieldInput>;
  valueSignature?: Maybe<ExtensionValuesignatureDeleteFieldInput>;
  valueTiming?: Maybe<ExtensionValuetimingDeleteFieldInput>;
  valueContactDetail?: Maybe<ExtensionValuecontactdetailDeleteFieldInput>;
  valueContributor?: Maybe<ExtensionValuecontributorDeleteFieldInput>;
  valueDataRequirement?: Maybe<ExtensionValuedatarequirementDeleteFieldInput>;
  valueExpression?: Maybe<ExtensionValueexpressionDeleteFieldInput>;
  valueParameterDefinition?: Maybe<ExtensionValueparameterdefinitionDeleteFieldInput>;
  valueRelatedArtifact?: Maybe<ExtensionValuerelatedartifactDeleteFieldInput>;
  valueTriggerDefinition?: Maybe<ExtensionValuetriggerdefinitionDeleteFieldInput>;
  valueUsageContext?: Maybe<ExtensionValueusagecontextDeleteFieldInput>;
  valueDosage?: Maybe<ExtensionValuedosageDeleteFieldInput>;
  valueMeta?: Maybe<ExtensionValuemetaDeleteFieldInput>;
};

export type ExtensionDisconnectFieldInput = {
  where?: Maybe<ExtensionWhere>;
  disconnect?: Maybe<ExtensionDisconnectInput>;
};

export type ExtensionDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _url?: Maybe<ElementDisconnectFieldInput>;
  _valueBase64Binary?: Maybe<ElementDisconnectFieldInput>;
  _valueBoolean?: Maybe<ElementDisconnectFieldInput>;
  _valueCanonical?: Maybe<ElementDisconnectFieldInput>;
  _valueCode?: Maybe<ElementDisconnectFieldInput>;
  _valueDate?: Maybe<ElementDisconnectFieldInput>;
  _valueDateTime?: Maybe<ElementDisconnectFieldInput>;
  _valueDecimal?: Maybe<ElementDisconnectFieldInput>;
  _valueId?: Maybe<ElementDisconnectFieldInput>;
  _valueInstant?: Maybe<ElementDisconnectFieldInput>;
  _valueInteger?: Maybe<ElementDisconnectFieldInput>;
  _valueMarkdown?: Maybe<ElementDisconnectFieldInput>;
  _valueOid?: Maybe<ElementDisconnectFieldInput>;
  _valuePositiveInt?: Maybe<ElementDisconnectFieldInput>;
  _valueString?: Maybe<ElementDisconnectFieldInput>;
  _valueTime?: Maybe<ElementDisconnectFieldInput>;
  _valueUnsignedInt?: Maybe<ElementDisconnectFieldInput>;
  _valueUri?: Maybe<ElementDisconnectFieldInput>;
  _valueUrl?: Maybe<ElementDisconnectFieldInput>;
  _valueUuid?: Maybe<ElementDisconnectFieldInput>;
  valueAddress?: Maybe<AddressDisconnectFieldInput>;
  valueAge?: Maybe<AgeDisconnectFieldInput>;
  valueAnnotation?: Maybe<AnnotationDisconnectFieldInput>;
  valueAttachment?: Maybe<AttachmentDisconnectFieldInput>;
  valueCodeableConcept?: Maybe<CodeableConceptDisconnectFieldInput>;
  valueCoding?: Maybe<CodingDisconnectFieldInput>;
  valueContactPoint?: Maybe<ContactPointDisconnectFieldInput>;
  valueCount?: Maybe<CountDisconnectFieldInput>;
  valueDistance?: Maybe<DistanceDisconnectFieldInput>;
  valueDuration?: Maybe<DurationDisconnectFieldInput>;
  valueHumanName?: Maybe<HumanNameDisconnectFieldInput>;
  valueIdentifier?: Maybe<IdentifierDisconnectFieldInput>;
  valueMoney?: Maybe<MoneyDisconnectFieldInput>;
  valuePeriod?: Maybe<PeriodDisconnectFieldInput>;
  valueQuantity?: Maybe<QuantityDisconnectFieldInput>;
  valueRange?: Maybe<RangeDisconnectFieldInput>;
  valueRatio?: Maybe<RatioDisconnectFieldInput>;
  valueReference?: Maybe<ReferenceDisconnectFieldInput>;
  valueSampledData?: Maybe<SampledDataDisconnectFieldInput>;
  valueSignature?: Maybe<SignatureDisconnectFieldInput>;
  valueTiming?: Maybe<TimingDisconnectFieldInput>;
  valueContactDetail?: Maybe<ContactDetailDisconnectFieldInput>;
  valueContributor?: Maybe<ContributorDisconnectFieldInput>;
  valueDataRequirement?: Maybe<DataRequirementDisconnectFieldInput>;
  valueExpression?: Maybe<ExpressionDisconnectFieldInput>;
  valueParameterDefinition?: Maybe<ParameterDefinitionDisconnectFieldInput>;
  valueRelatedArtifact?: Maybe<RelatedArtifactDisconnectFieldInput>;
  valueTriggerDefinition?: Maybe<TriggerDefinitionDisconnectFieldInput>;
  valueUsageContext?: Maybe<UsageContextDisconnectFieldInput>;
  valueDosage?: Maybe<DosageDisconnectFieldInput>;
  valueMeta?: Maybe<MetaDisconnectFieldInput>;
};

export type ExtensionExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ExtensionExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ExtensionExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ExtensionOptions = {
  /** Specify one or more ExtensionSort objects to sort Extensions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ExtensionSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ExtensionRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _url?: Maybe<ElementCreateInput>;
  _valueBase64Binary?: Maybe<ElementCreateInput>;
  _valueBoolean?: Maybe<ElementCreateInput>;
  _valueCanonical?: Maybe<ElementCreateInput>;
  _valueCode?: Maybe<ElementCreateInput>;
  _valueDate?: Maybe<ElementCreateInput>;
  _valueDateTime?: Maybe<ElementCreateInput>;
  _valueDecimal?: Maybe<ElementCreateInput>;
  _valueId?: Maybe<ElementCreateInput>;
  _valueInstant?: Maybe<ElementCreateInput>;
  _valueInteger?: Maybe<ElementCreateInput>;
  _valueMarkdown?: Maybe<ElementCreateInput>;
  _valueOid?: Maybe<ElementCreateInput>;
  _valuePositiveInt?: Maybe<ElementCreateInput>;
  _valueString?: Maybe<ElementCreateInput>;
  _valueTime?: Maybe<ElementCreateInput>;
  _valueUnsignedInt?: Maybe<ElementCreateInput>;
  _valueUri?: Maybe<ElementCreateInput>;
  _valueUrl?: Maybe<ElementCreateInput>;
  _valueUuid?: Maybe<ElementCreateInput>;
  valueAddress?: Maybe<AddressCreateInput>;
  valueAge?: Maybe<AgeCreateInput>;
  valueAnnotation?: Maybe<AnnotationCreateInput>;
  valueAttachment?: Maybe<AttachmentCreateInput>;
  valueCodeableConcept?: Maybe<CodeableConceptCreateInput>;
  valueCoding?: Maybe<CodingCreateInput>;
  valueContactPoint?: Maybe<ContactPointCreateInput>;
  valueCount?: Maybe<CountCreateInput>;
  valueDistance?: Maybe<DistanceCreateInput>;
  valueDuration?: Maybe<DurationCreateInput>;
  valueHumanName?: Maybe<HumanNameCreateInput>;
  valueIdentifier?: Maybe<IdentifierCreateInput>;
  valueMoney?: Maybe<MoneyCreateInput>;
  valuePeriod?: Maybe<PeriodCreateInput>;
  valueQuantity?: Maybe<QuantityCreateInput>;
  valueRange?: Maybe<RangeCreateInput>;
  valueRatio?: Maybe<RatioCreateInput>;
  valueReference?: Maybe<ReferenceCreateInput>;
  valueSampledData?: Maybe<SampledDataCreateInput>;
  valueSignature?: Maybe<SignatureCreateInput>;
  valueTiming?: Maybe<TimingCreateInput>;
  valueContactDetail?: Maybe<ContactDetailCreateInput>;
  valueContributor?: Maybe<ContributorCreateInput>;
  valueDataRequirement?: Maybe<DataRequirementCreateInput>;
  valueExpression?: Maybe<ExpressionCreateInput>;
  valueParameterDefinition?: Maybe<ParameterDefinitionCreateInput>;
  valueRelatedArtifact?: Maybe<RelatedArtifactCreateInput>;
  valueTriggerDefinition?: Maybe<TriggerDefinitionCreateInput>;
  valueUsageContext?: Maybe<UsageContextCreateInput>;
  valueDosage?: Maybe<DosageCreateInput>;
  valueMeta?: Maybe<MetaCreateInput>;
};

/** Fields to sort Extensions by. The order in which sorts are applied is not guaranteed when specifying many fields in one ExtensionSort object. */
export type ExtensionSort = {
  id?: Maybe<SortDirection>;
  valueBoolean?: Maybe<SortDirection>;
  url?: Maybe<SortDirection>;
  valueBase64Binary?: Maybe<SortDirection>;
  valueCanonical?: Maybe<SortDirection>;
  valueCode?: Maybe<SortDirection>;
  valueDate?: Maybe<SortDirection>;
  valueDateTime?: Maybe<SortDirection>;
  valueDecimal?: Maybe<SortDirection>;
  valueId?: Maybe<SortDirection>;
  valueInstant?: Maybe<SortDirection>;
  valueInteger?: Maybe<SortDirection>;
  valueMarkdown?: Maybe<SortDirection>;
  valueOid?: Maybe<SortDirection>;
  valuePositiveInt?: Maybe<SortDirection>;
  valueString?: Maybe<SortDirection>;
  valueTime?: Maybe<SortDirection>;
  valueUnsignedInt?: Maybe<SortDirection>;
  valueUri?: Maybe<SortDirection>;
  valueUrl?: Maybe<SortDirection>;
  valueUuid?: Maybe<SortDirection>;
};

export type ExtensionUpdateInput = {
  valueBoolean?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['Uri']>;
  valueBase64Binary?: Maybe<Scalars['String']>;
  valueCanonical?: Maybe<Scalars['String']>;
  valueCode?: Maybe<Scalars['String']>;
  valueDate?: Maybe<Scalars['String']>;
  valueDateTime?: Maybe<Scalars['String']>;
  valueDecimal?: Maybe<Scalars['Number']>;
  valueId?: Maybe<Scalars['String']>;
  valueInstant?: Maybe<Scalars['String']>;
  valueInteger?: Maybe<Scalars['Number']>;
  valueMarkdown?: Maybe<Scalars['String']>;
  valueOid?: Maybe<Scalars['String']>;
  valuePositiveInt?: Maybe<Scalars['Number']>;
  valueString?: Maybe<Scalars['String']>;
  valueTime?: Maybe<Scalars['String']>;
  valueUnsignedInt?: Maybe<Scalars['Number']>;
  valueUri?: Maybe<Scalars['String']>;
  valueUrl?: Maybe<Scalars['String']>;
  valueUuid?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<ExtensionExtensionUpdateFieldInput>>;
  _url?: Maybe<Extension_UrlUpdateFieldInput>;
  _valueBase64Binary?: Maybe<Extension_Valuebase64binaryUpdateFieldInput>;
  _valueBoolean?: Maybe<Extension_ValuebooleanUpdateFieldInput>;
  _valueCanonical?: Maybe<Extension_ValuecanonicalUpdateFieldInput>;
  _valueCode?: Maybe<Extension_ValuecodeUpdateFieldInput>;
  _valueDate?: Maybe<Extension_ValuedateUpdateFieldInput>;
  _valueDateTime?: Maybe<Extension_ValuedatetimeUpdateFieldInput>;
  _valueDecimal?: Maybe<Extension_ValuedecimalUpdateFieldInput>;
  _valueId?: Maybe<Extension_ValueidUpdateFieldInput>;
  _valueInstant?: Maybe<Extension_ValueinstantUpdateFieldInput>;
  _valueInteger?: Maybe<Extension_ValueintegerUpdateFieldInput>;
  _valueMarkdown?: Maybe<Extension_ValuemarkdownUpdateFieldInput>;
  _valueOid?: Maybe<Extension_ValueoidUpdateFieldInput>;
  _valuePositiveInt?: Maybe<Extension_ValuepositiveintUpdateFieldInput>;
  _valueString?: Maybe<Extension_ValuestringUpdateFieldInput>;
  _valueTime?: Maybe<Extension_ValuetimeUpdateFieldInput>;
  _valueUnsignedInt?: Maybe<Extension_ValueunsignedintUpdateFieldInput>;
  _valueUri?: Maybe<Extension_ValueuriUpdateFieldInput>;
  _valueUrl?: Maybe<Extension_ValueurlUpdateFieldInput>;
  _valueUuid?: Maybe<Extension_ValueuuidUpdateFieldInput>;
  valueAddress?: Maybe<ExtensionValueaddressUpdateFieldInput>;
  valueAge?: Maybe<ExtensionValueageUpdateFieldInput>;
  valueAnnotation?: Maybe<ExtensionValueannotationUpdateFieldInput>;
  valueAttachment?: Maybe<ExtensionValueattachmentUpdateFieldInput>;
  valueCodeableConcept?: Maybe<ExtensionValuecodeableconceptUpdateFieldInput>;
  valueCoding?: Maybe<ExtensionValuecodingUpdateFieldInput>;
  valueContactPoint?: Maybe<ExtensionValuecontactpointUpdateFieldInput>;
  valueCount?: Maybe<ExtensionValuecountUpdateFieldInput>;
  valueDistance?: Maybe<ExtensionValuedistanceUpdateFieldInput>;
  valueDuration?: Maybe<ExtensionValuedurationUpdateFieldInput>;
  valueHumanName?: Maybe<ExtensionValuehumannameUpdateFieldInput>;
  valueIdentifier?: Maybe<ExtensionValueidentifierUpdateFieldInput>;
  valueMoney?: Maybe<ExtensionValuemoneyUpdateFieldInput>;
  valuePeriod?: Maybe<ExtensionValueperiodUpdateFieldInput>;
  valueQuantity?: Maybe<ExtensionValuequantityUpdateFieldInput>;
  valueRange?: Maybe<ExtensionValuerangeUpdateFieldInput>;
  valueRatio?: Maybe<ExtensionValueratioUpdateFieldInput>;
  valueReference?: Maybe<ExtensionValuereferenceUpdateFieldInput>;
  valueSampledData?: Maybe<ExtensionValuesampleddataUpdateFieldInput>;
  valueSignature?: Maybe<ExtensionValuesignatureUpdateFieldInput>;
  valueTiming?: Maybe<ExtensionValuetimingUpdateFieldInput>;
  valueContactDetail?: Maybe<ExtensionValuecontactdetailUpdateFieldInput>;
  valueContributor?: Maybe<ExtensionValuecontributorUpdateFieldInput>;
  valueDataRequirement?: Maybe<ExtensionValuedatarequirementUpdateFieldInput>;
  valueExpression?: Maybe<ExtensionValueexpressionUpdateFieldInput>;
  valueParameterDefinition?: Maybe<ExtensionValueparameterdefinitionUpdateFieldInput>;
  valueRelatedArtifact?: Maybe<ExtensionValuerelatedartifactUpdateFieldInput>;
  valueTriggerDefinition?: Maybe<ExtensionValuetriggerdefinitionUpdateFieldInput>;
  valueUsageContext?: Maybe<ExtensionValueusagecontextUpdateFieldInput>;
  valueDosage?: Maybe<ExtensionValuedosageUpdateFieldInput>;
  valueMeta?: Maybe<ExtensionValuemetaUpdateFieldInput>;
};

export type ExtensionValueaddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type ExtensionValueaddressFieldInput = {
  create?: Maybe<AddressCreateInput>;
  connect?: Maybe<AddressConnectFieldInput>;
};

export type ExtensionValueaddressUpdateFieldInput = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<AddressConnectFieldInput>;
  disconnect?: Maybe<AddressDisconnectFieldInput>;
  create?: Maybe<AddressCreateInput>;
  delete?: Maybe<AddressDeleteFieldInput>;
};

export type ExtensionValueageDeleteFieldInput = {
  where?: Maybe<AgeWhere>;
  delete?: Maybe<AgeDeleteInput>;
};

export type ExtensionValueageFieldInput = {
  create?: Maybe<AgeCreateInput>;
  connect?: Maybe<AgeConnectFieldInput>;
};

export type ExtensionValueageUpdateFieldInput = {
  where?: Maybe<AgeWhere>;
  update?: Maybe<AgeUpdateInput>;
  connect?: Maybe<AgeConnectFieldInput>;
  disconnect?: Maybe<AgeDisconnectFieldInput>;
  create?: Maybe<AgeCreateInput>;
  delete?: Maybe<AgeDeleteFieldInput>;
};

export type ExtensionValueannotationDeleteFieldInput = {
  where?: Maybe<AnnotationWhere>;
  delete?: Maybe<AnnotationDeleteInput>;
};

export type ExtensionValueannotationFieldInput = {
  create?: Maybe<AnnotationCreateInput>;
  connect?: Maybe<AnnotationConnectFieldInput>;
};

export type ExtensionValueannotationUpdateFieldInput = {
  where?: Maybe<AnnotationWhere>;
  update?: Maybe<AnnotationUpdateInput>;
  connect?: Maybe<AnnotationConnectFieldInput>;
  disconnect?: Maybe<AnnotationDisconnectFieldInput>;
  create?: Maybe<AnnotationCreateInput>;
  delete?: Maybe<AnnotationDeleteFieldInput>;
};

export type ExtensionValueattachmentDeleteFieldInput = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type ExtensionValueattachmentFieldInput = {
  create?: Maybe<AttachmentCreateInput>;
  connect?: Maybe<AttachmentConnectFieldInput>;
};

export type ExtensionValueattachmentUpdateFieldInput = {
  where?: Maybe<AttachmentWhere>;
  update?: Maybe<AttachmentUpdateInput>;
  connect?: Maybe<AttachmentConnectFieldInput>;
  disconnect?: Maybe<AttachmentDisconnectFieldInput>;
  create?: Maybe<AttachmentCreateInput>;
  delete?: Maybe<AttachmentDeleteFieldInput>;
};

export type ExtensionValuecodeableconceptDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type ExtensionValuecodeableconceptFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type ExtensionValuecodeableconceptUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type ExtensionValuecodingDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type ExtensionValuecodingFieldInput = {
  create?: Maybe<CodingCreateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
};

export type ExtensionValuecodingUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
  disconnect?: Maybe<CodingDisconnectFieldInput>;
  create?: Maybe<CodingCreateInput>;
  delete?: Maybe<CodingDeleteFieldInput>;
};

export type ExtensionValuecontactdetailDeleteFieldInput = {
  where?: Maybe<ContactDetailWhere>;
  delete?: Maybe<ContactDetailDeleteInput>;
};

export type ExtensionValuecontactdetailFieldInput = {
  create?: Maybe<ContactDetailCreateInput>;
  connect?: Maybe<ContactDetailConnectFieldInput>;
};

export type ExtensionValuecontactdetailUpdateFieldInput = {
  where?: Maybe<ContactDetailWhere>;
  update?: Maybe<ContactDetailUpdateInput>;
  connect?: Maybe<ContactDetailConnectFieldInput>;
  disconnect?: Maybe<ContactDetailDisconnectFieldInput>;
  create?: Maybe<ContactDetailCreateInput>;
  delete?: Maybe<ContactDetailDeleteFieldInput>;
};

export type ExtensionValuecontactpointDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type ExtensionValuecontactpointFieldInput = {
  create?: Maybe<ContactPointCreateInput>;
  connect?: Maybe<ContactPointConnectFieldInput>;
};

export type ExtensionValuecontactpointUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<ContactPointConnectFieldInput>;
  disconnect?: Maybe<ContactPointDisconnectFieldInput>;
  create?: Maybe<ContactPointCreateInput>;
  delete?: Maybe<ContactPointDeleteFieldInput>;
};

export type ExtensionValuecontributorDeleteFieldInput = {
  where?: Maybe<ContributorWhere>;
  delete?: Maybe<ContributorDeleteInput>;
};

export type ExtensionValuecontributorFieldInput = {
  create?: Maybe<ContributorCreateInput>;
  connect?: Maybe<ContributorConnectFieldInput>;
};

export type ExtensionValuecontributorUpdateFieldInput = {
  where?: Maybe<ContributorWhere>;
  update?: Maybe<ContributorUpdateInput>;
  connect?: Maybe<ContributorConnectFieldInput>;
  disconnect?: Maybe<ContributorDisconnectFieldInput>;
  create?: Maybe<ContributorCreateInput>;
  delete?: Maybe<ContributorDeleteFieldInput>;
};

export type ExtensionValuecountDeleteFieldInput = {
  where?: Maybe<CountWhere>;
  delete?: Maybe<CountDeleteInput>;
};

export type ExtensionValuecountFieldInput = {
  create?: Maybe<CountCreateInput>;
  connect?: Maybe<CountConnectFieldInput>;
};

export type ExtensionValuecountUpdateFieldInput = {
  where?: Maybe<CountWhere>;
  update?: Maybe<CountUpdateInput>;
  connect?: Maybe<CountConnectFieldInput>;
  disconnect?: Maybe<CountDisconnectFieldInput>;
  create?: Maybe<CountCreateInput>;
  delete?: Maybe<CountDeleteFieldInput>;
};

export type ExtensionValuedatarequirementDeleteFieldInput = {
  where?: Maybe<DataRequirementWhere>;
  delete?: Maybe<DataRequirementDeleteInput>;
};

export type ExtensionValuedatarequirementFieldInput = {
  create?: Maybe<DataRequirementCreateInput>;
  connect?: Maybe<DataRequirementConnectFieldInput>;
};

export type ExtensionValuedatarequirementUpdateFieldInput = {
  where?: Maybe<DataRequirementWhere>;
  update?: Maybe<DataRequirementUpdateInput>;
  connect?: Maybe<DataRequirementConnectFieldInput>;
  disconnect?: Maybe<DataRequirementDisconnectFieldInput>;
  create?: Maybe<DataRequirementCreateInput>;
  delete?: Maybe<DataRequirementDeleteFieldInput>;
};

export type ExtensionValuedistanceDeleteFieldInput = {
  where?: Maybe<DistanceWhere>;
  delete?: Maybe<DistanceDeleteInput>;
};

export type ExtensionValuedistanceFieldInput = {
  create?: Maybe<DistanceCreateInput>;
  connect?: Maybe<DistanceConnectFieldInput>;
};

export type ExtensionValuedistanceUpdateFieldInput = {
  where?: Maybe<DistanceWhere>;
  update?: Maybe<DistanceUpdateInput>;
  connect?: Maybe<DistanceConnectFieldInput>;
  disconnect?: Maybe<DistanceDisconnectFieldInput>;
  create?: Maybe<DistanceCreateInput>;
  delete?: Maybe<DistanceDeleteFieldInput>;
};

export type ExtensionValuedosageDeleteFieldInput = {
  where?: Maybe<DosageWhere>;
  delete?: Maybe<DosageDeleteInput>;
};

export type ExtensionValuedosageFieldInput = {
  create?: Maybe<DosageCreateInput>;
  connect?: Maybe<DosageConnectFieldInput>;
};

export type ExtensionValuedosageUpdateFieldInput = {
  where?: Maybe<DosageWhere>;
  update?: Maybe<DosageUpdateInput>;
  connect?: Maybe<DosageConnectFieldInput>;
  disconnect?: Maybe<DosageDisconnectFieldInput>;
  create?: Maybe<DosageCreateInput>;
  delete?: Maybe<DosageDeleteFieldInput>;
};

export type ExtensionValuedurationDeleteFieldInput = {
  where?: Maybe<DurationWhere>;
  delete?: Maybe<DurationDeleteInput>;
};

export type ExtensionValuedurationFieldInput = {
  create?: Maybe<DurationCreateInput>;
  connect?: Maybe<DurationConnectFieldInput>;
};

export type ExtensionValuedurationUpdateFieldInput = {
  where?: Maybe<DurationWhere>;
  update?: Maybe<DurationUpdateInput>;
  connect?: Maybe<DurationConnectFieldInput>;
  disconnect?: Maybe<DurationDisconnectFieldInput>;
  create?: Maybe<DurationCreateInput>;
  delete?: Maybe<DurationDeleteFieldInput>;
};

export type ExtensionValueexpressionDeleteFieldInput = {
  where?: Maybe<ExpressionWhere>;
  delete?: Maybe<ExpressionDeleteInput>;
};

export type ExtensionValueexpressionFieldInput = {
  create?: Maybe<ExpressionCreateInput>;
  connect?: Maybe<ExpressionConnectFieldInput>;
};

export type ExtensionValueexpressionUpdateFieldInput = {
  where?: Maybe<ExpressionWhere>;
  update?: Maybe<ExpressionUpdateInput>;
  connect?: Maybe<ExpressionConnectFieldInput>;
  disconnect?: Maybe<ExpressionDisconnectFieldInput>;
  create?: Maybe<ExpressionCreateInput>;
  delete?: Maybe<ExpressionDeleteFieldInput>;
};

export type ExtensionValuehumannameDeleteFieldInput = {
  where?: Maybe<HumanNameWhere>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type ExtensionValuehumannameFieldInput = {
  create?: Maybe<HumanNameCreateInput>;
  connect?: Maybe<HumanNameConnectFieldInput>;
};

export type ExtensionValuehumannameUpdateFieldInput = {
  where?: Maybe<HumanNameWhere>;
  update?: Maybe<HumanNameUpdateInput>;
  connect?: Maybe<HumanNameConnectFieldInput>;
  disconnect?: Maybe<HumanNameDisconnectFieldInput>;
  create?: Maybe<HumanNameCreateInput>;
  delete?: Maybe<HumanNameDeleteFieldInput>;
};

export type ExtensionValueidentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type ExtensionValueidentifierFieldInput = {
  create?: Maybe<IdentifierCreateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
};

export type ExtensionValueidentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
  disconnect?: Maybe<IdentifierDisconnectFieldInput>;
  create?: Maybe<IdentifierCreateInput>;
  delete?: Maybe<IdentifierDeleteFieldInput>;
};

export type ExtensionValuemetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type ExtensionValuemetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type ExtensionValuemetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type ExtensionValuemoneyDeleteFieldInput = {
  where?: Maybe<MoneyWhere>;
  delete?: Maybe<MoneyDeleteInput>;
};

export type ExtensionValuemoneyFieldInput = {
  create?: Maybe<MoneyCreateInput>;
  connect?: Maybe<MoneyConnectFieldInput>;
};

export type ExtensionValuemoneyUpdateFieldInput = {
  where?: Maybe<MoneyWhere>;
  update?: Maybe<MoneyUpdateInput>;
  connect?: Maybe<MoneyConnectFieldInput>;
  disconnect?: Maybe<MoneyDisconnectFieldInput>;
  create?: Maybe<MoneyCreateInput>;
  delete?: Maybe<MoneyDeleteFieldInput>;
};

export type ExtensionValueparameterdefinitionDeleteFieldInput = {
  where?: Maybe<ParameterDefinitionWhere>;
  delete?: Maybe<ParameterDefinitionDeleteInput>;
};

export type ExtensionValueparameterdefinitionFieldInput = {
  create?: Maybe<ParameterDefinitionCreateInput>;
  connect?: Maybe<ParameterDefinitionConnectFieldInput>;
};

export type ExtensionValueparameterdefinitionUpdateFieldInput = {
  where?: Maybe<ParameterDefinitionWhere>;
  update?: Maybe<ParameterDefinitionUpdateInput>;
  connect?: Maybe<ParameterDefinitionConnectFieldInput>;
  disconnect?: Maybe<ParameterDefinitionDisconnectFieldInput>;
  create?: Maybe<ParameterDefinitionCreateInput>;
  delete?: Maybe<ParameterDefinitionDeleteFieldInput>;
};

export type ExtensionValueperiodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type ExtensionValueperiodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type ExtensionValueperiodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type ExtensionValuequantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type ExtensionValuequantityFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type ExtensionValuequantityUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type ExtensionValuerangeDeleteFieldInput = {
  where?: Maybe<RangeWhere>;
  delete?: Maybe<RangeDeleteInput>;
};

export type ExtensionValuerangeFieldInput = {
  create?: Maybe<RangeCreateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
};

export type ExtensionValuerangeUpdateFieldInput = {
  where?: Maybe<RangeWhere>;
  update?: Maybe<RangeUpdateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
  disconnect?: Maybe<RangeDisconnectFieldInput>;
  create?: Maybe<RangeCreateInput>;
  delete?: Maybe<RangeDeleteFieldInput>;
};

export type ExtensionValueratioDeleteFieldInput = {
  where?: Maybe<RatioWhere>;
  delete?: Maybe<RatioDeleteInput>;
};

export type ExtensionValueratioFieldInput = {
  create?: Maybe<RatioCreateInput>;
  connect?: Maybe<RatioConnectFieldInput>;
};

export type ExtensionValueratioUpdateFieldInput = {
  where?: Maybe<RatioWhere>;
  update?: Maybe<RatioUpdateInput>;
  connect?: Maybe<RatioConnectFieldInput>;
  disconnect?: Maybe<RatioDisconnectFieldInput>;
  create?: Maybe<RatioCreateInput>;
  delete?: Maybe<RatioDeleteFieldInput>;
};

export type ExtensionValuereferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type ExtensionValuereferenceFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type ExtensionValuereferenceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type ExtensionValuerelatedartifactDeleteFieldInput = {
  where?: Maybe<RelatedArtifactWhere>;
  delete?: Maybe<RelatedArtifactDeleteInput>;
};

export type ExtensionValuerelatedartifactFieldInput = {
  create?: Maybe<RelatedArtifactCreateInput>;
  connect?: Maybe<RelatedArtifactConnectFieldInput>;
};

export type ExtensionValuerelatedartifactUpdateFieldInput = {
  where?: Maybe<RelatedArtifactWhere>;
  update?: Maybe<RelatedArtifactUpdateInput>;
  connect?: Maybe<RelatedArtifactConnectFieldInput>;
  disconnect?: Maybe<RelatedArtifactDisconnectFieldInput>;
  create?: Maybe<RelatedArtifactCreateInput>;
  delete?: Maybe<RelatedArtifactDeleteFieldInput>;
};

export type ExtensionValuesampleddataDeleteFieldInput = {
  where?: Maybe<SampledDataWhere>;
  delete?: Maybe<SampledDataDeleteInput>;
};

export type ExtensionValuesampleddataFieldInput = {
  create?: Maybe<SampledDataCreateInput>;
  connect?: Maybe<SampledDataConnectFieldInput>;
};

export type ExtensionValuesampleddataUpdateFieldInput = {
  where?: Maybe<SampledDataWhere>;
  update?: Maybe<SampledDataUpdateInput>;
  connect?: Maybe<SampledDataConnectFieldInput>;
  disconnect?: Maybe<SampledDataDisconnectFieldInput>;
  create?: Maybe<SampledDataCreateInput>;
  delete?: Maybe<SampledDataDeleteFieldInput>;
};

export type ExtensionValuesignatureDeleteFieldInput = {
  where?: Maybe<SignatureWhere>;
  delete?: Maybe<SignatureDeleteInput>;
};

export type ExtensionValuesignatureFieldInput = {
  create?: Maybe<SignatureCreateInput>;
  connect?: Maybe<SignatureConnectFieldInput>;
};

export type ExtensionValuesignatureUpdateFieldInput = {
  where?: Maybe<SignatureWhere>;
  update?: Maybe<SignatureUpdateInput>;
  connect?: Maybe<SignatureConnectFieldInput>;
  disconnect?: Maybe<SignatureDisconnectFieldInput>;
  create?: Maybe<SignatureCreateInput>;
  delete?: Maybe<SignatureDeleteFieldInput>;
};

export type ExtensionValuetimingDeleteFieldInput = {
  where?: Maybe<TimingWhere>;
  delete?: Maybe<TimingDeleteInput>;
};

export type ExtensionValuetimingFieldInput = {
  create?: Maybe<TimingCreateInput>;
  connect?: Maybe<TimingConnectFieldInput>;
};

export type ExtensionValuetimingUpdateFieldInput = {
  where?: Maybe<TimingWhere>;
  update?: Maybe<TimingUpdateInput>;
  connect?: Maybe<TimingConnectFieldInput>;
  disconnect?: Maybe<TimingDisconnectFieldInput>;
  create?: Maybe<TimingCreateInput>;
  delete?: Maybe<TimingDeleteFieldInput>;
};

export type ExtensionValuetriggerdefinitionDeleteFieldInput = {
  where?: Maybe<TriggerDefinitionWhere>;
  delete?: Maybe<TriggerDefinitionDeleteInput>;
};

export type ExtensionValuetriggerdefinitionFieldInput = {
  create?: Maybe<TriggerDefinitionCreateInput>;
  connect?: Maybe<TriggerDefinitionConnectFieldInput>;
};

export type ExtensionValuetriggerdefinitionUpdateFieldInput = {
  where?: Maybe<TriggerDefinitionWhere>;
  update?: Maybe<TriggerDefinitionUpdateInput>;
  connect?: Maybe<TriggerDefinitionConnectFieldInput>;
  disconnect?: Maybe<TriggerDefinitionDisconnectFieldInput>;
  create?: Maybe<TriggerDefinitionCreateInput>;
  delete?: Maybe<TriggerDefinitionDeleteFieldInput>;
};

export type ExtensionValueusagecontextDeleteFieldInput = {
  where?: Maybe<UsageContextWhere>;
  delete?: Maybe<UsageContextDeleteInput>;
};

export type ExtensionValueusagecontextFieldInput = {
  create?: Maybe<UsageContextCreateInput>;
  connect?: Maybe<UsageContextConnectFieldInput>;
};

export type ExtensionValueusagecontextUpdateFieldInput = {
  where?: Maybe<UsageContextWhere>;
  update?: Maybe<UsageContextUpdateInput>;
  connect?: Maybe<UsageContextConnectFieldInput>;
  disconnect?: Maybe<UsageContextDisconnectFieldInput>;
  create?: Maybe<UsageContextCreateInput>;
  delete?: Maybe<UsageContextDeleteFieldInput>;
};

export type ExtensionWhere = {
  OR?: Maybe<Array<ExtensionWhere>>;
  AND?: Maybe<Array<ExtensionWhere>>;
  url?: Maybe<Scalars['Uri']>;
  valueBase64Binary?: Maybe<Scalars['String']>;
  valueCanonical?: Maybe<Scalars['String']>;
  valueCode?: Maybe<Scalars['String']>;
  valueDate?: Maybe<Scalars['String']>;
  valueDateTime?: Maybe<Scalars['String']>;
  valueDecimal?: Maybe<Scalars['Number']>;
  valueId?: Maybe<Scalars['String']>;
  valueInstant?: Maybe<Scalars['String']>;
  valueInteger?: Maybe<Scalars['Number']>;
  valueMarkdown?: Maybe<Scalars['String']>;
  valueOid?: Maybe<Scalars['String']>;
  valuePositiveInt?: Maybe<Scalars['Number']>;
  valueString?: Maybe<Scalars['String']>;
  valueTime?: Maybe<Scalars['String']>;
  valueUnsignedInt?: Maybe<Scalars['Number']>;
  valueUri?: Maybe<Scalars['String']>;
  valueUrl?: Maybe<Scalars['String']>;
  valueUuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  valueBoolean?: Maybe<Scalars['Boolean']>;
  valueBoolean_NOT?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _url?: Maybe<ElementWhere>;
  _url_NOT?: Maybe<ElementWhere>;
  _url_IN?: Maybe<Array<ElementWhere>>;
  _url_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueBase64Binary?: Maybe<ElementWhere>;
  _valueBase64Binary_NOT?: Maybe<ElementWhere>;
  _valueBase64Binary_IN?: Maybe<Array<ElementWhere>>;
  _valueBase64Binary_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueBoolean?: Maybe<ElementWhere>;
  _valueBoolean_NOT?: Maybe<ElementWhere>;
  _valueBoolean_IN?: Maybe<Array<ElementWhere>>;
  _valueBoolean_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueCanonical?: Maybe<ElementWhere>;
  _valueCanonical_NOT?: Maybe<ElementWhere>;
  _valueCanonical_IN?: Maybe<Array<ElementWhere>>;
  _valueCanonical_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueCode?: Maybe<ElementWhere>;
  _valueCode_NOT?: Maybe<ElementWhere>;
  _valueCode_IN?: Maybe<Array<ElementWhere>>;
  _valueCode_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueDate?: Maybe<ElementWhere>;
  _valueDate_NOT?: Maybe<ElementWhere>;
  _valueDate_IN?: Maybe<Array<ElementWhere>>;
  _valueDate_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueDateTime?: Maybe<ElementWhere>;
  _valueDateTime_NOT?: Maybe<ElementWhere>;
  _valueDateTime_IN?: Maybe<Array<ElementWhere>>;
  _valueDateTime_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueDecimal?: Maybe<ElementWhere>;
  _valueDecimal_NOT?: Maybe<ElementWhere>;
  _valueDecimal_IN?: Maybe<Array<ElementWhere>>;
  _valueDecimal_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueId?: Maybe<ElementWhere>;
  _valueId_NOT?: Maybe<ElementWhere>;
  _valueId_IN?: Maybe<Array<ElementWhere>>;
  _valueId_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueInstant?: Maybe<ElementWhere>;
  _valueInstant_NOT?: Maybe<ElementWhere>;
  _valueInstant_IN?: Maybe<Array<ElementWhere>>;
  _valueInstant_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueInteger?: Maybe<ElementWhere>;
  _valueInteger_NOT?: Maybe<ElementWhere>;
  _valueInteger_IN?: Maybe<Array<ElementWhere>>;
  _valueInteger_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueMarkdown?: Maybe<ElementWhere>;
  _valueMarkdown_NOT?: Maybe<ElementWhere>;
  _valueMarkdown_IN?: Maybe<Array<ElementWhere>>;
  _valueMarkdown_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueOid?: Maybe<ElementWhere>;
  _valueOid_NOT?: Maybe<ElementWhere>;
  _valueOid_IN?: Maybe<Array<ElementWhere>>;
  _valueOid_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valuePositiveInt?: Maybe<ElementWhere>;
  _valuePositiveInt_NOT?: Maybe<ElementWhere>;
  _valuePositiveInt_IN?: Maybe<Array<ElementWhere>>;
  _valuePositiveInt_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueString?: Maybe<ElementWhere>;
  _valueString_NOT?: Maybe<ElementWhere>;
  _valueString_IN?: Maybe<Array<ElementWhere>>;
  _valueString_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueTime?: Maybe<ElementWhere>;
  _valueTime_NOT?: Maybe<ElementWhere>;
  _valueTime_IN?: Maybe<Array<ElementWhere>>;
  _valueTime_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueUnsignedInt?: Maybe<ElementWhere>;
  _valueUnsignedInt_NOT?: Maybe<ElementWhere>;
  _valueUnsignedInt_IN?: Maybe<Array<ElementWhere>>;
  _valueUnsignedInt_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueUri?: Maybe<ElementWhere>;
  _valueUri_NOT?: Maybe<ElementWhere>;
  _valueUri_IN?: Maybe<Array<ElementWhere>>;
  _valueUri_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueUrl?: Maybe<ElementWhere>;
  _valueUrl_NOT?: Maybe<ElementWhere>;
  _valueUrl_IN?: Maybe<Array<ElementWhere>>;
  _valueUrl_NOT_IN?: Maybe<Array<ElementWhere>>;
  _valueUuid?: Maybe<ElementWhere>;
  _valueUuid_NOT?: Maybe<ElementWhere>;
  _valueUuid_IN?: Maybe<Array<ElementWhere>>;
  _valueUuid_NOT_IN?: Maybe<Array<ElementWhere>>;
  valueAddress?: Maybe<AddressWhere>;
  valueAddress_NOT?: Maybe<AddressWhere>;
  valueAddress_IN?: Maybe<Array<AddressWhere>>;
  valueAddress_NOT_IN?: Maybe<Array<AddressWhere>>;
  valueAge?: Maybe<AgeWhere>;
  valueAge_NOT?: Maybe<AgeWhere>;
  valueAge_IN?: Maybe<Array<AgeWhere>>;
  valueAge_NOT_IN?: Maybe<Array<AgeWhere>>;
  valueAnnotation?: Maybe<AnnotationWhere>;
  valueAnnotation_NOT?: Maybe<AnnotationWhere>;
  valueAnnotation_IN?: Maybe<Array<AnnotationWhere>>;
  valueAnnotation_NOT_IN?: Maybe<Array<AnnotationWhere>>;
  valueAttachment?: Maybe<AttachmentWhere>;
  valueAttachment_NOT?: Maybe<AttachmentWhere>;
  valueAttachment_IN?: Maybe<Array<AttachmentWhere>>;
  valueAttachment_NOT_IN?: Maybe<Array<AttachmentWhere>>;
  valueCodeableConcept?: Maybe<CodeableConceptWhere>;
  valueCodeableConcept_NOT?: Maybe<CodeableConceptWhere>;
  valueCodeableConcept_IN?: Maybe<Array<CodeableConceptWhere>>;
  valueCodeableConcept_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  valueCoding?: Maybe<CodingWhere>;
  valueCoding_NOT?: Maybe<CodingWhere>;
  valueCoding_IN?: Maybe<Array<CodingWhere>>;
  valueCoding_NOT_IN?: Maybe<Array<CodingWhere>>;
  valueContactPoint?: Maybe<ContactPointWhere>;
  valueContactPoint_NOT?: Maybe<ContactPointWhere>;
  valueContactPoint_IN?: Maybe<Array<ContactPointWhere>>;
  valueContactPoint_NOT_IN?: Maybe<Array<ContactPointWhere>>;
  valueCount?: Maybe<CountWhere>;
  valueCount_NOT?: Maybe<CountWhere>;
  valueCount_IN?: Maybe<Array<CountWhere>>;
  valueCount_NOT_IN?: Maybe<Array<CountWhere>>;
  valueDistance?: Maybe<DistanceWhere>;
  valueDistance_NOT?: Maybe<DistanceWhere>;
  valueDistance_IN?: Maybe<Array<DistanceWhere>>;
  valueDistance_NOT_IN?: Maybe<Array<DistanceWhere>>;
  valueDuration?: Maybe<DurationWhere>;
  valueDuration_NOT?: Maybe<DurationWhere>;
  valueDuration_IN?: Maybe<Array<DurationWhere>>;
  valueDuration_NOT_IN?: Maybe<Array<DurationWhere>>;
  valueHumanName?: Maybe<HumanNameWhere>;
  valueHumanName_NOT?: Maybe<HumanNameWhere>;
  valueHumanName_IN?: Maybe<Array<HumanNameWhere>>;
  valueHumanName_NOT_IN?: Maybe<Array<HumanNameWhere>>;
  valueIdentifier?: Maybe<IdentifierWhere>;
  valueIdentifier_NOT?: Maybe<IdentifierWhere>;
  valueIdentifier_IN?: Maybe<Array<IdentifierWhere>>;
  valueIdentifier_NOT_IN?: Maybe<Array<IdentifierWhere>>;
  valueMoney?: Maybe<MoneyWhere>;
  valueMoney_NOT?: Maybe<MoneyWhere>;
  valueMoney_IN?: Maybe<Array<MoneyWhere>>;
  valueMoney_NOT_IN?: Maybe<Array<MoneyWhere>>;
  valuePeriod?: Maybe<PeriodWhere>;
  valuePeriod_NOT?: Maybe<PeriodWhere>;
  valuePeriod_IN?: Maybe<Array<PeriodWhere>>;
  valuePeriod_NOT_IN?: Maybe<Array<PeriodWhere>>;
  valueQuantity?: Maybe<QuantityWhere>;
  valueQuantity_NOT?: Maybe<QuantityWhere>;
  valueQuantity_IN?: Maybe<Array<QuantityWhere>>;
  valueQuantity_NOT_IN?: Maybe<Array<QuantityWhere>>;
  valueRange?: Maybe<RangeWhere>;
  valueRange_NOT?: Maybe<RangeWhere>;
  valueRange_IN?: Maybe<Array<RangeWhere>>;
  valueRange_NOT_IN?: Maybe<Array<RangeWhere>>;
  valueRatio?: Maybe<RatioWhere>;
  valueRatio_NOT?: Maybe<RatioWhere>;
  valueRatio_IN?: Maybe<Array<RatioWhere>>;
  valueRatio_NOT_IN?: Maybe<Array<RatioWhere>>;
  valueReference?: Maybe<ReferenceWhere>;
  valueReference_NOT?: Maybe<ReferenceWhere>;
  valueReference_IN?: Maybe<Array<ReferenceWhere>>;
  valueReference_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  valueSampledData?: Maybe<SampledDataWhere>;
  valueSampledData_NOT?: Maybe<SampledDataWhere>;
  valueSampledData_IN?: Maybe<Array<SampledDataWhere>>;
  valueSampledData_NOT_IN?: Maybe<Array<SampledDataWhere>>;
  valueSignature?: Maybe<SignatureWhere>;
  valueSignature_NOT?: Maybe<SignatureWhere>;
  valueSignature_IN?: Maybe<Array<SignatureWhere>>;
  valueSignature_NOT_IN?: Maybe<Array<SignatureWhere>>;
  valueTiming?: Maybe<TimingWhere>;
  valueTiming_NOT?: Maybe<TimingWhere>;
  valueTiming_IN?: Maybe<Array<TimingWhere>>;
  valueTiming_NOT_IN?: Maybe<Array<TimingWhere>>;
  valueContactDetail?: Maybe<ContactDetailWhere>;
  valueContactDetail_NOT?: Maybe<ContactDetailWhere>;
  valueContactDetail_IN?: Maybe<Array<ContactDetailWhere>>;
  valueContactDetail_NOT_IN?: Maybe<Array<ContactDetailWhere>>;
  valueContributor?: Maybe<ContributorWhere>;
  valueContributor_NOT?: Maybe<ContributorWhere>;
  valueContributor_IN?: Maybe<Array<ContributorWhere>>;
  valueContributor_NOT_IN?: Maybe<Array<ContributorWhere>>;
  valueDataRequirement?: Maybe<DataRequirementWhere>;
  valueDataRequirement_NOT?: Maybe<DataRequirementWhere>;
  valueDataRequirement_IN?: Maybe<Array<DataRequirementWhere>>;
  valueDataRequirement_NOT_IN?: Maybe<Array<DataRequirementWhere>>;
  valueExpression?: Maybe<ExpressionWhere>;
  valueExpression_NOT?: Maybe<ExpressionWhere>;
  valueExpression_IN?: Maybe<Array<ExpressionWhere>>;
  valueExpression_NOT_IN?: Maybe<Array<ExpressionWhere>>;
  valueParameterDefinition?: Maybe<ParameterDefinitionWhere>;
  valueParameterDefinition_NOT?: Maybe<ParameterDefinitionWhere>;
  valueParameterDefinition_IN?: Maybe<Array<ParameterDefinitionWhere>>;
  valueParameterDefinition_NOT_IN?: Maybe<Array<ParameterDefinitionWhere>>;
  valueRelatedArtifact?: Maybe<RelatedArtifactWhere>;
  valueRelatedArtifact_NOT?: Maybe<RelatedArtifactWhere>;
  valueRelatedArtifact_IN?: Maybe<Array<RelatedArtifactWhere>>;
  valueRelatedArtifact_NOT_IN?: Maybe<Array<RelatedArtifactWhere>>;
  valueTriggerDefinition?: Maybe<TriggerDefinitionWhere>;
  valueTriggerDefinition_NOT?: Maybe<TriggerDefinitionWhere>;
  valueTriggerDefinition_IN?: Maybe<Array<TriggerDefinitionWhere>>;
  valueTriggerDefinition_NOT_IN?: Maybe<Array<TriggerDefinitionWhere>>;
  valueUsageContext?: Maybe<UsageContextWhere>;
  valueUsageContext_NOT?: Maybe<UsageContextWhere>;
  valueUsageContext_IN?: Maybe<Array<UsageContextWhere>>;
  valueUsageContext_NOT_IN?: Maybe<Array<UsageContextWhere>>;
  valueDosage?: Maybe<DosageWhere>;
  valueDosage_NOT?: Maybe<DosageWhere>;
  valueDosage_IN?: Maybe<Array<DosageWhere>>;
  valueDosage_NOT_IN?: Maybe<Array<DosageWhere>>;
  valueMeta?: Maybe<MetaWhere>;
  valueMeta_NOT?: Maybe<MetaWhere>;
  valueMeta_IN?: Maybe<Array<MetaWhere>>;
  valueMeta_NOT_IN?: Maybe<Array<MetaWhere>>;
};

export type Extension_UrlDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_UrlFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_UrlUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_Valuebase64binaryDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_Valuebase64binaryFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_Valuebase64binaryUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuebooleanDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuebooleanFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuebooleanUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuecanonicalDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuecanonicalFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuecanonicalUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuecodeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuecodeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuecodeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuedateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuedateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuedateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuedatetimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuedatetimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuedatetimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuedecimalDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuedecimalFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuedecimalUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValueidDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValueidFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValueidUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValueinstantDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValueinstantFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValueinstantUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValueintegerDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValueintegerFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValueintegerUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuemarkdownDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuemarkdownFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuemarkdownUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValueoidDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValueoidFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValueoidUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuepositiveintDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuepositiveintFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuepositiveintUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuestringDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuestringFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuestringUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValuetimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValuetimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValuetimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValueunsignedintDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValueunsignedintFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValueunsignedintUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValueuriDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValueuriFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValueuriUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValueurlDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValueurlFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValueurlUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Extension_ValueuuidDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Extension_ValueuuidFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Extension_ValueuuidUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareService = {
  __typename?: 'HealthcareService';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This flag is used to mark the record to not be used. This is not used when a center is closed for maintenance, or for holidays, the notAvailable period is to be used for this. */
  active?: Maybe<Scalars['Boolean']>;
  /** Indicates whether or not a prospective consumer will require an appointment for a particular service at a site to be provided by the Organization. Indicates if an appointment is required for access to this service. */
  appointmentRequired?: Maybe<Scalars['Boolean']>;
  /** This is a HealthcareService resource */
  resourceType: HealthcareServiceResourceType;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** Further description of the service as it would be presented to a consumer while searching. */
  name?: Maybe<Scalars['String']>;
  /** Any additional description of the service and/or any specific issues not covered by the other attributes, which can be displayed as further detail under the serviceName. */
  comment?: Maybe<Scalars['String']>;
  /** Extra details about the service that can't be placed in the other fields. */
  extraDetails?: Maybe<Scalars['Markdown']>;
  /** A description of site availability exceptions, e.g. public holiday availability. Succinctly describing all possible exceptions to normal site availability as details in the available Times and not available Times. */
  availabilityExceptions?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _active?: Maybe<Element>;
  providedBy?: Maybe<Reference>;
  category: Array<CodeableConcept>;
  type: Array<CodeableConcept>;
  specialty: Array<CodeableConcept>;
  location: Array<Reference>;
  _name?: Maybe<Element>;
  _comment?: Maybe<Element>;
  _extraDetails?: Maybe<Element>;
  photo?: Maybe<Attachment>;
  telecom: Array<ContactPoint>;
  coverageArea: Array<Reference>;
  serviceProvisionCode: Array<CodeableConcept>;
  eligibility: Array<HealthcareServiceEligibility>;
  program: Array<CodeableConcept>;
  characteristic: Array<CodeableConcept>;
  communication: Array<CodeableConcept>;
  referralMethod: Array<CodeableConcept>;
  _appointmentRequired?: Maybe<Element>;
  availableTime: Array<HealthcareServiceAvailableTime>;
  notAvailable: Array<HealthcareServiceNotAvailable>;
  _availabilityExceptions?: Maybe<Element>;
  endpoint: Array<Reference>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareService_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareService_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareService_ActiveArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceProvidedByArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceCategoryArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceSpecialtyArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceLocationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareService_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareService_CommentArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareService_ExtraDetailsArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServicePhotoArgs = {
  where?: Maybe<AttachmentWhere>;
  options?: Maybe<AttachmentOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceCoverageAreaArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceServiceProvisionCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceEligibilityArgs = {
  where?: Maybe<HealthcareServiceEligibilityWhere>;
  options?: Maybe<HealthcareServiceEligibilityOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceProgramArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceCharacteristicArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceCommunicationArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceReferralMethodArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareService_AppointmentRequiredArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceAvailableTimeArgs = {
  where?: Maybe<HealthcareServiceAvailableTimeWhere>;
  options?: Maybe<HealthcareServiceAvailableTimeOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceNotAvailableArgs = {
  where?: Maybe<HealthcareServiceNotAvailableWhere>;
  options?: Maybe<HealthcareServiceNotAvailableOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareService_AvailabilityExceptionsArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceEndpointArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceAvailableTime = {
  __typename?: 'HealthcareServiceAvailableTime';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Is this always available? (hence times are irrelevant) e.g. 24 hour service. */
  allDay?: Maybe<Scalars['Boolean']>;
  /** Indicates which days of the week are available between the start and end Times. */
  daysOfWeek: Array<HealthcareServiceAvailableTimeDaysOfWeek>;
  /** The opening time of day. Note: If the AllDay flag is set, then this time is ignored. */
  availableStartTime?: Maybe<Scalars['Time']>;
  /** The closing time of day. Note: If the AllDay flag is set, then this time is ignored. */
  availableEndTime?: Maybe<Scalars['Time']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _daysOfWeek: Array<Element>;
  _allDay?: Maybe<Element>;
  _availableStartTime?: Maybe<Element>;
  _availableEndTime?: Maybe<Element>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceAvailableTimeExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceAvailableTimeModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceAvailableTime_DaysOfWeekArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceAvailableTime_AllDayArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceAvailableTime_AvailableStartTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceAvailableTime_AvailableEndTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type HealthcareServiceAvailableTimeConnectFieldInput = {
  where?: Maybe<HealthcareServiceAvailableTimeWhere>;
  connect?: Maybe<HealthcareServiceAvailableTimeConnectInput>;
};

export type HealthcareServiceAvailableTimeConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _daysOfWeek?: Maybe<Array<ElementConnectFieldInput>>;
  _allDay?: Maybe<ElementConnectFieldInput>;
  _availableStartTime?: Maybe<ElementConnectFieldInput>;
  _availableEndTime?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareServiceAvailableTimeCreateInput = {
  allDay?: Maybe<Scalars['Boolean']>;
  availableStartTime?: Maybe<Scalars['Time']>;
  availableEndTime?: Maybe<Scalars['Time']>;
  daysOfWeek: Array<HealthcareServiceAvailableTimeDaysOfWeek>;
  extension?: Maybe<HealthcareServiceAvailableTimeExtensionFieldInput>;
  modifierExtension?: Maybe<HealthcareServiceAvailableTimeModifierextensionFieldInput>;
  _daysOfWeek?: Maybe<HealthcareServiceAvailableTime_DaysofweekFieldInput>;
  _allDay?: Maybe<HealthcareServiceAvailableTime_AlldayFieldInput>;
  _availableStartTime?: Maybe<HealthcareServiceAvailableTime_AvailablestarttimeFieldInput>;
  _availableEndTime?: Maybe<HealthcareServiceAvailableTime_AvailableendtimeFieldInput>;
};

export enum HealthcareServiceAvailableTimeDaysOfWeek {
  Mon = 'mon',
  Tue = 'tue',
  Wed = 'wed',
  Thu = 'thu',
  Fri = 'fri',
  Sat = 'sat',
  Sun = 'sun',
}

export type HealthcareServiceAvailableTimeDeleteFieldInput = {
  where?: Maybe<HealthcareServiceAvailableTimeWhere>;
  delete?: Maybe<HealthcareServiceAvailableTimeDeleteInput>;
};

export type HealthcareServiceAvailableTimeDeleteInput = {
  extension?: Maybe<Array<HealthcareServiceAvailableTimeExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<HealthcareServiceAvailableTimeModifierextensionDeleteFieldInput>>;
  _daysOfWeek?: Maybe<Array<HealthcareServiceAvailableTime_DaysofweekDeleteFieldInput>>;
  _allDay?: Maybe<HealthcareServiceAvailableTime_AlldayDeleteFieldInput>;
  _availableStartTime?: Maybe<HealthcareServiceAvailableTime_AvailablestarttimeDeleteFieldInput>;
  _availableEndTime?: Maybe<HealthcareServiceAvailableTime_AvailableendtimeDeleteFieldInput>;
};

export type HealthcareServiceAvailableTimeDisconnectFieldInput = {
  where?: Maybe<HealthcareServiceAvailableTimeWhere>;
  disconnect?: Maybe<HealthcareServiceAvailableTimeDisconnectInput>;
};

export type HealthcareServiceAvailableTimeDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _daysOfWeek?: Maybe<Array<ElementDisconnectFieldInput>>;
  _allDay?: Maybe<ElementDisconnectFieldInput>;
  _availableStartTime?: Maybe<ElementDisconnectFieldInput>;
  _availableEndTime?: Maybe<ElementDisconnectFieldInput>;
};

export type HealthcareServiceAvailableTimeExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type HealthcareServiceAvailableTimeExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type HealthcareServiceAvailableTimeExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type HealthcareServiceAvailableTimeModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type HealthcareServiceAvailableTimeModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type HealthcareServiceAvailableTimeModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type HealthcareServiceAvailableTimeOptions = {
  /** Specify one or more HealthcareServiceAvailableTimeSort objects to sort HealthcareServiceAvailableTimes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<HealthcareServiceAvailableTimeSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type HealthcareServiceAvailableTimeRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _daysOfWeek?: Maybe<Array<ElementCreateInput>>;
  _allDay?: Maybe<ElementCreateInput>;
  _availableStartTime?: Maybe<ElementCreateInput>;
  _availableEndTime?: Maybe<ElementCreateInput>;
};

/** Fields to sort HealthcareServiceAvailableTimes by. The order in which sorts are applied is not guaranteed when specifying many fields in one HealthcareServiceAvailableTimeSort object. */
export type HealthcareServiceAvailableTimeSort = {
  id?: Maybe<SortDirection>;
  allDay?: Maybe<SortDirection>;
  availableStartTime?: Maybe<SortDirection>;
  availableEndTime?: Maybe<SortDirection>;
};

export type HealthcareServiceAvailableTimeUpdateInput = {
  allDay?: Maybe<Scalars['Boolean']>;
  availableStartTime?: Maybe<Scalars['Time']>;
  availableEndTime?: Maybe<Scalars['Time']>;
  daysOfWeek?: Maybe<Array<HealthcareServiceAvailableTimeDaysOfWeek>>;
  extension?: Maybe<Array<HealthcareServiceAvailableTimeExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<HealthcareServiceAvailableTimeModifierextensionUpdateFieldInput>>;
  _daysOfWeek?: Maybe<Array<HealthcareServiceAvailableTime_DaysofweekUpdateFieldInput>>;
  _allDay?: Maybe<HealthcareServiceAvailableTime_AlldayUpdateFieldInput>;
  _availableStartTime?: Maybe<HealthcareServiceAvailableTime_AvailablestarttimeUpdateFieldInput>;
  _availableEndTime?: Maybe<HealthcareServiceAvailableTime_AvailableendtimeUpdateFieldInput>;
};

export type HealthcareServiceAvailableTimeWhere = {
  OR?: Maybe<Array<HealthcareServiceAvailableTimeWhere>>;
  AND?: Maybe<Array<HealthcareServiceAvailableTimeWhere>>;
  availableStartTime?: Maybe<Scalars['Time']>;
  availableEndTime?: Maybe<Scalars['Time']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  allDay?: Maybe<Scalars['Boolean']>;
  allDay_NOT?: Maybe<Scalars['Boolean']>;
  daysOfWeek?: Maybe<Array<HealthcareServiceAvailableTimeDaysOfWeek>>;
  daysOfWeek_NOT?: Maybe<Array<HealthcareServiceAvailableTimeDaysOfWeek>>;
  daysOfWeek_INCLUDES?: Maybe<HealthcareServiceAvailableTimeDaysOfWeek>;
  daysOfWeek_NOT_INCLUDES?: Maybe<HealthcareServiceAvailableTimeDaysOfWeek>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _daysOfWeek?: Maybe<ElementWhere>;
  _daysOfWeek_NOT?: Maybe<ElementWhere>;
  _allDay?: Maybe<ElementWhere>;
  _allDay_NOT?: Maybe<ElementWhere>;
  _allDay_IN?: Maybe<Array<ElementWhere>>;
  _allDay_NOT_IN?: Maybe<Array<ElementWhere>>;
  _availableStartTime?: Maybe<ElementWhere>;
  _availableStartTime_NOT?: Maybe<ElementWhere>;
  _availableStartTime_IN?: Maybe<Array<ElementWhere>>;
  _availableStartTime_NOT_IN?: Maybe<Array<ElementWhere>>;
  _availableEndTime?: Maybe<ElementWhere>;
  _availableEndTime_NOT?: Maybe<ElementWhere>;
  _availableEndTime_IN?: Maybe<Array<ElementWhere>>;
  _availableEndTime_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type HealthcareServiceAvailableTime_AlldayDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareServiceAvailableTime_AlldayFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareServiceAvailableTime_AlldayUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareServiceAvailableTime_AvailableendtimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareServiceAvailableTime_AvailableendtimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareServiceAvailableTime_AvailableendtimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareServiceAvailableTime_AvailablestarttimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareServiceAvailableTime_AvailablestarttimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareServiceAvailableTime_AvailablestarttimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareServiceAvailableTime_DaysofweekDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareServiceAvailableTime_DaysofweekFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type HealthcareServiceAvailableTime_DaysofweekUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type HealthcareServiceAvailabletimeDeleteFieldInput = {
  where?: Maybe<HealthcareServiceAvailableTimeWhere>;
  delete?: Maybe<HealthcareServiceAvailableTimeDeleteInput>;
};

export type HealthcareServiceAvailabletimeFieldInput = {
  create?: Maybe<Array<HealthcareServiceAvailableTimeCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceAvailableTimeConnectFieldInput>>;
};

export type HealthcareServiceAvailabletimeUpdateFieldInput = {
  where?: Maybe<HealthcareServiceAvailableTimeWhere>;
  update?: Maybe<HealthcareServiceAvailableTimeUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceAvailableTimeConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceAvailableTimeDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceAvailableTimeCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceAvailableTimeDeleteFieldInput>>;
};

export type HealthcareServiceCategoryDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type HealthcareServiceCategoryFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type HealthcareServiceCategoryUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type HealthcareServiceCharacteristicDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type HealthcareServiceCharacteristicFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type HealthcareServiceCharacteristicUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type HealthcareServiceCommunicationDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type HealthcareServiceCommunicationFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type HealthcareServiceCommunicationUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type HealthcareServiceConnectFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  connect?: Maybe<HealthcareServiceConnectInput>;
};

export type HealthcareServiceConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _active?: Maybe<ElementConnectFieldInput>;
  providedBy?: Maybe<ReferenceConnectFieldInput>;
  category?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  location?: Maybe<Array<ReferenceConnectFieldInput>>;
  _name?: Maybe<ElementConnectFieldInput>;
  _comment?: Maybe<ElementConnectFieldInput>;
  _extraDetails?: Maybe<ElementConnectFieldInput>;
  photo?: Maybe<AttachmentConnectFieldInput>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  coverageArea?: Maybe<Array<ReferenceConnectFieldInput>>;
  serviceProvisionCode?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  eligibility?: Maybe<Array<HealthcareServiceEligibilityConnectFieldInput>>;
  program?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  characteristic?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  communication?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  referralMethod?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  _appointmentRequired?: Maybe<ElementConnectFieldInput>;
  availableTime?: Maybe<Array<HealthcareServiceAvailableTimeConnectFieldInput>>;
  notAvailable?: Maybe<Array<HealthcareServiceNotAvailableConnectFieldInput>>;
  _availabilityExceptions?: Maybe<ElementConnectFieldInput>;
  endpoint?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type HealthcareServiceContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type HealthcareServiceContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type HealthcareServiceContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type HealthcareServiceContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type HealthcareServiceContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type HealthcareServiceContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type HealthcareServiceContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type HealthcareServiceContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type HealthcareServiceContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type HealthcareServiceContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type HealthcareServiceContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type HealthcareServiceContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type HealthcareServiceContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type HealthcareServiceContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type HealthcareServiceContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type HealthcareServiceContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type HealthcareServiceContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type HealthcareServiceContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type HealthcareServiceContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type HealthcareServiceContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type HealthcareServiceContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type HealthcareServiceContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type HealthcareServiceContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type HealthcareServiceContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type HealthcareServiceContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type HealthcareServiceContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type HealthcareServiceContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type HealthcareServiceContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type HealthcareServiceContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type HealthcareServiceContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type HealthcareServiceContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type HealthcareServiceContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type HealthcareServiceContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type HealthcareServiceContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type HealthcareServiceContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type HealthcareServiceContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type HealthcareServiceContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type HealthcareServiceContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type HealthcareServiceContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type HealthcareServiceContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type HealthcareServiceContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type HealthcareServiceContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type HealthcareServiceContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type HealthcareServiceContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type HealthcareServiceContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type HealthcareServiceContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type HealthcareServiceContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type HealthcareServiceContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type HealthcareServiceContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type HealthcareServiceContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type HealthcareServiceContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type HealthcareServiceContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type HealthcareServiceContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type HealthcareServiceContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type HealthcareServiceContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type HealthcareServiceContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type HealthcareServiceContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type HealthcareServiceContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type HealthcareServiceContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type HealthcareServiceContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type HealthcareServiceContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type HealthcareServiceContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type HealthcareServiceContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type HealthcareServiceCoverageareaDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type HealthcareServiceCoverageareaFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type HealthcareServiceCoverageareaUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type HealthcareServiceCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointmentRequired?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  extraDetails?: Maybe<Scalars['Markdown']>;
  availabilityExceptions?: Maybe<Scalars['String']>;
  resourceType: HealthcareServiceResourceType;
  meta?: Maybe<HealthcareServiceMetaFieldInput>;
  _implicitRules?: Maybe<HealthcareService_ImplicitrulesFieldInput>;
  _language?: Maybe<HealthcareService_LanguageFieldInput>;
  text?: Maybe<HealthcareServiceTextFieldInput>;
  contained_Account?: Maybe<HealthcareServiceContainedAccountFieldInput>;
  contained_Appointment?: Maybe<HealthcareServiceContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<HealthcareServiceContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<HealthcareServiceContainedCoverageFieldInput>;
  contained_Device?: Maybe<HealthcareServiceContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<HealthcareServiceContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<HealthcareServiceContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<HealthcareServiceContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<HealthcareServiceContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<HealthcareServiceContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<HealthcareServiceContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<HealthcareServiceContainedLocationFieldInput>;
  contained_Organization?: Maybe<HealthcareServiceContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<HealthcareServiceContainedPatientFieldInput>;
  contained_Person?: Maybe<HealthcareServiceContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<HealthcareServiceContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<HealthcareServiceContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<HealthcareServiceContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<HealthcareServiceContainedScheduleFieldInput>;
  contained_Slot?: Maybe<HealthcareServiceContainedSlotFieldInput>;
  contained_Substance?: Maybe<HealthcareServiceContainedSubstanceFieldInput>;
  extension?: Maybe<HealthcareServiceExtensionFieldInput>;
  modifierExtension?: Maybe<HealthcareServiceModifierextensionFieldInput>;
  identifier?: Maybe<HealthcareServiceIdentifierFieldInput>;
  _active?: Maybe<HealthcareService_ActiveFieldInput>;
  providedBy?: Maybe<HealthcareServiceProvidedbyFieldInput>;
  category?: Maybe<HealthcareServiceCategoryFieldInput>;
  type?: Maybe<HealthcareServiceTypeFieldInput>;
  specialty?: Maybe<HealthcareServiceSpecialtyFieldInput>;
  location?: Maybe<HealthcareServiceLocationFieldInput>;
  _name?: Maybe<HealthcareService_NameFieldInput>;
  _comment?: Maybe<HealthcareService_CommentFieldInput>;
  _extraDetails?: Maybe<HealthcareService_ExtradetailsFieldInput>;
  photo?: Maybe<HealthcareServicePhotoFieldInput>;
  telecom?: Maybe<HealthcareServiceTelecomFieldInput>;
  coverageArea?: Maybe<HealthcareServiceCoverageareaFieldInput>;
  serviceProvisionCode?: Maybe<HealthcareServiceServiceprovisioncodeFieldInput>;
  eligibility?: Maybe<HealthcareServiceEligibilityFieldInput>;
  program?: Maybe<HealthcareServiceProgramFieldInput>;
  characteristic?: Maybe<HealthcareServiceCharacteristicFieldInput>;
  communication?: Maybe<HealthcareServiceCommunicationFieldInput>;
  referralMethod?: Maybe<HealthcareServiceReferralmethodFieldInput>;
  _appointmentRequired?: Maybe<HealthcareService_AppointmentrequiredFieldInput>;
  availableTime?: Maybe<HealthcareServiceAvailabletimeFieldInput>;
  notAvailable?: Maybe<HealthcareServiceNotavailableFieldInput>;
  _availabilityExceptions?: Maybe<HealthcareService_AvailabilityexceptionsFieldInput>;
  endpoint?: Maybe<HealthcareServiceEndpointFieldInput>;
};

export type HealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type HealthcareServiceDeleteInput = {
  meta?: Maybe<HealthcareServiceMetaDeleteFieldInput>;
  _implicitRules?: Maybe<HealthcareService_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<HealthcareService_LanguageDeleteFieldInput>;
  text?: Maybe<HealthcareServiceTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<HealthcareServiceContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<HealthcareServiceContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<HealthcareServiceContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<HealthcareServiceContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<HealthcareServiceContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<HealthcareServiceContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<HealthcareServiceContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<HealthcareServiceContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<HealthcareServiceContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<HealthcareServiceContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<HealthcareServiceContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<HealthcareServiceContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<HealthcareServiceContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<HealthcareServiceContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<HealthcareServiceContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<HealthcareServiceContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<HealthcareServiceContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<HealthcareServiceContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<HealthcareServiceContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<HealthcareServiceContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<HealthcareServiceExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<HealthcareServiceModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<HealthcareServiceIdentifierDeleteFieldInput>>;
  _active?: Maybe<HealthcareService_ActiveDeleteFieldInput>;
  providedBy?: Maybe<HealthcareServiceProvidedbyDeleteFieldInput>;
  category?: Maybe<Array<HealthcareServiceCategoryDeleteFieldInput>>;
  type?: Maybe<Array<HealthcareServiceTypeDeleteFieldInput>>;
  specialty?: Maybe<Array<HealthcareServiceSpecialtyDeleteFieldInput>>;
  location?: Maybe<Array<HealthcareServiceLocationDeleteFieldInput>>;
  _name?: Maybe<HealthcareService_NameDeleteFieldInput>;
  _comment?: Maybe<HealthcareService_CommentDeleteFieldInput>;
  _extraDetails?: Maybe<HealthcareService_ExtradetailsDeleteFieldInput>;
  photo?: Maybe<HealthcareServicePhotoDeleteFieldInput>;
  telecom?: Maybe<Array<HealthcareServiceTelecomDeleteFieldInput>>;
  coverageArea?: Maybe<Array<HealthcareServiceCoverageareaDeleteFieldInput>>;
  serviceProvisionCode?: Maybe<Array<HealthcareServiceServiceprovisioncodeDeleteFieldInput>>;
  eligibility?: Maybe<Array<HealthcareServiceEligibilityDeleteFieldInput>>;
  program?: Maybe<Array<HealthcareServiceProgramDeleteFieldInput>>;
  characteristic?: Maybe<Array<HealthcareServiceCharacteristicDeleteFieldInput>>;
  communication?: Maybe<Array<HealthcareServiceCommunicationDeleteFieldInput>>;
  referralMethod?: Maybe<Array<HealthcareServiceReferralmethodDeleteFieldInput>>;
  _appointmentRequired?: Maybe<HealthcareService_AppointmentrequiredDeleteFieldInput>;
  availableTime?: Maybe<Array<HealthcareServiceAvailabletimeDeleteFieldInput>>;
  notAvailable?: Maybe<Array<HealthcareServiceNotavailableDeleteFieldInput>>;
  _availabilityExceptions?: Maybe<HealthcareService_AvailabilityexceptionsDeleteFieldInput>;
  endpoint?: Maybe<Array<HealthcareServiceEndpointDeleteFieldInput>>;
};

export type HealthcareServiceDisconnectFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  disconnect?: Maybe<HealthcareServiceDisconnectInput>;
};

export type HealthcareServiceDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _active?: Maybe<ElementDisconnectFieldInput>;
  providedBy?: Maybe<ReferenceDisconnectFieldInput>;
  category?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  type?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  location?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  _comment?: Maybe<ElementDisconnectFieldInput>;
  _extraDetails?: Maybe<ElementDisconnectFieldInput>;
  photo?: Maybe<AttachmentDisconnectFieldInput>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  coverageArea?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  serviceProvisionCode?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  eligibility?: Maybe<Array<HealthcareServiceEligibilityDisconnectFieldInput>>;
  program?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  characteristic?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  communication?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  referralMethod?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  _appointmentRequired?: Maybe<ElementDisconnectFieldInput>;
  availableTime?: Maybe<Array<HealthcareServiceAvailableTimeDisconnectFieldInput>>;
  notAvailable?: Maybe<Array<HealthcareServiceNotAvailableDisconnectFieldInput>>;
  _availabilityExceptions?: Maybe<ElementDisconnectFieldInput>;
  endpoint?: Maybe<Array<ReferenceDisconnectFieldInput>>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceEligibility = {
  __typename?: 'HealthcareServiceEligibility';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Describes the eligibility conditions for the service. */
  comment?: Maybe<Scalars['Markdown']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  code?: Maybe<CodeableConcept>;
  _comment?: Maybe<Element>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceEligibilityExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceEligibilityModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceEligibilityCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceEligibility_CommentArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type HealthcareServiceEligibilityCodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type HealthcareServiceEligibilityCodeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type HealthcareServiceEligibilityCodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type HealthcareServiceEligibilityConnectFieldInput = {
  where?: Maybe<HealthcareServiceEligibilityWhere>;
  connect?: Maybe<HealthcareServiceEligibilityConnectInput>;
};

export type HealthcareServiceEligibilityConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  code?: Maybe<CodeableConceptConnectFieldInput>;
  _comment?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareServiceEligibilityCreateInput = {
  comment?: Maybe<Scalars['Markdown']>;
  extension?: Maybe<HealthcareServiceEligibilityExtensionFieldInput>;
  modifierExtension?: Maybe<HealthcareServiceEligibilityModifierextensionFieldInput>;
  code?: Maybe<HealthcareServiceEligibilityCodeFieldInput>;
  _comment?: Maybe<HealthcareServiceEligibility_CommentFieldInput>;
};

export type HealthcareServiceEligibilityDeleteFieldInput = {
  where?: Maybe<HealthcareServiceEligibilityWhere>;
  delete?: Maybe<HealthcareServiceEligibilityDeleteInput>;
};

export type HealthcareServiceEligibilityDeleteInput = {
  extension?: Maybe<Array<HealthcareServiceEligibilityExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<HealthcareServiceEligibilityModifierextensionDeleteFieldInput>>;
  code?: Maybe<HealthcareServiceEligibilityCodeDeleteFieldInput>;
  _comment?: Maybe<HealthcareServiceEligibility_CommentDeleteFieldInput>;
};

export type HealthcareServiceEligibilityDisconnectFieldInput = {
  where?: Maybe<HealthcareServiceEligibilityWhere>;
  disconnect?: Maybe<HealthcareServiceEligibilityDisconnectInput>;
};

export type HealthcareServiceEligibilityDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  code?: Maybe<CodeableConceptDisconnectFieldInput>;
  _comment?: Maybe<ElementDisconnectFieldInput>;
};

export type HealthcareServiceEligibilityExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type HealthcareServiceEligibilityExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type HealthcareServiceEligibilityExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type HealthcareServiceEligibilityFieldInput = {
  create?: Maybe<Array<HealthcareServiceEligibilityCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceEligibilityConnectFieldInput>>;
};

export type HealthcareServiceEligibilityModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type HealthcareServiceEligibilityModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type HealthcareServiceEligibilityModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type HealthcareServiceEligibilityOptions = {
  /** Specify one or more HealthcareServiceEligibilitySort objects to sort HealthcareServiceEligibilities by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<HealthcareServiceEligibilitySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type HealthcareServiceEligibilityRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  code?: Maybe<CodeableConceptCreateInput>;
  _comment?: Maybe<ElementCreateInput>;
};

/** Fields to sort HealthcareServiceEligibilities by. The order in which sorts are applied is not guaranteed when specifying many fields in one HealthcareServiceEligibilitySort object. */
export type HealthcareServiceEligibilitySort = {
  id?: Maybe<SortDirection>;
  comment?: Maybe<SortDirection>;
};

export type HealthcareServiceEligibilityUpdateFieldInput = {
  where?: Maybe<HealthcareServiceEligibilityWhere>;
  update?: Maybe<HealthcareServiceEligibilityUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceEligibilityConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceEligibilityDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceEligibilityCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceEligibilityDeleteFieldInput>>;
};

export type HealthcareServiceEligibilityUpdateInput = {
  comment?: Maybe<Scalars['Markdown']>;
  extension?: Maybe<Array<HealthcareServiceEligibilityExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<HealthcareServiceEligibilityModifierextensionUpdateFieldInput>>;
  code?: Maybe<HealthcareServiceEligibilityCodeUpdateFieldInput>;
  _comment?: Maybe<HealthcareServiceEligibility_CommentUpdateFieldInput>;
};

export type HealthcareServiceEligibilityWhere = {
  OR?: Maybe<Array<HealthcareServiceEligibilityWhere>>;
  AND?: Maybe<Array<HealthcareServiceEligibilityWhere>>;
  comment?: Maybe<Scalars['Markdown']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  code?: Maybe<CodeableConceptWhere>;
  code_NOT?: Maybe<CodeableConceptWhere>;
  code_IN?: Maybe<Array<CodeableConceptWhere>>;
  code_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _comment?: Maybe<ElementWhere>;
  _comment_NOT?: Maybe<ElementWhere>;
  _comment_IN?: Maybe<Array<ElementWhere>>;
  _comment_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type HealthcareServiceEligibility_CommentDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareServiceEligibility_CommentFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareServiceEligibility_CommentUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareServiceEndpointDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type HealthcareServiceEndpointFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type HealthcareServiceEndpointUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type HealthcareServiceExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type HealthcareServiceExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type HealthcareServiceExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type HealthcareServiceIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type HealthcareServiceIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type HealthcareServiceIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type HealthcareServiceLocationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type HealthcareServiceLocationFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type HealthcareServiceLocationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type HealthcareServiceMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type HealthcareServiceMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type HealthcareServiceMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type HealthcareServiceModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type HealthcareServiceModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type HealthcareServiceModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceNotAvailable = {
  __typename?: 'HealthcareServiceNotAvailable';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The reason that can be presented to the user as to why this time is not available. */
  description?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _description?: Maybe<Element>;
  during?: Maybe<Period>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceNotAvailableExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceNotAvailableModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceNotAvailable_DescriptionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The details of a healthcare service available at a location. */
export type HealthcareServiceNotAvailableDuringArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type HealthcareServiceNotAvailableConnectFieldInput = {
  where?: Maybe<HealthcareServiceNotAvailableWhere>;
  connect?: Maybe<HealthcareServiceNotAvailableConnectInput>;
};

export type HealthcareServiceNotAvailableConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _description?: Maybe<ElementConnectFieldInput>;
  during?: Maybe<PeriodConnectFieldInput>;
};

export type HealthcareServiceNotAvailableCreateInput = {
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<HealthcareServiceNotAvailableExtensionFieldInput>;
  modifierExtension?: Maybe<HealthcareServiceNotAvailableModifierextensionFieldInput>;
  _description?: Maybe<HealthcareServiceNotAvailable_DescriptionFieldInput>;
  during?: Maybe<HealthcareServiceNotAvailableDuringFieldInput>;
};

export type HealthcareServiceNotAvailableDeleteFieldInput = {
  where?: Maybe<HealthcareServiceNotAvailableWhere>;
  delete?: Maybe<HealthcareServiceNotAvailableDeleteInput>;
};

export type HealthcareServiceNotAvailableDeleteInput = {
  extension?: Maybe<Array<HealthcareServiceNotAvailableExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<HealthcareServiceNotAvailableModifierextensionDeleteFieldInput>>;
  _description?: Maybe<HealthcareServiceNotAvailable_DescriptionDeleteFieldInput>;
  during?: Maybe<HealthcareServiceNotAvailableDuringDeleteFieldInput>;
};

export type HealthcareServiceNotAvailableDisconnectFieldInput = {
  where?: Maybe<HealthcareServiceNotAvailableWhere>;
  disconnect?: Maybe<HealthcareServiceNotAvailableDisconnectInput>;
};

export type HealthcareServiceNotAvailableDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _description?: Maybe<ElementDisconnectFieldInput>;
  during?: Maybe<PeriodDisconnectFieldInput>;
};

export type HealthcareServiceNotAvailableDuringDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type HealthcareServiceNotAvailableDuringFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type HealthcareServiceNotAvailableDuringUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type HealthcareServiceNotAvailableExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type HealthcareServiceNotAvailableExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type HealthcareServiceNotAvailableExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type HealthcareServiceNotAvailableModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type HealthcareServiceNotAvailableModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type HealthcareServiceNotAvailableModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type HealthcareServiceNotAvailableOptions = {
  /** Specify one or more HealthcareServiceNotAvailableSort objects to sort HealthcareServiceNotAvailables by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<HealthcareServiceNotAvailableSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type HealthcareServiceNotAvailableRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _description?: Maybe<ElementCreateInput>;
  during?: Maybe<PeriodCreateInput>;
};

/** Fields to sort HealthcareServiceNotAvailables by. The order in which sorts are applied is not guaranteed when specifying many fields in one HealthcareServiceNotAvailableSort object. */
export type HealthcareServiceNotAvailableSort = {
  id?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
};

export type HealthcareServiceNotAvailableUpdateInput = {
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<HealthcareServiceNotAvailableExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<HealthcareServiceNotAvailableModifierextensionUpdateFieldInput>>;
  _description?: Maybe<HealthcareServiceNotAvailable_DescriptionUpdateFieldInput>;
  during?: Maybe<HealthcareServiceNotAvailableDuringUpdateFieldInput>;
};

export type HealthcareServiceNotAvailableWhere = {
  OR?: Maybe<Array<HealthcareServiceNotAvailableWhere>>;
  AND?: Maybe<Array<HealthcareServiceNotAvailableWhere>>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _description?: Maybe<ElementWhere>;
  _description_NOT?: Maybe<ElementWhere>;
  _description_IN?: Maybe<Array<ElementWhere>>;
  _description_NOT_IN?: Maybe<Array<ElementWhere>>;
  during?: Maybe<PeriodWhere>;
  during_NOT?: Maybe<PeriodWhere>;
  during_IN?: Maybe<Array<PeriodWhere>>;
  during_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type HealthcareServiceNotAvailable_DescriptionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareServiceNotAvailable_DescriptionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareServiceNotAvailable_DescriptionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareServiceNotavailableDeleteFieldInput = {
  where?: Maybe<HealthcareServiceNotAvailableWhere>;
  delete?: Maybe<HealthcareServiceNotAvailableDeleteInput>;
};

export type HealthcareServiceNotavailableFieldInput = {
  create?: Maybe<Array<HealthcareServiceNotAvailableCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceNotAvailableConnectFieldInput>>;
};

export type HealthcareServiceNotavailableUpdateFieldInput = {
  where?: Maybe<HealthcareServiceNotAvailableWhere>;
  update?: Maybe<HealthcareServiceNotAvailableUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceNotAvailableConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceNotAvailableDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceNotAvailableCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceNotAvailableDeleteFieldInput>>;
};

export type HealthcareServiceOptions = {
  /** Specify one or more HealthcareServiceSort objects to sort HealthcareServices by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<HealthcareServiceSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type HealthcareServicePhotoDeleteFieldInput = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type HealthcareServicePhotoFieldInput = {
  create?: Maybe<AttachmentCreateInput>;
  connect?: Maybe<AttachmentConnectFieldInput>;
};

export type HealthcareServicePhotoUpdateFieldInput = {
  where?: Maybe<AttachmentWhere>;
  update?: Maybe<AttachmentUpdateInput>;
  connect?: Maybe<AttachmentConnectFieldInput>;
  disconnect?: Maybe<AttachmentDisconnectFieldInput>;
  create?: Maybe<AttachmentCreateInput>;
  delete?: Maybe<AttachmentDeleteFieldInput>;
};

export type HealthcareServiceProgramDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type HealthcareServiceProgramFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type HealthcareServiceProgramUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type HealthcareServiceProvidedbyDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type HealthcareServiceProvidedbyFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type HealthcareServiceProvidedbyUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type HealthcareServiceReferralmethodDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type HealthcareServiceReferralmethodFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type HealthcareServiceReferralmethodUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type HealthcareServiceRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _active?: Maybe<ElementCreateInput>;
  providedBy?: Maybe<ReferenceCreateInput>;
  category?: Maybe<Array<CodeableConceptCreateInput>>;
  type?: Maybe<Array<CodeableConceptCreateInput>>;
  specialty?: Maybe<Array<CodeableConceptCreateInput>>;
  location?: Maybe<Array<ReferenceCreateInput>>;
  _name?: Maybe<ElementCreateInput>;
  _comment?: Maybe<ElementCreateInput>;
  _extraDetails?: Maybe<ElementCreateInput>;
  photo?: Maybe<AttachmentCreateInput>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  coverageArea?: Maybe<Array<ReferenceCreateInput>>;
  serviceProvisionCode?: Maybe<Array<CodeableConceptCreateInput>>;
  eligibility?: Maybe<Array<HealthcareServiceEligibilityCreateInput>>;
  program?: Maybe<Array<CodeableConceptCreateInput>>;
  characteristic?: Maybe<Array<CodeableConceptCreateInput>>;
  communication?: Maybe<Array<CodeableConceptCreateInput>>;
  referralMethod?: Maybe<Array<CodeableConceptCreateInput>>;
  _appointmentRequired?: Maybe<ElementCreateInput>;
  availableTime?: Maybe<Array<HealthcareServiceAvailableTimeCreateInput>>;
  notAvailable?: Maybe<Array<HealthcareServiceNotAvailableCreateInput>>;
  _availabilityExceptions?: Maybe<ElementCreateInput>;
  endpoint?: Maybe<Array<ReferenceCreateInput>>;
};

export enum HealthcareServiceResourceType {
  HealthcareService = 'HealthcareService',
}

export type HealthcareServiceServiceprovisioncodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type HealthcareServiceServiceprovisioncodeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type HealthcareServiceServiceprovisioncodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

/** Fields to sort HealthcareServices by. The order in which sorts are applied is not guaranteed when specifying many fields in one HealthcareServiceSort object. */
export type HealthcareServiceSort = {
  id?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  appointmentRequired?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  comment?: Maybe<SortDirection>;
  extraDetails?: Maybe<SortDirection>;
  availabilityExceptions?: Maybe<SortDirection>;
};

export type HealthcareServiceSpecialtyDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type HealthcareServiceSpecialtyFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type HealthcareServiceSpecialtyUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type HealthcareServiceTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type HealthcareServiceTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type HealthcareServiceTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type HealthcareServiceTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type HealthcareServiceTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type HealthcareServiceTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type HealthcareServiceTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type HealthcareServiceTypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type HealthcareServiceTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type HealthcareServiceUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointmentRequired?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  extraDetails?: Maybe<Scalars['Markdown']>;
  availabilityExceptions?: Maybe<Scalars['String']>;
  resourceType?: Maybe<HealthcareServiceResourceType>;
  meta?: Maybe<HealthcareServiceMetaUpdateFieldInput>;
  _implicitRules?: Maybe<HealthcareService_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<HealthcareService_LanguageUpdateFieldInput>;
  text?: Maybe<HealthcareServiceTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<HealthcareServiceContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<HealthcareServiceContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<HealthcareServiceContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<HealthcareServiceContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<HealthcareServiceContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<HealthcareServiceContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<HealthcareServiceContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<HealthcareServiceContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<HealthcareServiceContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<HealthcareServiceContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<HealthcareServiceContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<HealthcareServiceContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<HealthcareServiceContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<HealthcareServiceContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<HealthcareServiceContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<HealthcareServiceContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<HealthcareServiceContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<HealthcareServiceContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<HealthcareServiceContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<HealthcareServiceContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<HealthcareServiceExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<HealthcareServiceModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<HealthcareServiceIdentifierUpdateFieldInput>>;
  _active?: Maybe<HealthcareService_ActiveUpdateFieldInput>;
  providedBy?: Maybe<HealthcareServiceProvidedbyUpdateFieldInput>;
  category?: Maybe<Array<HealthcareServiceCategoryUpdateFieldInput>>;
  type?: Maybe<Array<HealthcareServiceTypeUpdateFieldInput>>;
  specialty?: Maybe<Array<HealthcareServiceSpecialtyUpdateFieldInput>>;
  location?: Maybe<Array<HealthcareServiceLocationUpdateFieldInput>>;
  _name?: Maybe<HealthcareService_NameUpdateFieldInput>;
  _comment?: Maybe<HealthcareService_CommentUpdateFieldInput>;
  _extraDetails?: Maybe<HealthcareService_ExtradetailsUpdateFieldInput>;
  photo?: Maybe<HealthcareServicePhotoUpdateFieldInput>;
  telecom?: Maybe<Array<HealthcareServiceTelecomUpdateFieldInput>>;
  coverageArea?: Maybe<Array<HealthcareServiceCoverageareaUpdateFieldInput>>;
  serviceProvisionCode?: Maybe<Array<HealthcareServiceServiceprovisioncodeUpdateFieldInput>>;
  eligibility?: Maybe<Array<HealthcareServiceEligibilityUpdateFieldInput>>;
  program?: Maybe<Array<HealthcareServiceProgramUpdateFieldInput>>;
  characteristic?: Maybe<Array<HealthcareServiceCharacteristicUpdateFieldInput>>;
  communication?: Maybe<Array<HealthcareServiceCommunicationUpdateFieldInput>>;
  referralMethod?: Maybe<Array<HealthcareServiceReferralmethodUpdateFieldInput>>;
  _appointmentRequired?: Maybe<HealthcareService_AppointmentrequiredUpdateFieldInput>;
  availableTime?: Maybe<Array<HealthcareServiceAvailabletimeUpdateFieldInput>>;
  notAvailable?: Maybe<Array<HealthcareServiceNotavailableUpdateFieldInput>>;
  _availabilityExceptions?: Maybe<HealthcareService_AvailabilityexceptionsUpdateFieldInput>;
  endpoint?: Maybe<Array<HealthcareServiceEndpointUpdateFieldInput>>;
};

export type HealthcareServiceWhere = {
  OR?: Maybe<Array<HealthcareServiceWhere>>;
  AND?: Maybe<Array<HealthcareServiceWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  extraDetails?: Maybe<Scalars['Markdown']>;
  availabilityExceptions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_NOT?: Maybe<Scalars['Boolean']>;
  appointmentRequired?: Maybe<Scalars['Boolean']>;
  appointmentRequired_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<HealthcareServiceResourceType>;
  resourceType_NOT?: Maybe<HealthcareServiceResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<HealthcareServiceResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<HealthcareServiceResourceType>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _active?: Maybe<ElementWhere>;
  _active_NOT?: Maybe<ElementWhere>;
  _active_IN?: Maybe<Array<ElementWhere>>;
  _active_NOT_IN?: Maybe<Array<ElementWhere>>;
  providedBy?: Maybe<ReferenceWhere>;
  providedBy_NOT?: Maybe<ReferenceWhere>;
  providedBy_IN?: Maybe<Array<ReferenceWhere>>;
  providedBy_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  category?: Maybe<CodeableConceptWhere>;
  category_NOT?: Maybe<CodeableConceptWhere>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  specialty?: Maybe<CodeableConceptWhere>;
  specialty_NOT?: Maybe<CodeableConceptWhere>;
  location?: Maybe<ReferenceWhere>;
  location_NOT?: Maybe<ReferenceWhere>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  _comment?: Maybe<ElementWhere>;
  _comment_NOT?: Maybe<ElementWhere>;
  _comment_IN?: Maybe<Array<ElementWhere>>;
  _comment_NOT_IN?: Maybe<Array<ElementWhere>>;
  _extraDetails?: Maybe<ElementWhere>;
  _extraDetails_NOT?: Maybe<ElementWhere>;
  _extraDetails_IN?: Maybe<Array<ElementWhere>>;
  _extraDetails_NOT_IN?: Maybe<Array<ElementWhere>>;
  photo?: Maybe<AttachmentWhere>;
  photo_NOT?: Maybe<AttachmentWhere>;
  photo_IN?: Maybe<Array<AttachmentWhere>>;
  photo_NOT_IN?: Maybe<Array<AttachmentWhere>>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  coverageArea?: Maybe<ReferenceWhere>;
  coverageArea_NOT?: Maybe<ReferenceWhere>;
  serviceProvisionCode?: Maybe<CodeableConceptWhere>;
  serviceProvisionCode_NOT?: Maybe<CodeableConceptWhere>;
  eligibility?: Maybe<HealthcareServiceEligibilityWhere>;
  eligibility_NOT?: Maybe<HealthcareServiceEligibilityWhere>;
  program?: Maybe<CodeableConceptWhere>;
  program_NOT?: Maybe<CodeableConceptWhere>;
  characteristic?: Maybe<CodeableConceptWhere>;
  characteristic_NOT?: Maybe<CodeableConceptWhere>;
  communication?: Maybe<CodeableConceptWhere>;
  communication_NOT?: Maybe<CodeableConceptWhere>;
  referralMethod?: Maybe<CodeableConceptWhere>;
  referralMethod_NOT?: Maybe<CodeableConceptWhere>;
  _appointmentRequired?: Maybe<ElementWhere>;
  _appointmentRequired_NOT?: Maybe<ElementWhere>;
  _appointmentRequired_IN?: Maybe<Array<ElementWhere>>;
  _appointmentRequired_NOT_IN?: Maybe<Array<ElementWhere>>;
  availableTime?: Maybe<HealthcareServiceAvailableTimeWhere>;
  availableTime_NOT?: Maybe<HealthcareServiceAvailableTimeWhere>;
  notAvailable?: Maybe<HealthcareServiceNotAvailableWhere>;
  notAvailable_NOT?: Maybe<HealthcareServiceNotAvailableWhere>;
  _availabilityExceptions?: Maybe<ElementWhere>;
  _availabilityExceptions_NOT?: Maybe<ElementWhere>;
  _availabilityExceptions_IN?: Maybe<Array<ElementWhere>>;
  _availabilityExceptions_NOT_IN?: Maybe<Array<ElementWhere>>;
  endpoint?: Maybe<ReferenceWhere>;
  endpoint_NOT?: Maybe<ReferenceWhere>;
};

export type HealthcareService_ActiveDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareService_ActiveFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareService_ActiveUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareService_AppointmentrequiredDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareService_AppointmentrequiredFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareService_AppointmentrequiredUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareService_AvailabilityexceptionsDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareService_AvailabilityexceptionsFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareService_AvailabilityexceptionsUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareService_CommentDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareService_CommentFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareService_CommentUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareService_ExtradetailsDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareService_ExtradetailsFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareService_ExtradetailsUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareService_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareService_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareService_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareService_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareService_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareService_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HealthcareService_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HealthcareService_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HealthcareService_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A human's name with the ability to identify parts and usage. */
export type HumanName = {
  __typename?: 'HumanName';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Identifies the purpose for this name. */
  use?: Maybe<HumanNameUse>;
  /** Specifies the entire name as it should be displayed e.g. on an application UI. This may be provided instead of or as well as the specific parts. */
  text?: Maybe<Scalars['String']>;
  /** The part of a name that links to the genealogy. In some cultures (e.g. Eritrea) the family name of a son is the first name of his father. */
  family?: Maybe<Scalars['String']>;
  /** Given name. */
  given: Array<Scalars['String']>;
  /** Part of the name that is acquired as a title due to academic, legal, employment or nobility status, etc. and that appears at the start of the name. */
  prefix: Array<Scalars['String']>;
  /** Part of the name that is acquired as a title due to academic, legal, employment or nobility status, etc. and that appears at the end of the name. */
  suffix: Array<Scalars['String']>;
  extension: Array<Extension>;
  _use?: Maybe<Element>;
  _text?: Maybe<Element>;
  _family?: Maybe<Element>;
  _given: Array<Element>;
  _prefix: Array<Element>;
  _suffix: Array<Element>;
  period?: Maybe<Period>;
};

/** A human's name with the ability to identify parts and usage. */
export type HumanNameExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A human's name with the ability to identify parts and usage. */
export type HumanName_UseArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A human's name with the ability to identify parts and usage. */
export type HumanName_TextArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A human's name with the ability to identify parts and usage. */
export type HumanName_FamilyArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A human's name with the ability to identify parts and usage. */
export type HumanName_GivenArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A human's name with the ability to identify parts and usage. */
export type HumanName_PrefixArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A human's name with the ability to identify parts and usage. */
export type HumanName_SuffixArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A human's name with the ability to identify parts and usage. */
export type HumanNamePeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type HumanNameConnectFieldInput = {
  where?: Maybe<HumanNameWhere>;
  connect?: Maybe<HumanNameConnectInput>;
};

export type HumanNameConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _use?: Maybe<ElementConnectFieldInput>;
  _text?: Maybe<ElementConnectFieldInput>;
  _family?: Maybe<ElementConnectFieldInput>;
  _given?: Maybe<Array<ElementConnectFieldInput>>;
  _prefix?: Maybe<Array<ElementConnectFieldInput>>;
  _suffix?: Maybe<Array<ElementConnectFieldInput>>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type HumanNameCreateInput = {
  text?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  given: Array<Scalars['String']>;
  prefix: Array<Scalars['String']>;
  suffix: Array<Scalars['String']>;
  use?: Maybe<HumanNameUse>;
  extension?: Maybe<HumanNameExtensionFieldInput>;
  _use?: Maybe<HumanName_UseFieldInput>;
  _text?: Maybe<HumanName_TextFieldInput>;
  _family?: Maybe<HumanName_FamilyFieldInput>;
  _given?: Maybe<HumanName_GivenFieldInput>;
  _prefix?: Maybe<HumanName_PrefixFieldInput>;
  _suffix?: Maybe<HumanName_SuffixFieldInput>;
  period?: Maybe<HumanNamePeriodFieldInput>;
};

export type HumanNameDeleteFieldInput = {
  where?: Maybe<HumanNameWhere>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type HumanNameDeleteInput = {
  extension?: Maybe<Array<HumanNameExtensionDeleteFieldInput>>;
  _use?: Maybe<HumanName_UseDeleteFieldInput>;
  _text?: Maybe<HumanName_TextDeleteFieldInput>;
  _family?: Maybe<HumanName_FamilyDeleteFieldInput>;
  _given?: Maybe<Array<HumanName_GivenDeleteFieldInput>>;
  _prefix?: Maybe<Array<HumanName_PrefixDeleteFieldInput>>;
  _suffix?: Maybe<Array<HumanName_SuffixDeleteFieldInput>>;
  period?: Maybe<HumanNamePeriodDeleteFieldInput>;
};

export type HumanNameDisconnectFieldInput = {
  where?: Maybe<HumanNameWhere>;
  disconnect?: Maybe<HumanNameDisconnectInput>;
};

export type HumanNameDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _use?: Maybe<ElementDisconnectFieldInput>;
  _text?: Maybe<ElementDisconnectFieldInput>;
  _family?: Maybe<ElementDisconnectFieldInput>;
  _given?: Maybe<Array<ElementDisconnectFieldInput>>;
  _prefix?: Maybe<Array<ElementDisconnectFieldInput>>;
  _suffix?: Maybe<Array<ElementDisconnectFieldInput>>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type HumanNameExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type HumanNameExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type HumanNameExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type HumanNameOptions = {
  /** Specify one or more HumanNameSort objects to sort HumanNames by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<HumanNameSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type HumanNamePeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type HumanNamePeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type HumanNamePeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type HumanNameRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _use?: Maybe<ElementCreateInput>;
  _text?: Maybe<ElementCreateInput>;
  _family?: Maybe<ElementCreateInput>;
  _given?: Maybe<Array<ElementCreateInput>>;
  _prefix?: Maybe<Array<ElementCreateInput>>;
  _suffix?: Maybe<Array<ElementCreateInput>>;
  period?: Maybe<PeriodCreateInput>;
};

/** Fields to sort HumanNames by. The order in which sorts are applied is not guaranteed when specifying many fields in one HumanNameSort object. */
export type HumanNameSort = {
  id?: Maybe<SortDirection>;
  use?: Maybe<SortDirection>;
  text?: Maybe<SortDirection>;
  family?: Maybe<SortDirection>;
};

export type HumanNameUpdateInput = {
  text?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  given?: Maybe<Array<Scalars['String']>>;
  prefix?: Maybe<Array<Scalars['String']>>;
  suffix?: Maybe<Array<Scalars['String']>>;
  use?: Maybe<HumanNameUse>;
  extension?: Maybe<Array<HumanNameExtensionUpdateFieldInput>>;
  _use?: Maybe<HumanName_UseUpdateFieldInput>;
  _text?: Maybe<HumanName_TextUpdateFieldInput>;
  _family?: Maybe<HumanName_FamilyUpdateFieldInput>;
  _given?: Maybe<Array<HumanName_GivenUpdateFieldInput>>;
  _prefix?: Maybe<Array<HumanName_PrefixUpdateFieldInput>>;
  _suffix?: Maybe<Array<HumanName_SuffixUpdateFieldInput>>;
  period?: Maybe<HumanNamePeriodUpdateFieldInput>;
};

/** Identifies the purpose for this name. */
export enum HumanNameUse {
  Usual = 'usual',
  Official = 'official',
  Temp = 'temp',
  Nickname = 'nickname',
  Anonymous = 'anonymous',
  Old = 'old',
  Maiden = 'maiden',
}

export type HumanNameWhere = {
  OR?: Maybe<Array<HumanNameWhere>>;
  AND?: Maybe<Array<HumanNameWhere>>;
  text?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  given?: Maybe<Array<Maybe<Scalars['String']>>>;
  prefix?: Maybe<Array<Maybe<Scalars['String']>>>;
  suffix?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  use?: Maybe<HumanNameUse>;
  use_NOT?: Maybe<HumanNameUse>;
  use_IN?: Maybe<Array<Maybe<HumanNameUse>>>;
  use_NOT_IN?: Maybe<Array<Maybe<HumanNameUse>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _use?: Maybe<ElementWhere>;
  _use_NOT?: Maybe<ElementWhere>;
  _use_IN?: Maybe<Array<ElementWhere>>;
  _use_NOT_IN?: Maybe<Array<ElementWhere>>;
  _text?: Maybe<ElementWhere>;
  _text_NOT?: Maybe<ElementWhere>;
  _text_IN?: Maybe<Array<ElementWhere>>;
  _text_NOT_IN?: Maybe<Array<ElementWhere>>;
  _family?: Maybe<ElementWhere>;
  _family_NOT?: Maybe<ElementWhere>;
  _family_IN?: Maybe<Array<ElementWhere>>;
  _family_NOT_IN?: Maybe<Array<ElementWhere>>;
  _given?: Maybe<ElementWhere>;
  _given_NOT?: Maybe<ElementWhere>;
  _prefix?: Maybe<ElementWhere>;
  _prefix_NOT?: Maybe<ElementWhere>;
  _suffix?: Maybe<ElementWhere>;
  _suffix_NOT?: Maybe<ElementWhere>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type HumanName_FamilyDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HumanName_FamilyFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HumanName_FamilyUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HumanName_GivenDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HumanName_GivenFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type HumanName_GivenUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type HumanName_PrefixDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HumanName_PrefixFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type HumanName_PrefixUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type HumanName_SuffixDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HumanName_SuffixFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type HumanName_SuffixUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type HumanName_TextDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HumanName_TextFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HumanName_TextUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type HumanName_UseDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type HumanName_UseFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type HumanName_UseUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type Identifier = {
  __typename?: 'Identifier';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The purpose of this identifier. */
  use?: Maybe<IdentifierUse>;
  /** Establishes the namespace for the value - that is, a URL that describes a set values that are unique. */
  system?: Maybe<Scalars['Uri']>;
  /** The portion of the identifier typically relevant to the user and which is unique within the context of the system. */
  value?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  _use?: Maybe<Element>;
  type?: Maybe<CodeableConcept>;
  _system?: Maybe<Element>;
  _value?: Maybe<Element>;
  period?: Maybe<Period>;
  assigner?: Maybe<Reference>;
};

/** An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type IdentifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type Identifier_UseArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type IdentifierTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type Identifier_SystemArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type Identifier_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type IdentifierPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type IdentifierAssignerArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type IdentifierAssignerDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type IdentifierAssignerFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type IdentifierAssignerUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type IdentifierConnectFieldInput = {
  where?: Maybe<IdentifierWhere>;
  connect?: Maybe<IdentifierConnectInput>;
};

export type IdentifierConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _use?: Maybe<ElementConnectFieldInput>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  _system?: Maybe<ElementConnectFieldInput>;
  _value?: Maybe<ElementConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
  assigner?: Maybe<ReferenceConnectFieldInput>;
};

export type IdentifierCreateInput = {
  system?: Maybe<Scalars['Uri']>;
  value?: Maybe<Scalars['String']>;
  use?: Maybe<IdentifierUse>;
  extension?: Maybe<IdentifierExtensionFieldInput>;
  _use?: Maybe<Identifier_UseFieldInput>;
  type?: Maybe<IdentifierTypeFieldInput>;
  _system?: Maybe<Identifier_SystemFieldInput>;
  _value?: Maybe<Identifier_ValueFieldInput>;
  period?: Maybe<IdentifierPeriodFieldInput>;
  assigner?: Maybe<IdentifierAssignerFieldInput>;
};

export type IdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type IdentifierDeleteInput = {
  extension?: Maybe<Array<IdentifierExtensionDeleteFieldInput>>;
  _use?: Maybe<Identifier_UseDeleteFieldInput>;
  type?: Maybe<IdentifierTypeDeleteFieldInput>;
  _system?: Maybe<Identifier_SystemDeleteFieldInput>;
  _value?: Maybe<Identifier_ValueDeleteFieldInput>;
  period?: Maybe<IdentifierPeriodDeleteFieldInput>;
  assigner?: Maybe<IdentifierAssignerDeleteFieldInput>;
};

export type IdentifierDisconnectFieldInput = {
  where?: Maybe<IdentifierWhere>;
  disconnect?: Maybe<IdentifierDisconnectInput>;
};

export type IdentifierDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _use?: Maybe<ElementDisconnectFieldInput>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  _system?: Maybe<ElementDisconnectFieldInput>;
  _value?: Maybe<ElementDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
  assigner?: Maybe<ReferenceDisconnectFieldInput>;
};

export type IdentifierExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type IdentifierExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type IdentifierExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type IdentifierOptions = {
  /** Specify one or more IdentifierSort objects to sort Identifiers by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<IdentifierSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type IdentifierPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type IdentifierPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type IdentifierPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type IdentifierRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _use?: Maybe<ElementCreateInput>;
  type?: Maybe<CodeableConceptCreateInput>;
  _system?: Maybe<ElementCreateInput>;
  _value?: Maybe<ElementCreateInput>;
  period?: Maybe<PeriodCreateInput>;
  assigner?: Maybe<ReferenceCreateInput>;
};

/** Fields to sort Identifiers by. The order in which sorts are applied is not guaranteed when specifying many fields in one IdentifierSort object. */
export type IdentifierSort = {
  id?: Maybe<SortDirection>;
  use?: Maybe<SortDirection>;
  system?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
};

export type IdentifierTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type IdentifierTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type IdentifierTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type IdentifierUpdateInput = {
  system?: Maybe<Scalars['Uri']>;
  value?: Maybe<Scalars['String']>;
  use?: Maybe<IdentifierUse>;
  extension?: Maybe<Array<IdentifierExtensionUpdateFieldInput>>;
  _use?: Maybe<Identifier_UseUpdateFieldInput>;
  type?: Maybe<IdentifierTypeUpdateFieldInput>;
  _system?: Maybe<Identifier_SystemUpdateFieldInput>;
  _value?: Maybe<Identifier_ValueUpdateFieldInput>;
  period?: Maybe<IdentifierPeriodUpdateFieldInput>;
  assigner?: Maybe<IdentifierAssignerUpdateFieldInput>;
};

/** The purpose of this identifier. */
export enum IdentifierUse {
  Usual = 'usual',
  Official = 'official',
  Temp = 'temp',
  Secondary = 'secondary',
  Old = 'old',
}

export type IdentifierWhere = {
  OR?: Maybe<Array<IdentifierWhere>>;
  AND?: Maybe<Array<IdentifierWhere>>;
  system?: Maybe<Scalars['Uri']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  use?: Maybe<IdentifierUse>;
  use_NOT?: Maybe<IdentifierUse>;
  use_IN?: Maybe<Array<Maybe<IdentifierUse>>>;
  use_NOT_IN?: Maybe<Array<Maybe<IdentifierUse>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _use?: Maybe<ElementWhere>;
  _use_NOT?: Maybe<ElementWhere>;
  _use_IN?: Maybe<Array<ElementWhere>>;
  _use_NOT_IN?: Maybe<Array<ElementWhere>>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _system?: Maybe<ElementWhere>;
  _system_NOT?: Maybe<ElementWhere>;
  _system_IN?: Maybe<Array<ElementWhere>>;
  _system_NOT_IN?: Maybe<Array<ElementWhere>>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
  assigner?: Maybe<ReferenceWhere>;
  assigner_NOT?: Maybe<ReferenceWhere>;
  assigner_IN?: Maybe<Array<ReferenceWhere>>;
  assigner_NOT_IN?: Maybe<Array<ReferenceWhere>>;
};

export type Identifier_SystemDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Identifier_SystemFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Identifier_SystemUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Identifier_UseDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Identifier_UseFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Identifier_UseUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Identifier_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Identifier_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Identifier_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type Location = {
  __typename?: 'Location';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a Location resource */
  resourceType: LocationResourceType;
  /** The status property covers the general availability of the resource, not the current value which may be covered by the operationStatus, or by a schedule/slots if they are configured for the location. */
  status?: Maybe<LocationStatus>;
  /** Indicates whether a resource instance represents a specific location or a class of locations. */
  mode?: Maybe<LocationMode>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** Name of the location as used by humans. Does not need to be unique. */
  name?: Maybe<Scalars['String']>;
  /** A list of alternate names that the location is known as, or was known as, in the past. */
  alias: Array<Scalars['String']>;
  /** Description of the Location, which helps in finding or referencing the place. */
  description?: Maybe<Scalars['String']>;
  /** A description of when the locations opening ours are different to normal, e.g. public holiday availability. Succinctly describing all possible exceptions to normal site availability as detailed in the opening hours Times. */
  availabilityExceptions?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _status?: Maybe<Element>;
  operationalStatus?: Maybe<Coding>;
  _name?: Maybe<Element>;
  _alias: Array<Element>;
  _description?: Maybe<Element>;
  _mode?: Maybe<Element>;
  type: Array<CodeableConcept>;
  telecom: Array<ContactPoint>;
  address?: Maybe<Address>;
  physicalType?: Maybe<CodeableConcept>;
  position?: Maybe<LocationPosition>;
  managingOrganization?: Maybe<Reference>;
  partOf?: Maybe<Reference>;
  hoursOfOperation: Array<LocationHoursOfOperation>;
  _availabilityExceptions?: Maybe<Element>;
  endpoint: Array<Reference>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type Location_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type Location_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type Location_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationOperationalStatusArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type Location_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type Location_AliasArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type Location_DescriptionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type Location_ModeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationAddressArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationPhysicalTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationPositionArgs = {
  where?: Maybe<LocationPositionWhere>;
  options?: Maybe<LocationPositionOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationManagingOrganizationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationPartOfArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationHoursOfOperationArgs = {
  where?: Maybe<LocationHoursOfOperationWhere>;
  options?: Maybe<LocationHoursOfOperationOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type Location_AvailabilityExceptionsArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationEndpointArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type LocationAddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type LocationAddressFieldInput = {
  create?: Maybe<AddressCreateInput>;
  connect?: Maybe<AddressConnectFieldInput>;
};

export type LocationAddressUpdateFieldInput = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<AddressConnectFieldInput>;
  disconnect?: Maybe<AddressDisconnectFieldInput>;
  create?: Maybe<AddressCreateInput>;
  delete?: Maybe<AddressDeleteFieldInput>;
};

export type LocationConnectFieldInput = {
  where?: Maybe<LocationWhere>;
  connect?: Maybe<LocationConnectInput>;
};

export type LocationConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  operationalStatus?: Maybe<CodingConnectFieldInput>;
  _name?: Maybe<ElementConnectFieldInput>;
  _alias?: Maybe<Array<ElementConnectFieldInput>>;
  _description?: Maybe<ElementConnectFieldInput>;
  _mode?: Maybe<ElementConnectFieldInput>;
  type?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  address?: Maybe<AddressConnectFieldInput>;
  physicalType?: Maybe<CodeableConceptConnectFieldInput>;
  position?: Maybe<LocationPositionConnectFieldInput>;
  managingOrganization?: Maybe<ReferenceConnectFieldInput>;
  partOf?: Maybe<ReferenceConnectFieldInput>;
  hoursOfOperation?: Maybe<Array<LocationHoursOfOperationConnectFieldInput>>;
  _availabilityExceptions?: Maybe<ElementConnectFieldInput>;
  endpoint?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type LocationContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type LocationContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type LocationContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type LocationContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type LocationContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type LocationContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type LocationContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type LocationContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type LocationContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type LocationContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type LocationContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type LocationContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type LocationContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type LocationContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type LocationContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type LocationContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type LocationContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type LocationContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type LocationContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type LocationContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type LocationContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type LocationContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type LocationContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type LocationContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type LocationContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type LocationContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type LocationContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type LocationContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type LocationContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type LocationContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type LocationContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type LocationContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type LocationContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type LocationContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type LocationContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type LocationContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type LocationContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type LocationContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type LocationContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type LocationContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type LocationContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type LocationContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type LocationContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type LocationContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type LocationContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type LocationContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type LocationContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type LocationContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type LocationContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type LocationContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type LocationContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type LocationContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type LocationContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type LocationContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type LocationContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type LocationContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type LocationContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type LocationContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type LocationContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type LocationContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type LocationContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type LocationContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type LocationContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type LocationCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  alias: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  availabilityExceptions?: Maybe<Scalars['String']>;
  resourceType: LocationResourceType;
  status?: Maybe<LocationStatus>;
  mode?: Maybe<LocationMode>;
  meta?: Maybe<LocationMetaFieldInput>;
  _implicitRules?: Maybe<Location_ImplicitrulesFieldInput>;
  _language?: Maybe<Location_LanguageFieldInput>;
  text?: Maybe<LocationTextFieldInput>;
  contained_Account?: Maybe<LocationContainedAccountFieldInput>;
  contained_Appointment?: Maybe<LocationContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<LocationContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<LocationContainedCoverageFieldInput>;
  contained_Device?: Maybe<LocationContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<LocationContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<LocationContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<LocationContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<LocationContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<LocationContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<LocationContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<LocationContainedLocationFieldInput>;
  contained_Organization?: Maybe<LocationContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<LocationContainedPatientFieldInput>;
  contained_Person?: Maybe<LocationContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<LocationContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<LocationContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<LocationContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<LocationContainedScheduleFieldInput>;
  contained_Slot?: Maybe<LocationContainedSlotFieldInput>;
  contained_Substance?: Maybe<LocationContainedSubstanceFieldInput>;
  extension?: Maybe<LocationExtensionFieldInput>;
  modifierExtension?: Maybe<LocationModifierextensionFieldInput>;
  identifier?: Maybe<LocationIdentifierFieldInput>;
  _status?: Maybe<Location_StatusFieldInput>;
  operationalStatus?: Maybe<LocationOperationalstatusFieldInput>;
  _name?: Maybe<Location_NameFieldInput>;
  _alias?: Maybe<Location_AliasFieldInput>;
  _description?: Maybe<Location_DescriptionFieldInput>;
  _mode?: Maybe<Location_ModeFieldInput>;
  type?: Maybe<LocationTypeFieldInput>;
  telecom?: Maybe<LocationTelecomFieldInput>;
  address?: Maybe<LocationAddressFieldInput>;
  physicalType?: Maybe<LocationPhysicaltypeFieldInput>;
  position?: Maybe<LocationPositionFieldInput>;
  managingOrganization?: Maybe<LocationManagingorganizationFieldInput>;
  partOf?: Maybe<LocationPartofFieldInput>;
  hoursOfOperation?: Maybe<LocationHoursofoperationFieldInput>;
  _availabilityExceptions?: Maybe<Location_AvailabilityexceptionsFieldInput>;
  endpoint?: Maybe<LocationEndpointFieldInput>;
};

export type LocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type LocationDeleteInput = {
  meta?: Maybe<LocationMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Location_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Location_LanguageDeleteFieldInput>;
  text?: Maybe<LocationTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<LocationContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<LocationContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<LocationContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<LocationContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<LocationContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<LocationContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<LocationContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<LocationContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<LocationContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<LocationContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<LocationContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<LocationContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<LocationContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<LocationContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<LocationContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<LocationContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<LocationContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<LocationContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<LocationContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<LocationContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<LocationContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<LocationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<LocationModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<LocationIdentifierDeleteFieldInput>>;
  _status?: Maybe<Location_StatusDeleteFieldInput>;
  operationalStatus?: Maybe<LocationOperationalstatusDeleteFieldInput>;
  _name?: Maybe<Location_NameDeleteFieldInput>;
  _alias?: Maybe<Array<Location_AliasDeleteFieldInput>>;
  _description?: Maybe<Location_DescriptionDeleteFieldInput>;
  _mode?: Maybe<Location_ModeDeleteFieldInput>;
  type?: Maybe<Array<LocationTypeDeleteFieldInput>>;
  telecom?: Maybe<Array<LocationTelecomDeleteFieldInput>>;
  address?: Maybe<LocationAddressDeleteFieldInput>;
  physicalType?: Maybe<LocationPhysicaltypeDeleteFieldInput>;
  position?: Maybe<LocationPositionDeleteFieldInput>;
  managingOrganization?: Maybe<LocationManagingorganizationDeleteFieldInput>;
  partOf?: Maybe<LocationPartofDeleteFieldInput>;
  hoursOfOperation?: Maybe<Array<LocationHoursofoperationDeleteFieldInput>>;
  _availabilityExceptions?: Maybe<Location_AvailabilityexceptionsDeleteFieldInput>;
  endpoint?: Maybe<Array<LocationEndpointDeleteFieldInput>>;
};

export type LocationDisconnectFieldInput = {
  where?: Maybe<LocationWhere>;
  disconnect?: Maybe<LocationDisconnectInput>;
};

export type LocationDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  operationalStatus?: Maybe<CodingDisconnectFieldInput>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  _alias?: Maybe<Array<ElementDisconnectFieldInput>>;
  _description?: Maybe<ElementDisconnectFieldInput>;
  _mode?: Maybe<ElementDisconnectFieldInput>;
  type?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  address?: Maybe<AddressDisconnectFieldInput>;
  physicalType?: Maybe<CodeableConceptDisconnectFieldInput>;
  position?: Maybe<LocationPositionDisconnectFieldInput>;
  managingOrganization?: Maybe<ReferenceDisconnectFieldInput>;
  partOf?: Maybe<ReferenceDisconnectFieldInput>;
  hoursOfOperation?: Maybe<Array<LocationHoursOfOperationDisconnectFieldInput>>;
  _availabilityExceptions?: Maybe<ElementDisconnectFieldInput>;
  endpoint?: Maybe<Array<ReferenceDisconnectFieldInput>>;
};

export type LocationEndpointDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type LocationEndpointFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type LocationEndpointUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type LocationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type LocationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type LocationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationHoursOfOperation = {
  __typename?: 'LocationHoursOfOperation';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The Location is open all day. */
  allDay?: Maybe<Scalars['Boolean']>;
  /** Indicates which days of the week are available between the start and end Times. */
  daysOfWeek: Array<Scalars['Code']>;
  /** Time that the Location opens. */
  openingTime?: Maybe<Scalars['Time']>;
  /** Time that the Location closes. */
  closingTime?: Maybe<Scalars['Time']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _daysOfWeek: Array<Element>;
  _allDay?: Maybe<Element>;
  _openingTime?: Maybe<Element>;
  _closingTime?: Maybe<Element>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationHoursOfOperationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationHoursOfOperationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationHoursOfOperation_DaysOfWeekArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationHoursOfOperation_AllDayArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationHoursOfOperation_OpeningTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationHoursOfOperation_ClosingTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type LocationHoursOfOperationConnectFieldInput = {
  where?: Maybe<LocationHoursOfOperationWhere>;
  connect?: Maybe<LocationHoursOfOperationConnectInput>;
};

export type LocationHoursOfOperationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _daysOfWeek?: Maybe<Array<ElementConnectFieldInput>>;
  _allDay?: Maybe<ElementConnectFieldInput>;
  _openingTime?: Maybe<ElementConnectFieldInput>;
  _closingTime?: Maybe<ElementConnectFieldInput>;
};

export type LocationHoursOfOperationCreateInput = {
  allDay?: Maybe<Scalars['Boolean']>;
  daysOfWeek: Array<Scalars['Code']>;
  openingTime?: Maybe<Scalars['Time']>;
  closingTime?: Maybe<Scalars['Time']>;
  extension?: Maybe<LocationHoursOfOperationExtensionFieldInput>;
  modifierExtension?: Maybe<LocationHoursOfOperationModifierextensionFieldInput>;
  _daysOfWeek?: Maybe<LocationHoursOfOperation_DaysofweekFieldInput>;
  _allDay?: Maybe<LocationHoursOfOperation_AlldayFieldInput>;
  _openingTime?: Maybe<LocationHoursOfOperation_OpeningtimeFieldInput>;
  _closingTime?: Maybe<LocationHoursOfOperation_ClosingtimeFieldInput>;
};

export type LocationHoursOfOperationDeleteFieldInput = {
  where?: Maybe<LocationHoursOfOperationWhere>;
  delete?: Maybe<LocationHoursOfOperationDeleteInput>;
};

export type LocationHoursOfOperationDeleteInput = {
  extension?: Maybe<Array<LocationHoursOfOperationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<LocationHoursOfOperationModifierextensionDeleteFieldInput>>;
  _daysOfWeek?: Maybe<Array<LocationHoursOfOperation_DaysofweekDeleteFieldInput>>;
  _allDay?: Maybe<LocationHoursOfOperation_AlldayDeleteFieldInput>;
  _openingTime?: Maybe<LocationHoursOfOperation_OpeningtimeDeleteFieldInput>;
  _closingTime?: Maybe<LocationHoursOfOperation_ClosingtimeDeleteFieldInput>;
};

export type LocationHoursOfOperationDisconnectFieldInput = {
  where?: Maybe<LocationHoursOfOperationWhere>;
  disconnect?: Maybe<LocationHoursOfOperationDisconnectInput>;
};

export type LocationHoursOfOperationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _daysOfWeek?: Maybe<Array<ElementDisconnectFieldInput>>;
  _allDay?: Maybe<ElementDisconnectFieldInput>;
  _openingTime?: Maybe<ElementDisconnectFieldInput>;
  _closingTime?: Maybe<ElementDisconnectFieldInput>;
};

export type LocationHoursOfOperationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type LocationHoursOfOperationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type LocationHoursOfOperationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type LocationHoursOfOperationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type LocationHoursOfOperationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type LocationHoursOfOperationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type LocationHoursOfOperationOptions = {
  /** Specify one or more LocationHoursOfOperationSort objects to sort LocationHoursOfOperations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<LocationHoursOfOperationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type LocationHoursOfOperationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _daysOfWeek?: Maybe<Array<ElementCreateInput>>;
  _allDay?: Maybe<ElementCreateInput>;
  _openingTime?: Maybe<ElementCreateInput>;
  _closingTime?: Maybe<ElementCreateInput>;
};

/** Fields to sort LocationHoursOfOperations by. The order in which sorts are applied is not guaranteed when specifying many fields in one LocationHoursOfOperationSort object. */
export type LocationHoursOfOperationSort = {
  id?: Maybe<SortDirection>;
  allDay?: Maybe<SortDirection>;
  openingTime?: Maybe<SortDirection>;
  closingTime?: Maybe<SortDirection>;
};

export type LocationHoursOfOperationUpdateInput = {
  allDay?: Maybe<Scalars['Boolean']>;
  daysOfWeek?: Maybe<Array<Scalars['Code']>>;
  openingTime?: Maybe<Scalars['Time']>;
  closingTime?: Maybe<Scalars['Time']>;
  extension?: Maybe<Array<LocationHoursOfOperationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<LocationHoursOfOperationModifierextensionUpdateFieldInput>>;
  _daysOfWeek?: Maybe<Array<LocationHoursOfOperation_DaysofweekUpdateFieldInput>>;
  _allDay?: Maybe<LocationHoursOfOperation_AlldayUpdateFieldInput>;
  _openingTime?: Maybe<LocationHoursOfOperation_OpeningtimeUpdateFieldInput>;
  _closingTime?: Maybe<LocationHoursOfOperation_ClosingtimeUpdateFieldInput>;
};

export type LocationHoursOfOperationWhere = {
  OR?: Maybe<Array<LocationHoursOfOperationWhere>>;
  AND?: Maybe<Array<LocationHoursOfOperationWhere>>;
  daysOfWeek?: Maybe<Array<Maybe<Scalars['Code']>>>;
  openingTime?: Maybe<Scalars['Time']>;
  closingTime?: Maybe<Scalars['Time']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  allDay?: Maybe<Scalars['Boolean']>;
  allDay_NOT?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _daysOfWeek?: Maybe<ElementWhere>;
  _daysOfWeek_NOT?: Maybe<ElementWhere>;
  _allDay?: Maybe<ElementWhere>;
  _allDay_NOT?: Maybe<ElementWhere>;
  _allDay_IN?: Maybe<Array<ElementWhere>>;
  _allDay_NOT_IN?: Maybe<Array<ElementWhere>>;
  _openingTime?: Maybe<ElementWhere>;
  _openingTime_NOT?: Maybe<ElementWhere>;
  _openingTime_IN?: Maybe<Array<ElementWhere>>;
  _openingTime_NOT_IN?: Maybe<Array<ElementWhere>>;
  _closingTime?: Maybe<ElementWhere>;
  _closingTime_NOT?: Maybe<ElementWhere>;
  _closingTime_IN?: Maybe<Array<ElementWhere>>;
  _closingTime_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type LocationHoursOfOperation_AlldayDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type LocationHoursOfOperation_AlldayFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type LocationHoursOfOperation_AlldayUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type LocationHoursOfOperation_ClosingtimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type LocationHoursOfOperation_ClosingtimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type LocationHoursOfOperation_ClosingtimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type LocationHoursOfOperation_DaysofweekDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type LocationHoursOfOperation_DaysofweekFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type LocationHoursOfOperation_DaysofweekUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type LocationHoursOfOperation_OpeningtimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type LocationHoursOfOperation_OpeningtimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type LocationHoursOfOperation_OpeningtimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type LocationHoursofoperationDeleteFieldInput = {
  where?: Maybe<LocationHoursOfOperationWhere>;
  delete?: Maybe<LocationHoursOfOperationDeleteInput>;
};

export type LocationHoursofoperationFieldInput = {
  create?: Maybe<Array<LocationHoursOfOperationCreateInput>>;
  connect?: Maybe<Array<LocationHoursOfOperationConnectFieldInput>>;
};

export type LocationHoursofoperationUpdateFieldInput = {
  where?: Maybe<LocationHoursOfOperationWhere>;
  update?: Maybe<LocationHoursOfOperationUpdateInput>;
  connect?: Maybe<Array<LocationHoursOfOperationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationHoursOfOperationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationHoursOfOperationCreateInput>>;
  delete?: Maybe<Array<LocationHoursOfOperationDeleteFieldInput>>;
};

export type LocationIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type LocationIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type LocationIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type LocationManagingorganizationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type LocationManagingorganizationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type LocationManagingorganizationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type LocationMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type LocationMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type LocationMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

/** Indicates whether a resource instance represents a specific location or a class of locations. */
export enum LocationMode {
  Instance = 'instance',
  Kind = 'kind',
}

export type LocationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type LocationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type LocationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type LocationOperationalstatusDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type LocationOperationalstatusFieldInput = {
  create?: Maybe<CodingCreateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
};

export type LocationOperationalstatusUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
  disconnect?: Maybe<CodingDisconnectFieldInput>;
  create?: Maybe<CodingCreateInput>;
  delete?: Maybe<CodingDeleteFieldInput>;
};

export type LocationOptions = {
  /** Specify one or more LocationSort objects to sort Locations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<LocationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type LocationPartofDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type LocationPartofFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type LocationPartofUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type LocationPhysicaltypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type LocationPhysicaltypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type LocationPhysicaltypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationPosition = {
  __typename?: 'LocationPosition';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Longitude. The value domain and the interpretation are the same as for the text of the longitude element in KML (see notes below). */
  longitude?: Maybe<Scalars['Decimal']>;
  /** Latitude. The value domain and the interpretation are the same as for the text of the latitude element in KML (see notes below). */
  latitude?: Maybe<Scalars['Decimal']>;
  /** Altitude. The value domain and the interpretation are the same as for the text of the altitude element in KML (see notes below). */
  altitude?: Maybe<Scalars['Decimal']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _longitude?: Maybe<Element>;
  _latitude?: Maybe<Element>;
  _altitude?: Maybe<Element>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationPositionExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationPositionModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationPosition_LongitudeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationPosition_LatitudeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained, or accommodated. */
export type LocationPosition_AltitudeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type LocationPositionConnectFieldInput = {
  where?: Maybe<LocationPositionWhere>;
  connect?: Maybe<LocationPositionConnectInput>;
};

export type LocationPositionConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _longitude?: Maybe<ElementConnectFieldInput>;
  _latitude?: Maybe<ElementConnectFieldInput>;
  _altitude?: Maybe<ElementConnectFieldInput>;
};

export type LocationPositionCreateInput = {
  longitude?: Maybe<Scalars['Decimal']>;
  latitude?: Maybe<Scalars['Decimal']>;
  altitude?: Maybe<Scalars['Decimal']>;
  extension?: Maybe<LocationPositionExtensionFieldInput>;
  modifierExtension?: Maybe<LocationPositionModifierextensionFieldInput>;
  _longitude?: Maybe<LocationPosition_LongitudeFieldInput>;
  _latitude?: Maybe<LocationPosition_LatitudeFieldInput>;
  _altitude?: Maybe<LocationPosition_AltitudeFieldInput>;
};

export type LocationPositionDeleteFieldInput = {
  where?: Maybe<LocationPositionWhere>;
  delete?: Maybe<LocationPositionDeleteInput>;
};

export type LocationPositionDeleteInput = {
  extension?: Maybe<Array<LocationPositionExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<LocationPositionModifierextensionDeleteFieldInput>>;
  _longitude?: Maybe<LocationPosition_LongitudeDeleteFieldInput>;
  _latitude?: Maybe<LocationPosition_LatitudeDeleteFieldInput>;
  _altitude?: Maybe<LocationPosition_AltitudeDeleteFieldInput>;
};

export type LocationPositionDisconnectFieldInput = {
  where?: Maybe<LocationPositionWhere>;
  disconnect?: Maybe<LocationPositionDisconnectInput>;
};

export type LocationPositionDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _longitude?: Maybe<ElementDisconnectFieldInput>;
  _latitude?: Maybe<ElementDisconnectFieldInput>;
  _altitude?: Maybe<ElementDisconnectFieldInput>;
};

export type LocationPositionExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type LocationPositionExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type LocationPositionExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type LocationPositionFieldInput = {
  create?: Maybe<LocationPositionCreateInput>;
  connect?: Maybe<LocationPositionConnectFieldInput>;
};

export type LocationPositionModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type LocationPositionModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type LocationPositionModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type LocationPositionOptions = {
  /** Specify one or more LocationPositionSort objects to sort LocationPositions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<LocationPositionSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type LocationPositionRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _longitude?: Maybe<ElementCreateInput>;
  _latitude?: Maybe<ElementCreateInput>;
  _altitude?: Maybe<ElementCreateInput>;
};

/** Fields to sort LocationPositions by. The order in which sorts are applied is not guaranteed when specifying many fields in one LocationPositionSort object. */
export type LocationPositionSort = {
  id?: Maybe<SortDirection>;
  longitude?: Maybe<SortDirection>;
  latitude?: Maybe<SortDirection>;
  altitude?: Maybe<SortDirection>;
};

export type LocationPositionUpdateFieldInput = {
  where?: Maybe<LocationPositionWhere>;
  update?: Maybe<LocationPositionUpdateInput>;
  connect?: Maybe<LocationPositionConnectFieldInput>;
  disconnect?: Maybe<LocationPositionDisconnectFieldInput>;
  create?: Maybe<LocationPositionCreateInput>;
  delete?: Maybe<LocationPositionDeleteFieldInput>;
};

export type LocationPositionUpdateInput = {
  longitude?: Maybe<Scalars['Decimal']>;
  latitude?: Maybe<Scalars['Decimal']>;
  altitude?: Maybe<Scalars['Decimal']>;
  extension?: Maybe<Array<LocationPositionExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<LocationPositionModifierextensionUpdateFieldInput>>;
  _longitude?: Maybe<LocationPosition_LongitudeUpdateFieldInput>;
  _latitude?: Maybe<LocationPosition_LatitudeUpdateFieldInput>;
  _altitude?: Maybe<LocationPosition_AltitudeUpdateFieldInput>;
};

export type LocationPositionWhere = {
  OR?: Maybe<Array<LocationPositionWhere>>;
  AND?: Maybe<Array<LocationPositionWhere>>;
  longitude?: Maybe<Scalars['Decimal']>;
  latitude?: Maybe<Scalars['Decimal']>;
  altitude?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _longitude?: Maybe<ElementWhere>;
  _longitude_NOT?: Maybe<ElementWhere>;
  _longitude_IN?: Maybe<Array<ElementWhere>>;
  _longitude_NOT_IN?: Maybe<Array<ElementWhere>>;
  _latitude?: Maybe<ElementWhere>;
  _latitude_NOT?: Maybe<ElementWhere>;
  _latitude_IN?: Maybe<Array<ElementWhere>>;
  _latitude_NOT_IN?: Maybe<Array<ElementWhere>>;
  _altitude?: Maybe<ElementWhere>;
  _altitude_NOT?: Maybe<ElementWhere>;
  _altitude_IN?: Maybe<Array<ElementWhere>>;
  _altitude_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type LocationPosition_AltitudeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type LocationPosition_AltitudeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type LocationPosition_AltitudeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type LocationPosition_LatitudeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type LocationPosition_LatitudeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type LocationPosition_LatitudeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type LocationPosition_LongitudeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type LocationPosition_LongitudeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type LocationPosition_LongitudeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type LocationRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  operationalStatus?: Maybe<CodingCreateInput>;
  _name?: Maybe<ElementCreateInput>;
  _alias?: Maybe<Array<ElementCreateInput>>;
  _description?: Maybe<ElementCreateInput>;
  _mode?: Maybe<ElementCreateInput>;
  type?: Maybe<Array<CodeableConceptCreateInput>>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  address?: Maybe<AddressCreateInput>;
  physicalType?: Maybe<CodeableConceptCreateInput>;
  position?: Maybe<LocationPositionCreateInput>;
  managingOrganization?: Maybe<ReferenceCreateInput>;
  partOf?: Maybe<ReferenceCreateInput>;
  hoursOfOperation?: Maybe<Array<LocationHoursOfOperationCreateInput>>;
  _availabilityExceptions?: Maybe<ElementCreateInput>;
  endpoint?: Maybe<Array<ReferenceCreateInput>>;
};

export enum LocationResourceType {
  Location = 'Location',
}

/** Fields to sort Locations by. The order in which sorts are applied is not guaranteed when specifying many fields in one LocationSort object. */
export type LocationSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  mode?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
  availabilityExceptions?: Maybe<SortDirection>;
};

/** The status property covers the general availability of the resource, not the current value which may be covered by the operationStatus, or by a schedule/slots if they are configured for the location. */
export enum LocationStatus {
  Active = 'active',
  Suspended = 'suspended',
  Inactive = 'inactive',
}

export type LocationTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type LocationTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type LocationTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type LocationTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type LocationTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type LocationTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type LocationTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type LocationTypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type LocationTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type LocationUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  availabilityExceptions?: Maybe<Scalars['String']>;
  resourceType?: Maybe<LocationResourceType>;
  status?: Maybe<LocationStatus>;
  mode?: Maybe<LocationMode>;
  meta?: Maybe<LocationMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Location_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Location_LanguageUpdateFieldInput>;
  text?: Maybe<LocationTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<LocationContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<LocationContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<LocationContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<LocationContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<LocationContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<LocationContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<LocationContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<LocationContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<LocationContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<LocationContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<LocationContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<LocationContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<LocationContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<LocationContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<LocationContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<LocationContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<LocationContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<LocationContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<LocationContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<LocationContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<LocationContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<LocationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<LocationModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<LocationIdentifierUpdateFieldInput>>;
  _status?: Maybe<Location_StatusUpdateFieldInput>;
  operationalStatus?: Maybe<LocationOperationalstatusUpdateFieldInput>;
  _name?: Maybe<Location_NameUpdateFieldInput>;
  _alias?: Maybe<Array<Location_AliasUpdateFieldInput>>;
  _description?: Maybe<Location_DescriptionUpdateFieldInput>;
  _mode?: Maybe<Location_ModeUpdateFieldInput>;
  type?: Maybe<Array<LocationTypeUpdateFieldInput>>;
  telecom?: Maybe<Array<LocationTelecomUpdateFieldInput>>;
  address?: Maybe<LocationAddressUpdateFieldInput>;
  physicalType?: Maybe<LocationPhysicaltypeUpdateFieldInput>;
  position?: Maybe<LocationPositionUpdateFieldInput>;
  managingOrganization?: Maybe<LocationManagingorganizationUpdateFieldInput>;
  partOf?: Maybe<LocationPartofUpdateFieldInput>;
  hoursOfOperation?: Maybe<Array<LocationHoursofoperationUpdateFieldInput>>;
  _availabilityExceptions?: Maybe<Location_AvailabilityexceptionsUpdateFieldInput>;
  endpoint?: Maybe<Array<LocationEndpointUpdateFieldInput>>;
};

export type LocationWhere = {
  OR?: Maybe<Array<LocationWhere>>;
  AND?: Maybe<Array<LocationWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  availabilityExceptions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<LocationResourceType>;
  resourceType_NOT?: Maybe<LocationResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<LocationResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<LocationResourceType>>>;
  status?: Maybe<LocationStatus>;
  status_NOT?: Maybe<LocationStatus>;
  status_IN?: Maybe<Array<Maybe<LocationStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<LocationStatus>>>;
  mode?: Maybe<LocationMode>;
  mode_NOT?: Maybe<LocationMode>;
  mode_IN?: Maybe<Array<Maybe<LocationMode>>>;
  mode_NOT_IN?: Maybe<Array<Maybe<LocationMode>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  operationalStatus?: Maybe<CodingWhere>;
  operationalStatus_NOT?: Maybe<CodingWhere>;
  operationalStatus_IN?: Maybe<Array<CodingWhere>>;
  operationalStatus_NOT_IN?: Maybe<Array<CodingWhere>>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  _alias?: Maybe<ElementWhere>;
  _alias_NOT?: Maybe<ElementWhere>;
  _description?: Maybe<ElementWhere>;
  _description_NOT?: Maybe<ElementWhere>;
  _description_IN?: Maybe<Array<ElementWhere>>;
  _description_NOT_IN?: Maybe<Array<ElementWhere>>;
  _mode?: Maybe<ElementWhere>;
  _mode_NOT?: Maybe<ElementWhere>;
  _mode_IN?: Maybe<Array<ElementWhere>>;
  _mode_NOT_IN?: Maybe<Array<ElementWhere>>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  address?: Maybe<AddressWhere>;
  address_NOT?: Maybe<AddressWhere>;
  address_IN?: Maybe<Array<AddressWhere>>;
  address_NOT_IN?: Maybe<Array<AddressWhere>>;
  physicalType?: Maybe<CodeableConceptWhere>;
  physicalType_NOT?: Maybe<CodeableConceptWhere>;
  physicalType_IN?: Maybe<Array<CodeableConceptWhere>>;
  physicalType_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  position?: Maybe<LocationPositionWhere>;
  position_NOT?: Maybe<LocationPositionWhere>;
  position_IN?: Maybe<Array<LocationPositionWhere>>;
  position_NOT_IN?: Maybe<Array<LocationPositionWhere>>;
  managingOrganization?: Maybe<ReferenceWhere>;
  managingOrganization_NOT?: Maybe<ReferenceWhere>;
  managingOrganization_IN?: Maybe<Array<ReferenceWhere>>;
  managingOrganization_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  partOf?: Maybe<ReferenceWhere>;
  partOf_NOT?: Maybe<ReferenceWhere>;
  partOf_IN?: Maybe<Array<ReferenceWhere>>;
  partOf_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  hoursOfOperation?: Maybe<LocationHoursOfOperationWhere>;
  hoursOfOperation_NOT?: Maybe<LocationHoursOfOperationWhere>;
  _availabilityExceptions?: Maybe<ElementWhere>;
  _availabilityExceptions_NOT?: Maybe<ElementWhere>;
  _availabilityExceptions_IN?: Maybe<Array<ElementWhere>>;
  _availabilityExceptions_NOT_IN?: Maybe<Array<ElementWhere>>;
  endpoint?: Maybe<ReferenceWhere>;
  endpoint_NOT?: Maybe<ReferenceWhere>;
};

export type Location_AliasDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Location_AliasFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type Location_AliasUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type Location_AvailabilityexceptionsDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Location_AvailabilityexceptionsFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Location_AvailabilityexceptionsUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Location_DescriptionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Location_DescriptionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Location_DescriptionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Location_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Location_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Location_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Location_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Location_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Location_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Location_ModeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Location_ModeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Location_ModeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Location_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Location_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Location_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Location_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Location_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Location_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token: Scalars['String'];
  user: User;
};

/** The metadata about a resource. This is content in the resource that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource. */
export type Meta = {
  __typename?: 'Meta';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The version specific identifier, as it appears in the version portion of the URL. This value changes when the resource is created, updated, or deleted. */
  versionId?: Maybe<Scalars['ID']>;
  /** When the resource last changed - e.g. when the version changed. */
  lastUpdated?: Maybe<Scalars['Instant']>;
  /** A uri that identifies the source system of the resource. This provides a minimal amount of [[[Provenance]]] information that can be used to track or differentiate the source of information in the resource. The source may identify another FHIR server, document, message, database, etc. */
  source?: Maybe<Scalars['Uri']>;
  /** A list of profiles (references to [[[StructureDefinition]]] resources) that this resource claims to conform to. The URL is a reference to [[[StructureDefinition.url]]]. */
  profile: Array<Scalars['Canonical']>;
  extension: Array<Extension>;
  _versionId?: Maybe<Element>;
  _lastUpdated?: Maybe<Element>;
  _source?: Maybe<Element>;
  security: Array<Coding>;
  tag: Array<Coding>;
};

/** The metadata about a resource. This is content in the resource that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource. */
export type MetaExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The metadata about a resource. This is content in the resource that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource. */
export type Meta_VersionIdArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The metadata about a resource. This is content in the resource that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource. */
export type Meta_LastUpdatedArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The metadata about a resource. This is content in the resource that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource. */
export type Meta_SourceArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The metadata about a resource. This is content in the resource that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource. */
export type MetaSecurityArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

/** The metadata about a resource. This is content in the resource that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource. */
export type MetaTagArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

export type MetaConnectFieldInput = {
  where?: Maybe<MetaWhere>;
  connect?: Maybe<MetaConnectInput>;
};

export type MetaConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _versionId?: Maybe<ElementConnectFieldInput>;
  _lastUpdated?: Maybe<ElementConnectFieldInput>;
  _source?: Maybe<ElementConnectFieldInput>;
  security?: Maybe<Array<CodingConnectFieldInput>>;
  tag?: Maybe<Array<CodingConnectFieldInput>>;
};

export type MetaCreateInput = {
  versionId?: Maybe<Scalars['ID']>;
  lastUpdated?: Maybe<Scalars['Instant']>;
  source?: Maybe<Scalars['Uri']>;
  profile: Array<Scalars['Canonical']>;
  extension?: Maybe<MetaExtensionFieldInput>;
  _versionId?: Maybe<Meta_VersionidFieldInput>;
  _lastUpdated?: Maybe<Meta_LastupdatedFieldInput>;
  _source?: Maybe<Meta_SourceFieldInput>;
  security?: Maybe<MetaSecurityFieldInput>;
  tag?: Maybe<MetaTagFieldInput>;
};

export type MetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type MetaDeleteInput = {
  extension?: Maybe<Array<MetaExtensionDeleteFieldInput>>;
  _versionId?: Maybe<Meta_VersionidDeleteFieldInput>;
  _lastUpdated?: Maybe<Meta_LastupdatedDeleteFieldInput>;
  _source?: Maybe<Meta_SourceDeleteFieldInput>;
  security?: Maybe<Array<MetaSecurityDeleteFieldInput>>;
  tag?: Maybe<Array<MetaTagDeleteFieldInput>>;
};

export type MetaDisconnectFieldInput = {
  where?: Maybe<MetaWhere>;
  disconnect?: Maybe<MetaDisconnectInput>;
};

export type MetaDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _versionId?: Maybe<ElementDisconnectFieldInput>;
  _lastUpdated?: Maybe<ElementDisconnectFieldInput>;
  _source?: Maybe<ElementDisconnectFieldInput>;
  security?: Maybe<Array<CodingDisconnectFieldInput>>;
  tag?: Maybe<Array<CodingDisconnectFieldInput>>;
};

export type MetaExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type MetaExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type MetaExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type MetaOptions = {
  /** Specify one or more MetaSort objects to sort Metas by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<MetaSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type MetaRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _versionId?: Maybe<ElementCreateInput>;
  _lastUpdated?: Maybe<ElementCreateInput>;
  _source?: Maybe<ElementCreateInput>;
  security?: Maybe<Array<CodingCreateInput>>;
  tag?: Maybe<Array<CodingCreateInput>>;
};

export type MetaSecurityDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type MetaSecurityFieldInput = {
  create?: Maybe<Array<CodingCreateInput>>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
};

export type MetaSecurityUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
  disconnect?: Maybe<Array<CodingDisconnectFieldInput>>;
  create?: Maybe<Array<CodingCreateInput>>;
  delete?: Maybe<Array<CodingDeleteFieldInput>>;
};

/** Fields to sort Metas by. The order in which sorts are applied is not guaranteed when specifying many fields in one MetaSort object. */
export type MetaSort = {
  id?: Maybe<SortDirection>;
  versionId?: Maybe<SortDirection>;
  lastUpdated?: Maybe<SortDirection>;
  source?: Maybe<SortDirection>;
};

export type MetaTagDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type MetaTagFieldInput = {
  create?: Maybe<Array<CodingCreateInput>>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
};

export type MetaTagUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
  disconnect?: Maybe<Array<CodingDisconnectFieldInput>>;
  create?: Maybe<Array<CodingCreateInput>>;
  delete?: Maybe<Array<CodingDeleteFieldInput>>;
};

export type MetaUpdateInput = {
  versionId?: Maybe<Scalars['ID']>;
  lastUpdated?: Maybe<Scalars['Instant']>;
  source?: Maybe<Scalars['Uri']>;
  profile?: Maybe<Array<Scalars['Canonical']>>;
  extension?: Maybe<Array<MetaExtensionUpdateFieldInput>>;
  _versionId?: Maybe<Meta_VersionidUpdateFieldInput>;
  _lastUpdated?: Maybe<Meta_LastupdatedUpdateFieldInput>;
  _source?: Maybe<Meta_SourceUpdateFieldInput>;
  security?: Maybe<Array<MetaSecurityUpdateFieldInput>>;
  tag?: Maybe<Array<MetaTagUpdateFieldInput>>;
};

export type MetaWhere = {
  OR?: Maybe<Array<MetaWhere>>;
  AND?: Maybe<Array<MetaWhere>>;
  lastUpdated?: Maybe<Scalars['Instant']>;
  source?: Maybe<Scalars['Uri']>;
  profile?: Maybe<Array<Maybe<Scalars['Canonical']>>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  versionId?: Maybe<Scalars['ID']>;
  versionId_NOT?: Maybe<Scalars['ID']>;
  versionId_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  versionId_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  versionId_CONTAINS?: Maybe<Scalars['ID']>;
  versionId_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  versionId_STARTS_WITH?: Maybe<Scalars['ID']>;
  versionId_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  versionId_ENDS_WITH?: Maybe<Scalars['ID']>;
  versionId_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _versionId?: Maybe<ElementWhere>;
  _versionId_NOT?: Maybe<ElementWhere>;
  _versionId_IN?: Maybe<Array<ElementWhere>>;
  _versionId_NOT_IN?: Maybe<Array<ElementWhere>>;
  _lastUpdated?: Maybe<ElementWhere>;
  _lastUpdated_NOT?: Maybe<ElementWhere>;
  _lastUpdated_IN?: Maybe<Array<ElementWhere>>;
  _lastUpdated_NOT_IN?: Maybe<Array<ElementWhere>>;
  _source?: Maybe<ElementWhere>;
  _source_NOT?: Maybe<ElementWhere>;
  _source_IN?: Maybe<Array<ElementWhere>>;
  _source_NOT_IN?: Maybe<Array<ElementWhere>>;
  security?: Maybe<CodingWhere>;
  security_NOT?: Maybe<CodingWhere>;
  tag?: Maybe<CodingWhere>;
  tag_NOT?: Maybe<CodingWhere>;
};

export type Meta_LastupdatedDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Meta_LastupdatedFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Meta_LastupdatedUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Meta_SourceDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Meta_SourceFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Meta_SourceUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Meta_VersionidDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Meta_VersionidFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Meta_VersionidUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** An amount of economic utility in some recognized currency. */
export type Money = {
  __typename?: 'Money';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Numerical value (with implicit precision). */
  value?: Maybe<Scalars['Decimal']>;
  /** ISO 4217 Currency Code. */
  currency?: Maybe<Scalars['Code']>;
  extension: Array<Extension>;
  _value?: Maybe<Element>;
  _currency?: Maybe<Element>;
};

/** An amount of economic utility in some recognized currency. */
export type MoneyExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** An amount of economic utility in some recognized currency. */
export type Money_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** An amount of economic utility in some recognized currency. */
export type Money_CurrencyArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type MoneyConnectFieldInput = {
  where?: Maybe<MoneyWhere>;
  connect?: Maybe<MoneyConnectInput>;
};

export type MoneyConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _value?: Maybe<ElementConnectFieldInput>;
  _currency?: Maybe<ElementConnectFieldInput>;
};

export type MoneyCreateInput = {
  value?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Scalars['Code']>;
  extension?: Maybe<MoneyExtensionFieldInput>;
  _value?: Maybe<Money_ValueFieldInput>;
  _currency?: Maybe<Money_CurrencyFieldInput>;
};

export type MoneyDeleteFieldInput = {
  where?: Maybe<MoneyWhere>;
  delete?: Maybe<MoneyDeleteInput>;
};

export type MoneyDeleteInput = {
  extension?: Maybe<Array<MoneyExtensionDeleteFieldInput>>;
  _value?: Maybe<Money_ValueDeleteFieldInput>;
  _currency?: Maybe<Money_CurrencyDeleteFieldInput>;
};

export type MoneyDisconnectFieldInput = {
  where?: Maybe<MoneyWhere>;
  disconnect?: Maybe<MoneyDisconnectInput>;
};

export type MoneyDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _value?: Maybe<ElementDisconnectFieldInput>;
  _currency?: Maybe<ElementDisconnectFieldInput>;
};

export type MoneyExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type MoneyExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type MoneyExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type MoneyOptions = {
  /** Specify one or more MoneySort objects to sort Monies by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<MoneySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type MoneyRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _value?: Maybe<ElementCreateInput>;
  _currency?: Maybe<ElementCreateInput>;
};

/** Fields to sort Monies by. The order in which sorts are applied is not guaranteed when specifying many fields in one MoneySort object. */
export type MoneySort = {
  id?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  currency?: Maybe<SortDirection>;
};

export type MoneyUpdateInput = {
  value?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Scalars['Code']>;
  extension?: Maybe<Array<MoneyExtensionUpdateFieldInput>>;
  _value?: Maybe<Money_ValueUpdateFieldInput>;
  _currency?: Maybe<Money_CurrencyUpdateFieldInput>;
};

export type MoneyWhere = {
  OR?: Maybe<Array<MoneyWhere>>;
  AND?: Maybe<Array<MoneyWhere>>;
  value?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Scalars['Code']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
  _currency?: Maybe<ElementWhere>;
  _currency_NOT?: Maybe<ElementWhere>;
  _currency_IN?: Maybe<Array<ElementWhere>>;
  _currency_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Money_CurrencyDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Money_CurrencyFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Money_CurrencyUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Money_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Money_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Money_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAccounts: CreateAccountsMutationResponse;
  deleteAccounts: DeleteInfo;
  updateAccounts: UpdateAccountsMutationResponse;
  createMetas: CreateMetasMutationResponse;
  deleteMetas: DeleteInfo;
  updateMetas: UpdateMetasMutationResponse;
  createExtensions: CreateExtensionsMutationResponse;
  deleteExtensions: DeleteInfo;
  updateExtensions: UpdateExtensionsMutationResponse;
  createElements: CreateElementsMutationResponse;
  deleteElements: DeleteInfo;
  updateElements: UpdateElementsMutationResponse;
  createAddresses: CreateAddressesMutationResponse;
  deleteAddresses: DeleteInfo;
  updateAddresses: UpdateAddressesMutationResponse;
  createPeriods: CreatePeriodsMutationResponse;
  deletePeriods: DeleteInfo;
  updatePeriods: UpdatePeriodsMutationResponse;
  createAges: CreateAgesMutationResponse;
  deleteAges: DeleteInfo;
  updateAges: UpdateAgesMutationResponse;
  createAnnotations: CreateAnnotationsMutationResponse;
  deleteAnnotations: DeleteInfo;
  updateAnnotations: UpdateAnnotationsMutationResponse;
  createReferences: CreateReferencesMutationResponse;
  deleteReferences: DeleteInfo;
  updateReferences: UpdateReferencesMutationResponse;
  createIdentifiers: CreateIdentifiersMutationResponse;
  deleteIdentifiers: DeleteInfo;
  updateIdentifiers: UpdateIdentifiersMutationResponse;
  createCodeableConcepts: CreateCodeableConceptsMutationResponse;
  deleteCodeableConcepts: DeleteInfo;
  updateCodeableConcepts: UpdateCodeableConceptsMutationResponse;
  createCodings: CreateCodingsMutationResponse;
  deleteCodings: DeleteInfo;
  updateCodings: UpdateCodingsMutationResponse;
  createAttachments: CreateAttachmentsMutationResponse;
  deleteAttachments: DeleteInfo;
  updateAttachments: UpdateAttachmentsMutationResponse;
  createContactPoints: CreateContactPointsMutationResponse;
  deleteContactPoints: DeleteInfo;
  updateContactPoints: UpdateContactPointsMutationResponse;
  createCounts: CreateCountsMutationResponse;
  deleteCounts: DeleteInfo;
  updateCounts: UpdateCountsMutationResponse;
  createDistances: CreateDistancesMutationResponse;
  deleteDistances: DeleteInfo;
  updateDistances: UpdateDistancesMutationResponse;
  createDurations: CreateDurationsMutationResponse;
  deleteDurations: DeleteInfo;
  updateDurations: UpdateDurationsMutationResponse;
  createHumanNames: CreateHumanNamesMutationResponse;
  deleteHumanNames: DeleteInfo;
  updateHumanNames: UpdateHumanNamesMutationResponse;
  createMonies: CreateMoniesMutationResponse;
  deleteMonies: DeleteInfo;
  updateMonies: UpdateMoniesMutationResponse;
  createQuantities: CreateQuantitiesMutationResponse;
  deleteQuantities: DeleteInfo;
  updateQuantities: UpdateQuantitiesMutationResponse;
  createRanges: CreateRangesMutationResponse;
  deleteRanges: DeleteInfo;
  updateRanges: UpdateRangesMutationResponse;
  createRatios: CreateRatiosMutationResponse;
  deleteRatios: DeleteInfo;
  updateRatios: UpdateRatiosMutationResponse;
  createSampledData: CreateSampledDataMutationResponse;
  deleteSampledData: DeleteInfo;
  updateSampledData: UpdateSampledDataMutationResponse;
  createSignatures: CreateSignaturesMutationResponse;
  deleteSignatures: DeleteInfo;
  updateSignatures: UpdateSignaturesMutationResponse;
  createTimings: CreateTimingsMutationResponse;
  deleteTimings: DeleteInfo;
  updateTimings: UpdateTimingsMutationResponse;
  createTimingRepeats: CreateTimingRepeatsMutationResponse;
  deleteTimingRepeats: DeleteInfo;
  updateTimingRepeats: UpdateTimingRepeatsMutationResponse;
  createContactDetails: CreateContactDetailsMutationResponse;
  deleteContactDetails: DeleteInfo;
  updateContactDetails: UpdateContactDetailsMutationResponse;
  createContributors: CreateContributorsMutationResponse;
  deleteContributors: DeleteInfo;
  updateContributors: UpdateContributorsMutationResponse;
  createDataRequirements: CreateDataRequirementsMutationResponse;
  deleteDataRequirements: DeleteInfo;
  updateDataRequirements: UpdateDataRequirementsMutationResponse;
  createDataRequirementCodeFilters: CreateDataRequirementCodeFiltersMutationResponse;
  deleteDataRequirementCodeFilters: DeleteInfo;
  updateDataRequirementCodeFilters: UpdateDataRequirementCodeFiltersMutationResponse;
  createDataRequirementDateFilters: CreateDataRequirementDateFiltersMutationResponse;
  deleteDataRequirementDateFilters: DeleteInfo;
  updateDataRequirementDateFilters: UpdateDataRequirementDateFiltersMutationResponse;
  createDataRequirementCustomSorts: CreateDataRequirementCustomSortsMutationResponse;
  deleteDataRequirementCustomSorts: DeleteInfo;
  updateDataRequirementCustomSorts: UpdateDataRequirementCustomSortsMutationResponse;
  createExpressions: CreateExpressionsMutationResponse;
  deleteExpressions: DeleteInfo;
  updateExpressions: UpdateExpressionsMutationResponse;
  createParameterDefinitions: CreateParameterDefinitionsMutationResponse;
  deleteParameterDefinitions: DeleteInfo;
  updateParameterDefinitions: UpdateParameterDefinitionsMutationResponse;
  createRelatedArtifacts: CreateRelatedArtifactsMutationResponse;
  deleteRelatedArtifacts: DeleteInfo;
  updateRelatedArtifacts: UpdateRelatedArtifactsMutationResponse;
  createTriggerDefinitions: CreateTriggerDefinitionsMutationResponse;
  deleteTriggerDefinitions: DeleteInfo;
  updateTriggerDefinitions: UpdateTriggerDefinitionsMutationResponse;
  createUsageContexts: CreateUsageContextsMutationResponse;
  deleteUsageContexts: DeleteInfo;
  updateUsageContexts: UpdateUsageContextsMutationResponse;
  createDosages: CreateDosagesMutationResponse;
  deleteDosages: DeleteInfo;
  updateDosages: UpdateDosagesMutationResponse;
  createDosageDoseAndRates: CreateDosageDoseAndRatesMutationResponse;
  deleteDosageDoseAndRates: DeleteInfo;
  updateDosageDoseAndRates: UpdateDosageDoseAndRatesMutationResponse;
  createNarratives: CreateNarrativesMutationResponse;
  deleteNarratives: DeleteInfo;
  updateNarratives: UpdateNarrativesMutationResponse;
  createAccountCoverages: CreateAccountCoveragesMutationResponse;
  deleteAccountCoverages: DeleteInfo;
  updateAccountCoverages: UpdateAccountCoveragesMutationResponse;
  createAccountGuarantors: CreateAccountGuarantorsMutationResponse;
  deleteAccountGuarantors: DeleteInfo;
  updateAccountGuarantors: UpdateAccountGuarantorsMutationResponse;
  createAppointments: CreateAppointmentsMutationResponse;
  deleteAppointments: DeleteInfo;
  updateAppointments: UpdateAppointmentsMutationResponse;
  createAppointmentParticipants: CreateAppointmentParticipantsMutationResponse;
  deleteAppointmentParticipants: DeleteInfo;
  updateAppointmentParticipants: UpdateAppointmentParticipantsMutationResponse;
  createAppointmentResponses: CreateAppointmentResponsesMutationResponse;
  deleteAppointmentResponses: DeleteInfo;
  updateAppointmentResponses: UpdateAppointmentResponsesMutationResponse;
  createCoverages: CreateCoveragesMutationResponse;
  deleteCoverages: DeleteInfo;
  updateCoverages: UpdateCoveragesMutationResponse;
  createCoverageClasses: CreateCoverageClassesMutationResponse;
  deleteCoverageClasses: DeleteInfo;
  updateCoverageClasses: UpdateCoverageClassesMutationResponse;
  createCoverageCostToBeneficiaries: CreateCoverageCostToBeneficiariesMutationResponse;
  deleteCoverageCostToBeneficiaries: DeleteInfo;
  updateCoverageCostToBeneficiaries: UpdateCoverageCostToBeneficiariesMutationResponse;
  createCoverageExceptions: CreateCoverageExceptionsMutationResponse;
  deleteCoverageExceptions: DeleteInfo;
  updateCoverageExceptions: UpdateCoverageExceptionsMutationResponse;
  createDevices: CreateDevicesMutationResponse;
  deleteDevices: DeleteInfo;
  updateDevices: UpdateDevicesMutationResponse;
  createDeviceUdiCarriers: CreateDeviceUdiCarriersMutationResponse;
  deleteDeviceUdiCarriers: DeleteInfo;
  updateDeviceUdiCarriers: UpdateDeviceUdiCarriersMutationResponse;
  createDeviceDeviceNames: CreateDeviceDeviceNamesMutationResponse;
  deleteDeviceDeviceNames: DeleteInfo;
  updateDeviceDeviceNames: UpdateDeviceDeviceNamesMutationResponse;
  createDeviceSpecializations: CreateDeviceSpecializationsMutationResponse;
  deleteDeviceSpecializations: DeleteInfo;
  updateDeviceSpecializations: UpdateDeviceSpecializationsMutationResponse;
  createDeviceVersions: CreateDeviceVersionsMutationResponse;
  deleteDeviceVersions: DeleteInfo;
  updateDeviceVersions: UpdateDeviceVersionsMutationResponse;
  createDeviceProperties: CreateDevicePropertiesMutationResponse;
  deleteDeviceProperties: DeleteInfo;
  updateDeviceProperties: UpdateDevicePropertiesMutationResponse;
  createDeviceDefinitions: CreateDeviceDefinitionsMutationResponse;
  deleteDeviceDefinitions: DeleteInfo;
  updateDeviceDefinitions: UpdateDeviceDefinitionsMutationResponse;
  createDeviceDefinitionUdiDeviceIdentifiers: CreateDeviceDefinitionUdiDeviceIdentifiersMutationResponse;
  deleteDeviceDefinitionUdiDeviceIdentifiers: DeleteInfo;
  updateDeviceDefinitionUdiDeviceIdentifiers: UpdateDeviceDefinitionUdiDeviceIdentifiersMutationResponse;
  createDeviceDefinitionDeviceNames: CreateDeviceDefinitionDeviceNamesMutationResponse;
  deleteDeviceDefinitionDeviceNames: DeleteInfo;
  updateDeviceDefinitionDeviceNames: UpdateDeviceDefinitionDeviceNamesMutationResponse;
  createDeviceDefinitionSpecializations: CreateDeviceDefinitionSpecializationsMutationResponse;
  deleteDeviceDefinitionSpecializations: DeleteInfo;
  updateDeviceDefinitionSpecializations: UpdateDeviceDefinitionSpecializationsMutationResponse;
  createProductShelfLives: CreateProductShelfLivesMutationResponse;
  deleteProductShelfLives: DeleteInfo;
  updateProductShelfLives: UpdateProductShelfLivesMutationResponse;
  createProdCharacteristics: CreateProdCharacteristicsMutationResponse;
  deleteProdCharacteristics: DeleteInfo;
  updateProdCharacteristics: UpdateProdCharacteristicsMutationResponse;
  createDeviceDefinitionCapabilities: CreateDeviceDefinitionCapabilitiesMutationResponse;
  deleteDeviceDefinitionCapabilities: DeleteInfo;
  updateDeviceDefinitionCapabilities: UpdateDeviceDefinitionCapabilitiesMutationResponse;
  createDeviceDefinitionProperties: CreateDeviceDefinitionPropertiesMutationResponse;
  deleteDeviceDefinitionProperties: DeleteInfo;
  updateDeviceDefinitionProperties: UpdateDeviceDefinitionPropertiesMutationResponse;
  createDeviceDefinitionMaterials: CreateDeviceDefinitionMaterialsMutationResponse;
  deleteDeviceDefinitionMaterials: DeleteInfo;
  updateDeviceDefinitionMaterials: UpdateDeviceDefinitionMaterialsMutationResponse;
  createDeviceMetrics: CreateDeviceMetricsMutationResponse;
  deleteDeviceMetrics: DeleteInfo;
  updateDeviceMetrics: UpdateDeviceMetricsMutationResponse;
  createDeviceMetricCalibrations: CreateDeviceMetricCalibrationsMutationResponse;
  deleteDeviceMetricCalibrations: DeleteInfo;
  updateDeviceMetricCalibrations: UpdateDeviceMetricCalibrationsMutationResponse;
  createEncounters: CreateEncountersMutationResponse;
  deleteEncounters: DeleteInfo;
  updateEncounters: UpdateEncountersMutationResponse;
  createEncounterStatusHistories: CreateEncounterStatusHistoriesMutationResponse;
  deleteEncounterStatusHistories: DeleteInfo;
  updateEncounterStatusHistories: UpdateEncounterStatusHistoriesMutationResponse;
  createEncounterClassHistories: CreateEncounterClassHistoriesMutationResponse;
  deleteEncounterClassHistories: DeleteInfo;
  updateEncounterClassHistories: UpdateEncounterClassHistoriesMutationResponse;
  createEncounterParticipants: CreateEncounterParticipantsMutationResponse;
  deleteEncounterParticipants: DeleteInfo;
  updateEncounterParticipants: UpdateEncounterParticipantsMutationResponse;
  createEncounterDiagnoses: CreateEncounterDiagnosesMutationResponse;
  deleteEncounterDiagnoses: DeleteInfo;
  updateEncounterDiagnoses: UpdateEncounterDiagnosesMutationResponse;
  createEncounterHospitalizations: CreateEncounterHospitalizationsMutationResponse;
  deleteEncounterHospitalizations: DeleteInfo;
  updateEncounterHospitalizations: UpdateEncounterHospitalizationsMutationResponse;
  createEncounterLocations: CreateEncounterLocationsMutationResponse;
  deleteEncounterLocations: DeleteInfo;
  updateEncounterLocations: UpdateEncounterLocationsMutationResponse;
  createEndpoints: CreateEndpointsMutationResponse;
  deleteEndpoints: DeleteInfo;
  updateEndpoints: UpdateEndpointsMutationResponse;
  createEpisodeOfCares: CreateEpisodeOfCaresMutationResponse;
  deleteEpisodeOfCares: DeleteInfo;
  updateEpisodeOfCares: UpdateEpisodeOfCaresMutationResponse;
  createEpisodeOfCareStatusHistories: CreateEpisodeOfCareStatusHistoriesMutationResponse;
  deleteEpisodeOfCareStatusHistories: DeleteInfo;
  updateEpisodeOfCareStatusHistories: UpdateEpisodeOfCareStatusHistoriesMutationResponse;
  createEpisodeOfCareDiagnoses: CreateEpisodeOfCareDiagnosesMutationResponse;
  deleteEpisodeOfCareDiagnoses: DeleteInfo;
  updateEpisodeOfCareDiagnoses: UpdateEpisodeOfCareDiagnosesMutationResponse;
  createHealthcareServices: CreateHealthcareServicesMutationResponse;
  deleteHealthcareServices: DeleteInfo;
  updateHealthcareServices: UpdateHealthcareServicesMutationResponse;
  createHealthcareServiceEligibilities: CreateHealthcareServiceEligibilitiesMutationResponse;
  deleteHealthcareServiceEligibilities: DeleteInfo;
  updateHealthcareServiceEligibilities: UpdateHealthcareServiceEligibilitiesMutationResponse;
  createHealthcareServiceAvailableTimes: CreateHealthcareServiceAvailableTimesMutationResponse;
  deleteHealthcareServiceAvailableTimes: DeleteInfo;
  updateHealthcareServiceAvailableTimes: UpdateHealthcareServiceAvailableTimesMutationResponse;
  createHealthcareServiceNotAvailables: CreateHealthcareServiceNotAvailablesMutationResponse;
  deleteHealthcareServiceNotAvailables: DeleteInfo;
  updateHealthcareServiceNotAvailables: UpdateHealthcareServiceNotAvailablesMutationResponse;
  createLocations: CreateLocationsMutationResponse;
  deleteLocations: DeleteInfo;
  updateLocations: UpdateLocationsMutationResponse;
  createLocationPositions: CreateLocationPositionsMutationResponse;
  deleteLocationPositions: DeleteInfo;
  updateLocationPositions: UpdateLocationPositionsMutationResponse;
  createLocationHoursOfOperations: CreateLocationHoursOfOperationsMutationResponse;
  deleteLocationHoursOfOperations: DeleteInfo;
  updateLocationHoursOfOperations: UpdateLocationHoursOfOperationsMutationResponse;
  createOrganizations: CreateOrganizationsMutationResponse;
  deleteOrganizations: DeleteInfo;
  updateOrganizations: UpdateOrganizationsMutationResponse;
  createOrganizationContacts: CreateOrganizationContactsMutationResponse;
  deleteOrganizationContacts: DeleteInfo;
  updateOrganizationContacts: UpdateOrganizationContactsMutationResponse;
  createPatients: CreatePatientsMutationResponse;
  deletePatients: DeleteInfo;
  updatePatients: UpdatePatientsMutationResponse;
  createPatientContacts: CreatePatientContactsMutationResponse;
  deletePatientContacts: DeleteInfo;
  updatePatientContacts: UpdatePatientContactsMutationResponse;
  createPatientCommunications: CreatePatientCommunicationsMutationResponse;
  deletePatientCommunications: DeleteInfo;
  updatePatientCommunications: UpdatePatientCommunicationsMutationResponse;
  createPatientLinks: CreatePatientLinksMutationResponse;
  deletePatientLinks: DeleteInfo;
  updatePatientLinks: UpdatePatientLinksMutationResponse;
  createPeople: CreatePeopleMutationResponse;
  deletePeople: DeleteInfo;
  updatePeople: UpdatePeopleMutationResponse;
  createPersonLinks: CreatePersonLinksMutationResponse;
  deletePersonLinks: DeleteInfo;
  updatePersonLinks: UpdatePersonLinksMutationResponse;
  createPractitioners: CreatePractitionersMutationResponse;
  deletePractitioners: DeleteInfo;
  updatePractitioners: UpdatePractitionersMutationResponse;
  createPractitionerQualifications: CreatePractitionerQualificationsMutationResponse;
  deletePractitionerQualifications: DeleteInfo;
  updatePractitionerQualifications: UpdatePractitionerQualificationsMutationResponse;
  createPractitionerRoles: CreatePractitionerRolesMutationResponse;
  deletePractitionerRoles: DeleteInfo;
  updatePractitionerRoles: UpdatePractitionerRolesMutationResponse;
  createPractitionerRoleAvailableTimes: CreatePractitionerRoleAvailableTimesMutationResponse;
  deletePractitionerRoleAvailableTimes: DeleteInfo;
  updatePractitionerRoleAvailableTimes: UpdatePractitionerRoleAvailableTimesMutationResponse;
  createPractitionerRoleNotAvailables: CreatePractitionerRoleNotAvailablesMutationResponse;
  deletePractitionerRoleNotAvailables: DeleteInfo;
  updatePractitionerRoleNotAvailables: UpdatePractitionerRoleNotAvailablesMutationResponse;
  createRelatedPeople: CreateRelatedPeopleMutationResponse;
  deleteRelatedPeople: DeleteInfo;
  updateRelatedPeople: UpdateRelatedPeopleMutationResponse;
  createRelatedPersonCommunications: CreateRelatedPersonCommunicationsMutationResponse;
  deleteRelatedPersonCommunications: DeleteInfo;
  updateRelatedPersonCommunications: UpdateRelatedPersonCommunicationsMutationResponse;
  createSchedules: CreateSchedulesMutationResponse;
  deleteSchedules: DeleteInfo;
  updateSchedules: UpdateSchedulesMutationResponse;
  createSlots: CreateSlotsMutationResponse;
  deleteSlots: DeleteInfo;
  updateSlots: UpdateSlotsMutationResponse;
  createSubstances: CreateSubstancesMutationResponse;
  deleteSubstances: DeleteInfo;
  updateSubstances: UpdateSubstancesMutationResponse;
  createSubstanceInstances: CreateSubstanceInstancesMutationResponse;
  deleteSubstanceInstances: DeleteInfo;
  updateSubstanceInstances: UpdateSubstanceInstancesMutationResponse;
  createSubstanceIngredients: CreateSubstanceIngredientsMutationResponse;
  deleteSubstanceIngredients: DeleteInfo;
  updateSubstanceIngredients: UpdateSubstanceIngredientsMutationResponse;
  createUsers: CreateUsersMutationResponse;
  deleteUsers: DeleteInfo;
  updateUsers: UpdateUsersMutationResponse;
  login: LoginResponse;
  resetPassword: Scalars['Boolean'];
  setNewPassword: Scalars['Boolean'];
  register: LoginResponse;
};

export type MutationCreateAccountsArgs = {
  input: Array<AccountCreateInput>;
};

export type MutationDeleteAccountsArgs = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type MutationUpdateAccountsArgs = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<AccountConnectInput>;
  disconnect?: Maybe<AccountDisconnectInput>;
  create?: Maybe<AccountRelationInput>;
  delete?: Maybe<AccountDeleteInput>;
};

export type MutationCreateMetasArgs = {
  input: Array<MetaCreateInput>;
};

export type MutationDeleteMetasArgs = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type MutationUpdateMetasArgs = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectInput>;
  disconnect?: Maybe<MetaDisconnectInput>;
  create?: Maybe<MetaRelationInput>;
  delete?: Maybe<MetaDeleteInput>;
};

export type MutationCreateExtensionsArgs = {
  input: Array<ExtensionCreateInput>;
};

export type MutationDeleteExtensionsArgs = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type MutationUpdateExtensionsArgs = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<ExtensionConnectInput>;
  disconnect?: Maybe<ExtensionDisconnectInput>;
  create?: Maybe<ExtensionRelationInput>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type MutationCreateElementsArgs = {
  input: Array<ElementCreateInput>;
};

export type MutationDeleteElementsArgs = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type MutationUpdateElementsArgs = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectInput>;
  disconnect?: Maybe<ElementDisconnectInput>;
  create?: Maybe<ElementRelationInput>;
  delete?: Maybe<ElementDeleteInput>;
};

export type MutationCreateAddressesArgs = {
  input: Array<AddressCreateInput>;
};

export type MutationDeleteAddressesArgs = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type MutationUpdateAddressesArgs = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<AddressConnectInput>;
  disconnect?: Maybe<AddressDisconnectInput>;
  create?: Maybe<AddressRelationInput>;
  delete?: Maybe<AddressDeleteInput>;
};

export type MutationCreatePeriodsArgs = {
  input: Array<PeriodCreateInput>;
};

export type MutationDeletePeriodsArgs = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type MutationUpdatePeriodsArgs = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectInput>;
  disconnect?: Maybe<PeriodDisconnectInput>;
  create?: Maybe<PeriodRelationInput>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type MutationCreateAgesArgs = {
  input: Array<AgeCreateInput>;
};

export type MutationDeleteAgesArgs = {
  where?: Maybe<AgeWhere>;
  delete?: Maybe<AgeDeleteInput>;
};

export type MutationUpdateAgesArgs = {
  where?: Maybe<AgeWhere>;
  update?: Maybe<AgeUpdateInput>;
  connect?: Maybe<AgeConnectInput>;
  disconnect?: Maybe<AgeDisconnectInput>;
  create?: Maybe<AgeRelationInput>;
  delete?: Maybe<AgeDeleteInput>;
};

export type MutationCreateAnnotationsArgs = {
  input: Array<AnnotationCreateInput>;
};

export type MutationDeleteAnnotationsArgs = {
  where?: Maybe<AnnotationWhere>;
  delete?: Maybe<AnnotationDeleteInput>;
};

export type MutationUpdateAnnotationsArgs = {
  where?: Maybe<AnnotationWhere>;
  update?: Maybe<AnnotationUpdateInput>;
  connect?: Maybe<AnnotationConnectInput>;
  disconnect?: Maybe<AnnotationDisconnectInput>;
  create?: Maybe<AnnotationRelationInput>;
  delete?: Maybe<AnnotationDeleteInput>;
};

export type MutationCreateReferencesArgs = {
  input: Array<ReferenceCreateInput>;
};

export type MutationDeleteReferencesArgs = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type MutationUpdateReferencesArgs = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectInput>;
  disconnect?: Maybe<ReferenceDisconnectInput>;
  create?: Maybe<ReferenceRelationInput>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type MutationCreateIdentifiersArgs = {
  input: Array<IdentifierCreateInput>;
};

export type MutationDeleteIdentifiersArgs = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type MutationUpdateIdentifiersArgs = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<IdentifierConnectInput>;
  disconnect?: Maybe<IdentifierDisconnectInput>;
  create?: Maybe<IdentifierRelationInput>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type MutationCreateCodeableConceptsArgs = {
  input: Array<CodeableConceptCreateInput>;
};

export type MutationDeleteCodeableConceptsArgs = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type MutationUpdateCodeableConceptsArgs = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectInput>;
  disconnect?: Maybe<CodeableConceptDisconnectInput>;
  create?: Maybe<CodeableConceptRelationInput>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type MutationCreateCodingsArgs = {
  input: Array<CodingCreateInput>;
};

export type MutationDeleteCodingsArgs = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type MutationUpdateCodingsArgs = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<CodingConnectInput>;
  disconnect?: Maybe<CodingDisconnectInput>;
  create?: Maybe<CodingRelationInput>;
  delete?: Maybe<CodingDeleteInput>;
};

export type MutationCreateAttachmentsArgs = {
  input: Array<AttachmentCreateInput>;
};

export type MutationDeleteAttachmentsArgs = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type MutationUpdateAttachmentsArgs = {
  where?: Maybe<AttachmentWhere>;
  update?: Maybe<AttachmentUpdateInput>;
  connect?: Maybe<AttachmentConnectInput>;
  disconnect?: Maybe<AttachmentDisconnectInput>;
  create?: Maybe<AttachmentRelationInput>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type MutationCreateContactPointsArgs = {
  input: Array<ContactPointCreateInput>;
};

export type MutationDeleteContactPointsArgs = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type MutationUpdateContactPointsArgs = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<ContactPointConnectInput>;
  disconnect?: Maybe<ContactPointDisconnectInput>;
  create?: Maybe<ContactPointRelationInput>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type MutationCreateCountsArgs = {
  input: Array<CountCreateInput>;
};

export type MutationDeleteCountsArgs = {
  where?: Maybe<CountWhere>;
  delete?: Maybe<CountDeleteInput>;
};

export type MutationUpdateCountsArgs = {
  where?: Maybe<CountWhere>;
  update?: Maybe<CountUpdateInput>;
  connect?: Maybe<CountConnectInput>;
  disconnect?: Maybe<CountDisconnectInput>;
  create?: Maybe<CountRelationInput>;
  delete?: Maybe<CountDeleteInput>;
};

export type MutationCreateDistancesArgs = {
  input: Array<DistanceCreateInput>;
};

export type MutationDeleteDistancesArgs = {
  where?: Maybe<DistanceWhere>;
  delete?: Maybe<DistanceDeleteInput>;
};

export type MutationUpdateDistancesArgs = {
  where?: Maybe<DistanceWhere>;
  update?: Maybe<DistanceUpdateInput>;
  connect?: Maybe<DistanceConnectInput>;
  disconnect?: Maybe<DistanceDisconnectInput>;
  create?: Maybe<DistanceRelationInput>;
  delete?: Maybe<DistanceDeleteInput>;
};

export type MutationCreateDurationsArgs = {
  input: Array<DurationCreateInput>;
};

export type MutationDeleteDurationsArgs = {
  where?: Maybe<DurationWhere>;
  delete?: Maybe<DurationDeleteInput>;
};

export type MutationUpdateDurationsArgs = {
  where?: Maybe<DurationWhere>;
  update?: Maybe<DurationUpdateInput>;
  connect?: Maybe<DurationConnectInput>;
  disconnect?: Maybe<DurationDisconnectInput>;
  create?: Maybe<DurationRelationInput>;
  delete?: Maybe<DurationDeleteInput>;
};

export type MutationCreateHumanNamesArgs = {
  input: Array<HumanNameCreateInput>;
};

export type MutationDeleteHumanNamesArgs = {
  where?: Maybe<HumanNameWhere>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type MutationUpdateHumanNamesArgs = {
  where?: Maybe<HumanNameWhere>;
  update?: Maybe<HumanNameUpdateInput>;
  connect?: Maybe<HumanNameConnectInput>;
  disconnect?: Maybe<HumanNameDisconnectInput>;
  create?: Maybe<HumanNameRelationInput>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type MutationCreateMoniesArgs = {
  input: Array<MoneyCreateInput>;
};

export type MutationDeleteMoniesArgs = {
  where?: Maybe<MoneyWhere>;
  delete?: Maybe<MoneyDeleteInput>;
};

export type MutationUpdateMoniesArgs = {
  where?: Maybe<MoneyWhere>;
  update?: Maybe<MoneyUpdateInput>;
  connect?: Maybe<MoneyConnectInput>;
  disconnect?: Maybe<MoneyDisconnectInput>;
  create?: Maybe<MoneyRelationInput>;
  delete?: Maybe<MoneyDeleteInput>;
};

export type MutationCreateQuantitiesArgs = {
  input: Array<QuantityCreateInput>;
};

export type MutationDeleteQuantitiesArgs = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type MutationUpdateQuantitiesArgs = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectInput>;
  disconnect?: Maybe<QuantityDisconnectInput>;
  create?: Maybe<QuantityRelationInput>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type MutationCreateRangesArgs = {
  input: Array<RangeCreateInput>;
};

export type MutationDeleteRangesArgs = {
  where?: Maybe<RangeWhere>;
  delete?: Maybe<RangeDeleteInput>;
};

export type MutationUpdateRangesArgs = {
  where?: Maybe<RangeWhere>;
  update?: Maybe<RangeUpdateInput>;
  connect?: Maybe<RangeConnectInput>;
  disconnect?: Maybe<RangeDisconnectInput>;
  create?: Maybe<RangeRelationInput>;
  delete?: Maybe<RangeDeleteInput>;
};

export type MutationCreateRatiosArgs = {
  input: Array<RatioCreateInput>;
};

export type MutationDeleteRatiosArgs = {
  where?: Maybe<RatioWhere>;
  delete?: Maybe<RatioDeleteInput>;
};

export type MutationUpdateRatiosArgs = {
  where?: Maybe<RatioWhere>;
  update?: Maybe<RatioUpdateInput>;
  connect?: Maybe<RatioConnectInput>;
  disconnect?: Maybe<RatioDisconnectInput>;
  create?: Maybe<RatioRelationInput>;
  delete?: Maybe<RatioDeleteInput>;
};

export type MutationCreateSampledDataArgs = {
  input: Array<SampledDataCreateInput>;
};

export type MutationDeleteSampledDataArgs = {
  where?: Maybe<SampledDataWhere>;
  delete?: Maybe<SampledDataDeleteInput>;
};

export type MutationUpdateSampledDataArgs = {
  where?: Maybe<SampledDataWhere>;
  update?: Maybe<SampledDataUpdateInput>;
  connect?: Maybe<SampledDataConnectInput>;
  disconnect?: Maybe<SampledDataDisconnectInput>;
  create?: Maybe<SampledDataRelationInput>;
  delete?: Maybe<SampledDataDeleteInput>;
};

export type MutationCreateSignaturesArgs = {
  input: Array<SignatureCreateInput>;
};

export type MutationDeleteSignaturesArgs = {
  where?: Maybe<SignatureWhere>;
  delete?: Maybe<SignatureDeleteInput>;
};

export type MutationUpdateSignaturesArgs = {
  where?: Maybe<SignatureWhere>;
  update?: Maybe<SignatureUpdateInput>;
  connect?: Maybe<SignatureConnectInput>;
  disconnect?: Maybe<SignatureDisconnectInput>;
  create?: Maybe<SignatureRelationInput>;
  delete?: Maybe<SignatureDeleteInput>;
};

export type MutationCreateTimingsArgs = {
  input: Array<TimingCreateInput>;
};

export type MutationDeleteTimingsArgs = {
  where?: Maybe<TimingWhere>;
  delete?: Maybe<TimingDeleteInput>;
};

export type MutationUpdateTimingsArgs = {
  where?: Maybe<TimingWhere>;
  update?: Maybe<TimingUpdateInput>;
  connect?: Maybe<TimingConnectInput>;
  disconnect?: Maybe<TimingDisconnectInput>;
  create?: Maybe<TimingRelationInput>;
  delete?: Maybe<TimingDeleteInput>;
};

export type MutationCreateTimingRepeatsArgs = {
  input: Array<TimingRepeatCreateInput>;
};

export type MutationDeleteTimingRepeatsArgs = {
  where?: Maybe<TimingRepeatWhere>;
  delete?: Maybe<TimingRepeatDeleteInput>;
};

export type MutationUpdateTimingRepeatsArgs = {
  where?: Maybe<TimingRepeatWhere>;
  update?: Maybe<TimingRepeatUpdateInput>;
  connect?: Maybe<TimingRepeatConnectInput>;
  disconnect?: Maybe<TimingRepeatDisconnectInput>;
  create?: Maybe<TimingRepeatRelationInput>;
  delete?: Maybe<TimingRepeatDeleteInput>;
};

export type MutationCreateContactDetailsArgs = {
  input: Array<ContactDetailCreateInput>;
};

export type MutationDeleteContactDetailsArgs = {
  where?: Maybe<ContactDetailWhere>;
  delete?: Maybe<ContactDetailDeleteInput>;
};

export type MutationUpdateContactDetailsArgs = {
  where?: Maybe<ContactDetailWhere>;
  update?: Maybe<ContactDetailUpdateInput>;
  connect?: Maybe<ContactDetailConnectInput>;
  disconnect?: Maybe<ContactDetailDisconnectInput>;
  create?: Maybe<ContactDetailRelationInput>;
  delete?: Maybe<ContactDetailDeleteInput>;
};

export type MutationCreateContributorsArgs = {
  input: Array<ContributorCreateInput>;
};

export type MutationDeleteContributorsArgs = {
  where?: Maybe<ContributorWhere>;
  delete?: Maybe<ContributorDeleteInput>;
};

export type MutationUpdateContributorsArgs = {
  where?: Maybe<ContributorWhere>;
  update?: Maybe<ContributorUpdateInput>;
  connect?: Maybe<ContributorConnectInput>;
  disconnect?: Maybe<ContributorDisconnectInput>;
  create?: Maybe<ContributorRelationInput>;
  delete?: Maybe<ContributorDeleteInput>;
};

export type MutationCreateDataRequirementsArgs = {
  input: Array<DataRequirementCreateInput>;
};

export type MutationDeleteDataRequirementsArgs = {
  where?: Maybe<DataRequirementWhere>;
  delete?: Maybe<DataRequirementDeleteInput>;
};

export type MutationUpdateDataRequirementsArgs = {
  where?: Maybe<DataRequirementWhere>;
  update?: Maybe<DataRequirementUpdateInput>;
  connect?: Maybe<DataRequirementConnectInput>;
  disconnect?: Maybe<DataRequirementDisconnectInput>;
  create?: Maybe<DataRequirementRelationInput>;
  delete?: Maybe<DataRequirementDeleteInput>;
};

export type MutationCreateDataRequirementCodeFiltersArgs = {
  input: Array<DataRequirementCodeFilterCreateInput>;
};

export type MutationDeleteDataRequirementCodeFiltersArgs = {
  where?: Maybe<DataRequirementCodeFilterWhere>;
  delete?: Maybe<DataRequirementCodeFilterDeleteInput>;
};

export type MutationUpdateDataRequirementCodeFiltersArgs = {
  where?: Maybe<DataRequirementCodeFilterWhere>;
  update?: Maybe<DataRequirementCodeFilterUpdateInput>;
  connect?: Maybe<DataRequirementCodeFilterConnectInput>;
  disconnect?: Maybe<DataRequirementCodeFilterDisconnectInput>;
  create?: Maybe<DataRequirementCodeFilterRelationInput>;
  delete?: Maybe<DataRequirementCodeFilterDeleteInput>;
};

export type MutationCreateDataRequirementDateFiltersArgs = {
  input: Array<DataRequirementDateFilterCreateInput>;
};

export type MutationDeleteDataRequirementDateFiltersArgs = {
  where?: Maybe<DataRequirementDateFilterWhere>;
  delete?: Maybe<DataRequirementDateFilterDeleteInput>;
};

export type MutationUpdateDataRequirementDateFiltersArgs = {
  where?: Maybe<DataRequirementDateFilterWhere>;
  update?: Maybe<DataRequirementDateFilterUpdateInput>;
  connect?: Maybe<DataRequirementDateFilterConnectInput>;
  disconnect?: Maybe<DataRequirementDateFilterDisconnectInput>;
  create?: Maybe<DataRequirementDateFilterRelationInput>;
  delete?: Maybe<DataRequirementDateFilterDeleteInput>;
};

export type MutationCreateDataRequirementCustomSortsArgs = {
  input: Array<DataRequirementCustomSortCreateInput>;
};

export type MutationDeleteDataRequirementCustomSortsArgs = {
  where?: Maybe<DataRequirementCustomSortWhere>;
  delete?: Maybe<DataRequirementCustomSortDeleteInput>;
};

export type MutationUpdateDataRequirementCustomSortsArgs = {
  where?: Maybe<DataRequirementCustomSortWhere>;
  update?: Maybe<DataRequirementCustomSortUpdateInput>;
  connect?: Maybe<DataRequirementCustomSortConnectInput>;
  disconnect?: Maybe<DataRequirementCustomSortDisconnectInput>;
  create?: Maybe<DataRequirementCustomSortRelationInput>;
  delete?: Maybe<DataRequirementCustomSortDeleteInput>;
};

export type MutationCreateExpressionsArgs = {
  input: Array<ExpressionCreateInput>;
};

export type MutationDeleteExpressionsArgs = {
  where?: Maybe<ExpressionWhere>;
  delete?: Maybe<ExpressionDeleteInput>;
};

export type MutationUpdateExpressionsArgs = {
  where?: Maybe<ExpressionWhere>;
  update?: Maybe<ExpressionUpdateInput>;
  connect?: Maybe<ExpressionConnectInput>;
  disconnect?: Maybe<ExpressionDisconnectInput>;
  create?: Maybe<ExpressionRelationInput>;
  delete?: Maybe<ExpressionDeleteInput>;
};

export type MutationCreateParameterDefinitionsArgs = {
  input: Array<ParameterDefinitionCreateInput>;
};

export type MutationDeleteParameterDefinitionsArgs = {
  where?: Maybe<ParameterDefinitionWhere>;
  delete?: Maybe<ParameterDefinitionDeleteInput>;
};

export type MutationUpdateParameterDefinitionsArgs = {
  where?: Maybe<ParameterDefinitionWhere>;
  update?: Maybe<ParameterDefinitionUpdateInput>;
  connect?: Maybe<ParameterDefinitionConnectInput>;
  disconnect?: Maybe<ParameterDefinitionDisconnectInput>;
  create?: Maybe<ParameterDefinitionRelationInput>;
  delete?: Maybe<ParameterDefinitionDeleteInput>;
};

export type MutationCreateRelatedArtifactsArgs = {
  input: Array<RelatedArtifactCreateInput>;
};

export type MutationDeleteRelatedArtifactsArgs = {
  where?: Maybe<RelatedArtifactWhere>;
  delete?: Maybe<RelatedArtifactDeleteInput>;
};

export type MutationUpdateRelatedArtifactsArgs = {
  where?: Maybe<RelatedArtifactWhere>;
  update?: Maybe<RelatedArtifactUpdateInput>;
  connect?: Maybe<RelatedArtifactConnectInput>;
  disconnect?: Maybe<RelatedArtifactDisconnectInput>;
  create?: Maybe<RelatedArtifactRelationInput>;
  delete?: Maybe<RelatedArtifactDeleteInput>;
};

export type MutationCreateTriggerDefinitionsArgs = {
  input: Array<TriggerDefinitionCreateInput>;
};

export type MutationDeleteTriggerDefinitionsArgs = {
  where?: Maybe<TriggerDefinitionWhere>;
  delete?: Maybe<TriggerDefinitionDeleteInput>;
};

export type MutationUpdateTriggerDefinitionsArgs = {
  where?: Maybe<TriggerDefinitionWhere>;
  update?: Maybe<TriggerDefinitionUpdateInput>;
  connect?: Maybe<TriggerDefinitionConnectInput>;
  disconnect?: Maybe<TriggerDefinitionDisconnectInput>;
  create?: Maybe<TriggerDefinitionRelationInput>;
  delete?: Maybe<TriggerDefinitionDeleteInput>;
};

export type MutationCreateUsageContextsArgs = {
  input: Array<UsageContextCreateInput>;
};

export type MutationDeleteUsageContextsArgs = {
  where?: Maybe<UsageContextWhere>;
  delete?: Maybe<UsageContextDeleteInput>;
};

export type MutationUpdateUsageContextsArgs = {
  where?: Maybe<UsageContextWhere>;
  update?: Maybe<UsageContextUpdateInput>;
  connect?: Maybe<UsageContextConnectInput>;
  disconnect?: Maybe<UsageContextDisconnectInput>;
  create?: Maybe<UsageContextRelationInput>;
  delete?: Maybe<UsageContextDeleteInput>;
};

export type MutationCreateDosagesArgs = {
  input: Array<DosageCreateInput>;
};

export type MutationDeleteDosagesArgs = {
  where?: Maybe<DosageWhere>;
  delete?: Maybe<DosageDeleteInput>;
};

export type MutationUpdateDosagesArgs = {
  where?: Maybe<DosageWhere>;
  update?: Maybe<DosageUpdateInput>;
  connect?: Maybe<DosageConnectInput>;
  disconnect?: Maybe<DosageDisconnectInput>;
  create?: Maybe<DosageRelationInput>;
  delete?: Maybe<DosageDeleteInput>;
};

export type MutationCreateDosageDoseAndRatesArgs = {
  input: Array<DosageDoseAndRateCreateInput>;
};

export type MutationDeleteDosageDoseAndRatesArgs = {
  where?: Maybe<DosageDoseAndRateWhere>;
  delete?: Maybe<DosageDoseAndRateDeleteInput>;
};

export type MutationUpdateDosageDoseAndRatesArgs = {
  where?: Maybe<DosageDoseAndRateWhere>;
  update?: Maybe<DosageDoseAndRateUpdateInput>;
  connect?: Maybe<DosageDoseAndRateConnectInput>;
  disconnect?: Maybe<DosageDoseAndRateDisconnectInput>;
  create?: Maybe<DosageDoseAndRateRelationInput>;
  delete?: Maybe<DosageDoseAndRateDeleteInput>;
};

export type MutationCreateNarrativesArgs = {
  input: Array<NarrativeCreateInput>;
};

export type MutationDeleteNarrativesArgs = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type MutationUpdateNarrativesArgs = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectInput>;
  disconnect?: Maybe<NarrativeDisconnectInput>;
  create?: Maybe<NarrativeRelationInput>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type MutationCreateAccountCoveragesArgs = {
  input: Array<AccountCoverageCreateInput>;
};

export type MutationDeleteAccountCoveragesArgs = {
  where?: Maybe<AccountCoverageWhere>;
  delete?: Maybe<AccountCoverageDeleteInput>;
};

export type MutationUpdateAccountCoveragesArgs = {
  where?: Maybe<AccountCoverageWhere>;
  update?: Maybe<AccountCoverageUpdateInput>;
  connect?: Maybe<AccountCoverageConnectInput>;
  disconnect?: Maybe<AccountCoverageDisconnectInput>;
  create?: Maybe<AccountCoverageRelationInput>;
  delete?: Maybe<AccountCoverageDeleteInput>;
};

export type MutationCreateAccountGuarantorsArgs = {
  input: Array<AccountGuarantorCreateInput>;
};

export type MutationDeleteAccountGuarantorsArgs = {
  where?: Maybe<AccountGuarantorWhere>;
  delete?: Maybe<AccountGuarantorDeleteInput>;
};

export type MutationUpdateAccountGuarantorsArgs = {
  where?: Maybe<AccountGuarantorWhere>;
  update?: Maybe<AccountGuarantorUpdateInput>;
  connect?: Maybe<AccountGuarantorConnectInput>;
  disconnect?: Maybe<AccountGuarantorDisconnectInput>;
  create?: Maybe<AccountGuarantorRelationInput>;
  delete?: Maybe<AccountGuarantorDeleteInput>;
};

export type MutationCreateAppointmentsArgs = {
  input: Array<AppointmentCreateInput>;
};

export type MutationDeleteAppointmentsArgs = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type MutationUpdateAppointmentsArgs = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<AppointmentConnectInput>;
  disconnect?: Maybe<AppointmentDisconnectInput>;
  create?: Maybe<AppointmentRelationInput>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type MutationCreateAppointmentParticipantsArgs = {
  input: Array<AppointmentParticipantCreateInput>;
};

export type MutationDeleteAppointmentParticipantsArgs = {
  where?: Maybe<AppointmentParticipantWhere>;
  delete?: Maybe<AppointmentParticipantDeleteInput>;
};

export type MutationUpdateAppointmentParticipantsArgs = {
  where?: Maybe<AppointmentParticipantWhere>;
  update?: Maybe<AppointmentParticipantUpdateInput>;
  connect?: Maybe<AppointmentParticipantConnectInput>;
  disconnect?: Maybe<AppointmentParticipantDisconnectInput>;
  create?: Maybe<AppointmentParticipantRelationInput>;
  delete?: Maybe<AppointmentParticipantDeleteInput>;
};

export type MutationCreateAppointmentResponsesArgs = {
  input: Array<AppointmentResponseCreateInput>;
};

export type MutationDeleteAppointmentResponsesArgs = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type MutationUpdateAppointmentResponsesArgs = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<AppointmentResponseConnectInput>;
  disconnect?: Maybe<AppointmentResponseDisconnectInput>;
  create?: Maybe<AppointmentResponseRelationInput>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type MutationCreateCoveragesArgs = {
  input: Array<CoverageCreateInput>;
};

export type MutationDeleteCoveragesArgs = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type MutationUpdateCoveragesArgs = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<CoverageConnectInput>;
  disconnect?: Maybe<CoverageDisconnectInput>;
  create?: Maybe<CoverageRelationInput>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type MutationCreateCoverageClassesArgs = {
  input: Array<CoverageClassCreateInput>;
};

export type MutationDeleteCoverageClassesArgs = {
  where?: Maybe<CoverageClassWhere>;
  delete?: Maybe<CoverageClassDeleteInput>;
};

export type MutationUpdateCoverageClassesArgs = {
  where?: Maybe<CoverageClassWhere>;
  update?: Maybe<CoverageClassUpdateInput>;
  connect?: Maybe<CoverageClassConnectInput>;
  disconnect?: Maybe<CoverageClassDisconnectInput>;
  create?: Maybe<CoverageClassRelationInput>;
  delete?: Maybe<CoverageClassDeleteInput>;
};

export type MutationCreateCoverageCostToBeneficiariesArgs = {
  input: Array<CoverageCostToBeneficiaryCreateInput>;
};

export type MutationDeleteCoverageCostToBeneficiariesArgs = {
  where?: Maybe<CoverageCostToBeneficiaryWhere>;
  delete?: Maybe<CoverageCostToBeneficiaryDeleteInput>;
};

export type MutationUpdateCoverageCostToBeneficiariesArgs = {
  where?: Maybe<CoverageCostToBeneficiaryWhere>;
  update?: Maybe<CoverageCostToBeneficiaryUpdateInput>;
  connect?: Maybe<CoverageCostToBeneficiaryConnectInput>;
  disconnect?: Maybe<CoverageCostToBeneficiaryDisconnectInput>;
  create?: Maybe<CoverageCostToBeneficiaryRelationInput>;
  delete?: Maybe<CoverageCostToBeneficiaryDeleteInput>;
};

export type MutationCreateCoverageExceptionsArgs = {
  input: Array<CoverageExceptionCreateInput>;
};

export type MutationDeleteCoverageExceptionsArgs = {
  where?: Maybe<CoverageExceptionWhere>;
  delete?: Maybe<CoverageExceptionDeleteInput>;
};

export type MutationUpdateCoverageExceptionsArgs = {
  where?: Maybe<CoverageExceptionWhere>;
  update?: Maybe<CoverageExceptionUpdateInput>;
  connect?: Maybe<CoverageExceptionConnectInput>;
  disconnect?: Maybe<CoverageExceptionDisconnectInput>;
  create?: Maybe<CoverageExceptionRelationInput>;
  delete?: Maybe<CoverageExceptionDeleteInput>;
};

export type MutationCreateDevicesArgs = {
  input: Array<DeviceCreateInput>;
};

export type MutationDeleteDevicesArgs = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type MutationUpdateDevicesArgs = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<DeviceConnectInput>;
  disconnect?: Maybe<DeviceDisconnectInput>;
  create?: Maybe<DeviceRelationInput>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type MutationCreateDeviceUdiCarriersArgs = {
  input: Array<DeviceUdiCarrierCreateInput>;
};

export type MutationDeleteDeviceUdiCarriersArgs = {
  where?: Maybe<DeviceUdiCarrierWhere>;
  delete?: Maybe<DeviceUdiCarrierDeleteInput>;
};

export type MutationUpdateDeviceUdiCarriersArgs = {
  where?: Maybe<DeviceUdiCarrierWhere>;
  update?: Maybe<DeviceUdiCarrierUpdateInput>;
  connect?: Maybe<DeviceUdiCarrierConnectInput>;
  disconnect?: Maybe<DeviceUdiCarrierDisconnectInput>;
  create?: Maybe<DeviceUdiCarrierRelationInput>;
  delete?: Maybe<DeviceUdiCarrierDeleteInput>;
};

export type MutationCreateDeviceDeviceNamesArgs = {
  input: Array<DeviceDeviceNameCreateInput>;
};

export type MutationDeleteDeviceDeviceNamesArgs = {
  where?: Maybe<DeviceDeviceNameWhere>;
  delete?: Maybe<DeviceDeviceNameDeleteInput>;
};

export type MutationUpdateDeviceDeviceNamesArgs = {
  where?: Maybe<DeviceDeviceNameWhere>;
  update?: Maybe<DeviceDeviceNameUpdateInput>;
  connect?: Maybe<DeviceDeviceNameConnectInput>;
  disconnect?: Maybe<DeviceDeviceNameDisconnectInput>;
  create?: Maybe<DeviceDeviceNameRelationInput>;
  delete?: Maybe<DeviceDeviceNameDeleteInput>;
};

export type MutationCreateDeviceSpecializationsArgs = {
  input: Array<DeviceSpecializationCreateInput>;
};

export type MutationDeleteDeviceSpecializationsArgs = {
  where?: Maybe<DeviceSpecializationWhere>;
  delete?: Maybe<DeviceSpecializationDeleteInput>;
};

export type MutationUpdateDeviceSpecializationsArgs = {
  where?: Maybe<DeviceSpecializationWhere>;
  update?: Maybe<DeviceSpecializationUpdateInput>;
  connect?: Maybe<DeviceSpecializationConnectInput>;
  disconnect?: Maybe<DeviceSpecializationDisconnectInput>;
  create?: Maybe<DeviceSpecializationRelationInput>;
  delete?: Maybe<DeviceSpecializationDeleteInput>;
};

export type MutationCreateDeviceVersionsArgs = {
  input: Array<DeviceVersionCreateInput>;
};

export type MutationDeleteDeviceVersionsArgs = {
  where?: Maybe<DeviceVersionWhere>;
  delete?: Maybe<DeviceVersionDeleteInput>;
};

export type MutationUpdateDeviceVersionsArgs = {
  where?: Maybe<DeviceVersionWhere>;
  update?: Maybe<DeviceVersionUpdateInput>;
  connect?: Maybe<DeviceVersionConnectInput>;
  disconnect?: Maybe<DeviceVersionDisconnectInput>;
  create?: Maybe<DeviceVersionRelationInput>;
  delete?: Maybe<DeviceVersionDeleteInput>;
};

export type MutationCreateDevicePropertiesArgs = {
  input: Array<DevicePropertyCreateInput>;
};

export type MutationDeleteDevicePropertiesArgs = {
  where?: Maybe<DevicePropertyWhere>;
  delete?: Maybe<DevicePropertyDeleteInput>;
};

export type MutationUpdateDevicePropertiesArgs = {
  where?: Maybe<DevicePropertyWhere>;
  update?: Maybe<DevicePropertyUpdateInput>;
  connect?: Maybe<DevicePropertyConnectInput>;
  disconnect?: Maybe<DevicePropertyDisconnectInput>;
  create?: Maybe<DevicePropertyRelationInput>;
  delete?: Maybe<DevicePropertyDeleteInput>;
};

export type MutationCreateDeviceDefinitionsArgs = {
  input: Array<DeviceDefinitionCreateInput>;
};

export type MutationDeleteDeviceDefinitionsArgs = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type MutationUpdateDeviceDefinitionsArgs = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<DeviceDefinitionConnectInput>;
  disconnect?: Maybe<DeviceDefinitionDisconnectInput>;
  create?: Maybe<DeviceDefinitionRelationInput>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type MutationCreateDeviceDefinitionUdiDeviceIdentifiersArgs = {
  input: Array<DeviceDefinitionUdiDeviceIdentifierCreateInput>;
};

export type MutationDeleteDeviceDefinitionUdiDeviceIdentifiersArgs = {
  where?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  delete?: Maybe<DeviceDefinitionUdiDeviceIdentifierDeleteInput>;
};

export type MutationUpdateDeviceDefinitionUdiDeviceIdentifiersArgs = {
  where?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  update?: Maybe<DeviceDefinitionUdiDeviceIdentifierUpdateInput>;
  connect?: Maybe<DeviceDefinitionUdiDeviceIdentifierConnectInput>;
  disconnect?: Maybe<DeviceDefinitionUdiDeviceIdentifierDisconnectInput>;
  create?: Maybe<DeviceDefinitionUdiDeviceIdentifierRelationInput>;
  delete?: Maybe<DeviceDefinitionUdiDeviceIdentifierDeleteInput>;
};

export type MutationCreateDeviceDefinitionDeviceNamesArgs = {
  input: Array<DeviceDefinitionDeviceNameCreateInput>;
};

export type MutationDeleteDeviceDefinitionDeviceNamesArgs = {
  where?: Maybe<DeviceDefinitionDeviceNameWhere>;
  delete?: Maybe<DeviceDefinitionDeviceNameDeleteInput>;
};

export type MutationUpdateDeviceDefinitionDeviceNamesArgs = {
  where?: Maybe<DeviceDefinitionDeviceNameWhere>;
  update?: Maybe<DeviceDefinitionDeviceNameUpdateInput>;
  connect?: Maybe<DeviceDefinitionDeviceNameConnectInput>;
  disconnect?: Maybe<DeviceDefinitionDeviceNameDisconnectInput>;
  create?: Maybe<DeviceDefinitionDeviceNameRelationInput>;
  delete?: Maybe<DeviceDefinitionDeviceNameDeleteInput>;
};

export type MutationCreateDeviceDefinitionSpecializationsArgs = {
  input: Array<DeviceDefinitionSpecializationCreateInput>;
};

export type MutationDeleteDeviceDefinitionSpecializationsArgs = {
  where?: Maybe<DeviceDefinitionSpecializationWhere>;
  delete?: Maybe<DeviceDefinitionSpecializationDeleteInput>;
};

export type MutationUpdateDeviceDefinitionSpecializationsArgs = {
  where?: Maybe<DeviceDefinitionSpecializationWhere>;
  update?: Maybe<DeviceDefinitionSpecializationUpdateInput>;
  connect?: Maybe<DeviceDefinitionSpecializationConnectInput>;
  disconnect?: Maybe<DeviceDefinitionSpecializationDisconnectInput>;
  create?: Maybe<DeviceDefinitionSpecializationRelationInput>;
  delete?: Maybe<DeviceDefinitionSpecializationDeleteInput>;
};

export type MutationCreateProductShelfLivesArgs = {
  input: Array<ProductShelfLifeCreateInput>;
};

export type MutationDeleteProductShelfLivesArgs = {
  where?: Maybe<ProductShelfLifeWhere>;
  delete?: Maybe<ProductShelfLifeDeleteInput>;
};

export type MutationUpdateProductShelfLivesArgs = {
  where?: Maybe<ProductShelfLifeWhere>;
  update?: Maybe<ProductShelfLifeUpdateInput>;
  connect?: Maybe<ProductShelfLifeConnectInput>;
  disconnect?: Maybe<ProductShelfLifeDisconnectInput>;
  create?: Maybe<ProductShelfLifeRelationInput>;
  delete?: Maybe<ProductShelfLifeDeleteInput>;
};

export type MutationCreateProdCharacteristicsArgs = {
  input: Array<ProdCharacteristicCreateInput>;
};

export type MutationDeleteProdCharacteristicsArgs = {
  where?: Maybe<ProdCharacteristicWhere>;
  delete?: Maybe<ProdCharacteristicDeleteInput>;
};

export type MutationUpdateProdCharacteristicsArgs = {
  where?: Maybe<ProdCharacteristicWhere>;
  update?: Maybe<ProdCharacteristicUpdateInput>;
  connect?: Maybe<ProdCharacteristicConnectInput>;
  disconnect?: Maybe<ProdCharacteristicDisconnectInput>;
  create?: Maybe<ProdCharacteristicRelationInput>;
  delete?: Maybe<ProdCharacteristicDeleteInput>;
};

export type MutationCreateDeviceDefinitionCapabilitiesArgs = {
  input: Array<DeviceDefinitionCapabilityCreateInput>;
};

export type MutationDeleteDeviceDefinitionCapabilitiesArgs = {
  where?: Maybe<DeviceDefinitionCapabilityWhere>;
  delete?: Maybe<DeviceDefinitionCapabilityDeleteInput>;
};

export type MutationUpdateDeviceDefinitionCapabilitiesArgs = {
  where?: Maybe<DeviceDefinitionCapabilityWhere>;
  update?: Maybe<DeviceDefinitionCapabilityUpdateInput>;
  connect?: Maybe<DeviceDefinitionCapabilityConnectInput>;
  disconnect?: Maybe<DeviceDefinitionCapabilityDisconnectInput>;
  create?: Maybe<DeviceDefinitionCapabilityRelationInput>;
  delete?: Maybe<DeviceDefinitionCapabilityDeleteInput>;
};

export type MutationCreateDeviceDefinitionPropertiesArgs = {
  input: Array<DeviceDefinitionPropertyCreateInput>;
};

export type MutationDeleteDeviceDefinitionPropertiesArgs = {
  where?: Maybe<DeviceDefinitionPropertyWhere>;
  delete?: Maybe<DeviceDefinitionPropertyDeleteInput>;
};

export type MutationUpdateDeviceDefinitionPropertiesArgs = {
  where?: Maybe<DeviceDefinitionPropertyWhere>;
  update?: Maybe<DeviceDefinitionPropertyUpdateInput>;
  connect?: Maybe<DeviceDefinitionPropertyConnectInput>;
  disconnect?: Maybe<DeviceDefinitionPropertyDisconnectInput>;
  create?: Maybe<DeviceDefinitionPropertyRelationInput>;
  delete?: Maybe<DeviceDefinitionPropertyDeleteInput>;
};

export type MutationCreateDeviceDefinitionMaterialsArgs = {
  input: Array<DeviceDefinitionMaterialCreateInput>;
};

export type MutationDeleteDeviceDefinitionMaterialsArgs = {
  where?: Maybe<DeviceDefinitionMaterialWhere>;
  delete?: Maybe<DeviceDefinitionMaterialDeleteInput>;
};

export type MutationUpdateDeviceDefinitionMaterialsArgs = {
  where?: Maybe<DeviceDefinitionMaterialWhere>;
  update?: Maybe<DeviceDefinitionMaterialUpdateInput>;
  connect?: Maybe<DeviceDefinitionMaterialConnectInput>;
  disconnect?: Maybe<DeviceDefinitionMaterialDisconnectInput>;
  create?: Maybe<DeviceDefinitionMaterialRelationInput>;
  delete?: Maybe<DeviceDefinitionMaterialDeleteInput>;
};

export type MutationCreateDeviceMetricsArgs = {
  input: Array<DeviceMetricCreateInput>;
};

export type MutationDeleteDeviceMetricsArgs = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type MutationUpdateDeviceMetricsArgs = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<DeviceMetricConnectInput>;
  disconnect?: Maybe<DeviceMetricDisconnectInput>;
  create?: Maybe<DeviceMetricRelationInput>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type MutationCreateDeviceMetricCalibrationsArgs = {
  input: Array<DeviceMetricCalibrationCreateInput>;
};

export type MutationDeleteDeviceMetricCalibrationsArgs = {
  where?: Maybe<DeviceMetricCalibrationWhere>;
  delete?: Maybe<DeviceMetricCalibrationDeleteInput>;
};

export type MutationUpdateDeviceMetricCalibrationsArgs = {
  where?: Maybe<DeviceMetricCalibrationWhere>;
  update?: Maybe<DeviceMetricCalibrationUpdateInput>;
  connect?: Maybe<DeviceMetricCalibrationConnectInput>;
  disconnect?: Maybe<DeviceMetricCalibrationDisconnectInput>;
  create?: Maybe<DeviceMetricCalibrationRelationInput>;
  delete?: Maybe<DeviceMetricCalibrationDeleteInput>;
};

export type MutationCreateEncountersArgs = {
  input: Array<EncounterCreateInput>;
};

export type MutationDeleteEncountersArgs = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type MutationUpdateEncountersArgs = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<EncounterConnectInput>;
  disconnect?: Maybe<EncounterDisconnectInput>;
  create?: Maybe<EncounterRelationInput>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type MutationCreateEncounterStatusHistoriesArgs = {
  input: Array<EncounterStatusHistoryCreateInput>;
};

export type MutationDeleteEncounterStatusHistoriesArgs = {
  where?: Maybe<EncounterStatusHistoryWhere>;
  delete?: Maybe<EncounterStatusHistoryDeleteInput>;
};

export type MutationUpdateEncounterStatusHistoriesArgs = {
  where?: Maybe<EncounterStatusHistoryWhere>;
  update?: Maybe<EncounterStatusHistoryUpdateInput>;
  connect?: Maybe<EncounterStatusHistoryConnectInput>;
  disconnect?: Maybe<EncounterStatusHistoryDisconnectInput>;
  create?: Maybe<EncounterStatusHistoryRelationInput>;
  delete?: Maybe<EncounterStatusHistoryDeleteInput>;
};

export type MutationCreateEncounterClassHistoriesArgs = {
  input: Array<EncounterClassHistoryCreateInput>;
};

export type MutationDeleteEncounterClassHistoriesArgs = {
  where?: Maybe<EncounterClassHistoryWhere>;
  delete?: Maybe<EncounterClassHistoryDeleteInput>;
};

export type MutationUpdateEncounterClassHistoriesArgs = {
  where?: Maybe<EncounterClassHistoryWhere>;
  update?: Maybe<EncounterClassHistoryUpdateInput>;
  connect?: Maybe<EncounterClassHistoryConnectInput>;
  disconnect?: Maybe<EncounterClassHistoryDisconnectInput>;
  create?: Maybe<EncounterClassHistoryRelationInput>;
  delete?: Maybe<EncounterClassHistoryDeleteInput>;
};

export type MutationCreateEncounterParticipantsArgs = {
  input: Array<EncounterParticipantCreateInput>;
};

export type MutationDeleteEncounterParticipantsArgs = {
  where?: Maybe<EncounterParticipantWhere>;
  delete?: Maybe<EncounterParticipantDeleteInput>;
};

export type MutationUpdateEncounterParticipantsArgs = {
  where?: Maybe<EncounterParticipantWhere>;
  update?: Maybe<EncounterParticipantUpdateInput>;
  connect?: Maybe<EncounterParticipantConnectInput>;
  disconnect?: Maybe<EncounterParticipantDisconnectInput>;
  create?: Maybe<EncounterParticipantRelationInput>;
  delete?: Maybe<EncounterParticipantDeleteInput>;
};

export type MutationCreateEncounterDiagnosesArgs = {
  input: Array<EncounterDiagnosisCreateInput>;
};

export type MutationDeleteEncounterDiagnosesArgs = {
  where?: Maybe<EncounterDiagnosisWhere>;
  delete?: Maybe<EncounterDiagnosisDeleteInput>;
};

export type MutationUpdateEncounterDiagnosesArgs = {
  where?: Maybe<EncounterDiagnosisWhere>;
  update?: Maybe<EncounterDiagnosisUpdateInput>;
  connect?: Maybe<EncounterDiagnosisConnectInput>;
  disconnect?: Maybe<EncounterDiagnosisDisconnectInput>;
  create?: Maybe<EncounterDiagnosisRelationInput>;
  delete?: Maybe<EncounterDiagnosisDeleteInput>;
};

export type MutationCreateEncounterHospitalizationsArgs = {
  input: Array<EncounterHospitalizationCreateInput>;
};

export type MutationDeleteEncounterHospitalizationsArgs = {
  where?: Maybe<EncounterHospitalizationWhere>;
  delete?: Maybe<EncounterHospitalizationDeleteInput>;
};

export type MutationUpdateEncounterHospitalizationsArgs = {
  where?: Maybe<EncounterHospitalizationWhere>;
  update?: Maybe<EncounterHospitalizationUpdateInput>;
  connect?: Maybe<EncounterHospitalizationConnectInput>;
  disconnect?: Maybe<EncounterHospitalizationDisconnectInput>;
  create?: Maybe<EncounterHospitalizationRelationInput>;
  delete?: Maybe<EncounterHospitalizationDeleteInput>;
};

export type MutationCreateEncounterLocationsArgs = {
  input: Array<EncounterLocationCreateInput>;
};

export type MutationDeleteEncounterLocationsArgs = {
  where?: Maybe<EncounterLocationWhere>;
  delete?: Maybe<EncounterLocationDeleteInput>;
};

export type MutationUpdateEncounterLocationsArgs = {
  where?: Maybe<EncounterLocationWhere>;
  update?: Maybe<EncounterLocationUpdateInput>;
  connect?: Maybe<EncounterLocationConnectInput>;
  disconnect?: Maybe<EncounterLocationDisconnectInput>;
  create?: Maybe<EncounterLocationRelationInput>;
  delete?: Maybe<EncounterLocationDeleteInput>;
};

export type MutationCreateEndpointsArgs = {
  input: Array<EndpointCreateInput>;
};

export type MutationDeleteEndpointsArgs = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type MutationUpdateEndpointsArgs = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<EndpointConnectInput>;
  disconnect?: Maybe<EndpointDisconnectInput>;
  create?: Maybe<EndpointRelationInput>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type MutationCreateEpisodeOfCaresArgs = {
  input: Array<EpisodeOfCareCreateInput>;
};

export type MutationDeleteEpisodeOfCaresArgs = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type MutationUpdateEpisodeOfCaresArgs = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<EpisodeOfCareConnectInput>;
  disconnect?: Maybe<EpisodeOfCareDisconnectInput>;
  create?: Maybe<EpisodeOfCareRelationInput>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type MutationCreateEpisodeOfCareStatusHistoriesArgs = {
  input: Array<EpisodeOfCareStatusHistoryCreateInput>;
};

export type MutationDeleteEpisodeOfCareStatusHistoriesArgs = {
  where?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  delete?: Maybe<EpisodeOfCareStatusHistoryDeleteInput>;
};

export type MutationUpdateEpisodeOfCareStatusHistoriesArgs = {
  where?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  update?: Maybe<EpisodeOfCareStatusHistoryUpdateInput>;
  connect?: Maybe<EpisodeOfCareStatusHistoryConnectInput>;
  disconnect?: Maybe<EpisodeOfCareStatusHistoryDisconnectInput>;
  create?: Maybe<EpisodeOfCareStatusHistoryRelationInput>;
  delete?: Maybe<EpisodeOfCareStatusHistoryDeleteInput>;
};

export type MutationCreateEpisodeOfCareDiagnosesArgs = {
  input: Array<EpisodeOfCareDiagnosisCreateInput>;
};

export type MutationDeleteEpisodeOfCareDiagnosesArgs = {
  where?: Maybe<EpisodeOfCareDiagnosisWhere>;
  delete?: Maybe<EpisodeOfCareDiagnosisDeleteInput>;
};

export type MutationUpdateEpisodeOfCareDiagnosesArgs = {
  where?: Maybe<EpisodeOfCareDiagnosisWhere>;
  update?: Maybe<EpisodeOfCareDiagnosisUpdateInput>;
  connect?: Maybe<EpisodeOfCareDiagnosisConnectInput>;
  disconnect?: Maybe<EpisodeOfCareDiagnosisDisconnectInput>;
  create?: Maybe<EpisodeOfCareDiagnosisRelationInput>;
  delete?: Maybe<EpisodeOfCareDiagnosisDeleteInput>;
};

export type MutationCreateHealthcareServicesArgs = {
  input: Array<HealthcareServiceCreateInput>;
};

export type MutationDeleteHealthcareServicesArgs = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type MutationUpdateHealthcareServicesArgs = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<HealthcareServiceConnectInput>;
  disconnect?: Maybe<HealthcareServiceDisconnectInput>;
  create?: Maybe<HealthcareServiceRelationInput>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type MutationCreateHealthcareServiceEligibilitiesArgs = {
  input: Array<HealthcareServiceEligibilityCreateInput>;
};

export type MutationDeleteHealthcareServiceEligibilitiesArgs = {
  where?: Maybe<HealthcareServiceEligibilityWhere>;
  delete?: Maybe<HealthcareServiceEligibilityDeleteInput>;
};

export type MutationUpdateHealthcareServiceEligibilitiesArgs = {
  where?: Maybe<HealthcareServiceEligibilityWhere>;
  update?: Maybe<HealthcareServiceEligibilityUpdateInput>;
  connect?: Maybe<HealthcareServiceEligibilityConnectInput>;
  disconnect?: Maybe<HealthcareServiceEligibilityDisconnectInput>;
  create?: Maybe<HealthcareServiceEligibilityRelationInput>;
  delete?: Maybe<HealthcareServiceEligibilityDeleteInput>;
};

export type MutationCreateHealthcareServiceAvailableTimesArgs = {
  input: Array<HealthcareServiceAvailableTimeCreateInput>;
};

export type MutationDeleteHealthcareServiceAvailableTimesArgs = {
  where?: Maybe<HealthcareServiceAvailableTimeWhere>;
  delete?: Maybe<HealthcareServiceAvailableTimeDeleteInput>;
};

export type MutationUpdateHealthcareServiceAvailableTimesArgs = {
  where?: Maybe<HealthcareServiceAvailableTimeWhere>;
  update?: Maybe<HealthcareServiceAvailableTimeUpdateInput>;
  connect?: Maybe<HealthcareServiceAvailableTimeConnectInput>;
  disconnect?: Maybe<HealthcareServiceAvailableTimeDisconnectInput>;
  create?: Maybe<HealthcareServiceAvailableTimeRelationInput>;
  delete?: Maybe<HealthcareServiceAvailableTimeDeleteInput>;
};

export type MutationCreateHealthcareServiceNotAvailablesArgs = {
  input: Array<HealthcareServiceNotAvailableCreateInput>;
};

export type MutationDeleteHealthcareServiceNotAvailablesArgs = {
  where?: Maybe<HealthcareServiceNotAvailableWhere>;
  delete?: Maybe<HealthcareServiceNotAvailableDeleteInput>;
};

export type MutationUpdateHealthcareServiceNotAvailablesArgs = {
  where?: Maybe<HealthcareServiceNotAvailableWhere>;
  update?: Maybe<HealthcareServiceNotAvailableUpdateInput>;
  connect?: Maybe<HealthcareServiceNotAvailableConnectInput>;
  disconnect?: Maybe<HealthcareServiceNotAvailableDisconnectInput>;
  create?: Maybe<HealthcareServiceNotAvailableRelationInput>;
  delete?: Maybe<HealthcareServiceNotAvailableDeleteInput>;
};

export type MutationCreateLocationsArgs = {
  input: Array<LocationCreateInput>;
};

export type MutationDeleteLocationsArgs = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type MutationUpdateLocationsArgs = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<LocationConnectInput>;
  disconnect?: Maybe<LocationDisconnectInput>;
  create?: Maybe<LocationRelationInput>;
  delete?: Maybe<LocationDeleteInput>;
};

export type MutationCreateLocationPositionsArgs = {
  input: Array<LocationPositionCreateInput>;
};

export type MutationDeleteLocationPositionsArgs = {
  where?: Maybe<LocationPositionWhere>;
  delete?: Maybe<LocationPositionDeleteInput>;
};

export type MutationUpdateLocationPositionsArgs = {
  where?: Maybe<LocationPositionWhere>;
  update?: Maybe<LocationPositionUpdateInput>;
  connect?: Maybe<LocationPositionConnectInput>;
  disconnect?: Maybe<LocationPositionDisconnectInput>;
  create?: Maybe<LocationPositionRelationInput>;
  delete?: Maybe<LocationPositionDeleteInput>;
};

export type MutationCreateLocationHoursOfOperationsArgs = {
  input: Array<LocationHoursOfOperationCreateInput>;
};

export type MutationDeleteLocationHoursOfOperationsArgs = {
  where?: Maybe<LocationHoursOfOperationWhere>;
  delete?: Maybe<LocationHoursOfOperationDeleteInput>;
};

export type MutationUpdateLocationHoursOfOperationsArgs = {
  where?: Maybe<LocationHoursOfOperationWhere>;
  update?: Maybe<LocationHoursOfOperationUpdateInput>;
  connect?: Maybe<LocationHoursOfOperationConnectInput>;
  disconnect?: Maybe<LocationHoursOfOperationDisconnectInput>;
  create?: Maybe<LocationHoursOfOperationRelationInput>;
  delete?: Maybe<LocationHoursOfOperationDeleteInput>;
};

export type MutationCreateOrganizationsArgs = {
  input: Array<OrganizationCreateInput>;
};

export type MutationDeleteOrganizationsArgs = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type MutationUpdateOrganizationsArgs = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<OrganizationConnectInput>;
  disconnect?: Maybe<OrganizationDisconnectInput>;
  create?: Maybe<OrganizationRelationInput>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type MutationCreateOrganizationContactsArgs = {
  input: Array<OrganizationContactCreateInput>;
};

export type MutationDeleteOrganizationContactsArgs = {
  where?: Maybe<OrganizationContactWhere>;
  delete?: Maybe<OrganizationContactDeleteInput>;
};

export type MutationUpdateOrganizationContactsArgs = {
  where?: Maybe<OrganizationContactWhere>;
  update?: Maybe<OrganizationContactUpdateInput>;
  connect?: Maybe<OrganizationContactConnectInput>;
  disconnect?: Maybe<OrganizationContactDisconnectInput>;
  create?: Maybe<OrganizationContactRelationInput>;
  delete?: Maybe<OrganizationContactDeleteInput>;
};

export type MutationCreatePatientsArgs = {
  input: Array<PatientCreateInput>;
};

export type MutationDeletePatientsArgs = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type MutationUpdatePatientsArgs = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<PatientConnectInput>;
  disconnect?: Maybe<PatientDisconnectInput>;
  create?: Maybe<PatientRelationInput>;
  delete?: Maybe<PatientDeleteInput>;
};

export type MutationCreatePatientContactsArgs = {
  input: Array<PatientContactCreateInput>;
};

export type MutationDeletePatientContactsArgs = {
  where?: Maybe<PatientContactWhere>;
  delete?: Maybe<PatientContactDeleteInput>;
};

export type MutationUpdatePatientContactsArgs = {
  where?: Maybe<PatientContactWhere>;
  update?: Maybe<PatientContactUpdateInput>;
  connect?: Maybe<PatientContactConnectInput>;
  disconnect?: Maybe<PatientContactDisconnectInput>;
  create?: Maybe<PatientContactRelationInput>;
  delete?: Maybe<PatientContactDeleteInput>;
};

export type MutationCreatePatientCommunicationsArgs = {
  input: Array<PatientCommunicationCreateInput>;
};

export type MutationDeletePatientCommunicationsArgs = {
  where?: Maybe<PatientCommunicationWhere>;
  delete?: Maybe<PatientCommunicationDeleteInput>;
};

export type MutationUpdatePatientCommunicationsArgs = {
  where?: Maybe<PatientCommunicationWhere>;
  update?: Maybe<PatientCommunicationUpdateInput>;
  connect?: Maybe<PatientCommunicationConnectInput>;
  disconnect?: Maybe<PatientCommunicationDisconnectInput>;
  create?: Maybe<PatientCommunicationRelationInput>;
  delete?: Maybe<PatientCommunicationDeleteInput>;
};

export type MutationCreatePatientLinksArgs = {
  input: Array<PatientLinkCreateInput>;
};

export type MutationDeletePatientLinksArgs = {
  where?: Maybe<PatientLinkWhere>;
  delete?: Maybe<PatientLinkDeleteInput>;
};

export type MutationUpdatePatientLinksArgs = {
  where?: Maybe<PatientLinkWhere>;
  update?: Maybe<PatientLinkUpdateInput>;
  connect?: Maybe<PatientLinkConnectInput>;
  disconnect?: Maybe<PatientLinkDisconnectInput>;
  create?: Maybe<PatientLinkRelationInput>;
  delete?: Maybe<PatientLinkDeleteInput>;
};

export type MutationCreatePeopleArgs = {
  input: Array<PersonCreateInput>;
};

export type MutationDeletePeopleArgs = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type MutationUpdatePeopleArgs = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<PersonConnectInput>;
  disconnect?: Maybe<PersonDisconnectInput>;
  create?: Maybe<PersonRelationInput>;
  delete?: Maybe<PersonDeleteInput>;
};

export type MutationCreatePersonLinksArgs = {
  input: Array<PersonLinkCreateInput>;
};

export type MutationDeletePersonLinksArgs = {
  where?: Maybe<PersonLinkWhere>;
  delete?: Maybe<PersonLinkDeleteInput>;
};

export type MutationUpdatePersonLinksArgs = {
  where?: Maybe<PersonLinkWhere>;
  update?: Maybe<PersonLinkUpdateInput>;
  connect?: Maybe<PersonLinkConnectInput>;
  disconnect?: Maybe<PersonLinkDisconnectInput>;
  create?: Maybe<PersonLinkRelationInput>;
  delete?: Maybe<PersonLinkDeleteInput>;
};

export type MutationCreatePractitionersArgs = {
  input: Array<PractitionerCreateInput>;
};

export type MutationDeletePractitionersArgs = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type MutationUpdatePractitionersArgs = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<PractitionerConnectInput>;
  disconnect?: Maybe<PractitionerDisconnectInput>;
  create?: Maybe<PractitionerRelationInput>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type MutationCreatePractitionerQualificationsArgs = {
  input: Array<PractitionerQualificationCreateInput>;
};

export type MutationDeletePractitionerQualificationsArgs = {
  where?: Maybe<PractitionerQualificationWhere>;
  delete?: Maybe<PractitionerQualificationDeleteInput>;
};

export type MutationUpdatePractitionerQualificationsArgs = {
  where?: Maybe<PractitionerQualificationWhere>;
  update?: Maybe<PractitionerQualificationUpdateInput>;
  connect?: Maybe<PractitionerQualificationConnectInput>;
  disconnect?: Maybe<PractitionerQualificationDisconnectInput>;
  create?: Maybe<PractitionerQualificationRelationInput>;
  delete?: Maybe<PractitionerQualificationDeleteInput>;
};

export type MutationCreatePractitionerRolesArgs = {
  input: Array<PractitionerRoleCreateInput>;
};

export type MutationDeletePractitionerRolesArgs = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type MutationUpdatePractitionerRolesArgs = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<PractitionerRoleConnectInput>;
  disconnect?: Maybe<PractitionerRoleDisconnectInput>;
  create?: Maybe<PractitionerRoleRelationInput>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type MutationCreatePractitionerRoleAvailableTimesArgs = {
  input: Array<PractitionerRoleAvailableTimeCreateInput>;
};

export type MutationDeletePractitionerRoleAvailableTimesArgs = {
  where?: Maybe<PractitionerRoleAvailableTimeWhere>;
  delete?: Maybe<PractitionerRoleAvailableTimeDeleteInput>;
};

export type MutationUpdatePractitionerRoleAvailableTimesArgs = {
  where?: Maybe<PractitionerRoleAvailableTimeWhere>;
  update?: Maybe<PractitionerRoleAvailableTimeUpdateInput>;
  connect?: Maybe<PractitionerRoleAvailableTimeConnectInput>;
  disconnect?: Maybe<PractitionerRoleAvailableTimeDisconnectInput>;
  create?: Maybe<PractitionerRoleAvailableTimeRelationInput>;
  delete?: Maybe<PractitionerRoleAvailableTimeDeleteInput>;
};

export type MutationCreatePractitionerRoleNotAvailablesArgs = {
  input: Array<PractitionerRoleNotAvailableCreateInput>;
};

export type MutationDeletePractitionerRoleNotAvailablesArgs = {
  where?: Maybe<PractitionerRoleNotAvailableWhere>;
  delete?: Maybe<PractitionerRoleNotAvailableDeleteInput>;
};

export type MutationUpdatePractitionerRoleNotAvailablesArgs = {
  where?: Maybe<PractitionerRoleNotAvailableWhere>;
  update?: Maybe<PractitionerRoleNotAvailableUpdateInput>;
  connect?: Maybe<PractitionerRoleNotAvailableConnectInput>;
  disconnect?: Maybe<PractitionerRoleNotAvailableDisconnectInput>;
  create?: Maybe<PractitionerRoleNotAvailableRelationInput>;
  delete?: Maybe<PractitionerRoleNotAvailableDeleteInput>;
};

export type MutationCreateRelatedPeopleArgs = {
  input: Array<RelatedPersonCreateInput>;
};

export type MutationDeleteRelatedPeopleArgs = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type MutationUpdateRelatedPeopleArgs = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<RelatedPersonConnectInput>;
  disconnect?: Maybe<RelatedPersonDisconnectInput>;
  create?: Maybe<RelatedPersonRelationInput>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type MutationCreateRelatedPersonCommunicationsArgs = {
  input: Array<RelatedPersonCommunicationCreateInput>;
};

export type MutationDeleteRelatedPersonCommunicationsArgs = {
  where?: Maybe<RelatedPersonCommunicationWhere>;
  delete?: Maybe<RelatedPersonCommunicationDeleteInput>;
};

export type MutationUpdateRelatedPersonCommunicationsArgs = {
  where?: Maybe<RelatedPersonCommunicationWhere>;
  update?: Maybe<RelatedPersonCommunicationUpdateInput>;
  connect?: Maybe<RelatedPersonCommunicationConnectInput>;
  disconnect?: Maybe<RelatedPersonCommunicationDisconnectInput>;
  create?: Maybe<RelatedPersonCommunicationRelationInput>;
  delete?: Maybe<RelatedPersonCommunicationDeleteInput>;
};

export type MutationCreateSchedulesArgs = {
  input: Array<ScheduleCreateInput>;
};

export type MutationDeleteSchedulesArgs = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type MutationUpdateSchedulesArgs = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<ScheduleConnectInput>;
  disconnect?: Maybe<ScheduleDisconnectInput>;
  create?: Maybe<ScheduleRelationInput>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type MutationCreateSlotsArgs = {
  input: Array<SlotCreateInput>;
};

export type MutationDeleteSlotsArgs = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type MutationUpdateSlotsArgs = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<SlotConnectInput>;
  disconnect?: Maybe<SlotDisconnectInput>;
  create?: Maybe<SlotRelationInput>;
  delete?: Maybe<SlotDeleteInput>;
};

export type MutationCreateSubstancesArgs = {
  input: Array<SubstanceCreateInput>;
};

export type MutationDeleteSubstancesArgs = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type MutationUpdateSubstancesArgs = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<SubstanceConnectInput>;
  disconnect?: Maybe<SubstanceDisconnectInput>;
  create?: Maybe<SubstanceRelationInput>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type MutationCreateSubstanceInstancesArgs = {
  input: Array<SubstanceInstanceCreateInput>;
};

export type MutationDeleteSubstanceInstancesArgs = {
  where?: Maybe<SubstanceInstanceWhere>;
  delete?: Maybe<SubstanceInstanceDeleteInput>;
};

export type MutationUpdateSubstanceInstancesArgs = {
  where?: Maybe<SubstanceInstanceWhere>;
  update?: Maybe<SubstanceInstanceUpdateInput>;
  connect?: Maybe<SubstanceInstanceConnectInput>;
  disconnect?: Maybe<SubstanceInstanceDisconnectInput>;
  create?: Maybe<SubstanceInstanceRelationInput>;
  delete?: Maybe<SubstanceInstanceDeleteInput>;
};

export type MutationCreateSubstanceIngredientsArgs = {
  input: Array<SubstanceIngredientCreateInput>;
};

export type MutationDeleteSubstanceIngredientsArgs = {
  where?: Maybe<SubstanceIngredientWhere>;
  delete?: Maybe<SubstanceIngredientDeleteInput>;
};

export type MutationUpdateSubstanceIngredientsArgs = {
  where?: Maybe<SubstanceIngredientWhere>;
  update?: Maybe<SubstanceIngredientUpdateInput>;
  connect?: Maybe<SubstanceIngredientConnectInput>;
  disconnect?: Maybe<SubstanceIngredientDisconnectInput>;
  create?: Maybe<SubstanceIngredientRelationInput>;
  delete?: Maybe<SubstanceIngredientDeleteInput>;
};

export type MutationCreateUsersArgs = {
  input: Array<UserCreateInput>;
};

export type MutationDeleteUsersArgs = {
  where?: Maybe<UserWhere>;
  delete?: Maybe<UserDeleteInput>;
};

export type MutationUpdateUsersArgs = {
  where?: Maybe<UserWhere>;
  update?: Maybe<UserUpdateInput>;
  connect?: Maybe<UserConnectInput>;
  disconnect?: Maybe<UserDisconnectInput>;
  create?: Maybe<UserRelationInput>;
  delete?: Maybe<UserDeleteInput>;
};

export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationSetNewPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type MutationRegisterArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  profile: PractitionerCreateInput;
};

/** A human-readable summary of the resource conveying the essential clinical and business information for the resource. */
export type Narrative = {
  __typename?: 'Narrative';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The status of the narrative - whether it's entirely generated (from just the defined data or the extensions too), or whether a human authored it and it may contain additional data. */
  status?: Maybe<NarrativeStatus>;
  /** The actual narrative content, a stripped down version of XHTML. */
  div: Scalars['Xhtml'];
  extension: Array<Extension>;
  _status?: Maybe<Element>;
};

/** A human-readable summary of the resource conveying the essential clinical and business information for the resource. */
export type NarrativeExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A human-readable summary of the resource conveying the essential clinical and business information for the resource. */
export type Narrative_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type NarrativeConnectFieldInput = {
  where?: Maybe<NarrativeWhere>;
  connect?: Maybe<NarrativeConnectInput>;
};

export type NarrativeConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
};

export type NarrativeCreateInput = {
  div: Scalars['Xhtml'];
  status?: Maybe<NarrativeStatus>;
  extension?: Maybe<NarrativeExtensionFieldInput>;
  _status?: Maybe<Narrative_StatusFieldInput>;
};

export type NarrativeDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type NarrativeDeleteInput = {
  extension?: Maybe<Array<NarrativeExtensionDeleteFieldInput>>;
  _status?: Maybe<Narrative_StatusDeleteFieldInput>;
};

export type NarrativeDisconnectFieldInput = {
  where?: Maybe<NarrativeWhere>;
  disconnect?: Maybe<NarrativeDisconnectInput>;
};

export type NarrativeDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
};

export type NarrativeExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type NarrativeExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type NarrativeExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type NarrativeOptions = {
  /** Specify one or more NarrativeSort objects to sort Narratives by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<NarrativeSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type NarrativeRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
};

/** Fields to sort Narratives by. The order in which sorts are applied is not guaranteed when specifying many fields in one NarrativeSort object. */
export type NarrativeSort = {
  id?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  div?: Maybe<SortDirection>;
};

/** The status of the narrative - whether it's entirely generated (from just the defined data or the extensions too), or whether a human authored it and it may contain additional data. */
export enum NarrativeStatus {
  Generated = 'generated',
  Extensions = 'extensions',
  Additional = 'additional',
  Empty = 'empty',
}

export type NarrativeUpdateInput = {
  div?: Maybe<Scalars['Xhtml']>;
  status?: Maybe<NarrativeStatus>;
  extension?: Maybe<Array<NarrativeExtensionUpdateFieldInput>>;
  _status?: Maybe<Narrative_StatusUpdateFieldInput>;
};

export type NarrativeWhere = {
  OR?: Maybe<Array<NarrativeWhere>>;
  AND?: Maybe<Array<NarrativeWhere>>;
  div?: Maybe<Scalars['Xhtml']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  status?: Maybe<NarrativeStatus>;
  status_NOT?: Maybe<NarrativeStatus>;
  status_IN?: Maybe<Array<Maybe<NarrativeStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<NarrativeStatus>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Narrative_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Narrative_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Narrative_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type Organization = {
  __typename?: 'Organization';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** Whether the organization's record is still in active use. */
  active?: Maybe<Scalars['Boolean']>;
  /** This is a Organization resource */
  resourceType: OrganizationResourceType;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** A name associated with the organization. */
  name?: Maybe<Scalars['String']>;
  /** A list of alternate names that the organization is known as, or was known as in the past. */
  alias: Array<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _active?: Maybe<Element>;
  type: Array<CodeableConcept>;
  _name?: Maybe<Element>;
  _alias: Array<Element>;
  telecom: Array<ContactPoint>;
  address: Array<Address>;
  partOf?: Maybe<Reference>;
  contact: Array<OrganizationContact>;
  endpoint: Array<Reference>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type Organization_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type Organization_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type Organization_ActiveArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type Organization_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type Organization_AliasArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationAddressArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationPartOfArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationContactArgs = {
  where?: Maybe<OrganizationContactWhere>;
  options?: Maybe<OrganizationContactOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationEndpointArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type OrganizationAddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type OrganizationAddressFieldInput = {
  create?: Maybe<Array<AddressCreateInput>>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
};

export type OrganizationAddressUpdateFieldInput = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
  disconnect?: Maybe<Array<AddressDisconnectFieldInput>>;
  create?: Maybe<Array<AddressCreateInput>>;
  delete?: Maybe<Array<AddressDeleteFieldInput>>;
};

export type OrganizationConnectFieldInput = {
  where?: Maybe<OrganizationWhere>;
  connect?: Maybe<OrganizationConnectInput>;
};

export type OrganizationConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _active?: Maybe<ElementConnectFieldInput>;
  type?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  _name?: Maybe<ElementConnectFieldInput>;
  _alias?: Maybe<Array<ElementConnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  address?: Maybe<Array<AddressConnectFieldInput>>;
  partOf?: Maybe<ReferenceConnectFieldInput>;
  contact?: Maybe<Array<OrganizationContactConnectFieldInput>>;
  endpoint?: Maybe<Array<ReferenceConnectFieldInput>>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationContact = {
  __typename?: 'OrganizationContact';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  purpose?: Maybe<CodeableConcept>;
  name?: Maybe<HumanName>;
  telecom: Array<ContactPoint>;
  address?: Maybe<Address>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationContactExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationContactModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationContactPurposeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationContactNameArgs = {
  where?: Maybe<HumanNameWhere>;
  options?: Maybe<HumanNameOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationContactTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action.  Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, payer/insurer, etc. */
export type OrganizationContactAddressArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

export type OrganizationContactAddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type OrganizationContactAddressFieldInput = {
  create?: Maybe<AddressCreateInput>;
  connect?: Maybe<AddressConnectFieldInput>;
};

export type OrganizationContactAddressUpdateFieldInput = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<AddressConnectFieldInput>;
  disconnect?: Maybe<AddressDisconnectFieldInput>;
  create?: Maybe<AddressCreateInput>;
  delete?: Maybe<AddressDeleteFieldInput>;
};

export type OrganizationContactConnectFieldInput = {
  where?: Maybe<OrganizationContactWhere>;
  connect?: Maybe<OrganizationContactConnectInput>;
};

export type OrganizationContactConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  purpose?: Maybe<CodeableConceptConnectFieldInput>;
  name?: Maybe<HumanNameConnectFieldInput>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  address?: Maybe<AddressConnectFieldInput>;
};

export type OrganizationContactCreateInput = {
  extension?: Maybe<OrganizationContactExtensionFieldInput>;
  modifierExtension?: Maybe<OrganizationContactModifierextensionFieldInput>;
  purpose?: Maybe<OrganizationContactPurposeFieldInput>;
  name?: Maybe<OrganizationContactNameFieldInput>;
  telecom?: Maybe<OrganizationContactTelecomFieldInput>;
  address?: Maybe<OrganizationContactAddressFieldInput>;
};

export type OrganizationContactDeleteFieldInput = {
  where?: Maybe<OrganizationContactWhere>;
  delete?: Maybe<OrganizationContactDeleteInput>;
};

export type OrganizationContactDeleteInput = {
  extension?: Maybe<Array<OrganizationContactExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<OrganizationContactModifierextensionDeleteFieldInput>>;
  purpose?: Maybe<OrganizationContactPurposeDeleteFieldInput>;
  name?: Maybe<OrganizationContactNameDeleteFieldInput>;
  telecom?: Maybe<Array<OrganizationContactTelecomDeleteFieldInput>>;
  address?: Maybe<OrganizationContactAddressDeleteFieldInput>;
};

export type OrganizationContactDisconnectFieldInput = {
  where?: Maybe<OrganizationContactWhere>;
  disconnect?: Maybe<OrganizationContactDisconnectInput>;
};

export type OrganizationContactDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  purpose?: Maybe<CodeableConceptDisconnectFieldInput>;
  name?: Maybe<HumanNameDisconnectFieldInput>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  address?: Maybe<AddressDisconnectFieldInput>;
};

export type OrganizationContactExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type OrganizationContactExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type OrganizationContactExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type OrganizationContactFieldInput = {
  create?: Maybe<Array<OrganizationContactCreateInput>>;
  connect?: Maybe<Array<OrganizationContactConnectFieldInput>>;
};

export type OrganizationContactModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type OrganizationContactModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type OrganizationContactModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type OrganizationContactNameDeleteFieldInput = {
  where?: Maybe<HumanNameWhere>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type OrganizationContactNameFieldInput = {
  create?: Maybe<HumanNameCreateInput>;
  connect?: Maybe<HumanNameConnectFieldInput>;
};

export type OrganizationContactNameUpdateFieldInput = {
  where?: Maybe<HumanNameWhere>;
  update?: Maybe<HumanNameUpdateInput>;
  connect?: Maybe<HumanNameConnectFieldInput>;
  disconnect?: Maybe<HumanNameDisconnectFieldInput>;
  create?: Maybe<HumanNameCreateInput>;
  delete?: Maybe<HumanNameDeleteFieldInput>;
};

export type OrganizationContactOptions = {
  /** Specify one or more OrganizationContactSort objects to sort OrganizationContacts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<OrganizationContactSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type OrganizationContactPurposeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type OrganizationContactPurposeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type OrganizationContactPurposeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type OrganizationContactRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  purpose?: Maybe<CodeableConceptCreateInput>;
  name?: Maybe<HumanNameCreateInput>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  address?: Maybe<AddressCreateInput>;
};

/** Fields to sort OrganizationContacts by. The order in which sorts are applied is not guaranteed when specifying many fields in one OrganizationContactSort object. */
export type OrganizationContactSort = {
  id?: Maybe<SortDirection>;
};

export type OrganizationContactTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type OrganizationContactTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type OrganizationContactTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type OrganizationContactUpdateFieldInput = {
  where?: Maybe<OrganizationContactWhere>;
  update?: Maybe<OrganizationContactUpdateInput>;
  connect?: Maybe<Array<OrganizationContactConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationContactDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationContactCreateInput>>;
  delete?: Maybe<Array<OrganizationContactDeleteFieldInput>>;
};

export type OrganizationContactUpdateInput = {
  extension?: Maybe<Array<OrganizationContactExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<OrganizationContactModifierextensionUpdateFieldInput>>;
  purpose?: Maybe<OrganizationContactPurposeUpdateFieldInput>;
  name?: Maybe<OrganizationContactNameUpdateFieldInput>;
  telecom?: Maybe<Array<OrganizationContactTelecomUpdateFieldInput>>;
  address?: Maybe<OrganizationContactAddressUpdateFieldInput>;
};

export type OrganizationContactWhere = {
  OR?: Maybe<Array<OrganizationContactWhere>>;
  AND?: Maybe<Array<OrganizationContactWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  purpose?: Maybe<CodeableConceptWhere>;
  purpose_NOT?: Maybe<CodeableConceptWhere>;
  purpose_IN?: Maybe<Array<CodeableConceptWhere>>;
  purpose_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  name?: Maybe<HumanNameWhere>;
  name_NOT?: Maybe<HumanNameWhere>;
  name_IN?: Maybe<Array<HumanNameWhere>>;
  name_NOT_IN?: Maybe<Array<HumanNameWhere>>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  address?: Maybe<AddressWhere>;
  address_NOT?: Maybe<AddressWhere>;
  address_IN?: Maybe<Array<AddressWhere>>;
  address_NOT_IN?: Maybe<Array<AddressWhere>>;
};

export type OrganizationContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type OrganizationContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type OrganizationContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type OrganizationContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type OrganizationContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type OrganizationContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type OrganizationContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type OrganizationContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type OrganizationContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type OrganizationContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type OrganizationContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type OrganizationContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type OrganizationContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type OrganizationContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type OrganizationContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type OrganizationContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type OrganizationContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type OrganizationContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type OrganizationContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type OrganizationContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type OrganizationContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type OrganizationContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type OrganizationContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type OrganizationContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type OrganizationContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type OrganizationContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type OrganizationContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type OrganizationContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type OrganizationContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type OrganizationContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type OrganizationContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type OrganizationContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type OrganizationContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type OrganizationContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type OrganizationContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type OrganizationContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type OrganizationContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type OrganizationContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type OrganizationContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type OrganizationContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type OrganizationContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type OrganizationContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type OrganizationContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type OrganizationContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type OrganizationContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type OrganizationContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type OrganizationContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type OrganizationContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type OrganizationContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type OrganizationContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type OrganizationContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type OrganizationContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type OrganizationContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type OrganizationContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type OrganizationContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type OrganizationContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type OrganizationContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type OrganizationContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type OrganizationContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type OrganizationContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type OrganizationContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type OrganizationContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type OrganizationContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type OrganizationCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  alias: Array<Scalars['String']>;
  resourceType: OrganizationResourceType;
  meta?: Maybe<OrganizationMetaFieldInput>;
  _implicitRules?: Maybe<Organization_ImplicitrulesFieldInput>;
  _language?: Maybe<Organization_LanguageFieldInput>;
  text?: Maybe<OrganizationTextFieldInput>;
  contained_Account?: Maybe<OrganizationContainedAccountFieldInput>;
  contained_Appointment?: Maybe<OrganizationContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<OrganizationContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<OrganizationContainedCoverageFieldInput>;
  contained_Device?: Maybe<OrganizationContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<OrganizationContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<OrganizationContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<OrganizationContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<OrganizationContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<OrganizationContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<OrganizationContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<OrganizationContainedLocationFieldInput>;
  contained_Organization?: Maybe<OrganizationContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<OrganizationContainedPatientFieldInput>;
  contained_Person?: Maybe<OrganizationContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<OrganizationContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<OrganizationContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<OrganizationContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<OrganizationContainedScheduleFieldInput>;
  contained_Slot?: Maybe<OrganizationContainedSlotFieldInput>;
  contained_Substance?: Maybe<OrganizationContainedSubstanceFieldInput>;
  extension?: Maybe<OrganizationExtensionFieldInput>;
  modifierExtension?: Maybe<OrganizationModifierextensionFieldInput>;
  identifier?: Maybe<OrganizationIdentifierFieldInput>;
  _active?: Maybe<Organization_ActiveFieldInput>;
  type?: Maybe<OrganizationTypeFieldInput>;
  _name?: Maybe<Organization_NameFieldInput>;
  _alias?: Maybe<Organization_AliasFieldInput>;
  telecom?: Maybe<OrganizationTelecomFieldInput>;
  address?: Maybe<OrganizationAddressFieldInput>;
  partOf?: Maybe<OrganizationPartofFieldInput>;
  contact?: Maybe<OrganizationContactFieldInput>;
  endpoint?: Maybe<OrganizationEndpointFieldInput>;
};

export type OrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type OrganizationDeleteInput = {
  meta?: Maybe<OrganizationMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Organization_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Organization_LanguageDeleteFieldInput>;
  text?: Maybe<OrganizationTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<OrganizationContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<OrganizationContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<OrganizationContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<OrganizationContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<OrganizationContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<OrganizationContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<OrganizationContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<OrganizationContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<OrganizationContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<OrganizationContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<OrganizationContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<OrganizationContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<OrganizationContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<OrganizationContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<OrganizationContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<OrganizationContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<OrganizationContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<OrganizationContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<OrganizationContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<OrganizationContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<OrganizationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<OrganizationModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<OrganizationIdentifierDeleteFieldInput>>;
  _active?: Maybe<Organization_ActiveDeleteFieldInput>;
  type?: Maybe<Array<OrganizationTypeDeleteFieldInput>>;
  _name?: Maybe<Organization_NameDeleteFieldInput>;
  _alias?: Maybe<Array<Organization_AliasDeleteFieldInput>>;
  telecom?: Maybe<Array<OrganizationTelecomDeleteFieldInput>>;
  address?: Maybe<Array<OrganizationAddressDeleteFieldInput>>;
  partOf?: Maybe<OrganizationPartofDeleteFieldInput>;
  contact?: Maybe<Array<OrganizationContactDeleteFieldInput>>;
  endpoint?: Maybe<Array<OrganizationEndpointDeleteFieldInput>>;
};

export type OrganizationDisconnectFieldInput = {
  where?: Maybe<OrganizationWhere>;
  disconnect?: Maybe<OrganizationDisconnectInput>;
};

export type OrganizationDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _active?: Maybe<ElementDisconnectFieldInput>;
  type?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  _alias?: Maybe<Array<ElementDisconnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  address?: Maybe<Array<AddressDisconnectFieldInput>>;
  partOf?: Maybe<ReferenceDisconnectFieldInput>;
  contact?: Maybe<Array<OrganizationContactDisconnectFieldInput>>;
  endpoint?: Maybe<Array<ReferenceDisconnectFieldInput>>;
};

export type OrganizationEndpointDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type OrganizationEndpointFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type OrganizationEndpointUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type OrganizationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type OrganizationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type OrganizationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type OrganizationIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type OrganizationIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type OrganizationIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type OrganizationMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type OrganizationMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type OrganizationMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type OrganizationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type OrganizationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type OrganizationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type OrganizationOptions = {
  /** Specify one or more OrganizationSort objects to sort Organizations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<OrganizationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type OrganizationPartofDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type OrganizationPartofFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type OrganizationPartofUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type OrganizationRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _active?: Maybe<ElementCreateInput>;
  type?: Maybe<Array<CodeableConceptCreateInput>>;
  _name?: Maybe<ElementCreateInput>;
  _alias?: Maybe<Array<ElementCreateInput>>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  address?: Maybe<Array<AddressCreateInput>>;
  partOf?: Maybe<ReferenceCreateInput>;
  contact?: Maybe<Array<OrganizationContactCreateInput>>;
  endpoint?: Maybe<Array<ReferenceCreateInput>>;
};

export enum OrganizationResourceType {
  Organization = 'Organization',
}

/** Fields to sort Organizations by. The order in which sorts are applied is not guaranteed when specifying many fields in one OrganizationSort object. */
export type OrganizationSort = {
  id?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
};

export type OrganizationTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type OrganizationTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type OrganizationTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type OrganizationTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type OrganizationTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type OrganizationTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type OrganizationTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type OrganizationTypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type OrganizationTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type OrganizationUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Array<Scalars['String']>>;
  resourceType?: Maybe<OrganizationResourceType>;
  meta?: Maybe<OrganizationMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Organization_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Organization_LanguageUpdateFieldInput>;
  text?: Maybe<OrganizationTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<OrganizationContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<OrganizationContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<OrganizationContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<OrganizationContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<OrganizationContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<OrganizationContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<OrganizationContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<OrganizationContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<OrganizationContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<OrganizationContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<OrganizationContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<OrganizationContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<OrganizationContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<OrganizationContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<OrganizationContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<OrganizationContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<OrganizationContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<OrganizationContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<OrganizationContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<OrganizationContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<OrganizationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<OrganizationModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<OrganizationIdentifierUpdateFieldInput>>;
  _active?: Maybe<Organization_ActiveUpdateFieldInput>;
  type?: Maybe<Array<OrganizationTypeUpdateFieldInput>>;
  _name?: Maybe<Organization_NameUpdateFieldInput>;
  _alias?: Maybe<Array<Organization_AliasUpdateFieldInput>>;
  telecom?: Maybe<Array<OrganizationTelecomUpdateFieldInput>>;
  address?: Maybe<Array<OrganizationAddressUpdateFieldInput>>;
  partOf?: Maybe<OrganizationPartofUpdateFieldInput>;
  contact?: Maybe<Array<OrganizationContactUpdateFieldInput>>;
  endpoint?: Maybe<Array<OrganizationEndpointUpdateFieldInput>>;
};

export type OrganizationWhere = {
  OR?: Maybe<Array<OrganizationWhere>>;
  AND?: Maybe<Array<OrganizationWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<OrganizationResourceType>;
  resourceType_NOT?: Maybe<OrganizationResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<OrganizationResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<OrganizationResourceType>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _active?: Maybe<ElementWhere>;
  _active_NOT?: Maybe<ElementWhere>;
  _active_IN?: Maybe<Array<ElementWhere>>;
  _active_NOT_IN?: Maybe<Array<ElementWhere>>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  _alias?: Maybe<ElementWhere>;
  _alias_NOT?: Maybe<ElementWhere>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  address?: Maybe<AddressWhere>;
  address_NOT?: Maybe<AddressWhere>;
  partOf?: Maybe<ReferenceWhere>;
  partOf_NOT?: Maybe<ReferenceWhere>;
  partOf_IN?: Maybe<Array<ReferenceWhere>>;
  partOf_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  contact?: Maybe<OrganizationContactWhere>;
  contact_NOT?: Maybe<OrganizationContactWhere>;
  endpoint?: Maybe<ReferenceWhere>;
  endpoint_NOT?: Maybe<ReferenceWhere>;
};

export type Organization_ActiveDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Organization_ActiveFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Organization_ActiveUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Organization_AliasDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Organization_AliasFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type Organization_AliasUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type Organization_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Organization_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Organization_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Organization_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Organization_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Organization_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Organization_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Organization_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Organization_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** The parameters to the module. This collection specifies both the input and output parameters. Input parameters are provided by the caller as part of the $evaluate operation. Output parameters are included in the GuidanceResponse. */
export type ParameterDefinition = {
  __typename?: 'ParameterDefinition';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The minimum number of times this parameter SHALL appear in the request or response. */
  min?: Maybe<Scalars['Int']>;
  /** The name of the parameter used to allow access to the value of the parameter in evaluation contexts. */
  name?: Maybe<Scalars['Code']>;
  /** Whether the parameter is input or output for the module. */
  use?: Maybe<Scalars['Code']>;
  /** The maximum number of times this element is permitted to appear in the request or response. */
  max?: Maybe<Scalars['String']>;
  /** A brief discussion of what the parameter is for and how it is used by the module. */
  documentation?: Maybe<Scalars['String']>;
  /** The type of the parameter. */
  type?: Maybe<Scalars['Code']>;
  /** If specified, this indicates a profile that the input data must conform to, or that the output data will conform to. */
  profile?: Maybe<Scalars['Canonical']>;
  extension: Array<Extension>;
  _name?: Maybe<Element>;
  _use?: Maybe<Element>;
  _min?: Maybe<Element>;
  _max?: Maybe<Element>;
  _documentation?: Maybe<Element>;
  _type?: Maybe<Element>;
};

/** The parameters to the module. This collection specifies both the input and output parameters. Input parameters are provided by the caller as part of the $evaluate operation. Output parameters are included in the GuidanceResponse. */
export type ParameterDefinitionExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The parameters to the module. This collection specifies both the input and output parameters. Input parameters are provided by the caller as part of the $evaluate operation. Output parameters are included in the GuidanceResponse. */
export type ParameterDefinition_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The parameters to the module. This collection specifies both the input and output parameters. Input parameters are provided by the caller as part of the $evaluate operation. Output parameters are included in the GuidanceResponse. */
export type ParameterDefinition_UseArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The parameters to the module. This collection specifies both the input and output parameters. Input parameters are provided by the caller as part of the $evaluate operation. Output parameters are included in the GuidanceResponse. */
export type ParameterDefinition_MinArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The parameters to the module. This collection specifies both the input and output parameters. Input parameters are provided by the caller as part of the $evaluate operation. Output parameters are included in the GuidanceResponse. */
export type ParameterDefinition_MaxArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The parameters to the module. This collection specifies both the input and output parameters. Input parameters are provided by the caller as part of the $evaluate operation. Output parameters are included in the GuidanceResponse. */
export type ParameterDefinition_DocumentationArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The parameters to the module. This collection specifies both the input and output parameters. Input parameters are provided by the caller as part of the $evaluate operation. Output parameters are included in the GuidanceResponse. */
export type ParameterDefinition_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type ParameterDefinitionConnectFieldInput = {
  where?: Maybe<ParameterDefinitionWhere>;
  connect?: Maybe<ParameterDefinitionConnectInput>;
};

export type ParameterDefinitionConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _name?: Maybe<ElementConnectFieldInput>;
  _use?: Maybe<ElementConnectFieldInput>;
  _min?: Maybe<ElementConnectFieldInput>;
  _max?: Maybe<ElementConnectFieldInput>;
  _documentation?: Maybe<ElementConnectFieldInput>;
  _type?: Maybe<ElementConnectFieldInput>;
};

export type ParameterDefinitionCreateInput = {
  min?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Code']>;
  use?: Maybe<Scalars['Code']>;
  max?: Maybe<Scalars['String']>;
  documentation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Code']>;
  profile?: Maybe<Scalars['Canonical']>;
  extension?: Maybe<ParameterDefinitionExtensionFieldInput>;
  _name?: Maybe<ParameterDefinition_NameFieldInput>;
  _use?: Maybe<ParameterDefinition_UseFieldInput>;
  _min?: Maybe<ParameterDefinition_MinFieldInput>;
  _max?: Maybe<ParameterDefinition_MaxFieldInput>;
  _documentation?: Maybe<ParameterDefinition_DocumentationFieldInput>;
  _type?: Maybe<ParameterDefinition_TypeFieldInput>;
};

export type ParameterDefinitionDeleteFieldInput = {
  where?: Maybe<ParameterDefinitionWhere>;
  delete?: Maybe<ParameterDefinitionDeleteInput>;
};

export type ParameterDefinitionDeleteInput = {
  extension?: Maybe<Array<ParameterDefinitionExtensionDeleteFieldInput>>;
  _name?: Maybe<ParameterDefinition_NameDeleteFieldInput>;
  _use?: Maybe<ParameterDefinition_UseDeleteFieldInput>;
  _min?: Maybe<ParameterDefinition_MinDeleteFieldInput>;
  _max?: Maybe<ParameterDefinition_MaxDeleteFieldInput>;
  _documentation?: Maybe<ParameterDefinition_DocumentationDeleteFieldInput>;
  _type?: Maybe<ParameterDefinition_TypeDeleteFieldInput>;
};

export type ParameterDefinitionDisconnectFieldInput = {
  where?: Maybe<ParameterDefinitionWhere>;
  disconnect?: Maybe<ParameterDefinitionDisconnectInput>;
};

export type ParameterDefinitionDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  _use?: Maybe<ElementDisconnectFieldInput>;
  _min?: Maybe<ElementDisconnectFieldInput>;
  _max?: Maybe<ElementDisconnectFieldInput>;
  _documentation?: Maybe<ElementDisconnectFieldInput>;
  _type?: Maybe<ElementDisconnectFieldInput>;
};

export type ParameterDefinitionExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ParameterDefinitionExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ParameterDefinitionExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ParameterDefinitionOptions = {
  /** Specify one or more ParameterDefinitionSort objects to sort ParameterDefinitions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ParameterDefinitionSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ParameterDefinitionRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _name?: Maybe<ElementCreateInput>;
  _use?: Maybe<ElementCreateInput>;
  _min?: Maybe<ElementCreateInput>;
  _max?: Maybe<ElementCreateInput>;
  _documentation?: Maybe<ElementCreateInput>;
  _type?: Maybe<ElementCreateInput>;
};

/** Fields to sort ParameterDefinitions by. The order in which sorts are applied is not guaranteed when specifying many fields in one ParameterDefinitionSort object. */
export type ParameterDefinitionSort = {
  id?: Maybe<SortDirection>;
  min?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  use?: Maybe<SortDirection>;
  max?: Maybe<SortDirection>;
  documentation?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  profile?: Maybe<SortDirection>;
};

export type ParameterDefinitionUpdateInput = {
  min?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Code']>;
  use?: Maybe<Scalars['Code']>;
  max?: Maybe<Scalars['String']>;
  documentation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Code']>;
  profile?: Maybe<Scalars['Canonical']>;
  extension?: Maybe<Array<ParameterDefinitionExtensionUpdateFieldInput>>;
  _name?: Maybe<ParameterDefinition_NameUpdateFieldInput>;
  _use?: Maybe<ParameterDefinition_UseUpdateFieldInput>;
  _min?: Maybe<ParameterDefinition_MinUpdateFieldInput>;
  _max?: Maybe<ParameterDefinition_MaxUpdateFieldInput>;
  _documentation?: Maybe<ParameterDefinition_DocumentationUpdateFieldInput>;
  _type?: Maybe<ParameterDefinition_TypeUpdateFieldInput>;
};

export type ParameterDefinitionWhere = {
  OR?: Maybe<Array<ParameterDefinitionWhere>>;
  AND?: Maybe<Array<ParameterDefinitionWhere>>;
  name?: Maybe<Scalars['Code']>;
  use?: Maybe<Scalars['Code']>;
  max?: Maybe<Scalars['String']>;
  documentation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Code']>;
  profile?: Maybe<Scalars['Canonical']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  min?: Maybe<Scalars['Int']>;
  min_NOT?: Maybe<Scalars['Int']>;
  min_IN?: Maybe<Array<Maybe<Scalars['Int']>>>;
  min_NOT_IN?: Maybe<Array<Maybe<Scalars['Int']>>>;
  min_LT?: Maybe<Scalars['Int']>;
  min_LTE?: Maybe<Scalars['Int']>;
  min_GT?: Maybe<Scalars['Int']>;
  min_GTE?: Maybe<Scalars['Int']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  _use?: Maybe<ElementWhere>;
  _use_NOT?: Maybe<ElementWhere>;
  _use_IN?: Maybe<Array<ElementWhere>>;
  _use_NOT_IN?: Maybe<Array<ElementWhere>>;
  _min?: Maybe<ElementWhere>;
  _min_NOT?: Maybe<ElementWhere>;
  _min_IN?: Maybe<Array<ElementWhere>>;
  _min_NOT_IN?: Maybe<Array<ElementWhere>>;
  _max?: Maybe<ElementWhere>;
  _max_NOT?: Maybe<ElementWhere>;
  _max_IN?: Maybe<Array<ElementWhere>>;
  _max_NOT_IN?: Maybe<Array<ElementWhere>>;
  _documentation?: Maybe<ElementWhere>;
  _documentation_NOT?: Maybe<ElementWhere>;
  _documentation_IN?: Maybe<Array<ElementWhere>>;
  _documentation_NOT_IN?: Maybe<Array<ElementWhere>>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type ParameterDefinition_DocumentationDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ParameterDefinition_DocumentationFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ParameterDefinition_DocumentationUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type ParameterDefinition_MaxDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ParameterDefinition_MaxFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ParameterDefinition_MaxUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type ParameterDefinition_MinDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ParameterDefinition_MinFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ParameterDefinition_MinUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type ParameterDefinition_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ParameterDefinition_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ParameterDefinition_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type ParameterDefinition_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ParameterDefinition_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ParameterDefinition_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type ParameterDefinition_UseDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ParameterDefinition_UseFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ParameterDefinition_UseUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient = {
  __typename?: 'Patient';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /**
   * Whether this patient record is in active use.
   * Many systems use this property to mark as non-current patients, such as those that have not been seen for a period of time based on an organization's business rules.
   *
   * It is often used to filter patient lists to exclude inactive patients
   *
   * Deceased patients may also be marked as inactive for the same reasons, but may be active for some time after death.
   */
  active?: Maybe<Scalars['Boolean']>;
  /** Indicates if the individual is deceased or not. */
  deceasedBoolean?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the patient is part of a multiple (boolean) or indicates the actual birth order (integer). */
  multipleBirthBoolean?: Maybe<Scalars['Boolean']>;
  /** This is a Patient resource */
  resourceType: PatientResourceType;
  /** Administrative Gender - the gender that the patient is considered to have for administration and record keeping purposes. */
  gender?: Maybe<PatientGender>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** The date of birth for the individual. */
  birthDate?: Maybe<Scalars['Date']>;
  /** Indicates if the individual is deceased or not. */
  deceasedDateTime?: Maybe<Scalars['String']>;
  /** Indicates whether the patient is part of a multiple (boolean) or indicates the actual birth order (integer). */
  multipleBirthInteger?: Maybe<Scalars['Number']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _active?: Maybe<Element>;
  name: Array<HumanName>;
  telecom: Array<ContactPoint>;
  _gender?: Maybe<Element>;
  _birthDate?: Maybe<Element>;
  _deceasedBoolean?: Maybe<Element>;
  _deceasedDateTime?: Maybe<Element>;
  address: Array<Address>;
  maritalStatus?: Maybe<CodeableConcept>;
  _multipleBirthBoolean?: Maybe<Element>;
  _multipleBirthInteger?: Maybe<Element>;
  photo: Array<Attachment>;
  contact: Array<PatientContact>;
  communication: Array<PatientCommunication>;
  generalPractitioner: Array<Reference>;
  managingOrganization?: Maybe<Reference>;
  link: Array<PatientLink>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient_ActiveArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientNameArgs = {
  where?: Maybe<HumanNameWhere>;
  options?: Maybe<HumanNameOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient_GenderArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient_BirthDateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient_DeceasedBooleanArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient_DeceasedDateTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientAddressArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientMaritalStatusArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient_MultipleBirthBooleanArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type Patient_MultipleBirthIntegerArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientPhotoArgs = {
  where?: Maybe<AttachmentWhere>;
  options?: Maybe<AttachmentOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContactArgs = {
  where?: Maybe<PatientContactWhere>;
  options?: Maybe<PatientContactOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientCommunicationArgs = {
  where?: Maybe<PatientCommunicationWhere>;
  options?: Maybe<PatientCommunicationOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientGeneralPractitionerArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientManagingOrganizationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientLinkArgs = {
  where?: Maybe<PatientLinkWhere>;
  options?: Maybe<PatientLinkOptions>;
};

export type PatientAddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type PatientAddressFieldInput = {
  create?: Maybe<Array<AddressCreateInput>>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
};

export type PatientAddressUpdateFieldInput = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
  disconnect?: Maybe<Array<AddressDisconnectFieldInput>>;
  create?: Maybe<Array<AddressCreateInput>>;
  delete?: Maybe<Array<AddressDeleteFieldInput>>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientCommunication = {
  __typename?: 'PatientCommunication';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Indicates whether or not the patient prefers this language (over other languages he masters up a certain level). */
  preferred?: Maybe<Scalars['Boolean']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  language: CodeableConcept;
  _preferred?: Maybe<Element>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientCommunicationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientCommunicationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientCommunicationLanguageArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientCommunication_PreferredArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type PatientCommunicationConnectFieldInput = {
  where?: Maybe<PatientCommunicationWhere>;
  connect?: Maybe<PatientCommunicationConnectInput>;
};

export type PatientCommunicationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  language?: Maybe<CodeableConceptConnectFieldInput>;
  _preferred?: Maybe<ElementConnectFieldInput>;
};

export type PatientCommunicationCreateInput = {
  preferred?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<PatientCommunicationExtensionFieldInput>;
  modifierExtension?: Maybe<PatientCommunicationModifierextensionFieldInput>;
  language?: Maybe<PatientCommunicationLanguageFieldInput>;
  _preferred?: Maybe<PatientCommunication_PreferredFieldInput>;
};

export type PatientCommunicationDeleteFieldInput = {
  where?: Maybe<PatientCommunicationWhere>;
  delete?: Maybe<PatientCommunicationDeleteInput>;
};

export type PatientCommunicationDeleteInput = {
  extension?: Maybe<Array<PatientCommunicationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PatientCommunicationModifierextensionDeleteFieldInput>>;
  language?: Maybe<PatientCommunicationLanguageDeleteFieldInput>;
  _preferred?: Maybe<PatientCommunication_PreferredDeleteFieldInput>;
};

export type PatientCommunicationDisconnectFieldInput = {
  where?: Maybe<PatientCommunicationWhere>;
  disconnect?: Maybe<PatientCommunicationDisconnectInput>;
};

export type PatientCommunicationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  language?: Maybe<CodeableConceptDisconnectFieldInput>;
  _preferred?: Maybe<ElementDisconnectFieldInput>;
};

export type PatientCommunicationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PatientCommunicationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PatientCommunicationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PatientCommunicationFieldInput = {
  create?: Maybe<Array<PatientCommunicationCreateInput>>;
  connect?: Maybe<Array<PatientCommunicationConnectFieldInput>>;
};

export type PatientCommunicationLanguageDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type PatientCommunicationLanguageFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type PatientCommunicationLanguageUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type PatientCommunicationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PatientCommunicationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PatientCommunicationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PatientCommunicationOptions = {
  /** Specify one or more PatientCommunicationSort objects to sort PatientCommunications by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PatientCommunicationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PatientCommunicationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  language?: Maybe<CodeableConceptCreateInput>;
  _preferred?: Maybe<ElementCreateInput>;
};

/** Fields to sort PatientCommunications by. The order in which sorts are applied is not guaranteed when specifying many fields in one PatientCommunicationSort object. */
export type PatientCommunicationSort = {
  id?: Maybe<SortDirection>;
  preferred?: Maybe<SortDirection>;
};

export type PatientCommunicationUpdateFieldInput = {
  where?: Maybe<PatientCommunicationWhere>;
  update?: Maybe<PatientCommunicationUpdateInput>;
  connect?: Maybe<Array<PatientCommunicationConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientCommunicationDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCommunicationCreateInput>>;
  delete?: Maybe<Array<PatientCommunicationDeleteFieldInput>>;
};

export type PatientCommunicationUpdateInput = {
  preferred?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Array<PatientCommunicationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PatientCommunicationModifierextensionUpdateFieldInput>>;
  language?: Maybe<PatientCommunicationLanguageUpdateFieldInput>;
  _preferred?: Maybe<PatientCommunication_PreferredUpdateFieldInput>;
};

export type PatientCommunicationWhere = {
  OR?: Maybe<Array<PatientCommunicationWhere>>;
  AND?: Maybe<Array<PatientCommunicationWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  preferred?: Maybe<Scalars['Boolean']>;
  preferred_NOT?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  language?: Maybe<CodeableConceptWhere>;
  language_NOT?: Maybe<CodeableConceptWhere>;
  language_IN?: Maybe<Array<CodeableConceptWhere>>;
  language_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _preferred?: Maybe<ElementWhere>;
  _preferred_NOT?: Maybe<ElementWhere>;
  _preferred_IN?: Maybe<Array<ElementWhere>>;
  _preferred_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type PatientCommunication_PreferredDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PatientCommunication_PreferredFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PatientCommunication_PreferredUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PatientConnectFieldInput = {
  where?: Maybe<PatientWhere>;
  connect?: Maybe<PatientConnectInput>;
};

export type PatientConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _active?: Maybe<ElementConnectFieldInput>;
  name?: Maybe<Array<HumanNameConnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  _gender?: Maybe<ElementConnectFieldInput>;
  _birthDate?: Maybe<ElementConnectFieldInput>;
  _deceasedBoolean?: Maybe<ElementConnectFieldInput>;
  _deceasedDateTime?: Maybe<ElementConnectFieldInput>;
  address?: Maybe<Array<AddressConnectFieldInput>>;
  maritalStatus?: Maybe<CodeableConceptConnectFieldInput>;
  _multipleBirthBoolean?: Maybe<ElementConnectFieldInput>;
  _multipleBirthInteger?: Maybe<ElementConnectFieldInput>;
  photo?: Maybe<Array<AttachmentConnectFieldInput>>;
  contact?: Maybe<Array<PatientContactConnectFieldInput>>;
  communication?: Maybe<Array<PatientCommunicationConnectFieldInput>>;
  generalPractitioner?: Maybe<Array<ReferenceConnectFieldInput>>;
  managingOrganization?: Maybe<ReferenceConnectFieldInput>;
  link?: Maybe<Array<PatientLinkConnectFieldInput>>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContact = {
  __typename?: 'PatientContact';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Administrative Gender - the gender that the contact person is considered to have for administration and record keeping purposes. */
  gender?: Maybe<PatientContactGender>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  relationship: Array<CodeableConcept>;
  name?: Maybe<HumanName>;
  telecom: Array<ContactPoint>;
  address?: Maybe<Address>;
  _gender?: Maybe<Element>;
  organization?: Maybe<Reference>;
  period?: Maybe<Period>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContactExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContactModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContactRelationshipArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContactNameArgs = {
  where?: Maybe<HumanNameWhere>;
  options?: Maybe<HumanNameOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContactTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContactAddressArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContact_GenderArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContactOrganizationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientContactPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type PatientContactAddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type PatientContactAddressFieldInput = {
  create?: Maybe<AddressCreateInput>;
  connect?: Maybe<AddressConnectFieldInput>;
};

export type PatientContactAddressUpdateFieldInput = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<AddressConnectFieldInput>;
  disconnect?: Maybe<AddressDisconnectFieldInput>;
  create?: Maybe<AddressCreateInput>;
  delete?: Maybe<AddressDeleteFieldInput>;
};

export type PatientContactConnectFieldInput = {
  where?: Maybe<PatientContactWhere>;
  connect?: Maybe<PatientContactConnectInput>;
};

export type PatientContactConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  relationship?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  name?: Maybe<HumanNameConnectFieldInput>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  address?: Maybe<AddressConnectFieldInput>;
  _gender?: Maybe<ElementConnectFieldInput>;
  organization?: Maybe<ReferenceConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
};

export type PatientContactCreateInput = {
  gender?: Maybe<PatientContactGender>;
  extension?: Maybe<PatientContactExtensionFieldInput>;
  modifierExtension?: Maybe<PatientContactModifierextensionFieldInput>;
  relationship?: Maybe<PatientContactRelationshipFieldInput>;
  name?: Maybe<PatientContactNameFieldInput>;
  telecom?: Maybe<PatientContactTelecomFieldInput>;
  address?: Maybe<PatientContactAddressFieldInput>;
  _gender?: Maybe<PatientContact_GenderFieldInput>;
  organization?: Maybe<PatientContactOrganizationFieldInput>;
  period?: Maybe<PatientContactPeriodFieldInput>;
};

export type PatientContactDeleteFieldInput = {
  where?: Maybe<PatientContactWhere>;
  delete?: Maybe<PatientContactDeleteInput>;
};

export type PatientContactDeleteInput = {
  extension?: Maybe<Array<PatientContactExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PatientContactModifierextensionDeleteFieldInput>>;
  relationship?: Maybe<Array<PatientContactRelationshipDeleteFieldInput>>;
  name?: Maybe<PatientContactNameDeleteFieldInput>;
  telecom?: Maybe<Array<PatientContactTelecomDeleteFieldInput>>;
  address?: Maybe<PatientContactAddressDeleteFieldInput>;
  _gender?: Maybe<PatientContact_GenderDeleteFieldInput>;
  organization?: Maybe<PatientContactOrganizationDeleteFieldInput>;
  period?: Maybe<PatientContactPeriodDeleteFieldInput>;
};

export type PatientContactDisconnectFieldInput = {
  where?: Maybe<PatientContactWhere>;
  disconnect?: Maybe<PatientContactDisconnectInput>;
};

export type PatientContactDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  relationship?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  name?: Maybe<HumanNameDisconnectFieldInput>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  address?: Maybe<AddressDisconnectFieldInput>;
  _gender?: Maybe<ElementDisconnectFieldInput>;
  organization?: Maybe<ReferenceDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
};

export type PatientContactExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PatientContactExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PatientContactExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PatientContactFieldInput = {
  create?: Maybe<Array<PatientContactCreateInput>>;
  connect?: Maybe<Array<PatientContactConnectFieldInput>>;
};

/** Administrative Gender - the gender that the contact person is considered to have for administration and record keeping purposes. */
export enum PatientContactGender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
  Unknown = 'unknown',
}

export type PatientContactModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PatientContactModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PatientContactModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PatientContactNameDeleteFieldInput = {
  where?: Maybe<HumanNameWhere>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type PatientContactNameFieldInput = {
  create?: Maybe<HumanNameCreateInput>;
  connect?: Maybe<HumanNameConnectFieldInput>;
};

export type PatientContactNameUpdateFieldInput = {
  where?: Maybe<HumanNameWhere>;
  update?: Maybe<HumanNameUpdateInput>;
  connect?: Maybe<HumanNameConnectFieldInput>;
  disconnect?: Maybe<HumanNameDisconnectFieldInput>;
  create?: Maybe<HumanNameCreateInput>;
  delete?: Maybe<HumanNameDeleteFieldInput>;
};

export type PatientContactOptions = {
  /** Specify one or more PatientContactSort objects to sort PatientContacts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PatientContactSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PatientContactOrganizationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PatientContactOrganizationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type PatientContactOrganizationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type PatientContactPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type PatientContactPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type PatientContactPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type PatientContactRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  relationship?: Maybe<Array<CodeableConceptCreateInput>>;
  name?: Maybe<HumanNameCreateInput>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  address?: Maybe<AddressCreateInput>;
  _gender?: Maybe<ElementCreateInput>;
  organization?: Maybe<ReferenceCreateInput>;
  period?: Maybe<PeriodCreateInput>;
};

export type PatientContactRelationshipDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type PatientContactRelationshipFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type PatientContactRelationshipUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

/** Fields to sort PatientContacts by. The order in which sorts are applied is not guaranteed when specifying many fields in one PatientContactSort object. */
export type PatientContactSort = {
  id?: Maybe<SortDirection>;
  gender?: Maybe<SortDirection>;
};

export type PatientContactTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type PatientContactTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type PatientContactTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type PatientContactUpdateFieldInput = {
  where?: Maybe<PatientContactWhere>;
  update?: Maybe<PatientContactUpdateInput>;
  connect?: Maybe<Array<PatientContactConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientContactDisconnectFieldInput>>;
  create?: Maybe<Array<PatientContactCreateInput>>;
  delete?: Maybe<Array<PatientContactDeleteFieldInput>>;
};

export type PatientContactUpdateInput = {
  gender?: Maybe<PatientContactGender>;
  extension?: Maybe<Array<PatientContactExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PatientContactModifierextensionUpdateFieldInput>>;
  relationship?: Maybe<Array<PatientContactRelationshipUpdateFieldInput>>;
  name?: Maybe<PatientContactNameUpdateFieldInput>;
  telecom?: Maybe<Array<PatientContactTelecomUpdateFieldInput>>;
  address?: Maybe<PatientContactAddressUpdateFieldInput>;
  _gender?: Maybe<PatientContact_GenderUpdateFieldInput>;
  organization?: Maybe<PatientContactOrganizationUpdateFieldInput>;
  period?: Maybe<PatientContactPeriodUpdateFieldInput>;
};

export type PatientContactWhere = {
  OR?: Maybe<Array<PatientContactWhere>>;
  AND?: Maybe<Array<PatientContactWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  gender?: Maybe<PatientContactGender>;
  gender_NOT?: Maybe<PatientContactGender>;
  gender_IN?: Maybe<Array<Maybe<PatientContactGender>>>;
  gender_NOT_IN?: Maybe<Array<Maybe<PatientContactGender>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  relationship?: Maybe<CodeableConceptWhere>;
  relationship_NOT?: Maybe<CodeableConceptWhere>;
  name?: Maybe<HumanNameWhere>;
  name_NOT?: Maybe<HumanNameWhere>;
  name_IN?: Maybe<Array<HumanNameWhere>>;
  name_NOT_IN?: Maybe<Array<HumanNameWhere>>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  address?: Maybe<AddressWhere>;
  address_NOT?: Maybe<AddressWhere>;
  address_IN?: Maybe<Array<AddressWhere>>;
  address_NOT_IN?: Maybe<Array<AddressWhere>>;
  _gender?: Maybe<ElementWhere>;
  _gender_NOT?: Maybe<ElementWhere>;
  _gender_IN?: Maybe<Array<ElementWhere>>;
  _gender_NOT_IN?: Maybe<Array<ElementWhere>>;
  organization?: Maybe<ReferenceWhere>;
  organization_NOT?: Maybe<ReferenceWhere>;
  organization_IN?: Maybe<Array<ReferenceWhere>>;
  organization_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type PatientContact_GenderDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PatientContact_GenderFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PatientContact_GenderUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PatientContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type PatientContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type PatientContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type PatientContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type PatientContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type PatientContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type PatientContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type PatientContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type PatientContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type PatientContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type PatientContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type PatientContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type PatientContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type PatientContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type PatientContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type PatientContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type PatientContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type PatientContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type PatientContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type PatientContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type PatientContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type PatientContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type PatientContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type PatientContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type PatientContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type PatientContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type PatientContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type PatientContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type PatientContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type PatientContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type PatientContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type PatientContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type PatientContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type PatientContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type PatientContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type PatientContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type PatientContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type PatientContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type PatientContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type PatientContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type PatientContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type PatientContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type PatientContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type PatientContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type PatientContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type PatientContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type PatientContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type PatientContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type PatientContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type PatientContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type PatientContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type PatientContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type PatientContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type PatientContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type PatientContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type PatientContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type PatientContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type PatientContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type PatientContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type PatientContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type PatientContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type PatientContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type PatientContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type PatientCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  deceasedBoolean?: Maybe<Scalars['Boolean']>;
  multipleBirthBoolean?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  deceasedDateTime?: Maybe<Scalars['String']>;
  multipleBirthInteger?: Maybe<Scalars['Number']>;
  resourceType: PatientResourceType;
  gender?: Maybe<PatientGender>;
  meta?: Maybe<PatientMetaFieldInput>;
  _implicitRules?: Maybe<Patient_ImplicitrulesFieldInput>;
  _language?: Maybe<Patient_LanguageFieldInput>;
  text?: Maybe<PatientTextFieldInput>;
  contained_Account?: Maybe<PatientContainedAccountFieldInput>;
  contained_Appointment?: Maybe<PatientContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<PatientContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<PatientContainedCoverageFieldInput>;
  contained_Device?: Maybe<PatientContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<PatientContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<PatientContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<PatientContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<PatientContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<PatientContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<PatientContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<PatientContainedLocationFieldInput>;
  contained_Organization?: Maybe<PatientContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<PatientContainedPatientFieldInput>;
  contained_Person?: Maybe<PatientContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<PatientContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<PatientContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<PatientContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<PatientContainedScheduleFieldInput>;
  contained_Slot?: Maybe<PatientContainedSlotFieldInput>;
  contained_Substance?: Maybe<PatientContainedSubstanceFieldInput>;
  extension?: Maybe<PatientExtensionFieldInput>;
  modifierExtension?: Maybe<PatientModifierextensionFieldInput>;
  identifier?: Maybe<PatientIdentifierFieldInput>;
  _active?: Maybe<Patient_ActiveFieldInput>;
  name?: Maybe<PatientNameFieldInput>;
  telecom?: Maybe<PatientTelecomFieldInput>;
  _gender?: Maybe<Patient_GenderFieldInput>;
  _birthDate?: Maybe<Patient_BirthdateFieldInput>;
  _deceasedBoolean?: Maybe<Patient_DeceasedbooleanFieldInput>;
  _deceasedDateTime?: Maybe<Patient_DeceaseddatetimeFieldInput>;
  address?: Maybe<PatientAddressFieldInput>;
  maritalStatus?: Maybe<PatientMaritalstatusFieldInput>;
  _multipleBirthBoolean?: Maybe<Patient_MultiplebirthbooleanFieldInput>;
  _multipleBirthInteger?: Maybe<Patient_MultiplebirthintegerFieldInput>;
  photo?: Maybe<PatientPhotoFieldInput>;
  contact?: Maybe<PatientContactFieldInput>;
  communication?: Maybe<PatientCommunicationFieldInput>;
  generalPractitioner?: Maybe<PatientGeneralpractitionerFieldInput>;
  managingOrganization?: Maybe<PatientManagingorganizationFieldInput>;
  link?: Maybe<PatientLinkFieldInput>;
};

export type PatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type PatientDeleteInput = {
  meta?: Maybe<PatientMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Patient_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Patient_LanguageDeleteFieldInput>;
  text?: Maybe<PatientTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<PatientContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<PatientContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<PatientContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<PatientContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<PatientContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<PatientContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<PatientContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<PatientContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<PatientContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<PatientContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<PatientContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<PatientContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<PatientContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<PatientContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<PatientContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<PatientContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PatientContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<PatientContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<PatientContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<PatientContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<PatientContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<PatientExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PatientModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<PatientIdentifierDeleteFieldInput>>;
  _active?: Maybe<Patient_ActiveDeleteFieldInput>;
  name?: Maybe<Array<PatientNameDeleteFieldInput>>;
  telecom?: Maybe<Array<PatientTelecomDeleteFieldInput>>;
  _gender?: Maybe<Patient_GenderDeleteFieldInput>;
  _birthDate?: Maybe<Patient_BirthdateDeleteFieldInput>;
  _deceasedBoolean?: Maybe<Patient_DeceasedbooleanDeleteFieldInput>;
  _deceasedDateTime?: Maybe<Patient_DeceaseddatetimeDeleteFieldInput>;
  address?: Maybe<Array<PatientAddressDeleteFieldInput>>;
  maritalStatus?: Maybe<PatientMaritalstatusDeleteFieldInput>;
  _multipleBirthBoolean?: Maybe<Patient_MultiplebirthbooleanDeleteFieldInput>;
  _multipleBirthInteger?: Maybe<Patient_MultiplebirthintegerDeleteFieldInput>;
  photo?: Maybe<Array<PatientPhotoDeleteFieldInput>>;
  contact?: Maybe<Array<PatientContactDeleteFieldInput>>;
  communication?: Maybe<Array<PatientCommunicationDeleteFieldInput>>;
  generalPractitioner?: Maybe<Array<PatientGeneralpractitionerDeleteFieldInput>>;
  managingOrganization?: Maybe<PatientManagingorganizationDeleteFieldInput>;
  link?: Maybe<Array<PatientLinkDeleteFieldInput>>;
};

export type PatientDisconnectFieldInput = {
  where?: Maybe<PatientWhere>;
  disconnect?: Maybe<PatientDisconnectInput>;
};

export type PatientDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _active?: Maybe<ElementDisconnectFieldInput>;
  name?: Maybe<Array<HumanNameDisconnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  _gender?: Maybe<ElementDisconnectFieldInput>;
  _birthDate?: Maybe<ElementDisconnectFieldInput>;
  _deceasedBoolean?: Maybe<ElementDisconnectFieldInput>;
  _deceasedDateTime?: Maybe<ElementDisconnectFieldInput>;
  address?: Maybe<Array<AddressDisconnectFieldInput>>;
  maritalStatus?: Maybe<CodeableConceptDisconnectFieldInput>;
  _multipleBirthBoolean?: Maybe<ElementDisconnectFieldInput>;
  _multipleBirthInteger?: Maybe<ElementDisconnectFieldInput>;
  photo?: Maybe<Array<AttachmentDisconnectFieldInput>>;
  contact?: Maybe<Array<PatientContactDisconnectFieldInput>>;
  communication?: Maybe<Array<PatientCommunicationDisconnectFieldInput>>;
  generalPractitioner?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  managingOrganization?: Maybe<ReferenceDisconnectFieldInput>;
  link?: Maybe<Array<PatientLinkDisconnectFieldInput>>;
};

export type PatientExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PatientExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PatientExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** Administrative Gender - the gender that the patient is considered to have for administration and record keeping purposes. */
export enum PatientGender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
  Unknown = 'unknown',
}

export type PatientGeneralpractitionerDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PatientGeneralpractitionerFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type PatientGeneralpractitionerUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type PatientIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type PatientIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type PatientIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientLink = {
  __typename?: 'PatientLink';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The type of link between this patient resource and another patient resource. */
  type?: Maybe<PatientLinkType>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  other: Reference;
  _type?: Maybe<Element>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientLinkExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientLinkModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientLinkOtherArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
export type PatientLink_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type PatientLinkConnectFieldInput = {
  where?: Maybe<PatientLinkWhere>;
  connect?: Maybe<PatientLinkConnectInput>;
};

export type PatientLinkConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  other?: Maybe<ReferenceConnectFieldInput>;
  _type?: Maybe<ElementConnectFieldInput>;
};

export type PatientLinkCreateInput = {
  type?: Maybe<PatientLinkType>;
  extension?: Maybe<PatientLinkExtensionFieldInput>;
  modifierExtension?: Maybe<PatientLinkModifierextensionFieldInput>;
  other?: Maybe<PatientLinkOtherFieldInput>;
  _type?: Maybe<PatientLink_TypeFieldInput>;
};

export type PatientLinkDeleteFieldInput = {
  where?: Maybe<PatientLinkWhere>;
  delete?: Maybe<PatientLinkDeleteInput>;
};

export type PatientLinkDeleteInput = {
  extension?: Maybe<Array<PatientLinkExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PatientLinkModifierextensionDeleteFieldInput>>;
  other?: Maybe<PatientLinkOtherDeleteFieldInput>;
  _type?: Maybe<PatientLink_TypeDeleteFieldInput>;
};

export type PatientLinkDisconnectFieldInput = {
  where?: Maybe<PatientLinkWhere>;
  disconnect?: Maybe<PatientLinkDisconnectInput>;
};

export type PatientLinkDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  other?: Maybe<ReferenceDisconnectFieldInput>;
  _type?: Maybe<ElementDisconnectFieldInput>;
};

export type PatientLinkExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PatientLinkExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PatientLinkExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PatientLinkFieldInput = {
  create?: Maybe<Array<PatientLinkCreateInput>>;
  connect?: Maybe<Array<PatientLinkConnectFieldInput>>;
};

export type PatientLinkModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PatientLinkModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PatientLinkModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PatientLinkOptions = {
  /** Specify one or more PatientLinkSort objects to sort PatientLinks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PatientLinkSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PatientLinkOtherDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PatientLinkOtherFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type PatientLinkOtherUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type PatientLinkRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  other?: Maybe<ReferenceCreateInput>;
  _type?: Maybe<ElementCreateInput>;
};

/** Fields to sort PatientLinks by. The order in which sorts are applied is not guaranteed when specifying many fields in one PatientLinkSort object. */
export type PatientLinkSort = {
  id?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
};

/** The type of link between this patient resource and another patient resource. */
export enum PatientLinkType {
  ReplacedBy = 'replacedBy',
  Replaces = 'replaces',
  Refer = 'refer',
  Seealso = 'seealso',
}

export type PatientLinkUpdateFieldInput = {
  where?: Maybe<PatientLinkWhere>;
  update?: Maybe<PatientLinkUpdateInput>;
  connect?: Maybe<Array<PatientLinkConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientLinkDisconnectFieldInput>>;
  create?: Maybe<Array<PatientLinkCreateInput>>;
  delete?: Maybe<Array<PatientLinkDeleteFieldInput>>;
};

export type PatientLinkUpdateInput = {
  type?: Maybe<PatientLinkType>;
  extension?: Maybe<Array<PatientLinkExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PatientLinkModifierextensionUpdateFieldInput>>;
  other?: Maybe<PatientLinkOtherUpdateFieldInput>;
  _type?: Maybe<PatientLink_TypeUpdateFieldInput>;
};

export type PatientLinkWhere = {
  OR?: Maybe<Array<PatientLinkWhere>>;
  AND?: Maybe<Array<PatientLinkWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  type?: Maybe<PatientLinkType>;
  type_NOT?: Maybe<PatientLinkType>;
  type_IN?: Maybe<Array<Maybe<PatientLinkType>>>;
  type_NOT_IN?: Maybe<Array<Maybe<PatientLinkType>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  other?: Maybe<ReferenceWhere>;
  other_NOT?: Maybe<ReferenceWhere>;
  other_IN?: Maybe<Array<ReferenceWhere>>;
  other_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type PatientLink_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PatientLink_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PatientLink_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PatientManagingorganizationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PatientManagingorganizationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type PatientManagingorganizationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type PatientMaritalstatusDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type PatientMaritalstatusFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type PatientMaritalstatusUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type PatientMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type PatientMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type PatientMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type PatientModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PatientModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PatientModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PatientNameDeleteFieldInput = {
  where?: Maybe<HumanNameWhere>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type PatientNameFieldInput = {
  create?: Maybe<Array<HumanNameCreateInput>>;
  connect?: Maybe<Array<HumanNameConnectFieldInput>>;
};

export type PatientNameUpdateFieldInput = {
  where?: Maybe<HumanNameWhere>;
  update?: Maybe<HumanNameUpdateInput>;
  connect?: Maybe<Array<HumanNameConnectFieldInput>>;
  disconnect?: Maybe<Array<HumanNameDisconnectFieldInput>>;
  create?: Maybe<Array<HumanNameCreateInput>>;
  delete?: Maybe<Array<HumanNameDeleteFieldInput>>;
};

export type PatientOptions = {
  /** Specify one or more PatientSort objects to sort Patients by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PatientSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PatientPhotoDeleteFieldInput = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type PatientPhotoFieldInput = {
  create?: Maybe<Array<AttachmentCreateInput>>;
  connect?: Maybe<Array<AttachmentConnectFieldInput>>;
};

export type PatientPhotoUpdateFieldInput = {
  where?: Maybe<AttachmentWhere>;
  update?: Maybe<AttachmentUpdateInput>;
  connect?: Maybe<Array<AttachmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AttachmentDisconnectFieldInput>>;
  create?: Maybe<Array<AttachmentCreateInput>>;
  delete?: Maybe<Array<AttachmentDeleteFieldInput>>;
};

export type PatientRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _active?: Maybe<ElementCreateInput>;
  name?: Maybe<Array<HumanNameCreateInput>>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  _gender?: Maybe<ElementCreateInput>;
  _birthDate?: Maybe<ElementCreateInput>;
  _deceasedBoolean?: Maybe<ElementCreateInput>;
  _deceasedDateTime?: Maybe<ElementCreateInput>;
  address?: Maybe<Array<AddressCreateInput>>;
  maritalStatus?: Maybe<CodeableConceptCreateInput>;
  _multipleBirthBoolean?: Maybe<ElementCreateInput>;
  _multipleBirthInteger?: Maybe<ElementCreateInput>;
  photo?: Maybe<Array<AttachmentCreateInput>>;
  contact?: Maybe<Array<PatientContactCreateInput>>;
  communication?: Maybe<Array<PatientCommunicationCreateInput>>;
  generalPractitioner?: Maybe<Array<ReferenceCreateInput>>;
  managingOrganization?: Maybe<ReferenceCreateInput>;
  link?: Maybe<Array<PatientLinkCreateInput>>;
};

export enum PatientResourceType {
  Patient = 'Patient',
}

/** Fields to sort Patients by. The order in which sorts are applied is not guaranteed when specifying many fields in one PatientSort object. */
export type PatientSort = {
  id?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  deceasedBoolean?: Maybe<SortDirection>;
  multipleBirthBoolean?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  gender?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  birthDate?: Maybe<SortDirection>;
  deceasedDateTime?: Maybe<SortDirection>;
  multipleBirthInteger?: Maybe<SortDirection>;
};

export type PatientTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type PatientTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type PatientTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type PatientTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type PatientTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type PatientTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type PatientUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  deceasedBoolean?: Maybe<Scalars['Boolean']>;
  multipleBirthBoolean?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  deceasedDateTime?: Maybe<Scalars['String']>;
  multipleBirthInteger?: Maybe<Scalars['Number']>;
  resourceType?: Maybe<PatientResourceType>;
  gender?: Maybe<PatientGender>;
  meta?: Maybe<PatientMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Patient_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Patient_LanguageUpdateFieldInput>;
  text?: Maybe<PatientTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<PatientContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<PatientContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<PatientContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<PatientContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<PatientContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<PatientContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<PatientContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<PatientContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<PatientContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<PatientContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<PatientContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<PatientContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<PatientContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<PatientContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<PatientContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<PatientContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PatientContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<PatientContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<PatientContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<PatientContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<PatientContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<PatientExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PatientModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<PatientIdentifierUpdateFieldInput>>;
  _active?: Maybe<Patient_ActiveUpdateFieldInput>;
  name?: Maybe<Array<PatientNameUpdateFieldInput>>;
  telecom?: Maybe<Array<PatientTelecomUpdateFieldInput>>;
  _gender?: Maybe<Patient_GenderUpdateFieldInput>;
  _birthDate?: Maybe<Patient_BirthdateUpdateFieldInput>;
  _deceasedBoolean?: Maybe<Patient_DeceasedbooleanUpdateFieldInput>;
  _deceasedDateTime?: Maybe<Patient_DeceaseddatetimeUpdateFieldInput>;
  address?: Maybe<Array<PatientAddressUpdateFieldInput>>;
  maritalStatus?: Maybe<PatientMaritalstatusUpdateFieldInput>;
  _multipleBirthBoolean?: Maybe<Patient_MultiplebirthbooleanUpdateFieldInput>;
  _multipleBirthInteger?: Maybe<Patient_MultiplebirthintegerUpdateFieldInput>;
  photo?: Maybe<Array<PatientPhotoUpdateFieldInput>>;
  contact?: Maybe<Array<PatientContactUpdateFieldInput>>;
  communication?: Maybe<Array<PatientCommunicationUpdateFieldInput>>;
  generalPractitioner?: Maybe<Array<PatientGeneralpractitionerUpdateFieldInput>>;
  managingOrganization?: Maybe<PatientManagingorganizationUpdateFieldInput>;
  link?: Maybe<Array<PatientLinkUpdateFieldInput>>;
};

export type PatientWhere = {
  OR?: Maybe<Array<PatientWhere>>;
  AND?: Maybe<Array<PatientWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  deceasedDateTime?: Maybe<Scalars['String']>;
  multipleBirthInteger?: Maybe<Scalars['Number']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_NOT?: Maybe<Scalars['Boolean']>;
  deceasedBoolean?: Maybe<Scalars['Boolean']>;
  deceasedBoolean_NOT?: Maybe<Scalars['Boolean']>;
  multipleBirthBoolean?: Maybe<Scalars['Boolean']>;
  multipleBirthBoolean_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<PatientResourceType>;
  resourceType_NOT?: Maybe<PatientResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<PatientResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<PatientResourceType>>>;
  gender?: Maybe<PatientGender>;
  gender_NOT?: Maybe<PatientGender>;
  gender_IN?: Maybe<Array<Maybe<PatientGender>>>;
  gender_NOT_IN?: Maybe<Array<Maybe<PatientGender>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _active?: Maybe<ElementWhere>;
  _active_NOT?: Maybe<ElementWhere>;
  _active_IN?: Maybe<Array<ElementWhere>>;
  _active_NOT_IN?: Maybe<Array<ElementWhere>>;
  name?: Maybe<HumanNameWhere>;
  name_NOT?: Maybe<HumanNameWhere>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  _gender?: Maybe<ElementWhere>;
  _gender_NOT?: Maybe<ElementWhere>;
  _gender_IN?: Maybe<Array<ElementWhere>>;
  _gender_NOT_IN?: Maybe<Array<ElementWhere>>;
  _birthDate?: Maybe<ElementWhere>;
  _birthDate_NOT?: Maybe<ElementWhere>;
  _birthDate_IN?: Maybe<Array<ElementWhere>>;
  _birthDate_NOT_IN?: Maybe<Array<ElementWhere>>;
  _deceasedBoolean?: Maybe<ElementWhere>;
  _deceasedBoolean_NOT?: Maybe<ElementWhere>;
  _deceasedBoolean_IN?: Maybe<Array<ElementWhere>>;
  _deceasedBoolean_NOT_IN?: Maybe<Array<ElementWhere>>;
  _deceasedDateTime?: Maybe<ElementWhere>;
  _deceasedDateTime_NOT?: Maybe<ElementWhere>;
  _deceasedDateTime_IN?: Maybe<Array<ElementWhere>>;
  _deceasedDateTime_NOT_IN?: Maybe<Array<ElementWhere>>;
  address?: Maybe<AddressWhere>;
  address_NOT?: Maybe<AddressWhere>;
  maritalStatus?: Maybe<CodeableConceptWhere>;
  maritalStatus_NOT?: Maybe<CodeableConceptWhere>;
  maritalStatus_IN?: Maybe<Array<CodeableConceptWhere>>;
  maritalStatus_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _multipleBirthBoolean?: Maybe<ElementWhere>;
  _multipleBirthBoolean_NOT?: Maybe<ElementWhere>;
  _multipleBirthBoolean_IN?: Maybe<Array<ElementWhere>>;
  _multipleBirthBoolean_NOT_IN?: Maybe<Array<ElementWhere>>;
  _multipleBirthInteger?: Maybe<ElementWhere>;
  _multipleBirthInteger_NOT?: Maybe<ElementWhere>;
  _multipleBirthInteger_IN?: Maybe<Array<ElementWhere>>;
  _multipleBirthInteger_NOT_IN?: Maybe<Array<ElementWhere>>;
  photo?: Maybe<AttachmentWhere>;
  photo_NOT?: Maybe<AttachmentWhere>;
  contact?: Maybe<PatientContactWhere>;
  contact_NOT?: Maybe<PatientContactWhere>;
  communication?: Maybe<PatientCommunicationWhere>;
  communication_NOT?: Maybe<PatientCommunicationWhere>;
  generalPractitioner?: Maybe<ReferenceWhere>;
  generalPractitioner_NOT?: Maybe<ReferenceWhere>;
  managingOrganization?: Maybe<ReferenceWhere>;
  managingOrganization_NOT?: Maybe<ReferenceWhere>;
  managingOrganization_IN?: Maybe<Array<ReferenceWhere>>;
  managingOrganization_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  link?: Maybe<PatientLinkWhere>;
  link_NOT?: Maybe<PatientLinkWhere>;
};

export type Patient_ActiveDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Patient_ActiveFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Patient_ActiveUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Patient_BirthdateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Patient_BirthdateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Patient_BirthdateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Patient_DeceasedbooleanDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Patient_DeceasedbooleanFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Patient_DeceasedbooleanUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Patient_DeceaseddatetimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Patient_DeceaseddatetimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Patient_DeceaseddatetimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Patient_GenderDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Patient_GenderFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Patient_GenderUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Patient_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Patient_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Patient_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Patient_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Patient_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Patient_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Patient_MultiplebirthbooleanDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Patient_MultiplebirthbooleanFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Patient_MultiplebirthbooleanUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Patient_MultiplebirthintegerDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Patient_MultiplebirthintegerFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Patient_MultiplebirthintegerUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A time period defined by a start and end date and optionally time. */
export type Period = {
  __typename?: 'Period';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The start of the period. The boundary is inclusive. */
  start?: Maybe<Scalars['DateTime']>;
  /** The end of the period. If the end of the period is missing, it means no end was known or planned at the time the instance was created. The start may be in the past, and the end date in the future, which means that period is expected/planned to end at that time. */
  end?: Maybe<Scalars['DateTime']>;
  extension: Array<Extension>;
  _start?: Maybe<Element>;
  _end?: Maybe<Element>;
};

/** A time period defined by a start and end date and optionally time. */
export type PeriodExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A time period defined by a start and end date and optionally time. */
export type Period_StartArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A time period defined by a start and end date and optionally time. */
export type Period_EndArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type PeriodConnectFieldInput = {
  where?: Maybe<PeriodWhere>;
  connect?: Maybe<PeriodConnectInput>;
};

export type PeriodConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _start?: Maybe<ElementConnectFieldInput>;
  _end?: Maybe<ElementConnectFieldInput>;
};

export type PeriodCreateInput = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  extension?: Maybe<PeriodExtensionFieldInput>;
  _start?: Maybe<Period_StartFieldInput>;
  _end?: Maybe<Period_EndFieldInput>;
};

export type PeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type PeriodDeleteInput = {
  extension?: Maybe<Array<PeriodExtensionDeleteFieldInput>>;
  _start?: Maybe<Period_StartDeleteFieldInput>;
  _end?: Maybe<Period_EndDeleteFieldInput>;
};

export type PeriodDisconnectFieldInput = {
  where?: Maybe<PeriodWhere>;
  disconnect?: Maybe<PeriodDisconnectInput>;
};

export type PeriodDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _start?: Maybe<ElementDisconnectFieldInput>;
  _end?: Maybe<ElementDisconnectFieldInput>;
};

export type PeriodExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PeriodExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PeriodExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PeriodOptions = {
  /** Specify one or more PeriodSort objects to sort Periods by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PeriodSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PeriodRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _start?: Maybe<ElementCreateInput>;
  _end?: Maybe<ElementCreateInput>;
};

/** Fields to sort Periods by. The order in which sorts are applied is not guaranteed when specifying many fields in one PeriodSort object. */
export type PeriodSort = {
  id?: Maybe<SortDirection>;
  start?: Maybe<SortDirection>;
  end?: Maybe<SortDirection>;
};

export type PeriodUpdateInput = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  extension?: Maybe<Array<PeriodExtensionUpdateFieldInput>>;
  _start?: Maybe<Period_StartUpdateFieldInput>;
  _end?: Maybe<Period_EndUpdateFieldInput>;
};

export type PeriodWhere = {
  OR?: Maybe<Array<PeriodWhere>>;
  AND?: Maybe<Array<PeriodWhere>>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _start?: Maybe<ElementWhere>;
  _start_NOT?: Maybe<ElementWhere>;
  _start_IN?: Maybe<Array<ElementWhere>>;
  _start_NOT_IN?: Maybe<Array<ElementWhere>>;
  _end?: Maybe<ElementWhere>;
  _end_NOT?: Maybe<ElementWhere>;
  _end_IN?: Maybe<Array<ElementWhere>>;
  _end_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Period_EndDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Period_EndFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Period_EndUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Period_StartDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Period_StartFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Period_StartUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type Person = {
  __typename?: 'Person';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** Whether this person's record is in active use. */
  active?: Maybe<Scalars['Boolean']>;
  /** This is a Person resource */
  resourceType: PersonResourceType;
  /** Administrative Gender. */
  gender?: Maybe<PersonGender>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** The birth date for the person. */
  birthDate?: Maybe<Scalars['Date']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  name: Array<HumanName>;
  telecom: Array<ContactPoint>;
  _gender?: Maybe<Element>;
  _birthDate?: Maybe<Element>;
  address: Array<Address>;
  photo?: Maybe<Attachment>;
  managingOrganization?: Maybe<Reference>;
  _active?: Maybe<Element>;
  link: Array<PersonLink>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type Person_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type Person_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonNameArgs = {
  where?: Maybe<HumanNameWhere>;
  options?: Maybe<HumanNameOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type Person_GenderArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type Person_BirthDateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonAddressArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonPhotoArgs = {
  where?: Maybe<AttachmentWhere>;
  options?: Maybe<AttachmentOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonManagingOrganizationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type Person_ActiveArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonLinkArgs = {
  where?: Maybe<PersonLinkWhere>;
  options?: Maybe<PersonLinkOptions>;
};

export type PersonAddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type PersonAddressFieldInput = {
  create?: Maybe<Array<AddressCreateInput>>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
};

export type PersonAddressUpdateFieldInput = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
  disconnect?: Maybe<Array<AddressDisconnectFieldInput>>;
  create?: Maybe<Array<AddressCreateInput>>;
  delete?: Maybe<Array<AddressDeleteFieldInput>>;
};

export type PersonConnectFieldInput = {
  where?: Maybe<PersonWhere>;
  connect?: Maybe<PersonConnectInput>;
};

export type PersonConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  name?: Maybe<Array<HumanNameConnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  _gender?: Maybe<ElementConnectFieldInput>;
  _birthDate?: Maybe<ElementConnectFieldInput>;
  address?: Maybe<Array<AddressConnectFieldInput>>;
  photo?: Maybe<AttachmentConnectFieldInput>;
  managingOrganization?: Maybe<ReferenceConnectFieldInput>;
  _active?: Maybe<ElementConnectFieldInput>;
  link?: Maybe<Array<PersonLinkConnectFieldInput>>;
};

export type PersonContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type PersonContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type PersonContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type PersonContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type PersonContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type PersonContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type PersonContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type PersonContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type PersonContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type PersonContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type PersonContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type PersonContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type PersonContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type PersonContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type PersonContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type PersonContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type PersonContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type PersonContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type PersonContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type PersonContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type PersonContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type PersonContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type PersonContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type PersonContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type PersonContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type PersonContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type PersonContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type PersonContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type PersonContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type PersonContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type PersonContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type PersonContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type PersonContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type PersonContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type PersonContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type PersonContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type PersonContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type PersonContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type PersonContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type PersonContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type PersonContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type PersonContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type PersonContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type PersonContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type PersonContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type PersonContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type PersonContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type PersonContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type PersonContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type PersonContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type PersonContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type PersonContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type PersonContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type PersonContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type PersonContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type PersonContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type PersonContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type PersonContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type PersonContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type PersonContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type PersonContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type PersonContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type PersonContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type PersonCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  resourceType: PersonResourceType;
  gender?: Maybe<PersonGender>;
  meta?: Maybe<PersonMetaFieldInput>;
  _implicitRules?: Maybe<Person_ImplicitrulesFieldInput>;
  _language?: Maybe<Person_LanguageFieldInput>;
  text?: Maybe<PersonTextFieldInput>;
  contained_Account?: Maybe<PersonContainedAccountFieldInput>;
  contained_Appointment?: Maybe<PersonContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<PersonContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<PersonContainedCoverageFieldInput>;
  contained_Device?: Maybe<PersonContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<PersonContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<PersonContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<PersonContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<PersonContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<PersonContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<PersonContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<PersonContainedLocationFieldInput>;
  contained_Organization?: Maybe<PersonContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<PersonContainedPatientFieldInput>;
  contained_Person?: Maybe<PersonContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<PersonContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<PersonContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<PersonContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<PersonContainedScheduleFieldInput>;
  contained_Slot?: Maybe<PersonContainedSlotFieldInput>;
  contained_Substance?: Maybe<PersonContainedSubstanceFieldInput>;
  extension?: Maybe<PersonExtensionFieldInput>;
  modifierExtension?: Maybe<PersonModifierextensionFieldInput>;
  identifier?: Maybe<PersonIdentifierFieldInput>;
  name?: Maybe<PersonNameFieldInput>;
  telecom?: Maybe<PersonTelecomFieldInput>;
  _gender?: Maybe<Person_GenderFieldInput>;
  _birthDate?: Maybe<Person_BirthdateFieldInput>;
  address?: Maybe<PersonAddressFieldInput>;
  photo?: Maybe<PersonPhotoFieldInput>;
  managingOrganization?: Maybe<PersonManagingorganizationFieldInput>;
  _active?: Maybe<Person_ActiveFieldInput>;
  link?: Maybe<PersonLinkFieldInput>;
};

export type PersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type PersonDeleteInput = {
  meta?: Maybe<PersonMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Person_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Person_LanguageDeleteFieldInput>;
  text?: Maybe<PersonTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<PersonContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<PersonContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<PersonContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<PersonContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<PersonContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<PersonContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<PersonContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<PersonContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<PersonContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<PersonContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<PersonContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<PersonContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<PersonContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<PersonContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<PersonContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<PersonContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PersonContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<PersonContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<PersonContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<PersonContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<PersonContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<PersonExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PersonModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<PersonIdentifierDeleteFieldInput>>;
  name?: Maybe<Array<PersonNameDeleteFieldInput>>;
  telecom?: Maybe<Array<PersonTelecomDeleteFieldInput>>;
  _gender?: Maybe<Person_GenderDeleteFieldInput>;
  _birthDate?: Maybe<Person_BirthdateDeleteFieldInput>;
  address?: Maybe<Array<PersonAddressDeleteFieldInput>>;
  photo?: Maybe<PersonPhotoDeleteFieldInput>;
  managingOrganization?: Maybe<PersonManagingorganizationDeleteFieldInput>;
  _active?: Maybe<Person_ActiveDeleteFieldInput>;
  link?: Maybe<Array<PersonLinkDeleteFieldInput>>;
};

export type PersonDisconnectFieldInput = {
  where?: Maybe<PersonWhere>;
  disconnect?: Maybe<PersonDisconnectInput>;
};

export type PersonDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  name?: Maybe<Array<HumanNameDisconnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  _gender?: Maybe<ElementDisconnectFieldInput>;
  _birthDate?: Maybe<ElementDisconnectFieldInput>;
  address?: Maybe<Array<AddressDisconnectFieldInput>>;
  photo?: Maybe<AttachmentDisconnectFieldInput>;
  managingOrganization?: Maybe<ReferenceDisconnectFieldInput>;
  _active?: Maybe<ElementDisconnectFieldInput>;
  link?: Maybe<Array<PersonLinkDisconnectFieldInput>>;
};

export type PersonExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PersonExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PersonExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** Administrative Gender. */
export enum PersonGender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
  Unknown = 'unknown',
}

export type PersonIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type PersonIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type PersonIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonLink = {
  __typename?: 'PersonLink';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Level of assurance that this link is associated with the target resource. */
  assurance?: Maybe<PersonLinkAssurance>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  target: Reference;
  _assurance?: Maybe<Element>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonLinkExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonLinkModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonLinkTargetArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Demographics and administrative information about a person independent of a specific health-related context. */
export type PersonLink_AssuranceArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Level of assurance that this link is associated with the target resource. */
export enum PersonLinkAssurance {
  Level1 = 'level1',
  Level2 = 'level2',
  Level3 = 'level3',
  Level4 = 'level4',
}

export type PersonLinkConnectFieldInput = {
  where?: Maybe<PersonLinkWhere>;
  connect?: Maybe<PersonLinkConnectInput>;
};

export type PersonLinkConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  target?: Maybe<ReferenceConnectFieldInput>;
  _assurance?: Maybe<ElementConnectFieldInput>;
};

export type PersonLinkCreateInput = {
  assurance?: Maybe<PersonLinkAssurance>;
  extension?: Maybe<PersonLinkExtensionFieldInput>;
  modifierExtension?: Maybe<PersonLinkModifierextensionFieldInput>;
  target?: Maybe<PersonLinkTargetFieldInput>;
  _assurance?: Maybe<PersonLink_AssuranceFieldInput>;
};

export type PersonLinkDeleteFieldInput = {
  where?: Maybe<PersonLinkWhere>;
  delete?: Maybe<PersonLinkDeleteInput>;
};

export type PersonLinkDeleteInput = {
  extension?: Maybe<Array<PersonLinkExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PersonLinkModifierextensionDeleteFieldInput>>;
  target?: Maybe<PersonLinkTargetDeleteFieldInput>;
  _assurance?: Maybe<PersonLink_AssuranceDeleteFieldInput>;
};

export type PersonLinkDisconnectFieldInput = {
  where?: Maybe<PersonLinkWhere>;
  disconnect?: Maybe<PersonLinkDisconnectInput>;
};

export type PersonLinkDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  target?: Maybe<ReferenceDisconnectFieldInput>;
  _assurance?: Maybe<ElementDisconnectFieldInput>;
};

export type PersonLinkExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PersonLinkExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PersonLinkExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PersonLinkFieldInput = {
  create?: Maybe<Array<PersonLinkCreateInput>>;
  connect?: Maybe<Array<PersonLinkConnectFieldInput>>;
};

export type PersonLinkModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PersonLinkModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PersonLinkModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PersonLinkOptions = {
  /** Specify one or more PersonLinkSort objects to sort PersonLinks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PersonLinkSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PersonLinkRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  target?: Maybe<ReferenceCreateInput>;
  _assurance?: Maybe<ElementCreateInput>;
};

/** Fields to sort PersonLinks by. The order in which sorts are applied is not guaranteed when specifying many fields in one PersonLinkSort object. */
export type PersonLinkSort = {
  id?: Maybe<SortDirection>;
  assurance?: Maybe<SortDirection>;
};

export type PersonLinkTargetDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PersonLinkTargetFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type PersonLinkTargetUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type PersonLinkUpdateFieldInput = {
  where?: Maybe<PersonLinkWhere>;
  update?: Maybe<PersonLinkUpdateInput>;
  connect?: Maybe<Array<PersonLinkConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonLinkDisconnectFieldInput>>;
  create?: Maybe<Array<PersonLinkCreateInput>>;
  delete?: Maybe<Array<PersonLinkDeleteFieldInput>>;
};

export type PersonLinkUpdateInput = {
  assurance?: Maybe<PersonLinkAssurance>;
  extension?: Maybe<Array<PersonLinkExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PersonLinkModifierextensionUpdateFieldInput>>;
  target?: Maybe<PersonLinkTargetUpdateFieldInput>;
  _assurance?: Maybe<PersonLink_AssuranceUpdateFieldInput>;
};

export type PersonLinkWhere = {
  OR?: Maybe<Array<PersonLinkWhere>>;
  AND?: Maybe<Array<PersonLinkWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  assurance?: Maybe<PersonLinkAssurance>;
  assurance_NOT?: Maybe<PersonLinkAssurance>;
  assurance_IN?: Maybe<Array<Maybe<PersonLinkAssurance>>>;
  assurance_NOT_IN?: Maybe<Array<Maybe<PersonLinkAssurance>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  target?: Maybe<ReferenceWhere>;
  target_NOT?: Maybe<ReferenceWhere>;
  target_IN?: Maybe<Array<ReferenceWhere>>;
  target_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _assurance?: Maybe<ElementWhere>;
  _assurance_NOT?: Maybe<ElementWhere>;
  _assurance_IN?: Maybe<Array<ElementWhere>>;
  _assurance_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type PersonLink_AssuranceDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PersonLink_AssuranceFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PersonLink_AssuranceUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PersonManagingorganizationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PersonManagingorganizationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type PersonManagingorganizationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type PersonMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type PersonMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type PersonMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type PersonModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PersonModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PersonModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PersonNameDeleteFieldInput = {
  where?: Maybe<HumanNameWhere>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type PersonNameFieldInput = {
  create?: Maybe<Array<HumanNameCreateInput>>;
  connect?: Maybe<Array<HumanNameConnectFieldInput>>;
};

export type PersonNameUpdateFieldInput = {
  where?: Maybe<HumanNameWhere>;
  update?: Maybe<HumanNameUpdateInput>;
  connect?: Maybe<Array<HumanNameConnectFieldInput>>;
  disconnect?: Maybe<Array<HumanNameDisconnectFieldInput>>;
  create?: Maybe<Array<HumanNameCreateInput>>;
  delete?: Maybe<Array<HumanNameDeleteFieldInput>>;
};

export type PersonOptions = {
  /** Specify one or more PersonSort objects to sort People by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PersonSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PersonPhotoDeleteFieldInput = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type PersonPhotoFieldInput = {
  create?: Maybe<AttachmentCreateInput>;
  connect?: Maybe<AttachmentConnectFieldInput>;
};

export type PersonPhotoUpdateFieldInput = {
  where?: Maybe<AttachmentWhere>;
  update?: Maybe<AttachmentUpdateInput>;
  connect?: Maybe<AttachmentConnectFieldInput>;
  disconnect?: Maybe<AttachmentDisconnectFieldInput>;
  create?: Maybe<AttachmentCreateInput>;
  delete?: Maybe<AttachmentDeleteFieldInput>;
};

export type PersonRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  name?: Maybe<Array<HumanNameCreateInput>>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  _gender?: Maybe<ElementCreateInput>;
  _birthDate?: Maybe<ElementCreateInput>;
  address?: Maybe<Array<AddressCreateInput>>;
  photo?: Maybe<AttachmentCreateInput>;
  managingOrganization?: Maybe<ReferenceCreateInput>;
  _active?: Maybe<ElementCreateInput>;
  link?: Maybe<Array<PersonLinkCreateInput>>;
};

export enum PersonResourceType {
  Person = 'Person',
}

/** Fields to sort People by. The order in which sorts are applied is not guaranteed when specifying many fields in one PersonSort object. */
export type PersonSort = {
  id?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  gender?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  birthDate?: Maybe<SortDirection>;
};

export type PersonTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type PersonTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type PersonTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type PersonTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type PersonTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type PersonTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type PersonUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  resourceType?: Maybe<PersonResourceType>;
  gender?: Maybe<PersonGender>;
  meta?: Maybe<PersonMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Person_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Person_LanguageUpdateFieldInput>;
  text?: Maybe<PersonTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<PersonContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<PersonContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<PersonContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<PersonContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<PersonContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<PersonContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<PersonContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<PersonContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<PersonContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<PersonContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<PersonContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<PersonContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<PersonContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<PersonContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<PersonContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<PersonContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PersonContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<PersonContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<PersonContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<PersonContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<PersonContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<PersonExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PersonModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<PersonIdentifierUpdateFieldInput>>;
  name?: Maybe<Array<PersonNameUpdateFieldInput>>;
  telecom?: Maybe<Array<PersonTelecomUpdateFieldInput>>;
  _gender?: Maybe<Person_GenderUpdateFieldInput>;
  _birthDate?: Maybe<Person_BirthdateUpdateFieldInput>;
  address?: Maybe<Array<PersonAddressUpdateFieldInput>>;
  photo?: Maybe<PersonPhotoUpdateFieldInput>;
  managingOrganization?: Maybe<PersonManagingorganizationUpdateFieldInput>;
  _active?: Maybe<Person_ActiveUpdateFieldInput>;
  link?: Maybe<Array<PersonLinkUpdateFieldInput>>;
};

export type PersonWhere = {
  OR?: Maybe<Array<PersonWhere>>;
  AND?: Maybe<Array<PersonWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<PersonResourceType>;
  resourceType_NOT?: Maybe<PersonResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<PersonResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<PersonResourceType>>>;
  gender?: Maybe<PersonGender>;
  gender_NOT?: Maybe<PersonGender>;
  gender_IN?: Maybe<Array<Maybe<PersonGender>>>;
  gender_NOT_IN?: Maybe<Array<Maybe<PersonGender>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  name?: Maybe<HumanNameWhere>;
  name_NOT?: Maybe<HumanNameWhere>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  _gender?: Maybe<ElementWhere>;
  _gender_NOT?: Maybe<ElementWhere>;
  _gender_IN?: Maybe<Array<ElementWhere>>;
  _gender_NOT_IN?: Maybe<Array<ElementWhere>>;
  _birthDate?: Maybe<ElementWhere>;
  _birthDate_NOT?: Maybe<ElementWhere>;
  _birthDate_IN?: Maybe<Array<ElementWhere>>;
  _birthDate_NOT_IN?: Maybe<Array<ElementWhere>>;
  address?: Maybe<AddressWhere>;
  address_NOT?: Maybe<AddressWhere>;
  photo?: Maybe<AttachmentWhere>;
  photo_NOT?: Maybe<AttachmentWhere>;
  photo_IN?: Maybe<Array<AttachmentWhere>>;
  photo_NOT_IN?: Maybe<Array<AttachmentWhere>>;
  managingOrganization?: Maybe<ReferenceWhere>;
  managingOrganization_NOT?: Maybe<ReferenceWhere>;
  managingOrganization_IN?: Maybe<Array<ReferenceWhere>>;
  managingOrganization_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _active?: Maybe<ElementWhere>;
  _active_NOT?: Maybe<ElementWhere>;
  _active_IN?: Maybe<Array<ElementWhere>>;
  _active_NOT_IN?: Maybe<Array<ElementWhere>>;
  link?: Maybe<PersonLinkWhere>;
  link_NOT?: Maybe<PersonLinkWhere>;
};

export type Person_ActiveDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Person_ActiveFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Person_ActiveUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Person_BirthdateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Person_BirthdateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Person_BirthdateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Person_GenderDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Person_GenderFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Person_GenderUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Person_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Person_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Person_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Person_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Person_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Person_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type Practitioner = {
  __typename?: 'Practitioner';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** Whether this practitioner's record is in active use. */
  active?: Maybe<Scalars['Boolean']>;
  /** This is a Practitioner resource */
  resourceType: PractitionerResourceType;
  /** Administrative Gender - the gender that the person is considered to have for administration and record keeping purposes. */
  gender?: Maybe<PractitionerGender>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** The date of birth for the practitioner. */
  birthDate?: Maybe<Scalars['Date']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _active?: Maybe<Element>;
  name: Array<HumanName>;
  telecom: Array<ContactPoint>;
  address: Array<Address>;
  _gender?: Maybe<Element>;
  _birthDate?: Maybe<Element>;
  photo: Array<Attachment>;
  qualification: Array<PractitionerQualification>;
  communication: Array<CodeableConcept>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type Practitioner_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type Practitioner_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type Practitioner_ActiveArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerNameArgs = {
  where?: Maybe<HumanNameWhere>;
  options?: Maybe<HumanNameOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerAddressArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type Practitioner_GenderArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type Practitioner_BirthDateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerPhotoArgs = {
  where?: Maybe<AttachmentWhere>;
  options?: Maybe<AttachmentOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerQualificationArgs = {
  where?: Maybe<PractitionerQualificationWhere>;
  options?: Maybe<PractitionerQualificationOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerCommunicationArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

export type PractitionerAddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type PractitionerAddressFieldInput = {
  create?: Maybe<Array<AddressCreateInput>>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
};

export type PractitionerAddressUpdateFieldInput = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
  disconnect?: Maybe<Array<AddressDisconnectFieldInput>>;
  create?: Maybe<Array<AddressCreateInput>>;
  delete?: Maybe<Array<AddressDeleteFieldInput>>;
};

export type PractitionerCommunicationDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type PractitionerCommunicationFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type PractitionerCommunicationUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type PractitionerConnectFieldInput = {
  where?: Maybe<PractitionerWhere>;
  connect?: Maybe<PractitionerConnectInput>;
};

export type PractitionerConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _active?: Maybe<ElementConnectFieldInput>;
  name?: Maybe<Array<HumanNameConnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  address?: Maybe<Array<AddressConnectFieldInput>>;
  _gender?: Maybe<ElementConnectFieldInput>;
  _birthDate?: Maybe<ElementConnectFieldInput>;
  photo?: Maybe<Array<AttachmentConnectFieldInput>>;
  qualification?: Maybe<Array<PractitionerQualificationConnectFieldInput>>;
  communication?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type PractitionerContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type PractitionerContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type PractitionerContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type PractitionerContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type PractitionerContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type PractitionerContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type PractitionerContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type PractitionerContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type PractitionerContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type PractitionerContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type PractitionerContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type PractitionerContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type PractitionerContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type PractitionerContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type PractitionerContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type PractitionerContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type PractitionerContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type PractitionerContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type PractitionerContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type PractitionerContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type PractitionerContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type PractitionerContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type PractitionerContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type PractitionerContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type PractitionerContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type PractitionerContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type PractitionerContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type PractitionerContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type PractitionerContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type PractitionerContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type PractitionerContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type PractitionerContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type PractitionerContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type PractitionerContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type PractitionerContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type PractitionerContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type PractitionerContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type PractitionerContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type PractitionerContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type PractitionerContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type PractitionerContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type PractitionerContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type PractitionerContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type PractitionerContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type PractitionerContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type PractitionerContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type PractitionerContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type PractitionerContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type PractitionerContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type PractitionerContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type PractitionerContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type PractitionerContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type PractitionerContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type PractitionerContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type PractitionerContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type PractitionerContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type PractitionerContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type PractitionerContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type PractitionerContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type PractitionerContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type PractitionerContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type PractitionerContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type PractitionerContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type PractitionerCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  resourceType: PractitionerResourceType;
  gender?: Maybe<PractitionerGender>;
  meta?: Maybe<PractitionerMetaFieldInput>;
  _implicitRules?: Maybe<Practitioner_ImplicitrulesFieldInput>;
  _language?: Maybe<Practitioner_LanguageFieldInput>;
  text?: Maybe<PractitionerTextFieldInput>;
  contained_Account?: Maybe<PractitionerContainedAccountFieldInput>;
  contained_Appointment?: Maybe<PractitionerContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<PractitionerContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<PractitionerContainedCoverageFieldInput>;
  contained_Device?: Maybe<PractitionerContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<PractitionerContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<PractitionerContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<PractitionerContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<PractitionerContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<PractitionerContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<PractitionerContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<PractitionerContainedLocationFieldInput>;
  contained_Organization?: Maybe<PractitionerContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<PractitionerContainedPatientFieldInput>;
  contained_Person?: Maybe<PractitionerContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<PractitionerContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<PractitionerContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<PractitionerContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<PractitionerContainedScheduleFieldInput>;
  contained_Slot?: Maybe<PractitionerContainedSlotFieldInput>;
  contained_Substance?: Maybe<PractitionerContainedSubstanceFieldInput>;
  extension?: Maybe<PractitionerExtensionFieldInput>;
  modifierExtension?: Maybe<PractitionerModifierextensionFieldInput>;
  identifier?: Maybe<PractitionerIdentifierFieldInput>;
  _active?: Maybe<Practitioner_ActiveFieldInput>;
  name?: Maybe<PractitionerNameFieldInput>;
  telecom?: Maybe<PractitionerTelecomFieldInput>;
  address?: Maybe<PractitionerAddressFieldInput>;
  _gender?: Maybe<Practitioner_GenderFieldInput>;
  _birthDate?: Maybe<Practitioner_BirthdateFieldInput>;
  photo?: Maybe<PractitionerPhotoFieldInput>;
  qualification?: Maybe<PractitionerQualificationFieldInput>;
  communication?: Maybe<PractitionerCommunicationFieldInput>;
};

export type PractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type PractitionerDeleteInput = {
  meta?: Maybe<PractitionerMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Practitioner_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Practitioner_LanguageDeleteFieldInput>;
  text?: Maybe<PractitionerTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<PractitionerContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<PractitionerContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<PractitionerContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<PractitionerContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<PractitionerContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<PractitionerContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<PractitionerContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<PractitionerContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<PractitionerContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<PractitionerContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<PractitionerContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<PractitionerContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<PractitionerContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<PractitionerContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<PractitionerContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<PractitionerContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<PractitionerContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<PractitionerContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<PractitionerContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<PractitionerExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<PractitionerIdentifierDeleteFieldInput>>;
  _active?: Maybe<Practitioner_ActiveDeleteFieldInput>;
  name?: Maybe<Array<PractitionerNameDeleteFieldInput>>;
  telecom?: Maybe<Array<PractitionerTelecomDeleteFieldInput>>;
  address?: Maybe<Array<PractitionerAddressDeleteFieldInput>>;
  _gender?: Maybe<Practitioner_GenderDeleteFieldInput>;
  _birthDate?: Maybe<Practitioner_BirthdateDeleteFieldInput>;
  photo?: Maybe<Array<PractitionerPhotoDeleteFieldInput>>;
  qualification?: Maybe<Array<PractitionerQualificationDeleteFieldInput>>;
  communication?: Maybe<Array<PractitionerCommunicationDeleteFieldInput>>;
};

export type PractitionerDisconnectFieldInput = {
  where?: Maybe<PractitionerWhere>;
  disconnect?: Maybe<PractitionerDisconnectInput>;
};

export type PractitionerDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _active?: Maybe<ElementDisconnectFieldInput>;
  name?: Maybe<Array<HumanNameDisconnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  address?: Maybe<Array<AddressDisconnectFieldInput>>;
  _gender?: Maybe<ElementDisconnectFieldInput>;
  _birthDate?: Maybe<ElementDisconnectFieldInput>;
  photo?: Maybe<Array<AttachmentDisconnectFieldInput>>;
  qualification?: Maybe<Array<PractitionerQualificationDisconnectFieldInput>>;
  communication?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
};

export type PractitionerExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** Administrative Gender - the gender that the person is considered to have for administration and record keeping purposes. */
export enum PractitionerGender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
  Unknown = 'unknown',
}

export type PractitionerIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type PractitionerIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type PractitionerIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type PractitionerMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type PractitionerMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type PractitionerMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type PractitionerModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PractitionerNameDeleteFieldInput = {
  where?: Maybe<HumanNameWhere>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type PractitionerNameFieldInput = {
  create?: Maybe<Array<HumanNameCreateInput>>;
  connect?: Maybe<Array<HumanNameConnectFieldInput>>;
};

export type PractitionerNameUpdateFieldInput = {
  where?: Maybe<HumanNameWhere>;
  update?: Maybe<HumanNameUpdateInput>;
  connect?: Maybe<Array<HumanNameConnectFieldInput>>;
  disconnect?: Maybe<Array<HumanNameDisconnectFieldInput>>;
  create?: Maybe<Array<HumanNameCreateInput>>;
  delete?: Maybe<Array<HumanNameDeleteFieldInput>>;
};

export type PractitionerOptions = {
  /** Specify one or more PractitionerSort objects to sort Practitioners by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PractitionerSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PractitionerPhotoDeleteFieldInput = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type PractitionerPhotoFieldInput = {
  create?: Maybe<Array<AttachmentCreateInput>>;
  connect?: Maybe<Array<AttachmentConnectFieldInput>>;
};

export type PractitionerPhotoUpdateFieldInput = {
  where?: Maybe<AttachmentWhere>;
  update?: Maybe<AttachmentUpdateInput>;
  connect?: Maybe<Array<AttachmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AttachmentDisconnectFieldInput>>;
  create?: Maybe<Array<AttachmentCreateInput>>;
  delete?: Maybe<Array<AttachmentDeleteFieldInput>>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerQualification = {
  __typename?: 'PractitionerQualification';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  code: CodeableConcept;
  period?: Maybe<Period>;
  issuer?: Maybe<Reference>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerQualificationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerQualificationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerQualificationIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerQualificationCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerQualificationPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerQualificationIssuerArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type PractitionerQualificationCodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type PractitionerQualificationCodeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type PractitionerQualificationCodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type PractitionerQualificationConnectFieldInput = {
  where?: Maybe<PractitionerQualificationWhere>;
  connect?: Maybe<PractitionerQualificationConnectInput>;
};

export type PractitionerQualificationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  code?: Maybe<CodeableConceptConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
  issuer?: Maybe<ReferenceConnectFieldInput>;
};

export type PractitionerQualificationCreateInput = {
  extension?: Maybe<PractitionerQualificationExtensionFieldInput>;
  modifierExtension?: Maybe<PractitionerQualificationModifierextensionFieldInput>;
  identifier?: Maybe<PractitionerQualificationIdentifierFieldInput>;
  code?: Maybe<PractitionerQualificationCodeFieldInput>;
  period?: Maybe<PractitionerQualificationPeriodFieldInput>;
  issuer?: Maybe<PractitionerQualificationIssuerFieldInput>;
};

export type PractitionerQualificationDeleteFieldInput = {
  where?: Maybe<PractitionerQualificationWhere>;
  delete?: Maybe<PractitionerQualificationDeleteInput>;
};

export type PractitionerQualificationDeleteInput = {
  extension?: Maybe<Array<PractitionerQualificationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerQualificationModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<PractitionerQualificationIdentifierDeleteFieldInput>>;
  code?: Maybe<PractitionerQualificationCodeDeleteFieldInput>;
  period?: Maybe<PractitionerQualificationPeriodDeleteFieldInput>;
  issuer?: Maybe<PractitionerQualificationIssuerDeleteFieldInput>;
};

export type PractitionerQualificationDisconnectFieldInput = {
  where?: Maybe<PractitionerQualificationWhere>;
  disconnect?: Maybe<PractitionerQualificationDisconnectInput>;
};

export type PractitionerQualificationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  code?: Maybe<CodeableConceptDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
  issuer?: Maybe<ReferenceDisconnectFieldInput>;
};

export type PractitionerQualificationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerQualificationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerQualificationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PractitionerQualificationFieldInput = {
  create?: Maybe<Array<PractitionerQualificationCreateInput>>;
  connect?: Maybe<Array<PractitionerQualificationConnectFieldInput>>;
};

export type PractitionerQualificationIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type PractitionerQualificationIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type PractitionerQualificationIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type PractitionerQualificationIssuerDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PractitionerQualificationIssuerFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type PractitionerQualificationIssuerUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type PractitionerQualificationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerQualificationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerQualificationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PractitionerQualificationOptions = {
  /** Specify one or more PractitionerQualificationSort objects to sort PractitionerQualifications by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PractitionerQualificationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PractitionerQualificationPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type PractitionerQualificationPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type PractitionerQualificationPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type PractitionerQualificationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  code?: Maybe<CodeableConceptCreateInput>;
  period?: Maybe<PeriodCreateInput>;
  issuer?: Maybe<ReferenceCreateInput>;
};

/** Fields to sort PractitionerQualifications by. The order in which sorts are applied is not guaranteed when specifying many fields in one PractitionerQualificationSort object. */
export type PractitionerQualificationSort = {
  id?: Maybe<SortDirection>;
};

export type PractitionerQualificationUpdateFieldInput = {
  where?: Maybe<PractitionerQualificationWhere>;
  update?: Maybe<PractitionerQualificationUpdateInput>;
  connect?: Maybe<Array<PractitionerQualificationConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerQualificationDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerQualificationCreateInput>>;
  delete?: Maybe<Array<PractitionerQualificationDeleteFieldInput>>;
};

export type PractitionerQualificationUpdateInput = {
  extension?: Maybe<Array<PractitionerQualificationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerQualificationModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<PractitionerQualificationIdentifierUpdateFieldInput>>;
  code?: Maybe<PractitionerQualificationCodeUpdateFieldInput>;
  period?: Maybe<PractitionerQualificationPeriodUpdateFieldInput>;
  issuer?: Maybe<PractitionerQualificationIssuerUpdateFieldInput>;
};

export type PractitionerQualificationWhere = {
  OR?: Maybe<Array<PractitionerQualificationWhere>>;
  AND?: Maybe<Array<PractitionerQualificationWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  code?: Maybe<CodeableConceptWhere>;
  code_NOT?: Maybe<CodeableConceptWhere>;
  code_IN?: Maybe<Array<CodeableConceptWhere>>;
  code_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
  issuer?: Maybe<ReferenceWhere>;
  issuer_NOT?: Maybe<ReferenceWhere>;
  issuer_IN?: Maybe<Array<ReferenceWhere>>;
  issuer_NOT_IN?: Maybe<Array<ReferenceWhere>>;
};

export type PractitionerRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _active?: Maybe<ElementCreateInput>;
  name?: Maybe<Array<HumanNameCreateInput>>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  address?: Maybe<Array<AddressCreateInput>>;
  _gender?: Maybe<ElementCreateInput>;
  _birthDate?: Maybe<ElementCreateInput>;
  photo?: Maybe<Array<AttachmentCreateInput>>;
  qualification?: Maybe<Array<PractitionerQualificationCreateInput>>;
  communication?: Maybe<Array<CodeableConceptCreateInput>>;
};

export enum PractitionerResourceType {
  Practitioner = 'Practitioner',
}

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRole = {
  __typename?: 'PractitionerRole';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** Whether this practitioner role record is in active use. */
  active?: Maybe<Scalars['Boolean']>;
  /** This is a PractitionerRole resource */
  resourceType: PractitionerRoleResourceType;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** A description of site availability exceptions, e.g. public holiday availability. Succinctly describing all possible exceptions to normal site availability as details in the available Times and not available Times. */
  availabilityExceptions?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _active?: Maybe<Element>;
  period?: Maybe<Period>;
  practitioner?: Maybe<Reference>;
  organization?: Maybe<Reference>;
  code: Array<CodeableConcept>;
  specialty: Array<CodeableConcept>;
  location: Array<Reference>;
  healthcareService: Array<Reference>;
  telecom: Array<ContactPoint>;
  availableTime: Array<PractitionerRoleAvailableTime>;
  notAvailable: Array<PractitionerRoleNotAvailable>;
  _availabilityExceptions?: Maybe<Element>;
  endpoint: Array<Reference>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRole_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRole_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRole_ActiveArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRolePeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRolePractitionerArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleOrganizationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleSpecialtyArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleLocationArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleHealthcareServiceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleAvailableTimeArgs = {
  where?: Maybe<PractitionerRoleAvailableTimeWhere>;
  options?: Maybe<PractitionerRoleAvailableTimeOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleNotAvailableArgs = {
  where?: Maybe<PractitionerRoleNotAvailableWhere>;
  options?: Maybe<PractitionerRoleNotAvailableOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRole_AvailabilityExceptionsArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleEndpointArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleAvailableTime = {
  __typename?: 'PractitionerRoleAvailableTime';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Is this always available? (hence times are irrelevant) e.g. 24 hour service. */
  allDay?: Maybe<Scalars['Boolean']>;
  /** Indicates which days of the week are available between the start and end Times. */
  daysOfWeek: Array<Scalars['Code']>;
  /** The opening time of day. Note: If the AllDay flag is set, then this time is ignored. */
  availableStartTime?: Maybe<Scalars['Time']>;
  /** The closing time of day. Note: If the AllDay flag is set, then this time is ignored. */
  availableEndTime?: Maybe<Scalars['Time']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _daysOfWeek: Array<Element>;
  _allDay?: Maybe<Element>;
  _availableStartTime?: Maybe<Element>;
  _availableEndTime?: Maybe<Element>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleAvailableTimeExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleAvailableTimeModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleAvailableTime_DaysOfWeekArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleAvailableTime_AllDayArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleAvailableTime_AvailableStartTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleAvailableTime_AvailableEndTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type PractitionerRoleAvailableTimeConnectFieldInput = {
  where?: Maybe<PractitionerRoleAvailableTimeWhere>;
  connect?: Maybe<PractitionerRoleAvailableTimeConnectInput>;
};

export type PractitionerRoleAvailableTimeConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _daysOfWeek?: Maybe<Array<ElementConnectFieldInput>>;
  _allDay?: Maybe<ElementConnectFieldInput>;
  _availableStartTime?: Maybe<ElementConnectFieldInput>;
  _availableEndTime?: Maybe<ElementConnectFieldInput>;
};

export type PractitionerRoleAvailableTimeCreateInput = {
  allDay?: Maybe<Scalars['Boolean']>;
  daysOfWeek: Array<Scalars['Code']>;
  availableStartTime?: Maybe<Scalars['Time']>;
  availableEndTime?: Maybe<Scalars['Time']>;
  extension?: Maybe<PractitionerRoleAvailableTimeExtensionFieldInput>;
  modifierExtension?: Maybe<PractitionerRoleAvailableTimeModifierextensionFieldInput>;
  _daysOfWeek?: Maybe<PractitionerRoleAvailableTime_DaysofweekFieldInput>;
  _allDay?: Maybe<PractitionerRoleAvailableTime_AlldayFieldInput>;
  _availableStartTime?: Maybe<PractitionerRoleAvailableTime_AvailablestarttimeFieldInput>;
  _availableEndTime?: Maybe<PractitionerRoleAvailableTime_AvailableendtimeFieldInput>;
};

export type PractitionerRoleAvailableTimeDeleteFieldInput = {
  where?: Maybe<PractitionerRoleAvailableTimeWhere>;
  delete?: Maybe<PractitionerRoleAvailableTimeDeleteInput>;
};

export type PractitionerRoleAvailableTimeDeleteInput = {
  extension?: Maybe<Array<PractitionerRoleAvailableTimeExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerRoleAvailableTimeModifierextensionDeleteFieldInput>>;
  _daysOfWeek?: Maybe<Array<PractitionerRoleAvailableTime_DaysofweekDeleteFieldInput>>;
  _allDay?: Maybe<PractitionerRoleAvailableTime_AlldayDeleteFieldInput>;
  _availableStartTime?: Maybe<PractitionerRoleAvailableTime_AvailablestarttimeDeleteFieldInput>;
  _availableEndTime?: Maybe<PractitionerRoleAvailableTime_AvailableendtimeDeleteFieldInput>;
};

export type PractitionerRoleAvailableTimeDisconnectFieldInput = {
  where?: Maybe<PractitionerRoleAvailableTimeWhere>;
  disconnect?: Maybe<PractitionerRoleAvailableTimeDisconnectInput>;
};

export type PractitionerRoleAvailableTimeDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _daysOfWeek?: Maybe<Array<ElementDisconnectFieldInput>>;
  _allDay?: Maybe<ElementDisconnectFieldInput>;
  _availableStartTime?: Maybe<ElementDisconnectFieldInput>;
  _availableEndTime?: Maybe<ElementDisconnectFieldInput>;
};

export type PractitionerRoleAvailableTimeExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerRoleAvailableTimeExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerRoleAvailableTimeExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PractitionerRoleAvailableTimeModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerRoleAvailableTimeModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerRoleAvailableTimeModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PractitionerRoleAvailableTimeOptions = {
  /** Specify one or more PractitionerRoleAvailableTimeSort objects to sort PractitionerRoleAvailableTimes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PractitionerRoleAvailableTimeSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PractitionerRoleAvailableTimeRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _daysOfWeek?: Maybe<Array<ElementCreateInput>>;
  _allDay?: Maybe<ElementCreateInput>;
  _availableStartTime?: Maybe<ElementCreateInput>;
  _availableEndTime?: Maybe<ElementCreateInput>;
};

/** Fields to sort PractitionerRoleAvailableTimes by. The order in which sorts are applied is not guaranteed when specifying many fields in one PractitionerRoleAvailableTimeSort object. */
export type PractitionerRoleAvailableTimeSort = {
  id?: Maybe<SortDirection>;
  allDay?: Maybe<SortDirection>;
  availableStartTime?: Maybe<SortDirection>;
  availableEndTime?: Maybe<SortDirection>;
};

export type PractitionerRoleAvailableTimeUpdateInput = {
  allDay?: Maybe<Scalars['Boolean']>;
  daysOfWeek?: Maybe<Array<Scalars['Code']>>;
  availableStartTime?: Maybe<Scalars['Time']>;
  availableEndTime?: Maybe<Scalars['Time']>;
  extension?: Maybe<Array<PractitionerRoleAvailableTimeExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerRoleAvailableTimeModifierextensionUpdateFieldInput>>;
  _daysOfWeek?: Maybe<Array<PractitionerRoleAvailableTime_DaysofweekUpdateFieldInput>>;
  _allDay?: Maybe<PractitionerRoleAvailableTime_AlldayUpdateFieldInput>;
  _availableStartTime?: Maybe<PractitionerRoleAvailableTime_AvailablestarttimeUpdateFieldInput>;
  _availableEndTime?: Maybe<PractitionerRoleAvailableTime_AvailableendtimeUpdateFieldInput>;
};

export type PractitionerRoleAvailableTimeWhere = {
  OR?: Maybe<Array<PractitionerRoleAvailableTimeWhere>>;
  AND?: Maybe<Array<PractitionerRoleAvailableTimeWhere>>;
  daysOfWeek?: Maybe<Array<Maybe<Scalars['Code']>>>;
  availableStartTime?: Maybe<Scalars['Time']>;
  availableEndTime?: Maybe<Scalars['Time']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  allDay?: Maybe<Scalars['Boolean']>;
  allDay_NOT?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _daysOfWeek?: Maybe<ElementWhere>;
  _daysOfWeek_NOT?: Maybe<ElementWhere>;
  _allDay?: Maybe<ElementWhere>;
  _allDay_NOT?: Maybe<ElementWhere>;
  _allDay_IN?: Maybe<Array<ElementWhere>>;
  _allDay_NOT_IN?: Maybe<Array<ElementWhere>>;
  _availableStartTime?: Maybe<ElementWhere>;
  _availableStartTime_NOT?: Maybe<ElementWhere>;
  _availableStartTime_IN?: Maybe<Array<ElementWhere>>;
  _availableStartTime_NOT_IN?: Maybe<Array<ElementWhere>>;
  _availableEndTime?: Maybe<ElementWhere>;
  _availableEndTime_NOT?: Maybe<ElementWhere>;
  _availableEndTime_IN?: Maybe<Array<ElementWhere>>;
  _availableEndTime_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type PractitionerRoleAvailableTime_AlldayDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PractitionerRoleAvailableTime_AlldayFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PractitionerRoleAvailableTime_AlldayUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PractitionerRoleAvailableTime_AvailableendtimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PractitionerRoleAvailableTime_AvailableendtimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PractitionerRoleAvailableTime_AvailableendtimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PractitionerRoleAvailableTime_AvailablestarttimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PractitionerRoleAvailableTime_AvailablestarttimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PractitionerRoleAvailableTime_AvailablestarttimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PractitionerRoleAvailableTime_DaysofweekDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PractitionerRoleAvailableTime_DaysofweekFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type PractitionerRoleAvailableTime_DaysofweekUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type PractitionerRoleAvailabletimeDeleteFieldInput = {
  where?: Maybe<PractitionerRoleAvailableTimeWhere>;
  delete?: Maybe<PractitionerRoleAvailableTimeDeleteInput>;
};

export type PractitionerRoleAvailabletimeFieldInput = {
  create?: Maybe<Array<PractitionerRoleAvailableTimeCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleAvailableTimeConnectFieldInput>>;
};

export type PractitionerRoleAvailabletimeUpdateFieldInput = {
  where?: Maybe<PractitionerRoleAvailableTimeWhere>;
  update?: Maybe<PractitionerRoleAvailableTimeUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleAvailableTimeConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleAvailableTimeDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleAvailableTimeCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleAvailableTimeDeleteFieldInput>>;
};

export type PractitionerRoleCodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type PractitionerRoleCodeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type PractitionerRoleCodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type PractitionerRoleConnectFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  connect?: Maybe<PractitionerRoleConnectInput>;
};

export type PractitionerRoleConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _active?: Maybe<ElementConnectFieldInput>;
  period?: Maybe<PeriodConnectFieldInput>;
  practitioner?: Maybe<ReferenceConnectFieldInput>;
  organization?: Maybe<ReferenceConnectFieldInput>;
  code?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  location?: Maybe<Array<ReferenceConnectFieldInput>>;
  healthcareService?: Maybe<Array<ReferenceConnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  availableTime?: Maybe<Array<PractitionerRoleAvailableTimeConnectFieldInput>>;
  notAvailable?: Maybe<Array<PractitionerRoleNotAvailableConnectFieldInput>>;
  _availabilityExceptions?: Maybe<ElementConnectFieldInput>;
  endpoint?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type PractitionerRoleContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type PractitionerRoleContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type PractitionerRoleContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type PractitionerRoleContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type PractitionerRoleContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type PractitionerRoleContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type PractitionerRoleContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type PractitionerRoleContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type PractitionerRoleContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type PractitionerRoleContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type PractitionerRoleContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type PractitionerRoleContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type PractitionerRoleContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type PractitionerRoleContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type PractitionerRoleContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type PractitionerRoleContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type PractitionerRoleContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type PractitionerRoleContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type PractitionerRoleContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type PractitionerRoleContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type PractitionerRoleContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type PractitionerRoleContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type PractitionerRoleContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type PractitionerRoleContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type PractitionerRoleContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type PractitionerRoleContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type PractitionerRoleContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type PractitionerRoleContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type PractitionerRoleContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type PractitionerRoleContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type PractitionerRoleContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type PractitionerRoleContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type PractitionerRoleContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type PractitionerRoleContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type PractitionerRoleContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type PractitionerRoleContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type PractitionerRoleContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type PractitionerRoleContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type PractitionerRoleContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type PractitionerRoleContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type PractitionerRoleContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type PractitionerRoleContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type PractitionerRoleContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type PractitionerRoleContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type PractitionerRoleContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type PractitionerRoleContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type PractitionerRoleContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type PractitionerRoleContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type PractitionerRoleContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type PractitionerRoleContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type PractitionerRoleContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type PractitionerRoleContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type PractitionerRoleContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type PractitionerRoleContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type PractitionerRoleContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type PractitionerRoleContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type PractitionerRoleContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type PractitionerRoleContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type PractitionerRoleContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type PractitionerRoleContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type PractitionerRoleContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type PractitionerRoleContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type PractitionerRoleContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type PractitionerRoleCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  availabilityExceptions?: Maybe<Scalars['String']>;
  resourceType: PractitionerRoleResourceType;
  meta?: Maybe<PractitionerRoleMetaFieldInput>;
  _implicitRules?: Maybe<PractitionerRole_ImplicitrulesFieldInput>;
  _language?: Maybe<PractitionerRole_LanguageFieldInput>;
  text?: Maybe<PractitionerRoleTextFieldInput>;
  contained_Account?: Maybe<PractitionerRoleContainedAccountFieldInput>;
  contained_Appointment?: Maybe<PractitionerRoleContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<PractitionerRoleContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<PractitionerRoleContainedCoverageFieldInput>;
  contained_Device?: Maybe<PractitionerRoleContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<PractitionerRoleContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<PractitionerRoleContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<PractitionerRoleContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<PractitionerRoleContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<PractitionerRoleContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<PractitionerRoleContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<PractitionerRoleContainedLocationFieldInput>;
  contained_Organization?: Maybe<PractitionerRoleContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<PractitionerRoleContainedPatientFieldInput>;
  contained_Person?: Maybe<PractitionerRoleContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<PractitionerRoleContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<PractitionerRoleContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<PractitionerRoleContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<PractitionerRoleContainedScheduleFieldInput>;
  contained_Slot?: Maybe<PractitionerRoleContainedSlotFieldInput>;
  contained_Substance?: Maybe<PractitionerRoleContainedSubstanceFieldInput>;
  extension?: Maybe<PractitionerRoleExtensionFieldInput>;
  modifierExtension?: Maybe<PractitionerRoleModifierextensionFieldInput>;
  identifier?: Maybe<PractitionerRoleIdentifierFieldInput>;
  _active?: Maybe<PractitionerRole_ActiveFieldInput>;
  period?: Maybe<PractitionerRolePeriodFieldInput>;
  practitioner?: Maybe<PractitionerRolePractitionerFieldInput>;
  organization?: Maybe<PractitionerRoleOrganizationFieldInput>;
  code?: Maybe<PractitionerRoleCodeFieldInput>;
  specialty?: Maybe<PractitionerRoleSpecialtyFieldInput>;
  location?: Maybe<PractitionerRoleLocationFieldInput>;
  healthcareService?: Maybe<PractitionerRoleHealthcareserviceFieldInput>;
  telecom?: Maybe<PractitionerRoleTelecomFieldInput>;
  availableTime?: Maybe<PractitionerRoleAvailabletimeFieldInput>;
  notAvailable?: Maybe<PractitionerRoleNotavailableFieldInput>;
  _availabilityExceptions?: Maybe<PractitionerRole_AvailabilityexceptionsFieldInput>;
  endpoint?: Maybe<PractitionerRoleEndpointFieldInput>;
};

export type PractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type PractitionerRoleDeleteInput = {
  meta?: Maybe<PractitionerRoleMetaDeleteFieldInput>;
  _implicitRules?: Maybe<PractitionerRole_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<PractitionerRole_LanguageDeleteFieldInput>;
  text?: Maybe<PractitionerRoleTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<PractitionerRoleContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<PractitionerRoleContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<PractitionerRoleContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<PractitionerRoleContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<PractitionerRoleContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<PractitionerRoleContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<PractitionerRoleContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<PractitionerRoleContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<PractitionerRoleContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<PractitionerRoleContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<PractitionerRoleContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<PractitionerRoleContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<PractitionerRoleContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<PractitionerRoleContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<PractitionerRoleContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerRoleContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<PractitionerRoleContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<PractitionerRoleContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<PractitionerRoleContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<PractitionerRoleContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<PractitionerRoleExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerRoleModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<PractitionerRoleIdentifierDeleteFieldInput>>;
  _active?: Maybe<PractitionerRole_ActiveDeleteFieldInput>;
  period?: Maybe<PractitionerRolePeriodDeleteFieldInput>;
  practitioner?: Maybe<PractitionerRolePractitionerDeleteFieldInput>;
  organization?: Maybe<PractitionerRoleOrganizationDeleteFieldInput>;
  code?: Maybe<Array<PractitionerRoleCodeDeleteFieldInput>>;
  specialty?: Maybe<Array<PractitionerRoleSpecialtyDeleteFieldInput>>;
  location?: Maybe<Array<PractitionerRoleLocationDeleteFieldInput>>;
  healthcareService?: Maybe<Array<PractitionerRoleHealthcareserviceDeleteFieldInput>>;
  telecom?: Maybe<Array<PractitionerRoleTelecomDeleteFieldInput>>;
  availableTime?: Maybe<Array<PractitionerRoleAvailabletimeDeleteFieldInput>>;
  notAvailable?: Maybe<Array<PractitionerRoleNotavailableDeleteFieldInput>>;
  _availabilityExceptions?: Maybe<PractitionerRole_AvailabilityexceptionsDeleteFieldInput>;
  endpoint?: Maybe<Array<PractitionerRoleEndpointDeleteFieldInput>>;
};

export type PractitionerRoleDisconnectFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  disconnect?: Maybe<PractitionerRoleDisconnectInput>;
};

export type PractitionerRoleDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _active?: Maybe<ElementDisconnectFieldInput>;
  period?: Maybe<PeriodDisconnectFieldInput>;
  practitioner?: Maybe<ReferenceDisconnectFieldInput>;
  organization?: Maybe<ReferenceDisconnectFieldInput>;
  code?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  location?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  healthcareService?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  availableTime?: Maybe<Array<PractitionerRoleAvailableTimeDisconnectFieldInput>>;
  notAvailable?: Maybe<Array<PractitionerRoleNotAvailableDisconnectFieldInput>>;
  _availabilityExceptions?: Maybe<ElementDisconnectFieldInput>;
  endpoint?: Maybe<Array<ReferenceDisconnectFieldInput>>;
};

export type PractitionerRoleEndpointDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PractitionerRoleEndpointFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type PractitionerRoleEndpointUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type PractitionerRoleExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerRoleExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerRoleExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PractitionerRoleHealthcareserviceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PractitionerRoleHealthcareserviceFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type PractitionerRoleHealthcareserviceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type PractitionerRoleIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type PractitionerRoleIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type PractitionerRoleIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type PractitionerRoleLocationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PractitionerRoleLocationFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type PractitionerRoleLocationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type PractitionerRoleMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type PractitionerRoleMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type PractitionerRoleMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type PractitionerRoleModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerRoleModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerRoleModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleNotAvailable = {
  __typename?: 'PractitionerRoleNotAvailable';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The reason that can be presented to the user as to why this time is not available. */
  description?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _description?: Maybe<Element>;
  during?: Maybe<Period>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleNotAvailableExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleNotAvailableModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleNotAvailable_DescriptionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
export type PractitionerRoleNotAvailableDuringArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type PractitionerRoleNotAvailableConnectFieldInput = {
  where?: Maybe<PractitionerRoleNotAvailableWhere>;
  connect?: Maybe<PractitionerRoleNotAvailableConnectInput>;
};

export type PractitionerRoleNotAvailableConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _description?: Maybe<ElementConnectFieldInput>;
  during?: Maybe<PeriodConnectFieldInput>;
};

export type PractitionerRoleNotAvailableCreateInput = {
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<PractitionerRoleNotAvailableExtensionFieldInput>;
  modifierExtension?: Maybe<PractitionerRoleNotAvailableModifierextensionFieldInput>;
  _description?: Maybe<PractitionerRoleNotAvailable_DescriptionFieldInput>;
  during?: Maybe<PractitionerRoleNotAvailableDuringFieldInput>;
};

export type PractitionerRoleNotAvailableDeleteFieldInput = {
  where?: Maybe<PractitionerRoleNotAvailableWhere>;
  delete?: Maybe<PractitionerRoleNotAvailableDeleteInput>;
};

export type PractitionerRoleNotAvailableDeleteInput = {
  extension?: Maybe<Array<PractitionerRoleNotAvailableExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerRoleNotAvailableModifierextensionDeleteFieldInput>>;
  _description?: Maybe<PractitionerRoleNotAvailable_DescriptionDeleteFieldInput>;
  during?: Maybe<PractitionerRoleNotAvailableDuringDeleteFieldInput>;
};

export type PractitionerRoleNotAvailableDisconnectFieldInput = {
  where?: Maybe<PractitionerRoleNotAvailableWhere>;
  disconnect?: Maybe<PractitionerRoleNotAvailableDisconnectInput>;
};

export type PractitionerRoleNotAvailableDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _description?: Maybe<ElementDisconnectFieldInput>;
  during?: Maybe<PeriodDisconnectFieldInput>;
};

export type PractitionerRoleNotAvailableDuringDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type PractitionerRoleNotAvailableDuringFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type PractitionerRoleNotAvailableDuringUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type PractitionerRoleNotAvailableExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerRoleNotAvailableExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerRoleNotAvailableExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PractitionerRoleNotAvailableModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type PractitionerRoleNotAvailableModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type PractitionerRoleNotAvailableModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type PractitionerRoleNotAvailableOptions = {
  /** Specify one or more PractitionerRoleNotAvailableSort objects to sort PractitionerRoleNotAvailables by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PractitionerRoleNotAvailableSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PractitionerRoleNotAvailableRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _description?: Maybe<ElementCreateInput>;
  during?: Maybe<PeriodCreateInput>;
};

/** Fields to sort PractitionerRoleNotAvailables by. The order in which sorts are applied is not guaranteed when specifying many fields in one PractitionerRoleNotAvailableSort object. */
export type PractitionerRoleNotAvailableSort = {
  id?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
};

export type PractitionerRoleNotAvailableUpdateInput = {
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<PractitionerRoleNotAvailableExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerRoleNotAvailableModifierextensionUpdateFieldInput>>;
  _description?: Maybe<PractitionerRoleNotAvailable_DescriptionUpdateFieldInput>;
  during?: Maybe<PractitionerRoleNotAvailableDuringUpdateFieldInput>;
};

export type PractitionerRoleNotAvailableWhere = {
  OR?: Maybe<Array<PractitionerRoleNotAvailableWhere>>;
  AND?: Maybe<Array<PractitionerRoleNotAvailableWhere>>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _description?: Maybe<ElementWhere>;
  _description_NOT?: Maybe<ElementWhere>;
  _description_IN?: Maybe<Array<ElementWhere>>;
  _description_NOT_IN?: Maybe<Array<ElementWhere>>;
  during?: Maybe<PeriodWhere>;
  during_NOT?: Maybe<PeriodWhere>;
  during_IN?: Maybe<Array<PeriodWhere>>;
  during_NOT_IN?: Maybe<Array<PeriodWhere>>;
};

export type PractitionerRoleNotAvailable_DescriptionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PractitionerRoleNotAvailable_DescriptionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PractitionerRoleNotAvailable_DescriptionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PractitionerRoleNotavailableDeleteFieldInput = {
  where?: Maybe<PractitionerRoleNotAvailableWhere>;
  delete?: Maybe<PractitionerRoleNotAvailableDeleteInput>;
};

export type PractitionerRoleNotavailableFieldInput = {
  create?: Maybe<Array<PractitionerRoleNotAvailableCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleNotAvailableConnectFieldInput>>;
};

export type PractitionerRoleNotavailableUpdateFieldInput = {
  where?: Maybe<PractitionerRoleNotAvailableWhere>;
  update?: Maybe<PractitionerRoleNotAvailableUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleNotAvailableConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleNotAvailableDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleNotAvailableCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleNotAvailableDeleteFieldInput>>;
};

export type PractitionerRoleOptions = {
  /** Specify one or more PractitionerRoleSort objects to sort PractitionerRoles by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<PractitionerRoleSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PractitionerRoleOrganizationDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PractitionerRoleOrganizationFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type PractitionerRoleOrganizationUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type PractitionerRolePeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type PractitionerRolePeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type PractitionerRolePeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type PractitionerRolePractitionerDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type PractitionerRolePractitionerFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type PractitionerRolePractitionerUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type PractitionerRoleRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _active?: Maybe<ElementCreateInput>;
  period?: Maybe<PeriodCreateInput>;
  practitioner?: Maybe<ReferenceCreateInput>;
  organization?: Maybe<ReferenceCreateInput>;
  code?: Maybe<Array<CodeableConceptCreateInput>>;
  specialty?: Maybe<Array<CodeableConceptCreateInput>>;
  location?: Maybe<Array<ReferenceCreateInput>>;
  healthcareService?: Maybe<Array<ReferenceCreateInput>>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  availableTime?: Maybe<Array<PractitionerRoleAvailableTimeCreateInput>>;
  notAvailable?: Maybe<Array<PractitionerRoleNotAvailableCreateInput>>;
  _availabilityExceptions?: Maybe<ElementCreateInput>;
  endpoint?: Maybe<Array<ReferenceCreateInput>>;
};

export enum PractitionerRoleResourceType {
  PractitionerRole = 'PractitionerRole',
}

/** Fields to sort PractitionerRoles by. The order in which sorts are applied is not guaranteed when specifying many fields in one PractitionerRoleSort object. */
export type PractitionerRoleSort = {
  id?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  availabilityExceptions?: Maybe<SortDirection>;
};

export type PractitionerRoleSpecialtyDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type PractitionerRoleSpecialtyFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type PractitionerRoleSpecialtyUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type PractitionerRoleTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type PractitionerRoleTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type PractitionerRoleTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type PractitionerRoleTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type PractitionerRoleTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type PractitionerRoleTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type PractitionerRoleUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  availabilityExceptions?: Maybe<Scalars['String']>;
  resourceType?: Maybe<PractitionerRoleResourceType>;
  meta?: Maybe<PractitionerRoleMetaUpdateFieldInput>;
  _implicitRules?: Maybe<PractitionerRole_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<PractitionerRole_LanguageUpdateFieldInput>;
  text?: Maybe<PractitionerRoleTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<PractitionerRoleContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<PractitionerRoleContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<PractitionerRoleContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<PractitionerRoleContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<PractitionerRoleContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<PractitionerRoleContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<PractitionerRoleContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<PractitionerRoleContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<PractitionerRoleContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<PractitionerRoleContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<PractitionerRoleContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<PractitionerRoleContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<PractitionerRoleContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<PractitionerRoleContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<PractitionerRoleContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerRoleContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<PractitionerRoleContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<PractitionerRoleContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<PractitionerRoleContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<PractitionerRoleContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<PractitionerRoleExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerRoleModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<PractitionerRoleIdentifierUpdateFieldInput>>;
  _active?: Maybe<PractitionerRole_ActiveUpdateFieldInput>;
  period?: Maybe<PractitionerRolePeriodUpdateFieldInput>;
  practitioner?: Maybe<PractitionerRolePractitionerUpdateFieldInput>;
  organization?: Maybe<PractitionerRoleOrganizationUpdateFieldInput>;
  code?: Maybe<Array<PractitionerRoleCodeUpdateFieldInput>>;
  specialty?: Maybe<Array<PractitionerRoleSpecialtyUpdateFieldInput>>;
  location?: Maybe<Array<PractitionerRoleLocationUpdateFieldInput>>;
  healthcareService?: Maybe<Array<PractitionerRoleHealthcareserviceUpdateFieldInput>>;
  telecom?: Maybe<Array<PractitionerRoleTelecomUpdateFieldInput>>;
  availableTime?: Maybe<Array<PractitionerRoleAvailabletimeUpdateFieldInput>>;
  notAvailable?: Maybe<Array<PractitionerRoleNotavailableUpdateFieldInput>>;
  _availabilityExceptions?: Maybe<PractitionerRole_AvailabilityexceptionsUpdateFieldInput>;
  endpoint?: Maybe<Array<PractitionerRoleEndpointUpdateFieldInput>>;
};

export type PractitionerRoleWhere = {
  OR?: Maybe<Array<PractitionerRoleWhere>>;
  AND?: Maybe<Array<PractitionerRoleWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  availabilityExceptions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<PractitionerRoleResourceType>;
  resourceType_NOT?: Maybe<PractitionerRoleResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<PractitionerRoleResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<PractitionerRoleResourceType>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _active?: Maybe<ElementWhere>;
  _active_NOT?: Maybe<ElementWhere>;
  _active_IN?: Maybe<Array<ElementWhere>>;
  _active_NOT_IN?: Maybe<Array<ElementWhere>>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
  practitioner?: Maybe<ReferenceWhere>;
  practitioner_NOT?: Maybe<ReferenceWhere>;
  practitioner_IN?: Maybe<Array<ReferenceWhere>>;
  practitioner_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  organization?: Maybe<ReferenceWhere>;
  organization_NOT?: Maybe<ReferenceWhere>;
  organization_IN?: Maybe<Array<ReferenceWhere>>;
  organization_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  code?: Maybe<CodeableConceptWhere>;
  code_NOT?: Maybe<CodeableConceptWhere>;
  specialty?: Maybe<CodeableConceptWhere>;
  specialty_NOT?: Maybe<CodeableConceptWhere>;
  location?: Maybe<ReferenceWhere>;
  location_NOT?: Maybe<ReferenceWhere>;
  healthcareService?: Maybe<ReferenceWhere>;
  healthcareService_NOT?: Maybe<ReferenceWhere>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  availableTime?: Maybe<PractitionerRoleAvailableTimeWhere>;
  availableTime_NOT?: Maybe<PractitionerRoleAvailableTimeWhere>;
  notAvailable?: Maybe<PractitionerRoleNotAvailableWhere>;
  notAvailable_NOT?: Maybe<PractitionerRoleNotAvailableWhere>;
  _availabilityExceptions?: Maybe<ElementWhere>;
  _availabilityExceptions_NOT?: Maybe<ElementWhere>;
  _availabilityExceptions_IN?: Maybe<Array<ElementWhere>>;
  _availabilityExceptions_NOT_IN?: Maybe<Array<ElementWhere>>;
  endpoint?: Maybe<ReferenceWhere>;
  endpoint_NOT?: Maybe<ReferenceWhere>;
};

export type PractitionerRole_ActiveDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PractitionerRole_ActiveFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PractitionerRole_ActiveUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PractitionerRole_AvailabilityexceptionsDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PractitionerRole_AvailabilityexceptionsFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PractitionerRole_AvailabilityexceptionsUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PractitionerRole_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PractitionerRole_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PractitionerRole_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type PractitionerRole_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type PractitionerRole_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type PractitionerRole_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Fields to sort Practitioners by. The order in which sorts are applied is not guaranteed when specifying many fields in one PractitionerSort object. */
export type PractitionerSort = {
  id?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  gender?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  birthDate?: Maybe<SortDirection>;
};

export type PractitionerTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type PractitionerTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type PractitionerTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type PractitionerTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type PractitionerTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type PractitionerTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type PractitionerUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  resourceType?: Maybe<PractitionerResourceType>;
  gender?: Maybe<PractitionerGender>;
  meta?: Maybe<PractitionerMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Practitioner_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Practitioner_LanguageUpdateFieldInput>;
  text?: Maybe<PractitionerTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<PractitionerContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<PractitionerContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<PractitionerContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<PractitionerContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<PractitionerContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<PractitionerContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<PractitionerContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<PractitionerContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<PractitionerContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<PractitionerContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<PractitionerContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<PractitionerContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<PractitionerContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<PractitionerContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<PractitionerContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<PractitionerContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<PractitionerContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<PractitionerContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<PractitionerContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<PractitionerExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<PractitionerModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<PractitionerIdentifierUpdateFieldInput>>;
  _active?: Maybe<Practitioner_ActiveUpdateFieldInput>;
  name?: Maybe<Array<PractitionerNameUpdateFieldInput>>;
  telecom?: Maybe<Array<PractitionerTelecomUpdateFieldInput>>;
  address?: Maybe<Array<PractitionerAddressUpdateFieldInput>>;
  _gender?: Maybe<Practitioner_GenderUpdateFieldInput>;
  _birthDate?: Maybe<Practitioner_BirthdateUpdateFieldInput>;
  photo?: Maybe<Array<PractitionerPhotoUpdateFieldInput>>;
  qualification?: Maybe<Array<PractitionerQualificationUpdateFieldInput>>;
  communication?: Maybe<Array<PractitionerCommunicationUpdateFieldInput>>;
};

export type PractitionerWhere = {
  OR?: Maybe<Array<PractitionerWhere>>;
  AND?: Maybe<Array<PractitionerWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<PractitionerResourceType>;
  resourceType_NOT?: Maybe<PractitionerResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<PractitionerResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<PractitionerResourceType>>>;
  gender?: Maybe<PractitionerGender>;
  gender_NOT?: Maybe<PractitionerGender>;
  gender_IN?: Maybe<Array<Maybe<PractitionerGender>>>;
  gender_NOT_IN?: Maybe<Array<Maybe<PractitionerGender>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _active?: Maybe<ElementWhere>;
  _active_NOT?: Maybe<ElementWhere>;
  _active_IN?: Maybe<Array<ElementWhere>>;
  _active_NOT_IN?: Maybe<Array<ElementWhere>>;
  name?: Maybe<HumanNameWhere>;
  name_NOT?: Maybe<HumanNameWhere>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  address?: Maybe<AddressWhere>;
  address_NOT?: Maybe<AddressWhere>;
  _gender?: Maybe<ElementWhere>;
  _gender_NOT?: Maybe<ElementWhere>;
  _gender_IN?: Maybe<Array<ElementWhere>>;
  _gender_NOT_IN?: Maybe<Array<ElementWhere>>;
  _birthDate?: Maybe<ElementWhere>;
  _birthDate_NOT?: Maybe<ElementWhere>;
  _birthDate_IN?: Maybe<Array<ElementWhere>>;
  _birthDate_NOT_IN?: Maybe<Array<ElementWhere>>;
  photo?: Maybe<AttachmentWhere>;
  photo_NOT?: Maybe<AttachmentWhere>;
  qualification?: Maybe<PractitionerQualificationWhere>;
  qualification_NOT?: Maybe<PractitionerQualificationWhere>;
  communication?: Maybe<CodeableConceptWhere>;
  communication_NOT?: Maybe<CodeableConceptWhere>;
};

export type Practitioner_ActiveDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Practitioner_ActiveFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Practitioner_ActiveUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Practitioner_BirthdateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Practitioner_BirthdateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Practitioner_BirthdateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Practitioner_GenderDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Practitioner_GenderFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Practitioner_GenderUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Practitioner_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Practitioner_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Practitioner_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Practitioner_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Practitioner_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Practitioner_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristic = {
  __typename?: 'ProdCharacteristic';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Where applicable, the shape can be specified An appropriate controlled vocabulary shall be used The term and the term identifier shall be used. */
  shape?: Maybe<Scalars['String']>;
  /** Where applicable, the color can be specified An appropriate controlled vocabulary shall be used The term and the term identifier shall be used. */
  color: Array<Scalars['String']>;
  /** Where applicable, the imprint can be specified as text. */
  imprint: Array<Scalars['String']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  height?: Maybe<Quantity>;
  width?: Maybe<Quantity>;
  depth?: Maybe<Quantity>;
  weight?: Maybe<Quantity>;
  nominalVolume?: Maybe<Quantity>;
  externalDiameter?: Maybe<Quantity>;
  _shape?: Maybe<Element>;
  _color: Array<Element>;
  _imprint: Array<Element>;
  image: Array<Attachment>;
  scoring?: Maybe<CodeableConcept>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicHeightArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicWidthArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicDepthArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicWeightArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicNominalVolumeArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicExternalDiameterArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristic_ShapeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristic_ColorArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristic_ImprintArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicImageArgs = {
  where?: Maybe<AttachmentWhere>;
  options?: Maybe<AttachmentOptions>;
};

/** The marketing status describes the date when a medicinal product is actually put on the market or the date as of which it is no longer available. */
export type ProdCharacteristicScoringArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

export type ProdCharacteristicConnectFieldInput = {
  where?: Maybe<ProdCharacteristicWhere>;
  connect?: Maybe<ProdCharacteristicConnectInput>;
};

export type ProdCharacteristicConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  height?: Maybe<QuantityConnectFieldInput>;
  width?: Maybe<QuantityConnectFieldInput>;
  depth?: Maybe<QuantityConnectFieldInput>;
  weight?: Maybe<QuantityConnectFieldInput>;
  nominalVolume?: Maybe<QuantityConnectFieldInput>;
  externalDiameter?: Maybe<QuantityConnectFieldInput>;
  _shape?: Maybe<ElementConnectFieldInput>;
  _color?: Maybe<Array<ElementConnectFieldInput>>;
  _imprint?: Maybe<Array<ElementConnectFieldInput>>;
  image?: Maybe<Array<AttachmentConnectFieldInput>>;
  scoring?: Maybe<CodeableConceptConnectFieldInput>;
};

export type ProdCharacteristicCreateInput = {
  shape?: Maybe<Scalars['String']>;
  color: Array<Scalars['String']>;
  imprint: Array<Scalars['String']>;
  extension?: Maybe<ProdCharacteristicExtensionFieldInput>;
  modifierExtension?: Maybe<ProdCharacteristicModifierextensionFieldInput>;
  height?: Maybe<ProdCharacteristicHeightFieldInput>;
  width?: Maybe<ProdCharacteristicWidthFieldInput>;
  depth?: Maybe<ProdCharacteristicDepthFieldInput>;
  weight?: Maybe<ProdCharacteristicWeightFieldInput>;
  nominalVolume?: Maybe<ProdCharacteristicNominalvolumeFieldInput>;
  externalDiameter?: Maybe<ProdCharacteristicExternaldiameterFieldInput>;
  _shape?: Maybe<ProdCharacteristic_ShapeFieldInput>;
  _color?: Maybe<ProdCharacteristic_ColorFieldInput>;
  _imprint?: Maybe<ProdCharacteristic_ImprintFieldInput>;
  image?: Maybe<ProdCharacteristicImageFieldInput>;
  scoring?: Maybe<ProdCharacteristicScoringFieldInput>;
};

export type ProdCharacteristicDeleteFieldInput = {
  where?: Maybe<ProdCharacteristicWhere>;
  delete?: Maybe<ProdCharacteristicDeleteInput>;
};

export type ProdCharacteristicDeleteInput = {
  extension?: Maybe<Array<ProdCharacteristicExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<ProdCharacteristicModifierextensionDeleteFieldInput>>;
  height?: Maybe<ProdCharacteristicHeightDeleteFieldInput>;
  width?: Maybe<ProdCharacteristicWidthDeleteFieldInput>;
  depth?: Maybe<ProdCharacteristicDepthDeleteFieldInput>;
  weight?: Maybe<ProdCharacteristicWeightDeleteFieldInput>;
  nominalVolume?: Maybe<ProdCharacteristicNominalvolumeDeleteFieldInput>;
  externalDiameter?: Maybe<ProdCharacteristicExternaldiameterDeleteFieldInput>;
  _shape?: Maybe<ProdCharacteristic_ShapeDeleteFieldInput>;
  _color?: Maybe<Array<ProdCharacteristic_ColorDeleteFieldInput>>;
  _imprint?: Maybe<Array<ProdCharacteristic_ImprintDeleteFieldInput>>;
  image?: Maybe<Array<ProdCharacteristicImageDeleteFieldInput>>;
  scoring?: Maybe<ProdCharacteristicScoringDeleteFieldInput>;
};

export type ProdCharacteristicDepthDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type ProdCharacteristicDepthFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type ProdCharacteristicDepthUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type ProdCharacteristicDisconnectFieldInput = {
  where?: Maybe<ProdCharacteristicWhere>;
  disconnect?: Maybe<ProdCharacteristicDisconnectInput>;
};

export type ProdCharacteristicDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  height?: Maybe<QuantityDisconnectFieldInput>;
  width?: Maybe<QuantityDisconnectFieldInput>;
  depth?: Maybe<QuantityDisconnectFieldInput>;
  weight?: Maybe<QuantityDisconnectFieldInput>;
  nominalVolume?: Maybe<QuantityDisconnectFieldInput>;
  externalDiameter?: Maybe<QuantityDisconnectFieldInput>;
  _shape?: Maybe<ElementDisconnectFieldInput>;
  _color?: Maybe<Array<ElementDisconnectFieldInput>>;
  _imprint?: Maybe<Array<ElementDisconnectFieldInput>>;
  image?: Maybe<Array<AttachmentDisconnectFieldInput>>;
  scoring?: Maybe<CodeableConceptDisconnectFieldInput>;
};

export type ProdCharacteristicExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ProdCharacteristicExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ProdCharacteristicExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ProdCharacteristicExternaldiameterDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type ProdCharacteristicExternaldiameterFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type ProdCharacteristicExternaldiameterUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type ProdCharacteristicHeightDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type ProdCharacteristicHeightFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type ProdCharacteristicHeightUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type ProdCharacteristicImageDeleteFieldInput = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type ProdCharacteristicImageFieldInput = {
  create?: Maybe<Array<AttachmentCreateInput>>;
  connect?: Maybe<Array<AttachmentConnectFieldInput>>;
};

export type ProdCharacteristicImageUpdateFieldInput = {
  where?: Maybe<AttachmentWhere>;
  update?: Maybe<AttachmentUpdateInput>;
  connect?: Maybe<Array<AttachmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AttachmentDisconnectFieldInput>>;
  create?: Maybe<Array<AttachmentCreateInput>>;
  delete?: Maybe<Array<AttachmentDeleteFieldInput>>;
};

export type ProdCharacteristicModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ProdCharacteristicModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ProdCharacteristicModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ProdCharacteristicNominalvolumeDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type ProdCharacteristicNominalvolumeFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type ProdCharacteristicNominalvolumeUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type ProdCharacteristicOptions = {
  /** Specify one or more ProdCharacteristicSort objects to sort ProdCharacteristics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ProdCharacteristicSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ProdCharacteristicRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  height?: Maybe<QuantityCreateInput>;
  width?: Maybe<QuantityCreateInput>;
  depth?: Maybe<QuantityCreateInput>;
  weight?: Maybe<QuantityCreateInput>;
  nominalVolume?: Maybe<QuantityCreateInput>;
  externalDiameter?: Maybe<QuantityCreateInput>;
  _shape?: Maybe<ElementCreateInput>;
  _color?: Maybe<Array<ElementCreateInput>>;
  _imprint?: Maybe<Array<ElementCreateInput>>;
  image?: Maybe<Array<AttachmentCreateInput>>;
  scoring?: Maybe<CodeableConceptCreateInput>;
};

export type ProdCharacteristicScoringDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type ProdCharacteristicScoringFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type ProdCharacteristicScoringUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

/** Fields to sort ProdCharacteristics by. The order in which sorts are applied is not guaranteed when specifying many fields in one ProdCharacteristicSort object. */
export type ProdCharacteristicSort = {
  id?: Maybe<SortDirection>;
  shape?: Maybe<SortDirection>;
};

export type ProdCharacteristicUpdateInput = {
  shape?: Maybe<Scalars['String']>;
  color?: Maybe<Array<Scalars['String']>>;
  imprint?: Maybe<Array<Scalars['String']>>;
  extension?: Maybe<Array<ProdCharacteristicExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<ProdCharacteristicModifierextensionUpdateFieldInput>>;
  height?: Maybe<ProdCharacteristicHeightUpdateFieldInput>;
  width?: Maybe<ProdCharacteristicWidthUpdateFieldInput>;
  depth?: Maybe<ProdCharacteristicDepthUpdateFieldInput>;
  weight?: Maybe<ProdCharacteristicWeightUpdateFieldInput>;
  nominalVolume?: Maybe<ProdCharacteristicNominalvolumeUpdateFieldInput>;
  externalDiameter?: Maybe<ProdCharacteristicExternaldiameterUpdateFieldInput>;
  _shape?: Maybe<ProdCharacteristic_ShapeUpdateFieldInput>;
  _color?: Maybe<Array<ProdCharacteristic_ColorUpdateFieldInput>>;
  _imprint?: Maybe<Array<ProdCharacteristic_ImprintUpdateFieldInput>>;
  image?: Maybe<Array<ProdCharacteristicImageUpdateFieldInput>>;
  scoring?: Maybe<ProdCharacteristicScoringUpdateFieldInput>;
};

export type ProdCharacteristicWeightDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type ProdCharacteristicWeightFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type ProdCharacteristicWeightUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type ProdCharacteristicWhere = {
  OR?: Maybe<Array<ProdCharacteristicWhere>>;
  AND?: Maybe<Array<ProdCharacteristicWhere>>;
  shape?: Maybe<Scalars['String']>;
  color?: Maybe<Array<Maybe<Scalars['String']>>>;
  imprint?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  height?: Maybe<QuantityWhere>;
  height_NOT?: Maybe<QuantityWhere>;
  height_IN?: Maybe<Array<QuantityWhere>>;
  height_NOT_IN?: Maybe<Array<QuantityWhere>>;
  width?: Maybe<QuantityWhere>;
  width_NOT?: Maybe<QuantityWhere>;
  width_IN?: Maybe<Array<QuantityWhere>>;
  width_NOT_IN?: Maybe<Array<QuantityWhere>>;
  depth?: Maybe<QuantityWhere>;
  depth_NOT?: Maybe<QuantityWhere>;
  depth_IN?: Maybe<Array<QuantityWhere>>;
  depth_NOT_IN?: Maybe<Array<QuantityWhere>>;
  weight?: Maybe<QuantityWhere>;
  weight_NOT?: Maybe<QuantityWhere>;
  weight_IN?: Maybe<Array<QuantityWhere>>;
  weight_NOT_IN?: Maybe<Array<QuantityWhere>>;
  nominalVolume?: Maybe<QuantityWhere>;
  nominalVolume_NOT?: Maybe<QuantityWhere>;
  nominalVolume_IN?: Maybe<Array<QuantityWhere>>;
  nominalVolume_NOT_IN?: Maybe<Array<QuantityWhere>>;
  externalDiameter?: Maybe<QuantityWhere>;
  externalDiameter_NOT?: Maybe<QuantityWhere>;
  externalDiameter_IN?: Maybe<Array<QuantityWhere>>;
  externalDiameter_NOT_IN?: Maybe<Array<QuantityWhere>>;
  _shape?: Maybe<ElementWhere>;
  _shape_NOT?: Maybe<ElementWhere>;
  _shape_IN?: Maybe<Array<ElementWhere>>;
  _shape_NOT_IN?: Maybe<Array<ElementWhere>>;
  _color?: Maybe<ElementWhere>;
  _color_NOT?: Maybe<ElementWhere>;
  _imprint?: Maybe<ElementWhere>;
  _imprint_NOT?: Maybe<ElementWhere>;
  image?: Maybe<AttachmentWhere>;
  image_NOT?: Maybe<AttachmentWhere>;
  scoring?: Maybe<CodeableConceptWhere>;
  scoring_NOT?: Maybe<CodeableConceptWhere>;
  scoring_IN?: Maybe<Array<CodeableConceptWhere>>;
  scoring_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
};

export type ProdCharacteristicWidthDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type ProdCharacteristicWidthFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type ProdCharacteristicWidthUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type ProdCharacteristic_ColorDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ProdCharacteristic_ColorFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type ProdCharacteristic_ColorUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type ProdCharacteristic_ImprintDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ProdCharacteristic_ImprintFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type ProdCharacteristic_ImprintUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type ProdCharacteristic_ShapeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type ProdCharacteristic_ShapeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type ProdCharacteristic_ShapeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** The shelf-life and storage information for a medicinal product item or container can be described using this class. */
export type ProductShelfLife = {
  __typename?: 'ProductShelfLife';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier?: Maybe<Identifier>;
  type: CodeableConcept;
  period: Quantity;
  specialPrecautionsForStorage: Array<CodeableConcept>;
};

/** The shelf-life and storage information for a medicinal product item or container can be described using this class. */
export type ProductShelfLifeExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The shelf-life and storage information for a medicinal product item or container can be described using this class. */
export type ProductShelfLifeModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** The shelf-life and storage information for a medicinal product item or container can be described using this class. */
export type ProductShelfLifeIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** The shelf-life and storage information for a medicinal product item or container can be described using this class. */
export type ProductShelfLifeTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** The shelf-life and storage information for a medicinal product item or container can be described using this class. */
export type ProductShelfLifePeriodArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** The shelf-life and storage information for a medicinal product item or container can be described using this class. */
export type ProductShelfLifeSpecialPrecautionsForStorageArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

export type ProductShelfLifeConnectFieldInput = {
  where?: Maybe<ProductShelfLifeWhere>;
  connect?: Maybe<ProductShelfLifeConnectInput>;
};

export type ProductShelfLifeConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<IdentifierConnectFieldInput>;
  type?: Maybe<CodeableConceptConnectFieldInput>;
  period?: Maybe<QuantityConnectFieldInput>;
  specialPrecautionsForStorage?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type ProductShelfLifeCreateInput = {
  extension?: Maybe<ProductShelfLifeExtensionFieldInput>;
  modifierExtension?: Maybe<ProductShelfLifeModifierextensionFieldInput>;
  identifier?: Maybe<ProductShelfLifeIdentifierFieldInput>;
  type?: Maybe<ProductShelfLifeTypeFieldInput>;
  period?: Maybe<ProductShelfLifePeriodFieldInput>;
  specialPrecautionsForStorage?: Maybe<ProductShelfLifeSpecialprecautionsforstorageFieldInput>;
};

export type ProductShelfLifeDeleteFieldInput = {
  where?: Maybe<ProductShelfLifeWhere>;
  delete?: Maybe<ProductShelfLifeDeleteInput>;
};

export type ProductShelfLifeDeleteInput = {
  extension?: Maybe<Array<ProductShelfLifeExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<ProductShelfLifeModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<ProductShelfLifeIdentifierDeleteFieldInput>;
  type?: Maybe<ProductShelfLifeTypeDeleteFieldInput>;
  period?: Maybe<ProductShelfLifePeriodDeleteFieldInput>;
  specialPrecautionsForStorage?: Maybe<Array<ProductShelfLifeSpecialprecautionsforstorageDeleteFieldInput>>;
};

export type ProductShelfLifeDisconnectFieldInput = {
  where?: Maybe<ProductShelfLifeWhere>;
  disconnect?: Maybe<ProductShelfLifeDisconnectInput>;
};

export type ProductShelfLifeDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<IdentifierDisconnectFieldInput>;
  type?: Maybe<CodeableConceptDisconnectFieldInput>;
  period?: Maybe<QuantityDisconnectFieldInput>;
  specialPrecautionsForStorage?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
};

export type ProductShelfLifeExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ProductShelfLifeExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ProductShelfLifeExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ProductShelfLifeIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type ProductShelfLifeIdentifierFieldInput = {
  create?: Maybe<IdentifierCreateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
};

export type ProductShelfLifeIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
  disconnect?: Maybe<IdentifierDisconnectFieldInput>;
  create?: Maybe<IdentifierCreateInput>;
  delete?: Maybe<IdentifierDeleteFieldInput>;
};

export type ProductShelfLifeModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ProductShelfLifeModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ProductShelfLifeModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ProductShelfLifeOptions = {
  /** Specify one or more ProductShelfLifeSort objects to sort ProductShelfLives by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ProductShelfLifeSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ProductShelfLifePeriodDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type ProductShelfLifePeriodFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type ProductShelfLifePeriodUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type ProductShelfLifeRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<IdentifierCreateInput>;
  type?: Maybe<CodeableConceptCreateInput>;
  period?: Maybe<QuantityCreateInput>;
  specialPrecautionsForStorage?: Maybe<Array<CodeableConceptCreateInput>>;
};

/** Fields to sort ProductShelfLives by. The order in which sorts are applied is not guaranteed when specifying many fields in one ProductShelfLifeSort object. */
export type ProductShelfLifeSort = {
  id?: Maybe<SortDirection>;
};

export type ProductShelfLifeSpecialprecautionsforstorageDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type ProductShelfLifeSpecialprecautionsforstorageFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type ProductShelfLifeSpecialprecautionsforstorageUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type ProductShelfLifeTypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type ProductShelfLifeTypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type ProductShelfLifeTypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type ProductShelfLifeUpdateInput = {
  extension?: Maybe<Array<ProductShelfLifeExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<ProductShelfLifeModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<ProductShelfLifeIdentifierUpdateFieldInput>;
  type?: Maybe<ProductShelfLifeTypeUpdateFieldInput>;
  period?: Maybe<ProductShelfLifePeriodUpdateFieldInput>;
  specialPrecautionsForStorage?: Maybe<Array<ProductShelfLifeSpecialprecautionsforstorageUpdateFieldInput>>;
};

export type ProductShelfLifeWhere = {
  OR?: Maybe<Array<ProductShelfLifeWhere>>;
  AND?: Maybe<Array<ProductShelfLifeWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  identifier_IN?: Maybe<Array<IdentifierWhere>>;
  identifier_NOT_IN?: Maybe<Array<IdentifierWhere>>;
  type?: Maybe<CodeableConceptWhere>;
  type_NOT?: Maybe<CodeableConceptWhere>;
  type_IN?: Maybe<Array<CodeableConceptWhere>>;
  type_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  period?: Maybe<QuantityWhere>;
  period_NOT?: Maybe<QuantityWhere>;
  period_IN?: Maybe<Array<QuantityWhere>>;
  period_NOT_IN?: Maybe<Array<QuantityWhere>>;
  specialPrecautionsForStorage?: Maybe<CodeableConceptWhere>;
  specialPrecautionsForStorage_NOT?: Maybe<CodeableConceptWhere>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Quantity = {
  __typename?: 'Quantity';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
  comparator?: Maybe<QuantityComparator>;
  /** The value of the measured amount. The value includes an implicit precision in the presentation of the value. */
  value?: Maybe<Scalars['Decimal']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['String']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['Uri']>;
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['Code']>;
  extension: Array<Extension>;
  _value?: Maybe<Element>;
  _comparator?: Maybe<Element>;
  _unit?: Maybe<Element>;
  _system?: Maybe<Element>;
  _code?: Maybe<Element>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type QuantityExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Quantity_ValueArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Quantity_ComparatorArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Quantity_UnitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Quantity_SystemArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A measured amount (or an amount that can potentially be measured). Note that measured amounts include amounts that are not precisely quantified, including amounts involving arbitrary units and floating currencies. */
export type Quantity_CodeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** How the value should be understood and represented - whether the actual value is greater or less than the stated value due to measurement issues; e.g. if the comparator is "<" , then the real value is < stated value. */
export enum QuantityComparator {
  Lt = 'lt',
  Lte = 'lte',
  Gte = 'gte',
  Gt = 'gt',
}

export type QuantityConnectFieldInput = {
  where?: Maybe<QuantityWhere>;
  connect?: Maybe<QuantityConnectInput>;
};

export type QuantityConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _value?: Maybe<ElementConnectFieldInput>;
  _comparator?: Maybe<ElementConnectFieldInput>;
  _unit?: Maybe<ElementConnectFieldInput>;
  _system?: Maybe<ElementConnectFieldInput>;
  _code?: Maybe<ElementConnectFieldInput>;
};

export type QuantityCreateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<QuantityComparator>;
  extension?: Maybe<QuantityExtensionFieldInput>;
  _value?: Maybe<Quantity_ValueFieldInput>;
  _comparator?: Maybe<Quantity_ComparatorFieldInput>;
  _unit?: Maybe<Quantity_UnitFieldInput>;
  _system?: Maybe<Quantity_SystemFieldInput>;
  _code?: Maybe<Quantity_CodeFieldInput>;
};

export type QuantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type QuantityDeleteInput = {
  extension?: Maybe<Array<QuantityExtensionDeleteFieldInput>>;
  _value?: Maybe<Quantity_ValueDeleteFieldInput>;
  _comparator?: Maybe<Quantity_ComparatorDeleteFieldInput>;
  _unit?: Maybe<Quantity_UnitDeleteFieldInput>;
  _system?: Maybe<Quantity_SystemDeleteFieldInput>;
  _code?: Maybe<Quantity_CodeDeleteFieldInput>;
};

export type QuantityDisconnectFieldInput = {
  where?: Maybe<QuantityWhere>;
  disconnect?: Maybe<QuantityDisconnectInput>;
};

export type QuantityDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _value?: Maybe<ElementDisconnectFieldInput>;
  _comparator?: Maybe<ElementDisconnectFieldInput>;
  _unit?: Maybe<ElementDisconnectFieldInput>;
  _system?: Maybe<ElementDisconnectFieldInput>;
  _code?: Maybe<ElementDisconnectFieldInput>;
};

export type QuantityExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type QuantityExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type QuantityExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type QuantityOptions = {
  /** Specify one or more QuantitySort objects to sort Quantities by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<QuantitySort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuantityRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _value?: Maybe<ElementCreateInput>;
  _comparator?: Maybe<ElementCreateInput>;
  _unit?: Maybe<ElementCreateInput>;
  _system?: Maybe<ElementCreateInput>;
  _code?: Maybe<ElementCreateInput>;
};

/** Fields to sort Quantities by. The order in which sorts are applied is not guaranteed when specifying many fields in one QuantitySort object. */
export type QuantitySort = {
  id?: Maybe<SortDirection>;
  comparator?: Maybe<SortDirection>;
  value?: Maybe<SortDirection>;
  unit?: Maybe<SortDirection>;
  system?: Maybe<SortDirection>;
  code?: Maybe<SortDirection>;
};

export type QuantityUpdateInput = {
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  comparator?: Maybe<QuantityComparator>;
  extension?: Maybe<Array<QuantityExtensionUpdateFieldInput>>;
  _value?: Maybe<Quantity_ValueUpdateFieldInput>;
  _comparator?: Maybe<Quantity_ComparatorUpdateFieldInput>;
  _unit?: Maybe<Quantity_UnitUpdateFieldInput>;
  _system?: Maybe<Quantity_SystemUpdateFieldInput>;
  _code?: Maybe<Quantity_CodeUpdateFieldInput>;
};

export type QuantityWhere = {
  OR?: Maybe<Array<QuantityWhere>>;
  AND?: Maybe<Array<QuantityWhere>>;
  value?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Uri']>;
  code?: Maybe<Scalars['Code']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  comparator?: Maybe<QuantityComparator>;
  comparator_NOT?: Maybe<QuantityComparator>;
  comparator_IN?: Maybe<Array<Maybe<QuantityComparator>>>;
  comparator_NOT_IN?: Maybe<Array<Maybe<QuantityComparator>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _value?: Maybe<ElementWhere>;
  _value_NOT?: Maybe<ElementWhere>;
  _value_IN?: Maybe<Array<ElementWhere>>;
  _value_NOT_IN?: Maybe<Array<ElementWhere>>;
  _comparator?: Maybe<ElementWhere>;
  _comparator_NOT?: Maybe<ElementWhere>;
  _comparator_IN?: Maybe<Array<ElementWhere>>;
  _comparator_NOT_IN?: Maybe<Array<ElementWhere>>;
  _unit?: Maybe<ElementWhere>;
  _unit_NOT?: Maybe<ElementWhere>;
  _unit_IN?: Maybe<Array<ElementWhere>>;
  _unit_NOT_IN?: Maybe<Array<ElementWhere>>;
  _system?: Maybe<ElementWhere>;
  _system_NOT?: Maybe<ElementWhere>;
  _system_IN?: Maybe<Array<ElementWhere>>;
  _system_NOT_IN?: Maybe<Array<ElementWhere>>;
  _code?: Maybe<ElementWhere>;
  _code_NOT?: Maybe<ElementWhere>;
  _code_IN?: Maybe<Array<ElementWhere>>;
  _code_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Quantity_CodeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Quantity_CodeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Quantity_CodeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Quantity_ComparatorDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Quantity_ComparatorFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Quantity_ComparatorUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Quantity_SystemDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Quantity_SystemFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Quantity_SystemUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Quantity_UnitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Quantity_UnitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Quantity_UnitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Quantity_ValueDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Quantity_ValueFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Quantity_ValueUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Query = {
  __typename?: 'Query';
  accounts: Array<Maybe<Account>>;
  metas: Array<Maybe<Meta>>;
  extensions: Array<Maybe<Extension>>;
  elements: Array<Maybe<Element>>;
  addresses: Array<Maybe<Address>>;
  periods: Array<Maybe<Period>>;
  ages: Array<Maybe<Age>>;
  annotations: Array<Maybe<Annotation>>;
  references: Array<Maybe<Reference>>;
  identifiers: Array<Maybe<Identifier>>;
  codeableConcepts: Array<Maybe<CodeableConcept>>;
  codings: Array<Maybe<Coding>>;
  attachments: Array<Maybe<Attachment>>;
  contactPoints: Array<Maybe<ContactPoint>>;
  counts: Array<Maybe<Count>>;
  distances: Array<Maybe<Distance>>;
  durations: Array<Maybe<Duration>>;
  humanNames: Array<Maybe<HumanName>>;
  monies: Array<Maybe<Money>>;
  quantities: Array<Maybe<Quantity>>;
  ranges: Array<Maybe<Range>>;
  ratios: Array<Maybe<Ratio>>;
  sampledData: Array<Maybe<SampledData>>;
  signatures: Array<Maybe<Signature>>;
  timings: Array<Maybe<Timing>>;
  timingRepeats: Array<Maybe<TimingRepeat>>;
  contactDetails: Array<Maybe<ContactDetail>>;
  contributors: Array<Maybe<Contributor>>;
  dataRequirements: Array<Maybe<DataRequirement>>;
  dataRequirementCodeFilters: Array<Maybe<DataRequirementCodeFilter>>;
  dataRequirementDateFilters: Array<Maybe<DataRequirementDateFilter>>;
  dataRequirementCustomSorts: Array<Maybe<DataRequirementCustomSort>>;
  expressions: Array<Maybe<Expression>>;
  parameterDefinitions: Array<Maybe<ParameterDefinition>>;
  relatedArtifacts: Array<Maybe<RelatedArtifact>>;
  triggerDefinitions: Array<Maybe<TriggerDefinition>>;
  usageContexts: Array<Maybe<UsageContext>>;
  dosages: Array<Maybe<Dosage>>;
  dosageDoseAndRates: Array<Maybe<DosageDoseAndRate>>;
  narratives: Array<Maybe<Narrative>>;
  accountCoverages: Array<Maybe<AccountCoverage>>;
  accountGuarantors: Array<Maybe<AccountGuarantor>>;
  appointments: Array<Maybe<Appointment>>;
  appointmentParticipants: Array<Maybe<AppointmentParticipant>>;
  appointmentResponses: Array<Maybe<AppointmentResponse>>;
  coverages: Array<Maybe<Coverage>>;
  coverageClasses: Array<Maybe<CoverageClass>>;
  coverageCostToBeneficiaries: Array<Maybe<CoverageCostToBeneficiary>>;
  coverageExceptions: Array<Maybe<CoverageException>>;
  devices: Array<Maybe<Device>>;
  deviceUdiCarriers: Array<Maybe<DeviceUdiCarrier>>;
  deviceDeviceNames: Array<Maybe<DeviceDeviceName>>;
  deviceSpecializations: Array<Maybe<DeviceSpecialization>>;
  deviceVersions: Array<Maybe<DeviceVersion>>;
  deviceProperties: Array<Maybe<DeviceProperty>>;
  deviceDefinitions: Array<Maybe<DeviceDefinition>>;
  deviceDefinitionUdiDeviceIdentifiers: Array<Maybe<DeviceDefinitionUdiDeviceIdentifier>>;
  deviceDefinitionDeviceNames: Array<Maybe<DeviceDefinitionDeviceName>>;
  deviceDefinitionSpecializations: Array<Maybe<DeviceDefinitionSpecialization>>;
  productShelfLives: Array<Maybe<ProductShelfLife>>;
  prodCharacteristics: Array<Maybe<ProdCharacteristic>>;
  deviceDefinitionCapabilities: Array<Maybe<DeviceDefinitionCapability>>;
  deviceDefinitionProperties: Array<Maybe<DeviceDefinitionProperty>>;
  deviceDefinitionMaterials: Array<Maybe<DeviceDefinitionMaterial>>;
  deviceMetrics: Array<Maybe<DeviceMetric>>;
  deviceMetricCalibrations: Array<Maybe<DeviceMetricCalibration>>;
  encounters: Array<Maybe<Encounter>>;
  encounterStatusHistories: Array<Maybe<EncounterStatusHistory>>;
  encounterClassHistories: Array<Maybe<EncounterClassHistory>>;
  encounterParticipants: Array<Maybe<EncounterParticipant>>;
  encounterDiagnoses: Array<Maybe<EncounterDiagnosis>>;
  encounterHospitalizations: Array<Maybe<EncounterHospitalization>>;
  encounterLocations: Array<Maybe<EncounterLocation>>;
  endpoints: Array<Maybe<Endpoint>>;
  episodeOfCares: Array<Maybe<EpisodeOfCare>>;
  episodeOfCareStatusHistories: Array<Maybe<EpisodeOfCareStatusHistory>>;
  episodeOfCareDiagnoses: Array<Maybe<EpisodeOfCareDiagnosis>>;
  healthcareServices: Array<Maybe<HealthcareService>>;
  healthcareServiceEligibilities: Array<Maybe<HealthcareServiceEligibility>>;
  healthcareServiceAvailableTimes: Array<Maybe<HealthcareServiceAvailableTime>>;
  healthcareServiceNotAvailables: Array<Maybe<HealthcareServiceNotAvailable>>;
  locations: Array<Maybe<Location>>;
  locationPositions: Array<Maybe<LocationPosition>>;
  locationHoursOfOperations: Array<Maybe<LocationHoursOfOperation>>;
  organizations: Array<Maybe<Organization>>;
  organizationContacts: Array<Maybe<OrganizationContact>>;
  patients: Array<Maybe<Patient>>;
  patientContacts: Array<Maybe<PatientContact>>;
  patientCommunications: Array<Maybe<PatientCommunication>>;
  patientLinks: Array<Maybe<PatientLink>>;
  people: Array<Maybe<Person>>;
  personLinks: Array<Maybe<PersonLink>>;
  practitioners: Array<Maybe<Practitioner>>;
  practitionerQualifications: Array<Maybe<PractitionerQualification>>;
  practitionerRoles: Array<Maybe<PractitionerRole>>;
  practitionerRoleAvailableTimes: Array<Maybe<PractitionerRoleAvailableTime>>;
  practitionerRoleNotAvailables: Array<Maybe<PractitionerRoleNotAvailable>>;
  relatedPeople: Array<Maybe<RelatedPerson>>;
  relatedPersonCommunications: Array<Maybe<RelatedPersonCommunication>>;
  schedules: Array<Maybe<Schedule>>;
  slots: Array<Maybe<Slot>>;
  substances: Array<Maybe<Substance>>;
  substanceInstances: Array<Maybe<SubstanceInstance>>;
  substanceIngredients: Array<Maybe<SubstanceIngredient>>;
  users: Array<Maybe<User>>;
  viewer: User;
  validateResetPassword: Scalars['Boolean'];
  emailAvailable: Scalars['Boolean'];
};

export type QueryAccountsArgs = {
  where?: Maybe<AccountWhere>;
  options?: Maybe<AccountOptions>;
};

export type QueryMetasArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

export type QueryExtensionsArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

export type QueryElementsArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type QueryAddressesArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

export type QueryPeriodsArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

export type QueryAgesArgs = {
  where?: Maybe<AgeWhere>;
  options?: Maybe<AgeOptions>;
};

export type QueryAnnotationsArgs = {
  where?: Maybe<AnnotationWhere>;
  options?: Maybe<AnnotationOptions>;
};

export type QueryReferencesArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type QueryIdentifiersArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

export type QueryCodeableConceptsArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

export type QueryCodingsArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

export type QueryAttachmentsArgs = {
  where?: Maybe<AttachmentWhere>;
  options?: Maybe<AttachmentOptions>;
};

export type QueryContactPointsArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

export type QueryCountsArgs = {
  where?: Maybe<CountWhere>;
  options?: Maybe<CountOptions>;
};

export type QueryDistancesArgs = {
  where?: Maybe<DistanceWhere>;
  options?: Maybe<DistanceOptions>;
};

export type QueryDurationsArgs = {
  where?: Maybe<DurationWhere>;
  options?: Maybe<DurationOptions>;
};

export type QueryHumanNamesArgs = {
  where?: Maybe<HumanNameWhere>;
  options?: Maybe<HumanNameOptions>;
};

export type QueryMoniesArgs = {
  where?: Maybe<MoneyWhere>;
  options?: Maybe<MoneyOptions>;
};

export type QueryQuantitiesArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

export type QueryRangesArgs = {
  where?: Maybe<RangeWhere>;
  options?: Maybe<RangeOptions>;
};

export type QueryRatiosArgs = {
  where?: Maybe<RatioWhere>;
  options?: Maybe<RatioOptions>;
};

export type QuerySampledDataArgs = {
  where?: Maybe<SampledDataWhere>;
  options?: Maybe<SampledDataOptions>;
};

export type QuerySignaturesArgs = {
  where?: Maybe<SignatureWhere>;
  options?: Maybe<SignatureOptions>;
};

export type QueryTimingsArgs = {
  where?: Maybe<TimingWhere>;
  options?: Maybe<TimingOptions>;
};

export type QueryTimingRepeatsArgs = {
  where?: Maybe<TimingRepeatWhere>;
  options?: Maybe<TimingRepeatOptions>;
};

export type QueryContactDetailsArgs = {
  where?: Maybe<ContactDetailWhere>;
  options?: Maybe<ContactDetailOptions>;
};

export type QueryContributorsArgs = {
  where?: Maybe<ContributorWhere>;
  options?: Maybe<ContributorOptions>;
};

export type QueryDataRequirementsArgs = {
  where?: Maybe<DataRequirementWhere>;
  options?: Maybe<DataRequirementOptions>;
};

export type QueryDataRequirementCodeFiltersArgs = {
  where?: Maybe<DataRequirementCodeFilterWhere>;
  options?: Maybe<DataRequirementCodeFilterOptions>;
};

export type QueryDataRequirementDateFiltersArgs = {
  where?: Maybe<DataRequirementDateFilterWhere>;
  options?: Maybe<DataRequirementDateFilterOptions>;
};

export type QueryDataRequirementCustomSortsArgs = {
  where?: Maybe<DataRequirementCustomSortWhere>;
  options?: Maybe<DataRequirementCustomSortOptions>;
};

export type QueryExpressionsArgs = {
  where?: Maybe<ExpressionWhere>;
  options?: Maybe<ExpressionOptions>;
};

export type QueryParameterDefinitionsArgs = {
  where?: Maybe<ParameterDefinitionWhere>;
  options?: Maybe<ParameterDefinitionOptions>;
};

export type QueryRelatedArtifactsArgs = {
  where?: Maybe<RelatedArtifactWhere>;
  options?: Maybe<RelatedArtifactOptions>;
};

export type QueryTriggerDefinitionsArgs = {
  where?: Maybe<TriggerDefinitionWhere>;
  options?: Maybe<TriggerDefinitionOptions>;
};

export type QueryUsageContextsArgs = {
  where?: Maybe<UsageContextWhere>;
  options?: Maybe<UsageContextOptions>;
};

export type QueryDosagesArgs = {
  where?: Maybe<DosageWhere>;
  options?: Maybe<DosageOptions>;
};

export type QueryDosageDoseAndRatesArgs = {
  where?: Maybe<DosageDoseAndRateWhere>;
  options?: Maybe<DosageDoseAndRateOptions>;
};

export type QueryNarrativesArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

export type QueryAccountCoveragesArgs = {
  where?: Maybe<AccountCoverageWhere>;
  options?: Maybe<AccountCoverageOptions>;
};

export type QueryAccountGuarantorsArgs = {
  where?: Maybe<AccountGuarantorWhere>;
  options?: Maybe<AccountGuarantorOptions>;
};

export type QueryAppointmentsArgs = {
  where?: Maybe<AppointmentWhere>;
  options?: Maybe<AppointmentOptions>;
};

export type QueryAppointmentParticipantsArgs = {
  where?: Maybe<AppointmentParticipantWhere>;
  options?: Maybe<AppointmentParticipantOptions>;
};

export type QueryAppointmentResponsesArgs = {
  where?: Maybe<AppointmentResponseWhere>;
  options?: Maybe<AppointmentResponseOptions>;
};

export type QueryCoveragesArgs = {
  where?: Maybe<CoverageWhere>;
  options?: Maybe<CoverageOptions>;
};

export type QueryCoverageClassesArgs = {
  where?: Maybe<CoverageClassWhere>;
  options?: Maybe<CoverageClassOptions>;
};

export type QueryCoverageCostToBeneficiariesArgs = {
  where?: Maybe<CoverageCostToBeneficiaryWhere>;
  options?: Maybe<CoverageCostToBeneficiaryOptions>;
};

export type QueryCoverageExceptionsArgs = {
  where?: Maybe<CoverageExceptionWhere>;
  options?: Maybe<CoverageExceptionOptions>;
};

export type QueryDevicesArgs = {
  where?: Maybe<DeviceWhere>;
  options?: Maybe<DeviceOptions>;
};

export type QueryDeviceUdiCarriersArgs = {
  where?: Maybe<DeviceUdiCarrierWhere>;
  options?: Maybe<DeviceUdiCarrierOptions>;
};

export type QueryDeviceDeviceNamesArgs = {
  where?: Maybe<DeviceDeviceNameWhere>;
  options?: Maybe<DeviceDeviceNameOptions>;
};

export type QueryDeviceSpecializationsArgs = {
  where?: Maybe<DeviceSpecializationWhere>;
  options?: Maybe<DeviceSpecializationOptions>;
};

export type QueryDeviceVersionsArgs = {
  where?: Maybe<DeviceVersionWhere>;
  options?: Maybe<DeviceVersionOptions>;
};

export type QueryDevicePropertiesArgs = {
  where?: Maybe<DevicePropertyWhere>;
  options?: Maybe<DevicePropertyOptions>;
};

export type QueryDeviceDefinitionsArgs = {
  where?: Maybe<DeviceDefinitionWhere>;
  options?: Maybe<DeviceDefinitionOptions>;
};

export type QueryDeviceDefinitionUdiDeviceIdentifiersArgs = {
  where?: Maybe<DeviceDefinitionUdiDeviceIdentifierWhere>;
  options?: Maybe<DeviceDefinitionUdiDeviceIdentifierOptions>;
};

export type QueryDeviceDefinitionDeviceNamesArgs = {
  where?: Maybe<DeviceDefinitionDeviceNameWhere>;
  options?: Maybe<DeviceDefinitionDeviceNameOptions>;
};

export type QueryDeviceDefinitionSpecializationsArgs = {
  where?: Maybe<DeviceDefinitionSpecializationWhere>;
  options?: Maybe<DeviceDefinitionSpecializationOptions>;
};

export type QueryProductShelfLivesArgs = {
  where?: Maybe<ProductShelfLifeWhere>;
  options?: Maybe<ProductShelfLifeOptions>;
};

export type QueryProdCharacteristicsArgs = {
  where?: Maybe<ProdCharacteristicWhere>;
  options?: Maybe<ProdCharacteristicOptions>;
};

export type QueryDeviceDefinitionCapabilitiesArgs = {
  where?: Maybe<DeviceDefinitionCapabilityWhere>;
  options?: Maybe<DeviceDefinitionCapabilityOptions>;
};

export type QueryDeviceDefinitionPropertiesArgs = {
  where?: Maybe<DeviceDefinitionPropertyWhere>;
  options?: Maybe<DeviceDefinitionPropertyOptions>;
};

export type QueryDeviceDefinitionMaterialsArgs = {
  where?: Maybe<DeviceDefinitionMaterialWhere>;
  options?: Maybe<DeviceDefinitionMaterialOptions>;
};

export type QueryDeviceMetricsArgs = {
  where?: Maybe<DeviceMetricWhere>;
  options?: Maybe<DeviceMetricOptions>;
};

export type QueryDeviceMetricCalibrationsArgs = {
  where?: Maybe<DeviceMetricCalibrationWhere>;
  options?: Maybe<DeviceMetricCalibrationOptions>;
};

export type QueryEncountersArgs = {
  where?: Maybe<EncounterWhere>;
  options?: Maybe<EncounterOptions>;
};

export type QueryEncounterStatusHistoriesArgs = {
  where?: Maybe<EncounterStatusHistoryWhere>;
  options?: Maybe<EncounterStatusHistoryOptions>;
};

export type QueryEncounterClassHistoriesArgs = {
  where?: Maybe<EncounterClassHistoryWhere>;
  options?: Maybe<EncounterClassHistoryOptions>;
};

export type QueryEncounterParticipantsArgs = {
  where?: Maybe<EncounterParticipantWhere>;
  options?: Maybe<EncounterParticipantOptions>;
};

export type QueryEncounterDiagnosesArgs = {
  where?: Maybe<EncounterDiagnosisWhere>;
  options?: Maybe<EncounterDiagnosisOptions>;
};

export type QueryEncounterHospitalizationsArgs = {
  where?: Maybe<EncounterHospitalizationWhere>;
  options?: Maybe<EncounterHospitalizationOptions>;
};

export type QueryEncounterLocationsArgs = {
  where?: Maybe<EncounterLocationWhere>;
  options?: Maybe<EncounterLocationOptions>;
};

export type QueryEndpointsArgs = {
  where?: Maybe<EndpointWhere>;
  options?: Maybe<EndpointOptions>;
};

export type QueryEpisodeOfCaresArgs = {
  where?: Maybe<EpisodeOfCareWhere>;
  options?: Maybe<EpisodeOfCareOptions>;
};

export type QueryEpisodeOfCareStatusHistoriesArgs = {
  where?: Maybe<EpisodeOfCareStatusHistoryWhere>;
  options?: Maybe<EpisodeOfCareStatusHistoryOptions>;
};

export type QueryEpisodeOfCareDiagnosesArgs = {
  where?: Maybe<EpisodeOfCareDiagnosisWhere>;
  options?: Maybe<EpisodeOfCareDiagnosisOptions>;
};

export type QueryHealthcareServicesArgs = {
  where?: Maybe<HealthcareServiceWhere>;
  options?: Maybe<HealthcareServiceOptions>;
};

export type QueryHealthcareServiceEligibilitiesArgs = {
  where?: Maybe<HealthcareServiceEligibilityWhere>;
  options?: Maybe<HealthcareServiceEligibilityOptions>;
};

export type QueryHealthcareServiceAvailableTimesArgs = {
  where?: Maybe<HealthcareServiceAvailableTimeWhere>;
  options?: Maybe<HealthcareServiceAvailableTimeOptions>;
};

export type QueryHealthcareServiceNotAvailablesArgs = {
  where?: Maybe<HealthcareServiceNotAvailableWhere>;
  options?: Maybe<HealthcareServiceNotAvailableOptions>;
};

export type QueryLocationsArgs = {
  where?: Maybe<LocationWhere>;
  options?: Maybe<LocationOptions>;
};

export type QueryLocationPositionsArgs = {
  where?: Maybe<LocationPositionWhere>;
  options?: Maybe<LocationPositionOptions>;
};

export type QueryLocationHoursOfOperationsArgs = {
  where?: Maybe<LocationHoursOfOperationWhere>;
  options?: Maybe<LocationHoursOfOperationOptions>;
};

export type QueryOrganizationsArgs = {
  where?: Maybe<OrganizationWhere>;
  options?: Maybe<OrganizationOptions>;
};

export type QueryOrganizationContactsArgs = {
  where?: Maybe<OrganizationContactWhere>;
  options?: Maybe<OrganizationContactOptions>;
};

export type QueryPatientsArgs = {
  where?: Maybe<PatientWhere>;
  options?: Maybe<PatientOptions>;
};

export type QueryPatientContactsArgs = {
  where?: Maybe<PatientContactWhere>;
  options?: Maybe<PatientContactOptions>;
};

export type QueryPatientCommunicationsArgs = {
  where?: Maybe<PatientCommunicationWhere>;
  options?: Maybe<PatientCommunicationOptions>;
};

export type QueryPatientLinksArgs = {
  where?: Maybe<PatientLinkWhere>;
  options?: Maybe<PatientLinkOptions>;
};

export type QueryPeopleArgs = {
  where?: Maybe<PersonWhere>;
  options?: Maybe<PersonOptions>;
};

export type QueryPersonLinksArgs = {
  where?: Maybe<PersonLinkWhere>;
  options?: Maybe<PersonLinkOptions>;
};

export type QueryPractitionersArgs = {
  where?: Maybe<PractitionerWhere>;
  options?: Maybe<PractitionerOptions>;
};

export type QueryPractitionerQualificationsArgs = {
  where?: Maybe<PractitionerQualificationWhere>;
  options?: Maybe<PractitionerQualificationOptions>;
};

export type QueryPractitionerRolesArgs = {
  where?: Maybe<PractitionerRoleWhere>;
  options?: Maybe<PractitionerRoleOptions>;
};

export type QueryPractitionerRoleAvailableTimesArgs = {
  where?: Maybe<PractitionerRoleAvailableTimeWhere>;
  options?: Maybe<PractitionerRoleAvailableTimeOptions>;
};

export type QueryPractitionerRoleNotAvailablesArgs = {
  where?: Maybe<PractitionerRoleNotAvailableWhere>;
  options?: Maybe<PractitionerRoleNotAvailableOptions>;
};

export type QueryRelatedPeopleArgs = {
  where?: Maybe<RelatedPersonWhere>;
  options?: Maybe<RelatedPersonOptions>;
};

export type QueryRelatedPersonCommunicationsArgs = {
  where?: Maybe<RelatedPersonCommunicationWhere>;
  options?: Maybe<RelatedPersonCommunicationOptions>;
};

export type QuerySchedulesArgs = {
  where?: Maybe<ScheduleWhere>;
  options?: Maybe<ScheduleOptions>;
};

export type QuerySlotsArgs = {
  where?: Maybe<SlotWhere>;
  options?: Maybe<SlotOptions>;
};

export type QuerySubstancesArgs = {
  where?: Maybe<SubstanceWhere>;
  options?: Maybe<SubstanceOptions>;
};

export type QuerySubstanceInstancesArgs = {
  where?: Maybe<SubstanceInstanceWhere>;
  options?: Maybe<SubstanceInstanceOptions>;
};

export type QuerySubstanceIngredientsArgs = {
  where?: Maybe<SubstanceIngredientWhere>;
  options?: Maybe<SubstanceIngredientOptions>;
};

export type QueryUsersArgs = {
  where?: Maybe<UserWhere>;
  options?: Maybe<UserOptions>;
};

export type QueryValidateResetPasswordArgs = {
  token: Scalars['String'];
};

export type QueryEmailAvailableArgs = {
  email: Scalars['String'];
};

export type QueryOptions = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** A set of ordered Quantities defined by a low and high limit. */
export type Range = {
  __typename?: 'Range';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  low?: Maybe<Quantity>;
  high?: Maybe<Quantity>;
};

/** A set of ordered Quantities defined by a low and high limit. */
export type RangeExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A set of ordered Quantities defined by a low and high limit. */
export type RangeLowArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** A set of ordered Quantities defined by a low and high limit. */
export type RangeHighArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

export type RangeConnectFieldInput = {
  where?: Maybe<RangeWhere>;
  connect?: Maybe<RangeConnectInput>;
};

export type RangeConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  low?: Maybe<QuantityConnectFieldInput>;
  high?: Maybe<QuantityConnectFieldInput>;
};

export type RangeCreateInput = {
  extension?: Maybe<RangeExtensionFieldInput>;
  low?: Maybe<RangeLowFieldInput>;
  high?: Maybe<RangeHighFieldInput>;
};

export type RangeDeleteFieldInput = {
  where?: Maybe<RangeWhere>;
  delete?: Maybe<RangeDeleteInput>;
};

export type RangeDeleteInput = {
  extension?: Maybe<Array<RangeExtensionDeleteFieldInput>>;
  low?: Maybe<RangeLowDeleteFieldInput>;
  high?: Maybe<RangeHighDeleteFieldInput>;
};

export type RangeDisconnectFieldInput = {
  where?: Maybe<RangeWhere>;
  disconnect?: Maybe<RangeDisconnectInput>;
};

export type RangeDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  low?: Maybe<QuantityDisconnectFieldInput>;
  high?: Maybe<QuantityDisconnectFieldInput>;
};

export type RangeExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type RangeExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type RangeExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type RangeHighDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type RangeHighFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type RangeHighUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type RangeLowDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type RangeLowFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type RangeLowUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type RangeOptions = {
  /** Specify one or more RangeSort objects to sort Ranges by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<RangeSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type RangeRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  low?: Maybe<QuantityCreateInput>;
  high?: Maybe<QuantityCreateInput>;
};

/** Fields to sort Ranges by. The order in which sorts are applied is not guaranteed when specifying many fields in one RangeSort object. */
export type RangeSort = {
  id?: Maybe<SortDirection>;
};

export type RangeUpdateInput = {
  extension?: Maybe<Array<RangeExtensionUpdateFieldInput>>;
  low?: Maybe<RangeLowUpdateFieldInput>;
  high?: Maybe<RangeHighUpdateFieldInput>;
};

export type RangeWhere = {
  OR?: Maybe<Array<RangeWhere>>;
  AND?: Maybe<Array<RangeWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  low?: Maybe<QuantityWhere>;
  low_NOT?: Maybe<QuantityWhere>;
  low_IN?: Maybe<Array<QuantityWhere>>;
  low_NOT_IN?: Maybe<Array<QuantityWhere>>;
  high?: Maybe<QuantityWhere>;
  high_NOT?: Maybe<QuantityWhere>;
  high_IN?: Maybe<Array<QuantityWhere>>;
  high_NOT_IN?: Maybe<Array<QuantityWhere>>;
};

/** A relationship of two Quantity values - expressed as a numerator and a denominator. */
export type Ratio = {
  __typename?: 'Ratio';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  numerator?: Maybe<Quantity>;
  denominator?: Maybe<Quantity>;
};

/** A relationship of two Quantity values - expressed as a numerator and a denominator. */
export type RatioExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A relationship of two Quantity values - expressed as a numerator and a denominator. */
export type RatioNumeratorArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** A relationship of two Quantity values - expressed as a numerator and a denominator. */
export type RatioDenominatorArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

export type RatioConnectFieldInput = {
  where?: Maybe<RatioWhere>;
  connect?: Maybe<RatioConnectInput>;
};

export type RatioConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  numerator?: Maybe<QuantityConnectFieldInput>;
  denominator?: Maybe<QuantityConnectFieldInput>;
};

export type RatioCreateInput = {
  extension?: Maybe<RatioExtensionFieldInput>;
  numerator?: Maybe<RatioNumeratorFieldInput>;
  denominator?: Maybe<RatioDenominatorFieldInput>;
};

export type RatioDeleteFieldInput = {
  where?: Maybe<RatioWhere>;
  delete?: Maybe<RatioDeleteInput>;
};

export type RatioDeleteInput = {
  extension?: Maybe<Array<RatioExtensionDeleteFieldInput>>;
  numerator?: Maybe<RatioNumeratorDeleteFieldInput>;
  denominator?: Maybe<RatioDenominatorDeleteFieldInput>;
};

export type RatioDenominatorDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type RatioDenominatorFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type RatioDenominatorUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type RatioDisconnectFieldInput = {
  where?: Maybe<RatioWhere>;
  disconnect?: Maybe<RatioDisconnectInput>;
};

export type RatioDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  numerator?: Maybe<QuantityDisconnectFieldInput>;
  denominator?: Maybe<QuantityDisconnectFieldInput>;
};

export type RatioExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type RatioExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type RatioExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type RatioNumeratorDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type RatioNumeratorFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type RatioNumeratorUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type RatioOptions = {
  /** Specify one or more RatioSort objects to sort Ratios by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<RatioSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type RatioRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  numerator?: Maybe<QuantityCreateInput>;
  denominator?: Maybe<QuantityCreateInput>;
};

/** Fields to sort Ratios by. The order in which sorts are applied is not guaranteed when specifying many fields in one RatioSort object. */
export type RatioSort = {
  id?: Maybe<SortDirection>;
};

export type RatioUpdateInput = {
  extension?: Maybe<Array<RatioExtensionUpdateFieldInput>>;
  numerator?: Maybe<RatioNumeratorUpdateFieldInput>;
  denominator?: Maybe<RatioDenominatorUpdateFieldInput>;
};

export type RatioWhere = {
  OR?: Maybe<Array<RatioWhere>>;
  AND?: Maybe<Array<RatioWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  numerator?: Maybe<QuantityWhere>;
  numerator_NOT?: Maybe<QuantityWhere>;
  numerator_IN?: Maybe<Array<QuantityWhere>>;
  numerator_NOT_IN?: Maybe<Array<QuantityWhere>>;
  denominator?: Maybe<QuantityWhere>;
  denominator_NOT?: Maybe<QuantityWhere>;
  denominator_IN?: Maybe<Array<QuantityWhere>>;
  denominator_NOT_IN?: Maybe<Array<QuantityWhere>>;
};

/** A reference from one resource to another. */
export type Reference = {
  __typename?: 'Reference';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** A reference to a location at which the other resource is found. The reference may be a relative reference, in which case it is relative to the service base URL, or an absolute URL that resolves to the location where the resource is found. The reference may be version specific or not. If the reference is not to a FHIR RESTful server, then it should be assumed to be version specific. Internal fragment references (start with '#') refer to contained resources. */
  reference?: Maybe<Scalars['String']>;
  /**
   * The expected type of the target of the reference. If both Reference.type and Reference.reference are populated and Reference.reference is a FHIR URL, both SHALL be consistent.
   *
   * The type is the Canonical URL of Resource Definition that is the type this reference refers to. References are URLs that are relative to http://hl7.org/fhir/StructureDefinition/ e.g. "Patient" is a reference to http://hl7.org/fhir/StructureDefinition/Patient. Absolute URLs are only allowed for logical models (and can only be used in references in logical models, not resources).
   */
  type?: Maybe<Scalars['Uri']>;
  /** Plain text narrative that identifies the resource in addition to the resource reference. */
  display?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  _reference?: Maybe<Element>;
  _type?: Maybe<Element>;
  identifier?: Maybe<Identifier>;
  _display?: Maybe<Element>;
};

/** A reference from one resource to another. */
export type ReferenceExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A reference from one resource to another. */
export type Reference_ReferenceArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reference from one resource to another. */
export type Reference_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A reference from one resource to another. */
export type ReferenceIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A reference from one resource to another. */
export type Reference_DisplayArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type ReferenceConnectFieldInput = {
  where?: Maybe<ReferenceWhere>;
  connect?: Maybe<ReferenceConnectInput>;
};

export type ReferenceConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _reference?: Maybe<ElementConnectFieldInput>;
  _type?: Maybe<ElementConnectFieldInput>;
  identifier?: Maybe<IdentifierConnectFieldInput>;
  _display?: Maybe<ElementConnectFieldInput>;
};

export type ReferenceCreateInput = {
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Uri']>;
  display?: Maybe<Scalars['String']>;
  extension?: Maybe<ReferenceExtensionFieldInput>;
  _reference?: Maybe<Reference_ReferenceFieldInput>;
  _type?: Maybe<Reference_TypeFieldInput>;
  identifier?: Maybe<ReferenceIdentifierFieldInput>;
  _display?: Maybe<Reference_DisplayFieldInput>;
};

export type ReferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type ReferenceDeleteInput = {
  extension?: Maybe<Array<ReferenceExtensionDeleteFieldInput>>;
  _reference?: Maybe<Reference_ReferenceDeleteFieldInput>;
  _type?: Maybe<Reference_TypeDeleteFieldInput>;
  identifier?: Maybe<ReferenceIdentifierDeleteFieldInput>;
  _display?: Maybe<Reference_DisplayDeleteFieldInput>;
};

export type ReferenceDisconnectFieldInput = {
  where?: Maybe<ReferenceWhere>;
  disconnect?: Maybe<ReferenceDisconnectInput>;
};

export type ReferenceDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _reference?: Maybe<ElementDisconnectFieldInput>;
  _type?: Maybe<ElementDisconnectFieldInput>;
  identifier?: Maybe<IdentifierDisconnectFieldInput>;
  _display?: Maybe<ElementDisconnectFieldInput>;
};

export type ReferenceExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ReferenceExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ReferenceExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ReferenceIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type ReferenceIdentifierFieldInput = {
  create?: Maybe<IdentifierCreateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
};

export type ReferenceIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
  disconnect?: Maybe<IdentifierDisconnectFieldInput>;
  create?: Maybe<IdentifierCreateInput>;
  delete?: Maybe<IdentifierDeleteFieldInput>;
};

export type ReferenceOptions = {
  /** Specify one or more ReferenceSort objects to sort References by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ReferenceSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ReferenceRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _reference?: Maybe<ElementCreateInput>;
  _type?: Maybe<ElementCreateInput>;
  identifier?: Maybe<IdentifierCreateInput>;
  _display?: Maybe<ElementCreateInput>;
};

/** Fields to sort References by. The order in which sorts are applied is not guaranteed when specifying many fields in one ReferenceSort object. */
export type ReferenceSort = {
  id?: Maybe<SortDirection>;
  reference?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  display?: Maybe<SortDirection>;
};

export type ReferenceUpdateInput = {
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Uri']>;
  display?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<ReferenceExtensionUpdateFieldInput>>;
  _reference?: Maybe<Reference_ReferenceUpdateFieldInput>;
  _type?: Maybe<Reference_TypeUpdateFieldInput>;
  identifier?: Maybe<ReferenceIdentifierUpdateFieldInput>;
  _display?: Maybe<Reference_DisplayUpdateFieldInput>;
};

export type ReferenceWhere = {
  OR?: Maybe<Array<ReferenceWhere>>;
  AND?: Maybe<Array<ReferenceWhere>>;
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Uri']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _reference?: Maybe<ElementWhere>;
  _reference_NOT?: Maybe<ElementWhere>;
  _reference_IN?: Maybe<Array<ElementWhere>>;
  _reference_NOT_IN?: Maybe<Array<ElementWhere>>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  identifier_IN?: Maybe<Array<IdentifierWhere>>;
  identifier_NOT_IN?: Maybe<Array<IdentifierWhere>>;
  _display?: Maybe<ElementWhere>;
  _display_NOT?: Maybe<ElementWhere>;
  _display_IN?: Maybe<Array<ElementWhere>>;
  _display_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Reference_DisplayDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Reference_DisplayFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Reference_DisplayUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Reference_ReferenceDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Reference_ReferenceFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Reference_ReferenceUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Reference_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Reference_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Reference_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Related artifacts such as additional documentation, justification, or bibliographic references. */
export type RelatedArtifact = {
  __typename?: 'RelatedArtifact';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The type of relationship to the related artifact. */
  type?: Maybe<RelatedArtifactType>;
  /** A short label that can be used to reference the citation from elsewhere in the containing artifact, such as a footnote index. */
  label?: Maybe<Scalars['String']>;
  /** A brief description of the document or knowledge resource being referenced, suitable for display to a consumer. */
  display?: Maybe<Scalars['String']>;
  /** A bibliographic citation for the related artifact. This text SHOULD be formatted according to an accepted citation format. */
  citation?: Maybe<Scalars['Markdown']>;
  /** A url for the artifact that can be followed to access the actual content. */
  url?: Maybe<Scalars['Url']>;
  /** The related resource, such as a library, value set, profile, or other knowledge resource. */
  resource?: Maybe<Scalars['Canonical']>;
  extension: Array<Extension>;
  _type?: Maybe<Element>;
  _label?: Maybe<Element>;
  _display?: Maybe<Element>;
  _citation?: Maybe<Element>;
  _url?: Maybe<Element>;
  document?: Maybe<Attachment>;
};

/** Related artifacts such as additional documentation, justification, or bibliographic references. */
export type RelatedArtifactExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Related artifacts such as additional documentation, justification, or bibliographic references. */
export type RelatedArtifact_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Related artifacts such as additional documentation, justification, or bibliographic references. */
export type RelatedArtifact_LabelArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Related artifacts such as additional documentation, justification, or bibliographic references. */
export type RelatedArtifact_DisplayArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Related artifacts such as additional documentation, justification, or bibliographic references. */
export type RelatedArtifact_CitationArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Related artifacts such as additional documentation, justification, or bibliographic references. */
export type RelatedArtifact_UrlArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Related artifacts such as additional documentation, justification, or bibliographic references. */
export type RelatedArtifactDocumentArgs = {
  where?: Maybe<AttachmentWhere>;
  options?: Maybe<AttachmentOptions>;
};

export type RelatedArtifactConnectFieldInput = {
  where?: Maybe<RelatedArtifactWhere>;
  connect?: Maybe<RelatedArtifactConnectInput>;
};

export type RelatedArtifactConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _type?: Maybe<ElementConnectFieldInput>;
  _label?: Maybe<ElementConnectFieldInput>;
  _display?: Maybe<ElementConnectFieldInput>;
  _citation?: Maybe<ElementConnectFieldInput>;
  _url?: Maybe<ElementConnectFieldInput>;
  document?: Maybe<AttachmentConnectFieldInput>;
};

export type RelatedArtifactCreateInput = {
  label?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  citation?: Maybe<Scalars['Markdown']>;
  url?: Maybe<Scalars['Url']>;
  resource?: Maybe<Scalars['Canonical']>;
  type?: Maybe<RelatedArtifactType>;
  extension?: Maybe<RelatedArtifactExtensionFieldInput>;
  _type?: Maybe<RelatedArtifact_TypeFieldInput>;
  _label?: Maybe<RelatedArtifact_LabelFieldInput>;
  _display?: Maybe<RelatedArtifact_DisplayFieldInput>;
  _citation?: Maybe<RelatedArtifact_CitationFieldInput>;
  _url?: Maybe<RelatedArtifact_UrlFieldInput>;
  document?: Maybe<RelatedArtifactDocumentFieldInput>;
};

export type RelatedArtifactDeleteFieldInput = {
  where?: Maybe<RelatedArtifactWhere>;
  delete?: Maybe<RelatedArtifactDeleteInput>;
};

export type RelatedArtifactDeleteInput = {
  extension?: Maybe<Array<RelatedArtifactExtensionDeleteFieldInput>>;
  _type?: Maybe<RelatedArtifact_TypeDeleteFieldInput>;
  _label?: Maybe<RelatedArtifact_LabelDeleteFieldInput>;
  _display?: Maybe<RelatedArtifact_DisplayDeleteFieldInput>;
  _citation?: Maybe<RelatedArtifact_CitationDeleteFieldInput>;
  _url?: Maybe<RelatedArtifact_UrlDeleteFieldInput>;
  document?: Maybe<RelatedArtifactDocumentDeleteFieldInput>;
};

export type RelatedArtifactDisconnectFieldInput = {
  where?: Maybe<RelatedArtifactWhere>;
  disconnect?: Maybe<RelatedArtifactDisconnectInput>;
};

export type RelatedArtifactDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _type?: Maybe<ElementDisconnectFieldInput>;
  _label?: Maybe<ElementDisconnectFieldInput>;
  _display?: Maybe<ElementDisconnectFieldInput>;
  _citation?: Maybe<ElementDisconnectFieldInput>;
  _url?: Maybe<ElementDisconnectFieldInput>;
  document?: Maybe<AttachmentDisconnectFieldInput>;
};

export type RelatedArtifactDocumentDeleteFieldInput = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type RelatedArtifactDocumentFieldInput = {
  create?: Maybe<AttachmentCreateInput>;
  connect?: Maybe<AttachmentConnectFieldInput>;
};

export type RelatedArtifactDocumentUpdateFieldInput = {
  where?: Maybe<AttachmentWhere>;
  update?: Maybe<AttachmentUpdateInput>;
  connect?: Maybe<AttachmentConnectFieldInput>;
  disconnect?: Maybe<AttachmentDisconnectFieldInput>;
  create?: Maybe<AttachmentCreateInput>;
  delete?: Maybe<AttachmentDeleteFieldInput>;
};

export type RelatedArtifactExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type RelatedArtifactExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type RelatedArtifactExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type RelatedArtifactOptions = {
  /** Specify one or more RelatedArtifactSort objects to sort RelatedArtifacts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<RelatedArtifactSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type RelatedArtifactRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _type?: Maybe<ElementCreateInput>;
  _label?: Maybe<ElementCreateInput>;
  _display?: Maybe<ElementCreateInput>;
  _citation?: Maybe<ElementCreateInput>;
  _url?: Maybe<ElementCreateInput>;
  document?: Maybe<AttachmentCreateInput>;
};

/** Fields to sort RelatedArtifacts by. The order in which sorts are applied is not guaranteed when specifying many fields in one RelatedArtifactSort object. */
export type RelatedArtifactSort = {
  id?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  label?: Maybe<SortDirection>;
  display?: Maybe<SortDirection>;
  citation?: Maybe<SortDirection>;
  url?: Maybe<SortDirection>;
  resource?: Maybe<SortDirection>;
};

/** The type of relationship to the related artifact. */
export enum RelatedArtifactType {
  Documentation = 'documentation',
  Justification = 'justification',
  Citation = 'citation',
  Predecessor = 'predecessor',
  Successor = 'successor',
  DerivedFrom = 'derivedFrom',
  DependsOn = 'dependsOn',
  ComposedOf = 'composedOf',
}

export type RelatedArtifactUpdateInput = {
  label?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  citation?: Maybe<Scalars['Markdown']>;
  url?: Maybe<Scalars['Url']>;
  resource?: Maybe<Scalars['Canonical']>;
  type?: Maybe<RelatedArtifactType>;
  extension?: Maybe<Array<RelatedArtifactExtensionUpdateFieldInput>>;
  _type?: Maybe<RelatedArtifact_TypeUpdateFieldInput>;
  _label?: Maybe<RelatedArtifact_LabelUpdateFieldInput>;
  _display?: Maybe<RelatedArtifact_DisplayUpdateFieldInput>;
  _citation?: Maybe<RelatedArtifact_CitationUpdateFieldInput>;
  _url?: Maybe<RelatedArtifact_UrlUpdateFieldInput>;
  document?: Maybe<RelatedArtifactDocumentUpdateFieldInput>;
};

export type RelatedArtifactWhere = {
  OR?: Maybe<Array<RelatedArtifactWhere>>;
  AND?: Maybe<Array<RelatedArtifactWhere>>;
  label?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  citation?: Maybe<Scalars['Markdown']>;
  url?: Maybe<Scalars['Url']>;
  resource?: Maybe<Scalars['Canonical']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  type?: Maybe<RelatedArtifactType>;
  type_NOT?: Maybe<RelatedArtifactType>;
  type_IN?: Maybe<Array<Maybe<RelatedArtifactType>>>;
  type_NOT_IN?: Maybe<Array<Maybe<RelatedArtifactType>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
  _label?: Maybe<ElementWhere>;
  _label_NOT?: Maybe<ElementWhere>;
  _label_IN?: Maybe<Array<ElementWhere>>;
  _label_NOT_IN?: Maybe<Array<ElementWhere>>;
  _display?: Maybe<ElementWhere>;
  _display_NOT?: Maybe<ElementWhere>;
  _display_IN?: Maybe<Array<ElementWhere>>;
  _display_NOT_IN?: Maybe<Array<ElementWhere>>;
  _citation?: Maybe<ElementWhere>;
  _citation_NOT?: Maybe<ElementWhere>;
  _citation_IN?: Maybe<Array<ElementWhere>>;
  _citation_NOT_IN?: Maybe<Array<ElementWhere>>;
  _url?: Maybe<ElementWhere>;
  _url_NOT?: Maybe<ElementWhere>;
  _url_IN?: Maybe<Array<ElementWhere>>;
  _url_NOT_IN?: Maybe<Array<ElementWhere>>;
  document?: Maybe<AttachmentWhere>;
  document_NOT?: Maybe<AttachmentWhere>;
  document_IN?: Maybe<Array<AttachmentWhere>>;
  document_NOT_IN?: Maybe<Array<AttachmentWhere>>;
};

export type RelatedArtifact_CitationDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedArtifact_CitationFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedArtifact_CitationUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type RelatedArtifact_DisplayDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedArtifact_DisplayFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedArtifact_DisplayUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type RelatedArtifact_LabelDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedArtifact_LabelFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedArtifact_LabelUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type RelatedArtifact_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedArtifact_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedArtifact_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type RelatedArtifact_UrlDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedArtifact_UrlFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedArtifact_UrlUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPerson = {
  __typename?: 'RelatedPerson';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** Whether this related person record is in active use. */
  active?: Maybe<Scalars['Boolean']>;
  /** This is a RelatedPerson resource */
  resourceType: RelatedPersonResourceType;
  /** Administrative Gender - the gender that the person is considered to have for administration and record keeping purposes. */
  gender?: Maybe<RelatedPersonGender>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** The date on which the related person was born. */
  birthDate?: Maybe<Scalars['Date']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _active?: Maybe<Element>;
  patient: Reference;
  relationship: Array<CodeableConcept>;
  name: Array<HumanName>;
  telecom: Array<ContactPoint>;
  _gender?: Maybe<Element>;
  _birthDate?: Maybe<Element>;
  address: Array<Address>;
  photo: Array<Attachment>;
  period?: Maybe<Period>;
  communication: Array<RelatedPersonCommunication>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPerson_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPerson_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPerson_ActiveArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonPatientArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonRelationshipArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonNameArgs = {
  where?: Maybe<HumanNameWhere>;
  options?: Maybe<HumanNameOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonTelecomArgs = {
  where?: Maybe<ContactPointWhere>;
  options?: Maybe<ContactPointOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPerson_GenderArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPerson_BirthDateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonAddressArgs = {
  where?: Maybe<AddressWhere>;
  options?: Maybe<AddressOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonPhotoArgs = {
  where?: Maybe<AttachmentWhere>;
  options?: Maybe<AttachmentOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonCommunicationArgs = {
  where?: Maybe<RelatedPersonCommunicationWhere>;
  options?: Maybe<RelatedPersonCommunicationOptions>;
};

export type RelatedPersonAddressDeleteFieldInput = {
  where?: Maybe<AddressWhere>;
  delete?: Maybe<AddressDeleteInput>;
};

export type RelatedPersonAddressFieldInput = {
  create?: Maybe<Array<AddressCreateInput>>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
};

export type RelatedPersonAddressUpdateFieldInput = {
  where?: Maybe<AddressWhere>;
  update?: Maybe<AddressUpdateInput>;
  connect?: Maybe<Array<AddressConnectFieldInput>>;
  disconnect?: Maybe<Array<AddressDisconnectFieldInput>>;
  create?: Maybe<Array<AddressCreateInput>>;
  delete?: Maybe<Array<AddressDeleteFieldInput>>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonCommunication = {
  __typename?: 'RelatedPersonCommunication';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Indicates whether or not the patient prefers this language (over other languages he masters up a certain level). */
  preferred?: Maybe<Scalars['Boolean']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  language: CodeableConcept;
  _preferred?: Maybe<Element>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonCommunicationExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonCommunicationModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonCommunicationLanguageArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
export type RelatedPersonCommunication_PreferredArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type RelatedPersonCommunicationConnectFieldInput = {
  where?: Maybe<RelatedPersonCommunicationWhere>;
  connect?: Maybe<RelatedPersonCommunicationConnectInput>;
};

export type RelatedPersonCommunicationConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  language?: Maybe<CodeableConceptConnectFieldInput>;
  _preferred?: Maybe<ElementConnectFieldInput>;
};

export type RelatedPersonCommunicationCreateInput = {
  preferred?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<RelatedPersonCommunicationExtensionFieldInput>;
  modifierExtension?: Maybe<RelatedPersonCommunicationModifierextensionFieldInput>;
  language?: Maybe<RelatedPersonCommunicationLanguageFieldInput>;
  _preferred?: Maybe<RelatedPersonCommunication_PreferredFieldInput>;
};

export type RelatedPersonCommunicationDeleteFieldInput = {
  where?: Maybe<RelatedPersonCommunicationWhere>;
  delete?: Maybe<RelatedPersonCommunicationDeleteInput>;
};

export type RelatedPersonCommunicationDeleteInput = {
  extension?: Maybe<Array<RelatedPersonCommunicationExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<RelatedPersonCommunicationModifierextensionDeleteFieldInput>>;
  language?: Maybe<RelatedPersonCommunicationLanguageDeleteFieldInput>;
  _preferred?: Maybe<RelatedPersonCommunication_PreferredDeleteFieldInput>;
};

export type RelatedPersonCommunicationDisconnectFieldInput = {
  where?: Maybe<RelatedPersonCommunicationWhere>;
  disconnect?: Maybe<RelatedPersonCommunicationDisconnectInput>;
};

export type RelatedPersonCommunicationDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  language?: Maybe<CodeableConceptDisconnectFieldInput>;
  _preferred?: Maybe<ElementDisconnectFieldInput>;
};

export type RelatedPersonCommunicationExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type RelatedPersonCommunicationExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type RelatedPersonCommunicationExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type RelatedPersonCommunicationFieldInput = {
  create?: Maybe<Array<RelatedPersonCommunicationCreateInput>>;
  connect?: Maybe<Array<RelatedPersonCommunicationConnectFieldInput>>;
};

export type RelatedPersonCommunicationLanguageDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type RelatedPersonCommunicationLanguageFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type RelatedPersonCommunicationLanguageUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type RelatedPersonCommunicationModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type RelatedPersonCommunicationModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type RelatedPersonCommunicationModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type RelatedPersonCommunicationOptions = {
  /** Specify one or more RelatedPersonCommunicationSort objects to sort RelatedPersonCommunications by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<RelatedPersonCommunicationSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type RelatedPersonCommunicationRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  language?: Maybe<CodeableConceptCreateInput>;
  _preferred?: Maybe<ElementCreateInput>;
};

/** Fields to sort RelatedPersonCommunications by. The order in which sorts are applied is not guaranteed when specifying many fields in one RelatedPersonCommunicationSort object. */
export type RelatedPersonCommunicationSort = {
  id?: Maybe<SortDirection>;
  preferred?: Maybe<SortDirection>;
};

export type RelatedPersonCommunicationUpdateFieldInput = {
  where?: Maybe<RelatedPersonCommunicationWhere>;
  update?: Maybe<RelatedPersonCommunicationUpdateInput>;
  connect?: Maybe<Array<RelatedPersonCommunicationConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonCommunicationDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCommunicationCreateInput>>;
  delete?: Maybe<Array<RelatedPersonCommunicationDeleteFieldInput>>;
};

export type RelatedPersonCommunicationUpdateInput = {
  preferred?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Array<RelatedPersonCommunicationExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<RelatedPersonCommunicationModifierextensionUpdateFieldInput>>;
  language?: Maybe<RelatedPersonCommunicationLanguageUpdateFieldInput>;
  _preferred?: Maybe<RelatedPersonCommunication_PreferredUpdateFieldInput>;
};

export type RelatedPersonCommunicationWhere = {
  OR?: Maybe<Array<RelatedPersonCommunicationWhere>>;
  AND?: Maybe<Array<RelatedPersonCommunicationWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  preferred?: Maybe<Scalars['Boolean']>;
  preferred_NOT?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  language?: Maybe<CodeableConceptWhere>;
  language_NOT?: Maybe<CodeableConceptWhere>;
  language_IN?: Maybe<Array<CodeableConceptWhere>>;
  language_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _preferred?: Maybe<ElementWhere>;
  _preferred_NOT?: Maybe<ElementWhere>;
  _preferred_IN?: Maybe<Array<ElementWhere>>;
  _preferred_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type RelatedPersonCommunication_PreferredDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedPersonCommunication_PreferredFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedPersonCommunication_PreferredUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type RelatedPersonConnectFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  connect?: Maybe<RelatedPersonConnectInput>;
};

export type RelatedPersonConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _active?: Maybe<ElementConnectFieldInput>;
  patient?: Maybe<ReferenceConnectFieldInput>;
  relationship?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  name?: Maybe<Array<HumanNameConnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointConnectFieldInput>>;
  _gender?: Maybe<ElementConnectFieldInput>;
  _birthDate?: Maybe<ElementConnectFieldInput>;
  address?: Maybe<Array<AddressConnectFieldInput>>;
  photo?: Maybe<Array<AttachmentConnectFieldInput>>;
  period?: Maybe<PeriodConnectFieldInput>;
  communication?: Maybe<Array<RelatedPersonCommunicationConnectFieldInput>>;
};

export type RelatedPersonContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type RelatedPersonContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type RelatedPersonContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type RelatedPersonContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type RelatedPersonContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type RelatedPersonContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type RelatedPersonContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type RelatedPersonContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type RelatedPersonContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type RelatedPersonContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type RelatedPersonContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type RelatedPersonContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type RelatedPersonContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type RelatedPersonContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type RelatedPersonContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type RelatedPersonContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type RelatedPersonContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type RelatedPersonContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type RelatedPersonContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type RelatedPersonContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type RelatedPersonContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type RelatedPersonContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type RelatedPersonContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type RelatedPersonContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type RelatedPersonContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type RelatedPersonContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type RelatedPersonContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type RelatedPersonContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type RelatedPersonContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type RelatedPersonContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type RelatedPersonContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type RelatedPersonContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type RelatedPersonContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type RelatedPersonContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type RelatedPersonContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type RelatedPersonContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type RelatedPersonContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type RelatedPersonContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type RelatedPersonContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type RelatedPersonContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type RelatedPersonContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type RelatedPersonContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type RelatedPersonContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type RelatedPersonContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type RelatedPersonContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type RelatedPersonContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type RelatedPersonContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type RelatedPersonContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type RelatedPersonContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type RelatedPersonContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type RelatedPersonContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type RelatedPersonContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type RelatedPersonContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type RelatedPersonContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type RelatedPersonContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type RelatedPersonContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type RelatedPersonContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type RelatedPersonContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type RelatedPersonContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type RelatedPersonContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type RelatedPersonContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type RelatedPersonContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type RelatedPersonContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type RelatedPersonCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  resourceType: RelatedPersonResourceType;
  gender?: Maybe<RelatedPersonGender>;
  meta?: Maybe<RelatedPersonMetaFieldInput>;
  _implicitRules?: Maybe<RelatedPerson_ImplicitrulesFieldInput>;
  _language?: Maybe<RelatedPerson_LanguageFieldInput>;
  text?: Maybe<RelatedPersonTextFieldInput>;
  contained_Account?: Maybe<RelatedPersonContainedAccountFieldInput>;
  contained_Appointment?: Maybe<RelatedPersonContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<RelatedPersonContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<RelatedPersonContainedCoverageFieldInput>;
  contained_Device?: Maybe<RelatedPersonContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<RelatedPersonContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<RelatedPersonContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<RelatedPersonContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<RelatedPersonContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<RelatedPersonContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<RelatedPersonContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<RelatedPersonContainedLocationFieldInput>;
  contained_Organization?: Maybe<RelatedPersonContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<RelatedPersonContainedPatientFieldInput>;
  contained_Person?: Maybe<RelatedPersonContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<RelatedPersonContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<RelatedPersonContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<RelatedPersonContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<RelatedPersonContainedScheduleFieldInput>;
  contained_Slot?: Maybe<RelatedPersonContainedSlotFieldInput>;
  contained_Substance?: Maybe<RelatedPersonContainedSubstanceFieldInput>;
  extension?: Maybe<RelatedPersonExtensionFieldInput>;
  modifierExtension?: Maybe<RelatedPersonModifierextensionFieldInput>;
  identifier?: Maybe<RelatedPersonIdentifierFieldInput>;
  _active?: Maybe<RelatedPerson_ActiveFieldInput>;
  patient?: Maybe<RelatedPersonPatientFieldInput>;
  relationship?: Maybe<RelatedPersonRelationshipFieldInput>;
  name?: Maybe<RelatedPersonNameFieldInput>;
  telecom?: Maybe<RelatedPersonTelecomFieldInput>;
  _gender?: Maybe<RelatedPerson_GenderFieldInput>;
  _birthDate?: Maybe<RelatedPerson_BirthdateFieldInput>;
  address?: Maybe<RelatedPersonAddressFieldInput>;
  photo?: Maybe<RelatedPersonPhotoFieldInput>;
  period?: Maybe<RelatedPersonPeriodFieldInput>;
  communication?: Maybe<RelatedPersonCommunicationFieldInput>;
};

export type RelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type RelatedPersonDeleteInput = {
  meta?: Maybe<RelatedPersonMetaDeleteFieldInput>;
  _implicitRules?: Maybe<RelatedPerson_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<RelatedPerson_LanguageDeleteFieldInput>;
  text?: Maybe<RelatedPersonTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<RelatedPersonContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<RelatedPersonContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<RelatedPersonContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<RelatedPersonContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<RelatedPersonContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<RelatedPersonContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<RelatedPersonContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<RelatedPersonContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<RelatedPersonContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<RelatedPersonContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<RelatedPersonContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<RelatedPersonContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<RelatedPersonContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<RelatedPersonContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<RelatedPersonContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<RelatedPersonContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<RelatedPersonContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<RelatedPersonContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<RelatedPersonContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<RelatedPersonContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<RelatedPersonExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<RelatedPersonModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<RelatedPersonIdentifierDeleteFieldInput>>;
  _active?: Maybe<RelatedPerson_ActiveDeleteFieldInput>;
  patient?: Maybe<RelatedPersonPatientDeleteFieldInput>;
  relationship?: Maybe<Array<RelatedPersonRelationshipDeleteFieldInput>>;
  name?: Maybe<Array<RelatedPersonNameDeleteFieldInput>>;
  telecom?: Maybe<Array<RelatedPersonTelecomDeleteFieldInput>>;
  _gender?: Maybe<RelatedPerson_GenderDeleteFieldInput>;
  _birthDate?: Maybe<RelatedPerson_BirthdateDeleteFieldInput>;
  address?: Maybe<Array<RelatedPersonAddressDeleteFieldInput>>;
  photo?: Maybe<Array<RelatedPersonPhotoDeleteFieldInput>>;
  period?: Maybe<RelatedPersonPeriodDeleteFieldInput>;
  communication?: Maybe<Array<RelatedPersonCommunicationDeleteFieldInput>>;
};

export type RelatedPersonDisconnectFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  disconnect?: Maybe<RelatedPersonDisconnectInput>;
};

export type RelatedPersonDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _active?: Maybe<ElementDisconnectFieldInput>;
  patient?: Maybe<ReferenceDisconnectFieldInput>;
  relationship?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  name?: Maybe<Array<HumanNameDisconnectFieldInput>>;
  telecom?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  _gender?: Maybe<ElementDisconnectFieldInput>;
  _birthDate?: Maybe<ElementDisconnectFieldInput>;
  address?: Maybe<Array<AddressDisconnectFieldInput>>;
  photo?: Maybe<Array<AttachmentDisconnectFieldInput>>;
  period?: Maybe<PeriodDisconnectFieldInput>;
  communication?: Maybe<Array<RelatedPersonCommunicationDisconnectFieldInput>>;
};

export type RelatedPersonExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type RelatedPersonExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type RelatedPersonExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

/** Administrative Gender - the gender that the person is considered to have for administration and record keeping purposes. */
export enum RelatedPersonGender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
  Unknown = 'unknown',
}

export type RelatedPersonIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type RelatedPersonIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type RelatedPersonIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type RelatedPersonMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type RelatedPersonMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type RelatedPersonMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type RelatedPersonModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type RelatedPersonModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type RelatedPersonModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type RelatedPersonNameDeleteFieldInput = {
  where?: Maybe<HumanNameWhere>;
  delete?: Maybe<HumanNameDeleteInput>;
};

export type RelatedPersonNameFieldInput = {
  create?: Maybe<Array<HumanNameCreateInput>>;
  connect?: Maybe<Array<HumanNameConnectFieldInput>>;
};

export type RelatedPersonNameUpdateFieldInput = {
  where?: Maybe<HumanNameWhere>;
  update?: Maybe<HumanNameUpdateInput>;
  connect?: Maybe<Array<HumanNameConnectFieldInput>>;
  disconnect?: Maybe<Array<HumanNameDisconnectFieldInput>>;
  create?: Maybe<Array<HumanNameCreateInput>>;
  delete?: Maybe<Array<HumanNameDeleteFieldInput>>;
};

export type RelatedPersonOptions = {
  /** Specify one or more RelatedPersonSort objects to sort RelatedPeople by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<RelatedPersonSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type RelatedPersonPatientDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type RelatedPersonPatientFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type RelatedPersonPatientUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type RelatedPersonPeriodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type RelatedPersonPeriodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type RelatedPersonPeriodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type RelatedPersonPhotoDeleteFieldInput = {
  where?: Maybe<AttachmentWhere>;
  delete?: Maybe<AttachmentDeleteInput>;
};

export type RelatedPersonPhotoFieldInput = {
  create?: Maybe<Array<AttachmentCreateInput>>;
  connect?: Maybe<Array<AttachmentConnectFieldInput>>;
};

export type RelatedPersonPhotoUpdateFieldInput = {
  where?: Maybe<AttachmentWhere>;
  update?: Maybe<AttachmentUpdateInput>;
  connect?: Maybe<Array<AttachmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AttachmentDisconnectFieldInput>>;
  create?: Maybe<Array<AttachmentCreateInput>>;
  delete?: Maybe<Array<AttachmentDeleteFieldInput>>;
};

export type RelatedPersonRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _active?: Maybe<ElementCreateInput>;
  patient?: Maybe<ReferenceCreateInput>;
  relationship?: Maybe<Array<CodeableConceptCreateInput>>;
  name?: Maybe<Array<HumanNameCreateInput>>;
  telecom?: Maybe<Array<ContactPointCreateInput>>;
  _gender?: Maybe<ElementCreateInput>;
  _birthDate?: Maybe<ElementCreateInput>;
  address?: Maybe<Array<AddressCreateInput>>;
  photo?: Maybe<Array<AttachmentCreateInput>>;
  period?: Maybe<PeriodCreateInput>;
  communication?: Maybe<Array<RelatedPersonCommunicationCreateInput>>;
};

export type RelatedPersonRelationshipDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type RelatedPersonRelationshipFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type RelatedPersonRelationshipUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export enum RelatedPersonResourceType {
  RelatedPerson = 'RelatedPerson',
}

/** Fields to sort RelatedPeople by. The order in which sorts are applied is not guaranteed when specifying many fields in one RelatedPersonSort object. */
export type RelatedPersonSort = {
  id?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  gender?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  birthDate?: Maybe<SortDirection>;
};

export type RelatedPersonTelecomDeleteFieldInput = {
  where?: Maybe<ContactPointWhere>;
  delete?: Maybe<ContactPointDeleteInput>;
};

export type RelatedPersonTelecomFieldInput = {
  create?: Maybe<Array<ContactPointCreateInput>>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
};

export type RelatedPersonTelecomUpdateFieldInput = {
  where?: Maybe<ContactPointWhere>;
  update?: Maybe<ContactPointUpdateInput>;
  connect?: Maybe<Array<ContactPointConnectFieldInput>>;
  disconnect?: Maybe<Array<ContactPointDisconnectFieldInput>>;
  create?: Maybe<Array<ContactPointCreateInput>>;
  delete?: Maybe<Array<ContactPointDeleteFieldInput>>;
};

export type RelatedPersonTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type RelatedPersonTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type RelatedPersonTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type RelatedPersonUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  resourceType?: Maybe<RelatedPersonResourceType>;
  gender?: Maybe<RelatedPersonGender>;
  meta?: Maybe<RelatedPersonMetaUpdateFieldInput>;
  _implicitRules?: Maybe<RelatedPerson_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<RelatedPerson_LanguageUpdateFieldInput>;
  text?: Maybe<RelatedPersonTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<RelatedPersonContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<RelatedPersonContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<RelatedPersonContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<RelatedPersonContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<RelatedPersonContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<RelatedPersonContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<RelatedPersonContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<RelatedPersonContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<RelatedPersonContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<RelatedPersonContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<RelatedPersonContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<RelatedPersonContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<RelatedPersonContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<RelatedPersonContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<RelatedPersonContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<RelatedPersonContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<RelatedPersonContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<RelatedPersonContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<RelatedPersonContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<RelatedPersonContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<RelatedPersonExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<RelatedPersonModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<RelatedPersonIdentifierUpdateFieldInput>>;
  _active?: Maybe<RelatedPerson_ActiveUpdateFieldInput>;
  patient?: Maybe<RelatedPersonPatientUpdateFieldInput>;
  relationship?: Maybe<Array<RelatedPersonRelationshipUpdateFieldInput>>;
  name?: Maybe<Array<RelatedPersonNameUpdateFieldInput>>;
  telecom?: Maybe<Array<RelatedPersonTelecomUpdateFieldInput>>;
  _gender?: Maybe<RelatedPerson_GenderUpdateFieldInput>;
  _birthDate?: Maybe<RelatedPerson_BirthdateUpdateFieldInput>;
  address?: Maybe<Array<RelatedPersonAddressUpdateFieldInput>>;
  photo?: Maybe<Array<RelatedPersonPhotoUpdateFieldInput>>;
  period?: Maybe<RelatedPersonPeriodUpdateFieldInput>;
  communication?: Maybe<Array<RelatedPersonCommunicationUpdateFieldInput>>;
};

export type RelatedPersonWhere = {
  OR?: Maybe<Array<RelatedPersonWhere>>;
  AND?: Maybe<Array<RelatedPersonWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  birthDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<RelatedPersonResourceType>;
  resourceType_NOT?: Maybe<RelatedPersonResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<RelatedPersonResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<RelatedPersonResourceType>>>;
  gender?: Maybe<RelatedPersonGender>;
  gender_NOT?: Maybe<RelatedPersonGender>;
  gender_IN?: Maybe<Array<Maybe<RelatedPersonGender>>>;
  gender_NOT_IN?: Maybe<Array<Maybe<RelatedPersonGender>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _active?: Maybe<ElementWhere>;
  _active_NOT?: Maybe<ElementWhere>;
  _active_IN?: Maybe<Array<ElementWhere>>;
  _active_NOT_IN?: Maybe<Array<ElementWhere>>;
  patient?: Maybe<ReferenceWhere>;
  patient_NOT?: Maybe<ReferenceWhere>;
  patient_IN?: Maybe<Array<ReferenceWhere>>;
  patient_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  relationship?: Maybe<CodeableConceptWhere>;
  relationship_NOT?: Maybe<CodeableConceptWhere>;
  name?: Maybe<HumanNameWhere>;
  name_NOT?: Maybe<HumanNameWhere>;
  telecom?: Maybe<ContactPointWhere>;
  telecom_NOT?: Maybe<ContactPointWhere>;
  _gender?: Maybe<ElementWhere>;
  _gender_NOT?: Maybe<ElementWhere>;
  _gender_IN?: Maybe<Array<ElementWhere>>;
  _gender_NOT_IN?: Maybe<Array<ElementWhere>>;
  _birthDate?: Maybe<ElementWhere>;
  _birthDate_NOT?: Maybe<ElementWhere>;
  _birthDate_IN?: Maybe<Array<ElementWhere>>;
  _birthDate_NOT_IN?: Maybe<Array<ElementWhere>>;
  address?: Maybe<AddressWhere>;
  address_NOT?: Maybe<AddressWhere>;
  photo?: Maybe<AttachmentWhere>;
  photo_NOT?: Maybe<AttachmentWhere>;
  period?: Maybe<PeriodWhere>;
  period_NOT?: Maybe<PeriodWhere>;
  period_IN?: Maybe<Array<PeriodWhere>>;
  period_NOT_IN?: Maybe<Array<PeriodWhere>>;
  communication?: Maybe<RelatedPersonCommunicationWhere>;
  communication_NOT?: Maybe<RelatedPersonCommunicationWhere>;
};

export type RelatedPerson_ActiveDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedPerson_ActiveFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedPerson_ActiveUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type RelatedPerson_BirthdateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedPerson_BirthdateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedPerson_BirthdateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type RelatedPerson_GenderDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedPerson_GenderFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedPerson_GenderUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type RelatedPerson_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedPerson_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedPerson_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type RelatedPerson_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type RelatedPerson_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type RelatedPerson_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export enum RelationshipDirection {
  In = 'IN',
  Out = 'OUT',
}

export type ResourceList =
  | Account
  | Appointment
  | AppointmentResponse
  | Coverage
  | Device
  | DeviceDefinition
  | DeviceMetric
  | Encounter
  | Endpoint
  | EpisodeOfCare
  | HealthcareService
  | Location
  | Organization
  | Patient
  | Person
  | Practitioner
  | PractitionerRole
  | RelatedPerson
  | Schedule
  | Slot
  | Substance;

/** A series of measurements taken by a device, with upper and lower limits. There may be more than one dimension in the data. */
export type SampledData = {
  __typename?: 'SampledData';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The length of time between sampling times, measured in milliseconds. */
  period?: Maybe<Scalars['Decimal']>;
  /** A correction factor that is applied to the sampled data points before they are added to the origin. */
  factor?: Maybe<Scalars['Decimal']>;
  /** The lower limit of detection of the measured points. This is needed if any of the data points have the value "L" (lower than detection limit). */
  lowerLimit?: Maybe<Scalars['Decimal']>;
  /** The upper limit of detection of the measured points. This is needed if any of the data points have the value "U" (higher than detection limit). */
  upperLimit?: Maybe<Scalars['Decimal']>;
  /** The number of sample points at each time point. If this value is greater than one, then the dimensions will be interlaced - all the sample points for a point in time will be recorded at once. */
  dimensions?: Maybe<Scalars['PositiveInt']>;
  /** A series of data points which are decimal values separated by a single space (character u20). The special values "E" (error), "L" (below detection limit) and "U" (above detection limit) can also be used in place of a decimal value. */
  data?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  origin: Quantity;
  _period?: Maybe<Element>;
  _factor?: Maybe<Element>;
  _lowerLimit?: Maybe<Element>;
  _upperLimit?: Maybe<Element>;
  _dimensions?: Maybe<Element>;
  _data?: Maybe<Element>;
};

/** A series of measurements taken by a device, with upper and lower limits. There may be more than one dimension in the data. */
export type SampledDataExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A series of measurements taken by a device, with upper and lower limits. There may be more than one dimension in the data. */
export type SampledDataOriginArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** A series of measurements taken by a device, with upper and lower limits. There may be more than one dimension in the data. */
export type SampledData_PeriodArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A series of measurements taken by a device, with upper and lower limits. There may be more than one dimension in the data. */
export type SampledData_FactorArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A series of measurements taken by a device, with upper and lower limits. There may be more than one dimension in the data. */
export type SampledData_LowerLimitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A series of measurements taken by a device, with upper and lower limits. There may be more than one dimension in the data. */
export type SampledData_UpperLimitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A series of measurements taken by a device, with upper and lower limits. There may be more than one dimension in the data. */
export type SampledData_DimensionsArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A series of measurements taken by a device, with upper and lower limits. There may be more than one dimension in the data. */
export type SampledData_DataArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type SampledDataConnectFieldInput = {
  where?: Maybe<SampledDataWhere>;
  connect?: Maybe<SampledDataConnectInput>;
};

export type SampledDataConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  origin?: Maybe<QuantityConnectFieldInput>;
  _period?: Maybe<ElementConnectFieldInput>;
  _factor?: Maybe<ElementConnectFieldInput>;
  _lowerLimit?: Maybe<ElementConnectFieldInput>;
  _upperLimit?: Maybe<ElementConnectFieldInput>;
  _dimensions?: Maybe<ElementConnectFieldInput>;
  _data?: Maybe<ElementConnectFieldInput>;
};

export type SampledDataCreateInput = {
  period?: Maybe<Scalars['Decimal']>;
  factor?: Maybe<Scalars['Decimal']>;
  lowerLimit?: Maybe<Scalars['Decimal']>;
  upperLimit?: Maybe<Scalars['Decimal']>;
  dimensions?: Maybe<Scalars['PositiveInt']>;
  data?: Maybe<Scalars['String']>;
  extension?: Maybe<SampledDataExtensionFieldInput>;
  origin?: Maybe<SampledDataOriginFieldInput>;
  _period?: Maybe<SampledData_PeriodFieldInput>;
  _factor?: Maybe<SampledData_FactorFieldInput>;
  _lowerLimit?: Maybe<SampledData_LowerlimitFieldInput>;
  _upperLimit?: Maybe<SampledData_UpperlimitFieldInput>;
  _dimensions?: Maybe<SampledData_DimensionsFieldInput>;
  _data?: Maybe<SampledData_DataFieldInput>;
};

export type SampledDataDeleteFieldInput = {
  where?: Maybe<SampledDataWhere>;
  delete?: Maybe<SampledDataDeleteInput>;
};

export type SampledDataDeleteInput = {
  extension?: Maybe<Array<SampledDataExtensionDeleteFieldInput>>;
  origin?: Maybe<SampledDataOriginDeleteFieldInput>;
  _period?: Maybe<SampledData_PeriodDeleteFieldInput>;
  _factor?: Maybe<SampledData_FactorDeleteFieldInput>;
  _lowerLimit?: Maybe<SampledData_LowerlimitDeleteFieldInput>;
  _upperLimit?: Maybe<SampledData_UpperlimitDeleteFieldInput>;
  _dimensions?: Maybe<SampledData_DimensionsDeleteFieldInput>;
  _data?: Maybe<SampledData_DataDeleteFieldInput>;
};

export type SampledDataDisconnectFieldInput = {
  where?: Maybe<SampledDataWhere>;
  disconnect?: Maybe<SampledDataDisconnectInput>;
};

export type SampledDataDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  origin?: Maybe<QuantityDisconnectFieldInput>;
  _period?: Maybe<ElementDisconnectFieldInput>;
  _factor?: Maybe<ElementDisconnectFieldInput>;
  _lowerLimit?: Maybe<ElementDisconnectFieldInput>;
  _upperLimit?: Maybe<ElementDisconnectFieldInput>;
  _dimensions?: Maybe<ElementDisconnectFieldInput>;
  _data?: Maybe<ElementDisconnectFieldInput>;
};

export type SampledDataExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SampledDataExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SampledDataExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SampledDataOptions = {
  /** Specify one or more SampledDataSort objects to sort SampledData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<SampledDataSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SampledDataOriginDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type SampledDataOriginFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type SampledDataOriginUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type SampledDataRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  origin?: Maybe<QuantityCreateInput>;
  _period?: Maybe<ElementCreateInput>;
  _factor?: Maybe<ElementCreateInput>;
  _lowerLimit?: Maybe<ElementCreateInput>;
  _upperLimit?: Maybe<ElementCreateInput>;
  _dimensions?: Maybe<ElementCreateInput>;
  _data?: Maybe<ElementCreateInput>;
};

/** Fields to sort SampledData by. The order in which sorts are applied is not guaranteed when specifying many fields in one SampledDataSort object. */
export type SampledDataSort = {
  id?: Maybe<SortDirection>;
  period?: Maybe<SortDirection>;
  factor?: Maybe<SortDirection>;
  lowerLimit?: Maybe<SortDirection>;
  upperLimit?: Maybe<SortDirection>;
  dimensions?: Maybe<SortDirection>;
  data?: Maybe<SortDirection>;
};

export type SampledDataUpdateInput = {
  period?: Maybe<Scalars['Decimal']>;
  factor?: Maybe<Scalars['Decimal']>;
  lowerLimit?: Maybe<Scalars['Decimal']>;
  upperLimit?: Maybe<Scalars['Decimal']>;
  dimensions?: Maybe<Scalars['PositiveInt']>;
  data?: Maybe<Scalars['String']>;
  extension?: Maybe<Array<SampledDataExtensionUpdateFieldInput>>;
  origin?: Maybe<SampledDataOriginUpdateFieldInput>;
  _period?: Maybe<SampledData_PeriodUpdateFieldInput>;
  _factor?: Maybe<SampledData_FactorUpdateFieldInput>;
  _lowerLimit?: Maybe<SampledData_LowerlimitUpdateFieldInput>;
  _upperLimit?: Maybe<SampledData_UpperlimitUpdateFieldInput>;
  _dimensions?: Maybe<SampledData_DimensionsUpdateFieldInput>;
  _data?: Maybe<SampledData_DataUpdateFieldInput>;
};

export type SampledDataWhere = {
  OR?: Maybe<Array<SampledDataWhere>>;
  AND?: Maybe<Array<SampledDataWhere>>;
  period?: Maybe<Scalars['Decimal']>;
  factor?: Maybe<Scalars['Decimal']>;
  lowerLimit?: Maybe<Scalars['Decimal']>;
  upperLimit?: Maybe<Scalars['Decimal']>;
  dimensions?: Maybe<Scalars['PositiveInt']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  origin?: Maybe<QuantityWhere>;
  origin_NOT?: Maybe<QuantityWhere>;
  origin_IN?: Maybe<Array<QuantityWhere>>;
  origin_NOT_IN?: Maybe<Array<QuantityWhere>>;
  _period?: Maybe<ElementWhere>;
  _period_NOT?: Maybe<ElementWhere>;
  _period_IN?: Maybe<Array<ElementWhere>>;
  _period_NOT_IN?: Maybe<Array<ElementWhere>>;
  _factor?: Maybe<ElementWhere>;
  _factor_NOT?: Maybe<ElementWhere>;
  _factor_IN?: Maybe<Array<ElementWhere>>;
  _factor_NOT_IN?: Maybe<Array<ElementWhere>>;
  _lowerLimit?: Maybe<ElementWhere>;
  _lowerLimit_NOT?: Maybe<ElementWhere>;
  _lowerLimit_IN?: Maybe<Array<ElementWhere>>;
  _lowerLimit_NOT_IN?: Maybe<Array<ElementWhere>>;
  _upperLimit?: Maybe<ElementWhere>;
  _upperLimit_NOT?: Maybe<ElementWhere>;
  _upperLimit_IN?: Maybe<Array<ElementWhere>>;
  _upperLimit_NOT_IN?: Maybe<Array<ElementWhere>>;
  _dimensions?: Maybe<ElementWhere>;
  _dimensions_NOT?: Maybe<ElementWhere>;
  _dimensions_IN?: Maybe<Array<ElementWhere>>;
  _dimensions_NOT_IN?: Maybe<Array<ElementWhere>>;
  _data?: Maybe<ElementWhere>;
  _data_NOT?: Maybe<ElementWhere>;
  _data_IN?: Maybe<Array<ElementWhere>>;
  _data_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type SampledData_DataDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type SampledData_DataFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type SampledData_DataUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type SampledData_DimensionsDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type SampledData_DimensionsFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type SampledData_DimensionsUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type SampledData_FactorDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type SampledData_FactorFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type SampledData_FactorUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type SampledData_LowerlimitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type SampledData_LowerlimitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type SampledData_LowerlimitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type SampledData_PeriodDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type SampledData_PeriodFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type SampledData_PeriodUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type SampledData_UpperlimitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type SampledData_UpperlimitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type SampledData_UpperlimitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A container for slots of time that may be available for booking appointments. */
export type Schedule = {
  __typename?: 'Schedule';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** Whether this schedule record is in active use or should not be used (such as was entered in error). */
  active?: Maybe<Scalars['Boolean']>;
  /** This is a Schedule resource */
  resourceType: ScheduleResourceType;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** Comments on the availability to describe any extended information. Such as custom constraints on the slots that may be associated. */
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _active?: Maybe<Element>;
  serviceCategory: Array<CodeableConcept>;
  serviceType: Array<CodeableConcept>;
  specialty: Array<CodeableConcept>;
  actor: Array<Reference>;
  planningHorizon?: Maybe<Period>;
  _comment?: Maybe<Element>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type Schedule_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type Schedule_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type Schedule_ActiveArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleServiceCategoryArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleServiceTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleSpecialtyArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type ScheduleActorArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type SchedulePlanningHorizonArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** A container for slots of time that may be available for booking appointments. */
export type Schedule_CommentArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type ScheduleActorDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type ScheduleActorFieldInput = {
  create?: Maybe<Array<ReferenceCreateInput>>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
};

export type ScheduleActorUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<Array<ReferenceConnectFieldInput>>;
  disconnect?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  create?: Maybe<Array<ReferenceCreateInput>>;
  delete?: Maybe<Array<ReferenceDeleteFieldInput>>;
};

export type ScheduleConnectFieldInput = {
  where?: Maybe<ScheduleWhere>;
  connect?: Maybe<ScheduleConnectInput>;
};

export type ScheduleConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _active?: Maybe<ElementConnectFieldInput>;
  serviceCategory?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  serviceType?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  actor?: Maybe<Array<ReferenceConnectFieldInput>>;
  planningHorizon?: Maybe<PeriodConnectFieldInput>;
  _comment?: Maybe<ElementConnectFieldInput>;
};

export type ScheduleContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type ScheduleContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type ScheduleContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type ScheduleContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type ScheduleContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type ScheduleContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type ScheduleContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type ScheduleContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type ScheduleContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type ScheduleContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type ScheduleContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type ScheduleContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type ScheduleContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type ScheduleContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type ScheduleContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type ScheduleContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type ScheduleContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type ScheduleContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type ScheduleContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type ScheduleContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type ScheduleContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type ScheduleContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type ScheduleContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type ScheduleContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type ScheduleContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type ScheduleContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type ScheduleContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type ScheduleContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type ScheduleContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type ScheduleContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type ScheduleContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type ScheduleContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type ScheduleContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type ScheduleContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type ScheduleContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type ScheduleContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type ScheduleContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type ScheduleContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type ScheduleContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type ScheduleContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type ScheduleContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type ScheduleContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type ScheduleContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type ScheduleContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type ScheduleContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type ScheduleContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type ScheduleContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type ScheduleContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type ScheduleContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type ScheduleContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type ScheduleContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type ScheduleContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type ScheduleContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type ScheduleContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type ScheduleContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type ScheduleContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type ScheduleContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type ScheduleContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type ScheduleContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type ScheduleContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type ScheduleContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type ScheduleContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type ScheduleContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type ScheduleCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  comment?: Maybe<Scalars['String']>;
  resourceType: ScheduleResourceType;
  meta?: Maybe<ScheduleMetaFieldInput>;
  _implicitRules?: Maybe<Schedule_ImplicitrulesFieldInput>;
  _language?: Maybe<Schedule_LanguageFieldInput>;
  text?: Maybe<ScheduleTextFieldInput>;
  contained_Account?: Maybe<ScheduleContainedAccountFieldInput>;
  contained_Appointment?: Maybe<ScheduleContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<ScheduleContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<ScheduleContainedCoverageFieldInput>;
  contained_Device?: Maybe<ScheduleContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<ScheduleContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<ScheduleContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<ScheduleContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<ScheduleContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<ScheduleContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<ScheduleContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<ScheduleContainedLocationFieldInput>;
  contained_Organization?: Maybe<ScheduleContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<ScheduleContainedPatientFieldInput>;
  contained_Person?: Maybe<ScheduleContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<ScheduleContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<ScheduleContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<ScheduleContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<ScheduleContainedScheduleFieldInput>;
  contained_Slot?: Maybe<ScheduleContainedSlotFieldInput>;
  contained_Substance?: Maybe<ScheduleContainedSubstanceFieldInput>;
  extension?: Maybe<ScheduleExtensionFieldInput>;
  modifierExtension?: Maybe<ScheduleModifierextensionFieldInput>;
  identifier?: Maybe<ScheduleIdentifierFieldInput>;
  _active?: Maybe<Schedule_ActiveFieldInput>;
  serviceCategory?: Maybe<ScheduleServicecategoryFieldInput>;
  serviceType?: Maybe<ScheduleServicetypeFieldInput>;
  specialty?: Maybe<ScheduleSpecialtyFieldInput>;
  actor?: Maybe<ScheduleActorFieldInput>;
  planningHorizon?: Maybe<SchedulePlanninghorizonFieldInput>;
  _comment?: Maybe<Schedule_CommentFieldInput>;
};

export type ScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type ScheduleDeleteInput = {
  meta?: Maybe<ScheduleMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Schedule_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Schedule_LanguageDeleteFieldInput>;
  text?: Maybe<ScheduleTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<ScheduleContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<ScheduleContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<ScheduleContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<ScheduleContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<ScheduleContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<ScheduleContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<ScheduleContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<ScheduleContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<ScheduleContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<ScheduleContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<ScheduleContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<ScheduleContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<ScheduleContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<ScheduleContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<ScheduleContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<ScheduleContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<ScheduleContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<ScheduleContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<ScheduleContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<ScheduleContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<ScheduleExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<ScheduleModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<ScheduleIdentifierDeleteFieldInput>>;
  _active?: Maybe<Schedule_ActiveDeleteFieldInput>;
  serviceCategory?: Maybe<Array<ScheduleServicecategoryDeleteFieldInput>>;
  serviceType?: Maybe<Array<ScheduleServicetypeDeleteFieldInput>>;
  specialty?: Maybe<Array<ScheduleSpecialtyDeleteFieldInput>>;
  actor?: Maybe<Array<ScheduleActorDeleteFieldInput>>;
  planningHorizon?: Maybe<SchedulePlanninghorizonDeleteFieldInput>;
  _comment?: Maybe<Schedule_CommentDeleteFieldInput>;
};

export type ScheduleDisconnectFieldInput = {
  where?: Maybe<ScheduleWhere>;
  disconnect?: Maybe<ScheduleDisconnectInput>;
};

export type ScheduleDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _active?: Maybe<ElementDisconnectFieldInput>;
  serviceCategory?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  serviceType?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  actor?: Maybe<Array<ReferenceDisconnectFieldInput>>;
  planningHorizon?: Maybe<PeriodDisconnectFieldInput>;
  _comment?: Maybe<ElementDisconnectFieldInput>;
};

export type ScheduleExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ScheduleExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ScheduleExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ScheduleIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type ScheduleIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type ScheduleIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type ScheduleMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type ScheduleMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type ScheduleMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type ScheduleModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type ScheduleModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type ScheduleModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type ScheduleOptions = {
  /** Specify one or more ScheduleSort objects to sort Schedules by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<ScheduleSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SchedulePlanninghorizonDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type SchedulePlanninghorizonFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type SchedulePlanninghorizonUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type ScheduleRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _active?: Maybe<ElementCreateInput>;
  serviceCategory?: Maybe<Array<CodeableConceptCreateInput>>;
  serviceType?: Maybe<Array<CodeableConceptCreateInput>>;
  specialty?: Maybe<Array<CodeableConceptCreateInput>>;
  actor?: Maybe<Array<ReferenceCreateInput>>;
  planningHorizon?: Maybe<PeriodCreateInput>;
  _comment?: Maybe<ElementCreateInput>;
};

export enum ScheduleResourceType {
  Schedule = 'Schedule',
}

export type ScheduleServicecategoryDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type ScheduleServicecategoryFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type ScheduleServicecategoryUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type ScheduleServicetypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type ScheduleServicetypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type ScheduleServicetypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

/** Fields to sort Schedules by. The order in which sorts are applied is not guaranteed when specifying many fields in one ScheduleSort object. */
export type ScheduleSort = {
  id?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  comment?: Maybe<SortDirection>;
};

export type ScheduleSpecialtyDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type ScheduleSpecialtyFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type ScheduleSpecialtyUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type ScheduleTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type ScheduleTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type ScheduleTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type ScheduleUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  comment?: Maybe<Scalars['String']>;
  resourceType?: Maybe<ScheduleResourceType>;
  meta?: Maybe<ScheduleMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Schedule_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Schedule_LanguageUpdateFieldInput>;
  text?: Maybe<ScheduleTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<ScheduleContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<ScheduleContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<ScheduleContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<ScheduleContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<ScheduleContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<ScheduleContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<ScheduleContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<ScheduleContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<ScheduleContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<ScheduleContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<ScheduleContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<ScheduleContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<ScheduleContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<ScheduleContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<ScheduleContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<ScheduleContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<ScheduleContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<ScheduleContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<ScheduleContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<ScheduleContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<ScheduleExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<ScheduleModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<ScheduleIdentifierUpdateFieldInput>>;
  _active?: Maybe<Schedule_ActiveUpdateFieldInput>;
  serviceCategory?: Maybe<Array<ScheduleServicecategoryUpdateFieldInput>>;
  serviceType?: Maybe<Array<ScheduleServicetypeUpdateFieldInput>>;
  specialty?: Maybe<Array<ScheduleSpecialtyUpdateFieldInput>>;
  actor?: Maybe<Array<ScheduleActorUpdateFieldInput>>;
  planningHorizon?: Maybe<SchedulePlanninghorizonUpdateFieldInput>;
  _comment?: Maybe<Schedule_CommentUpdateFieldInput>;
};

export type ScheduleWhere = {
  OR?: Maybe<Array<ScheduleWhere>>;
  AND?: Maybe<Array<ScheduleWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<ScheduleResourceType>;
  resourceType_NOT?: Maybe<ScheduleResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<ScheduleResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<ScheduleResourceType>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _active?: Maybe<ElementWhere>;
  _active_NOT?: Maybe<ElementWhere>;
  _active_IN?: Maybe<Array<ElementWhere>>;
  _active_NOT_IN?: Maybe<Array<ElementWhere>>;
  serviceCategory?: Maybe<CodeableConceptWhere>;
  serviceCategory_NOT?: Maybe<CodeableConceptWhere>;
  serviceType?: Maybe<CodeableConceptWhere>;
  serviceType_NOT?: Maybe<CodeableConceptWhere>;
  specialty?: Maybe<CodeableConceptWhere>;
  specialty_NOT?: Maybe<CodeableConceptWhere>;
  actor?: Maybe<ReferenceWhere>;
  actor_NOT?: Maybe<ReferenceWhere>;
  planningHorizon?: Maybe<PeriodWhere>;
  planningHorizon_NOT?: Maybe<PeriodWhere>;
  planningHorizon_IN?: Maybe<Array<PeriodWhere>>;
  planningHorizon_NOT_IN?: Maybe<Array<PeriodWhere>>;
  _comment?: Maybe<ElementWhere>;
  _comment_NOT?: Maybe<ElementWhere>;
  _comment_IN?: Maybe<Array<ElementWhere>>;
  _comment_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Schedule_ActiveDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Schedule_ActiveFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Schedule_ActiveUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Schedule_CommentDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Schedule_CommentFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Schedule_CommentUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Schedule_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Schedule_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Schedule_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Schedule_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Schedule_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Schedule_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A signature along with supporting context. The signature may be a digital signature that is cryptographic in nature, or some other signature acceptable to the domain. This other signature may be as simple as a graphical image representing a hand-written signature, or a signature ceremony Different signature approaches have different utilities. */
export type Signature = {
  __typename?: 'Signature';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** When the digital signature was signed. */
  when?: Maybe<Scalars['Instant']>;
  /** A mime type that indicates the technical format of the target resources signed by the signature. */
  targetFormat?: Maybe<Scalars['Code']>;
  /** A mime type that indicates the technical format of the signature. Important mime types are application/signature+xml for X ML DigSig, application/jose for JWS, and image/* for a graphical image of a signature, etc. */
  sigFormat?: Maybe<Scalars['Code']>;
  /** The base64 encoding of the Signature content. When signature is not recorded electronically this element would be empty. */
  data?: Maybe<Scalars['Base64Binary']>;
  extension: Array<Extension>;
  type: Array<Coding>;
  _when?: Maybe<Element>;
  who: Reference;
  onBehalfOf?: Maybe<Reference>;
  _targetFormat?: Maybe<Element>;
  _sigFormat?: Maybe<Element>;
  _data?: Maybe<Element>;
};

/** A signature along with supporting context. The signature may be a digital signature that is cryptographic in nature, or some other signature acceptable to the domain. This other signature may be as simple as a graphical image representing a hand-written signature, or a signature ceremony Different signature approaches have different utilities. */
export type SignatureExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A signature along with supporting context. The signature may be a digital signature that is cryptographic in nature, or some other signature acceptable to the domain. This other signature may be as simple as a graphical image representing a hand-written signature, or a signature ceremony Different signature approaches have different utilities. */
export type SignatureTypeArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

/** A signature along with supporting context. The signature may be a digital signature that is cryptographic in nature, or some other signature acceptable to the domain. This other signature may be as simple as a graphical image representing a hand-written signature, or a signature ceremony Different signature approaches have different utilities. */
export type Signature_WhenArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A signature along with supporting context. The signature may be a digital signature that is cryptographic in nature, or some other signature acceptable to the domain. This other signature may be as simple as a graphical image representing a hand-written signature, or a signature ceremony Different signature approaches have different utilities. */
export type SignatureWhoArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A signature along with supporting context. The signature may be a digital signature that is cryptographic in nature, or some other signature acceptable to the domain. This other signature may be as simple as a graphical image representing a hand-written signature, or a signature ceremony Different signature approaches have different utilities. */
export type SignatureOnBehalfOfArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A signature along with supporting context. The signature may be a digital signature that is cryptographic in nature, or some other signature acceptable to the domain. This other signature may be as simple as a graphical image representing a hand-written signature, or a signature ceremony Different signature approaches have different utilities. */
export type Signature_TargetFormatArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A signature along with supporting context. The signature may be a digital signature that is cryptographic in nature, or some other signature acceptable to the domain. This other signature may be as simple as a graphical image representing a hand-written signature, or a signature ceremony Different signature approaches have different utilities. */
export type Signature_SigFormatArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A signature along with supporting context. The signature may be a digital signature that is cryptographic in nature, or some other signature acceptable to the domain. This other signature may be as simple as a graphical image representing a hand-written signature, or a signature ceremony Different signature approaches have different utilities. */
export type Signature_DataArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type SignatureConnectFieldInput = {
  where?: Maybe<SignatureWhere>;
  connect?: Maybe<SignatureConnectInput>;
};

export type SignatureConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  type?: Maybe<Array<CodingConnectFieldInput>>;
  _when?: Maybe<ElementConnectFieldInput>;
  who?: Maybe<ReferenceConnectFieldInput>;
  onBehalfOf?: Maybe<ReferenceConnectFieldInput>;
  _targetFormat?: Maybe<ElementConnectFieldInput>;
  _sigFormat?: Maybe<ElementConnectFieldInput>;
  _data?: Maybe<ElementConnectFieldInput>;
};

export type SignatureCreateInput = {
  when?: Maybe<Scalars['Instant']>;
  targetFormat?: Maybe<Scalars['Code']>;
  sigFormat?: Maybe<Scalars['Code']>;
  data?: Maybe<Scalars['Base64Binary']>;
  extension?: Maybe<SignatureExtensionFieldInput>;
  type?: Maybe<SignatureTypeFieldInput>;
  _when?: Maybe<Signature_WhenFieldInput>;
  who?: Maybe<SignatureWhoFieldInput>;
  onBehalfOf?: Maybe<SignatureOnbehalfofFieldInput>;
  _targetFormat?: Maybe<Signature_TargetformatFieldInput>;
  _sigFormat?: Maybe<Signature_SigformatFieldInput>;
  _data?: Maybe<Signature_DataFieldInput>;
};

export type SignatureDeleteFieldInput = {
  where?: Maybe<SignatureWhere>;
  delete?: Maybe<SignatureDeleteInput>;
};

export type SignatureDeleteInput = {
  extension?: Maybe<Array<SignatureExtensionDeleteFieldInput>>;
  type?: Maybe<Array<SignatureTypeDeleteFieldInput>>;
  _when?: Maybe<Signature_WhenDeleteFieldInput>;
  who?: Maybe<SignatureWhoDeleteFieldInput>;
  onBehalfOf?: Maybe<SignatureOnbehalfofDeleteFieldInput>;
  _targetFormat?: Maybe<Signature_TargetformatDeleteFieldInput>;
  _sigFormat?: Maybe<Signature_SigformatDeleteFieldInput>;
  _data?: Maybe<Signature_DataDeleteFieldInput>;
};

export type SignatureDisconnectFieldInput = {
  where?: Maybe<SignatureWhere>;
  disconnect?: Maybe<SignatureDisconnectInput>;
};

export type SignatureDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  type?: Maybe<Array<CodingDisconnectFieldInput>>;
  _when?: Maybe<ElementDisconnectFieldInput>;
  who?: Maybe<ReferenceDisconnectFieldInput>;
  onBehalfOf?: Maybe<ReferenceDisconnectFieldInput>;
  _targetFormat?: Maybe<ElementDisconnectFieldInput>;
  _sigFormat?: Maybe<ElementDisconnectFieldInput>;
  _data?: Maybe<ElementDisconnectFieldInput>;
};

export type SignatureExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SignatureExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SignatureExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SignatureOnbehalfofDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type SignatureOnbehalfofFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type SignatureOnbehalfofUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type SignatureOptions = {
  /** Specify one or more SignatureSort objects to sort Signatures by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<SignatureSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SignatureRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  type?: Maybe<Array<CodingCreateInput>>;
  _when?: Maybe<ElementCreateInput>;
  who?: Maybe<ReferenceCreateInput>;
  onBehalfOf?: Maybe<ReferenceCreateInput>;
  _targetFormat?: Maybe<ElementCreateInput>;
  _sigFormat?: Maybe<ElementCreateInput>;
  _data?: Maybe<ElementCreateInput>;
};

/** Fields to sort Signatures by. The order in which sorts are applied is not guaranteed when specifying many fields in one SignatureSort object. */
export type SignatureSort = {
  id?: Maybe<SortDirection>;
  when?: Maybe<SortDirection>;
  targetFormat?: Maybe<SortDirection>;
  sigFormat?: Maybe<SortDirection>;
  data?: Maybe<SortDirection>;
};

export type SignatureTypeDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type SignatureTypeFieldInput = {
  create?: Maybe<Array<CodingCreateInput>>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
};

export type SignatureTypeUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<Array<CodingConnectFieldInput>>;
  disconnect?: Maybe<Array<CodingDisconnectFieldInput>>;
  create?: Maybe<Array<CodingCreateInput>>;
  delete?: Maybe<Array<CodingDeleteFieldInput>>;
};

export type SignatureUpdateInput = {
  when?: Maybe<Scalars['Instant']>;
  targetFormat?: Maybe<Scalars['Code']>;
  sigFormat?: Maybe<Scalars['Code']>;
  data?: Maybe<Scalars['Base64Binary']>;
  extension?: Maybe<Array<SignatureExtensionUpdateFieldInput>>;
  type?: Maybe<Array<SignatureTypeUpdateFieldInput>>;
  _when?: Maybe<Signature_WhenUpdateFieldInput>;
  who?: Maybe<SignatureWhoUpdateFieldInput>;
  onBehalfOf?: Maybe<SignatureOnbehalfofUpdateFieldInput>;
  _targetFormat?: Maybe<Signature_TargetformatUpdateFieldInput>;
  _sigFormat?: Maybe<Signature_SigformatUpdateFieldInput>;
  _data?: Maybe<Signature_DataUpdateFieldInput>;
};

export type SignatureWhere = {
  OR?: Maybe<Array<SignatureWhere>>;
  AND?: Maybe<Array<SignatureWhere>>;
  when?: Maybe<Scalars['Instant']>;
  targetFormat?: Maybe<Scalars['Code']>;
  sigFormat?: Maybe<Scalars['Code']>;
  data?: Maybe<Scalars['Base64Binary']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  type?: Maybe<CodingWhere>;
  type_NOT?: Maybe<CodingWhere>;
  _when?: Maybe<ElementWhere>;
  _when_NOT?: Maybe<ElementWhere>;
  _when_IN?: Maybe<Array<ElementWhere>>;
  _when_NOT_IN?: Maybe<Array<ElementWhere>>;
  who?: Maybe<ReferenceWhere>;
  who_NOT?: Maybe<ReferenceWhere>;
  who_IN?: Maybe<Array<ReferenceWhere>>;
  who_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  onBehalfOf?: Maybe<ReferenceWhere>;
  onBehalfOf_NOT?: Maybe<ReferenceWhere>;
  onBehalfOf_IN?: Maybe<Array<ReferenceWhere>>;
  onBehalfOf_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _targetFormat?: Maybe<ElementWhere>;
  _targetFormat_NOT?: Maybe<ElementWhere>;
  _targetFormat_IN?: Maybe<Array<ElementWhere>>;
  _targetFormat_NOT_IN?: Maybe<Array<ElementWhere>>;
  _sigFormat?: Maybe<ElementWhere>;
  _sigFormat_NOT?: Maybe<ElementWhere>;
  _sigFormat_IN?: Maybe<Array<ElementWhere>>;
  _sigFormat_NOT_IN?: Maybe<Array<ElementWhere>>;
  _data?: Maybe<ElementWhere>;
  _data_NOT?: Maybe<ElementWhere>;
  _data_IN?: Maybe<Array<ElementWhere>>;
  _data_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type SignatureWhoDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type SignatureWhoFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type SignatureWhoUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type Signature_DataDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Signature_DataFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Signature_DataUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Signature_SigformatDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Signature_SigformatFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Signature_SigformatUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Signature_TargetformatDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Signature_TargetformatFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Signature_TargetformatUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Signature_WhenDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Signature_WhenFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Signature_WhenUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type Slot = {
  __typename?: 'Slot';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This slot has already been overbooked, appointments are unlikely to be accepted for this time. */
  overbooked?: Maybe<Scalars['Boolean']>;
  /** This is a Slot resource */
  resourceType: SlotResourceType;
  /** busy | free | busy-unavailable | busy-tentative | entered-in-error. */
  status?: Maybe<SlotStatus>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** Date/Time that the slot is to begin. */
  start?: Maybe<Scalars['Instant']>;
  /** Date/Time that the slot is to conclude. */
  end?: Maybe<Scalars['Instant']>;
  /** Comments on the slot to describe any extended information. Such as custom constraints on the slot. */
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  serviceCategory: Array<CodeableConcept>;
  serviceType: Array<CodeableConcept>;
  specialty: Array<CodeableConcept>;
  appointmentType?: Maybe<CodeableConcept>;
  schedule: Reference;
  _status?: Maybe<Element>;
  _start?: Maybe<Element>;
  _end?: Maybe<Element>;
  _overbooked?: Maybe<Element>;
  _comment?: Maybe<Element>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type Slot_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type Slot_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotServiceCategoryArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotServiceTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotSpecialtyArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotAppointmentTypeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotScheduleArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type Slot_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type Slot_StartArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type Slot_EndArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type Slot_OverbookedArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A slot of time on a schedule that may be available for booking appointments. */
export type Slot_CommentArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type SlotAppointmenttypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type SlotAppointmenttypeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type SlotAppointmenttypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type SlotConnectFieldInput = {
  where?: Maybe<SlotWhere>;
  connect?: Maybe<SlotConnectInput>;
};

export type SlotConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  serviceCategory?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  serviceType?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  appointmentType?: Maybe<CodeableConceptConnectFieldInput>;
  schedule?: Maybe<ReferenceConnectFieldInput>;
  _status?: Maybe<ElementConnectFieldInput>;
  _start?: Maybe<ElementConnectFieldInput>;
  _end?: Maybe<ElementConnectFieldInput>;
  _overbooked?: Maybe<ElementConnectFieldInput>;
  _comment?: Maybe<ElementConnectFieldInput>;
};

export type SlotContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type SlotContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type SlotContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type SlotContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type SlotContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type SlotContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type SlotContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type SlotContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type SlotContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type SlotContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type SlotContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type SlotContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type SlotContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type SlotContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type SlotContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type SlotContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type SlotContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type SlotContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type SlotContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type SlotContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type SlotContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type SlotContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type SlotContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type SlotContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type SlotContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type SlotContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type SlotContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type SlotContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type SlotContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type SlotContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type SlotContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type SlotContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type SlotContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type SlotContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type SlotContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type SlotContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type SlotContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type SlotContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type SlotContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type SlotContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type SlotContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type SlotContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type SlotContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type SlotContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type SlotContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type SlotContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type SlotContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type SlotContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type SlotContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type SlotContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type SlotContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type SlotContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type SlotContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type SlotContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type SlotContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type SlotContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type SlotContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type SlotContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type SlotContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type SlotContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type SlotContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type SlotContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type SlotContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type SlotCreateInput = {
  overbooked?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  start?: Maybe<Scalars['Instant']>;
  end?: Maybe<Scalars['Instant']>;
  comment?: Maybe<Scalars['String']>;
  resourceType: SlotResourceType;
  status?: Maybe<SlotStatus>;
  meta?: Maybe<SlotMetaFieldInput>;
  _implicitRules?: Maybe<Slot_ImplicitrulesFieldInput>;
  _language?: Maybe<Slot_LanguageFieldInput>;
  text?: Maybe<SlotTextFieldInput>;
  contained_Account?: Maybe<SlotContainedAccountFieldInput>;
  contained_Appointment?: Maybe<SlotContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<SlotContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<SlotContainedCoverageFieldInput>;
  contained_Device?: Maybe<SlotContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<SlotContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<SlotContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<SlotContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<SlotContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<SlotContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<SlotContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<SlotContainedLocationFieldInput>;
  contained_Organization?: Maybe<SlotContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<SlotContainedPatientFieldInput>;
  contained_Person?: Maybe<SlotContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<SlotContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<SlotContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<SlotContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<SlotContainedScheduleFieldInput>;
  contained_Slot?: Maybe<SlotContainedSlotFieldInput>;
  contained_Substance?: Maybe<SlotContainedSubstanceFieldInput>;
  extension?: Maybe<SlotExtensionFieldInput>;
  modifierExtension?: Maybe<SlotModifierextensionFieldInput>;
  identifier?: Maybe<SlotIdentifierFieldInput>;
  serviceCategory?: Maybe<SlotServicecategoryFieldInput>;
  serviceType?: Maybe<SlotServicetypeFieldInput>;
  specialty?: Maybe<SlotSpecialtyFieldInput>;
  appointmentType?: Maybe<SlotAppointmenttypeFieldInput>;
  schedule?: Maybe<SlotScheduleFieldInput>;
  _status?: Maybe<Slot_StatusFieldInput>;
  _start?: Maybe<Slot_StartFieldInput>;
  _end?: Maybe<Slot_EndFieldInput>;
  _overbooked?: Maybe<Slot_OverbookedFieldInput>;
  _comment?: Maybe<Slot_CommentFieldInput>;
};

export type SlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type SlotDeleteInput = {
  meta?: Maybe<SlotMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Slot_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Slot_LanguageDeleteFieldInput>;
  text?: Maybe<SlotTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<SlotContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<SlotContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<SlotContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<SlotContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<SlotContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<SlotContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<SlotContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<SlotContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<SlotContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<SlotContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<SlotContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<SlotContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<SlotContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<SlotContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<SlotContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<SlotContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<SlotContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<SlotContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<SlotContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<SlotContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<SlotContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<SlotExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<SlotModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<SlotIdentifierDeleteFieldInput>>;
  serviceCategory?: Maybe<Array<SlotServicecategoryDeleteFieldInput>>;
  serviceType?: Maybe<Array<SlotServicetypeDeleteFieldInput>>;
  specialty?: Maybe<Array<SlotSpecialtyDeleteFieldInput>>;
  appointmentType?: Maybe<SlotAppointmenttypeDeleteFieldInput>;
  schedule?: Maybe<SlotScheduleDeleteFieldInput>;
  _status?: Maybe<Slot_StatusDeleteFieldInput>;
  _start?: Maybe<Slot_StartDeleteFieldInput>;
  _end?: Maybe<Slot_EndDeleteFieldInput>;
  _overbooked?: Maybe<Slot_OverbookedDeleteFieldInput>;
  _comment?: Maybe<Slot_CommentDeleteFieldInput>;
};

export type SlotDisconnectFieldInput = {
  where?: Maybe<SlotWhere>;
  disconnect?: Maybe<SlotDisconnectInput>;
};

export type SlotDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  serviceCategory?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  serviceType?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  specialty?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  appointmentType?: Maybe<CodeableConceptDisconnectFieldInput>;
  schedule?: Maybe<ReferenceDisconnectFieldInput>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  _start?: Maybe<ElementDisconnectFieldInput>;
  _end?: Maybe<ElementDisconnectFieldInput>;
  _overbooked?: Maybe<ElementDisconnectFieldInput>;
  _comment?: Maybe<ElementDisconnectFieldInput>;
};

export type SlotExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SlotExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SlotExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SlotIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type SlotIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type SlotIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

export type SlotMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type SlotMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type SlotMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type SlotModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SlotModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SlotModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SlotOptions = {
  /** Specify one or more SlotSort objects to sort Slots by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<SlotSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SlotRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  serviceCategory?: Maybe<Array<CodeableConceptCreateInput>>;
  serviceType?: Maybe<Array<CodeableConceptCreateInput>>;
  specialty?: Maybe<Array<CodeableConceptCreateInput>>;
  appointmentType?: Maybe<CodeableConceptCreateInput>;
  schedule?: Maybe<ReferenceCreateInput>;
  _status?: Maybe<ElementCreateInput>;
  _start?: Maybe<ElementCreateInput>;
  _end?: Maybe<ElementCreateInput>;
  _overbooked?: Maybe<ElementCreateInput>;
  _comment?: Maybe<ElementCreateInput>;
};

export enum SlotResourceType {
  Slot = 'Slot',
}

export type SlotScheduleDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type SlotScheduleFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type SlotScheduleUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type SlotServicecategoryDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type SlotServicecategoryFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type SlotServicecategoryUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type SlotServicetypeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type SlotServicetypeFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type SlotServicetypeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

/** Fields to sort Slots by. The order in which sorts are applied is not guaranteed when specifying many fields in one SlotSort object. */
export type SlotSort = {
  id?: Maybe<SortDirection>;
  overbooked?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  start?: Maybe<SortDirection>;
  end?: Maybe<SortDirection>;
  comment?: Maybe<SortDirection>;
};

export type SlotSpecialtyDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type SlotSpecialtyFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type SlotSpecialtyUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

/** busy | free | busy-unavailable | busy-tentative | entered-in-error. */
export enum SlotStatus {
  Busy = 'busy',
  Free = 'free',
  BusyUnavailable = 'busyUnavailable',
  BusyTentative = 'busyTentative',
  EnteredInError = 'enteredInError',
}

export type SlotTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type SlotTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type SlotTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type SlotUpdateInput = {
  overbooked?: Maybe<Scalars['Boolean']>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  start?: Maybe<Scalars['Instant']>;
  end?: Maybe<Scalars['Instant']>;
  comment?: Maybe<Scalars['String']>;
  resourceType?: Maybe<SlotResourceType>;
  status?: Maybe<SlotStatus>;
  meta?: Maybe<SlotMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Slot_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Slot_LanguageUpdateFieldInput>;
  text?: Maybe<SlotTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<SlotContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<SlotContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<SlotContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<SlotContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<SlotContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<SlotContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<SlotContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<SlotContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<SlotContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<SlotContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<SlotContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<SlotContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<SlotContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<SlotContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<SlotContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<SlotContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<SlotContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<SlotContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<SlotContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<SlotContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<SlotContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<SlotExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<SlotModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<SlotIdentifierUpdateFieldInput>>;
  serviceCategory?: Maybe<Array<SlotServicecategoryUpdateFieldInput>>;
  serviceType?: Maybe<Array<SlotServicetypeUpdateFieldInput>>;
  specialty?: Maybe<Array<SlotSpecialtyUpdateFieldInput>>;
  appointmentType?: Maybe<SlotAppointmenttypeUpdateFieldInput>;
  schedule?: Maybe<SlotScheduleUpdateFieldInput>;
  _status?: Maybe<Slot_StatusUpdateFieldInput>;
  _start?: Maybe<Slot_StartUpdateFieldInput>;
  _end?: Maybe<Slot_EndUpdateFieldInput>;
  _overbooked?: Maybe<Slot_OverbookedUpdateFieldInput>;
  _comment?: Maybe<Slot_CommentUpdateFieldInput>;
};

export type SlotWhere = {
  OR?: Maybe<Array<SlotWhere>>;
  AND?: Maybe<Array<SlotWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  start?: Maybe<Scalars['Instant']>;
  end?: Maybe<Scalars['Instant']>;
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  overbooked?: Maybe<Scalars['Boolean']>;
  overbooked_NOT?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<SlotResourceType>;
  resourceType_NOT?: Maybe<SlotResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<SlotResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<SlotResourceType>>>;
  status?: Maybe<SlotStatus>;
  status_NOT?: Maybe<SlotStatus>;
  status_IN?: Maybe<Array<Maybe<SlotStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<SlotStatus>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  serviceCategory?: Maybe<CodeableConceptWhere>;
  serviceCategory_NOT?: Maybe<CodeableConceptWhere>;
  serviceType?: Maybe<CodeableConceptWhere>;
  serviceType_NOT?: Maybe<CodeableConceptWhere>;
  specialty?: Maybe<CodeableConceptWhere>;
  specialty_NOT?: Maybe<CodeableConceptWhere>;
  appointmentType?: Maybe<CodeableConceptWhere>;
  appointmentType_NOT?: Maybe<CodeableConceptWhere>;
  appointmentType_IN?: Maybe<Array<CodeableConceptWhere>>;
  appointmentType_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  schedule?: Maybe<ReferenceWhere>;
  schedule_NOT?: Maybe<ReferenceWhere>;
  schedule_IN?: Maybe<Array<ReferenceWhere>>;
  schedule_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  _start?: Maybe<ElementWhere>;
  _start_NOT?: Maybe<ElementWhere>;
  _start_IN?: Maybe<Array<ElementWhere>>;
  _start_NOT_IN?: Maybe<Array<ElementWhere>>;
  _end?: Maybe<ElementWhere>;
  _end_NOT?: Maybe<ElementWhere>;
  _end_IN?: Maybe<Array<ElementWhere>>;
  _end_NOT_IN?: Maybe<Array<ElementWhere>>;
  _overbooked?: Maybe<ElementWhere>;
  _overbooked_NOT?: Maybe<ElementWhere>;
  _overbooked_IN?: Maybe<Array<ElementWhere>>;
  _overbooked_NOT_IN?: Maybe<Array<ElementWhere>>;
  _comment?: Maybe<ElementWhere>;
  _comment_NOT?: Maybe<ElementWhere>;
  _comment_IN?: Maybe<Array<ElementWhere>>;
  _comment_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type Slot_CommentDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Slot_CommentFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Slot_CommentUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Slot_EndDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Slot_EndFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Slot_EndUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Slot_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Slot_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Slot_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Slot_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Slot_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Slot_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Slot_OverbookedDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Slot_OverbookedFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Slot_OverbookedUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Slot_StartDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Slot_StartFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Slot_StartUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Slot_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Slot_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Slot_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export enum SortDirection {
  /** Sort by field values in ascending order. */
  Asc = 'ASC',
  /** Sort by field values in descending order. */
  Desc = 'DESC',
}

/** A homogeneous material with a definite composition. */
export type Substance = {
  __typename?: 'Substance';
  /** The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes. */
  id: Scalars['ID'];
  /** This is a Substance resource */
  resourceType: SubstanceResourceType;
  /** A code to indicate if the substance is actively used. */
  status?: Maybe<SubstanceStatus>;
  /** A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc. */
  implicitRules?: Maybe<Scalars['Uri']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['Code']>;
  /** A description of the substance - its appearance, handling requirements, and other usage notes. */
  description?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  _implicitRules?: Maybe<Element>;
  _language?: Maybe<Element>;
  text?: Maybe<Narrative>;
  contained: Array<ResourceList>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier: Array<Identifier>;
  _status?: Maybe<Element>;
  category: Array<CodeableConcept>;
  code: CodeableConcept;
  _description?: Maybe<Element>;
  instance: Array<SubstanceInstance>;
  ingredient: Array<SubstanceIngredient>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceMetaArgs = {
  where?: Maybe<MetaWhere>;
  options?: Maybe<MetaOptions>;
};

/** A homogeneous material with a definite composition. */
export type Substance_ImplicitRulesArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A homogeneous material with a definite composition. */
export type Substance_LanguageArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceTextArgs = {
  where?: Maybe<NarrativeWhere>;
  options?: Maybe<NarrativeOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceContainedArgs = {
  options?: Maybe<QueryOptions>;
  Account?: Maybe<AccountWhere>;
  Appointment?: Maybe<AppointmentWhere>;
  AppointmentResponse?: Maybe<AppointmentResponseWhere>;
  Coverage?: Maybe<CoverageWhere>;
  Device?: Maybe<DeviceWhere>;
  DeviceDefinition?: Maybe<DeviceDefinitionWhere>;
  DeviceMetric?: Maybe<DeviceMetricWhere>;
  Encounter?: Maybe<EncounterWhere>;
  Endpoint?: Maybe<EndpointWhere>;
  EpisodeOfCare?: Maybe<EpisodeOfCareWhere>;
  HealthcareService?: Maybe<HealthcareServiceWhere>;
  Location?: Maybe<LocationWhere>;
  Organization?: Maybe<OrganizationWhere>;
  Patient?: Maybe<PatientWhere>;
  Person?: Maybe<PersonWhere>;
  Practitioner?: Maybe<PractitionerWhere>;
  PractitionerRole?: Maybe<PractitionerRoleWhere>;
  RelatedPerson?: Maybe<RelatedPersonWhere>;
  Schedule?: Maybe<ScheduleWhere>;
  Slot?: Maybe<SlotWhere>;
  Substance?: Maybe<SubstanceWhere>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A homogeneous material with a definite composition. */
export type Substance_StatusArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceCategoryArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A homogeneous material with a definite composition. */
export type Substance_DescriptionArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceInstanceArgs = {
  where?: Maybe<SubstanceInstanceWhere>;
  options?: Maybe<SubstanceInstanceOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceIngredientArgs = {
  where?: Maybe<SubstanceIngredientWhere>;
  options?: Maybe<SubstanceIngredientOptions>;
};

export type SubstanceCategoryDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type SubstanceCategoryFieldInput = {
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
};

export type SubstanceCategoryUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  disconnect?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  create?: Maybe<Array<CodeableConceptCreateInput>>;
  delete?: Maybe<Array<CodeableConceptDeleteFieldInput>>;
};

export type SubstanceCodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type SubstanceCodeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type SubstanceCodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type SubstanceConnectFieldInput = {
  where?: Maybe<SubstanceWhere>;
  connect?: Maybe<SubstanceConnectInput>;
};

export type SubstanceConnectInput = {
  meta?: Maybe<MetaConnectFieldInput>;
  _implicitRules?: Maybe<ElementConnectFieldInput>;
  _language?: Maybe<ElementConnectFieldInput>;
  text?: Maybe<NarrativeConnectFieldInput>;
  contained_Account?: Maybe<Array<AccountConnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentConnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageConnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceConnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterConnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointConnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationConnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationConnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientConnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonConnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerConnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleConnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotConnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceConnectFieldInput>>;
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierConnectFieldInput>>;
  _status?: Maybe<ElementConnectFieldInput>;
  category?: Maybe<Array<CodeableConceptConnectFieldInput>>;
  code?: Maybe<CodeableConceptConnectFieldInput>;
  _description?: Maybe<ElementConnectFieldInput>;
  instance?: Maybe<Array<SubstanceInstanceConnectFieldInput>>;
  ingredient?: Maybe<Array<SubstanceIngredientConnectFieldInput>>;
};

export type SubstanceContainedAccountDeleteFieldInput = {
  where?: Maybe<AccountWhere>;
  delete?: Maybe<AccountDeleteInput>;
};

export type SubstanceContainedAccountFieldInput = {
  create?: Maybe<Array<AccountCreateInput>>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
};

export type SubstanceContainedAccountUpdateFieldInput = {
  where?: Maybe<AccountWhere>;
  update?: Maybe<AccountUpdateInput>;
  connect?: Maybe<Array<AccountConnectFieldInput>>;
  disconnect?: Maybe<Array<AccountDisconnectFieldInput>>;
  create?: Maybe<Array<AccountCreateInput>>;
  delete?: Maybe<Array<AccountDeleteFieldInput>>;
};

export type SubstanceContainedAppointmentDeleteFieldInput = {
  where?: Maybe<AppointmentWhere>;
  delete?: Maybe<AppointmentDeleteInput>;
};

export type SubstanceContainedAppointmentFieldInput = {
  create?: Maybe<Array<AppointmentCreateInput>>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
};

export type SubstanceContainedAppointmentResponseDeleteFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  delete?: Maybe<AppointmentResponseDeleteInput>;
};

export type SubstanceContainedAppointmentResponseFieldInput = {
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
};

export type SubstanceContainedAppointmentResponseUpdateFieldInput = {
  where?: Maybe<AppointmentResponseWhere>;
  update?: Maybe<AppointmentResponseUpdateInput>;
  connect?: Maybe<Array<AppointmentResponseConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentResponseCreateInput>>;
  delete?: Maybe<Array<AppointmentResponseDeleteFieldInput>>;
};

export type SubstanceContainedAppointmentUpdateFieldInput = {
  where?: Maybe<AppointmentWhere>;
  update?: Maybe<AppointmentUpdateInput>;
  connect?: Maybe<Array<AppointmentConnectFieldInput>>;
  disconnect?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  create?: Maybe<Array<AppointmentCreateInput>>;
  delete?: Maybe<Array<AppointmentDeleteFieldInput>>;
};

export type SubstanceContainedCoverageDeleteFieldInput = {
  where?: Maybe<CoverageWhere>;
  delete?: Maybe<CoverageDeleteInput>;
};

export type SubstanceContainedCoverageFieldInput = {
  create?: Maybe<Array<CoverageCreateInput>>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
};

export type SubstanceContainedCoverageUpdateFieldInput = {
  where?: Maybe<CoverageWhere>;
  update?: Maybe<CoverageUpdateInput>;
  connect?: Maybe<Array<CoverageConnectFieldInput>>;
  disconnect?: Maybe<Array<CoverageDisconnectFieldInput>>;
  create?: Maybe<Array<CoverageCreateInput>>;
  delete?: Maybe<Array<CoverageDeleteFieldInput>>;
};

export type SubstanceContainedDeviceDefinitionDeleteFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  delete?: Maybe<DeviceDefinitionDeleteInput>;
};

export type SubstanceContainedDeviceDefinitionFieldInput = {
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
};

export type SubstanceContainedDeviceDefinitionUpdateFieldInput = {
  where?: Maybe<DeviceDefinitionWhere>;
  update?: Maybe<DeviceDefinitionUpdateInput>;
  connect?: Maybe<Array<DeviceDefinitionConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceDefinitionCreateInput>>;
  delete?: Maybe<Array<DeviceDefinitionDeleteFieldInput>>;
};

export type SubstanceContainedDeviceDeleteFieldInput = {
  where?: Maybe<DeviceWhere>;
  delete?: Maybe<DeviceDeleteInput>;
};

export type SubstanceContainedDeviceFieldInput = {
  create?: Maybe<Array<DeviceCreateInput>>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
};

export type SubstanceContainedDeviceMetricDeleteFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  delete?: Maybe<DeviceMetricDeleteInput>;
};

export type SubstanceContainedDeviceMetricFieldInput = {
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
};

export type SubstanceContainedDeviceMetricUpdateFieldInput = {
  where?: Maybe<DeviceMetricWhere>;
  update?: Maybe<DeviceMetricUpdateInput>;
  connect?: Maybe<Array<DeviceMetricConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceMetricCreateInput>>;
  delete?: Maybe<Array<DeviceMetricDeleteFieldInput>>;
};

export type SubstanceContainedDeviceUpdateFieldInput = {
  where?: Maybe<DeviceWhere>;
  update?: Maybe<DeviceUpdateInput>;
  connect?: Maybe<Array<DeviceConnectFieldInput>>;
  disconnect?: Maybe<Array<DeviceDisconnectFieldInput>>;
  create?: Maybe<Array<DeviceCreateInput>>;
  delete?: Maybe<Array<DeviceDeleteFieldInput>>;
};

export type SubstanceContainedEncounterDeleteFieldInput = {
  where?: Maybe<EncounterWhere>;
  delete?: Maybe<EncounterDeleteInput>;
};

export type SubstanceContainedEncounterFieldInput = {
  create?: Maybe<Array<EncounterCreateInput>>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
};

export type SubstanceContainedEncounterUpdateFieldInput = {
  where?: Maybe<EncounterWhere>;
  update?: Maybe<EncounterUpdateInput>;
  connect?: Maybe<Array<EncounterConnectFieldInput>>;
  disconnect?: Maybe<Array<EncounterDisconnectFieldInput>>;
  create?: Maybe<Array<EncounterCreateInput>>;
  delete?: Maybe<Array<EncounterDeleteFieldInput>>;
};

export type SubstanceContainedEndpointDeleteFieldInput = {
  where?: Maybe<EndpointWhere>;
  delete?: Maybe<EndpointDeleteInput>;
};

export type SubstanceContainedEndpointFieldInput = {
  create?: Maybe<Array<EndpointCreateInput>>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
};

export type SubstanceContainedEndpointUpdateFieldInput = {
  where?: Maybe<EndpointWhere>;
  update?: Maybe<EndpointUpdateInput>;
  connect?: Maybe<Array<EndpointConnectFieldInput>>;
  disconnect?: Maybe<Array<EndpointDisconnectFieldInput>>;
  create?: Maybe<Array<EndpointCreateInput>>;
  delete?: Maybe<Array<EndpointDeleteFieldInput>>;
};

export type SubstanceContainedEpisodeOfCareDeleteFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  delete?: Maybe<EpisodeOfCareDeleteInput>;
};

export type SubstanceContainedEpisodeOfCareFieldInput = {
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
};

export type SubstanceContainedEpisodeOfCareUpdateFieldInput = {
  where?: Maybe<EpisodeOfCareWhere>;
  update?: Maybe<EpisodeOfCareUpdateInput>;
  connect?: Maybe<Array<EpisodeOfCareConnectFieldInput>>;
  disconnect?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  create?: Maybe<Array<EpisodeOfCareCreateInput>>;
  delete?: Maybe<Array<EpisodeOfCareDeleteFieldInput>>;
};

export type SubstanceContainedHealthcareServiceDeleteFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  delete?: Maybe<HealthcareServiceDeleteInput>;
};

export type SubstanceContainedHealthcareServiceFieldInput = {
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
};

export type SubstanceContainedHealthcareServiceUpdateFieldInput = {
  where?: Maybe<HealthcareServiceWhere>;
  update?: Maybe<HealthcareServiceUpdateInput>;
  connect?: Maybe<Array<HealthcareServiceConnectFieldInput>>;
  disconnect?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  create?: Maybe<Array<HealthcareServiceCreateInput>>;
  delete?: Maybe<Array<HealthcareServiceDeleteFieldInput>>;
};

export type SubstanceContainedLocationDeleteFieldInput = {
  where?: Maybe<LocationWhere>;
  delete?: Maybe<LocationDeleteInput>;
};

export type SubstanceContainedLocationFieldInput = {
  create?: Maybe<Array<LocationCreateInput>>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
};

export type SubstanceContainedLocationUpdateFieldInput = {
  where?: Maybe<LocationWhere>;
  update?: Maybe<LocationUpdateInput>;
  connect?: Maybe<Array<LocationConnectFieldInput>>;
  disconnect?: Maybe<Array<LocationDisconnectFieldInput>>;
  create?: Maybe<Array<LocationCreateInput>>;
  delete?: Maybe<Array<LocationDeleteFieldInput>>;
};

export type SubstanceContainedOrganizationDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type SubstanceContainedOrganizationFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type SubstanceContainedOrganizationUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type SubstanceContainedPatientDeleteFieldInput = {
  where?: Maybe<PatientWhere>;
  delete?: Maybe<PatientDeleteInput>;
};

export type SubstanceContainedPatientFieldInput = {
  create?: Maybe<Array<PatientCreateInput>>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
};

export type SubstanceContainedPatientUpdateFieldInput = {
  where?: Maybe<PatientWhere>;
  update?: Maybe<PatientUpdateInput>;
  connect?: Maybe<Array<PatientConnectFieldInput>>;
  disconnect?: Maybe<Array<PatientDisconnectFieldInput>>;
  create?: Maybe<Array<PatientCreateInput>>;
  delete?: Maybe<Array<PatientDeleteFieldInput>>;
};

export type SubstanceContainedPersonDeleteFieldInput = {
  where?: Maybe<PersonWhere>;
  delete?: Maybe<PersonDeleteInput>;
};

export type SubstanceContainedPersonFieldInput = {
  create?: Maybe<Array<PersonCreateInput>>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
};

export type SubstanceContainedPersonUpdateFieldInput = {
  where?: Maybe<PersonWhere>;
  update?: Maybe<PersonUpdateInput>;
  connect?: Maybe<Array<PersonConnectFieldInput>>;
  disconnect?: Maybe<Array<PersonDisconnectFieldInput>>;
  create?: Maybe<Array<PersonCreateInput>>;
  delete?: Maybe<Array<PersonDeleteFieldInput>>;
};

export type SubstanceContainedPractitionerDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type SubstanceContainedPractitionerFieldInput = {
  create?: Maybe<Array<PractitionerCreateInput>>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
};

export type SubstanceContainedPractitionerRoleDeleteFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  delete?: Maybe<PractitionerRoleDeleteInput>;
};

export type SubstanceContainedPractitionerRoleFieldInput = {
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
};

export type SubstanceContainedPractitionerRoleUpdateFieldInput = {
  where?: Maybe<PractitionerRoleWhere>;
  update?: Maybe<PractitionerRoleUpdateInput>;
  connect?: Maybe<Array<PractitionerRoleConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerRoleCreateInput>>;
  delete?: Maybe<Array<PractitionerRoleDeleteFieldInput>>;
};

export type SubstanceContainedPractitionerUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<Array<PractitionerConnectFieldInput>>;
  disconnect?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  create?: Maybe<Array<PractitionerCreateInput>>;
  delete?: Maybe<Array<PractitionerDeleteFieldInput>>;
};

export type SubstanceContainedRelatedPersonDeleteFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  delete?: Maybe<RelatedPersonDeleteInput>;
};

export type SubstanceContainedRelatedPersonFieldInput = {
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
};

export type SubstanceContainedRelatedPersonUpdateFieldInput = {
  where?: Maybe<RelatedPersonWhere>;
  update?: Maybe<RelatedPersonUpdateInput>;
  connect?: Maybe<Array<RelatedPersonConnectFieldInput>>;
  disconnect?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  create?: Maybe<Array<RelatedPersonCreateInput>>;
  delete?: Maybe<Array<RelatedPersonDeleteFieldInput>>;
};

export type SubstanceContainedScheduleDeleteFieldInput = {
  where?: Maybe<ScheduleWhere>;
  delete?: Maybe<ScheduleDeleteInput>;
};

export type SubstanceContainedScheduleFieldInput = {
  create?: Maybe<Array<ScheduleCreateInput>>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
};

export type SubstanceContainedScheduleUpdateFieldInput = {
  where?: Maybe<ScheduleWhere>;
  update?: Maybe<ScheduleUpdateInput>;
  connect?: Maybe<Array<ScheduleConnectFieldInput>>;
  disconnect?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  create?: Maybe<Array<ScheduleCreateInput>>;
  delete?: Maybe<Array<ScheduleDeleteFieldInput>>;
};

export type SubstanceContainedSlotDeleteFieldInput = {
  where?: Maybe<SlotWhere>;
  delete?: Maybe<SlotDeleteInput>;
};

export type SubstanceContainedSlotFieldInput = {
  create?: Maybe<Array<SlotCreateInput>>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
};

export type SubstanceContainedSlotUpdateFieldInput = {
  where?: Maybe<SlotWhere>;
  update?: Maybe<SlotUpdateInput>;
  connect?: Maybe<Array<SlotConnectFieldInput>>;
  disconnect?: Maybe<Array<SlotDisconnectFieldInput>>;
  create?: Maybe<Array<SlotCreateInput>>;
  delete?: Maybe<Array<SlotDeleteFieldInput>>;
};

export type SubstanceContainedSubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type SubstanceContainedSubstanceFieldInput = {
  create?: Maybe<Array<SubstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
};

export type SubstanceContainedSubstanceUpdateFieldInput = {
  where?: Maybe<SubstanceWhere>;
  update?: Maybe<SubstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceDeleteFieldInput>>;
};

export type SubstanceCreateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  description?: Maybe<Scalars['String']>;
  resourceType: SubstanceResourceType;
  status?: Maybe<SubstanceStatus>;
  meta?: Maybe<SubstanceMetaFieldInput>;
  _implicitRules?: Maybe<Substance_ImplicitrulesFieldInput>;
  _language?: Maybe<Substance_LanguageFieldInput>;
  text?: Maybe<SubstanceTextFieldInput>;
  contained_Account?: Maybe<SubstanceContainedAccountFieldInput>;
  contained_Appointment?: Maybe<SubstanceContainedAppointmentFieldInput>;
  contained_AppointmentResponse?: Maybe<SubstanceContainedAppointmentResponseFieldInput>;
  contained_Coverage?: Maybe<SubstanceContainedCoverageFieldInput>;
  contained_Device?: Maybe<SubstanceContainedDeviceFieldInput>;
  contained_DeviceDefinition?: Maybe<SubstanceContainedDeviceDefinitionFieldInput>;
  contained_DeviceMetric?: Maybe<SubstanceContainedDeviceMetricFieldInput>;
  contained_Encounter?: Maybe<SubstanceContainedEncounterFieldInput>;
  contained_Endpoint?: Maybe<SubstanceContainedEndpointFieldInput>;
  contained_EpisodeOfCare?: Maybe<SubstanceContainedEpisodeOfCareFieldInput>;
  contained_HealthcareService?: Maybe<SubstanceContainedHealthcareServiceFieldInput>;
  contained_Location?: Maybe<SubstanceContainedLocationFieldInput>;
  contained_Organization?: Maybe<SubstanceContainedOrganizationFieldInput>;
  contained_Patient?: Maybe<SubstanceContainedPatientFieldInput>;
  contained_Person?: Maybe<SubstanceContainedPersonFieldInput>;
  contained_Practitioner?: Maybe<SubstanceContainedPractitionerFieldInput>;
  contained_PractitionerRole?: Maybe<SubstanceContainedPractitionerRoleFieldInput>;
  contained_RelatedPerson?: Maybe<SubstanceContainedRelatedPersonFieldInput>;
  contained_Schedule?: Maybe<SubstanceContainedScheduleFieldInput>;
  contained_Slot?: Maybe<SubstanceContainedSlotFieldInput>;
  contained_Substance?: Maybe<SubstanceContainedSubstanceFieldInput>;
  extension?: Maybe<SubstanceExtensionFieldInput>;
  modifierExtension?: Maybe<SubstanceModifierextensionFieldInput>;
  identifier?: Maybe<SubstanceIdentifierFieldInput>;
  _status?: Maybe<Substance_StatusFieldInput>;
  category?: Maybe<SubstanceCategoryFieldInput>;
  code?: Maybe<SubstanceCodeFieldInput>;
  _description?: Maybe<Substance_DescriptionFieldInput>;
  instance?: Maybe<SubstanceInstanceFieldInput>;
  ingredient?: Maybe<SubstanceIngredientFieldInput>;
};

export type SubstanceDeleteFieldInput = {
  where?: Maybe<SubstanceWhere>;
  delete?: Maybe<SubstanceDeleteInput>;
};

export type SubstanceDeleteInput = {
  meta?: Maybe<SubstanceMetaDeleteFieldInput>;
  _implicitRules?: Maybe<Substance_ImplicitrulesDeleteFieldInput>;
  _language?: Maybe<Substance_LanguageDeleteFieldInput>;
  text?: Maybe<SubstanceTextDeleteFieldInput>;
  contained_Account?: Maybe<Array<SubstanceContainedAccountDeleteFieldInput>>;
  contained_Appointment?: Maybe<Array<SubstanceContainedAppointmentDeleteFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<SubstanceContainedAppointmentResponseDeleteFieldInput>>;
  contained_Coverage?: Maybe<Array<SubstanceContainedCoverageDeleteFieldInput>>;
  contained_Device?: Maybe<Array<SubstanceContainedDeviceDeleteFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<SubstanceContainedDeviceDefinitionDeleteFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<SubstanceContainedDeviceMetricDeleteFieldInput>>;
  contained_Encounter?: Maybe<Array<SubstanceContainedEncounterDeleteFieldInput>>;
  contained_Endpoint?: Maybe<Array<SubstanceContainedEndpointDeleteFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<SubstanceContainedEpisodeOfCareDeleteFieldInput>>;
  contained_HealthcareService?: Maybe<Array<SubstanceContainedHealthcareServiceDeleteFieldInput>>;
  contained_Location?: Maybe<Array<SubstanceContainedLocationDeleteFieldInput>>;
  contained_Organization?: Maybe<Array<SubstanceContainedOrganizationDeleteFieldInput>>;
  contained_Patient?: Maybe<Array<SubstanceContainedPatientDeleteFieldInput>>;
  contained_Person?: Maybe<Array<SubstanceContainedPersonDeleteFieldInput>>;
  contained_Practitioner?: Maybe<Array<SubstanceContainedPractitionerDeleteFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<SubstanceContainedPractitionerRoleDeleteFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<SubstanceContainedRelatedPersonDeleteFieldInput>>;
  contained_Schedule?: Maybe<Array<SubstanceContainedScheduleDeleteFieldInput>>;
  contained_Slot?: Maybe<Array<SubstanceContainedSlotDeleteFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceContainedSubstanceDeleteFieldInput>>;
  extension?: Maybe<Array<SubstanceExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<SubstanceModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<Array<SubstanceIdentifierDeleteFieldInput>>;
  _status?: Maybe<Substance_StatusDeleteFieldInput>;
  category?: Maybe<Array<SubstanceCategoryDeleteFieldInput>>;
  code?: Maybe<SubstanceCodeDeleteFieldInput>;
  _description?: Maybe<Substance_DescriptionDeleteFieldInput>;
  instance?: Maybe<Array<SubstanceInstanceDeleteFieldInput>>;
  ingredient?: Maybe<Array<SubstanceIngredientDeleteFieldInput>>;
};

export type SubstanceDisconnectFieldInput = {
  where?: Maybe<SubstanceWhere>;
  disconnect?: Maybe<SubstanceDisconnectInput>;
};

export type SubstanceDisconnectInput = {
  meta?: Maybe<MetaDisconnectFieldInput>;
  _implicitRules?: Maybe<ElementDisconnectFieldInput>;
  _language?: Maybe<ElementDisconnectFieldInput>;
  text?: Maybe<NarrativeDisconnectFieldInput>;
  contained_Account?: Maybe<Array<AccountDisconnectFieldInput>>;
  contained_Appointment?: Maybe<Array<AppointmentDisconnectFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseDisconnectFieldInput>>;
  contained_Coverage?: Maybe<Array<CoverageDisconnectFieldInput>>;
  contained_Device?: Maybe<Array<DeviceDisconnectFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionDisconnectFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricDisconnectFieldInput>>;
  contained_Encounter?: Maybe<Array<EncounterDisconnectFieldInput>>;
  contained_Endpoint?: Maybe<Array<EndpointDisconnectFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareDisconnectFieldInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceDisconnectFieldInput>>;
  contained_Location?: Maybe<Array<LocationDisconnectFieldInput>>;
  contained_Organization?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  contained_Patient?: Maybe<Array<PatientDisconnectFieldInput>>;
  contained_Person?: Maybe<Array<PersonDisconnectFieldInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerDisconnectFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleDisconnectFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonDisconnectFieldInput>>;
  contained_Schedule?: Maybe<Array<ScheduleDisconnectFieldInput>>;
  contained_Slot?: Maybe<Array<SlotDisconnectFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceDisconnectFieldInput>>;
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  _status?: Maybe<ElementDisconnectFieldInput>;
  category?: Maybe<Array<CodeableConceptDisconnectFieldInput>>;
  code?: Maybe<CodeableConceptDisconnectFieldInput>;
  _description?: Maybe<ElementDisconnectFieldInput>;
  instance?: Maybe<Array<SubstanceInstanceDisconnectFieldInput>>;
  ingredient?: Maybe<Array<SubstanceIngredientDisconnectFieldInput>>;
};

export type SubstanceExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SubstanceExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SubstanceExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SubstanceIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type SubstanceIdentifierFieldInput = {
  create?: Maybe<Array<IdentifierCreateInput>>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
};

export type SubstanceIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<Array<IdentifierConnectFieldInput>>;
  disconnect?: Maybe<Array<IdentifierDisconnectFieldInput>>;
  create?: Maybe<Array<IdentifierCreateInput>>;
  delete?: Maybe<Array<IdentifierDeleteFieldInput>>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceIngredient = {
  __typename?: 'SubstanceIngredient';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  quantity?: Maybe<Ratio>;
  substanceCodeableConcept?: Maybe<CodeableConcept>;
  substanceReference?: Maybe<Reference>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceIngredientExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceIngredientModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceIngredientQuantityArgs = {
  where?: Maybe<RatioWhere>;
  options?: Maybe<RatioOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceIngredientSubstanceCodeableConceptArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceIngredientSubstanceReferenceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type SubstanceIngredientConnectFieldInput = {
  where?: Maybe<SubstanceIngredientWhere>;
  connect?: Maybe<SubstanceIngredientConnectInput>;
};

export type SubstanceIngredientConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  quantity?: Maybe<RatioConnectFieldInput>;
  substanceCodeableConcept?: Maybe<CodeableConceptConnectFieldInput>;
  substanceReference?: Maybe<ReferenceConnectFieldInput>;
};

export type SubstanceIngredientCreateInput = {
  extension?: Maybe<SubstanceIngredientExtensionFieldInput>;
  modifierExtension?: Maybe<SubstanceIngredientModifierextensionFieldInput>;
  quantity?: Maybe<SubstanceIngredientQuantityFieldInput>;
  substanceCodeableConcept?: Maybe<SubstanceIngredientSubstancecodeableconceptFieldInput>;
  substanceReference?: Maybe<SubstanceIngredientSubstancereferenceFieldInput>;
};

export type SubstanceIngredientDeleteFieldInput = {
  where?: Maybe<SubstanceIngredientWhere>;
  delete?: Maybe<SubstanceIngredientDeleteInput>;
};

export type SubstanceIngredientDeleteInput = {
  extension?: Maybe<Array<SubstanceIngredientExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<SubstanceIngredientModifierextensionDeleteFieldInput>>;
  quantity?: Maybe<SubstanceIngredientQuantityDeleteFieldInput>;
  substanceCodeableConcept?: Maybe<SubstanceIngredientSubstancecodeableconceptDeleteFieldInput>;
  substanceReference?: Maybe<SubstanceIngredientSubstancereferenceDeleteFieldInput>;
};

export type SubstanceIngredientDisconnectFieldInput = {
  where?: Maybe<SubstanceIngredientWhere>;
  disconnect?: Maybe<SubstanceIngredientDisconnectInput>;
};

export type SubstanceIngredientDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  quantity?: Maybe<RatioDisconnectFieldInput>;
  substanceCodeableConcept?: Maybe<CodeableConceptDisconnectFieldInput>;
  substanceReference?: Maybe<ReferenceDisconnectFieldInput>;
};

export type SubstanceIngredientExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SubstanceIngredientExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SubstanceIngredientExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SubstanceIngredientFieldInput = {
  create?: Maybe<Array<SubstanceIngredientCreateInput>>;
  connect?: Maybe<Array<SubstanceIngredientConnectFieldInput>>;
};

export type SubstanceIngredientModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SubstanceIngredientModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SubstanceIngredientModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SubstanceIngredientOptions = {
  /** Specify one or more SubstanceIngredientSort objects to sort SubstanceIngredients by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<SubstanceIngredientSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SubstanceIngredientQuantityDeleteFieldInput = {
  where?: Maybe<RatioWhere>;
  delete?: Maybe<RatioDeleteInput>;
};

export type SubstanceIngredientQuantityFieldInput = {
  create?: Maybe<RatioCreateInput>;
  connect?: Maybe<RatioConnectFieldInput>;
};

export type SubstanceIngredientQuantityUpdateFieldInput = {
  where?: Maybe<RatioWhere>;
  update?: Maybe<RatioUpdateInput>;
  connect?: Maybe<RatioConnectFieldInput>;
  disconnect?: Maybe<RatioDisconnectFieldInput>;
  create?: Maybe<RatioCreateInput>;
  delete?: Maybe<RatioDeleteFieldInput>;
};

export type SubstanceIngredientRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  quantity?: Maybe<RatioCreateInput>;
  substanceCodeableConcept?: Maybe<CodeableConceptCreateInput>;
  substanceReference?: Maybe<ReferenceCreateInput>;
};

/** Fields to sort SubstanceIngredients by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubstanceIngredientSort object. */
export type SubstanceIngredientSort = {
  id?: Maybe<SortDirection>;
};

export type SubstanceIngredientSubstancecodeableconceptDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type SubstanceIngredientSubstancecodeableconceptFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type SubstanceIngredientSubstancecodeableconceptUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type SubstanceIngredientSubstancereferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type SubstanceIngredientSubstancereferenceFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type SubstanceIngredientSubstancereferenceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type SubstanceIngredientUpdateFieldInput = {
  where?: Maybe<SubstanceIngredientWhere>;
  update?: Maybe<SubstanceIngredientUpdateInput>;
  connect?: Maybe<Array<SubstanceIngredientConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceIngredientDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceIngredientCreateInput>>;
  delete?: Maybe<Array<SubstanceIngredientDeleteFieldInput>>;
};

export type SubstanceIngredientUpdateInput = {
  extension?: Maybe<Array<SubstanceIngredientExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<SubstanceIngredientModifierextensionUpdateFieldInput>>;
  quantity?: Maybe<SubstanceIngredientQuantityUpdateFieldInput>;
  substanceCodeableConcept?: Maybe<SubstanceIngredientSubstancecodeableconceptUpdateFieldInput>;
  substanceReference?: Maybe<SubstanceIngredientSubstancereferenceUpdateFieldInput>;
};

export type SubstanceIngredientWhere = {
  OR?: Maybe<Array<SubstanceIngredientWhere>>;
  AND?: Maybe<Array<SubstanceIngredientWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  quantity?: Maybe<RatioWhere>;
  quantity_NOT?: Maybe<RatioWhere>;
  quantity_IN?: Maybe<Array<RatioWhere>>;
  quantity_NOT_IN?: Maybe<Array<RatioWhere>>;
  substanceCodeableConcept?: Maybe<CodeableConceptWhere>;
  substanceCodeableConcept_NOT?: Maybe<CodeableConceptWhere>;
  substanceCodeableConcept_IN?: Maybe<Array<CodeableConceptWhere>>;
  substanceCodeableConcept_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  substanceReference?: Maybe<ReferenceWhere>;
  substanceReference_NOT?: Maybe<ReferenceWhere>;
  substanceReference_IN?: Maybe<Array<ReferenceWhere>>;
  substanceReference_NOT_IN?: Maybe<Array<ReferenceWhere>>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceInstance = {
  __typename?: 'SubstanceInstance';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** When the substance is no longer valid to use. For some substances, a single arbitrary date is used for expiry. */
  expiry?: Maybe<Scalars['DateTime']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  identifier?: Maybe<Identifier>;
  _expiry?: Maybe<Element>;
  quantity?: Maybe<Quantity>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceInstanceExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceInstanceModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceInstanceIdentifierArgs = {
  where?: Maybe<IdentifierWhere>;
  options?: Maybe<IdentifierOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceInstance_ExpiryArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A homogeneous material with a definite composition. */
export type SubstanceInstanceQuantityArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

export type SubstanceInstanceConnectFieldInput = {
  where?: Maybe<SubstanceInstanceWhere>;
  connect?: Maybe<SubstanceInstanceConnectInput>;
};

export type SubstanceInstanceConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  identifier?: Maybe<IdentifierConnectFieldInput>;
  _expiry?: Maybe<ElementConnectFieldInput>;
  quantity?: Maybe<QuantityConnectFieldInput>;
};

export type SubstanceInstanceCreateInput = {
  expiry?: Maybe<Scalars['DateTime']>;
  extension?: Maybe<SubstanceInstanceExtensionFieldInput>;
  modifierExtension?: Maybe<SubstanceInstanceModifierextensionFieldInput>;
  identifier?: Maybe<SubstanceInstanceIdentifierFieldInput>;
  _expiry?: Maybe<SubstanceInstance_ExpiryFieldInput>;
  quantity?: Maybe<SubstanceInstanceQuantityFieldInput>;
};

export type SubstanceInstanceDeleteFieldInput = {
  where?: Maybe<SubstanceInstanceWhere>;
  delete?: Maybe<SubstanceInstanceDeleteInput>;
};

export type SubstanceInstanceDeleteInput = {
  extension?: Maybe<Array<SubstanceInstanceExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<SubstanceInstanceModifierextensionDeleteFieldInput>>;
  identifier?: Maybe<SubstanceInstanceIdentifierDeleteFieldInput>;
  _expiry?: Maybe<SubstanceInstance_ExpiryDeleteFieldInput>;
  quantity?: Maybe<SubstanceInstanceQuantityDeleteFieldInput>;
};

export type SubstanceInstanceDisconnectFieldInput = {
  where?: Maybe<SubstanceInstanceWhere>;
  disconnect?: Maybe<SubstanceInstanceDisconnectInput>;
};

export type SubstanceInstanceDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  identifier?: Maybe<IdentifierDisconnectFieldInput>;
  _expiry?: Maybe<ElementDisconnectFieldInput>;
  quantity?: Maybe<QuantityDisconnectFieldInput>;
};

export type SubstanceInstanceExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SubstanceInstanceExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SubstanceInstanceExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SubstanceInstanceFieldInput = {
  create?: Maybe<Array<SubstanceInstanceCreateInput>>;
  connect?: Maybe<Array<SubstanceInstanceConnectFieldInput>>;
};

export type SubstanceInstanceIdentifierDeleteFieldInput = {
  where?: Maybe<IdentifierWhere>;
  delete?: Maybe<IdentifierDeleteInput>;
};

export type SubstanceInstanceIdentifierFieldInput = {
  create?: Maybe<IdentifierCreateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
};

export type SubstanceInstanceIdentifierUpdateFieldInput = {
  where?: Maybe<IdentifierWhere>;
  update?: Maybe<IdentifierUpdateInput>;
  connect?: Maybe<IdentifierConnectFieldInput>;
  disconnect?: Maybe<IdentifierDisconnectFieldInput>;
  create?: Maybe<IdentifierCreateInput>;
  delete?: Maybe<IdentifierDeleteFieldInput>;
};

export type SubstanceInstanceModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SubstanceInstanceModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SubstanceInstanceModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SubstanceInstanceOptions = {
  /** Specify one or more SubstanceInstanceSort objects to sort SubstanceInstances by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<SubstanceInstanceSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SubstanceInstanceQuantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type SubstanceInstanceQuantityFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type SubstanceInstanceQuantityUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type SubstanceInstanceRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<IdentifierCreateInput>;
  _expiry?: Maybe<ElementCreateInput>;
  quantity?: Maybe<QuantityCreateInput>;
};

/** Fields to sort SubstanceInstances by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubstanceInstanceSort object. */
export type SubstanceInstanceSort = {
  id?: Maybe<SortDirection>;
  expiry?: Maybe<SortDirection>;
};

export type SubstanceInstanceUpdateFieldInput = {
  where?: Maybe<SubstanceInstanceWhere>;
  update?: Maybe<SubstanceInstanceUpdateInput>;
  connect?: Maybe<Array<SubstanceInstanceConnectFieldInput>>;
  disconnect?: Maybe<Array<SubstanceInstanceDisconnectFieldInput>>;
  create?: Maybe<Array<SubstanceInstanceCreateInput>>;
  delete?: Maybe<Array<SubstanceInstanceDeleteFieldInput>>;
};

export type SubstanceInstanceUpdateInput = {
  expiry?: Maybe<Scalars['DateTime']>;
  extension?: Maybe<Array<SubstanceInstanceExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<SubstanceInstanceModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<SubstanceInstanceIdentifierUpdateFieldInput>;
  _expiry?: Maybe<SubstanceInstance_ExpiryUpdateFieldInput>;
  quantity?: Maybe<SubstanceInstanceQuantityUpdateFieldInput>;
};

export type SubstanceInstanceWhere = {
  OR?: Maybe<Array<SubstanceInstanceWhere>>;
  AND?: Maybe<Array<SubstanceInstanceWhere>>;
  expiry?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  identifier_IN?: Maybe<Array<IdentifierWhere>>;
  identifier_NOT_IN?: Maybe<Array<IdentifierWhere>>;
  _expiry?: Maybe<ElementWhere>;
  _expiry_NOT?: Maybe<ElementWhere>;
  _expiry_IN?: Maybe<Array<ElementWhere>>;
  _expiry_NOT_IN?: Maybe<Array<ElementWhere>>;
  quantity?: Maybe<QuantityWhere>;
  quantity_NOT?: Maybe<QuantityWhere>;
  quantity_IN?: Maybe<Array<QuantityWhere>>;
  quantity_NOT_IN?: Maybe<Array<QuantityWhere>>;
};

export type SubstanceInstance_ExpiryDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type SubstanceInstance_ExpiryFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type SubstanceInstance_ExpiryUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type SubstanceMetaDeleteFieldInput = {
  where?: Maybe<MetaWhere>;
  delete?: Maybe<MetaDeleteInput>;
};

export type SubstanceMetaFieldInput = {
  create?: Maybe<MetaCreateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
};

export type SubstanceMetaUpdateFieldInput = {
  where?: Maybe<MetaWhere>;
  update?: Maybe<MetaUpdateInput>;
  connect?: Maybe<MetaConnectFieldInput>;
  disconnect?: Maybe<MetaDisconnectFieldInput>;
  create?: Maybe<MetaCreateInput>;
  delete?: Maybe<MetaDeleteFieldInput>;
};

export type SubstanceModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type SubstanceModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type SubstanceModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type SubstanceOptions = {
  /** Specify one or more SubstanceSort objects to sort Substances by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<SubstanceSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SubstanceRelationInput = {
  meta?: Maybe<MetaCreateInput>;
  _implicitRules?: Maybe<ElementCreateInput>;
  _language?: Maybe<ElementCreateInput>;
  text?: Maybe<NarrativeCreateInput>;
  contained_Account?: Maybe<Array<AccountCreateInput>>;
  contained_Appointment?: Maybe<Array<AppointmentCreateInput>>;
  contained_AppointmentResponse?: Maybe<Array<AppointmentResponseCreateInput>>;
  contained_Coverage?: Maybe<Array<CoverageCreateInput>>;
  contained_Device?: Maybe<Array<DeviceCreateInput>>;
  contained_DeviceDefinition?: Maybe<Array<DeviceDefinitionCreateInput>>;
  contained_DeviceMetric?: Maybe<Array<DeviceMetricCreateInput>>;
  contained_Encounter?: Maybe<Array<EncounterCreateInput>>;
  contained_Endpoint?: Maybe<Array<EndpointCreateInput>>;
  contained_EpisodeOfCare?: Maybe<Array<EpisodeOfCareCreateInput>>;
  contained_HealthcareService?: Maybe<Array<HealthcareServiceCreateInput>>;
  contained_Location?: Maybe<Array<LocationCreateInput>>;
  contained_Organization?: Maybe<Array<OrganizationCreateInput>>;
  contained_Patient?: Maybe<Array<PatientCreateInput>>;
  contained_Person?: Maybe<Array<PersonCreateInput>>;
  contained_Practitioner?: Maybe<Array<PractitionerCreateInput>>;
  contained_PractitionerRole?: Maybe<Array<PractitionerRoleCreateInput>>;
  contained_RelatedPerson?: Maybe<Array<RelatedPersonCreateInput>>;
  contained_Schedule?: Maybe<Array<ScheduleCreateInput>>;
  contained_Slot?: Maybe<Array<SlotCreateInput>>;
  contained_Substance?: Maybe<Array<SubstanceCreateInput>>;
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  identifier?: Maybe<Array<IdentifierCreateInput>>;
  _status?: Maybe<ElementCreateInput>;
  category?: Maybe<Array<CodeableConceptCreateInput>>;
  code?: Maybe<CodeableConceptCreateInput>;
  _description?: Maybe<ElementCreateInput>;
  instance?: Maybe<Array<SubstanceInstanceCreateInput>>;
  ingredient?: Maybe<Array<SubstanceIngredientCreateInput>>;
};

export enum SubstanceResourceType {
  Substance = 'Substance',
}

/** Fields to sort Substances by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubstanceSort object. */
export type SubstanceSort = {
  id?: Maybe<SortDirection>;
  resourceType?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  implicitRules?: Maybe<SortDirection>;
  language?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
};

/** A code to indicate if the substance is actively used. */
export enum SubstanceStatus {
  Active = 'active',
  Inactive = 'inactive',
  EnteredInError = 'enteredInError',
}

export type SubstanceTextDeleteFieldInput = {
  where?: Maybe<NarrativeWhere>;
  delete?: Maybe<NarrativeDeleteInput>;
};

export type SubstanceTextFieldInput = {
  create?: Maybe<NarrativeCreateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
};

export type SubstanceTextUpdateFieldInput = {
  where?: Maybe<NarrativeWhere>;
  update?: Maybe<NarrativeUpdateInput>;
  connect?: Maybe<NarrativeConnectFieldInput>;
  disconnect?: Maybe<NarrativeDisconnectFieldInput>;
  create?: Maybe<NarrativeCreateInput>;
  delete?: Maybe<NarrativeDeleteFieldInput>;
};

export type SubstanceUpdateInput = {
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  description?: Maybe<Scalars['String']>;
  resourceType?: Maybe<SubstanceResourceType>;
  status?: Maybe<SubstanceStatus>;
  meta?: Maybe<SubstanceMetaUpdateFieldInput>;
  _implicitRules?: Maybe<Substance_ImplicitrulesUpdateFieldInput>;
  _language?: Maybe<Substance_LanguageUpdateFieldInput>;
  text?: Maybe<SubstanceTextUpdateFieldInput>;
  contained_Account?: Maybe<Array<SubstanceContainedAccountUpdateFieldInput>>;
  contained_Appointment?: Maybe<Array<SubstanceContainedAppointmentUpdateFieldInput>>;
  contained_AppointmentResponse?: Maybe<Array<SubstanceContainedAppointmentResponseUpdateFieldInput>>;
  contained_Coverage?: Maybe<Array<SubstanceContainedCoverageUpdateFieldInput>>;
  contained_Device?: Maybe<Array<SubstanceContainedDeviceUpdateFieldInput>>;
  contained_DeviceDefinition?: Maybe<Array<SubstanceContainedDeviceDefinitionUpdateFieldInput>>;
  contained_DeviceMetric?: Maybe<Array<SubstanceContainedDeviceMetricUpdateFieldInput>>;
  contained_Encounter?: Maybe<Array<SubstanceContainedEncounterUpdateFieldInput>>;
  contained_Endpoint?: Maybe<Array<SubstanceContainedEndpointUpdateFieldInput>>;
  contained_EpisodeOfCare?: Maybe<Array<SubstanceContainedEpisodeOfCareUpdateFieldInput>>;
  contained_HealthcareService?: Maybe<Array<SubstanceContainedHealthcareServiceUpdateFieldInput>>;
  contained_Location?: Maybe<Array<SubstanceContainedLocationUpdateFieldInput>>;
  contained_Organization?: Maybe<Array<SubstanceContainedOrganizationUpdateFieldInput>>;
  contained_Patient?: Maybe<Array<SubstanceContainedPatientUpdateFieldInput>>;
  contained_Person?: Maybe<Array<SubstanceContainedPersonUpdateFieldInput>>;
  contained_Practitioner?: Maybe<Array<SubstanceContainedPractitionerUpdateFieldInput>>;
  contained_PractitionerRole?: Maybe<Array<SubstanceContainedPractitionerRoleUpdateFieldInput>>;
  contained_RelatedPerson?: Maybe<Array<SubstanceContainedRelatedPersonUpdateFieldInput>>;
  contained_Schedule?: Maybe<Array<SubstanceContainedScheduleUpdateFieldInput>>;
  contained_Slot?: Maybe<Array<SubstanceContainedSlotUpdateFieldInput>>;
  contained_Substance?: Maybe<Array<SubstanceContainedSubstanceUpdateFieldInput>>;
  extension?: Maybe<Array<SubstanceExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<SubstanceModifierextensionUpdateFieldInput>>;
  identifier?: Maybe<Array<SubstanceIdentifierUpdateFieldInput>>;
  _status?: Maybe<Substance_StatusUpdateFieldInput>;
  category?: Maybe<Array<SubstanceCategoryUpdateFieldInput>>;
  code?: Maybe<SubstanceCodeUpdateFieldInput>;
  _description?: Maybe<Substance_DescriptionUpdateFieldInput>;
  instance?: Maybe<Array<SubstanceInstanceUpdateFieldInput>>;
  ingredient?: Maybe<Array<SubstanceIngredientUpdateFieldInput>>;
};

export type SubstanceWhere = {
  OR?: Maybe<Array<SubstanceWhere>>;
  AND?: Maybe<Array<SubstanceWhere>>;
  implicitRules?: Maybe<Scalars['Uri']>;
  language?: Maybe<Scalars['Code']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<SubstanceResourceType>;
  resourceType_NOT?: Maybe<SubstanceResourceType>;
  resourceType_IN?: Maybe<Array<Maybe<SubstanceResourceType>>>;
  resourceType_NOT_IN?: Maybe<Array<Maybe<SubstanceResourceType>>>;
  status?: Maybe<SubstanceStatus>;
  status_NOT?: Maybe<SubstanceStatus>;
  status_IN?: Maybe<Array<Maybe<SubstanceStatus>>>;
  status_NOT_IN?: Maybe<Array<Maybe<SubstanceStatus>>>;
  meta?: Maybe<MetaWhere>;
  meta_NOT?: Maybe<MetaWhere>;
  meta_IN?: Maybe<Array<MetaWhere>>;
  meta_NOT_IN?: Maybe<Array<MetaWhere>>;
  _implicitRules?: Maybe<ElementWhere>;
  _implicitRules_NOT?: Maybe<ElementWhere>;
  _implicitRules_IN?: Maybe<Array<ElementWhere>>;
  _implicitRules_NOT_IN?: Maybe<Array<ElementWhere>>;
  _language?: Maybe<ElementWhere>;
  _language_NOT?: Maybe<ElementWhere>;
  _language_IN?: Maybe<Array<ElementWhere>>;
  _language_NOT_IN?: Maybe<Array<ElementWhere>>;
  text?: Maybe<NarrativeWhere>;
  text_NOT?: Maybe<NarrativeWhere>;
  text_IN?: Maybe<Array<NarrativeWhere>>;
  text_NOT_IN?: Maybe<Array<NarrativeWhere>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  identifier?: Maybe<IdentifierWhere>;
  identifier_NOT?: Maybe<IdentifierWhere>;
  _status?: Maybe<ElementWhere>;
  _status_NOT?: Maybe<ElementWhere>;
  _status_IN?: Maybe<Array<ElementWhere>>;
  _status_NOT_IN?: Maybe<Array<ElementWhere>>;
  category?: Maybe<CodeableConceptWhere>;
  category_NOT?: Maybe<CodeableConceptWhere>;
  code?: Maybe<CodeableConceptWhere>;
  code_NOT?: Maybe<CodeableConceptWhere>;
  code_IN?: Maybe<Array<CodeableConceptWhere>>;
  code_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  _description?: Maybe<ElementWhere>;
  _description_NOT?: Maybe<ElementWhere>;
  _description_IN?: Maybe<Array<ElementWhere>>;
  _description_NOT_IN?: Maybe<Array<ElementWhere>>;
  instance?: Maybe<SubstanceInstanceWhere>;
  instance_NOT?: Maybe<SubstanceInstanceWhere>;
  ingredient?: Maybe<SubstanceIngredientWhere>;
  ingredient_NOT?: Maybe<SubstanceIngredientWhere>;
};

export type Substance_DescriptionDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Substance_DescriptionFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Substance_DescriptionUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Substance_ImplicitrulesDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Substance_ImplicitrulesFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Substance_ImplicitrulesUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Substance_LanguageDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Substance_LanguageFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Substance_LanguageUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type Substance_StatusDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Substance_StatusFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type Substance_StatusUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export enum TimestampOperation {
  Create = 'CREATE',
  Update = 'UPDATE',
}

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type Timing = {
  __typename?: 'Timing';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** Identifies specific times when the event occurs. */
  event: Array<Scalars['DateTime']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  _event: Array<Element>;
  repeat?: Maybe<TimingRepeat>;
  code?: Maybe<CodeableConcept>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type Timing_EventArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeatArgs = {
  where?: Maybe<TimingRepeatWhere>;
  options?: Maybe<TimingRepeatOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingCodeArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

export type TimingCodeDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type TimingCodeFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type TimingCodeUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type TimingConnectFieldInput = {
  where?: Maybe<TimingWhere>;
  connect?: Maybe<TimingConnectInput>;
};

export type TimingConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _event?: Maybe<Array<ElementConnectFieldInput>>;
  repeat?: Maybe<TimingRepeatConnectFieldInput>;
  code?: Maybe<CodeableConceptConnectFieldInput>;
};

export type TimingCreateInput = {
  event: Array<Scalars['DateTime']>;
  extension?: Maybe<TimingExtensionFieldInput>;
  modifierExtension?: Maybe<TimingModifierextensionFieldInput>;
  _event?: Maybe<Timing_EventFieldInput>;
  repeat?: Maybe<TimingRepeatFieldInput>;
  code?: Maybe<TimingCodeFieldInput>;
};

export type TimingDeleteFieldInput = {
  where?: Maybe<TimingWhere>;
  delete?: Maybe<TimingDeleteInput>;
};

export type TimingDeleteInput = {
  extension?: Maybe<Array<TimingExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<TimingModifierextensionDeleteFieldInput>>;
  _event?: Maybe<Array<Timing_EventDeleteFieldInput>>;
  repeat?: Maybe<TimingRepeatDeleteFieldInput>;
  code?: Maybe<TimingCodeDeleteFieldInput>;
};

export type TimingDisconnectFieldInput = {
  where?: Maybe<TimingWhere>;
  disconnect?: Maybe<TimingDisconnectInput>;
};

export type TimingDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _event?: Maybe<Array<ElementDisconnectFieldInput>>;
  repeat?: Maybe<TimingRepeatDisconnectFieldInput>;
  code?: Maybe<CodeableConceptDisconnectFieldInput>;
};

export type TimingExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type TimingExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type TimingExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type TimingModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type TimingModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type TimingModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type TimingOptions = {
  /** Specify one or more TimingSort objects to sort Timings by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<TimingSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type TimingRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  _event?: Maybe<Array<ElementCreateInput>>;
  repeat?: Maybe<TimingRepeatCreateInput>;
  code?: Maybe<CodeableConceptCreateInput>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat = {
  __typename?: 'TimingRepeat';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The units of time for the duration, in UCUM units. */
  durationUnit?: Maybe<TimingRepeatDurationUnit>;
  /** The units of time for the period in UCUM units. */
  periodUnit?: Maybe<TimingRepeatPeriodUnit>;
  /** An approximate time period during the day, potentially linked to an event of daily living that indicates when the action should occur. */
  when: Array<TimingRepeatWhen>;
  /** A total count of the desired number of repetitions across the duration of the entire timing specification. If countMax is present, this element indicates the lower bound of the allowed range of count values. */
  count?: Maybe<Scalars['PositiveInt']>;
  /** If present, indicates that the count is a range - so to perform the action between [count] and [countMax] times. */
  countMax?: Maybe<Scalars['PositiveInt']>;
  /** How long this thing happens for when it happens. If durationMax is present, this element indicates the lower bound of the allowed range of the duration. */
  duration?: Maybe<Scalars['Decimal']>;
  /** If present, indicates that the duration is a range - so to perform the action between [duration] and [durationMax] time length. */
  durationMax?: Maybe<Scalars['Decimal']>;
  /** The number of times to repeat the action within the specified period. If frequencyMax is present, this element indicates the lower bound of the allowed range of the frequency. */
  frequency?: Maybe<Scalars['PositiveInt']>;
  /** If present, indicates that the frequency is a range - so to repeat between [frequency] and [frequencyMax] times within the period or period range. */
  frequencyMax?: Maybe<Scalars['PositiveInt']>;
  /** Indicates the duration of time over which repetitions are to occur; e.g. to express "3 times per day", 3 would be the frequency and "1 day" would be the period. If periodMax is present, this element indicates the lower bound of the allowed range of the period length. */
  period?: Maybe<Scalars['Decimal']>;
  /** If present, indicates that the period is a range from [period] to [periodMax], allowing expressing concepts such as "do this once every 3-5 days. */
  periodMax?: Maybe<Scalars['Decimal']>;
  /** If one or more days of week is provided, then the action happens only on the specified day(s). */
  dayOfWeek: Array<Scalars['Code']>;
  /** Specified time of day for action to take place. */
  timeOfDay: Array<Scalars['Time']>;
  /** The number of minutes from the event. If the event code does not indicate whether the minutes is before or after the event, then the offset is assumed to be after the event. */
  offset?: Maybe<Scalars['UnsignedInt']>;
  extension: Array<Extension>;
  modifierExtension: Array<Extension>;
  boundsDuration?: Maybe<Duration>;
  boundsRange?: Maybe<Range>;
  boundsPeriod?: Maybe<Period>;
  _count?: Maybe<Element>;
  _countMax?: Maybe<Element>;
  _duration?: Maybe<Element>;
  _durationMax?: Maybe<Element>;
  _durationUnit?: Maybe<Element>;
  _frequency?: Maybe<Element>;
  _frequencyMax?: Maybe<Element>;
  _period?: Maybe<Element>;
  _periodMax?: Maybe<Element>;
  _periodUnit?: Maybe<Element>;
  _dayOfWeek: Array<Element>;
  _timeOfDay: Array<Element>;
  _when: Array<Element>;
  _offset?: Maybe<Element>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeatExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeatModifierExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeatBoundsDurationArgs = {
  where?: Maybe<DurationWhere>;
  options?: Maybe<DurationOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeatBoundsRangeArgs = {
  where?: Maybe<RangeWhere>;
  options?: Maybe<RangeOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeatBoundsPeriodArgs = {
  where?: Maybe<PeriodWhere>;
  options?: Maybe<PeriodOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_CountArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_CountMaxArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_DurationArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_DurationMaxArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_DurationUnitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_FrequencyArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_FrequencyMaxArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_PeriodArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_PeriodMaxArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_PeriodUnitArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_DayOfWeekArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_TimeOfDayArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_WhenArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** Specifies an event that may occur multiple times. Timing schedules are used to record when things are planned, expected or requested to occur. The most common usage is in dosage instructions for medications. They are also used when planning care of various kinds, and may be used for reporting the schedule to which past regular activities were carried out. */
export type TimingRepeat_OffsetArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

export type TimingRepeatBoundsdurationDeleteFieldInput = {
  where?: Maybe<DurationWhere>;
  delete?: Maybe<DurationDeleteInput>;
};

export type TimingRepeatBoundsdurationFieldInput = {
  create?: Maybe<DurationCreateInput>;
  connect?: Maybe<DurationConnectFieldInput>;
};

export type TimingRepeatBoundsdurationUpdateFieldInput = {
  where?: Maybe<DurationWhere>;
  update?: Maybe<DurationUpdateInput>;
  connect?: Maybe<DurationConnectFieldInput>;
  disconnect?: Maybe<DurationDisconnectFieldInput>;
  create?: Maybe<DurationCreateInput>;
  delete?: Maybe<DurationDeleteFieldInput>;
};

export type TimingRepeatBoundsperiodDeleteFieldInput = {
  where?: Maybe<PeriodWhere>;
  delete?: Maybe<PeriodDeleteInput>;
};

export type TimingRepeatBoundsperiodFieldInput = {
  create?: Maybe<PeriodCreateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
};

export type TimingRepeatBoundsperiodUpdateFieldInput = {
  where?: Maybe<PeriodWhere>;
  update?: Maybe<PeriodUpdateInput>;
  connect?: Maybe<PeriodConnectFieldInput>;
  disconnect?: Maybe<PeriodDisconnectFieldInput>;
  create?: Maybe<PeriodCreateInput>;
  delete?: Maybe<PeriodDeleteFieldInput>;
};

export type TimingRepeatBoundsrangeDeleteFieldInput = {
  where?: Maybe<RangeWhere>;
  delete?: Maybe<RangeDeleteInput>;
};

export type TimingRepeatBoundsrangeFieldInput = {
  create?: Maybe<RangeCreateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
};

export type TimingRepeatBoundsrangeUpdateFieldInput = {
  where?: Maybe<RangeWhere>;
  update?: Maybe<RangeUpdateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
  disconnect?: Maybe<RangeDisconnectFieldInput>;
  create?: Maybe<RangeCreateInput>;
  delete?: Maybe<RangeDeleteFieldInput>;
};

export type TimingRepeatConnectFieldInput = {
  where?: Maybe<TimingRepeatWhere>;
  connect?: Maybe<TimingRepeatConnectInput>;
};

export type TimingRepeatConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionConnectFieldInput>>;
  boundsDuration?: Maybe<DurationConnectFieldInput>;
  boundsRange?: Maybe<RangeConnectFieldInput>;
  boundsPeriod?: Maybe<PeriodConnectFieldInput>;
  _count?: Maybe<ElementConnectFieldInput>;
  _countMax?: Maybe<ElementConnectFieldInput>;
  _duration?: Maybe<ElementConnectFieldInput>;
  _durationMax?: Maybe<ElementConnectFieldInput>;
  _durationUnit?: Maybe<ElementConnectFieldInput>;
  _frequency?: Maybe<ElementConnectFieldInput>;
  _frequencyMax?: Maybe<ElementConnectFieldInput>;
  _period?: Maybe<ElementConnectFieldInput>;
  _periodMax?: Maybe<ElementConnectFieldInput>;
  _periodUnit?: Maybe<ElementConnectFieldInput>;
  _dayOfWeek?: Maybe<Array<ElementConnectFieldInput>>;
  _timeOfDay?: Maybe<Array<ElementConnectFieldInput>>;
  _when?: Maybe<Array<ElementConnectFieldInput>>;
  _offset?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeatCreateInput = {
  count?: Maybe<Scalars['PositiveInt']>;
  countMax?: Maybe<Scalars['PositiveInt']>;
  duration?: Maybe<Scalars['Decimal']>;
  durationMax?: Maybe<Scalars['Decimal']>;
  frequency?: Maybe<Scalars['PositiveInt']>;
  frequencyMax?: Maybe<Scalars['PositiveInt']>;
  period?: Maybe<Scalars['Decimal']>;
  periodMax?: Maybe<Scalars['Decimal']>;
  dayOfWeek: Array<Scalars['Code']>;
  timeOfDay: Array<Scalars['Time']>;
  offset?: Maybe<Scalars['UnsignedInt']>;
  durationUnit?: Maybe<TimingRepeatDurationUnit>;
  periodUnit?: Maybe<TimingRepeatPeriodUnit>;
  when: Array<TimingRepeatWhen>;
  extension?: Maybe<TimingRepeatExtensionFieldInput>;
  modifierExtension?: Maybe<TimingRepeatModifierextensionFieldInput>;
  boundsDuration?: Maybe<TimingRepeatBoundsdurationFieldInput>;
  boundsRange?: Maybe<TimingRepeatBoundsrangeFieldInput>;
  boundsPeriod?: Maybe<TimingRepeatBoundsperiodFieldInput>;
  _count?: Maybe<TimingRepeat_CountFieldInput>;
  _countMax?: Maybe<TimingRepeat_CountmaxFieldInput>;
  _duration?: Maybe<TimingRepeat_DurationFieldInput>;
  _durationMax?: Maybe<TimingRepeat_DurationmaxFieldInput>;
  _durationUnit?: Maybe<TimingRepeat_DurationunitFieldInput>;
  _frequency?: Maybe<TimingRepeat_FrequencyFieldInput>;
  _frequencyMax?: Maybe<TimingRepeat_FrequencymaxFieldInput>;
  _period?: Maybe<TimingRepeat_PeriodFieldInput>;
  _periodMax?: Maybe<TimingRepeat_PeriodmaxFieldInput>;
  _periodUnit?: Maybe<TimingRepeat_PeriodunitFieldInput>;
  _dayOfWeek?: Maybe<TimingRepeat_DayofweekFieldInput>;
  _timeOfDay?: Maybe<TimingRepeat_TimeofdayFieldInput>;
  _when?: Maybe<TimingRepeat_WhenFieldInput>;
  _offset?: Maybe<TimingRepeat_OffsetFieldInput>;
};

export type TimingRepeatDeleteFieldInput = {
  where?: Maybe<TimingRepeatWhere>;
  delete?: Maybe<TimingRepeatDeleteInput>;
};

export type TimingRepeatDeleteInput = {
  extension?: Maybe<Array<TimingRepeatExtensionDeleteFieldInput>>;
  modifierExtension?: Maybe<Array<TimingRepeatModifierextensionDeleteFieldInput>>;
  boundsDuration?: Maybe<TimingRepeatBoundsdurationDeleteFieldInput>;
  boundsRange?: Maybe<TimingRepeatBoundsrangeDeleteFieldInput>;
  boundsPeriod?: Maybe<TimingRepeatBoundsperiodDeleteFieldInput>;
  _count?: Maybe<TimingRepeat_CountDeleteFieldInput>;
  _countMax?: Maybe<TimingRepeat_CountmaxDeleteFieldInput>;
  _duration?: Maybe<TimingRepeat_DurationDeleteFieldInput>;
  _durationMax?: Maybe<TimingRepeat_DurationmaxDeleteFieldInput>;
  _durationUnit?: Maybe<TimingRepeat_DurationunitDeleteFieldInput>;
  _frequency?: Maybe<TimingRepeat_FrequencyDeleteFieldInput>;
  _frequencyMax?: Maybe<TimingRepeat_FrequencymaxDeleteFieldInput>;
  _period?: Maybe<TimingRepeat_PeriodDeleteFieldInput>;
  _periodMax?: Maybe<TimingRepeat_PeriodmaxDeleteFieldInput>;
  _periodUnit?: Maybe<TimingRepeat_PeriodunitDeleteFieldInput>;
  _dayOfWeek?: Maybe<Array<TimingRepeat_DayofweekDeleteFieldInput>>;
  _timeOfDay?: Maybe<Array<TimingRepeat_TimeofdayDeleteFieldInput>>;
  _when?: Maybe<Array<TimingRepeat_WhenDeleteFieldInput>>;
  _offset?: Maybe<TimingRepeat_OffsetDeleteFieldInput>;
};

export type TimingRepeatDisconnectFieldInput = {
  where?: Maybe<TimingRepeatWhere>;
  disconnect?: Maybe<TimingRepeatDisconnectInput>;
};

export type TimingRepeatDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  modifierExtension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  boundsDuration?: Maybe<DurationDisconnectFieldInput>;
  boundsRange?: Maybe<RangeDisconnectFieldInput>;
  boundsPeriod?: Maybe<PeriodDisconnectFieldInput>;
  _count?: Maybe<ElementDisconnectFieldInput>;
  _countMax?: Maybe<ElementDisconnectFieldInput>;
  _duration?: Maybe<ElementDisconnectFieldInput>;
  _durationMax?: Maybe<ElementDisconnectFieldInput>;
  _durationUnit?: Maybe<ElementDisconnectFieldInput>;
  _frequency?: Maybe<ElementDisconnectFieldInput>;
  _frequencyMax?: Maybe<ElementDisconnectFieldInput>;
  _period?: Maybe<ElementDisconnectFieldInput>;
  _periodMax?: Maybe<ElementDisconnectFieldInput>;
  _periodUnit?: Maybe<ElementDisconnectFieldInput>;
  _dayOfWeek?: Maybe<Array<ElementDisconnectFieldInput>>;
  _timeOfDay?: Maybe<Array<ElementDisconnectFieldInput>>;
  _when?: Maybe<Array<ElementDisconnectFieldInput>>;
  _offset?: Maybe<ElementDisconnectFieldInput>;
};

/** The units of time for the duration, in UCUM units. */
export enum TimingRepeatDurationUnit {
  S = 's',
  Min = 'min',
  H = 'h',
  D = 'd',
  Wk = 'wk',
  Mo = 'mo',
  A = 'a',
}

export type TimingRepeatExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type TimingRepeatExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type TimingRepeatExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type TimingRepeatFieldInput = {
  create?: Maybe<TimingRepeatCreateInput>;
  connect?: Maybe<TimingRepeatConnectFieldInput>;
};

export type TimingRepeatModifierextensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type TimingRepeatModifierextensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type TimingRepeatModifierextensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type TimingRepeatOptions = {
  /** Specify one or more TimingRepeatSort objects to sort TimingRepeats by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<TimingRepeatSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** The units of time for the period in UCUM units. */
export enum TimingRepeatPeriodUnit {
  S = 's',
  Min = 'min',
  H = 'h',
  D = 'd',
  Wk = 'wk',
  Mo = 'mo',
  A = 'a',
}

export type TimingRepeatRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  modifierExtension?: Maybe<Array<ExtensionCreateInput>>;
  boundsDuration?: Maybe<DurationCreateInput>;
  boundsRange?: Maybe<RangeCreateInput>;
  boundsPeriod?: Maybe<PeriodCreateInput>;
  _count?: Maybe<ElementCreateInput>;
  _countMax?: Maybe<ElementCreateInput>;
  _duration?: Maybe<ElementCreateInput>;
  _durationMax?: Maybe<ElementCreateInput>;
  _durationUnit?: Maybe<ElementCreateInput>;
  _frequency?: Maybe<ElementCreateInput>;
  _frequencyMax?: Maybe<ElementCreateInput>;
  _period?: Maybe<ElementCreateInput>;
  _periodMax?: Maybe<ElementCreateInput>;
  _periodUnit?: Maybe<ElementCreateInput>;
  _dayOfWeek?: Maybe<Array<ElementCreateInput>>;
  _timeOfDay?: Maybe<Array<ElementCreateInput>>;
  _when?: Maybe<Array<ElementCreateInput>>;
  _offset?: Maybe<ElementCreateInput>;
};

/** Fields to sort TimingRepeats by. The order in which sorts are applied is not guaranteed when specifying many fields in one TimingRepeatSort object. */
export type TimingRepeatSort = {
  id?: Maybe<SortDirection>;
  durationUnit?: Maybe<SortDirection>;
  periodUnit?: Maybe<SortDirection>;
  count?: Maybe<SortDirection>;
  countMax?: Maybe<SortDirection>;
  duration?: Maybe<SortDirection>;
  durationMax?: Maybe<SortDirection>;
  frequency?: Maybe<SortDirection>;
  frequencyMax?: Maybe<SortDirection>;
  period?: Maybe<SortDirection>;
  periodMax?: Maybe<SortDirection>;
  offset?: Maybe<SortDirection>;
};

export type TimingRepeatUpdateFieldInput = {
  where?: Maybe<TimingRepeatWhere>;
  update?: Maybe<TimingRepeatUpdateInput>;
  connect?: Maybe<TimingRepeatConnectFieldInput>;
  disconnect?: Maybe<TimingRepeatDisconnectFieldInput>;
  create?: Maybe<TimingRepeatCreateInput>;
  delete?: Maybe<TimingRepeatDeleteFieldInput>;
};

export type TimingRepeatUpdateInput = {
  count?: Maybe<Scalars['PositiveInt']>;
  countMax?: Maybe<Scalars['PositiveInt']>;
  duration?: Maybe<Scalars['Decimal']>;
  durationMax?: Maybe<Scalars['Decimal']>;
  frequency?: Maybe<Scalars['PositiveInt']>;
  frequencyMax?: Maybe<Scalars['PositiveInt']>;
  period?: Maybe<Scalars['Decimal']>;
  periodMax?: Maybe<Scalars['Decimal']>;
  dayOfWeek?: Maybe<Array<Scalars['Code']>>;
  timeOfDay?: Maybe<Array<Scalars['Time']>>;
  offset?: Maybe<Scalars['UnsignedInt']>;
  durationUnit?: Maybe<TimingRepeatDurationUnit>;
  periodUnit?: Maybe<TimingRepeatPeriodUnit>;
  when?: Maybe<Array<TimingRepeatWhen>>;
  extension?: Maybe<Array<TimingRepeatExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<TimingRepeatModifierextensionUpdateFieldInput>>;
  boundsDuration?: Maybe<TimingRepeatBoundsdurationUpdateFieldInput>;
  boundsRange?: Maybe<TimingRepeatBoundsrangeUpdateFieldInput>;
  boundsPeriod?: Maybe<TimingRepeatBoundsperiodUpdateFieldInput>;
  _count?: Maybe<TimingRepeat_CountUpdateFieldInput>;
  _countMax?: Maybe<TimingRepeat_CountmaxUpdateFieldInput>;
  _duration?: Maybe<TimingRepeat_DurationUpdateFieldInput>;
  _durationMax?: Maybe<TimingRepeat_DurationmaxUpdateFieldInput>;
  _durationUnit?: Maybe<TimingRepeat_DurationunitUpdateFieldInput>;
  _frequency?: Maybe<TimingRepeat_FrequencyUpdateFieldInput>;
  _frequencyMax?: Maybe<TimingRepeat_FrequencymaxUpdateFieldInput>;
  _period?: Maybe<TimingRepeat_PeriodUpdateFieldInput>;
  _periodMax?: Maybe<TimingRepeat_PeriodmaxUpdateFieldInput>;
  _periodUnit?: Maybe<TimingRepeat_PeriodunitUpdateFieldInput>;
  _dayOfWeek?: Maybe<Array<TimingRepeat_DayofweekUpdateFieldInput>>;
  _timeOfDay?: Maybe<Array<TimingRepeat_TimeofdayUpdateFieldInput>>;
  _when?: Maybe<Array<TimingRepeat_WhenUpdateFieldInput>>;
  _offset?: Maybe<TimingRepeat_OffsetUpdateFieldInput>;
};

export enum TimingRepeatWhen {
  Morn = 'morn',
  MorNearly = 'morNearly',
  MorNlate = 'morNlate',
  Noon = 'noon',
  Aft = 'aft',
  AfTearly = 'afTearly',
  AfTlate = 'afTlate',
  Eve = 'eve',
  EvEearly = 'evEearly',
  EvElate = 'evElate',
  Night = 'night',
  Phs = 'phs',
  Hs = 'hs',
  Wake = 'wake',
  C = 'c',
  Cm = 'cm',
  Cd = 'cd',
  Cv = 'cv',
  Ac = 'ac',
  Acm = 'acm',
  Acd = 'acd',
  Acv = 'acv',
  Pc = 'pc',
  Pcm = 'pcm',
  Pcd = 'pcd',
  Pcv = 'pcv',
}

export type TimingRepeatWhere = {
  OR?: Maybe<Array<TimingRepeatWhere>>;
  AND?: Maybe<Array<TimingRepeatWhere>>;
  count?: Maybe<Scalars['PositiveInt']>;
  countMax?: Maybe<Scalars['PositiveInt']>;
  duration?: Maybe<Scalars['Decimal']>;
  durationMax?: Maybe<Scalars['Decimal']>;
  frequency?: Maybe<Scalars['PositiveInt']>;
  frequencyMax?: Maybe<Scalars['PositiveInt']>;
  period?: Maybe<Scalars['Decimal']>;
  periodMax?: Maybe<Scalars['Decimal']>;
  dayOfWeek?: Maybe<Array<Maybe<Scalars['Code']>>>;
  timeOfDay?: Maybe<Array<Maybe<Scalars['Time']>>>;
  offset?: Maybe<Scalars['UnsignedInt']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  durationUnit?: Maybe<TimingRepeatDurationUnit>;
  durationUnit_NOT?: Maybe<TimingRepeatDurationUnit>;
  durationUnit_IN?: Maybe<Array<Maybe<TimingRepeatDurationUnit>>>;
  durationUnit_NOT_IN?: Maybe<Array<Maybe<TimingRepeatDurationUnit>>>;
  periodUnit?: Maybe<TimingRepeatPeriodUnit>;
  periodUnit_NOT?: Maybe<TimingRepeatPeriodUnit>;
  periodUnit_IN?: Maybe<Array<Maybe<TimingRepeatPeriodUnit>>>;
  periodUnit_NOT_IN?: Maybe<Array<Maybe<TimingRepeatPeriodUnit>>>;
  when?: Maybe<Array<TimingRepeatWhen>>;
  when_NOT?: Maybe<Array<TimingRepeatWhen>>;
  when_INCLUDES?: Maybe<TimingRepeatWhen>;
  when_NOT_INCLUDES?: Maybe<TimingRepeatWhen>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  boundsDuration?: Maybe<DurationWhere>;
  boundsDuration_NOT?: Maybe<DurationWhere>;
  boundsDuration_IN?: Maybe<Array<DurationWhere>>;
  boundsDuration_NOT_IN?: Maybe<Array<DurationWhere>>;
  boundsRange?: Maybe<RangeWhere>;
  boundsRange_NOT?: Maybe<RangeWhere>;
  boundsRange_IN?: Maybe<Array<RangeWhere>>;
  boundsRange_NOT_IN?: Maybe<Array<RangeWhere>>;
  boundsPeriod?: Maybe<PeriodWhere>;
  boundsPeriod_NOT?: Maybe<PeriodWhere>;
  boundsPeriod_IN?: Maybe<Array<PeriodWhere>>;
  boundsPeriod_NOT_IN?: Maybe<Array<PeriodWhere>>;
  _count?: Maybe<ElementWhere>;
  _count_NOT?: Maybe<ElementWhere>;
  _count_IN?: Maybe<Array<ElementWhere>>;
  _count_NOT_IN?: Maybe<Array<ElementWhere>>;
  _countMax?: Maybe<ElementWhere>;
  _countMax_NOT?: Maybe<ElementWhere>;
  _countMax_IN?: Maybe<Array<ElementWhere>>;
  _countMax_NOT_IN?: Maybe<Array<ElementWhere>>;
  _duration?: Maybe<ElementWhere>;
  _duration_NOT?: Maybe<ElementWhere>;
  _duration_IN?: Maybe<Array<ElementWhere>>;
  _duration_NOT_IN?: Maybe<Array<ElementWhere>>;
  _durationMax?: Maybe<ElementWhere>;
  _durationMax_NOT?: Maybe<ElementWhere>;
  _durationMax_IN?: Maybe<Array<ElementWhere>>;
  _durationMax_NOT_IN?: Maybe<Array<ElementWhere>>;
  _durationUnit?: Maybe<ElementWhere>;
  _durationUnit_NOT?: Maybe<ElementWhere>;
  _durationUnit_IN?: Maybe<Array<ElementWhere>>;
  _durationUnit_NOT_IN?: Maybe<Array<ElementWhere>>;
  _frequency?: Maybe<ElementWhere>;
  _frequency_NOT?: Maybe<ElementWhere>;
  _frequency_IN?: Maybe<Array<ElementWhere>>;
  _frequency_NOT_IN?: Maybe<Array<ElementWhere>>;
  _frequencyMax?: Maybe<ElementWhere>;
  _frequencyMax_NOT?: Maybe<ElementWhere>;
  _frequencyMax_IN?: Maybe<Array<ElementWhere>>;
  _frequencyMax_NOT_IN?: Maybe<Array<ElementWhere>>;
  _period?: Maybe<ElementWhere>;
  _period_NOT?: Maybe<ElementWhere>;
  _period_IN?: Maybe<Array<ElementWhere>>;
  _period_NOT_IN?: Maybe<Array<ElementWhere>>;
  _periodMax?: Maybe<ElementWhere>;
  _periodMax_NOT?: Maybe<ElementWhere>;
  _periodMax_IN?: Maybe<Array<ElementWhere>>;
  _periodMax_NOT_IN?: Maybe<Array<ElementWhere>>;
  _periodUnit?: Maybe<ElementWhere>;
  _periodUnit_NOT?: Maybe<ElementWhere>;
  _periodUnit_IN?: Maybe<Array<ElementWhere>>;
  _periodUnit_NOT_IN?: Maybe<Array<ElementWhere>>;
  _dayOfWeek?: Maybe<ElementWhere>;
  _dayOfWeek_NOT?: Maybe<ElementWhere>;
  _timeOfDay?: Maybe<ElementWhere>;
  _timeOfDay_NOT?: Maybe<ElementWhere>;
  _when?: Maybe<ElementWhere>;
  _when_NOT?: Maybe<ElementWhere>;
  _offset?: Maybe<ElementWhere>;
  _offset_NOT?: Maybe<ElementWhere>;
  _offset_IN?: Maybe<Array<ElementWhere>>;
  _offset_NOT_IN?: Maybe<Array<ElementWhere>>;
};

export type TimingRepeat_CountDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_CountFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_CountUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_CountmaxDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_CountmaxFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_CountmaxUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_DayofweekDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_DayofweekFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type TimingRepeat_DayofweekUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type TimingRepeat_DurationDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_DurationFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_DurationUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_DurationmaxDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_DurationmaxFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_DurationmaxUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_DurationunitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_DurationunitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_DurationunitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_FrequencyDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_FrequencyFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_FrequencyUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_FrequencymaxDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_FrequencymaxFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_FrequencymaxUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_OffsetDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_OffsetFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_OffsetUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_PeriodDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_PeriodFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_PeriodUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_PeriodmaxDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_PeriodmaxFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_PeriodmaxUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_PeriodunitDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_PeriodunitFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TimingRepeat_PeriodunitUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TimingRepeat_TimeofdayDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_TimeofdayFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type TimingRepeat_TimeofdayUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

export type TimingRepeat_WhenDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TimingRepeat_WhenFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type TimingRepeat_WhenUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

/** Fields to sort Timings by. The order in which sorts are applied is not guaranteed when specifying many fields in one TimingSort object. */
export type TimingSort = {
  id?: Maybe<SortDirection>;
};

export type TimingUpdateInput = {
  event?: Maybe<Array<Scalars['DateTime']>>;
  extension?: Maybe<Array<TimingExtensionUpdateFieldInput>>;
  modifierExtension?: Maybe<Array<TimingModifierextensionUpdateFieldInput>>;
  _event?: Maybe<Array<Timing_EventUpdateFieldInput>>;
  repeat?: Maybe<TimingRepeatUpdateFieldInput>;
  code?: Maybe<TimingCodeUpdateFieldInput>;
};

export type TimingWhere = {
  OR?: Maybe<Array<TimingWhere>>;
  AND?: Maybe<Array<TimingWhere>>;
  event?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  modifierExtension?: Maybe<ExtensionWhere>;
  modifierExtension_NOT?: Maybe<ExtensionWhere>;
  _event?: Maybe<ElementWhere>;
  _event_NOT?: Maybe<ElementWhere>;
  repeat?: Maybe<TimingRepeatWhere>;
  repeat_NOT?: Maybe<TimingRepeatWhere>;
  repeat_IN?: Maybe<Array<TimingRepeatWhere>>;
  repeat_NOT_IN?: Maybe<Array<TimingRepeatWhere>>;
  code?: Maybe<CodeableConceptWhere>;
  code_NOT?: Maybe<CodeableConceptWhere>;
  code_IN?: Maybe<Array<CodeableConceptWhere>>;
  code_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
};

export type Timing_EventDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type Timing_EventFieldInput = {
  create?: Maybe<Array<ElementCreateInput>>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
};

export type Timing_EventUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<Array<ElementConnectFieldInput>>;
  disconnect?: Maybe<Array<ElementDisconnectFieldInput>>;
  create?: Maybe<Array<ElementCreateInput>>;
  delete?: Maybe<Array<ElementDeleteFieldInput>>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinition = {
  __typename?: 'TriggerDefinition';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  /** The type of triggering event. */
  type?: Maybe<TriggerDefinitionType>;
  /** A formal name for the event. This may be an absolute URI that identifies the event formally (e.g. from a trigger registry), or a simple relative URI that identifies the event in a local context. */
  name?: Maybe<Scalars['String']>;
  /** The timing of the event (if this is a periodic trigger). */
  timingDate?: Maybe<Scalars['String']>;
  /** The timing of the event (if this is a periodic trigger). */
  timingDateTime?: Maybe<Scalars['String']>;
  extension: Array<Extension>;
  _type?: Maybe<Element>;
  _name?: Maybe<Element>;
  timingTiming?: Maybe<Timing>;
  timingReference?: Maybe<Reference>;
  _timingDate?: Maybe<Element>;
  _timingDateTime?: Maybe<Element>;
  data: Array<DataRequirement>;
  condition?: Maybe<Expression>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinitionExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinition_TypeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinition_NameArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinitionTimingTimingArgs = {
  where?: Maybe<TimingWhere>;
  options?: Maybe<TimingOptions>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinitionTimingReferenceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinition_TimingDateArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinition_TimingDateTimeArgs = {
  where?: Maybe<ElementWhere>;
  options?: Maybe<ElementOptions>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinitionDataArgs = {
  where?: Maybe<DataRequirementWhere>;
  options?: Maybe<DataRequirementOptions>;
};

/** A description of a triggering event. Triggering events can be named events, data events, or periodic, as determined by the type element. */
export type TriggerDefinitionConditionArgs = {
  where?: Maybe<ExpressionWhere>;
  options?: Maybe<ExpressionOptions>;
};

export type TriggerDefinitionConditionDeleteFieldInput = {
  where?: Maybe<ExpressionWhere>;
  delete?: Maybe<ExpressionDeleteInput>;
};

export type TriggerDefinitionConditionFieldInput = {
  create?: Maybe<ExpressionCreateInput>;
  connect?: Maybe<ExpressionConnectFieldInput>;
};

export type TriggerDefinitionConditionUpdateFieldInput = {
  where?: Maybe<ExpressionWhere>;
  update?: Maybe<ExpressionUpdateInput>;
  connect?: Maybe<ExpressionConnectFieldInput>;
  disconnect?: Maybe<ExpressionDisconnectFieldInput>;
  create?: Maybe<ExpressionCreateInput>;
  delete?: Maybe<ExpressionDeleteFieldInput>;
};

export type TriggerDefinitionConnectFieldInput = {
  where?: Maybe<TriggerDefinitionWhere>;
  connect?: Maybe<TriggerDefinitionConnectInput>;
};

export type TriggerDefinitionConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  _type?: Maybe<ElementConnectFieldInput>;
  _name?: Maybe<ElementConnectFieldInput>;
  timingTiming?: Maybe<TimingConnectFieldInput>;
  timingReference?: Maybe<ReferenceConnectFieldInput>;
  _timingDate?: Maybe<ElementConnectFieldInput>;
  _timingDateTime?: Maybe<ElementConnectFieldInput>;
  data?: Maybe<Array<DataRequirementConnectFieldInput>>;
  condition?: Maybe<ExpressionConnectFieldInput>;
};

export type TriggerDefinitionCreateInput = {
  name?: Maybe<Scalars['String']>;
  timingDate?: Maybe<Scalars['String']>;
  timingDateTime?: Maybe<Scalars['String']>;
  type?: Maybe<TriggerDefinitionType>;
  extension?: Maybe<TriggerDefinitionExtensionFieldInput>;
  _type?: Maybe<TriggerDefinition_TypeFieldInput>;
  _name?: Maybe<TriggerDefinition_NameFieldInput>;
  timingTiming?: Maybe<TriggerDefinitionTimingtimingFieldInput>;
  timingReference?: Maybe<TriggerDefinitionTimingreferenceFieldInput>;
  _timingDate?: Maybe<TriggerDefinition_TimingdateFieldInput>;
  _timingDateTime?: Maybe<TriggerDefinition_TimingdatetimeFieldInput>;
  data?: Maybe<TriggerDefinitionDataFieldInput>;
  condition?: Maybe<TriggerDefinitionConditionFieldInput>;
};

export type TriggerDefinitionDataDeleteFieldInput = {
  where?: Maybe<DataRequirementWhere>;
  delete?: Maybe<DataRequirementDeleteInput>;
};

export type TriggerDefinitionDataFieldInput = {
  create?: Maybe<Array<DataRequirementCreateInput>>;
  connect?: Maybe<Array<DataRequirementConnectFieldInput>>;
};

export type TriggerDefinitionDataUpdateFieldInput = {
  where?: Maybe<DataRequirementWhere>;
  update?: Maybe<DataRequirementUpdateInput>;
  connect?: Maybe<Array<DataRequirementConnectFieldInput>>;
  disconnect?: Maybe<Array<DataRequirementDisconnectFieldInput>>;
  create?: Maybe<Array<DataRequirementCreateInput>>;
  delete?: Maybe<Array<DataRequirementDeleteFieldInput>>;
};

export type TriggerDefinitionDeleteFieldInput = {
  where?: Maybe<TriggerDefinitionWhere>;
  delete?: Maybe<TriggerDefinitionDeleteInput>;
};

export type TriggerDefinitionDeleteInput = {
  extension?: Maybe<Array<TriggerDefinitionExtensionDeleteFieldInput>>;
  _type?: Maybe<TriggerDefinition_TypeDeleteFieldInput>;
  _name?: Maybe<TriggerDefinition_NameDeleteFieldInput>;
  timingTiming?: Maybe<TriggerDefinitionTimingtimingDeleteFieldInput>;
  timingReference?: Maybe<TriggerDefinitionTimingreferenceDeleteFieldInput>;
  _timingDate?: Maybe<TriggerDefinition_TimingdateDeleteFieldInput>;
  _timingDateTime?: Maybe<TriggerDefinition_TimingdatetimeDeleteFieldInput>;
  data?: Maybe<Array<TriggerDefinitionDataDeleteFieldInput>>;
  condition?: Maybe<TriggerDefinitionConditionDeleteFieldInput>;
};

export type TriggerDefinitionDisconnectFieldInput = {
  where?: Maybe<TriggerDefinitionWhere>;
  disconnect?: Maybe<TriggerDefinitionDisconnectInput>;
};

export type TriggerDefinitionDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  _type?: Maybe<ElementDisconnectFieldInput>;
  _name?: Maybe<ElementDisconnectFieldInput>;
  timingTiming?: Maybe<TimingDisconnectFieldInput>;
  timingReference?: Maybe<ReferenceDisconnectFieldInput>;
  _timingDate?: Maybe<ElementDisconnectFieldInput>;
  _timingDateTime?: Maybe<ElementDisconnectFieldInput>;
  data?: Maybe<Array<DataRequirementDisconnectFieldInput>>;
  condition?: Maybe<ExpressionDisconnectFieldInput>;
};

export type TriggerDefinitionExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type TriggerDefinitionExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type TriggerDefinitionExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type TriggerDefinitionOptions = {
  /** Specify one or more TriggerDefinitionSort objects to sort TriggerDefinitions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<TriggerDefinitionSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type TriggerDefinitionRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  _type?: Maybe<ElementCreateInput>;
  _name?: Maybe<ElementCreateInput>;
  timingTiming?: Maybe<TimingCreateInput>;
  timingReference?: Maybe<ReferenceCreateInput>;
  _timingDate?: Maybe<ElementCreateInput>;
  _timingDateTime?: Maybe<ElementCreateInput>;
  data?: Maybe<Array<DataRequirementCreateInput>>;
  condition?: Maybe<ExpressionCreateInput>;
};

/** Fields to sort TriggerDefinitions by. The order in which sorts are applied is not guaranteed when specifying many fields in one TriggerDefinitionSort object. */
export type TriggerDefinitionSort = {
  id?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  timingDate?: Maybe<SortDirection>;
  timingDateTime?: Maybe<SortDirection>;
};

export type TriggerDefinitionTimingreferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type TriggerDefinitionTimingreferenceFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type TriggerDefinitionTimingreferenceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type TriggerDefinitionTimingtimingDeleteFieldInput = {
  where?: Maybe<TimingWhere>;
  delete?: Maybe<TimingDeleteInput>;
};

export type TriggerDefinitionTimingtimingFieldInput = {
  create?: Maybe<TimingCreateInput>;
  connect?: Maybe<TimingConnectFieldInput>;
};

export type TriggerDefinitionTimingtimingUpdateFieldInput = {
  where?: Maybe<TimingWhere>;
  update?: Maybe<TimingUpdateInput>;
  connect?: Maybe<TimingConnectFieldInput>;
  disconnect?: Maybe<TimingDisconnectFieldInput>;
  create?: Maybe<TimingCreateInput>;
  delete?: Maybe<TimingDeleteFieldInput>;
};

/** The type of triggering event. */
export enum TriggerDefinitionType {
  NamedEvent = 'namedEvent',
  Periodic = 'periodic',
  DataChanged = 'dataChanged',
  DataAdded = 'dataAdded',
  DataModified = 'dataModified',
  DataRemoved = 'dataRemoved',
  DataAccessed = 'dataAccessed',
  DataAccessEnded = 'dataAccessEnded',
}

export type TriggerDefinitionUpdateInput = {
  name?: Maybe<Scalars['String']>;
  timingDate?: Maybe<Scalars['String']>;
  timingDateTime?: Maybe<Scalars['String']>;
  type?: Maybe<TriggerDefinitionType>;
  extension?: Maybe<Array<TriggerDefinitionExtensionUpdateFieldInput>>;
  _type?: Maybe<TriggerDefinition_TypeUpdateFieldInput>;
  _name?: Maybe<TriggerDefinition_NameUpdateFieldInput>;
  timingTiming?: Maybe<TriggerDefinitionTimingtimingUpdateFieldInput>;
  timingReference?: Maybe<TriggerDefinitionTimingreferenceUpdateFieldInput>;
  _timingDate?: Maybe<TriggerDefinition_TimingdateUpdateFieldInput>;
  _timingDateTime?: Maybe<TriggerDefinition_TimingdatetimeUpdateFieldInput>;
  data?: Maybe<Array<TriggerDefinitionDataUpdateFieldInput>>;
  condition?: Maybe<TriggerDefinitionConditionUpdateFieldInput>;
};

export type TriggerDefinitionWhere = {
  OR?: Maybe<Array<TriggerDefinitionWhere>>;
  AND?: Maybe<Array<TriggerDefinitionWhere>>;
  name?: Maybe<Scalars['String']>;
  timingDate?: Maybe<Scalars['String']>;
  timingDateTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerDefinitionType>;
  type_NOT?: Maybe<TriggerDefinitionType>;
  type_IN?: Maybe<Array<Maybe<TriggerDefinitionType>>>;
  type_NOT_IN?: Maybe<Array<Maybe<TriggerDefinitionType>>>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  _type?: Maybe<ElementWhere>;
  _type_NOT?: Maybe<ElementWhere>;
  _type_IN?: Maybe<Array<ElementWhere>>;
  _type_NOT_IN?: Maybe<Array<ElementWhere>>;
  _name?: Maybe<ElementWhere>;
  _name_NOT?: Maybe<ElementWhere>;
  _name_IN?: Maybe<Array<ElementWhere>>;
  _name_NOT_IN?: Maybe<Array<ElementWhere>>;
  timingTiming?: Maybe<TimingWhere>;
  timingTiming_NOT?: Maybe<TimingWhere>;
  timingTiming_IN?: Maybe<Array<TimingWhere>>;
  timingTiming_NOT_IN?: Maybe<Array<TimingWhere>>;
  timingReference?: Maybe<ReferenceWhere>;
  timingReference_NOT?: Maybe<ReferenceWhere>;
  timingReference_IN?: Maybe<Array<ReferenceWhere>>;
  timingReference_NOT_IN?: Maybe<Array<ReferenceWhere>>;
  _timingDate?: Maybe<ElementWhere>;
  _timingDate_NOT?: Maybe<ElementWhere>;
  _timingDate_IN?: Maybe<Array<ElementWhere>>;
  _timingDate_NOT_IN?: Maybe<Array<ElementWhere>>;
  _timingDateTime?: Maybe<ElementWhere>;
  _timingDateTime_NOT?: Maybe<ElementWhere>;
  _timingDateTime_IN?: Maybe<Array<ElementWhere>>;
  _timingDateTime_NOT_IN?: Maybe<Array<ElementWhere>>;
  data?: Maybe<DataRequirementWhere>;
  data_NOT?: Maybe<DataRequirementWhere>;
  condition?: Maybe<ExpressionWhere>;
  condition_NOT?: Maybe<ExpressionWhere>;
  condition_IN?: Maybe<Array<ExpressionWhere>>;
  condition_NOT_IN?: Maybe<Array<ExpressionWhere>>;
};

export type TriggerDefinition_NameDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TriggerDefinition_NameFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TriggerDefinition_NameUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TriggerDefinition_TimingdateDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TriggerDefinition_TimingdateFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TriggerDefinition_TimingdateUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TriggerDefinition_TimingdatetimeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TriggerDefinition_TimingdatetimeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TriggerDefinition_TimingdatetimeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type TriggerDefinition_TypeDeleteFieldInput = {
  where?: Maybe<ElementWhere>;
  delete?: Maybe<ElementDeleteInput>;
};

export type TriggerDefinition_TypeFieldInput = {
  create?: Maybe<ElementCreateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
};

export type TriggerDefinition_TypeUpdateFieldInput = {
  where?: Maybe<ElementWhere>;
  update?: Maybe<ElementUpdateInput>;
  connect?: Maybe<ElementConnectFieldInput>;
  disconnect?: Maybe<ElementDisconnectFieldInput>;
  create?: Maybe<ElementCreateInput>;
  delete?: Maybe<ElementDeleteFieldInput>;
};

export type UpdateAccountCoveragesMutationResponse = {
  __typename?: 'UpdateAccountCoveragesMutationResponse';
  accountCoverages: Array<AccountCoverage>;
};

export type UpdateAccountGuarantorsMutationResponse = {
  __typename?: 'UpdateAccountGuarantorsMutationResponse';
  accountGuarantors: Array<AccountGuarantor>;
};

export type UpdateAccountsMutationResponse = {
  __typename?: 'UpdateAccountsMutationResponse';
  accounts: Array<Account>;
};

export type UpdateAddressesMutationResponse = {
  __typename?: 'UpdateAddressesMutationResponse';
  addresses: Array<Address>;
};

export type UpdateAgesMutationResponse = {
  __typename?: 'UpdateAgesMutationResponse';
  ages: Array<Age>;
};

export type UpdateAnnotationsMutationResponse = {
  __typename?: 'UpdateAnnotationsMutationResponse';
  annotations: Array<Annotation>;
};

export type UpdateAppointmentParticipantsMutationResponse = {
  __typename?: 'UpdateAppointmentParticipantsMutationResponse';
  appointmentParticipants: Array<AppointmentParticipant>;
};

export type UpdateAppointmentResponsesMutationResponse = {
  __typename?: 'UpdateAppointmentResponsesMutationResponse';
  appointmentResponses: Array<AppointmentResponse>;
};

export type UpdateAppointmentsMutationResponse = {
  __typename?: 'UpdateAppointmentsMutationResponse';
  appointments: Array<Appointment>;
};

export type UpdateAttachmentsMutationResponse = {
  __typename?: 'UpdateAttachmentsMutationResponse';
  attachments: Array<Attachment>;
};

export type UpdateCodeableConceptsMutationResponse = {
  __typename?: 'UpdateCodeableConceptsMutationResponse';
  codeableConcepts: Array<CodeableConcept>;
};

export type UpdateCodingsMutationResponse = {
  __typename?: 'UpdateCodingsMutationResponse';
  codings: Array<Coding>;
};

export type UpdateContactDetailsMutationResponse = {
  __typename?: 'UpdateContactDetailsMutationResponse';
  contactDetails: Array<ContactDetail>;
};

export type UpdateContactPointsMutationResponse = {
  __typename?: 'UpdateContactPointsMutationResponse';
  contactPoints: Array<ContactPoint>;
};

export type UpdateContributorsMutationResponse = {
  __typename?: 'UpdateContributorsMutationResponse';
  contributors: Array<Contributor>;
};

export type UpdateCountsMutationResponse = {
  __typename?: 'UpdateCountsMutationResponse';
  counts: Array<Count>;
};

export type UpdateCoverageClassesMutationResponse = {
  __typename?: 'UpdateCoverageClassesMutationResponse';
  coverageClasses: Array<CoverageClass>;
};

export type UpdateCoverageCostToBeneficiariesMutationResponse = {
  __typename?: 'UpdateCoverageCostToBeneficiariesMutationResponse';
  coverageCostToBeneficiaries: Array<CoverageCostToBeneficiary>;
};

export type UpdateCoverageExceptionsMutationResponse = {
  __typename?: 'UpdateCoverageExceptionsMutationResponse';
  coverageExceptions: Array<CoverageException>;
};

export type UpdateCoveragesMutationResponse = {
  __typename?: 'UpdateCoveragesMutationResponse';
  coverages: Array<Coverage>;
};

export type UpdateDataRequirementCodeFiltersMutationResponse = {
  __typename?: 'UpdateDataRequirementCodeFiltersMutationResponse';
  dataRequirementCodeFilters: Array<DataRequirementCodeFilter>;
};

export type UpdateDataRequirementCustomSortsMutationResponse = {
  __typename?: 'UpdateDataRequirementCustomSortsMutationResponse';
  dataRequirementCustomSorts: Array<DataRequirementCustomSort>;
};

export type UpdateDataRequirementDateFiltersMutationResponse = {
  __typename?: 'UpdateDataRequirementDateFiltersMutationResponse';
  dataRequirementDateFilters: Array<DataRequirementDateFilter>;
};

export type UpdateDataRequirementsMutationResponse = {
  __typename?: 'UpdateDataRequirementsMutationResponse';
  dataRequirements: Array<DataRequirement>;
};

export type UpdateDeviceDefinitionCapabilitiesMutationResponse = {
  __typename?: 'UpdateDeviceDefinitionCapabilitiesMutationResponse';
  deviceDefinitionCapabilities: Array<DeviceDefinitionCapability>;
};

export type UpdateDeviceDefinitionDeviceNamesMutationResponse = {
  __typename?: 'UpdateDeviceDefinitionDeviceNamesMutationResponse';
  deviceDefinitionDeviceNames: Array<DeviceDefinitionDeviceName>;
};

export type UpdateDeviceDefinitionMaterialsMutationResponse = {
  __typename?: 'UpdateDeviceDefinitionMaterialsMutationResponse';
  deviceDefinitionMaterials: Array<DeviceDefinitionMaterial>;
};

export type UpdateDeviceDefinitionPropertiesMutationResponse = {
  __typename?: 'UpdateDeviceDefinitionPropertiesMutationResponse';
  deviceDefinitionProperties: Array<DeviceDefinitionProperty>;
};

export type UpdateDeviceDefinitionSpecializationsMutationResponse = {
  __typename?: 'UpdateDeviceDefinitionSpecializationsMutationResponse';
  deviceDefinitionSpecializations: Array<DeviceDefinitionSpecialization>;
};

export type UpdateDeviceDefinitionUdiDeviceIdentifiersMutationResponse = {
  __typename?: 'UpdateDeviceDefinitionUdiDeviceIdentifiersMutationResponse';
  deviceDefinitionUdiDeviceIdentifiers: Array<DeviceDefinitionUdiDeviceIdentifier>;
};

export type UpdateDeviceDefinitionsMutationResponse = {
  __typename?: 'UpdateDeviceDefinitionsMutationResponse';
  deviceDefinitions: Array<DeviceDefinition>;
};

export type UpdateDeviceDeviceNamesMutationResponse = {
  __typename?: 'UpdateDeviceDeviceNamesMutationResponse';
  deviceDeviceNames: Array<DeviceDeviceName>;
};

export type UpdateDeviceMetricCalibrationsMutationResponse = {
  __typename?: 'UpdateDeviceMetricCalibrationsMutationResponse';
  deviceMetricCalibrations: Array<DeviceMetricCalibration>;
};

export type UpdateDeviceMetricsMutationResponse = {
  __typename?: 'UpdateDeviceMetricsMutationResponse';
  deviceMetrics: Array<DeviceMetric>;
};

export type UpdateDevicePropertiesMutationResponse = {
  __typename?: 'UpdateDevicePropertiesMutationResponse';
  deviceProperties: Array<DeviceProperty>;
};

export type UpdateDeviceSpecializationsMutationResponse = {
  __typename?: 'UpdateDeviceSpecializationsMutationResponse';
  deviceSpecializations: Array<DeviceSpecialization>;
};

export type UpdateDeviceUdiCarriersMutationResponse = {
  __typename?: 'UpdateDeviceUdiCarriersMutationResponse';
  deviceUdiCarriers: Array<DeviceUdiCarrier>;
};

export type UpdateDeviceVersionsMutationResponse = {
  __typename?: 'UpdateDeviceVersionsMutationResponse';
  deviceVersions: Array<DeviceVersion>;
};

export type UpdateDevicesMutationResponse = {
  __typename?: 'UpdateDevicesMutationResponse';
  devices: Array<Device>;
};

export type UpdateDistancesMutationResponse = {
  __typename?: 'UpdateDistancesMutationResponse';
  distances: Array<Distance>;
};

export type UpdateDosageDoseAndRatesMutationResponse = {
  __typename?: 'UpdateDosageDoseAndRatesMutationResponse';
  dosageDoseAndRates: Array<DosageDoseAndRate>;
};

export type UpdateDosagesMutationResponse = {
  __typename?: 'UpdateDosagesMutationResponse';
  dosages: Array<Dosage>;
};

export type UpdateDurationsMutationResponse = {
  __typename?: 'UpdateDurationsMutationResponse';
  durations: Array<Duration>;
};

export type UpdateElementsMutationResponse = {
  __typename?: 'UpdateElementsMutationResponse';
  elements: Array<Element>;
};

export type UpdateEncounterClassHistoriesMutationResponse = {
  __typename?: 'UpdateEncounterClassHistoriesMutationResponse';
  encounterClassHistories: Array<EncounterClassHistory>;
};

export type UpdateEncounterDiagnosesMutationResponse = {
  __typename?: 'UpdateEncounterDiagnosesMutationResponse';
  encounterDiagnoses: Array<EncounterDiagnosis>;
};

export type UpdateEncounterHospitalizationsMutationResponse = {
  __typename?: 'UpdateEncounterHospitalizationsMutationResponse';
  encounterHospitalizations: Array<EncounterHospitalization>;
};

export type UpdateEncounterLocationsMutationResponse = {
  __typename?: 'UpdateEncounterLocationsMutationResponse';
  encounterLocations: Array<EncounterLocation>;
};

export type UpdateEncounterParticipantsMutationResponse = {
  __typename?: 'UpdateEncounterParticipantsMutationResponse';
  encounterParticipants: Array<EncounterParticipant>;
};

export type UpdateEncounterStatusHistoriesMutationResponse = {
  __typename?: 'UpdateEncounterStatusHistoriesMutationResponse';
  encounterStatusHistories: Array<EncounterStatusHistory>;
};

export type UpdateEncountersMutationResponse = {
  __typename?: 'UpdateEncountersMutationResponse';
  encounters: Array<Encounter>;
};

export type UpdateEndpointsMutationResponse = {
  __typename?: 'UpdateEndpointsMutationResponse';
  endpoints: Array<Endpoint>;
};

export type UpdateEpisodeOfCareDiagnosesMutationResponse = {
  __typename?: 'UpdateEpisodeOfCareDiagnosesMutationResponse';
  episodeOfCareDiagnoses: Array<EpisodeOfCareDiagnosis>;
};

export type UpdateEpisodeOfCareStatusHistoriesMutationResponse = {
  __typename?: 'UpdateEpisodeOfCareStatusHistoriesMutationResponse';
  episodeOfCareStatusHistories: Array<EpisodeOfCareStatusHistory>;
};

export type UpdateEpisodeOfCaresMutationResponse = {
  __typename?: 'UpdateEpisodeOfCaresMutationResponse';
  episodeOfCares: Array<EpisodeOfCare>;
};

export type UpdateExpressionsMutationResponse = {
  __typename?: 'UpdateExpressionsMutationResponse';
  expressions: Array<Expression>;
};

export type UpdateExtensionsMutationResponse = {
  __typename?: 'UpdateExtensionsMutationResponse';
  extensions: Array<Extension>;
};

export type UpdateHealthcareServiceAvailableTimesMutationResponse = {
  __typename?: 'UpdateHealthcareServiceAvailableTimesMutationResponse';
  healthcareServiceAvailableTimes: Array<HealthcareServiceAvailableTime>;
};

export type UpdateHealthcareServiceEligibilitiesMutationResponse = {
  __typename?: 'UpdateHealthcareServiceEligibilitiesMutationResponse';
  healthcareServiceEligibilities: Array<HealthcareServiceEligibility>;
};

export type UpdateHealthcareServiceNotAvailablesMutationResponse = {
  __typename?: 'UpdateHealthcareServiceNotAvailablesMutationResponse';
  healthcareServiceNotAvailables: Array<HealthcareServiceNotAvailable>;
};

export type UpdateHealthcareServicesMutationResponse = {
  __typename?: 'UpdateHealthcareServicesMutationResponse';
  healthcareServices: Array<HealthcareService>;
};

export type UpdateHumanNamesMutationResponse = {
  __typename?: 'UpdateHumanNamesMutationResponse';
  humanNames: Array<HumanName>;
};

export type UpdateIdentifiersMutationResponse = {
  __typename?: 'UpdateIdentifiersMutationResponse';
  identifiers: Array<Identifier>;
};

export type UpdateLocationHoursOfOperationsMutationResponse = {
  __typename?: 'UpdateLocationHoursOfOperationsMutationResponse';
  locationHoursOfOperations: Array<LocationHoursOfOperation>;
};

export type UpdateLocationPositionsMutationResponse = {
  __typename?: 'UpdateLocationPositionsMutationResponse';
  locationPositions: Array<LocationPosition>;
};

export type UpdateLocationsMutationResponse = {
  __typename?: 'UpdateLocationsMutationResponse';
  locations: Array<Location>;
};

export type UpdateMetasMutationResponse = {
  __typename?: 'UpdateMetasMutationResponse';
  metas: Array<Meta>;
};

export type UpdateMoniesMutationResponse = {
  __typename?: 'UpdateMoniesMutationResponse';
  monies: Array<Money>;
};

export type UpdateNarrativesMutationResponse = {
  __typename?: 'UpdateNarrativesMutationResponse';
  narratives: Array<Narrative>;
};

export type UpdateOrganizationContactsMutationResponse = {
  __typename?: 'UpdateOrganizationContactsMutationResponse';
  organizationContacts: Array<OrganizationContact>;
};

export type UpdateOrganizationsMutationResponse = {
  __typename?: 'UpdateOrganizationsMutationResponse';
  organizations: Array<Organization>;
};

export type UpdateParameterDefinitionsMutationResponse = {
  __typename?: 'UpdateParameterDefinitionsMutationResponse';
  parameterDefinitions: Array<ParameterDefinition>;
};

export type UpdatePatientCommunicationsMutationResponse = {
  __typename?: 'UpdatePatientCommunicationsMutationResponse';
  patientCommunications: Array<PatientCommunication>;
};

export type UpdatePatientContactsMutationResponse = {
  __typename?: 'UpdatePatientContactsMutationResponse';
  patientContacts: Array<PatientContact>;
};

export type UpdatePatientLinksMutationResponse = {
  __typename?: 'UpdatePatientLinksMutationResponse';
  patientLinks: Array<PatientLink>;
};

export type UpdatePatientsMutationResponse = {
  __typename?: 'UpdatePatientsMutationResponse';
  patients: Array<Patient>;
};

export type UpdatePeopleMutationResponse = {
  __typename?: 'UpdatePeopleMutationResponse';
  people: Array<Person>;
};

export type UpdatePeriodsMutationResponse = {
  __typename?: 'UpdatePeriodsMutationResponse';
  periods: Array<Period>;
};

export type UpdatePersonLinksMutationResponse = {
  __typename?: 'UpdatePersonLinksMutationResponse';
  personLinks: Array<PersonLink>;
};

export type UpdatePractitionerQualificationsMutationResponse = {
  __typename?: 'UpdatePractitionerQualificationsMutationResponse';
  practitionerQualifications: Array<PractitionerQualification>;
};

export type UpdatePractitionerRoleAvailableTimesMutationResponse = {
  __typename?: 'UpdatePractitionerRoleAvailableTimesMutationResponse';
  practitionerRoleAvailableTimes: Array<PractitionerRoleAvailableTime>;
};

export type UpdatePractitionerRoleNotAvailablesMutationResponse = {
  __typename?: 'UpdatePractitionerRoleNotAvailablesMutationResponse';
  practitionerRoleNotAvailables: Array<PractitionerRoleNotAvailable>;
};

export type UpdatePractitionerRolesMutationResponse = {
  __typename?: 'UpdatePractitionerRolesMutationResponse';
  practitionerRoles: Array<PractitionerRole>;
};

export type UpdatePractitionersMutationResponse = {
  __typename?: 'UpdatePractitionersMutationResponse';
  practitioners: Array<Practitioner>;
};

export type UpdateProdCharacteristicsMutationResponse = {
  __typename?: 'UpdateProdCharacteristicsMutationResponse';
  prodCharacteristics: Array<ProdCharacteristic>;
};

export type UpdateProductShelfLivesMutationResponse = {
  __typename?: 'UpdateProductShelfLivesMutationResponse';
  productShelfLives: Array<ProductShelfLife>;
};

export type UpdateQuantitiesMutationResponse = {
  __typename?: 'UpdateQuantitiesMutationResponse';
  quantities: Array<Quantity>;
};

export type UpdateRangesMutationResponse = {
  __typename?: 'UpdateRangesMutationResponse';
  ranges: Array<Range>;
};

export type UpdateRatiosMutationResponse = {
  __typename?: 'UpdateRatiosMutationResponse';
  ratios: Array<Ratio>;
};

export type UpdateReferencesMutationResponse = {
  __typename?: 'UpdateReferencesMutationResponse';
  references: Array<Reference>;
};

export type UpdateRelatedArtifactsMutationResponse = {
  __typename?: 'UpdateRelatedArtifactsMutationResponse';
  relatedArtifacts: Array<RelatedArtifact>;
};

export type UpdateRelatedPeopleMutationResponse = {
  __typename?: 'UpdateRelatedPeopleMutationResponse';
  relatedPeople: Array<RelatedPerson>;
};

export type UpdateRelatedPersonCommunicationsMutationResponse = {
  __typename?: 'UpdateRelatedPersonCommunicationsMutationResponse';
  relatedPersonCommunications: Array<RelatedPersonCommunication>;
};

export type UpdateSampledDataMutationResponse = {
  __typename?: 'UpdateSampledDataMutationResponse';
  sampledData: Array<SampledData>;
};

export type UpdateSchedulesMutationResponse = {
  __typename?: 'UpdateSchedulesMutationResponse';
  schedules: Array<Schedule>;
};

export type UpdateSignaturesMutationResponse = {
  __typename?: 'UpdateSignaturesMutationResponse';
  signatures: Array<Signature>;
};

export type UpdateSlotsMutationResponse = {
  __typename?: 'UpdateSlotsMutationResponse';
  slots: Array<Slot>;
};

export type UpdateSubstanceIngredientsMutationResponse = {
  __typename?: 'UpdateSubstanceIngredientsMutationResponse';
  substanceIngredients: Array<SubstanceIngredient>;
};

export type UpdateSubstanceInstancesMutationResponse = {
  __typename?: 'UpdateSubstanceInstancesMutationResponse';
  substanceInstances: Array<SubstanceInstance>;
};

export type UpdateSubstancesMutationResponse = {
  __typename?: 'UpdateSubstancesMutationResponse';
  substances: Array<Substance>;
};

export type UpdateTimingRepeatsMutationResponse = {
  __typename?: 'UpdateTimingRepeatsMutationResponse';
  timingRepeats: Array<TimingRepeat>;
};

export type UpdateTimingsMutationResponse = {
  __typename?: 'UpdateTimingsMutationResponse';
  timings: Array<Timing>;
};

export type UpdateTriggerDefinitionsMutationResponse = {
  __typename?: 'UpdateTriggerDefinitionsMutationResponse';
  triggerDefinitions: Array<TriggerDefinition>;
};

export type UpdateUsageContextsMutationResponse = {
  __typename?: 'UpdateUsageContextsMutationResponse';
  usageContexts: Array<UsageContext>;
};

export type UpdateUsersMutationResponse = {
  __typename?: 'UpdateUsersMutationResponse';
  users: Array<User>;
};

/** Specifies clinical/business/etc. metadata that can be used to retrieve, index and/or categorize an artifact. This metadata can either be specific to the applicable population (e.g., age category, DRG) or the specific context of care (e.g., venue, care setting, provider of care). */
export type UsageContext = {
  __typename?: 'UsageContext';
  /** Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces. */
  id: Scalars['ID'];
  extension: Array<Extension>;
  code: Coding;
  valueCodeableConcept?: Maybe<CodeableConcept>;
  valueQuantity?: Maybe<Quantity>;
  valueRange?: Maybe<Range>;
  valueReference?: Maybe<Reference>;
};

/** Specifies clinical/business/etc. metadata that can be used to retrieve, index and/or categorize an artifact. This metadata can either be specific to the applicable population (e.g., age category, DRG) or the specific context of care (e.g., venue, care setting, provider of care). */
export type UsageContextExtensionArgs = {
  where?: Maybe<ExtensionWhere>;
  options?: Maybe<ExtensionOptions>;
};

/** Specifies clinical/business/etc. metadata that can be used to retrieve, index and/or categorize an artifact. This metadata can either be specific to the applicable population (e.g., age category, DRG) or the specific context of care (e.g., venue, care setting, provider of care). */
export type UsageContextCodeArgs = {
  where?: Maybe<CodingWhere>;
  options?: Maybe<CodingOptions>;
};

/** Specifies clinical/business/etc. metadata that can be used to retrieve, index and/or categorize an artifact. This metadata can either be specific to the applicable population (e.g., age category, DRG) or the specific context of care (e.g., venue, care setting, provider of care). */
export type UsageContextValueCodeableConceptArgs = {
  where?: Maybe<CodeableConceptWhere>;
  options?: Maybe<CodeableConceptOptions>;
};

/** Specifies clinical/business/etc. metadata that can be used to retrieve, index and/or categorize an artifact. This metadata can either be specific to the applicable population (e.g., age category, DRG) or the specific context of care (e.g., venue, care setting, provider of care). */
export type UsageContextValueQuantityArgs = {
  where?: Maybe<QuantityWhere>;
  options?: Maybe<QuantityOptions>;
};

/** Specifies clinical/business/etc. metadata that can be used to retrieve, index and/or categorize an artifact. This metadata can either be specific to the applicable population (e.g., age category, DRG) or the specific context of care (e.g., venue, care setting, provider of care). */
export type UsageContextValueRangeArgs = {
  where?: Maybe<RangeWhere>;
  options?: Maybe<RangeOptions>;
};

/** Specifies clinical/business/etc. metadata that can be used to retrieve, index and/or categorize an artifact. This metadata can either be specific to the applicable population (e.g., age category, DRG) or the specific context of care (e.g., venue, care setting, provider of care). */
export type UsageContextValueReferenceArgs = {
  where?: Maybe<ReferenceWhere>;
  options?: Maybe<ReferenceOptions>;
};

export type UsageContextCodeDeleteFieldInput = {
  where?: Maybe<CodingWhere>;
  delete?: Maybe<CodingDeleteInput>;
};

export type UsageContextCodeFieldInput = {
  create?: Maybe<CodingCreateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
};

export type UsageContextCodeUpdateFieldInput = {
  where?: Maybe<CodingWhere>;
  update?: Maybe<CodingUpdateInput>;
  connect?: Maybe<CodingConnectFieldInput>;
  disconnect?: Maybe<CodingDisconnectFieldInput>;
  create?: Maybe<CodingCreateInput>;
  delete?: Maybe<CodingDeleteFieldInput>;
};

export type UsageContextConnectFieldInput = {
  where?: Maybe<UsageContextWhere>;
  connect?: Maybe<UsageContextConnectInput>;
};

export type UsageContextConnectInput = {
  extension?: Maybe<Array<ExtensionConnectFieldInput>>;
  code?: Maybe<CodingConnectFieldInput>;
  valueCodeableConcept?: Maybe<CodeableConceptConnectFieldInput>;
  valueQuantity?: Maybe<QuantityConnectFieldInput>;
  valueRange?: Maybe<RangeConnectFieldInput>;
  valueReference?: Maybe<ReferenceConnectFieldInput>;
};

export type UsageContextCreateInput = {
  extension?: Maybe<UsageContextExtensionFieldInput>;
  code?: Maybe<UsageContextCodeFieldInput>;
  valueCodeableConcept?: Maybe<UsageContextValuecodeableconceptFieldInput>;
  valueQuantity?: Maybe<UsageContextValuequantityFieldInput>;
  valueRange?: Maybe<UsageContextValuerangeFieldInput>;
  valueReference?: Maybe<UsageContextValuereferenceFieldInput>;
};

export type UsageContextDeleteFieldInput = {
  where?: Maybe<UsageContextWhere>;
  delete?: Maybe<UsageContextDeleteInput>;
};

export type UsageContextDeleteInput = {
  extension?: Maybe<Array<UsageContextExtensionDeleteFieldInput>>;
  code?: Maybe<UsageContextCodeDeleteFieldInput>;
  valueCodeableConcept?: Maybe<UsageContextValuecodeableconceptDeleteFieldInput>;
  valueQuantity?: Maybe<UsageContextValuequantityDeleteFieldInput>;
  valueRange?: Maybe<UsageContextValuerangeDeleteFieldInput>;
  valueReference?: Maybe<UsageContextValuereferenceDeleteFieldInput>;
};

export type UsageContextDisconnectFieldInput = {
  where?: Maybe<UsageContextWhere>;
  disconnect?: Maybe<UsageContextDisconnectInput>;
};

export type UsageContextDisconnectInput = {
  extension?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  code?: Maybe<CodingDisconnectFieldInput>;
  valueCodeableConcept?: Maybe<CodeableConceptDisconnectFieldInput>;
  valueQuantity?: Maybe<QuantityDisconnectFieldInput>;
  valueRange?: Maybe<RangeDisconnectFieldInput>;
  valueReference?: Maybe<ReferenceDisconnectFieldInput>;
};

export type UsageContextExtensionDeleteFieldInput = {
  where?: Maybe<ExtensionWhere>;
  delete?: Maybe<ExtensionDeleteInput>;
};

export type UsageContextExtensionFieldInput = {
  create?: Maybe<Array<ExtensionCreateInput>>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
};

export type UsageContextExtensionUpdateFieldInput = {
  where?: Maybe<ExtensionWhere>;
  update?: Maybe<ExtensionUpdateInput>;
  connect?: Maybe<Array<ExtensionConnectFieldInput>>;
  disconnect?: Maybe<Array<ExtensionDisconnectFieldInput>>;
  create?: Maybe<Array<ExtensionCreateInput>>;
  delete?: Maybe<Array<ExtensionDeleteFieldInput>>;
};

export type UsageContextOptions = {
  /** Specify one or more UsageContextSort objects to sort UsageContexts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<UsageContextSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type UsageContextRelationInput = {
  extension?: Maybe<Array<ExtensionCreateInput>>;
  code?: Maybe<CodingCreateInput>;
  valueCodeableConcept?: Maybe<CodeableConceptCreateInput>;
  valueQuantity?: Maybe<QuantityCreateInput>;
  valueRange?: Maybe<RangeCreateInput>;
  valueReference?: Maybe<ReferenceCreateInput>;
};

/** Fields to sort UsageContexts by. The order in which sorts are applied is not guaranteed when specifying many fields in one UsageContextSort object. */
export type UsageContextSort = {
  id?: Maybe<SortDirection>;
};

export type UsageContextUpdateInput = {
  extension?: Maybe<Array<UsageContextExtensionUpdateFieldInput>>;
  code?: Maybe<UsageContextCodeUpdateFieldInput>;
  valueCodeableConcept?: Maybe<UsageContextValuecodeableconceptUpdateFieldInput>;
  valueQuantity?: Maybe<UsageContextValuequantityUpdateFieldInput>;
  valueRange?: Maybe<UsageContextValuerangeUpdateFieldInput>;
  valueReference?: Maybe<UsageContextValuereferenceUpdateFieldInput>;
};

export type UsageContextValuecodeableconceptDeleteFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  delete?: Maybe<CodeableConceptDeleteInput>;
};

export type UsageContextValuecodeableconceptFieldInput = {
  create?: Maybe<CodeableConceptCreateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
};

export type UsageContextValuecodeableconceptUpdateFieldInput = {
  where?: Maybe<CodeableConceptWhere>;
  update?: Maybe<CodeableConceptUpdateInput>;
  connect?: Maybe<CodeableConceptConnectFieldInput>;
  disconnect?: Maybe<CodeableConceptDisconnectFieldInput>;
  create?: Maybe<CodeableConceptCreateInput>;
  delete?: Maybe<CodeableConceptDeleteFieldInput>;
};

export type UsageContextValuequantityDeleteFieldInput = {
  where?: Maybe<QuantityWhere>;
  delete?: Maybe<QuantityDeleteInput>;
};

export type UsageContextValuequantityFieldInput = {
  create?: Maybe<QuantityCreateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
};

export type UsageContextValuequantityUpdateFieldInput = {
  where?: Maybe<QuantityWhere>;
  update?: Maybe<QuantityUpdateInput>;
  connect?: Maybe<QuantityConnectFieldInput>;
  disconnect?: Maybe<QuantityDisconnectFieldInput>;
  create?: Maybe<QuantityCreateInput>;
  delete?: Maybe<QuantityDeleteFieldInput>;
};

export type UsageContextValuerangeDeleteFieldInput = {
  where?: Maybe<RangeWhere>;
  delete?: Maybe<RangeDeleteInput>;
};

export type UsageContextValuerangeFieldInput = {
  create?: Maybe<RangeCreateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
};

export type UsageContextValuerangeUpdateFieldInput = {
  where?: Maybe<RangeWhere>;
  update?: Maybe<RangeUpdateInput>;
  connect?: Maybe<RangeConnectFieldInput>;
  disconnect?: Maybe<RangeDisconnectFieldInput>;
  create?: Maybe<RangeCreateInput>;
  delete?: Maybe<RangeDeleteFieldInput>;
};

export type UsageContextValuereferenceDeleteFieldInput = {
  where?: Maybe<ReferenceWhere>;
  delete?: Maybe<ReferenceDeleteInput>;
};

export type UsageContextValuereferenceFieldInput = {
  create?: Maybe<ReferenceCreateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
};

export type UsageContextValuereferenceUpdateFieldInput = {
  where?: Maybe<ReferenceWhere>;
  update?: Maybe<ReferenceUpdateInput>;
  connect?: Maybe<ReferenceConnectFieldInput>;
  disconnect?: Maybe<ReferenceDisconnectFieldInput>;
  create?: Maybe<ReferenceCreateInput>;
  delete?: Maybe<ReferenceDeleteFieldInput>;
};

export type UsageContextWhere = {
  OR?: Maybe<Array<UsageContextWhere>>;
  AND?: Maybe<Array<UsageContextWhere>>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  extension?: Maybe<ExtensionWhere>;
  extension_NOT?: Maybe<ExtensionWhere>;
  code?: Maybe<CodingWhere>;
  code_NOT?: Maybe<CodingWhere>;
  code_IN?: Maybe<Array<CodingWhere>>;
  code_NOT_IN?: Maybe<Array<CodingWhere>>;
  valueCodeableConcept?: Maybe<CodeableConceptWhere>;
  valueCodeableConcept_NOT?: Maybe<CodeableConceptWhere>;
  valueCodeableConcept_IN?: Maybe<Array<CodeableConceptWhere>>;
  valueCodeableConcept_NOT_IN?: Maybe<Array<CodeableConceptWhere>>;
  valueQuantity?: Maybe<QuantityWhere>;
  valueQuantity_NOT?: Maybe<QuantityWhere>;
  valueQuantity_IN?: Maybe<Array<QuantityWhere>>;
  valueQuantity_NOT_IN?: Maybe<Array<QuantityWhere>>;
  valueRange?: Maybe<RangeWhere>;
  valueRange_NOT?: Maybe<RangeWhere>;
  valueRange_IN?: Maybe<Array<RangeWhere>>;
  valueRange_NOT_IN?: Maybe<Array<RangeWhere>>;
  valueReference?: Maybe<ReferenceWhere>;
  valueReference_NOT?: Maybe<ReferenceWhere>;
  valueReference_IN?: Maybe<Array<ReferenceWhere>>;
  valueReference_NOT_IN?: Maybe<Array<ReferenceWhere>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  appRole: AppRole;
  email: Scalars['String'];
  password: Scalars['String'];
  profile?: Maybe<Practitioner>;
  organizations: Array<Organization>;
};

export type UserProfileArgs = {
  where?: Maybe<PractitionerWhere>;
  options?: Maybe<PractitionerOptions>;
};

export type UserOrganizationsArgs = {
  where?: Maybe<OrganizationWhere>;
  options?: Maybe<OrganizationOptions>;
};

export type UserConnectInput = {
  profile?: Maybe<PractitionerConnectFieldInput>;
  organizations?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type UserCreateInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  appRole: AppRole;
  profile?: Maybe<UserProfileFieldInput>;
  organizations?: Maybe<UserOrganizationsFieldInput>;
};

export type UserDeleteInput = {
  profile?: Maybe<UserProfileDeleteFieldInput>;
  organizations?: Maybe<Array<UserOrganizationsDeleteFieldInput>>;
};

export type UserDisconnectInput = {
  profile?: Maybe<PractitionerDisconnectFieldInput>;
  organizations?: Maybe<Array<OrganizationDisconnectFieldInput>>;
};

export type UserOptions = {
  /** Specify one or more UserSort objects to sort Users by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: Maybe<Array<Maybe<UserSort>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type UserOrganizationsDeleteFieldInput = {
  where?: Maybe<OrganizationWhere>;
  delete?: Maybe<OrganizationDeleteInput>;
};

export type UserOrganizationsFieldInput = {
  create?: Maybe<Array<OrganizationCreateInput>>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
};

export type UserOrganizationsUpdateFieldInput = {
  where?: Maybe<OrganizationWhere>;
  update?: Maybe<OrganizationUpdateInput>;
  connect?: Maybe<Array<OrganizationConnectFieldInput>>;
  disconnect?: Maybe<Array<OrganizationDisconnectFieldInput>>;
  create?: Maybe<Array<OrganizationCreateInput>>;
  delete?: Maybe<Array<OrganizationDeleteFieldInput>>;
};

export type UserProfileDeleteFieldInput = {
  where?: Maybe<PractitionerWhere>;
  delete?: Maybe<PractitionerDeleteInput>;
};

export type UserProfileFieldInput = {
  create?: Maybe<PractitionerCreateInput>;
  connect?: Maybe<PractitionerConnectFieldInput>;
};

export type UserProfileUpdateFieldInput = {
  where?: Maybe<PractitionerWhere>;
  update?: Maybe<PractitionerUpdateInput>;
  connect?: Maybe<PractitionerConnectFieldInput>;
  disconnect?: Maybe<PractitionerDisconnectFieldInput>;
  create?: Maybe<PractitionerCreateInput>;
  delete?: Maybe<PractitionerDeleteFieldInput>;
};

export type UserRelationInput = {
  profile?: Maybe<PractitionerCreateInput>;
  organizations?: Maybe<Array<OrganizationCreateInput>>;
};

/** Fields to sort Users by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserSort object. */
export type UserSort = {
  id?: Maybe<SortDirection>;
  appRole?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  password?: Maybe<SortDirection>;
};

export type UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  appRole?: Maybe<AppRole>;
  profile?: Maybe<UserProfileUpdateFieldInput>;
  organizations?: Maybe<Array<UserOrganizationsUpdateFieldInput>>;
};

export type UserWhere = {
  OR?: Maybe<Array<UserWhere>>;
  AND?: Maybe<Array<UserWhere>>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  id_NOT?: Maybe<Scalars['ID']>;
  id_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_CONTAINS?: Maybe<Scalars['ID']>;
  id_NOT_CONTAINS?: Maybe<Scalars['ID']>;
  id_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
  id_ENDS_WITH?: Maybe<Scalars['ID']>;
  id_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
  appRole?: Maybe<AppRole>;
  appRole_NOT?: Maybe<AppRole>;
  appRole_IN?: Maybe<Array<Maybe<AppRole>>>;
  appRole_NOT_IN?: Maybe<Array<Maybe<AppRole>>>;
  profile?: Maybe<PractitionerWhere>;
  profile_NOT?: Maybe<PractitionerWhere>;
  profile_IN?: Maybe<Array<PractitionerWhere>>;
  profile_NOT_IN?: Maybe<Array<PractitionerWhere>>;
  organizations?: Maybe<OrganizationWhere>;
  organizations_NOT?: Maybe<OrganizationWhere>;
};

export type CreateLocationMutationVariables = Exact<{
  status: LocationStatus;
  mode: LocationMode;
  name: Scalars['String'];
  alias: Array<Scalars['String']> | Scalars['String'];
  description: Scalars['String'];
  typetext: Scalars['String'];
  telecom: Scalars['String'];
  line: Array<Scalars['String']> | Scalars['String'];
  addresscity: Scalars['String'];
  addressstate: Scalars['String'];
  addresspostalcode: Scalars['String'];
  addresscountry: Scalars['String'];
  physicaltype: Scalars['String'];
  organization: Scalars['String'];
  exception: Scalars['String'];
}>;

export type CreateLocationMutation = { __typename?: 'Mutation' } & {
  createLocations: { __typename?: 'CreateLocationsMutationResponse' } & {
    locations: Array<{ __typename?: 'Location' } & Pick<Location, 'id'>>;
  };
};

export type LocationListQueryVariables = Exact<{
  order: SortDirection;
}>;

export type LocationListQuery = { __typename?: 'Query' } & {
  locations: Array<
    Maybe<
      { __typename?: 'Location' } & Pick<
        Location,
        'id' | 'status' | 'name' | 'alias' | 'description' | 'mode' | 'availabilityExceptions'
      > & {
          type: Array<{ __typename?: 'CodeableConcept' } & Pick<CodeableConcept, 'text'>>;
          telecom: Array<{ __typename?: 'ContactPoint' } & Pick<ContactPoint, 'value'>>;
          address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'line'>>;
          physicalType?: Maybe<{ __typename?: 'CodeableConcept' } & Pick<CodeableConcept, 'text'>>;
          position?: Maybe<
            { __typename?: 'LocationPosition' } & Pick<LocationPosition, 'longitude' | 'latitude' | 'altitude'>
          >;
          managingOrganization?: Maybe<{ __typename?: 'Reference' } & Pick<Reference, 'reference'>>;
          partOf?: Maybe<{ __typename?: 'Reference' } & Pick<Reference, 'reference'>>;
          hoursOfOperation: Array<
            { __typename?: 'LocationHoursOfOperation' } & Pick<
              LocationHoursOfOperation,
              'daysOfWeek' | 'openingTime' | 'closingTime'
            >
          >;
          endpoint: Array<{ __typename?: 'Reference' } & Pick<Reference, 'reference'>>;
        }
    >
  >;
};

export type DeleteLocationsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteLocationsMutation = { __typename?: 'Mutation' } & {
  deleteLocations: { __typename?: 'DeleteInfo' } & Pick<DeleteInfo, 'nodesDeleted' | 'relationshipsDeleted'>;
};

export type CreatePatientMutationVariables = Exact<{
  given: Array<Scalars['String']> | Scalars['String'];
  family: Scalars['String'];
  telecom: Scalars['String'];
  gender: PatientGender;
  birthday: Scalars['Date'];
  photourl?: Maybe<Scalars['Url']>;
  line: Array<Scalars['String']> | Scalars['String'];
  addresscity: Scalars['String'];
  addressstate: Scalars['String'];
  addresspostcode: Scalars['String'];
  addresscountry: Scalars['String'];
  language: Scalars['String'];
  maritalstatus: Scalars['String'];
}>;

export type CreatePatientMutation = { __typename?: 'Mutation' } & {
  createPatients: { __typename?: 'CreatePatientsMutationResponse' } & {
    patients: Array<{ __typename?: 'Patient' } & Pick<Patient, 'id'>>;
  };
};

export type PatientListQueryVariables = Exact<{
  order: SortDirection;
}>;

export type PatientListQuery = { __typename?: 'Query' } & {
  patients: Array<
    Maybe<
      { __typename?: 'Patient' } & Pick<Patient, 'id' | 'gender' | 'birthDate'> & {
          name: Array<{ __typename?: 'HumanName' } & Pick<HumanName, 'given' | 'family'>>;
          telecom: Array<{ __typename?: 'ContactPoint' } & Pick<ContactPoint, 'value'>>;
          address: Array<{ __typename?: 'Address' } & Pick<Address, 'line'>>;
          maritalStatus?: Maybe<{ __typename?: 'CodeableConcept' } & Pick<CodeableConcept, 'text'>>;
          photo: Array<{ __typename?: 'Attachment' } & Pick<Attachment, 'url'>>;
          contact: Array<
            { __typename?: 'PatientContact' } & Pick<PatientContact, 'gender'> & {
                relationship: Array<{ __typename?: 'CodeableConcept' } & Pick<CodeableConcept, 'text'>>;
                name?: Maybe<{ __typename?: 'HumanName' } & Pick<HumanName, 'given' | 'family'>>;
                telecom: Array<{ __typename?: 'ContactPoint' } & Pick<ContactPoint, 'value'>>;
                address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'line'>>;
                organization?: Maybe<{ __typename?: 'Reference' } & Pick<Reference, 'reference'>>;
                period?: Maybe<{ __typename?: 'Period' } & Pick<Period, 'start' | 'end'>>;
              }
          >;
          communication: Array<
            { __typename?: 'PatientCommunication' } & {
              language: { __typename?: 'CodeableConcept' } & Pick<CodeableConcept, 'text'>;
            }
          >;
          generalPractitioner: Array<{ __typename?: 'Reference' } & Pick<Reference, 'reference'>>;
          managingOrganization?: Maybe<{ __typename?: 'Reference' } & Pick<Reference, 'reference'>>;
          link: Array<
            { __typename?: 'PatientLink' } & Pick<PatientLink, 'type'> & {
                other: { __typename?: 'Reference' } & Pick<Reference, 'reference'>;
              }
          >;
        }
    >
  >;
};

export type DeletePatientsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePatientsMutation = { __typename?: 'Mutation' } & {
  deletePatients: { __typename?: 'DeleteInfo' } & Pick<DeleteInfo, 'nodesDeleted' | 'relationshipsDeleted'>;
};

export type CreatePractitionerMutationVariables = Exact<{
  given: Array<Scalars['String']> | Scalars['String'];
  family: Scalars['String'];
  telecom: Scalars['String'];
  gender: PractitionerGender;
  birthday: Scalars['Date'];
  photourl?: Maybe<Scalars['Url']>;
  line: Array<Scalars['String']> | Scalars['String'];
  addresscity: Scalars['String'];
  addressstate: Scalars['String'];
  addresspostcode: Scalars['String'];
  addresscountry: Scalars['String'];
  language: Scalars['String'];
  codetext: Scalars['String'];
  startdate: Scalars['DateTime'];
  enddate: Scalars['DateTime'];
  issuer: Scalars['String'];
}>;

export type CreatePractitionerMutation = { __typename?: 'Mutation' } & {
  createPractitioners: { __typename?: 'CreatePractitionersMutationResponse' } & {
    practitioners: Array<{ __typename?: 'Practitioner' } & Pick<Practitioner, 'id'>>;
  };
};

export type PractitionerListQueryVariables = Exact<{
  order: SortDirection;
}>;

export type PractitionerListQuery = { __typename?: 'Query' } & {
  practitioners: Array<
    Maybe<
      { __typename?: 'Practitioner' } & Pick<Practitioner, 'id' | 'gender' | 'birthDate'> & {
          name: Array<{ __typename?: 'HumanName' } & Pick<HumanName, 'given' | 'family'>>;
          telecom: Array<{ __typename?: 'ContactPoint' } & Pick<ContactPoint, 'value'>>;
          address: Array<{ __typename?: 'Address' } & Pick<Address, 'line'>>;
          photo: Array<{ __typename?: 'Attachment' } & Pick<Attachment, 'url'>>;
          qualification: Array<
            { __typename?: 'PractitionerQualification' } & Pick<PractitionerQualification, 'id'> & {
                code: { __typename?: 'CodeableConcept' } & Pick<CodeableConcept, 'text'>;
                period?: Maybe<{ __typename?: 'Period' } & Pick<Period, 'start' | 'end'>>;
                issuer?: Maybe<{ __typename?: 'Reference' } & Pick<Reference, 'reference'>>;
              }
          >;
          communication: Array<{ __typename?: 'CodeableConcept' } & Pick<CodeableConcept, 'text'>>;
        }
    >
  >;
};

export type DeletePractitionersMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePractitionersMutation = { __typename?: 'Mutation' } & {
  deletePractitioners: { __typename?: 'DeleteInfo' } & Pick<DeleteInfo, 'nodesDeleted' | 'relationshipsDeleted'>;
};

export type NameCommonFragment = { __typename?: 'HumanName' } & Pick<
  HumanName,
  'id' | 'use' | 'text' | 'family' | 'given' | 'prefix' | 'suffix'
>;

export type ContactPointCommonFragment = { __typename?: 'ContactPoint' } & Pick<
  ContactPoint,
  'id' | 'system' | 'value' | 'use' | 'rank'
> & { period?: Maybe<{ __typename?: 'Period' } & Pick<Period, 'start' | 'end'>> };

export type PractitionerCommonFragment = { __typename?: 'Practitioner' } & Pick<
  Practitioner,
  'id' | 'active' | 'resourceType' | 'gender' | 'implicitRules' | 'birthDate'
> & {
    name: Array<{ __typename?: 'HumanName' } & NameCommonFragment>;
    photo: Array<{ __typename?: 'Attachment' } & Pick<Attachment, 'id' | 'url'>>;
  };

export type CodeCommonFragment = { __typename?: 'CodeableConcept' } & Pick<CodeableConcept, 'id' | 'text'> & {
    coding: Array<
      { __typename?: 'Coding' } & Pick<Coding, 'id' | 'userSelected' | 'system' | 'version' | 'code' | 'display'>
    >;
  };

export type AuthUserFragment = { __typename?: 'User' } & Pick<User, 'id' | 'email' | 'appRole'> & {
    profile?: Maybe<{ __typename?: 'Practitioner' } & PractitionerCommonFragment>;
    organizations: Array<{ __typename?: 'Organization' } & Pick<Organization, 'id' | 'active' | 'name' | 'alias'>>;
  };

export type ViewerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ViewerQuery = { __typename?: 'Query' } & {
  users: Array<Maybe<{ __typename?: 'User' } & AuthUserFragment>>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  login: { __typename?: 'LoginResponse' } & Pick<LoginResponse, 'token'> & {
      user: { __typename?: 'User' } & AuthUserFragment;
    };
};

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  profile: PractitionerCreateInput;
}>;

export type RegisterMutation = { __typename?: 'Mutation' } & {
  register: { __typename?: 'LoginResponse' } & Pick<LoginResponse, 'token'> & {
      user: { __typename?: 'User' } & AuthUserFragment;
    };
};

export const ContactPointCommonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactPointCommon' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactPoint' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'use' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'period' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactPointCommonFragment, unknown>;
export const CodeCommonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CodeCommon' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CodeableConcept' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userSelected' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CodeCommonFragment, unknown>;
export const NameCommonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NameCommon' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HumanName' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'use' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'family' } },
          { kind: 'Field', name: { kind: 'Name', value: 'given' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suffix' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NameCommonFragment, unknown>;
export const PractitionerCommonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PractitionerCommon' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Practitioner' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resourceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'implicitRules' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NameCommon' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    ...NameCommonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PractitionerCommonFragment, unknown>;
export const AuthUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appRole' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PractitionerCommon' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alias' } },
              ],
            },
          },
        ],
      },
    },
    ...PractitionerCommonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AuthUserFragment, unknown>;
export const CreateLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'LocationStatus' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'LocationMode' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'alias' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'typetext' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'telecom' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'line' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addresscity' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addressstate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addresspostalcode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addresscountry' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'physicaltype' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'exception' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'resourceType' },
                      value: { kind: 'EnumValue', value: 'Location' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mode' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'mode' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'alias' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'alias' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'text' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'typetext' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'telecom' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'value' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'telecom' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'line' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'line' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'city' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addresscity' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'state' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addressstate' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'postalCode' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addresspostalcode' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'country' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addresscountry' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'physicalType' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'text' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'physicaltype' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managingOrganization' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'reference' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'availabilityExceptions' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'exception' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'locations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLocationMutation, CreateLocationMutationVariables>;
export const LocationListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocationList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortDirection' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'name' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alias' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'telecom' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'line' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'physicalType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'position' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'altitude' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managingOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reference' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partOf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reference' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hoursOfOperation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'daysOfWeek' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openingTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closingTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availabilityExceptions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'endpoint' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reference' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationListQuery, LocationListQueryVariables>;
export const DeleteLocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteLocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nodesDeleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relationshipsDeleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLocationsMutation, DeleteLocationsMutationVariables>;
export const CreatePatientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePatient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'given' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'family' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'telecom' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gender' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PatientGender' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'birthday' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'photourl' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Url' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'line' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addresscity' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addressstate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addresspostcode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addresscountry' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'maritalstatus' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPatients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'resourceType' },
                      value: { kind: 'EnumValue', value: 'Patient' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'given' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'given' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'family' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'family' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'prefix' },
                                  value: { kind: 'StringValue', value: '', block: false },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'suffix' },
                                  value: { kind: 'StringValue', value: '', block: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'telecom' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'value' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'telecom' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'gender' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'gender' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'birthDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'birthday' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'photo' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'url' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'photourl' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'line' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'line' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'city' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addresscity' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'state' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addressstate' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'postalCode' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addresspostcode' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'country' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addresscountry' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'communication' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'language' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'create' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'text' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'maritalStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'text' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'maritalstatus' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'patients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePatientMutation, CreatePatientMutationVariables>;
export const PatientListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PatientList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortDirection' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'birthDate' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'given' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'family' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'telecom' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'line' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maritalStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationship' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'given' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'family' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'telecom' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'line' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reference' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'period' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'language' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generalPractitioner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reference' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managingOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reference' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'other' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reference' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PatientListQuery, PatientListQueryVariables>;
export const DeletePatientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePatients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePatients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nodesDeleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relationshipsDeleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePatientsMutation, DeletePatientsMutationVariables>;
export const CreatePractitionerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePractitioner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'given' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'family' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'telecom' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gender' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PractitionerGender' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'birthday' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'photourl' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Url' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'line' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addresscity' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addressstate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addresspostcode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addresscountry' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'codetext' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startdate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'enddate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'issuer' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPractitioners' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'resourceType' },
                      value: { kind: 'EnumValue', value: 'Practitioner' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'given' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'given' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'family' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'family' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'prefix' },
                                  value: { kind: 'StringValue', value: '', block: false },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'suffix' },
                                  value: { kind: 'StringValue', value: '', block: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'telecom' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'value' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'telecom' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'line' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'line' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'city' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addresscity' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'state' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addressstate' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'postalCode' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addresspostcode' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'country' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'addresscountry' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'gender' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'gender' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'birthDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'birthday' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'photo' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'url' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'photourl' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'qualification' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'code' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'create' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'text' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'codetext' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'period' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'create' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'start' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'startdate' } },
                                            },
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'end' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'enddate' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'issuer' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'create' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'reference' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'issuer' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'communication' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'create' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'text' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'practitioners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePractitionerMutation, CreatePractitionerMutationVariables>;
export const PractitionerListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PractitionerList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortDirection' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'practitioners' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'birthDate' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'given' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'family' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'telecom' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'line' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qualification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'code' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'period' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'issuer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reference' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PractitionerListQuery, PractitionerListQueryVariables>;
export const DeletePractitionersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePractitioners' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePractitioners' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nodesDeleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relationshipsDeleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePractitionersMutation, DeletePractitionersMutationVariables>;
export const ViewerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Viewer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthUser' } }],
            },
          },
        ],
      },
    },
    ...AuthUserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ViewerQuery, ViewerQueryVariables>;
export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthUser' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AuthUserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Register' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profile' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PractitionerCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'register' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profile' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profile' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthUser' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AuthUserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<RegisterMutation, RegisterMutationVariables>;
