import type { FC } from 'react';
import { Typography } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import LogoSvg from 'assets/medorro.svg';
import { Link as RouterLink } from 'react-router-dom';
import 'assets/exa.ttf';

const LogoRoot = experimentalStyled('img')``;

const ImageBaseSize = 60;
const TextBaseSize = 32;

interface LogoProps {
  size?: 1 | 2 | 3 | 4;
  dark?: boolean;
}
const Logo: FC<LogoProps> = ({ size = 1, dark = true }) => (
  <>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <LogoRoot src={LogoSvg} sx={{ width: ImageBaseSize * size, height: ImageBaseSize * size }} />
      <Typography
        style={{
          color: dark ? '#333' : 'white',
          display: 'inline',
          fontSize: TextBaseSize * size,
          textShadow: dark ? undefined : '1px 1px #333',
          marginLeft: 8,
          fontFamily: 'Lexend Exa',
          letterSpacing: -1,
        }}
        variant="h1"
      >
        Medorro
      </Typography>
    </div>
  </>
);

export default Logo;

export const LogoLink: React.FC<LogoProps> = (props) => (
  <RouterLink to="/" style={{ textDecoration: 'none' }}>
    <Logo {...props} />
  </RouterLink>
);
