import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { AuthProvider } from './hooks/useAuth';
import { ApolloProvider } from '@apollo/client';
import { client } from './sdk/client';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ReduxProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
