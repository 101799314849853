export const APP_NAME = 'Medorro';

// export const API_URL = process.env.REACT_APP_API_URL!;
export const API_URL = 'https://medorro-api-staging.herokuapp.com';
export const GQL_URL = `${API_URL}/graphql`;
export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
} as const;
