import { AuthUserFragment, NameCommonFragment, PractitionerCommonFragment } from '../sdk/types';

export function fullName(name: Omit<NameCommonFragment, 'id'>) {
  return `${name.prefix.length ? name.prefix.join(' ') + ' ' : ''}${name.given} ${name.family}${
    name.suffix.length ? ' ' + name.suffix.join(' ') : ''
  }`;
}

export function authUserFullName(user?: AuthUserFragment) {
  if (!user || !user.profile?.name[0]) return '';
  return fullName(user!.profile!.name[0]);
}

export function authUserAvatar(user?: AuthUserFragment) {
  if (!user || !user.profile?.photo[0]) return null;
  return practitionerAvatar(user!.profile!);
}

export function practitionerAvatar(practitioner: PractitionerCommonFragment) {
  return practitioner!.photo.length && practitioner!.photo[0].url;
}
